import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchErpCustomers } from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import {
  getErpCustomers,
  getErpCustomersLoading,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import FormikSelect from '../../../FormikSelect/FormikSelect';

const ErpCustomerSelect = ({
  name,
  placeholder = 'Suchen...',
  labelKey,
  valueKey,
  isMulti = false,
  onChange,
  localFilter,
  disabled = false,
  ...props
}) => {

  const customers = useSelector(getErpCustomers);
  const customersLoading = useSelector(getErpCustomersLoading);

  const dispatch = useDispatch();

  const fetchCustomersPage = useCallback((searchQuery) => {
    dispatch(fetchErpCustomers({
      pageIndex: 0,
      pageSize: 10,
      searchQuery,
      view: 'plain_select',
    }));

  }, [dispatch]);

  const customersOptions = useMemo(() => customers.map(el => ({
    nameWithNumber: `${el.friendlyName} (${el.customerNumber})`,
    ...el,
  })), [customers]);

  return (
    <FormikSelect
      name={name}
      placeholder={placeholder}
      labelKey={labelKey}
      valueKey={valueKey}
      isMulti={isMulti}
      onChange={onChange}
      options={customersOptions}
      isLoading={customersLoading}
      onInputChange={fetchCustomersPage}
      localFilter={localFilter}
      {...props}
      disabled={disabled}
    />
  );
};

ErpCustomerSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  localFilter: PropTypes.arrayOf(PropTypes.shape),
  disabled: PropTypes.bool,
};

export default ErpCustomerSelect;
