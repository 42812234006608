import debounce from 'lodash/debounce';
import { replaceWithEmoji } from '../../../helper/emojiService';
import { latestMessageIsRead } from '../../messages/messageSelector';
import { getIsAgent } from '../../currentUser/currentUserSelectors';
import { channelInputTextChanged } from '../messageInputSlice';
import setChannelMessagesConsumed from '../../channel/channelThunks/setChannelMessagesConsumed';
import setChannelStartTyping from '../../channel/channelThunks/setChannelStartTyping';

const debouncedSetChannelMessagesConsumed = debounce(
  (dispatch, channelId) => {
    dispatch(setChannelMessagesConsumed({ channelId }));
  },
  1000, // debounce delay in ms
  { leading: true, trailing: false },
);

export const changeInputText = (
  { channelId, inputText, append = false },
) => async (dispatch, getState) => {
  const textWithEmojis = replaceWithEmoji(inputText);
  const latestIsRead = latestMessageIsRead(channelId)(getState());
  const isAgent = getIsAgent(getState());

  dispatch(channelInputTextChanged({ channelId, text: textWithEmojis, append }));

  if (!latestIsRead && isAgent) {
    debouncedSetChannelMessagesConsumed(dispatch, channelId);
  }

  dispatch(setChannelStartTyping({ channelId }));
};
