/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key,jsx-a11y/interactive-supports-focus */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { processSelected } from 'gcs-common/slices/processes/processesSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getProcessDateTag } from 'gcs-common/slices/processes/processesSelectors';
import ProcessDetailSmall from '../../ProcessDetailSmall/ProcessDetailSmall';
import ProcessPropType from '../../../models/ProcessPropType';
import styles from './styles.module.scss';

const Process = ({ process, index }) => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const dateTag = useSelector(getProcessDateTag(selectedChannelId, index));

  const dispatch = useDispatch();

  const setSelectedProcess = useCallback(() => {
    dispatch(processSelected({ selectedProcess: process }));
  }, [dispatch, process]);

  return (
    <>
      {dateTag && <div className={styles.dateTag}>{dateTag}</div>}
      <div
        className={styles.processDetailWrapper}
        onClick={setSelectedProcess}
      >
        <ProcessDetailSmall process={process} />
      </div>
    </>
  );
};

Process.propTypes = {
  index: PropTypes.number.isRequired,
  process: ProcessPropType.isRequired,
};

export default Process;
