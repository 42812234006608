import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMessageForwardBody } from 'gcs-common/slices/messages/messageSelector';
import QuotedMessage from '../QuotedMessage';
import styles from './styles.module.scss';
import ForwardIcon from '../../../img/forward.svg?react';
import LinkifyBlank from '../../LinkifyBlank/LinkifyBlank';

const ForwardMessage = ({ messageId }) => {

  const forwardBody = useSelector(getMessageForwardBody(messageId));

  return (
    <>
      <div className={styles.forwardHeader}>
        <ForwardIcon className={styles.forwardIcon} />
        Weitergeleitet
      </div>
      <QuotedMessage messageId={messageId} />
      <div className={styles.txtMsgBody}>
        <LinkifyBlank>{forwardBody}</LinkifyBlank>
      </div>
    </>
  );
};

ForwardMessage.propTypes = { messageId: PropTypes.string.isRequired };

export default ForwardMessage;
