import { createSelector } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../../constants/AsyncState';
import { currentPaginationEntities, toPaginationPaginationResult } from '../../../helper/reduxPaginationHelper';
import { toIsLoading } from '../../../helper/reduxLoadingStatusHelper';

export const getAllTenants = (state) => state.tenants.tenants;
export const getSelectedTenantId = (state) => state.tenants.selectedTenantId;

export const getSelectedTenant = createSelector(
  [getSelectedTenantId, getAllTenants],
  (tenantId, tenants) => tenants[tenantId],
);

const getTenantsState = state => state.tenants.fetch || {};

export const getTenants = createSelector(
  [getTenantsState, getAllTenants],
  currentPaginationEntities,
);

export const getTenantsPaginationResult = createSelector(
  [getTenantsState],
  toPaginationPaginationResult,
);

export const getTenantsLoading = createSelector(
  [getTenantsState],
  toIsLoading,
);


export const getSelectedTenantLoading = (state) => (
  state.tenants.fetchTenantDetails.status === ASYNC_STATE.LOADING
);

export const getDeleteTenantLoading = (state) => (
  state.tenants.delete.status === ASYNC_STATE.LOADING
);

export const getUpdateTenantErrorMessage = (state) => (
  state.tenants.update.errorMessage
);

export const getCreateTenantErrorMessage = (state) => (
  state.tenants.create.errorMessage
);

export const getDeleteTenantErrorMessage = (state) => (
  state.tenants.delete.errorMessage
);

export const getTenantSelectOptions = (state) => state.tenants.tenantSelectOptions;
