import { createAsyncThunk } from '@reduxjs/toolkit';
import logoutSuccessEffect from '../../auth/authThunks/logout-effect';

const deleteCurrentUser = createAsyncThunk(
  'currentUser/delete',
  async (_, { extra: { gccApiClient }, rejectWithValue, dispatch }) => {
    const response = await gccApiClient.deleteCurrentUser();
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    dispatch(logoutSuccessEffect());
    return response;
  },
);

export default deleteCurrentUser;
