/* eslint-disable react/prop-types */
/**
 * Table component: https://react-table.tanstack.com/
 * Pagination ("controlled"): https://react-table.tanstack.com/docs/api/usePagination
 * Action-Buttons: https://react-table.tanstack.com/docs/examples/row-selection
 * Filter/Search: https://react-table.tanstack.com/docs/examples/filtering
 */

import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { matchSorter } from 'match-sorter';
import { useGlobalFilter, useTable, useSortBy } from 'react-table';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import TableColumnPropType from '../../models/TableColumnPropType';
import TableDataPropType from '../../models/TableDataPropType';

import sortUp from '../../img/sortUp.svg';
import sortUpLight from '../../img/sortUpLight.svg';
import sortDown from '../../img/sortDown.svg';

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

const Table = ({
  columns,
  data,
  renderActions,
  filterString,
  onRowSelected,
  isLoading = false,
  fixColumnsWidth = false,
}) => {

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
    }),
    [],
  );

  const rowSelected = useCallback(
    (rowData) => () => {
      onRowSelected?.(rowData.original);
    },
    [onRowSelected],
  );

  const defaultSortAccessor = columns.find((elem) => !elem.disableSortBy).accessor;

  const tableSettings = useMemo(() => ({
    columns,
    data,
    filterTypes,
    initialState: {
      sortBy: [
        {
          id: defaultSortAccessor,
        },
      ],
    },
  }), [columns, data, filterTypes, defaultSortAccessor]);

  const tableHooks = useCallback(
    (hooks) => {
      if (renderActions) {
        hooks.visibleColumns.push(cols => [
          ...cols,
          {
            id: 'actions',
            Cell: ({ cell }) => (
              <div className={styles.actionButtons}>
                {renderActions(cell)}
              </div>
            ),
          },
        ]);
      }
    },
    [renderActions],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter,
  } = useTable(tableSettings, tableHooks, useGlobalFilter, useSortBy);

  useEffect(() => {
    setGlobalFilter(filterString);
  }, [filterString, setGlobalFilter]);

  return (
    <div className={styles.tableScroller}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {isLoading && (
              <th className={styles.loader}>
                <LoadingIndicator padding="8px" />
              </th>
              )
            }
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={styles.headerCell}
                  width={fixColumnsWidth ? column.width : undefined}
                >
                  <div className={styles.columnHeader}>
                    {column.render('Header')}
                    <span className={styles.span}>
                      {column.isSortedDesc && (
                        <img
                          alt="sort-icon"
                          className={styles.sortLogo}
                          src={sortDown}
                        />
                      )}
                      {column.isSorted && !column.isSortedDesc && (
                        <img
                          alt="sort-icon"
                          className={styles.sortLogo}
                          src={sortUp}
                        />
                      )}
                      {!column.isSorted && column.canSort && (
                        <img
                          alt="sort-icon"
                          className={styles.sortLogoLight}
                          src={sortUpLight}
                        />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr onClick={rowSelected(row)} {...row.getRowProps()} className={styles.row}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={styles.cell}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {(rows.length < 1 && !isLoading)
      && (
        <div className={styles.emptyMessage}>
          Keine Daten gefunden.
        </div>
      )
      }
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(TableColumnPropType).isRequired,
  data: PropTypes.arrayOf(TableDataPropType).isRequired,
  onRowSelected: PropTypes.func,
  renderActions: PropTypes.func,
  filterString: PropTypes.string,
  isLoading: PropTypes.bool,
  fixColumnsWidth: PropTypes.bool,
};

export default Table;
