import { useRouteError } from 'react-router';
import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import FullScreenErrorDialog from '../FullScreenErrorDialog/FullScreenErrorDialog';

function ErrorBoundaryFallback() {
  const routeError = useRouteError() as Error;

  useEffect(() => {
    captureException(routeError, { level: 'fatal' });
  }, [routeError]);

  return (
    <FullScreenErrorDialog dialogErrorMsg={routeError?.message} />
  );
}

ErrorBoundaryFallback.propTypes = {};

export default ErrorBoundaryFallback;
