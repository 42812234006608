import styles from './styles.module.scss';
import CubeIcon from '../../img/cube_primary.svg?react';

interface LoadingIndicatorProps {
  onLoad?: VoidFunction;
  padding?: string;
  className?: string
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const { onLoad, padding = '15px', className } = props;

  return (
    <div data-testid="loadingIndicator" style={{ padding }} className={`${styles.loadingWrapper} ${className}`}>
      <CubeIcon className={styles.loader} onLoad={onLoad} />
    </div>
  );
};

export default LoadingIndicator;
