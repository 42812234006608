import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUserId } from '../currentUserSelectors';
import { createFileFromUrl } from '../../../helper/fileHelper';
import { openErrorDialog } from '../../errorDialogSlice/errorDialogSlice';
import { updateCurrentUser } from './updateCurrentUser';

const updateProfilePicture = createAsyncThunk(
  'updateProfilePicture',
  async ({ image, type }, { _, dispatch, getState, extra: { gccApiClient } }) => {
    const currentUserId = getCurrentUserId(getState());
    const fileName = `${currentUserId}_${Date.now()}.png`;
    const file = await createFileFromUrl(image, fileName, type);
    try {
      const {
        preSignedUrl,
        imageUrl: newImageUrl,
      } = await gccApiClient.getPresignedUrl(fileName);
      const response = await fetch(preSignedUrl, {
        method: 'PUT',
        body: file,
      });

      if (response.ok) {
        const encoded = encodeURI(newImageUrl);
        dispatch(updateCurrentUser({ values: { image_url: encoded } }));
      }
    } catch (e) {
      dispatch(openErrorDialog({ dialogErrorMsg: 'Fehler beim Ändern des Profilfotos' }));
    }
  },
);

export default updateProfilePicture;
