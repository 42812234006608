/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key,jsx-a11y/interactive-supports-focus */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import {
  searchInputUpdated, resetProcessFilter,
} from 'gcs-common/slices/processes/processesSlice';
import suggestionsReset from 'gcs-common/slices/processes/processesActions/suggestionsReset';
import {
  getFilter,
  getProcessSearchInput,
} from 'gcs-common/slices/processes/processesSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelOPUserId,
} from 'gcs-common/slices/channel/channelSelectors';
import fetchProcessesFilterSuggestions from 'gcs-common/slices/processes/processesThunks/fetchProcessesFilterSuggestions';
import fetchProcesses from 'gcs-common/slices/processes/processesThunks/fetchProcesses';
import SearchBar from '../../SearchBar/SearchBar';

const ProcessSearch = () => {
  const dispatch = useDispatch();
  const filter = useSelector(getFilter);
  const searchInput = useSelector(getProcessSearchInput);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const gcopUserId = useSelector(getChannelOPUserId(selectedChannelId));

  const [searchSuggestionsDebounced] = useDebouncedCallback(
    (value) => {
      dispatch(fetchProcessesFilterSuggestions({ id: gcopUserId,
        channelId: selectedChannelId,
        queryString: value }));
    },
    600,
  );

  const onSearchInputChange = useCallback(($event) => {
    const { value } = $event.target;
    dispatch(searchInputUpdated({ searchInput: value }));
    if (value.length > 0) {
      searchSuggestionsDebounced(value);
    } else {
      dispatch(suggestionsReset());
    }
  }, [dispatch, searchSuggestionsDebounced]);

  const resetFilter = useCallback(async () => {
    dispatch(resetProcessFilter());
    dispatch(fetchProcesses({ page: 1,
      id: gcopUserId,
      channelId: selectedChannelId,
      append: false }));
  }, [dispatch, gcopUserId, selectedChannelId]);

  return (
    <SearchBar
      searchInput={searchInput}
      filter={filter}
      onSearchInputChange={onSearchInputChange}
      resetFilter={resetFilter}
      placeholder="Vorgangsnummer, Erfassungsdatum, Auftragsnummer oder -text"
    />
  );

};
export default ProcessSearch;
