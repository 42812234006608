import styles from './styles.module.scss';
import CubeIcon from '../../../img/cube_primary.svg?react';

const VideoProcessing = () => {

  return (
    <>
      <div className={styles.loaderContainer}>
        <CubeIcon alt="loading-indicator" className={styles.videoLoader} />
      </div>
      <h4>Video In Bearbeitung</h4>
    </>
  );

};

export default VideoProcessing;

VideoProcessing.propTypes = {
};
