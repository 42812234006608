/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  useState, useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import {
  fetchAgentAvailabilityStatus,
  updateAgentAvailabilityStatus,
} from 'gcs-common/slices/currentAgent/currentAgentThunks';
import { ICON } from 'gcs-common/constants/IconConstants';
import { useOnClickOutside } from 'usehooks-ts';
import styles from './styles.module.scss';
import IconComponent from '../IconComponent/IconComponent';

const AWAY_TYPE = {
  AWAY: 'ABWESENHEIT',
  LATE_SHIFT: 'SPÄTSCHICHT',
};

const TEXT_LIMIT = 200;

const AgentAvailabilityMessageInput = ({ statusType }) => {
  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [isActive, setIsActive] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    (async () => {
      // TODO: REFACTOR intermediary solution
      const { payload: { status } } = await dispatch(fetchAgentAvailabilityStatus({ statusType }));
      setText(status);
    })();
  }, [dispatch, statusType]);

  const [updateRemoteStatusDebounced] = useDebouncedCallback(
    async (newStatus) => {
      dispatch(updateAgentAvailabilityStatus({ status: newStatus, statusType }));
    },
    1000,
  );

  const trimText = (value) => {
    return value.replace(/[\n\s]+$/, '');
  };

  const onChange = useCallback((value) => {
    setText(value);
  }, []);

  const updateMessage = useCallback(() => {
    setIsActive(!isActive);
    const trimmedText = trimText(text);
    if (trimmedText.length <= 200) {
      setText(trimmedText);
      updateRemoteStatusDebounced(trimmedText);
    }
  }, [updateRemoteStatusDebounced, isActive, text]);

  const clickedOutside = useCallback(() => {
    updateMessage();
  }, [updateMessage]);

  useOnClickOutside(
    ref,
    clickedOutside,
  );

  return (
    <div ref={ref} className={styles.agentAwayStatus}>
      <div className={styles.statusHeader}>
        <div className={styles.mainHeaderText}>
          {`NOTIZ FÜR ${AWAY_TYPE[statusType]}`}
        </div>
        <button type="button" className={styles.editButton} onClick={updateMessage}>
          {!isActive
            ? (
              <IconComponent
                Icon={ICON.EDIT}
                alt="Edit"
              />
            )
            : (
              <IconComponent
                Icon={ICON.CHECKMARK}
                alt="Send"
              />
            )
          }
        </button>
      </div>
      <div style={{ position: 'relative' }}>
        <textarea
          placeholder="Schreibe etwas"
          rows="4"
          cols="60"
          value={text}
          className={`${styles.statusText}${isActive ? ` ${styles.statusTextActive}` : ''}`}
          onChange={($event) => onChange($event.target.value)}
          disabled={!isActive}
        />
        {isActive
        && (
          <div className={styles.bottomStatus}>
            {`${text.length}/${TEXT_LIMIT}`}
          </div>
        )
        }
      </div>
    </div>
  );
};

AgentAvailabilityMessageInput.propTypes = {
  statusType: PropTypes.string.isRequired,
};

export default AgentAvailabilityMessageInput;
