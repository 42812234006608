import { getMessageMediaRelativePath } from '../messageSelector';
import { openMediaFullscreen } from '../../uiState/uiStateSlice';
import preloadImage from '../../../helper/preloadImage';
import { calculateMessageMediaURL } from '../../../helper/mediaHelper';

const openMessageMediaFullscreen = ({
  messageId,
}) => async (dispatch, getState) => {
  const mediaFileName = getMessageMediaRelativePath(messageId)(getState());
  const mediaFullscreenUrl = calculateMessageMediaURL(mediaFileName);
  await preloadImage(mediaFullscreenUrl);
  dispatch(openMediaFullscreen({ mediaFullscreenUrl }));
};

export default openMessageMediaFullscreen;
