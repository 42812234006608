import VideoFileIcon from 'gcs-common/img/video_file.svg?react';
import styles from './styles.module.scss';

const VideoLocalThumbnail = () => {
  return (
    <div className={styles.videoThumbnail}>
      <VideoFileIcon alt="video-indicator" className={styles.icon} />
    </div>
  );
};

export default VideoLocalThumbnail;

VideoLocalThumbnail.propTypes = {
};
