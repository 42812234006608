/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  createErpCustomerAssignment, deleteErpCustomerAssignment,
  fetchErpCustomerAssignments, updateErpCustomerAssignment,
} from './erpCustomerAssignmentsThunks';
import ASYNC_STATE from '../../../constants/AsyncState';
import { getErrorMsg } from '../../../helper/errorMessages';


const asyncState = {
  fetchErpCustomerAssignments: {
    status: ASYNC_STATE.IDLE,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  update: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  delete: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  communicationAssignments: [],
};

const erpCustomerCommunicationAssignmentSlice = createSlice({
  name: 'erpCustomerAssignments',
  initialState,
  reducers: {
    resetCreateCustomerAssignmentDefaultErrorMessage: (state) => {
      state.create.defaultErrorMessage = null;
    },
    resetDeleteCustomerAssignmentDefaultErrorMessage: (state) => {
      state.delete.defaultErrorMessage = null;
    },
    resetUpdateCustomerAssignmentDefaultErrorMessage: (state) => {
      state.update.defaultErrorMessage = null;
    },
  },
  extraReducers: {
    [fetchErpCustomerAssignments.pending]: (state) => {
      state.fetchErpCustomerAssignments.status = ASYNC_STATE.LOADING;
    },
    [fetchErpCustomerAssignments.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.communicationAssignments = data;
      state.fetchErpCustomerAssignments.status = ASYNC_STATE.SUCCEEDED;
    },
    [fetchErpCustomerAssignments.rejected]: (state) => {
      state.fetchErpCustomerAssignments.status = ASYNC_STATE.FAILED;
    },
    [createErpCustomerAssignment.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
    },
    [createErpCustomerAssignment.fulfilled]: (state) => {
      state.create.status = ASYNC_STATE.SUCCEEDED;
    },
    [createErpCustomerAssignment.rejected]: (state) => {
      state.create.status = ASYNC_STATE.FAILED;
      state.create.defaultErrorMessage = getErrorMsg('default');
    },
    [updateErpCustomerAssignment.pending]: (state) => {
      state.update.status = ASYNC_STATE.LOADING;
    },
    [updateErpCustomerAssignment.fulfilled]: (state) => {
      state.update.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateErpCustomerAssignment.rejected]: (state) => {
      state.update.status = ASYNC_STATE.FAILED;
      state.update.defaultErrorMessage = getErrorMsg('default');
    },
    [deleteErpCustomerAssignment.pending]: (state) => {
      state.delete.status = ASYNC_STATE.LOADING;
    },
    [deleteErpCustomerAssignment.fulfilled]: (state) => {
      state.delete.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteErpCustomerAssignment.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.delete.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'delete_communication_assignment.default_assignment_delete') {
        state.delete.errorMessage = getErrorMsg('delete_communication_assignment.default_assignment_delete');
      } else {
        state.delete.errorMessage = getErrorMsg('delete_communication_assignment.default');
      }
    },
  },
});

export const {
  resetCreateCustomerAssignmentDefaultErrorMessage,
  resetDeleteCustomerAssignmentDefaultErrorMessage,
  resetUpdateCustomerAssignmentDefaultErrorMessage,
} = erpCustomerCommunicationAssignmentSlice.actions;

export default erpCustomerCommunicationAssignmentSlice.reducer;
