const CHANNEL_TYPE = {
  SALES_SPACE: 'sales_space',
  DIRECT_CHAT: 'direct_chat',
  INTERNAL_CRAFTSMAN: 'internal_craftsman',
  INTERNAL_AGENT: 'internal_agent',
};

export const ALL_CHANNEL_TYPES = Object.values(CHANNEL_TYPE);

const CHANNEL_FILTER_TYPES = {
  TEAM_CHATS: 'team_chats',
  DIRECT_CHATS: 'direct_chats',
};

const calculateChannelTypeForChannelFilter = (channelFilter) => {
  switch (channelFilter) {
    case CHANNEL_FILTER_TYPES.TEAM_CHATS:
      return [
        CHANNEL_TYPE.SALES_SPACE,
      ];
    case CHANNEL_FILTER_TYPES.DIRECT_CHATS:
      return [
        CHANNEL_TYPE.DIRECT_CHAT,
        CHANNEL_TYPE.INTERNAL_CRAFTSMAN,
        CHANNEL_TYPE.INTERNAL_AGENT,
      ];
    default:
      return [];
  }
};

const GroupChannelTypeInfo = {
  intern: {
    name: 'Mitarbeiter-Gruppe',
    color: '#42921f',
  },
  extern: {
    name: 'Kunden-Gruppe',
    color: '#d89f3c',
  },
};

const ChannelTypeInfo = {
  [CHANNEL_TYPE.DIRECT_CHAT]: {
    name: 'Direkt Chat',
    color: '#971c58',
  },
  [CHANNEL_TYPE.SALES_SPACE]: {
    name: 'Team Chat',
    color: '#3168c1',
  },
  [CHANNEL_TYPE.INTERNAL_AGENT]: {
    name: 'Intern Agent',
    color: '#42921f',
  },
  [CHANNEL_TYPE.INTERNAL_CRAFTSMAN]: {
    name: 'Intern Handwerker',
    color: '#42921f',
  },
};

export const CHANNEL_BRANDS = {
  CRAFTNOTE: 'craftnote',
  GC: 'GC',
  'G.U.T.': 'G.U.T.',
  EFG: 'EFG',
  HTI: 'HTI',
  ITG: 'ITG',
  BEEM: 'BEEM',
  DTG: 'DTG',
};


export {
  CHANNEL_TYPE,
  CHANNEL_FILTER_TYPES,
  ChannelTypeInfo,
  GroupChannelTypeInfo,
  calculateChannelTypeForChannelFilter,
};
