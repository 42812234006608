import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  globalSearchInputReset,
  globalSearchInputUpdated,
} from 'gcs-common/slices/administration/globalSearch/globalSearchSlice';
import SearchBar from '../SearchBar/SearchBar';
import HouseSelectBar from '../HouseSelectBar/HouseSelectBar';

const AdministrationSearchBar = () => {

  const dispatch = useDispatch();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [onSearchInputChangedDebounced, cancel] = useDebouncedCallback(
    (input) => dispatch(globalSearchInputUpdated({ globalSearchInput: input })),
    500,
  );

  const onCancelSearch = useCallback(() => {
    dispatch(globalSearchInputReset());
    setSearchInputValue('');
    cancel();
  }, [cancel, dispatch]);

  const onSearch = useCallback((event) => {
    const input = event.target.value;
    setSearchInputValue(input);
    onSearchInputChangedDebounced(input);
  }, [onSearchInputChangedDebounced]);

  const { pathname } = useLocation();
  const page = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length);

  const showSearchBar = (
    page === 'companies'
      || page === 'users'
      || page === 'teams'
      || page === 'tenants'
      || page === 'business_units'
      || page === 'houses'
      || page === 'responsibilities'
      || page === 'erpCustomers'
  );

  const showHouseSelectBar = (
    page === 'erpCustomers'
      || page === 'teams'
      || page === 'users'
      || page === 'responsibilities'
  );

  const [currentPathname, setCurrentPathname] = useState(pathname);

  useEffect(() => {
    if (pathname !== currentPathname) {
      onCancelSearch();
      setCurrentPathname(pathname);
    }
  }, [pathname, currentPathname, onCancelSearch]);

  return (
    <>
      {showSearchBar && (
        <SearchBar
          onSearchInputChange={onSearch}
          resetFilter={onCancelSearch}
          searchInput={searchInputValue}
          placeholder="Suche..."
        />
      )}
      {showHouseSelectBar && (
      <HouseSelectBar
        resetSearchFilter={onCancelSearch}
      />
      )}
    </>
  );

};

export default AdministrationSearchBar;
