import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveHouseId } from '../houses/housesSelectors';
import { CHANNEL_TYPE } from '../../../helper/channelTypeHelper';

export const fetchErpCustomers = createAsyncThunk(
  'erpCustomers/fetchErpCustomers',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, view,
  } = { pageIndex: 0, pageSize: 10 }, { getState, rejectWithValue, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const response = await gccApiClient.getErpCustomers(
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, activeHouseId, view,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination };
  },
);

export const fetchSelectedErpCustomer = createAsyncThunk(
  'erpCustomers/fetchSelected',
  async ({ erpCustomerId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getErpCustomer(erpCustomerId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { erpCustomer: response };
  },
);

export const fetchSelectedErpCustomers = createAsyncThunk(
  'erpCustomers/fetchSelectedErpCustomers',
  async ({ erpCustomerIds }, { extra: { gccApiClient } }) => {
    const erpCustomers = await Promise.all(erpCustomerIds.map(async erpCustomerId => {
      const result = await gccApiClient.getErpCustomer(erpCustomerId);
      return result;
    }));
    return erpCustomers;
  },
);

export const updateErpCustomer = createAsyncThunk(
  'erpCustomers/updateSelected',
  async ({ erpCustomerId, values }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateErpCustomer(erpCustomerId, values);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code, description: response.description });
    }
    return { erpCustomer: response };
  },
);

export const createErpCustomer = createAsyncThunk(
  'erpCustomers/create',
  async ({ erpCustomer }, { rejectWithValue, dispatch, getState, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const response = await gccApiClient.createErpCustomer(erpCustomer, activeHouseId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code, description: response.description });
    }
    await dispatch(fetchErpCustomers());
    return { erpCustomer: response };
  },
);

export const deleteErpCustomer = createAsyncThunk(
  'erpCustomers/delete',
  async ({ erpCustomerId }, { rejectWithValue, dispatch, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteErpCustomer(erpCustomerId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchErpCustomers());
    return response;
  },
);

// TODO refactor according to new channel types
export const fetchErpCustomerDirectChannels = createAsyncThunk(
  'erpCustomers/fetchDirectChannels',
  async ({ erpCustomerId, channelTypes = [CHANNEL_TYPE.DIRECT_CHAT] },
    { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getErpCustomerChannels(erpCustomerId, channelTypes);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { channels: response.channels };
  },
);

// TODO refactor according to new channel types
export const fetchErpCustomerGroupChannels = createAsyncThunk(
  'erpCustomers/fetchGlobalGroupChannelsSelection',
  async ({ erpCustomerId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const channelTypes = [CHANNEL_TYPE.SALES_SPACE];
    const response = await gccApiClient.getErpCustomerChannels(erpCustomerId, channelTypes);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { channels: response.channels };
  },
);

export const fetchErpCustomerUsers = createAsyncThunk(
  'erpCustomers/fetchUsers',
  async ({ erpCustomerId }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getErpCustomerUsers(erpCustomerId);
    return { users: response.data };
  },
);

// TODO refactor according to new channel types
export const createAgentToCraftsmanDirectChannel = createAsyncThunk(
  'erpCustomers/createAgentToCraftsmanDirectChannel',
  async (
    { agentId, userId1, erpCustomerId },
    { rejectWithValue, dispatch, getState, extra: { gccApiClient } },
  ) => {
    const activeHouseId = getActiveHouseId(getState());
    const channelType = CHANNEL_TYPE.DIRECT_CHAT;
    const response = await gccApiClient.createChannel(
      [agentId, userId1], activeHouseId, channelType,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // Note: Timeout to be sure that the changes are reflected in twilio mirror
    setTimeout(() => {
      dispatch(fetchErpCustomerDirectChannels({ erpCustomerId }));
    }, 1000);
    return { success: response };
  },
);

// TODO refactor according to new channel types
export const createCraftsmanToCraftsmanDirectChannel = createAsyncThunk(
  'erpCustomers/createCraftsmanToCraftsmanDirectChannel',
  async (
    { userId1, userId2, erpCustomerId },
    { rejectWithValue, dispatch, getState, extra: { gccApiClient } },
  ) => {
    const activeHouseId = getActiveHouseId(getState());
    const channelType = CHANNEL_TYPE.INTERNAL_CRAFTSMAN;
    const response = await gccApiClient.createChannel(
      [userId1, userId2], activeHouseId, channelType,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // Note: Timeout to be sure that the changes are reflected in twilio mirror
    setTimeout(() => {
      dispatch(fetchErpCustomerDirectChannels({ erpCustomerId }));
    }, 1000);
    return { success: response };
  },
);

export const createErpCustomerUser = createAsyncThunk(
  'erpCustomers/createErpCustomerUser',
  async (
    { user, erpCustomerId },
    { rejectWithValue, extra: { gccApiClient } },
  ) => {
    const response = await gccApiClient.createConnectionMember(user, erpCustomerId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { success: response };
  },
);

export const deleteErpCustomerUser = createAsyncThunk(
  'erpCustomers/deleteErpCustomerUser',
  async ({ userId, erpCustomerId }, { rejectWithValue, dispatch, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteErpCustomerUser(userId, erpCustomerId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchErpCustomerUsers({ erpCustomerId }));
    return response;
  },
);
