/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadProgress: {},
  videoIsDownloading: false,
};

const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    updateVideoUploadProgress: (state, action) => {
      const { videoId, percentage } = action.payload;
      state.uploadProgress[videoId] = percentage;
    },
    startVideoIsDownloading: (state) => {
      state.videoIsDownloading = true;
    },
    stopVideoIsDownloading: (state) => {
      state.videoIsDownloading = false;
    },
  },
});

export const {
  updateVideoUploadProgress,
  startVideoIsDownloading,
  stopVideoIsDownloading,
} = videosSlice.actions;

export default videosSlice.reducer;
