import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';

const ErpCustomerMigratePopup = ({ agentName, onCancel, onSuccess }) => {

  return (
    <div className={styles.column}>
      Möchten Sie wirklich den Ansprechpartner ändern und die bestehenden Kundenchat zu
      {' "'}
      {agentName}
      {'" '}
      übertragen?”
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={onSuccess}
        >
          Ja
        </Button>
      </div>
    </div>
  );
};

ErpCustomerMigratePopup.propTypes = {
  agentName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ErpCustomerMigratePopup;
