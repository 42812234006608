import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../../helper/errorMessages';
import fetchCurrentUser from '../../currentUser/currentUserThunks/fetchCurrentUser';
import fetchJoinedHouses from '../../houses/housesThunks/fetchJoinedHouses';

// TODO: We won't need this file

const linkAccount = createAsyncThunk(
  'settings/linkAccount',
  async (
    { brand, username, password },
    { dispatch, extra: { gccApiClient }, rejectWithValue },
  ) => {
    if (!username) {
      return rejectWithValue({ errorMessage: 'Kein Benutzername eingegeben' });
    } if (!password) {
      return rejectWithValue({ errorMessage: 'Kein Passwort eingegeben' });
    }

    const res = await gccApiClient.addCraftsmanConnection(username, password, brand);
    await dispatch(fetchCurrentUser());
    await dispatch(fetchJoinedHouses());
    const {
      error_code: errorCode, description,
    } = res;
    let errorMessage = null;
    if (errorCode) {
      // Note: We first try to map our error_code
      // to frontend error message, otherwise
      // try to get gcop error from description
      errorMessage = getErrorMsg(
        errorCode,
        brand,
        brand,
        brand.toLowerCase(),
      ) || description;
      return rejectWithValue({ errorMessage });
    }
    return undefined;
  },
);

export default linkAccount;
