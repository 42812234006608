import { useCallback, useEffect } from 'react';
import { isFirefox, isWindows } from 'react-device-detect';
import { createFileFromUrl } from 'gcs-common/helper/fileHelper';

const V_KEY = 86;

/*
  Firefox issue

  - on Windows & firefox the clipboard does not contain any file
  - the only way to receive one single image from the clipboard
  is using a div which is editable, in this div firefox allows
  to paste 1 single image
  - on Mac & Firefox it's working with the paste event but only when copied 1 file
  - in Firefox only images can be pasted

  Why this Key Pressed / Released

  - well, in case that our final paste component is not a div
  than we have to create one on firefox and using this key pressed
  we will focus on this div and on key released we should be able
  to read the image, then we can do what we want with that image.

  Unfortunately for firefox seems to be the only solution for the moment.

  Issue: if any input field is active on firefox the paste image doesn't work

*/

export default function usePasteMedia(firefoxDivRef, onPasteMedia) {

  const pasteMedia = useCallback((event) => {
    const clipboardDataItems = event?.clipboardData?.items;
    const files = [];
    Object.entries(clipboardDataItems).forEach(([_, item]) => {
      if (item.kind === 'file') {
        event.preventDefault();
        const file = item.getAsFile();
        if (file) {
          files.push(file);
        }
      }
    });

    onPasteMedia(files);

  }, [onPasteMedia]);

  const mozillaKeyPressed = useCallback((event) => {
    if (event.ctrlKey && event.keyCode === V_KEY) {
      const currentActiveElement = document.activeElement;
      firefoxDivRef.current.focus();
      const pastedImage = firefoxDivRef.current.getElementsByTagName('img')[0];
      if (!pastedImage) {
        currentActiveElement.focus();
      }
    }
  }, [firefoxDivRef]);

  const mozillaKeyReleased = useCallback(async (event) => {
    if (event.ctrlKey && event.keyCode === V_KEY) {
      const pastedImage = firefoxDivRef.current.getElementsByTagName('img')[0];
      if (pastedImage) {
        const file = await createFileFromUrl(pastedImage.src, 'media', 'image/jpeg');
        onPasteMedia([file]);
        firefoxDivRef.current.removeChild(firefoxDivRef.current.firstChild);
      }
    }
  }, [onPasteMedia, firefoxDivRef]);

  useEffect(() => {
    if (isFirefox && isWindows) {
      document.addEventListener('keydown', mozillaKeyPressed, false);
      document.addEventListener('keyup', mozillaKeyReleased, false);
    } else {
      document.addEventListener('paste', pasteMedia, false);
    }

    return () => {
      if (isFirefox && isWindows) {
        document.removeEventListener('keydown', mozillaKeyPressed);
        document.removeEventListener('keyup', mozillaKeyReleased);
      } else {
        document.removeEventListener('paste', pasteMedia);
      }
    };
  }, [pasteMedia, mozillaKeyPressed, mozillaKeyReleased]);


}
