import Select from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateFeatureFlag } from 'gcs-common/slices/administration/featureFlags/featureFlagsThunks';
import { getTenantSelectOptions } from 'gcs-common/slices/administration/tenants/tenantsSelectors';
import EditingCell from '../EditingCell/EditingCell';

const FeatureFlagTenantsCell = ({ cell, value: tenants }) => {

  const [allTenantOptions, setAllTenantOptions] = useState([]);
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [options, setOptions] = useState([]);

  const tenantSelectOptions = useSelector(getTenantSelectOptions);

  const featureFlagId = cell.row.original.id;

  useEffect(() => {
    const fetchOptions = async () => {
      const formattedOptions = tenantSelectOptions?.map(tenant => ({
        label: tenant.friendlyName,
        value: tenant.id,
      }));
      setAllTenantOptions(formattedOptions);
    };

    fetchOptions();
  }, [dispatch, tenantSelectOptions]);

  const setInitialOptions = useCallback(() => {
    const initialOptions = tenants
      .map((tenant) => ({ label: tenant.friendlyName, value: tenant.id }));
    setOptions(initialOptions);
  }, [tenants]);

  useEffect(() => {
    setInitialOptions();
  }, [setInitialOptions]);

  const save = useCallback(async () => {
    const tenantIds = options?.map((option) => option.value) || [];
    await dispatch(updateFeatureFlag({ featureFlagId, tenantIds }));
  }, [dispatch, featureFlagId, options]);

  const cancel = useCallback(() => {
    setInitialOptions();
  }, [setInitialOptions]);

  return (
    <EditingCell
      onSave={save}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      onCancel={cancel}
    >
      <Select
        isMulti
        options={allTenantOptions}
        isLoading={!allTenantOptions.length}
        isDisabled={!isEditing}
        value={options}
        onChange={(selected) => {
          setOptions(selected);
        }}
        styles={{ container: (base) => ({ ...base, minWidth: '300px' }) }}
      />

    </EditingCell>
  );
};

FeatureFlagTenantsCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      friendlyName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FeatureFlagTenantsCell;
