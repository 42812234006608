import styles from './styles.module.scss';

const TypingIndicator = () => {
  return (
    <li className={styles.msg}>
      <div className={styles.msgContent}>
        <div className={styles.spinner}>
          <div className={styles.bounce1} />
          <div className={styles.bounce2} />
          <div />
        </div>
      </div>
    </li>
  );
};

export default TypingIndicator;
