// single source of truth for themes
export const THEME = {
  AGENT: 'agent',
  ITG: 'itg',
  HTI: 'hti',
  GC: 'gc',
  GUT: 'gut',
  EFG: 'efg',
  DTG: 'dtg',
  TFG: 'tfg',
  HFH: 'hfh',
} as const;
