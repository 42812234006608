import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, FormikProps, useFormikContext } from 'formik';
import { NativeBiometric } from 'capacitor-native-biometric';
import { captureException } from '@sentry/react';
import OrderButton from '../OrderButton/OrderButton';
import styles from './styles.module.scss';
import { getOpLogo } from '../../slices/theme/themeSelectors';
import PulseLoader from '../PulseLoader/PulseLoader';
import { getIsMobile, getIsNative } from '../../slices/init/initSelectors';
// eslint-disable-next-line import/no-cycle
import { QuickOrderSubmitFormInput } from '../CheckoutAuthentication/CheckoutAuthentication';
import { SubmitOrderMutation } from '../../clients/api/entities/order-api';

export const opCredentialIdentifier = 'quick-order-op-credentials';

const isBiometricAvailable = async () => {
  const result = await NativeBiometric.isAvailable({ useFallback: true });
  return result.isAvailable;
};

export const hasBiometricCredentials = async (identifier : string) => {
  try {
    await NativeBiometric.getCredentials({
      server: identifier,
    });
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
};


const performBiometricVerificationForOp = async () => {
  const isAvailable = await isBiometricAvailable();
  const hasCredentials = await hasBiometricCredentials(opCredentialIdentifier);

  if (!isAvailable || !hasCredentials) {
    return undefined;
  }

  const verified = await NativeBiometric.verifyIdentity({
    reason: 'Zugang zu deinem ONLINE PLUS Konto',
    title: 'Biometrische Authentifizierung',
    subtitle: 'Nutze Face-ID / Touch-ID um deine Online Plus Zugangsdaten abzurufen',
    maxAttempts: 5,
    useFallback: true,
    negativeButtonText: 'Zugangsdaten manuell eingeben',

  }).then(() => true)
    .catch(() => false);

  if (!verified) {
    return undefined;
  }

  return NativeBiometric.getCredentials({
    server: opCredentialIdentifier,
  });
};

export const setBiometricCredentialsForOp = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const isAvailable = await isBiometricAvailable();
  if (!isAvailable) {
    return;
  }

  // delete necessary for ios
  await NativeBiometric.deleteCredentials({
    server: opCredentialIdentifier,
  });

  NativeBiometric.setCredentials({
    username,
    password,
    server: opCredentialIdentifier,
  });
};

type CheckoutOPLoginFormProps = FormikProps<QuickOrderSubmitFormInput> & {
  submitOrderMutation: SubmitOrderMutation
};

const CheckoutOPLoginForm = ({ isValid, submitOrderMutation } : CheckoutOPLoginFormProps) => {
  const opLogo = useSelector(getOpLogo);
  const [showBiometricCheckbox, setShowBiometricCheckbox] = useState(false);
  const isMobile = useSelector(getIsMobile);
  const isNative = useSelector(getIsNative);

  const { setValues } = useFormikContext();

  useEffect(() => {
    (async () => {
      if (!isMobile || !isNative) return;
      const canUseBiometric = await isBiometricAvailable();
      const hasCredentials = await hasBiometricCredentials(opCredentialIdentifier);
      setShowBiometricCheckbox(canUseBiometric && !hasCredentials);

      const credentials = await performBiometricVerificationForOp();
      if (!credentials?.username || !credentials?.password) return;
      setValues({
        username: credentials.username,
        password: credentials.password,
      });

    })();
  }, [isMobile, isNative, setValues]);

  const { isPending, error } = submitOrderMutation;

  return (
    <Form className={styles.form}>
      <img
        alt="ONLINE PLUS Logo"
        className={styles.opLogo}
        src={opLogo}
      />
      <div className={styles.h2}>
        Fast Geschafft! Loggen Sie sich mit Ihrem
        ONLINE PLUS Konto ein, um diese Bestellung abzuschließen
      </div>
      <div className={styles.inputWrapper}>
        <Field
          name="username"
          className={styles.input}
          placeholder="Benutzer"
        />
      </div>
      <div className={styles.inputWrapper}>
        <Field
          name="password"
          className={styles.input}
          type="password"
          placeholder="Passwort"
        />
      </div>
      {showBiometricCheckbox && (
        <div className={styles.faceId}>
          <Field
            name="faceId"
            id="faceIdCheck"
            className={styles.faceIdCheckbox}
            type="checkbox"
          />
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
          <label htmlFor="faceIdCheck">
            In Zukunft Face-ID / Touch-ID zum Bestellen verwenden
          </label>
        </div>
      )}
      {error && (
        <div className={styles.error}>
          {error?.message}
        </div>
      )}
      <OrderButton
        type="submit"
        disabled={!isValid}
      >
        {isPending && (
        <PulseLoader />
        )}
        Bestellung Abschließen
      </OrderButton>
    </Form>

  );
};

export default CheckoutOPLoginForm;
