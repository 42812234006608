import { useSelector } from 'react-redux';
import {
  getCustomerResponsibilitiesMembersPaginationResult,
  getCustomerResponsibilitiesMembersStateLoading,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSelector';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import CustomerResponsibilityMembersTable from '../CustomerResponsibilityMembersTable/CustomerResponsibilityMembersTable';

const CustomerResponsibilityMembersOverview = () => {

  // eslint-disable-next-line max-len
  const customerResponsibilityMembersLoading = useSelector(getCustomerResponsibilitiesMembersStateLoading);
  // eslint-disable-next-line max-len
  const customerResponsibilityMembersPagination = useSelector(getCustomerResponsibilitiesMembersPaginationResult);

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.inputHeaderText}>
          Verantwortlichkeiten
          {' '}
          (
          {customerResponsibilityMembersPagination && customerResponsibilityMembersPagination.total}
          )
        </div>
        {customerResponsibilityMembersLoading && <LoadingIndicator padding="0" />}
      </div>
      <CustomerResponsibilityMembersTable filter={[]} />
    </>
  );
};

export default CustomerResponsibilityMembersOverview;
