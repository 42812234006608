import PropTypes from 'prop-types';

const escapeRegExp = (str = '') => (
  str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
);

const Highlight = ({ search = '', children = '' }) => {
  let patt;
  if (Array.isArray(search)) {
    const searchArrayReg = search.map(s => `(${escapeRegExp(s)})`).join('|');
    patt = new RegExp(`${searchArrayReg}`, 'i');
  } else {
    patt = new RegExp(`(${escapeRegExp(search)})`, 'i');
  }

  const parts = String(children).split(patt);

  if (search) {
    return parts.map((part, index) => (
      // eslint-disable-next-line react/no-array-index-key
      patt.test(part) ? <mark key={index + part}>{part}</mark> : part
    ));
  }
  return children;
};

Highlight.propTypes = {
  search: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.arrayOf(PropTypes.string)],
  ).isRequired,
};

export default Highlight;
