import { toast } from 'react-toastify';
import { IconToast } from '../../components/AdministrationWrapper/shared/IconToast/IconToast';
import checkmarkIcon from '../../img/White_green_checkmark.svg';

export const showSuccessNotification = (message) => () => {
  toast.success(<IconToast message={message} icon={checkmarkIcon} type="success" />, {
    autoClose: 3000,
    hideProgressBar: true,
  });
};

export const showDisabledNotification = (message) => () => {
  toast.info(message, {
    autoClose: 3000,
    hideProgressBar: true,
  });
};

export const showFailureNotification = (message, callbackFunc) => () => {
  toast.error(message, {
    autoClose: 5000,
    onOpen: () => callbackFunc,
  });
};
