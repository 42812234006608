import { useSelector } from 'react-redux';
import { getSidebarCollapsed } from 'gcs-common/slices/uiState/uiStateSelectors';
import styles from './styles.module.scss';
import AgentChannelListNormal from '../AgentChannelListNormal/AgentChannelListNormal';
import AgentChannelListCollapsed from '../AgentChannelListCollapsed/AgentChannelListCollapsed';

function AgentChannelListWrapper() {

  const sidebarCollapsed = useSelector(getSidebarCollapsed);

  return (
    <div className={styles.channelListWrapper}>
      {sidebarCollapsed ? (
        <AgentChannelListCollapsed />
      ) : (
        <AgentChannelListNormal />
      )}
    </div>
  );
}

export default AgentChannelListWrapper;

AgentChannelListWrapper.propTypes = {};
