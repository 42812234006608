/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { LEFT_BAR_TABS, RIGHT_BAR_TABS } from '../../constants/uiFlags';
import { TOOL_TABS, TOOLS } from '../../constants/toolUIFlags';


const initialState = {
  leftBarTab: LEFT_BAR_TABS.SIDEBAR,
  rightBarTab: RIGHT_BAR_TABS.TOOLS,
  sidebarCollapsed: false,
  toolbarTab: TOOL_TABS.GCOP,
  tools: TOOLS,
  showChannelEmailPopup: false,
  uploadFileError: '',
  mediaFullscreenUrl: undefined,
  videoFullScreenId: undefined,
  showChangeEditImageModal: false,
  profileImageForEditing: '',
  teamchatImageForEditing: '',
  teamchatPicture: '',
  showWebcamModal: false,
};

// TODO: This slice can be deleted because it is
//  overridden by the respective slices in web / mobile?
const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    setLeftBarTab: (state, action) => {
      const { leftBarTab } = action.payload;
      state.leftBarTab = leftBarTab;
    },
    setRightBarTab: (state, action) => {
      const { rightBarTab } = action.payload;
      state.rightBarTab = rightBarTab;
    },
    setSidebarCollapsed: (state, action) => {
      const { sidebarCollapsed } = action.payload;
      state.sidebarCollapsed = sidebarCollapsed;
    },
    setToolbarTab: (state, action) => {
      const { tab } = action.payload;
      state.toolbarTab = tab;
    },
    setShowChannelEmailPopup: (state, action) => {
      const { showChannelEmailPopup } = action.payload;
      state.showChannelEmailPopup = showChannelEmailPopup;
    },
    setShowPrivacyTerm: (state, action) => {
      const { showPrivacyTerms } = action.payload;
      state.showPrivacyTerms = showPrivacyTerms;
    },
    setUsernameColorMap: (state, action) => {
      const { usernameColorMap } = action.payload;
      state.usernameColorMap = usernameColorMap;
    },
    setUploadFileError: (state, action) => {
      const { uploadFileError } = action.payload;
      state.uploadFileError = uploadFileError;
    },
    closeSettings: (state) => {
      state.leftBarTab = LEFT_BAR_TABS.SIDEBAR;
      state.rightBarTab = RIGHT_BAR_TABS.TOOLS;
    },
    openMediaFullscreen: (state, action) => {
      const { mediaFullscreenUrl } = action.payload;
      state.mediaFullscreenUrl = mediaFullscreenUrl;
    },
    closeMediaFullscreen: (state) => {
      state.mediaFullscreenUrl = undefined;
    },
    openVideoFullscreen: (state, action) => {
      const { videoId } = action.payload;
      state.videoFullScreenId = videoId;
    },
    closeVideoFullscreen: (state) => {
      state.videoFullScreenId = undefined;
    },
    openEditImageModal: (state) => {
      state.showChangeEditImageModal = true;
    },
    closeEditImageModal: (state) => {
      state.showChangeEditImageModal = false;
    },
    updateProfileImageForEditing: (state, action) => {
      const { profileImageForEditing } = action.payload;
      state.profileImageForEditing = profileImageForEditing;
    },
    updateTeamchatImageForEditing: (state, action) => {
      const { teamchatImageForEditing } = action.payload;
      state.teamchatImageForEditing = teamchatImageForEditing;
    },
    updateTeamchatPicture: (state, action) => {
      const { teamchatPicture } = action.payload;
      state.teamchatPicture = teamchatPicture;
    },
    openWebcamModal: (state) => {
      state.showWebcamModal = true;
    },
    closeWebcamModal: (state) => {
      state.showWebcamModal = false;
    },
  },
});

export const {
  setLeftBarTab,
  setRightBarTab,
  setSidebarCollapsed,
  setToolbarTab,
  setShowPrivacyTerm,
  setShowChannelEmailPopup,
  setPrevMessageLoadingError,
  setLoadingPreviousMessages,
  setSearchScrollMessage,
  setCancelSearchScrollMessage,
  setUploadFileError,
  closeSettings,
  openMediaFullscreen,
  closeMediaFullscreen,
  openVideoFullscreen,
  closeVideoFullscreen,
  openEditImageModal,
  closeEditImageModal,
  updateProfileImageForEditing,
  updateTeamchatImageForEditing,
  updateTeamchatPicture,
  openWebcamModal,
  closeWebcamModal,
} = uiStateSlice.actions;

export default uiStateSlice.reducer;
