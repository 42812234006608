export const removeChannelsOfChannelsWithTypes = (state, channelTypes) => {
  const filteredChannels = Object.values(state.channels)
    .filter(channel => !channelTypes.includes(channel.channelType));
  return filteredChannels.reduce((acc, channel) => {
    acc[channel.id] = channel;
    return acc;
  }, {});
};

export const removeChannelIdsOfChannelsWithTypes = (state, channelTypes) => {
  const filteredChannelIds = state.channelListChannelIds
    .filter(id => !channelTypes.includes(state.channels[id].channelType));
  return [...new Set([...filteredChannelIds])];
};
