import { useState, FC } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { useDispatch } from 'react-redux';
import { ICON } from 'gcs-common/constants/IconConstants';
import { useUpdateTaskMutation } from 'gcs-common/clients/api/entities/tasks/tasks-api';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import IconComponent from '../IconComponent/IconComponent';
import Button from '../Button/Button';
import styles from './styles.module.scss';

registerLocale('de', de);

interface TaskDatePickProps {
  task: FetchTasksResponseData['tasks'][number],
  onSubmit: VoidFunction,
  onClose: VoidFunction,
}

const TaskDatePick:FC<TaskDatePickProps> = ({ task, onSubmit, onClose }) => {

  const setDate = task.dueDate ? new Date(task.dueDate) : new Date();
  const [date, setPickedDate] = useState<Date | null>(setDate);
  const [startTime, setStartTime] = useState<Date | null>(setDate);

  const updateTaskMutation = useUpdateTaskMutation();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const newDate = date ? new Date(
      date?.getFullYear(),
      date?.getMonth(),
      date?.getDate(),
      startTime?.getHours(),
      startTime?.getMinutes(),
    ) : new Date();
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.EDIT_DUE_TIME()));
    updateTaskMutation.mutate({
      params: { path: { task_id: task.id } },
      body: {
        ...task,
        dueDate: newDate.toISOString(),
      },
    });
    onSubmit();
  };

  const handleChange = (newDate: Date | null) => setPickedDate(newDate);

  const { description } = task;
  return (
    <div className={styles.todoDatePick}>
      <div className={styles.timePickHeader}>
        <div> To-Do</div>
        <button type="button" onClick={onClose}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <IconComponent Icon={ICON.CLOSE} alt="close-icon" />
        </button>
      </div>
      <div className={styles.timePickTodoText}>
        {description}
      </div>
      <div className={styles.timeSelectHeader}>
        Wann?
      </div>
      <div className={styles.timePick}>
        <div>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <IconComponent Icon={ICON.CALENDAR} style={{ marginRight: '4px' }} alt="calendar-icon" />
          <DatePicker
            selected={date}
            onChange={handleChange}
            locale="de"
            dateFormat="dd.MM.yyyy"
          />
        </div>

        <div>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <IconComponent Icon={ICON.CLOCK} style={{ marginRight: '4px' }} alt="clock-icon" />
          <DatePicker
            selected={startTime}
            onChange={time => setStartTime(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            locale="de"
            timeCaption="Uhrzeit"
            dateFormat="p"
            timeFormat="p"
          />
        </div>
      </div>

      <div className={styles.timePickBottomBar}>
        <Button
          type="button"
          onClick={handleSubmit}
        >
          Erinnerung hinzufügen
        </Button>
      </div>
    </div>
  );
};

export default TaskDatePick;
