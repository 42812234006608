import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import ErpCustomerPropType from '../../../../models/CompanyPropType';

const ErpCustomerDelete = ({ erpCustomer, onCancel, handleDelete }) => {
  return (
    <div className={styles.column}>
      Möchten Sie den Kunden
      {' "'}
      {erpCustomer.friendlyName}
      {'" '}
      wirklich löschen? Es werden auch alle Nutzer dieses Kunden gelöscht.
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

ErpCustomerDelete.propTypes = {
  erpCustomer: ErpCustomerPropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ErpCustomerDelete;
