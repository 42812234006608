import { createAsyncThunk } from '@reduxjs/toolkit';
import reduceFavorites from '../../../helper/favoriteReducer';

const fetchFavorites = createAsyncThunk(
  'favorites/fetch',
  async (_, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getFavorites();
    return { favorites: reduceFavorites(response.favorites) };
  },
);

export default fetchFavorites;
