import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllResponsibilityRoles,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersThunk';
import {
  getCustomerResponsibilityRoles, getCustomerResponsibilityRolesLoading,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSelector';
import FormikSelect from '../../../FormikSelect/FormikSelect';

const CustomerResponsibilityRoleSelect = ({
  roleType,
  name,
  placeholder = 'Suchen...',
  labelKey,
  valueKey,
  isMulti = false,
  onChange,
  localFilter,
  ...props
}) => {

  const customerResponsibilityRolesLoading = useSelector(getCustomerResponsibilityRolesLoading);
  const customerResponsibilityRoles = useSelector(getCustomerResponsibilityRoles);

  const dispatch = useDispatch();

  const fetchCustomerResponsibilityRolesPage = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    roleType === undefined ? dispatch(fetchAllResponsibilityRoles())
      : dispatch(fetchAllResponsibilityRoles({
        roleType,
      }));
  }, [dispatch, roleType]);

  return (
    <FormikSelect
      name={name}
      placeholder={placeholder}
      labelKey={labelKey}
      valueKey={valueKey}
      isMulti={isMulti}
      onChange={onChange}
      options={customerResponsibilityRoles}
      isLoading={customerResponsibilityRolesLoading}
      onInputChange={fetchCustomerResponsibilityRolesPage}
      localFilter={localFilter}
      {...props}
    />
  );
};

CustomerResponsibilityRoleSelect.propTypes = {
  roleType: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  localFilter: PropTypes.arrayOf(PropTypes.shape),
};

export default CustomerResponsibilityRoleSelect;
