

const termsText = [
  <h2>Nutzungsbedingungen</h2>,
  `Die interaktive Kundeninteraktionsplattform mit Live-Chat-Funktion ("BEEM") wird von der GC Großhandels Contor GmbH, Altenwall 6, 28195 Bremen (nachfolgend: „GC“) zur Verfügung gestellt.
 
  Mit der Installation der BEEM-App auf Ihrem Gerät und/oder dem Zugriff auf BEEM mittels Web-Browser akzeptieren Sie die nachfolgenden Nutzungsbedingungen. Diese Bedingungen legen die Nutzungsregeln für die Nutzung von BEEM fest.
 

  `,
  <b>§1 Einsatz und Verfügbarkeit/Geltung AGB von GC</b>,
  `

 (1) Der Live-Chat wird nur Benutzern zur Verfügung gestellt, die Unternehmer gemäß § 14 BGB ("Nutzer") sind.
 
 (2) Der Live-Chat wird für die folgenden Dienstleistungen zur Verfügung gestellt:
 · Hilfestellung in Bezug auf Produktauswahl
 · Kauf von Produkten
 · Fragen zu Bestellungen
 
 (3) GC beabsichtigt, den Live-Chat 24 Stunden /Tag zur Verfügung zu stellen. Die Beantwortung und Bearbeitung der jeweiligen Nachrichten sind jedoch nur zu den üblichen Geschäftszeiten von GC möglich. GC übernimmt jedoch keine Garantie für die ständigen Verfügbarkeit von BEEM.
 
 (4) Sofern der Nutzer im Rahmen von BEEM Bestellungen von Produkten tätigt, gelten für das damit verbundene Rechtsgeschäft die Allgemeinen Geschäftsbedingungen der GC Großhandels Contor GmbH, Altenwall 6, 28195 Bremen, abrufbar unter https://www.gc-gruppe.de/agb.


  `,
  <b>§2 Pflichten des Nutzers</b>,
  `

 (1) Der Nutzer verpflichtet sich gegenüber BEEM, keine Nachrichten zu veröffentlichen, die gegen die guten Sitten oder geltendes Recht verstoßen.
 
 (2) Der Nutzer verpflichtet sich insbesondere dazu, keine Nachrichten zu veröffentlichen,
 · deren Veröffentlichung einen Straftatbestand erfüllt oder eine Ordnungswidrigkeit darstellt,
 · die gegen das Urheberrecht, Markenrecht oder Wettbewerbsrecht verstoßen,
 · die gegen das Rechtsdienstleistungsgesetz verstoßen,
 · die beleidigenden, rassistischen, diskriminierenden und/oder pornographischen Inhalt haben,
 · die Werbung enthalten,
 · die Fäkalsprache, Vergleiche von Personen mit extremistischen Gruppen, Drohungen oder Anschuldigungen ohne Belege enthalten,
 · keine Daten in das System einzubringen, die einen Computer-Virus (infizierte Software) enthalten.
 
 (3) Bei einem Verstoß gegen die Verpflichtung aus Absatz 1 oder Absatz 2 ist GC berechtigt, die entsprechenden Nachrichten abzuändern/zu löschen und den Zugang zu BEEM zu sperren.
 
 (4) GC hat gegen den Nutzer einen Anspruch auf Freistellung von Ansprüchen Dritter, die diese wegen der Verletzung eines Rechts durch den Nutzer geltend machen. Der Nutzer verpflichtet sich, GC bei der Abwehr derartiger Ansprüche zu unterstützen. Der Nutzer ist außerdem verpflichtet, die Kosten einer angemessenen Rechtsverteidigung von GC zu tragen.
 

  `,
  <b>§3 Haftung</b>,
  `
 
 (1) BEEM wird den Nutzern kostenlos zur Verfügung gestellt.

 (2) GC wird mit der Sorgfalt eines ordentlichen Kaufmanns Anfragen im Rahmen von BEEM beantworten.

 (3) Schadensersatzansprüche des Nutzers sind ausgeschlossen, soweit nachfolgend nichts anderes bestimmt ist. Der vorstehende Haftungsausschluss gil auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen von GC, sofern der Nutzer Ansprüche gegen diese geltend macht.

 (4) Von dem in Absatz (3) bestimmten Haftungsausschluss ausgenommen sind Schadensersatzansprüche aufgrund einer Verletzung des Lebens, des Körpers, der Gesundheit und Schadensersatzansprüche aus der Verletzung wesentlicher Vertragspflichten. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist. Von dem Haftungsausschluss ebenfalls ausgenommen ist die Haftung für Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung durch GC, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen.
 

  `,
  <b>§4 Datenschutz</b>,
  `

 Wenn Sie BEEM benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Unsere Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
 

  `,
  <b>§5 Anwendbares Recht</b>,
  `

 (1) Für die Nutzung von BEEM gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Die gesetzlichen Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften insbes. des Staates, in dem der Nutzer als Verbraucher seinen gewöhnlichen Aufenthalt hat, bleiben unberührt.

 (2) Ausschließlicher Gerichtsstand für alle Streitigkeiten im Zusammenhang mit der Nutzung des Live-Chats ist Bremen, Deutschland. GC ist auch berechtigt, am Sitz des Nutzers zu klagen.


 `,
];

export default termsText;
