import TypingIndicatorWrapper from '../TypingIndicatorWrapper/TypingIndicatorWrapper';
import styles from './styles.module.scss';

const ChatScrollerFooter = () => {
  return (
    <>
      <TypingIndicatorWrapper />
      <div className={styles.virtuosoFooter} />
    </>
  );
};

ChatScrollerFooter.propTypes = {};
export default ChatScrollerFooter;
