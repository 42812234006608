// eslint-disable-next-line import/no-cycle
import { getGetTasksQueryKey } from '../../../clients/api/entities/tasks/tasks-api-helpers';
import { channelMessagesUpsert } from '../messagesSlice';
import fetchFavorites from '../../favorites/favoritesThunks/fetchFavorites';
import { reactQueryClient } from '../../../clients/api/client';

const updateMessage = ({ message }) => async (dispatch) => {
  /* eslint-disable camelcase */
  const { channelId } = message;
  dispatch(channelMessagesUpsert({ channelMessages: { [channelId]: [message] } }));

  if (message.deleted) {
    setTimeout(() => {
      dispatch(fetchFavorites());
      reactQueryClient.invalidateQueries({ queryKey: getGetTasksQueryKey(channelId) });
    }, 1500);
  }
};

export default updateMessage;
