import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { orderUpdated } from '../../../slices/quickOrdering/quickOrderingSlice';
import { useApiClient } from '../client';
import { Parameters, RequestBody, Response } from '../../../../types/typeHelper';

export enum OrderClientQueryKeys {
  GET_ORDER = 'GET_ORDER',
}

export type ApiFetchOrderData = Response<'/chat/quick-order/orders/{order_id}', 'get'>;

export function useGetOrderQuery(path: Parameters<'/chat/quick-order/orders/{order_id}', 'get'>['path']) {
  const client = useApiClient();
  return useQuery({
    queryKey: [
      OrderClientQueryKeys.GET_ORDER,
      path,
    ],
    enabled: !!path.order_id,
    queryFn: async () => {
      const { data } = await client.GET('/chat/quick-order/orders/{order_id}', {
        params: {
          path,
        },
      });
      return data;
      // Note: Error throwing handled automatically via middleware
    },
  });
}

export type ApiUpdateOrderBody = RequestBody<'/chat/quick-order/orders/{order_id}', 'patch'>;

export function useUpdateOrderMutation() {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation({
    mutationFn: async ({ params, body }: {
      params: Parameters<'/chat/quick-order/orders/{order_id}', 'patch'>,
      body: RequestBody<'/chat/quick-order/orders/{order_id}', 'patch'>,
    }) => {
      return client.PATCH('/chat/quick-order/orders/{order_id}', {
        params, body,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [OrderClientQueryKeys.GET_ORDER] });
    },
  });
}


export type ApiSubmitOrder = RequestBody<'/chat/quick-order/orders/{order_id}/checkout', 'patch'>;
export type ApiSubmitOrderResponse = Response<'/chat/quick-order/orders/{order_id}/checkout', 'patch'>;
export type SubmitOrderMutation = ReturnType<typeof useSubmitOrderMutation>;

export function useSubmitOrderMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const client = useApiClient();
  const mutation = useMutation({
    mutationFn: async ({ params, body }: {
      params: Parameters<'/chat/quick-order/orders/{order_id}/checkout', 'patch'>,
      body: RequestBody<'/chat/quick-order/orders/{order_id}/checkout', 'patch'>,
    }) => {
      return client.PATCH('/chat/quick-order/orders/{order_id}/checkout', {
        params, body,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [OrderClientQueryKeys.GET_ORDER] });
    },
  });
  useEffect(() => {
    if (!mutation.data?.data) {
      return;
    }
    dispatch(orderUpdated({ orderId: mutation.data.data?.id, order: mutation.data.data }));
  }, [mutation.data, dispatch]);


  return mutation;
}
