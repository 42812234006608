import { useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { stringValidator } from 'gcs-common/helper/yupValidationHelper';
import { createAgent } from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import { getCreateAgentErrorMessage, getCreateAgentDefaultErrorMessage } from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import { resetCreateAgentErrorMessage, resetCreateAgentDefaultErrorMessage } from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import styles from './styles.module.scss';
import FormikInput from '../../../FormikInput/FormikInput';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const AgentCreateValidationSchema = Yup.object().shape({
  firstName: stringValidator,
  lastName: stringValidator,
  email: Yup.string().email('Ungültige E-Mail Adresse').required('Erforderlich'),
});

const SALUTATION = [
  {
    key: 'Herr',
    value: 'Herr',
  }, {
    key: 'Frau',
    value: 'Frau',
  },
];

const initialData = {
  email: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  salutation: '',
};

const AgentCreate = ({ onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateAgentErrorMessage);
  const defaultErrorMessage = useSelector(getCreateAgentDefaultErrorMessage);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetCreateAgentDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateAgentErrorMessage());
    dispatch(resetCreateAgentDefaultErrorMessage());
  }, [dispatch]);

  const onEmailChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateAgentErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(createAgent({ agent: values }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={AgentCreateValidationSchema}
      onSubmit={submit}

    >
      {({ isSubmitting }) => (
        <Form className={styles.agentCreateForm}>

          <div className={styles.row}>

            <FormikSelect
              label="Anrede"
              options={SALUTATION}
              labelKey="value"
              valueKey="key"
              name="salutation"
            />

          </div>

          <div className={styles.row}>

            <FormikInput label="Vorname*" type="text" name="firstName" />

            <FormikInput label="Nachname*" type="text" name="lastName" />

          </div>

          <div className={styles.row}>
            {/* Note: using onChange/onFocus might clash with formik-validation */}
            <FormikInput label="Email*" type="text" name="email" onKeyUp={onEmailChanged} />

            <FormikInput label="Telefonnumer" name="phoneNumber" />

          </div>

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AgentCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AgentCreate;
