import { ReactNode, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import styles from './styles.module.scss';

interface PopupMenuProps {
  children: ReactNode;
  isVisible: boolean;
  onHide: (arg0: boolean) => void;
  menuClass?: string;
  menuItemsClass?: string;
  upward?: boolean;
}

const PopupMenu = (props: PopupMenuProps) => {
  const {
    children, isVisible, onHide, menuClass, menuItemsClass, upward = false,
  } = props;

  const innerRef = useRef(null);

  useOnClickOutside(
    innerRef,
    () => onHide(!isVisible),
  );

  if (isVisible) {
    return (
      <div ref={innerRef} className={`${styles.menu}${menuClass ? ` ${menuClass}` : ''}`}>
        <div style={upward ? { bottom: 0 } : { top: 0 }} className={`${styles.menuItems}${menuItemsClass ? ` ${menuItemsClass}` : ''}`}>
          <div>
            {children}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default PopupMenu;
