import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class OrderMessageModel extends AbstractMessageModel {

  constructor({ order, ...args } = {}) {
    super({
      messageType: MESSAGE_TYPE.ORDER,
      ...args,
    });

    this.order = order;
  }
}
