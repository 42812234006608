/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key,jsx-a11y/interactive-supports-focus */
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOpConnectionStateForChannel,
  getProcessSearchInput,
  getSelectedProcess,
} from 'gcs-common/slices/processes/processesSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { processSelected, productSelected, opConnectionStateCleared, resetProcessFilter } from 'gcs-common/slices/processes/processesSlice';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { FETCH_PROCESSES_ERRORS } from 'gcs-common/slices/processes/processesConstants';
import { getIsAgent, getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { LEFT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { setLeftBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import ProcessSearch from './ProcessSearch/ProcessSearch';
import SuggestionList from './SuggestionList/SuggestionList';
import RenderHeader from './RenderHeader/RenderHeader';
import OpStatus from './OpStatus/OpStatus';
import Button from '../Button/Button';
import ProcessList from './ProcessList/ProcessList';
import ProcessDetail from '../ProcessDetail/ProcessDetail';
import styles from './styles.module.scss';
import Alert from '../Alert/Alert';

const ProcessSelect = () => {

  const isAgent = useSelector(getIsAgent);
  const isOnline = useSelector(getIsOnline);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedProcess = useSelector(getSelectedProcess);
  const searchInput = useSelector(getProcessSearchInput);
  const opConnectionState = useSelector(getOpConnectionStateForChannel(selectedChannelId));
  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(processSelected({ selectedProcess: null }));
    dispatch(productSelected({ selectedProduct: null }));
    dispatch(resetProcessFilter());
  }, [dispatch, selectedChannelId]);

  const clearOpConnectionState = useCallback(() => {
    dispatch(opConnectionStateCleared({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  const showProfile = useCallback(() => {
    dispatch(setLeftBarTab({ leftBarTab: LEFT_BAR_TABS.PROFILE }));
  }, [dispatch]);

  const errorMessage = useMemo(
    () => {
      if (!isOnline) {
        return (
          <div className={styles.errorMessage}>
            Warten auf Netzwerk
          </div>
        );
      }

      if (isOpMaintenanceEnabled) {
        return (
          <div className={styles.errorMessage}>
            <Alert type="info">
              <p className={styles.errorMessageText}>
                {opMaintenanceMessage}
              </p>
            </Alert>
          </div>
        );
      }

      if (opConnectionState && !opConnectionState.ok) {

        if (!isAgent) {
          if (opConnectionState.errorCode === FETCH_PROCESSES_ERRORS.NO_OP_ACCOUNT
        || opConnectionState.errorCode === FETCH_PROCESSES_ERRORS.MISSING_CREDENTIALS
        || opConnectionState.errorCode === FETCH_PROCESSES_ERRORS.SEARCH_DISABLED) {
            return (
              <div className={styles.errorMessage} data-testid="process-error-dialog-craftsman">
                <p>
                  <Alert type="info">
                    <p className={styles.errorMessageText}>{opConnectionState.errorMessage}</p>
                  </Alert>
                </p>
                <p>
                  Bitte prüfe &quot;Einstellungen/Verknüpfungen bearbeiten&quot;.
                </p>
                <Button
                  className={styles.errorButton}
                  onClick={showProfile}
                >
                  Einstellungen öffnen
                </Button>

                <Button
                  className={styles.errorButton}
                  onClick={clearOpConnectionState}
                >
                  Aktualisieren
                </Button>
              </div>
            );
          }

        }

        return (
          <div className={styles.errorMessage} data-testid="process-error-dialog-agents">
            <Alert type="info">
              <p className={styles.errorMessageText}>{opConnectionState.errorMessage}</p>
            </Alert>
          </div>
        );
      }
      return undefined;
    },
    [
      clearOpConnectionState,
      isAgent,
      isOnline,
      opConnectionState,
      showProfile,
      isOpMaintenanceEnabled,
    ],
  );

  if (!selectedChannelId) return null;

  return (
    <div className={styles.processWrapper}>
      <OpStatus />
      {
        (selectedProcess)
          ? (
            <ProcessDetail />
          )
          : (
            <>
              <RenderHeader />
              {!errorMessage && (
                <div className={styles.searchWrapper}>
                  <ProcessSearch />
                </div>
              )
              }
              {!errorMessage && (
                searchInput.length > 0
                  ? <SuggestionList />
                  : (
                    <ProcessList />
                  )
              )
              }
              {errorMessage}
            </>
          )
      }
    </div>
  );
};
export default ProcessSelect;

ProcessSelect.propTypes = {};
