import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { openErrorDialog } from '../slices/errorDialogSlice/errorDialogSlice';
import { getErrorMsg } from '../helper/errorMessages';

const useShowErrorDialogFromSearchParams = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const errorDescriptionParams = searchParams.get('errorDescription');

  if (errorDescriptionParams) {
    dispatch(openErrorDialog({
      dialogErrorMsg: errorDescriptionParams,
    }));
    return;
  }

  const errorParams = searchParams.get('error');

  if (errorParams) {
    const errorMessageFromCode = getErrorMsg(errorParams);
    const defaultErrorMessage = getErrorMsg('chat.default');
    dispatch(openErrorDialog({
      dialogErrorMsg: errorMessageFromCode || defaultErrorMessage,
    }));
  }
};

export default useShowErrorDialogFromSearchParams;
