import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, MESSAGE_LOADING_DIRECTIONS } from '../../../clients/gccApiClient/gccApiClient';
// eslint-disable-next-line import/no-cycle
import { channelMessagesUpsert } from '../messagesSlice';
import { getChannel } from '../../channel/channelSelectors';
import { loadTwilioChannelMessages } from '../../chatConnection/chatConnectionThunks/twilioLoadingThunk';
import { MESSAGE_TYPE } from '../../../constants/MessageTypes';
import { ORDER_STATUS } from '../../quickOrdering/quickOrderingConstants';


const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async ({
    channelId,
    pageSize = DEFAULT_PAGE_SIZE,
    fromMessageIndex = undefined,
    toMessageIndex = undefined,
    direction = MESSAGE_LOADING_DIRECTIONS.BACKWARDS,
    singlePage = false,
    includeAnchorMessage = false,
    throwIfToMessageIndexNotFound = true,
  },
  { dispatch, getState, extra: { gccApiClient } }) => {

    const channel = getChannel(channelId)(getState());
    const { messages, firstMessageLoaded } = await gccApiClient.getChannelMessages({
      channelId,
      pageSize,
      fromMessageIndex,
      toMessageIndex,
      direction,
      singlePage,
      includeAnchorMessage,
      throwIfToMessageIndexNotFound,
    });

    dispatch(channelMessagesUpsert({
      channelMessages: { [channelId]: messages },
      channelFirstMessageLoaded: { [channelId]: firstMessageLoaded },
    }));

    /*
      Only subscribe messages of type order, so we get updates on the order status
    */
    const messagesToSubscribe = messages.filter(
      message => message?.messageType === MESSAGE_TYPE.ORDER
      && message?.order?.status !== ORDER_STATUS.SUBMITTED,
    );
    const messageIndexes = messagesToSubscribe.map(message => message.index);
    dispatch(loadTwilioChannelMessages({ channel, messageIndexes }));
  },
);
export default fetchMessages;
