import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCustomerResponsibilityMember,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersThunk';
import {
  resetDeleteCustomerResponsibilityMemberErrorMessage,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSlice';
import {
  getDeleteCustomerResponsibilityMemberErrorMessage,
  getDeleteCustomerResponsibilityMemberLoading,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSelector';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import Button from '../../../Button/Button';
import styles from './styles.module.scss';
import CustomerResponsibilityMemberPropType from '../../../../models/CustomerResponsibilityMemberPropType';

const CustomerResponsibilityMemberDelete = ({ onCancel,
  customerResponsibilityMember,
  onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getDeleteCustomerResponsibilityMemberErrorMessage);
  const isDeleting = useSelector(getDeleteCustomerResponsibilityMemberLoading);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetDeleteCustomerResponsibilityMemberErrorMessage()),
      ));
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteCustomerResponsibilityMemberErrorMessage());
  }, [dispatch]);

  const handleDelete = useCallback(async () => {
    const result = await dispatch(deleteCustomerResponsibilityMember({ responsibilityMemberId:
                customerResponsibilityMember.id }));
    if (!result.error) {
      onSuccess();
    }
  }, [dispatch, customerResponsibilityMember.id, onSuccess]);

  return (
    <div className={styles.column}>
      Möchten Sie die Verantwortlichkeit von
      {' "'}
      { customerResponsibilityMember.customerNumber }
      {'" '}
      {' '}
      mit
      {' "'}
      { customerResponsibilityMember.teamOrAgent }
      {'" '}
      wirklich löschen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          onClick={onCancel}
          disabled={isDeleting}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

CustomerResponsibilityMemberDelete.propTypes = {
  onCancel: PropTypes.func.isRequired,
  customerResponsibilityMember: CustomerResponsibilityMemberPropType.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CustomerResponsibilityMemberDelete;
