import { combineReducers } from '@reduxjs/toolkit';
import { commonReducers } from 'gcs-common/slices/commonReducers';
import { clearStore } from 'gcs-common/slices/store/storeActions';
import settingsSlice from './slices/settings/settingsSlice';
import initializationSlice from './slices/initialization/initializationSlice';
import initSlice from './slices/initSlice/initSlice';

const createBaseReducers = () => combineReducers({
  ...commonReducers,
  settings: settingsSlice,
  initialization: initializationSlice,
  init: initSlice,
});

const createRootReducer = () => (state, action) => {
  // Note: We reset the whole state to the initial-state here
  // if needed, some parts can be also ignored
  // this is based on https://stackoverflow.com/a/35641992/8029510
  if (action.type === clearStore.type) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return createBaseReducers()(state, action);
};

export default createRootReducer;
