import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAgentsTableAgents,
  getAgentsTableAgentsPaginationResult,
  getDeleteAgentDefaultErrorMessage,
  getDisconnectAgentFromHouseDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import {
  disconnectAgentFromHouse,
  fetchAgentsTable,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import moment from 'moment';
import {
  resetDeleteAgentDefaultErrorMessage,
  resetDisconnectAgentFromHouseDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import { showFailureNotification, showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import ProfileImageCell from '../../../Table/ProfileImageCell/ProfileImageCell';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addAgentIcon from '../../../../img/plus_white.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import AgentCreate from '../AgentCreate/AgentCreate';
import { SORT_ORDER } from '../../../../constants/sortConstants';
import styles from './styles.module.scss';
import AgentDelete from '../AgentDelete/AgentDelete';
import deletedIcon from '../../../../img/delete.svg';
import detailsIcon from '../../../../img/arrow.svg';
import { Spinner } from '../../../Spinner/Spinner';

const getRowProps = (row) => {
  if (row?.original?.disabled) {
    return {
      style: {
        color: styles.disabledUserColor,
      },
    };
  }
  return {};
};

const AgentTable = ({ onAgentSelected, agentsLoading }) => {

  const dispatch = useDispatch();

  const [createAgent, setCreateAgent] = useState(false);
  const [showDeleteAgentModal, setShowDeleteAgentModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);

  const agentsPagination = useSelector(getAgentsTableAgentsPaginationResult);
  const agents = useSelector(getAgentsTableAgents);
  const globalSearchInput = useSelector(getGlobalSearchInput);
  const deleteAgentErrorMessage = useSelector(getDeleteAgentDefaultErrorMessage);
  const disconnectAgentErrorMessage = useSelector(getDisconnectAgentFromHouseDefaultErrorMessage);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'imageUrl',
        Cell: ProfileImageCell,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'friendlyName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Letztes Mal Online',
        accessor: 'statusUpdatedAt',
        Cell: (cell) => {
          return cell.value ? moment(cell.value).format('ddd, D MMM YYYY HH:mm') : '-';
        },
      },
      // TODO: hide until clarfied what to exactly show here
      // {
      //   Header: '# Kundenkontakte',
      //   headerPosition: 'center',
      //   accessor: 'craftsman_count',
      //   Cell: (cell) => {
      //     return <div className={styles.alignCenter}>{cell.value}</div>;
      //   },
      // },
      {
        Header: '# Kundenchats',
        headerPosition: 'center',
        accessor: 'channelCount',
        Cell: (cell) => {
          // eslint-disable-next-line react/destructuring-assignment
          return <div className={styles.alignCenter}>{cell.value}</div>;
        },
      },
    ],
    [],
  );

  const accessorToBeSorted = columns.find(element => element.Header).accessor;
  const [sortedColumnId, setSortedColumnId] = useState(accessorToBeSorted);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);
  const [agentIdsToDelete, setAgentIdsToDelete] = useState([]);

  const loadAgents = useCallback((pageIndex, pageSize, searchQuery) => {
    dispatch(fetchAgentsTable({
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
    }));
  }, [dispatch, sortOrder, sortedColumnId]);

  const agentCreatedSuccessfully = useCallback(() => {
    setCreateAgent(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setAgentToDelete(original);
      setShowDeleteAgentModal(true);
    },
    [],
  );

  useEffect(() => {
    if (deleteAgentErrorMessage || disconnectAgentErrorMessage) {
      dispatch(showFailureNotification(
        deleteAgentErrorMessage || disconnectAgentErrorMessage,
        (() => {
          dispatch(resetDisconnectAgentFromHouseDefaultErrorMessage());
          dispatch(resetDeleteAgentDefaultErrorMessage());
        })(),
      ));
    }
  }, [deleteAgentErrorMessage, disconnectAgentErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDisconnectAgentFromHouseDefaultErrorMessage());
  }, [dispatch]);

  const removeFromAgentIdsToDelete = useCallback((userId) => {
    const filteredUserIds = agentIdsToDelete.filter(id => id !== userId);
    setAgentIdsToDelete(filteredUserIds);
  }, [agentIdsToDelete]);

  const addToAgentsIdsToDelete = useCallback((userId) => {
    const userIds = [...agentIdsToDelete, userId];
    setAgentIdsToDelete(userIds);
  }, [agentIdsToDelete]);

  const handleDelete = useCallback(async () => {
    const agentId = agentToDelete?.id;
    addToAgentsIdsToDelete(agentId);
    const result = await dispatch(disconnectAgentFromHouse({ agentId }));
    if (!result.error) {
      removeFromAgentIdsToDelete(agentId);
      setAgentToDelete(null);
      setShowDeleteAgentModal(false);
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    }
  }, [dispatch,
    agentToDelete,
    addToAgentsIdsToDelete,
    removeFromAgentIdsToDelete]);

  const closeDeleteModal = useCallback(
    () => {
      const agentId = agentToDelete?.id;
      removeFromAgentIdsToDelete(agentId);
      setAgentToDelete(null);
      setShowDeleteAgentModal(false);
    },
    [agentToDelete, removeFromAgentIdsToDelete],
  );

  const extendedAgentsUsers = useMemo(() => {
    return agents.map(agent => {
      if (agentIdsToDelete.includes(agent.id)) {
        return { ...agent, isDeleting: true };
      }
      return agent;
    });
  }, [agents, agentIdsToDelete]);

  return (
    <>
      <PaginatedTable
        onRowSelected={onAgentSelected}
        data={extendedAgentsUsers}
        columns={columns}
        onPaginationChanged={loadAgents}
        searchInput={globalSearchInput}
        pagination={agentsPagination}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortedColumnId={sortedColumnId}
        setSortedColumnId={setSortedColumnId}
        sortIsLoading={agentsLoading}
        getRowProps={getRowProps}
        renderActions={({ row }) => (
          <>
            {
              row.original.isDeleting ? (<Spinner />) : (
                <button
                  type="button"
                  onClick={(event) => deleteSelected(event, row)}
                >
                  <img
                    alt="deleted-icon"
                    src={deletedIcon}
                  />
                </button>
              )
            }
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      <AddFabButton
        icon={addAgentIcon}
        title="Mitarbeiter"
        onClick={() => setCreateAgent(true)}
      />
      {createAgent && (
      <PopUpModal
        title="Neuer Mitarbeiter"
        onRequestClose={() => setCreateAgent(false)}
        isOpen
      >
        <AgentCreate
          onCancel={() => setCreateAgent(false)}
          onSuccess={agentCreatedSuccessfully}
        />
      </PopUpModal>
      )}
      {showDeleteAgentModal && (
      <PopUpModal
        title="Mitarbeiter entfernen"
        onRequestClose={closeDeleteModal}
        isOpen
      >
        <AgentDelete
          agent={agentToDelete}
          onCancel={closeDeleteModal}
          handleDelete={handleDelete}
        />
      </PopUpModal>
      )}
    </>
  );
};

AgentTable.propTypes = {
  onAgentSelected: PropTypes.func.isRequired,
  agentsLoading: PropTypes.bool.isRequired,
};

export default AgentTable;
