import { useState, useEffect } from 'react';

// This hook is used to check whether the image in each sales space is square or round:
// if it is square (the new format), it will be displayed as is;
// if it is round (old format), it will be zoomed in to fill out the square wrapper;

const imageCache = {};

const useRoundImageChecker = (imageUrl) => {

  // TODO: This should be removed altogether, too much performance overhead
  // if really necessary run migration that saves isRound property somewhere

  const [isRound, setIsRound] = useState(false);
  const [imgUrl, setImageUrl] = useState(imageUrl);

  useEffect(() => {
    if (!imgUrl) {
      return;
    }

    // Check if the image has already been checked and cached
    if (imageCache[imgUrl] !== undefined) {
      setIsRound(imageCache[imgUrl]);
      return;
    }

    const profilePic = new Image();
    profilePic.crossOrigin = 'anonymous';
    // it is necessary to add the url param otherwise we run into cors problems
    // if the image was already cached from an <img element that had no anonymous
    // crossOrigin
    profilePic.src = `${imgUrl}?roundImageChecker`;

    profilePic.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.drawImage(profilePic, 0, 0, canvas.width, canvas.height);
      const imgData = context.getImageData(0, 0, canvas.width, canvas.height).data;
      let isImageRound = false;
      // Loops through the canvas pixels checking for an alpha channel (transparency)
      for (let i = 0; i < imgData.length; i += 4) {
        if (imgData[i + 3] < 255) {
          isImageRound = true;
          break;
        }
      }
      // Cache the result of the check for future use
      imageCache[imgUrl] = isImageRound;
      setIsRound(isImageRound);
    };
  }, [imgUrl]);

  return [isRound, setImageUrl];
};


export default useRoundImageChecker;
