import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { getIsChatConnected } from '../slices/chatConnection/chatConnectionSelectors';
import changeSelectedChannelId from '../slices/channels/channelsThunks/changeSelectedChannelId';
import { resetSelectedMessageIds } from '../slices/messageInput/messageInputSlice';
import { getChannelListChannelIds, getSelectedChannelId } from '../slices/channels/channelsSelectors';
import { getFirstChannelId } from '../slices/channelList/channelListSelectors';

const useChannelInitialization = () => {
  const { routeChannelId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChatConnected = useSelector(getIsChatConnected);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const firstChannelId = useSelector(getFirstChannelId);
  const allChannels = useSelector(getChannelListChannelIds);
  const selectedChannelExists = selectedChannelId && allChannels.includes(selectedChannelId);

  useEffect(() => {
    if (!isChatConnected) {
      return;
    }

    if (!selectedChannelId || !selectedChannelExists) {
      const newSelectedChannelId = (
        routeChannelId && allChannels.includes(routeChannelId) ? routeChannelId : firstChannelId
      );
      if (newSelectedChannelId) {
        dispatch(changeSelectedChannelId({ selectedChannelId: newSelectedChannelId }));
      }
    }

    if (selectedChannelId && selectedChannelId !== routeChannelId) {
      dispatch(resetSelectedMessageIds({ channelId: selectedChannelId }));
      navigate(CHAT_ROUTES.CHANNEL(selectedChannelId));
    }
  }, [
    dispatch,
    firstChannelId,
    allChannels,
    routeChannelId,
    selectedChannelId,
    selectedChannelExists,
    isChatConnected,
    navigate,
  ]);
};

export default useChannelInitialization;
