import { createAsyncThunk } from '@reduxjs/toolkit';
import assignmetChannelImageUploadHelper from '../../../helper/assignmetChannelImageUploadHelper';

export const fetchErpCustomerAssignments = createAsyncThunk(
  'erpCustomers/fetchErpCustomerAssignments',
  async ({ erpCustomerId }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAssignmentsForErpCustomer(erpCustomerId);
    return { data: response };
  },
);

export const createErpCustomerAssignment = createAsyncThunk(
  'erpCustomers/createErpCustomerAssignment',
  async ({ assignment, erpCustomerId }, { extra: { gccApiClient }, rejectWithValue }) => {

    if (assignment.channelImage) {
      // eslint-disable-next-line no-param-reassign
      assignment.channelImage = await assignmetChannelImageUploadHelper(assignment);
    }

    const response = await gccApiClient.createErpCustomerAssignment(assignment, erpCustomerId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { assignment: response };
  },
);


export const updateErpCustomerAssignment = createAsyncThunk(
  'erpCustomers/updateErpCustomerAssignment',
  async ({ assignmentId, assignment }, { extra: { gccApiClient }, rejectWithValue }) => {

    if (assignment.channelImage) {
      // eslint-disable-next-line no-param-reassign
      assignment.channelImage = await assignmetChannelImageUploadHelper(assignment);
    }

    const response = await gccApiClient.updateAssignment(assignmentId, assignment);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { assignment: response };
  },
);

export const deleteErpCustomerAssignment = createAsyncThunk(
  'erpCustomers/deleteErpCustomerAssignment',
  async ({ assignmentId }, { extra: { gccApiClient }, rejectWithValue }) => {

    const response = await gccApiClient.deleteAssignment(assignmentId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { data: response };
  },
);
