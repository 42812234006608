/* eslint-disable no-use-before-define */
import { MESSAGE_TYPE } from '../constants/MessageTypes';
import { DEFAULT_LAST_CONSUMED_MESSAGE_INDEX } from '../slices/messages/messageConstants';
import { calculateUnreadCount } from './channelHelpers';


export const calculateAggregateCount = (channelIds, countGenerator) => {
  if (!channelIds) {
    return 0;
  }
  return channelIds.reduce((red, channelId) => {
    const count = countGenerator(channelId);
    return red + count;
  }, 0);
};

export const calculateUnreadCountForChannel = (
  channels, channelMembers, members, currentUserId, channelId,
) => {
  const channel = channels[channelId];
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const myMemberId = calculateMyMemberIdFromChannelAndUserId({
    channelMembers, members, channelId, currentUserId,
  });
  const member = members[myMemberId];
  return calculateUnreadCount({ channel, member });
};


const calculateLastMessageFromMe = (lastMessage, currentUserId) => {
  return (currentUserId === lastMessage?.authorId
    && lastMessage?.messageType !== MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION
    && lastMessage?.autogenerated !== true
  );
};

export const calculateMyMemberIdFromChannelAndUserId = ({
  channelMembers, members, channelId, currentUserId,
}) => {
  const channel = channelMembers[channelId];
  return channel?.find((memberId) => members[memberId].userId === currentUserId);
};

export const calculateMaxReadIndexJoined = ({
  channel, channelMessages, currentUserId, members, myMemberId,
}) => {
  if (!channelMessages || !channelMessages.length || !channelMessages || !channel) return 0;
  const lastMessage = channelMessages[channelMessages.length - 1];
  if (calculateLastMessageFromMe(lastMessage, currentUserId)) {
    return lastMessage.index;
  }

  if (!myMemberId) {
    return channel.agentsLastConsumedMessageIndex ?? DEFAULT_LAST_CONSUMED_MESSAGE_INDEX;
  }

  return members[myMemberId]?.lastConsumedMessageIndex ?? DEFAULT_LAST_CONSUMED_MESSAGE_INDEX;
};

export const calculateChannelMessageAuthorsIds = ({ channelMessages, channelId }) => {
  const getUniqueAuthorIds = messages => [...new Set(messages.map(m => m.authorId))];
  const channelAuthorIds = {
    [channelId]: getUniqueAuthorIds(channelMessages[channelId]),
  };
  return channelAuthorIds;
};
