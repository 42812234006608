import { createSelector } from '@reduxjs/toolkit';
import { getSelectedChannelId } from '../channels/channelsSelectors';

export const getFavorites = (state) => state.favorites.favorites;

export const getFavoritesForSelectedChannel = createSelector(
  [getSelectedChannelId, getFavorites],
  (currentChannelId, favorites) => {
    if (currentChannelId && favorites) {
      return favorites[currentChannelId];
    }
    return [];
  },
);

export const getFavoritesForSelectedChannelAsList = createSelector(
  getFavoritesForSelectedChannel,
  (channelFavorites) => {
    if (channelFavorites) {
      return Object.values(channelFavorites);
    }
    return [];
  },
);

export const getFavoriteForSelectedChannel = (favoriteId) => createSelector(
  getFavoritesForSelectedChannel,
  (channelFavorites) => {
    if (channelFavorites) {
      return channelFavorites[favoriteId];
    }
    return [];
  },
);

export const getFavoritesForSelectedChannelAsIds = createSelector(
  getFavoritesForSelectedChannel,
  (channelFavorites) => {
    if (channelFavorites) {
      return Object.keys(channelFavorites);
    }
    return [];
  },
);

export const getMessageFavorite = (messageId) => createSelector(
  getFavorites,
  (favorites) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const channelFavorites of Object.values(favorites)) {
      const favorite = Object.values(channelFavorites).find(fav => fav.messageId === messageId);
      if (favorite) {
        return favorite;
      }
    }
    return undefined;
  },
);

export const getFavorite = (favoriteId) => createSelector(
  getFavorites,
  (favorites) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const channelFavorites of Object.values(favorites)) {
      const favorite = Object.values(channelFavorites).find(fav => fav.id === favoriteId);
      if (favorite) {
        return favorite;
      }
    }
    return undefined;
  },
);

export const getMessageFavoriteId = (messageId) => createSelector(
  getMessageFavorite(messageId),
  (favorite) => {
    return favorite && favorite.id;
  },
);

export const getSelectedMessagesFavoriteIds = (selectedMessageIds) => createSelector(
  (state) => selectedMessageIds.map((messageId) => getMessageFavorite(messageId)(state)),
  (favorites) => {
    return favorites
      .filter((favorite) => favorite !== undefined)
      .map((favorite) => favorite.id);
  },
);
