import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMessageLinkPreviews } from 'gcs-common/slices/messages/messageSelector';
import LinkItemLinkPreview from '../LinkItemLinkPreview/LinkItemLinkPreview';

function LinkItem({ messageId }) {
  const linkPreviews = useSelector(getMessageLinkPreviews(messageId));
  return linkPreviews.map(linkPreview => {
    return (
      <LinkItemLinkPreview
        messageId={messageId}
        linkPreview={linkPreview}
        key={linkPreview?.matchStr + messageId}
      />
    );
  });
}

LinkItem.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default LinkItem;
