import styles from './styles.module.scss';
import SalesSpaceChannelList from '../SalesSpaceChannelList/SalesSpaceChannelList';
import SalesSpaceChannelSortAndFilter from '../SalesSpaceChannelSortAndFilter/SalesSpaceChannelSortAndFilter';

function SalesSpaceChannelListWrapper() {
  return (
    <>
      <div className={styles.channelListTypeSelectorWrapper}>
        <div className={styles.filterList}>
          <SalesSpaceChannelSortAndFilter />
        </div>
      </div>
      <SalesSpaceChannelList />
    </>
  );
}

export default SalesSpaceChannelListWrapper;
