import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { setLeftBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { LEFT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import createChannel from 'gcs-common/slices/channels/channelsThunks/createChannel';
import { resetSelectedNewChannelOptions, setGroupChat } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import fetchNewChannelOptions from 'gcs-common/slices/newChannelOptions/newChannelOptionsThunks';
import { resetCreateChannelAsyncStatus } from 'gcs-common/slices/channels/channelsSlice';
import {
  getIsGroupChat,
  getNewChannelOptionsFailed,
  getNewChannelOptionsLoading,
  getNewChannelOptionsPagination,
  getNewChannelOptionTypesToFetch,
  getSelectedNewChannelOptionIds,
  getUserIdsFromSelectedNewChannelOptions,
} from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import {
  getCreateChannelIsSuccess,
  getCreateChannelError, getCreateChannelIsLoading,
} from 'gcs-common/slices/channels/channelsSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { setSearchInput } from 'gcs-common/slices/channelList/channelListSlice';
import { useDebouncedCallback } from 'use-debounce';
import { getSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelTypeFilter';
import { CHANNEL_FILTER_TYPES } from 'gcs-common/helper/channelTypeHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import IconComponent from '../IconComponent/IconComponent';
import ChannelTypeSelector from '../AgentChannelList/ChannelTypeSelector/ChannelTypeSelector';
import NewChatGroupItem from './NewChatGroupItem/NewChatGroupItem';
import styles from './styles.module.scss';
import NewChatAgentSearch from './NewChatAgentSearch/NewChatAgentSearch';
import NewChatSearchMessage from './NewChatSearchMessage/NewChatSearchMessage';
import CraftsmanChannelSearchInput from '../CraftsmanChannelSearchInput/CraftsmanChannelSearchInput';
import NewChannelList from './NewChannelList/NewChannelList';
import ShowMoreNewChannelOptions from './ShowMoreNewChannelOptions/ShowMoreNewChannelOptions';

import NewChannelErrorMessage from './NewChannelErrorMessage/NewChannelErrorMessage';

const NewChat = () => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(getSearchInput);
  const isAgent = useSelector(getIsAgent);
  const newChannelOptionsSelectedIds = useSelector(getSelectedNewChannelOptionIds);
  const pagination = useSelector(getNewChannelOptionsPagination);
  const newChannelOptionsSelectedUserIds = useSelector(getUserIdsFromSelectedNewChannelOptions);
  const newChannelOptionTypesToFetch = useSelector(getNewChannelOptionTypesToFetch);
  const createChannelIsSuccess = useSelector(getCreateChannelIsSuccess);
  const createChannelError = useSelector(getCreateChannelError);
  const isGroupChat = useSelector(getIsGroupChat);
  const fetchLoading = useSelector(getNewChannelOptionsLoading);
  const fetchFailed = useSelector(getNewChannelOptionsFailed);
  const selectedChannelType = useSelector(getSelectedChannelTypeFilter);
  const createChannelIsLoading = useSelector(getCreateChannelIsLoading);

  // in each slice we load 20 channel options
  const [currentSlice, setCurrentSlice] = useState(1);

  const pageStyle = isAgent ? styles.containerAgent : styles.containerCraftsman;

  const closeNewChat = useCallback(() => {
    dispatch(setGroupChat({ groupChat: false }));
    dispatch(
      setLeftBarTab({
        leftBarTab: isGroupChat ? LEFT_BAR_TABS.NEW_CHAT : LEFT_BAR_TABS.SIDEBAR,
      }),
    );
  }, [dispatch, isGroupChat]);

  const onNext = useCallback(() => {
    return dispatch(createChannel({ userIds: newChannelOptionsSelectedUserIds }));
  }, [dispatch, newChannelOptionsSelectedUserIds]);

  const showMore = useCallback(() => {
    const nextSlice = currentSlice + 1;
    dispatch(fetchNewChannelOptions({ newChannelOptionTypesToFetch, searchTerm, nextSlice }));
    setCurrentSlice(nextSlice);
  // The dependency is ignored because of this trick with JSON.stringify
  // https://stackoverflow.com/questions/59467758/passing-array-to-useeffect-dependency-list/59468261#59468261
  // eslint-disable-next-line
  }, [dispatch, JSON.stringify(newChannelOptionTypesToFetch), searchTerm, currentSlice]);

  const [fetchNewChannelOptionsDebounced] = useDebouncedCallback((args) => {
    dispatch(fetchNewChannelOptions(args));
  }, 300);

  useEffect(() => {
    setCurrentSlice(1);
    fetchNewChannelOptionsDebounced({ newChannelOptionTypesToFetch, searchTerm });
  // eslint-disable-next-line
  }, [dispatch, JSON.stringify(newChannelOptionTypesToFetch), searchTerm]);

  useEffect(() => {
    if (createChannelIsSuccess) {
      dispatch(resetSelectedNewChannelOptions());
      dispatch(
        setLeftBarTab({
          leftBarTab: LEFT_BAR_TABS.SIDEBAR,
        }),
      );
      dispatch(resetCreateChannelAsyncStatus());
    }
  }, [createChannelIsSuccess, dispatch, isGroupChat]);

  useEffect(() => {
    if (createChannelError) {
      dispatch(resetSelectedNewChannelOptions());
      dispatch(resetCreateChannelAsyncStatus());
    }
  }, [createChannelError, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setGroupChat({ groupChat: false }));
      dispatch(setSearchInput({ searchInput: '' }));
    };
  }, [dispatch]);

  return (
    <div className={`${styles.container} ${pageStyle} ${isGroupChat ? styles.groupChat : ''}`}>
      <div className={styles.newChatHeaderWrapper}>
        <div className={styles.newChatHeader}>
          <button
            onClick={closeNewChat}
            type="button"
            className={styles.newChatBack}
          >
            {isGroupChat ? <IconComponent Icon={ICON.CLOSE} alt="Schließen" /> : <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="Zurück" />}
          </button>
          <div className={styles.headline}>
            {isGroupChat ? 'Neue Gruppe erstellen' : 'Neuen Chat starten'}
          </div>
          {!isAgent && isGroupChat && (
            <button
              className={`${styles.newChatNext} ${newChannelOptionsSelectedIds.length === 0 || createChannelIsLoading ? styles.buttonDisabled : ''}`.trim()}
              disabled={newChannelOptionsSelectedIds.length === 0 || createChannelIsLoading}
              onClick={onNext}
              type="button"
            >
              <IconComponent Icon={ICON.ARROW} />
            </button>
          )}
        </div>
        <div className={`${styles.searchWrapper} ${isAgent ? styles.noMargin : ''}`.trim()}>
          {isAgent ? (
            <NewChatAgentSearch onCreateChannel={onNext} />
          ) : (
            <CraftsmanChannelSearchInput
              className={styles.craftsmanSearchInput}
              hideNewChatButton
              placeholder={isGroupChat ? 'Kontakte suchen' : 'Kontakte oder Gruppe suchen'}
            />
          )}
        </div>
        {isAgent && !isGroupChat && <ChannelTypeSelector hideCount />}
        {selectedChannelType !== CHANNEL_FILTER_TYPES.TEAM_CHATS
          && !isGroupChat
          && <NewChatGroupItem />}
      </div>
      <div className={styles.newChatBody}>
        {!isAgent && <NewChatSearchMessage searchTerm={searchTerm} />}
        <NewChannelList />
        {!fetchLoading && !fetchFailed && pagination && pagination.slices > currentSlice && (
          <ShowMoreNewChannelOptions onShowMore={showMore} />
        )}
        {fetchLoading && <LoadingIndicator />}
        {fetchFailed && <NewChannelErrorMessage onShowMore={showMore} />}
      </div>
    </div>
  );
};

export default NewChat;
