import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const VideoLocalPlayer = ({ videoUrl, autoplay = false, controls = true }) => {
  return (
    <div className={styles.videoPreviewWrapper}>
      <video controls={controls} autoPlay={autoplay}>
        <source src={videoUrl} />
        <track kind="captions" />
      </video>
    </div>
  );
};

export default VideoLocalPlayer;

VideoLocalPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
};
