import { createAsyncThunk } from '@reduxjs/toolkit';


export const fetchAgentAvailabilityMessage = createAsyncThunk(
  'agentAvailabilityMessage/fetch',
  async ({ channelId }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAgentAvailability(channelId);
    return { agentAvailabilityMessage: response };
  },
);
