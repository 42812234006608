import { useCallback, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveHouse,
  getActiveHouseId,
  getAllHousesPlainList,
  getHousesLoading,
  getHouseLoading, getHouseLoadingError,
} from 'gcs-common/slices/administration/houses/housesSelectors';
import { fetchHouses, fetchHouse } from 'gcs-common/slices/administration/houses/housesThunks';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { globalHouseIdSelected } from 'gcs-common/slices/administration/houses/housesSlice';
import { resetAdministrationUsers } from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { getJoinedHousesIds } from 'gcs-common/slices/houses/housesSelectors';
import { HousesViews } from 'gcs-common/constants/views';
import styles from './styles.module.scss';

const HouseSelectBar = ({ resetSearchFilter }) => {

  const isGlobalAdmin = useSelector(getHasPermission('global_admin'));

  const activeHouse = useSelector(getActiveHouse);
  const activeHouseId = useSelector(getActiveHouseId);

  const housesLoading = useSelector(getHousesLoading);
  const isOnline = useSelector(getIsOnline);
  const houseLoading = useSelector(getHouseLoading);
  const houseError = useSelector(getHouseLoadingError);
  const houses = useSelector(getAllHousesPlainList);
  const userHouses = useSelector(getJoinedHousesIds);
  const userHousesCount = userHouses?.length;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOnline && !activeHouse && !(houseLoading || housesLoading || houseError)) {
      dispatch(fetchHouse({ houseId: activeHouseId }));
    }
  }, [
    activeHouse,
    activeHouseId,
    dispatch,
    isOnline,
    houseLoading,
    houses,
    housesLoading,
    houseError,
  ]);

  const loadHouses = useCallback((searchQuery = null) => {
    dispatch(fetchHouses({
      searchQuery,
      view: HousesViews.PLAIN,
    }));
  }, [dispatch]);

  useEffect(() => {
    loadHouses();
  }, [loadHouses]);

  const onChange = useCallback(async (value) => {
    const { id: selectedHouseId } = value;
    resetSearchFilter();
    dispatch(resetAdministrationUsers());
    await dispatch(globalHouseIdSelected({ houseId: selectedHouseId }));
  }, [dispatch, resetSearchFilter]);

  if (!isGlobalAdmin && userHousesCount === 1) {
    return (
      <div className={styles.settingsHeader}>
        {activeHouse && activeHouse.friendlyName}
      </div>
    );
  }

  return (
    <div className={styles.select}>
      <Select
        isLoading={houseLoading || housesLoading}
        className={styles.selectBar}
        placeholder="Haus auswählen"
        options={houses}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.friendlyName}
        value={activeHouse}
        onChange={onChange}
        onInputChange={loadHouses}
      />
    </div>
  );
};

HouseSelectBar.propTypes = {
  resetSearchFilter: PropTypes.func.isRequired,
};

export default HouseSelectBar;
