export function cleanPhoneNumber(n) {
  let cleaned = n;
  // 0049 to +49
  cleaned = cleaned.replace(/^0049/, '+49');
  // 0 to +49
  cleaned = cleaned.replace(/^0/, '+49');
  // remove all spaces and non-numeric stuff
  cleaned = cleaned.replace(/[\s-/.()]/g, '');

  return cleaned;
}
