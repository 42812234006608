import { sendMessagesOffline } from '../../slices/messageInput/messageInputActions/sendMessagesOffline';

const queueContainsMessageWithId = (queue, messageId) => {
  return queue.some(queuedAction => {
    if (queuedAction.type !== sendMessagesOffline.type) {
      return false;
    }
    const { meta: { messages } } = queuedAction;
    return messages.some(message => {
      const { clientMessageId } = message;
      return clientMessageId === messageId;
    });
  });
};

const findMessageAndSetDeleteAttribute = (queue, messageId) => {
  return queue.map(queuedAction => {
    if (queuedAction.type !== sendMessagesOffline.type) {
      return queuedAction;
    }

    const { meta: { messages } } = queuedAction;

    const newMessages = messages.map(message => {
      const { clientMessageId } = message;
      if (clientMessageId !== messageId) {
        return message;
      }

      return {
        ...message,
        deleted: true,
      };
    });


    return {
      ...queuedAction,
      meta: {
        ...queuedAction.meta,
        messages: newMessages,
      },
    };
  });
};

/**
 * When a message is deleted (a deleteMessageOffline action is dispatched),
 * we check if the deleted message is still pending to be synced (i.e. is queued in redux-offline).
 *
 * - if the message is queued, the delete attribute if the pending message is set to true
 * and the deleteMessageOffline action is discarded. Thus, when the client goes online,
 * we directly send the deleted message.
 * - if the message is not queued, the deleteMessageOffline action is enqueued as normal,
 * thus, deleting the message normally
 *
 */
export const deleteOfflineHandleEnqueue = (queue, action) => {
  const { meta: { messageId } } = action;

  const messageIsQueued = queueContainsMessageWithId(queue, messageId);

  if (!messageIsQueued) {
    return [...queue, action];
  }

  return findMessageAndSetDeleteAttribute(queue, messageId);
};
