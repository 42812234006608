import PropTypes from 'prop-types';

export default PropTypes.shape({
  Abrufsperre: PropTypes.bool,
  AddSign: PropTypes.bool,
  ArtikelNummer: PropTypes.string,
  Bestellmenge: PropTypes.string,
  Bezeichnung1: PropTypes.string,
  Bezeichnung2: PropTypes.string,
  BruttoPreis: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  Errortext: PropTypes.any,
  GTIN: PropTypes.string,
  ID: PropTypes.number,
  InputBoxUse: PropTypes.bool,
  LVPosition: PropTypes.string,
  LfdWeNr: PropTypes.string,
  Lieferant: PropTypes.string,
  MengeAbrufbar: PropTypes.string,
  MengeMaxAbrufbar: PropTypes.string,
  MengeUrsprung: PropTypes.string,
  Mengen: PropTypes.string,
  NachkommastellenErlaubt: PropTypes.string,
  NettoPreis: PropTypes.string,
  PosKey: PropTypes.string,
  Positionsart: PropTypes.string,
  Positionsnummer: PropTypes.string,
  Positionswert: PropTypes.string,
  Preis: PropTypes.string,
  RetrievalCartQuantity: PropTypes.number,
  ReturnAllowed: PropTypes.bool,
  Symbol: PropTypes.string,
  SymbolTooltip: PropTypes.string,
  Variante: PropTypes.string,
  Werksnummer: PropTypes.string,
});
