/* eslint-disable react/prop-types */

import avatarPlaceHolder from 'gcs-common/img/user.svg';
import styles from './styles.module.scss';

// eslint-disable-next-line react/prop-types
const ProfileImageCell = ({ cell }) => {
  return (
    <div className={styles.profileImageWrapper}>
      <img
        className={styles.profileImage}
        src={cell.value || avatarPlaceHolder}
        alt="profil-bild"
      />
    </div>
  );
};

export default ProfileImageCell;
