import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getAppName, getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { REGISTRATION_ROUTES } from 'gcs-common/constants/routes';
import styles from './styles.module.scss';
import MessageLikeTextBox from '../../MessageLikeTextBox/MessageLikeTextBox';
import Button from '../../Button/Button';

function Welcome() {
  const appName = useSelector(getAppName);
  const identifier = useSelector(getThemeIdentifier);
  const isAgent = useSelector(getIsAgent);
  // TODO
  const extraStyle = identifier === 'gut' ? ` ${styles.gutStyle}` : ' ';
  const navigate = useNavigate();
  const onNext = () => {
    navigate(isAgent
      ? REGISTRATION_ROUTES.REGISTRATION_PROFILE
      : REGISTRATION_ROUTES.REGISTRATION_REGISTRATION_EXTERNAL);
  };

  return (
    <div className={styles.welcomeWrapper}>
      <MessageLikeTextBox width="50%" className={`${styles.headerMessage}${extraStyle}`}>
        Willkommen beim
        {` ${appName}`}
        -Chat!
      </MessageLikeTextBox>

      <MessageLikeTextBox className={styles.termsAndConditionsBox}>
        <span>
          Bitte akzeptieren Sie zunächst
          <br />
          {' '}
          unsere
          {' '}
          <Link
            to={REGISTRATION_ROUTES.REGISTRATION_TERMS}
            className={`${styles.pA}${extraStyle}`}
          >
            Nutzungsbedingungen
          </Link>
          .
        </span>
      </MessageLikeTextBox>
      <MessageLikeTextBox right className={styles.messageBox}>
        <span className={styles.messageBoxText}>
          Ihre Privatsphäre ist uns wichtig.
          {' '}
          <br />
          Ihre persönlichen Daten werden in
          {' '}
          <br />
          Übereinstimmung mit unserer
          {' '}
          <br />
          {' '}
          <Link
            to={REGISTRATION_ROUTES.REGISTRATION_PRIVACY}
            className={`${styles.pA}${extraStyle}`}
          >
            Datenschutzerklärung
          </Link>
          {' '}
          verarbeitet.
        </span>
      </MessageLikeTextBox>
      <Button className={styles.acceptButton} onClick={onNext}>
        Nutzungsbedingungen akzeptieren
      </Button>
    </div>
  );
}

Welcome.propTypes = {};

export default Welcome;
