import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Button from '../../../../Button/Button';

const ErpCustomerUserDelete = ({
  erpCustomerUser, onCancel, handleDelete,
}) => {

  const message = useMemo(() => {
    return `Möchten Sie den Nutzer "${(erpCustomerUser?.friendlyName) || 'Unbekannt'}" wirklich löschen?`;
  }, [erpCustomerUser]);

  return (
    <div className={styles.column}>
      {message}
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

ErpCustomerUserDelete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  erpCustomerUser: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ErpCustomerUserDelete;
