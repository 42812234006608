import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import {
  ALL_FILE_LIST_TYPES,
  FILE_LIST_TYPES,
} from 'gcs-common/constants/fileListConstants';
import {
  getFileListTab, getGroupedFilesByMonth,
  getPreviousFileListErrorMessage,
  getPreviousFileListLoading,
} from 'gcs-common/slices/fileList/fileListSelectors';
import { getChannelHasMoreMessages } from 'gcs-common/slices/channel/channelSelectors';
import loadPreviousMessagesForFileList from 'gcs-common/slices/fileList/fileListThunks/loadPreviousMessagesForFileList';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import { GroupedVirtuoso } from 'react-virtuoso';
import styles from './styles.module.scss';
import FileListTabButton from './FileListTabButton/FileListTabButton';
import MediaItem from './MediaItem/MediaItem';
import DocItem from './DocItem/DocItem';
import LinkItem from './LinkItem/LinkItem';

const FILE_LIST_TYPE_COMPONENTS = {
  [FILE_LIST_TYPES.MEDIA]: MediaItem,
  [FILE_LIST_TYPES.DOCS]: DocItem,
  [FILE_LIST_TYPES.LINKS]: LinkItem,
};

const FILE_PER_ROW = 3;

const splitArrayInChunks = (initialArray, filePerRow) => {
  const chranks = initialArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / filePerRow);
    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  return chranks;
};

function FileList() {
  const previousLoadingError = useSelector(getPreviousFileListErrorMessage);
  const loadingPreviousMessages = useSelector(getPreviousFileListLoading);
  const selectedFiletype = useSelector(getFileListTab);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const channelHasMoreMessages = useSelector(getChannelHasMoreMessages(selectedChannelId));
  const groupedFileListMessageIds = useSelector(getGroupedFilesByMonth(selectedChannelId));
  const [filesPerRow, setFilesPerRow] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFiletype !== FILE_LIST_TYPES.LINKS) {
      setFilesPerRow(FILE_PER_ROW);
      return;
    }
    setFilesPerRow(1);
  }, [selectedFiletype]);
  const months = useMemo(
    () => Object.keys(groupedFileListMessageIds),
    [groupedFileListMessageIds],
  );

  const groupSize = useMemo(() => months.map(
    key => Math.ceil(groupedFileListMessageIds[key].length / filesPerRow),
  ), [groupedFileListMessageIds, months, filesPerRow]);

  const files = useMemo(() => {
    const result = [];
    months.forEach(month => result.push(
      ...splitArrayInChunks(groupedFileListMessageIds[month], filesPerRow),
    ));
    return result;
  }, [groupedFileListMessageIds, months, filesPerRow]);

  const onLoadPreviousMessagesForFileList = useCallback(() => {
    dispatch(loadPreviousMessagesForFileList());
  }, [dispatch]);

  const headers = (
    <div className={styles.headers}>
      {ALL_FILE_LIST_TYPES.map(fileListType => {
        return <FileListTabButton fileListType={fileListType} key={fileListType} />;
      })}
    </div>
  );

  const FiletypeComp = FILE_LIST_TYPE_COMPONENTS[selectedFiletype];
  return (
    <>
      {headers}
      {previousLoadingError && (
        <div className={styles.errorMsg} key={0}>
          <IconComponent Icon={ICON.CLOSE} size={ICON_SIZE.X_SMALL} color={ICON_COLOR.WHITE} />
          &nbsp;
          {previousLoadingError}
        </div>
      )}
      <GroupedVirtuoso
        key={selectedFiletype}
        groupCounts={groupSize}
        groupContent={index => (<div className={styles.monthHeader}>{months[index]}</div>)}
        components={{
          Footer: () => {
            if (channelHasMoreMessages && loadingPreviousMessages) {
              return (
                <div className={styles.loadmoreMsg} key={0}>
                  <Spinner size={ICON_SIZE.XX_SMALL} />
                  &nbsp;Lade frühere Nachrichten...
                </div>
              );
            }
            return (<div />);
          },
        }}
        endReached={() => onLoadPreviousMessagesForFileList(files.length)}
        itemContent={(index) => (
          <div className={styles.itemClassName}>
            {files[index].map(file => <FiletypeComp messageId={file} key={file} />)}
          </div>
        )
        }
      />
    </>
  );
}

export default FileList;
