import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import { fetchTenants } from 'gcs-common/slices/administration/tenants/tenantsThunks';
import { getTenants, getTenantsPaginationResult } from 'gcs-common/slices/administration/tenants/tenantsSelectors';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import detailsIcon from '../../../../img/arrow.svg';
import deletedIcon from '../../../../img/delete.svg';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addCompanyIcon from '../../../../img/plus_white.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import TenantDelete from '../TenantDelete/TenantDelete';
import TenantCreate from '../TenantCreate/TenantCreate';
import { SORT_ORDER } from '../../../../constants/sortConstants';

const columns = [
  {
    Header: 'Name',
    accessor: 'friendlyName',
  },
];

const TenantTable = ({
  onTenantSelected, tenantsLoading,
}) => {

  const tenants = useSelector(getTenants);
  const tenantsPagination = useSelector(getTenantsPaginationResult);
  const globalSearchInput = useSelector(getGlobalSearchInput);

  const dispatch = useDispatch();

  const [createTenant, setCreateTenant] = useState(false);
  const [deleteTenant, setDeleteTenant] = useState(null);

  const accessorToBeSorted = columns.find(element => element.Header).accessor;
  const [sortedColumnId, setSortedColumnId] = useState(accessorToBeSorted);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);

  const loadTenants = useCallback((pageIndex, pageSize, searchQuery) => {
    dispatch(fetchTenants({
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
    }));
  }, [dispatch, sortOrder, sortedColumnId]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setDeleteTenant(original);
    },
    [],
  );

  const tenantIsDeletedSuccessfully = useCallback(() => {
    setDeleteTenant(null);
    dispatch(showSuccessNotification('Erfolgreich entfernt'));
  }, [dispatch]);

  const tenantIsCreatedSuccessfully = useCallback(() => {
    setCreateTenant(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  return (
    <>
      <PaginatedTable
        onRowSelected={onTenantSelected}
        data={tenants}
        columns={columns}
        onPaginationChanged={loadTenants}
        searchInput={globalSearchInput}
        pagination={tenantsPagination}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortedColumnId={sortedColumnId}
        setSortedColumnId={setSortedColumnId}
        sortIsLoading={tenantsLoading}
        renderActions={({ row }) => (
          <>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              alt="deleted-icon"
              src={deletedIcon}
              onClick={(event) => deleteSelected(event, row)}
            />
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      <AddFabButton
        icon={addCompanyIcon}
        title="Tenant"
        onClick={() => setCreateTenant(true)}
      />
      <PopUpModal
        title="Neuer Tenant"
        onRequestClose={() => setCreateTenant(false)}
        isOpen={createTenant}
      >
        <TenantCreate
          onCancel={() => setCreateTenant(false)}
          onSuccess={tenantIsCreatedSuccessfully}
        />
      </PopUpModal>
      {deleteTenant && (
        <PopUpModal
          title="Tenant löschen"
          onRequestClose={() => setDeleteTenant(null)}
          isOpen={!!deleteTenant}
        >
          <TenantDelete
            tenant={deleteTenant}
            onCancel={() => setDeleteTenant(null)}
            onSuccess={tenantIsDeletedSuccessfully}
          />
        </PopUpModal>
      )}
    </>
  );
};

TenantTable.propTypes = {
  onTenantSelected: PropTypes.func.isRequired,
  tenantsLoading: PropTypes.bool.isRequired,
};

export default TenantTable;
