
import { LEGACY_EVENTS } from '../../config/googleAnalyticsLegacyConfig';
import {
  getMessageBody,
  getMessageType,
  getVideoTitle,
  getMessageMediaRelativePath,
  getMessage,
} from '../messages/messageSelector';
import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import { trackGALegacyEvent } from '../googleAnalytics/googleAnalyticsThunks';
import { calculateMessageMediaURL } from '../../helper/mediaHelper';
import { getVideoThumbnailUrl, calculateVideoUrl } from '../../helper/videoHelper';

// eslint-disable-next-line import/prefer-default-export
export const openAsMailToEmail = ({
  subject,
  body,
  messageMediaRelativePaths = [],
}) => async (dispatch, getState, { gccApiClient }) => {
  let bodyWithLinks = body;

  if (messageMediaRelativePaths) {
    // eslint-disable-next-line no-restricted-syntax
    for (const mediaPath of messageMediaRelativePaths) {
      // eslint-disable-next-line no-await-in-loop
      const { url: mediaUrl } = await gccApiClient.createShareableMediaUrl(mediaPath);
      // in the best case email client will preview it, but there is no way to attach otherwise
      bodyWithLinks += '\n\n';
      bodyWithLinks += mediaUrl;
    }
  }
  // for line breaks and proper formatting
  const encodedBody = encodeURIComponent(bodyWithLinks);
  const encodedSubject = encodeURIComponent(subject);
  window.location.href = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
};

// eslint-disable-next-line import/prefer-default-export
export const openAsEmlEmail = ({
  messageIds,
}) => async (dispatch, getState, { gccApiClient }) => {

  dispatch(trackGALegacyEvent(LEGACY_EVENTS.MESSAGE_FORWARD_EMAIL()));

  // NOTE: This solution aims at MS Outlook and might not work with other email clients.
  // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
  const emlContent = await generateEmlContent({
    messageIds,
    gccApiClient,
    getState,
  });

  const data = new Blob([emlContent], { type: 'text/plain' });

  const textFile = window.URL.createObjectURL(data);

  const link = document.createElement('a');
  link.href = textFile;
  link.download = 'beem.eml';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  window.URL.revokeObjectURL(textFile);
};

const generateEmlContent = async ({
  messageIds,
  getState,
}) => {

  // EML Headers should be empty
  let emlContent = 'To:\n';
  emlContent += 'Subject:\n';
  emlContent += 'X-Unsent: 1\n';
  emlContent += 'Content-Type: text/html; charset=utf-8\n\n';

  // EML Content (HTML)

  let htmlBody = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const messageId of messageIds) {
    const type = getMessageType(messageId)(getState());
    const body = getMessageBody(messageId)(getState());
    const mediaRelativePath = getMessageMediaRelativePath(messageId)(getState());
    const message = getMessage(messageId)(getState());

    switch (type) {
      case MESSAGE_TYPE.MEDIA:
      case MESSAGE_TYPE.MEDIA_FROM_MAIL: {
        const mediaUrl = calculateMessageMediaURL(mediaRelativePath);
        htmlBody += `<p><img src=${mediaUrl}></p>`;
        break;
      }
      case MESSAGE_TYPE.VIDEO: {
        const videoTitle = getVideoTitle(messageId)(getState());
        const { videoPlaybackId } = message;
        const videoThumbnailUrl = getVideoThumbnailUrl(videoPlaybackId);
        const videoUrl = calculateVideoUrl(videoPlaybackId);
        htmlBody += `<p><a href=${videoUrl}>${videoTitle}</a></p>`;
        htmlBody += `<p><img src=${videoThumbnailUrl}></p>`;
        break;
      }
      default: {
        htmlBody += `<p>${body}</p>`;
        break;
      }
    }
  }

  emlContent += '<!DOCTYPE html><html><head></head><body>';
  emlContent += htmlBody;
  emlContent += '</body></html>';

  return emlContent;
};
