import React, { ComponentProps, Suspense, useEffect, useState } from 'react';
import { getFileType } from 'gcs-common/helper/fileHelper';
import { createFileURL, removeFileURL } from 'gcs-common/helper/fileUrlHelper';
import { FILE_TYPE } from 'gcs-common/constants/fileTypes';
import fileWhite from '../../img/file_white_outline.svg';
import VideoLocalPlayer from '../VideoLocalPlayer/VideoLocalPlayer';

const PdfPreviewer = React.lazy(() => import('../PdfPreviewer/PdfPreviewer'));

interface LocalFilePreviewProps extends ComponentProps<'img'> {
  file: File | null | undefined;
  filePlaceHolder: string;
}

const LocalFilePreview = (props: LocalFilePreviewProps) => {
  const { file, filePlaceHolder = fileWhite, ...otherProps } = props;
  const fileType = file ? getFileType(file) : undefined;
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);

  useEffect(() => {
    // @ts-expect-error ignore
    if ([FILE_TYPE.IMAGE, FILE_TYPE.VIDEO].includes(fileType)) {
      const url = createFileURL(file);
      setMediaUrl(url);
      return () => removeFileURL(url);
    }
    return () => {};
  }, [file, fileType]);

  const placeHolder = <img alt="local-preview" src={filePlaceHolder} {...otherProps} />;

  if (!file) {
    return placeHolder;
  }

  switch (fileType) {
    case FILE_TYPE.IMAGE:
      return (
        <img
          alt="local-preview"
          src={mediaUrl || filePlaceHolder}
          {...props}
        />
      );
    case FILE_TYPE.PDF:
      return file
          && (
          <Suspense fallback="...">
            <PdfPreviewer pdfFile={file} />
          </Suspense>
          );
    case FILE_TYPE.VIDEO:
      return mediaUrl && <VideoLocalPlayer videoUrl={mediaUrl} />;
    default:
      return placeHolder;
  }
};

export default LocalFilePreview;
