/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedUserId: null,
  selectedChannelId: null,
};

const persistWhitelist = [
  'selectedUserId',
  'selectedChannelId',
];

const memberDetailsSlice = createSlice({
  name: 'memberDetails',
  initialState,
  reducers: {
    currentUserIdSelected: (state, action) => {
      const { userId, channelId } = action.payload;
      state.selectedUserId = userId;
      state.selectedChannelId = channelId;
    },
  },
});

export { persistWhitelist as membersDetailsPersistWhitelist };
export const {
  currentUserIdSelected,
} = memberDetailsSlice.actions;

export default memberDetailsSlice.reducer;
