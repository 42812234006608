import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const stringValidator = Yup.string()
  .min(2, 'Mindestens 2 Zeichen erforderlich')
  .max(50, 'Maximal 50 Zeichen möglich')
  .required('Erforderlich');

export const idValidator = Yup.string()
  .uuid('Ungültige ID (Format: 00000000-0000-0000-0000-000000000000)')
  .nullable();

export const idValidatorRequired = idValidator.required('Erforderlich');

export const craftnoteOrganizationIdValidator = Yup.string()
  .matches(/^OR-[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i, 'Ungültige Craftnote Organization ID (Format: OR-00000000-0000-0000-0000-000000000000)');

export const houseNumbersValidator = Yup.array()
  .nullable()
  .test('Valid house number', 'Hausnummer muss aus genau drei Ziffern bestehen.', (houseNumbers) => {
    if (houseNumbers) {
      return houseNumbers.every(houseNumber => houseNumber.length === 3) && houseNumbers.every(houseNumber => houseNumber.match(/^[0-9]+$/));
    }
    return true;
  })
  .required('Erforderlich');

export const ldaNumbersValidator = Yup.array()
  .nullable()
  .test('Valid LDA numbers', 'LDA muss aus genau drei Ziffern bestehen.', (ldaNumbers) => {
    if (ldaNumbers) {
      return ldaNumbers.every(ldaNumber => ldaNumber.length === 3) && ldaNumbers.every(ldaNumber => ldaNumber.match(/^[0-9]+$/));
    }
    return true;
  })
  .required('Erforderlich');

export const ldaNumberValidator = Yup.string()
  .nullable()
  .test('Valid LDA numbers', 'LDA muss aus genau drei Ziffern bestehen.', (ldaNumber) => {
    if (ldaNumber) {
      return ldaNumber.length === 3 && ldaNumber.match(/^[0-9]+$/);
    }
    return true;
  })
  .required('Erforderlich');

export const validatorRequired = Yup.string().required('Erforderlich');
