import { Navigate } from 'react-router';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useGetCurrentUserIsLoggedIn } from 'gcs-common/clients/api/entities/current-user/current-user-api';
import Login from '../Login/Login';

function LoginRoot() {
  const { isSuccess } = useGetCurrentUserIsLoggedIn();

  if (isSuccess) {
    return <Navigate to={CHAT_ROUTES.CHAT} replace />;
  }

  return (
    <Login />
  );
}

export default LoginRoot;
