import ASYNC_STATE from '../../constants/AsyncState';

export const getSelectedChannelId = state => state.channels.selectedChannelId;
export const getPreSelectedChannelId = state => state.channels.preSelectedChannelId;
export const getChannels = state => state.channels.channels;
export const getChannelColorMaps = state => state.channels.channelColorMaps;

export const getChannelListChannelIds = state => state.channels.channelListChannelIds;

export const getInitialChannelsLoading = state => state.channels.initialChannelsLoading;
export const getChannelsLoading = state => (
  state.channels.fetchChannels.status === ASYNC_STATE.LOADING
);
export const getCreateChannelIsSuccess = (state) => (
  state.channels.createChannel.status === ASYNC_STATE.SUCCEEDED
);
export const getCreateChannelIsLoading = (state) => (
  state.channels.createChannel.status === ASYNC_STATE.LOADING
);
export const getCreateSalesSpaceIsSuccess = (state) => (
  state.channels.createSalesSpace.status === ASYNC_STATE.SUCCEEDED
);
export const getCreateSalesSpaceIsLoading = (state) => (
  state.channels.createSalesSpace.status === ASYNC_STATE.LOADING
);
export const getCreateChannelError = (state) => state.channels.createChannel.error;
export const getCreateSalesSpaceError = (state) => state.channels.createSalesSpace.error;
