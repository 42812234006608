import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PROCESS_BUTTON_TYPES,
  PROCESS_TYPES,
  PRODUCT_BUTTON_TYPES,
} from 'gcs-common/constants/MessageTypes';
import { formatString } from 'gcs-common/helper/stringFormatHelper';
import { pad } from 'gcs-common/helper/paddedNumberHelper';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  channelInputProcessStaged, channelInputProductStaged,
} from 'gcs-common/slices/messageInput/messageInputSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import closeIcon from '../../../img/close.svg';
import Button from '../../Button/Button';
import ProcessPropType from '../../../models/ProcessPropType';
import ProductPropType from '../../../models/ProductPropType';
import styles from './styles.module.scss';

const RenderButtons = ({ process, product = null }) => {

  const isAgent = useSelector(getIsAgent);
  const selectedChannelId = useSelector(getSelectedChannelId);

  const dispatch = useDispatch();

  const [buttonDefinitions, setButtonDefinitions] = useState([]);
  const [isReclaim, setReclaim] = useState(false);
  const [prevDef, setPrefDef] = useState();

  const isProduct = product !== null;

  useEffect(() => {
    // AGENTS CURRENTLY ONLY HAVE ONE BUTTON (ADD_TO_CHAT)!
    if (isAgent) {
      setButtonDefinitions(
        [isProduct ? PRODUCT_BUTTON_TYPES.ADD_TO_CHAT : PROCESS_BUTTON_TYPES.ADD_TO_CHAT],
      );
    } else {
      const processType = PROCESS_TYPES[
        Object.keys(PROCESS_TYPES).find(key => {
          return PROCESS_TYPES[key].type === process.ProcessTypeDescription;
        })];
      if (processType) {
        setButtonDefinitions(isProduct ? processType.productButtons : processType.processButtons);
      } else {
        // Default option, if type is not found
        setButtonDefinitions(
          [isProduct ? PRODUCT_BUTTON_TYPES.ADD_TO_CHAT : PROCESS_BUTTON_TYPES.ADD_TO_CHAT],
        );
      }
    }
  }, [isAgent, process, isProduct]);

  const isAddChat = (buttonType) => {
    return buttonType === PRODUCT_BUTTON_TYPES.ADD_TO_CHAT
      || buttonType === PROCESS_BUTTON_TYPES.ADD_TO_CHAT;
  };

  const handleReclaim = useCallback(() => {
    setReclaim(true);
    setPrefDef(buttonDefinitions);
    setButtonDefinitions(Object.values(PRODUCT_BUTTON_TYPES.RECLAIM.children));
  }, [buttonDefinitions]);

  const handleActionClick = useCallback((buttonDefinition) => {
    if (buttonDefinition === PRODUCT_BUTTON_TYPES.RECLAIM) {
      handleReclaim();
    } else if (isProduct) {
      dispatch(channelInputProductStaged({
        channelId: selectedChannelId,
        subType: buttonDefinition.type,
        text: formatString(buttonDefinition.message, product.ArticleNumber),
        product: {
          ...product,
          containingProcess: process,
        },
      }));
    } else {
      dispatch(channelInputProcessStaged({
        channelId: selectedChannelId,
        subType: buttonDefinition.type,
        text: formatString(
          buttonDefinition.message,
          process.ProcessNumber,
          pad(process.ProcessSuffix),
        ),
        process,
      }));
    }

  }, [dispatch, handleReclaim, isProduct, process, product, selectedChannelId]);

  const stepBackReclaim = () => {
    setButtonDefinitions(prevDef);
    setReclaim(false);
  };

  const putExitIcon = () => {
    return (
      <button
        className={styles.exitReclaimButton}
        type="button"
        onClick={() => stepBackReclaim()}
      >
        <img
          src={closeIcon}
          className={styles.backButtonIcon}
          alt="close-icon"
        />
      </button>
    );
  };

  const buttons = buttonDefinitions.map(
    (buttonDefinition) => {
      return (
        <Button
          key={buttonDefinition.type}
          type="button"
          onClick={() => handleActionClick(buttonDefinition)}
          className={`${styles.btn} ${isAddChat(buttonDefinition) ? styles.addButton : ''}`}
        >
          {buttonDefinition.buttonText}
        </Button>
      );
    },
  );
  return (
    <>
      {isReclaim && putExitIcon()}
      {buttons}
    </>
  );
};

RenderButtons.propTypes = {
  process: ProcessPropType.isRequired,
  product: ProductPropType,
};


export default RenderButtons;
