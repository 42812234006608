import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { setSearchInput } from 'gcs-common/slices/channelList/channelListSlice';
import NewChatButton from '../NewChatButton/NewChatButton';
import styles from './styles.module.scss';
import ChannelSearchBar from '../SearchBar/ChannelSearchBar/ChannelSearchBar';

const CraftsmanChannelSearchInput = ({ className, hideNewChatButton = false, placeholder }) => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(getSearchInput);

  const onSearchInputChange = useCallback((event) => {
    dispatch(setSearchInput({ searchInput: event.target.value }));
  }, [dispatch]);

  return (
    <div className={`${styles.searchWrapper} ${className ?? ''}`.trim()}>
      <ChannelSearchBar
        onChange={onSearchInputChange}
        placeholder={placeholder || 'Kontakte oder Gruppe suchen'}
        value={searchTerm}
      />
      {!hideNewChatButton && <NewChatButton />}
    </div>
  );
};

export default CraftsmanChannelSearchInput;

CraftsmanChannelSearchInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.object,
  hideNewChatButton: PropTypes.bool,
  placeholder: PropTypes.string,
};
