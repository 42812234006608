import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class MediaMessageModel extends AbstractMessageModel {

  constructor(
    {
      pdfThumbnail,
      imgThumbnail,
      mediaRelativePath,
      filename,
      contentType,
      imgDimensionsWidth,
      imgDimensionsHeight,
      messageData,
      mediaSize,
      ...args
    } = {},
  ) {
    super({
      messageType: MESSAGE_TYPE.MEDIA,
      ...args,
    });

    this.pdfThumbnail = pdfThumbnail;
    this.imgThumbnail = imgThumbnail;
    this.mediaRelativePath = mediaRelativePath;
    this.filename = filename;
    this.contentType = contentType;
    this.imgDimensionsWidth = imgDimensionsWidth;
    this.imgDimensionsHeight = imgDimensionsHeight;
    this.messageData = messageData;
    this.mediaSize = mediaSize;
  }
}
