import { createAsyncThunk } from '@reduxjs/toolkit';
import { convertHouseUpdateValues } from '../../../helper/houseAdministrationHelper';
import { convertMessageDeToEn } from '../../../helper/HouseCustomMessageHelper';
import { HousesViews as HousesView } from '../../../constants/views';

export const fetchHouses = createAsyncThunk(
  'houses/fetchHouses',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, view,
  } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getHouseList(
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, view,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination, view };
  },
);

export const fetchHouse = createAsyncThunk(
  'houses/fetchHouse',
  async ({ houseId }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getHouseDetails(houseId);
    return { house: response };
  },
);

export const createHouse = createAsyncThunk(
  'houses/createHouse',
  async ({ house } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.createHouse(house);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // TODO: Reload?
    return { house: response };
  },
);


export const updateSelectedHouse = createAsyncThunk(
  'houses/updateSelected',
  async ({ houseId, values }, { rejectWithValue, extra: { gccApiClient } }) => {
    const updatedValues = convertHouseUpdateValues(values);
    const response = await gccApiClient.updateHouse(houseId, updatedValues);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { house: response };
  },
);

export const fetchHouseCustomMessageTypes = createAsyncThunk(
  'houses/fetchHouseCustomMessageTypes',
  async (_, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getHouseCustomMessageTypes();
    return { customMessageTypes: response };
  },
);


export const validateHouseCustomMessage = createAsyncThunk(
  'houses/validateHouseCustomMessage',
  async ({ customMessage, messageType, parameters, houseId },
    { rejectWithValue, extra: { gccApiClient } }) => {
    const customMessageEn = convertMessageDeToEn(customMessage, parameters);
    const response = await gccApiClient.validateHouseCustomMessage(
      customMessageEn,
      messageType,
      houseId,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return {
      exampleMessage: response.exampleMessage,
      customizedParameters: Object.values(response.customizedParameters),
    };
  },
);

export const fetchSelectedHouse = createAsyncThunk(
  'houses/fetchSelected',
  async ({ houseId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getHouseDetails(houseId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { house: response };
  },
);

export const fetchHouseSelectOptions = (search) => async (dispatch, getState, { gccApiClient }) => {
  const response = await gccApiClient.getHouseList(
    0, 20, search, null, null, HousesView.SELECT_OPTIONS,
  );
  return response?.data;
};
