/* eslint-disable no-use-before-define */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveHouseId } from '../houses/housesSelectors';
import { CHANNEL_TYPE } from '../../../helper/channelTypeHelper';
// eslint-disable-next-line import/no-cycle
import { getSelectedAgent } from './administrationUsersSelectors';
import { UserType } from './administrationUsersConstants';
import { createFileFromUrl } from '../../../helper/fileHelper';

// Note: This thunk-factory creates dynamic substates for each select
export const fetchSelectUsers = createAsyncThunk(
  'administrationUsers/fetchSelectUsers',
  async ({
    pageIndex, pageSize, searchQuery, houseId, userType, erpCustomerId,
  }, { dispatch }) => {

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const { data, pagination } = await dispatch(fetchActiveHouseUsers(
      {
        pageIndex,
        pageSize,
        searchQuery,
        houseId,
        userType,
        erpCustomerId,
      },
    ));
    return { data, pagination };
  },
);

export const fetchAgentsTable = createAsyncThunk(
  'administrationUsers/fetchAgentsTable',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, view = 'agent_table',
  } = { pageIndex: 0, pageSize: 10 }, { dispatch }) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const { data, pagination } = await dispatch(fetchActiveHouseUsers(
      {
        pageIndex,
        pageSize,
        searchQuery,
        userType: UserType.AGENT,
        sortOrder,
        sortedColumnId,
        view,
      },
    ));
    return { data, pagination };
  },
);

export const fetchActiveHouseUsers = (
  {
    pageIndex, pageSize, searchQuery, houseId, userType, erpCustomerId,
    sortOrder, sortedColumnId, view,
  },
) => async (_dispatch, getState, { gccApiClient }) => {
  const activeHouseId = houseId || getActiveHouseId(getState());
  let response;
  if (userType === UserType.CRAFTSMAN) {
    response = await gccApiClient.getCraftsmanUsersForHouse(
      activeHouseId,
      erpCustomerId,
      pageIndex,
      pageSize,
      searchQuery,
      sortOrder,
      sortedColumnId,
    );
  } else if (userType === UserType.AGENT) {
    response = await gccApiClient.getAgentUsersForHouse(
      activeHouseId,
      pageIndex,
      pageSize,
      searchQuery,
      sortOrder,
      sortedColumnId,
      view,
    );
  }

  return response;
};

export const fetchSelectedAgent = createAsyncThunk(
  'administrationUsers/fetchSelectedAgent',
  async ({ agentId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getUserDetails([agentId]);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const agent = response.find(user => user.id === agentId);
    return { agent };
  },
);

export const createAgent = createAsyncThunk(
  'administrationUsers/createAgent',
  async ({ agent }, { rejectWithValue, dispatch, getState, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const response = await gccApiClient.createAgent(agent, activeHouseId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchAgentsTable());
    return { agent: response };
  },
);

export const deleteAgent = createAsyncThunk(
  'administrationUsers/deleteAgent',
  async ({ agentId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteAgent(agentId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return {};
  },
);

export const disconnectAgentFromHouse = createAsyncThunk(
  'administrationUsers/disconnectAgentFromHouse',
  async ({ agentId }, { rejectWithValue, dispatch, getState, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const response = await gccApiClient.disconnectAgentFromHouse(agentId, activeHouseId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchAgentsTable());
    return { agent: response };
  },
);

export const fetchUserDetails = createAsyncThunk(
  'administrationUsers/fetchUserDetails',
  async ({ userId }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getUserDetails([userId]);
    const user = response.find(currentUser => currentUser.id === userId);
    return { user };
  },
);

export const fetchListOfUserDetails = createAsyncThunk(
  'administrationUsers/fetchListOfUserDetails',
  async ({ userIds }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getUserDetails(userIds);
    return { users: response };
  },
);

export const fetchAgentAssignmentsCount = createAsyncThunk(
  'administrationUsers/fetchAgentAssignmentsCount',
  async ({ agentId, houseId }, { extra: { gccApiClient } }) => {
    const { assignmentsCount } = await gccApiClient.getAgentAssignmentsCount(agentId, houseId);
    return {
      agentId,
      assignmentsCount,
    };
  },
);

export const fetchAgentCompanies = createAsyncThunk(
  'administrationUsers/fetchAgentCompanies',
  async ({ agentId }, { extra: { gccApiClient } }) => {
    // eslint-disable-next-line no-unused-vars
    await gccApiClient.getAgentCompanies(agentId);
    // TODO: Fix this in https://gcdigitalteam.atlassian.net/browse/SMOOT-2163
    return { agentCompanies: [] };
    // const { companies: agentCompanies } = response;
    // return { agentCompanies };
  },
);

// TODO refactor according to new channel types
export const fetchAgentDirectChannels = createAsyncThunk(
  'administrationUsers/fetchAgentDirectChannels',
  async ({ agentId, channelTypes = [CHANNEL_TYPE.DIRECT_CHAT] }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAgentChannels(agentId, channelTypes);
    const { channels: agentDirectChannels } = response;
    return { agentDirectChannels };
  },
);

// TODO refactor according to new channel types
export const fetchAgentGroupChannels = createAsyncThunk(
  'administrationUsers/fetchAgentGroupChannels',
  async ({ agentId, channelTypes = [CHANNEL_TYPE.SALES_SPACE] }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAgentChannels(agentId, channelTypes);
    const { channels: agentGroupChannels } = response;
    return { agentGroupChannels };
  },
);

// TODO refactor according to new channel types
export const createAgentDirectChannel = createAsyncThunk(
  'administrationUsers/createAgentDirectChannel',
  async ({ agentId, userId }, { rejectWithValue, getState, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const channelType = CHANNEL_TYPE.DIRECT_CHAT;
    const response = await gccApiClient.createChannel(
      [agentId, userId], activeHouseId, channelType,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { success: response };
  },
);

export const updateSelectedAgent = createAsyncThunk(
  'administrationUsers/updateSelectedAgent',
  async ({ agentId, updateFields }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateSelectedAgent(agentId, updateFields);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { success: response };
  },
);

// TODO refactor according to new channel types
export const createAgentAgentDirectChannel = createAsyncThunk(
  'administrationUsers/createAgentAgentDirectChannel',
  async ({ agentId, agentId2 }, { rejectWithValue, getState, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const channelType = CHANNEL_TYPE.INTERNAL_AGENT;
    const response = await gccApiClient.createChannel(
      [agentId, agentId2], activeHouseId, channelType,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { success: response };
  },
);

// TODO refactor according to new channel types
export const createAgentGroupChannel = createAsyncThunk(
  'administrationUsers/createAgentGroupChannel',
  async ({
    channelName, fileType, channelImage, craftsmanId, agentIds,
  }, { rejectWithValue, getState, extra: { gccApiClient } }) => {
    const selectedAgent = getSelectedAgent(getState());

    const fileName = `${channelName}_${Date.now()}.png`;
    const file = await createFileFromUrl(channelImage, fileName, fileType);
    const {
      preSignedUrl,
      imageUrl: newImageUrl,
    } = await gccApiClient.getPresignedUrl(fileName);

    const response = await fetch(preSignedUrl, {
      method: 'PUT',
      body: file,
    });

    if (!response.ok) {
      throw new Error('Failed uploading group picture');
    }

    const encodedChannelImage = encodeURI(newImageUrl);

    let usersList = [selectedAgent.id];
    if (craftsmanId) {
      usersList = [...usersList, ...craftsmanId];
    }
    if (agentIds) {
      usersList = [...usersList, ...agentIds];
    }

    const channelType = CHANNEL_TYPE.SALES_SPACE;
    const activeHouseId = getActiveHouseId(getState());
    const creatingGroupResponse = await gccApiClient.createChannel(
      usersList, activeHouseId, channelType, encodedChannelImage, channelName,
    );
    if (creatingGroupResponse.error_code) {
      return rejectWithValue({ errorCode: creatingGroupResponse.error_code });
    }
    return { success: response };
  },
);
