const reduceFavorites = (favorites) => {
  return favorites.reduce((red, favorite) => {
    if (!red[favorite.channelId]) {
      // eslint-disable-next-line no-param-reassign
      red[favorite.channelId] = {};
    }
    // eslint-disable-next-line no-param-reassign
    red[favorite.channelId][favorite.id] = favorite;
    return red;
  }, {});
};

export default reduceFavorites;
