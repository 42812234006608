/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useState, useEffect, useLayoutEffect } from 'react';
import { captureMessage } from '@sentry/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, useFormikContext, FormikHelpers,
} from 'formik';
import * as Yup from 'yup';
import { downloadMessageFiles } from 'gcs-common/helper/mediaHelper';
import { MAX_TICKET_ATTACHMENT_SIZE } from 'gcs-common/slices/tickets/ticketsConstants';
import {
  getCreateTicketLoading,
  getTicketFormDraft,
  getChannelTicketFormPrefill,
  getUploadTicketAttachmentsLoading,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import { uploadTicketAttachments, createTicket, patchTicketStatus } from 'gcs-common/slices/tickets/ticketsThunks';
import { getChannelCraftsmanConnections, getChannelTitle } from 'gcs-common/slices/channel/channelSelectors';
import { ticketFormChanged, clearFormPrefill } from 'gcs-common/slices/tickets/ticketsSlice';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import styles from './styles.module.scss';
import TicketDiscardButton from './TicketDiscardButton/TicketDiscardButton';
import {
  showFailureNotification,
  showSuccessNotification,
} from '../../../slices/notifications/ShowNotificationThunk';
// eslint-disable-next-line import/no-cycle
import TicketAttachmentUpload from './TicketAttachmentUpload/TicketAttachmentUpload';
import TicketErrorModal from '../TicketAttachmentSizeErrorModal/TicketAttachmentSizeErrorModal';
import ErpCustomerDropDown from '../../ErpCustomerDropDown/ErpCustomerDropDown';
import TicketInputChannelSelect from './TicketInputChannelSelect/TicketInputChannelSelect';

// TODO
interface Prefill {
  files: (File & { id:string, url:string })[];
  description: string;
}

interface AutoSaveDraftProps {
  channelId?: string | undefined;
}
/**
 * Saves the current form values as draft.
 *
 * This is a separate react component so it can make use of the useFormikContext hook.
 */
const AutoSaveDraft = (props: AutoSaveDraftProps) => {
  const { channelId } = props;
  const dispatch = useDispatch();
  const { values } = useFormikContext();

  useEffect(() => {
    dispatch(ticketFormChanged({ channelId, formValues: values }));
  }, [dispatch, channelId, values]);

  return <></>;
};

interface UpdateFormFieldsProps {
  channelId?: string | undefined;
}
/**
 * Updates the description and attachment field with selected messages.
 * This is not a one-time action, but user can select more messages,
 * even when they are already in the form.
 *
 * This is a separate react component so it can make use of the useFormikContext hook.
 */
const UpdateFormFields = (props: UpdateFormFieldsProps) => {
  const { channelId } = props;
  const { setValues } = useFormikContext();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const prefill: Prefill = useSelector(getChannelTicketFormPrefill(channelId));
  const [showAttachmentSizeError, setShowAttachmentSizeError] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (!prefill) return;

      let files: File[];
      if (prefill.files?.length) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        files = await downloadMessageFiles(prefill.files);
        // @ts-expect-error sentry
        captureMessage('ETS Ticket Attachments - prefilling files', { extra: { channelId, files: files?.length }, tags: ['ets'] });
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setValues((currentValues: TicketFormValues) => {
        const newDescription = [currentValues.description, prefill.description].filter(x => !!x).join('\n\n');

        let newAttachments = currentValues.attachments;

        const filteredFiles = files?.filter(file => file.size <= MAX_TICKET_ATTACHMENT_SIZE);
        if (files && filteredFiles.length !== files.length) {
          // @ts-expect-error sentry
          captureMessage('ETS Ticket Attachments - prefilling files - file size limit', { extra: { channelId, files: files?.length, filteredFiles: filteredFiles?.length }, tags: ['ets'] });
          setShowAttachmentSizeError(true);
        }

        if (filteredFiles?.length) {
          newAttachments = [...newAttachments, ...filteredFiles];
          // @ts-expect-error sentry
          captureMessage('ETS Ticket Attachments - prefilling files - merging files', { extra: { channelId, currentFiles: currentValues?.files?.length, filteredFiles: filteredFiles?.length, newAttachments: newAttachments?.length }, tags: ['ets'] });
        }

        return {
          ...currentValues,
          description: newDescription,
          attachments: newAttachments,
        };
      }, false);

      // Clear prefill state to avoid adding it again
      dispatch(clearFormPrefill({ channelId }));
    })();
  }, [prefill, channelId, dispatch, setValues]);

  return (
    <>
      {showAttachmentSizeError && (
        <TicketErrorModal
          onCancel={() => setShowAttachmentSizeError(false)}
        />
      )}
    </>
  );
};


export interface TicketFormValues {
  summary: string;
  description: string;
  customerOrderText: '',
  attachments: File[],
  erpCustomerId: string,
  inputChannel: string,
}

interface TicketFormProps {
  channelId?: string;
  onClose: VoidFunction;
  predefinedInputChannel?: string;
}

const TicketForm = (props: TicketFormProps) => {
  const { channelId, onClose, predefinedInputChannel } = props;
  const dispatch = useDispatch();

  const channelConnections = useSelector(getChannelCraftsmanConnections(channelId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelConnectionId: string = channelConnections?.[0]?.id;
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const channelCustomerNumber = channelConnections?.[0]?.customerNumber;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelTitle = useSelector(getChannelTitle(channelId));
  const channelName = channelId ? `BEEM - ${channelTitle}` : undefined;
  const creatingTicket = useSelector(getCreateTicketLoading);
  const uploadingAttachments = useSelector(getUploadTicketAttachmentsLoading);
  const loading = creatingTicket || uploadingAttachments;
  const [disableButton, setDisableButton] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const draft: TicketFormValues = useSelector(getTicketFormDraft(channelId));

  const initialValues = {
    summary: '',
    description: '',
    customerOrderText: '',
    attachments: [],
    erpCustomerId: channelConnectionId || '',
    inputChannel: predefinedInputChannel || '',
  };
  const validationSchema = Yup.object().shape({
    summary: Yup.string().required('Erforderlich').matches(/\S/g, 'Erforderlich')
      .max(256, 'Der Titel darf höchstens 256 Zeichen lang sein.'),
    description: Yup.string().required('Erforderlich').matches(/\S/g, 'Erforderlich'),
    erpCustomerId: Yup.string().required('Erforderlich').matches(/\S/g, 'Erforderlich'),
    inputChannel: Yup.string().required('Erforderlich').matches(/\S/g, 'Erforderlich'),
  });

  useLayoutEffect(() => {
    if (channelId && !channelConnectionId) {
      dispatch(showFailureNotification(getErrorMsg('ets.create_ticket.no_connection_id')));
      onClose();
    }
  }, [dispatch, channelConnectionId, onClose, channelId]);

  const submit = useCallback(async (values:TicketFormValues,
    { setSubmitting }:FormikHelpers<TicketFormValues>) => {
    setDisableButton(true);
    try {
      const {
        summary, description, customerOrderText, attachments, erpCustomerId, inputChannel,
      } = values;
      // @ts-expect-error sentry setup
      captureMessage('ETS Ticket Attachments - creating ticket', { extra: { channelId, attachments: attachments?.length }, tags: ['ets'] });

      // TODO
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const result = await dispatch(createTicket(
        // @ts-expect-error redux
        {
          channelId, summary, description, customerOrderText, erpCustomerId, inputChannel,
        },
      ));
      // @ts-expect-error redux
      if (!result.payload.errors && !result.payload.errorCode) {
        if (attachments?.length) {
          // @ts-expect-error sentry
          captureMessage('ETS Ticket - uploading attachments', { extra: { channelId, result }, tags: ['ets'] });
          // eslint-disable-next-line @typescript-eslint/await-thenable
          const attachmentsResult = await dispatch(uploadTicketAttachments(
            // @ts-expect-error redux
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            { ticketId: result.payload.id, attachments },
          ));
          // @ts-expect-error sentry
          captureMessage('ETS Ticket - uploading attachments successful', { extra: { channelId, attachmentsResult }, tags: ['ets'] });
        }
        // @ts-expect-error sentry
        captureMessage('ETS Ticket - submitting successful', { extra: { channelId, result }, tags: ['ets'] });

        dispatch(showSuccessNotification('Ticket erstellt'));
      } else {
        dispatch(showFailureNotification(getErrorMsg('ets.create_ticket.default')));
        // @ts-expect-error sentry
        captureMessage('ETS Ticket - submitting failed', { extra: { channelId }, tags: ['ets'] });
      }
      // TODO
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(patchTicketStatus(
        // @ts-expect-error sentry
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { ticketId: result.payload.id },
      ));
      dispatch(ticketFormChanged({ channelId }));

      onClose();
    } finally {
      setDisableButton(false);
      setSubmitting(false);
    }
  }, [dispatch, onClose, channelId]);

  return (
    <Formik<TicketFormValues>
      /**
       * Form values are channel specific.
       * We need to make sure to re-render the form when the channel id changes,
       * otherwise the values of one channel might override the draft of another channel.
       */
      key={channelId || 'no-channel'}
      initialValues={draft || initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <div className={styles.ticketFormWrapper}>
            <AutoSaveDraft channelId={channelId} />
            <UpdateFormFields channelId={channelId} />

            <div className={styles.formHeader}>
              ETS Ticket Erstellen
            </div>
            <div className={styles.formDetails}>
              <div className={styles.formElement}>
                <div className={styles.formElementTitle}>
                  Ticket Titel
                  <span className={styles.required}>&nbsp;*</span>
                </div>
                <div>
                  <Field className={styles.textbox} name="summary" type="text" />
                  {errors.summary ? (
                    <div className={styles.errorText}>
                      {errors.summary}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.formElement}>
                <div className={styles.formElementTitle}>
                  Beschreibung
                  <span className={styles.required}>&nbsp;*</span>
                </div>
                <div>
                  <Field className={styles.textArea} name="description" as="textarea" />
                  {errors.description && touched.description ? (
                    <div className={styles.errorText}>
                      {errors.description}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={styles.formElement}>
                <div className={styles.formElementTitle}>
                  Anhänge
                </div>
                <div>
                  <TicketAttachmentUpload />
                </div>
              </div>
              <div className={styles.formElement}>
                <div className={styles.formElementTitle}>
                  Kommission
                  <span className={styles.optional}>&nbsp;(Optional)</span>
                </div>
                <Field className={styles.textbox} name="customerOrderText" type="text" />
              </div>
              {channelCustomerNumber
                ? (
                  <div className={`${styles.formElement} ${styles.disabled}`}>
                    <div className={styles.formElementTitle}>Kundennummer</div>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                    <input className={styles.textbox} name="erpCustomerId" type="text" value={channelCustomerNumber} disabled />
                  </div>
                )
                : (
                  <div className={`${styles.formElement}`}>
                    <div className={styles.formElementTitle}>
                      Kundennummer
                      <span className={styles.required}>&nbsp;*</span>
                    </div>
                    <ErpCustomerDropDown
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      onChange={erpCustomerId => { setFieldValue('erpCustomerId', erpCustomerId); }}
                    />
                    {errors.erpCustomerId && touched.erpCustomerId ? (
                      <div className={styles.errorText}>
                        {errors.erpCustomerId}
                      </div>
                    ) : null}
                  </div>
                )
              }
              {!predefinedInputChannel && (
                <div className={styles.formElement}>
                  <div className={styles.formElementTitle}>
                    Eingangskanal
                    <span className={styles.required}>&nbsp;*</span>
                  </div>
                  <div>
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <TicketInputChannelSelect onChange={value => setFieldValue('inputChannel', value)} />
                    {errors.inputChannel && touched.inputChannel ? (
                      <div className={styles.errorText}>
                        {errors.inputChannel}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {channelName && (
                <div className={`${styles.formElement} ${styles.disabled}`}>
                  <div className={styles.formElementTitle}>Eingangskanal</div>
                  <input className={styles.textbox} name="inputChannel" type="text" value={channelName} disabled />
                </div>
              )}
              <div className={styles.buttonWrapper}>
                <TicketDiscardButton
                  channelId={channelId}
                  onClose={onClose}
                  disabled={loading || disableButton}
                  initialValues={initialValues}
                />
                <button disabled={loading} type="submit" className={styles.createTicket}>Erstellen</button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TicketForm;
