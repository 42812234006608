import * as UpChunk from '@mux/upchunk';
import BaseClient from '../baseClient';

class VideoClient extends BaseClient {
  // eslint-disable-next-line class-methods-use-this
  _generateUploadUrl = async () => {
    throw Error('Video client not initialized!');
  };

  init = (generateUploadUrl) => {
    this._generateUploadUrl = generateUploadUrl;
  };

  // eslint-disable-next-line class-methods-use-this
  _muxUploader = (url, file, onProgress) => {
    return new Promise((resolve, reject) => {
      try {
        const upload = UpChunk.createUpload({
          endpoint: url,
          file,
          dynamicChunkSize: true,
        });

        upload.on('progress', progress => {
          const percentage = Math.round(progress.detail);
          onProgress(percentage);
        });

        upload.on('error', error => {
          reject(error.detail);
        });

        upload.on('success', () => {
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  upload = async (file, onProgress = () => {}) => {
    const { id: videoId, url } = await this._generateUploadUrl();

    if (!url || !videoId) {
      throw Error('Video upload url could not be generated!');
    }

    await this._muxUploader(url, file, onProgress);

    return videoId;
  };

}

export default new VideoClient();
