import { useCallback, useEffect } from 'react';
import {
  ICON,
  ICON_SIZE,
  ICON_TRANSFORM,
  ICON_COLOR,
} from 'gcs-common/constants/IconConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTicketAttachments,
  getTicketNotification,
  getTicketRating,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import {
  refetchTicketDetails,
  deleteTicketNotification,
} from 'gcs-common/slices/tickets/ticketsThunks';
import { friendlyDate } from 'gcs-common/helper/dateHelper';
import NotificationBadge from 'gcs-common/components/Notifications/NotificationBadge/NotificationBadge';
import moment from 'moment';
import { DELETE_NOTIFICATION_AFTER_SECONDS, TICKET_INPUT_CHANNELS } from 'gcs-common/slices/tickets/ticketsConstants';
import { Response } from 'gcs-common/types/typeHelper';
import IconComponent from '../../IconComponent/IconComponent';
import TicketParts from './TicketParts/TicketParts';
import styles from './styles.module.scss';
import TicketInfo from './TicketInfo/TicketInfo';
import TicketAttachmentsList from './TicketAttachmentsList/TicketAttachmentsList';
import TicketComments from '../TicketComments/TicketComments';
import TicketStatus from '../TicketStatus';
import TicketRating from '../TicketRating/TicketRating';

interface TicketDetailsProps {
  ticket: Response<'/chat/ets/tickets/{ticket_id}', 'get'>
  onClose: VoidFunction;
}

const TicketDetails = (props: TicketDetailsProps) => {
  const { onClose, ticket } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const attachments: Response<'/chat/ets/tickets/{ticket_id}/attachments', 'get'>['attachments'] = useSelector(getTicketAttachments(ticket.id));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ticketNotification = useSelector(getTicketNotification(ticket.id));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ticketRating: Response<'/chat/ets/tickets/{ticket_id}/rating', 'get'> = useSelector(getTicketRating(ticket.id));
  const isCraftsmanTicket = ticket?.inputChannel === TICKET_INPUT_CHANNELS.craftsman.key;

  const refetchData = useCallback(() => {
    if (!ticket?.id) return;
    // @ts-expect-error redux
    dispatch(refetchTicketDetails({ ticketId: ticket.id }));
  }, [dispatch, ticket?.id]);

  useEffect(() => {
    refetchData();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }, [refetchData, ticketNotification?.updatedAt]);

  useEffect(() => {
    const opened = moment();

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!ticketNotification?.id) return;

      if (moment().diff(opened, 'seconds') < DELETE_NOTIFICATION_AFTER_SECONDS) return;
      // @ts-expect-error redux
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      dispatch(deleteTicketNotification({ id: ticketNotification.id }));
    };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }, [dispatch, ticketNotification?.id]);

  return (
    <div className={styles.ticketWrapper} key={ticket.id}>
      <div className={styles.ticketDetailsHeader}>
        <button onClick={onClose} type="button">
          <IconComponent
            Icon={ICON.ARROW}
            size={ICON_SIZE.LARGE}
            transform={ICON_TRANSFORM.ROTATE_180}
          />
        </button>
        <span>{ticket?.title}</span>
      </div>
      {ticket && (
        <div className={styles.container}>
          <div>
            <div className={styles.ticketDetailsWrapper}>
              <div className={styles.ticketDetailLeft}>
                <span>Erstelldatum</span>
                <span>Ticket ID</span>
                <span>
                  {ticketNotification
                    // eslint-disable-next-line
                    && moment(ticket.statusUpdatedAt).isAfter(ticketNotification.lastViewedAt)
                    ? <NotificationBadge />
                    : <div className={styles.status}> ETS </div>}
                </span>
              </div>
              <div className={styles.ticketDetailRight}>
                <span>
                  {friendlyDate(ticket.createdAt)}
                </span>
                <span>
                  {ticket.externalJiraKey}
                </span>
                <TicketStatus status={ticket.status} />
              </div>
            </div>
          </div>

          {isCraftsmanTicket && (
            <div className={styles.craftsmanTicketWarning}>
              <IconComponent
                Icon={ICON.INFORMATION}
                size={ICON_SIZE.LARGE}
                color={ICON_COLOR.PURPLE}
              />
              <div>
                Diese Anfrage ist von einem Fachhandwerker und daher schreibgeschützt.
              </div>
            </div>
          )}

          {ticket.status === 'done' && (
            <div>
              <TicketParts ticketId={ticket.id} />
            </div>
          )}

          <div className={styles.section}>
            <div className={styles.subTitle}>Beschreibung</div>
            <div>{ticket.description}</div>
          </div>

          {!isCraftsmanTicket && ticketRating && (ticket.status === 'wont_do' || ticket.status === 'done') && (
            <TicketRating
              ticketId={ticket.id}
              ticketStatus={ticket.status}
              comment={ticketRating.comment}
              rating={ticketRating.rating}
              onRatingAdded={refetchData}
            />
          )}

          {attachments && attachments.length > 0 && (
            <div className={styles.section}>
              <div className={styles.subTitle}>Anhänge</div>
              <TicketAttachmentsList ticketId={ticket.id} attachments={attachments} />
            </div>
          )}

          <div className={styles.section}>
            <div className={styles.subTitle}>Kommentare</div>
            <TicketComments ticketId={ticket.id} readOnly={isCraftsmanTicket} />
          </div>

          <div className={styles.divider} />

          <div className={styles.section}>
            <div className={styles.subTitle}>Allgemeine Informationen</div>
            <div>
              <TicketInfo
                customerOrderText={ticket.customerOrderText}
                customerNumber={ticket.customerNumber}
                creator={ticket.creatorName}
                inputChannel={ticket.inputChannel}
                channelName={ticket.channelName}
                houseName={ticket.houseName}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketDetails;
