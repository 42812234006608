import { useReducer } from 'react';
import {
  ICON,
  ICON_COLOR,
  ICON_SIZE,
  ICON_TRANSFORM,
} from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import IconComponent from '../../../../IconComponent/IconComponent';

interface TicketPartProps {
  kbn: string | undefined;
  description: string | undefined;
  manufacturer: string | undefined;
  articleNumber: string | undefined;
  amount: number | undefined;
}

const TicketPart = (props: TicketPartProps) => {
  const {
    kbn, description, manufacturer, articleNumber, amount,
  } = props;
  const [show, toggleShow] = useReducer(old => !old, false);

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.sparePartRow}
        onClick={toggleShow}
        // @ts-expect-error why div with onClick ?
        type="button"
      >
        {kbn ? (
          <span>
            KBN
            &nbsp;
            {kbn}
          </span>
        )
          : (<span>{description || 'Unbekannt'}</span>)}
        <IconComponent
          color={ICON_COLOR.DARK_GREY}
          size={ICON_SIZE.LARGE}
          Icon={ICON.ARROW}
          transform={show ? ICON_TRANSFORM.ROTATE_270 : ICON_TRANSFORM.ROTATE_90}
        />
      </div>
      {show && (
      <div className={styles.sparePartGrid}>
        <div>
          Bez.
        </div>
        <div className={styles.sparePartValue}>{description}</div>
        <div>
          Hersteller
        </div>
        <div className={styles.sparePartValue}>{manufacturer}</div>
        <div>
          Werksnr.
        </div>
        <div className={styles.sparePartValue}>{articleNumber}</div>
        {amount !== undefined && (
          <>
            <div>Menge</div>
            <div className={styles.sparePartValue}>
              {' '}
              {amount}
            </div>
          </>
        )}
      </div>
      )}
    </div>
  );
};

export default TicketPart;
