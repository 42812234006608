import {
  useEffect, useState, useCallback,
} from 'react';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import browserScreenShot from '../../../img/permission_screenshot.png';
import IconComponent from '../../IconComponent/IconComponent';

const PushWarning = () => {
  const [showPermissionWarning, setShowPermissionWarning] = useState(false);
  const [showPermissionDeniedWarning, setShowPermissionDeniedWarning] = useState(false);

  // TODO: Move into custom hook?
  useEffect(() => {
    if (!('Notification' in window)) {
      setShowPermissionWarning(false);
      return;
    }
    if (Notification.permission !== 'granted') {
      setShowPermissionWarning(true);
    }
  }, []);

  const handleAskForPushPermission = useCallback(() => {
    if (!('Notification' in window)) {
      return;
    }
    (async () => {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        setShowPermissionWarning(false);
      }
      if (permission === 'denied') {
        setShowPermissionDeniedWarning(true);
      }
    })();
  }, []);

  const handleClosePushPermission = useCallback(() => {
    setShowPermissionWarning(false);
  }, []);

  const handleClosePushPermissionDenied = useCallback(() => {
    setShowPermissionDeniedWarning(false);
  }, []);

  if (showPermissionDeniedWarning) {
    return (
      <div className={styles.pushDeniedWarning}>
        <div className={styles.permissionDeniedHeader}>
          <IconComponent Icon={ICON.SETTINGS} alt="push-settings" />
          <div>Push-Benachrichtigungen blockiert</div>
          <button
            className={styles.closeIcon}
            type="button"
            onClick={handleClosePushPermissionDenied}
          >
            <IconComponent Icon={ICON.CLOSE} alt="close-push-settings" />
          </button>
        </div>
        <div className={styles.pushDeniedBody}>
          Push-Benachrichtungen werden von deinem Browser blockiert.
          <br />
          Bitte aktiviere sie über deine Browsereinstellungen wie auf dem Beispiel zu sehen.
          Das Menü kann sich je nach Browser unterscheiden.
          <div>
            <img
              className={styles.browserScreenShot}
              src={browserScreenShot}
              alt="browser-screenshot"
            />
          </div>
        </div>
      </div>
    );
  }

  if (showPermissionWarning) {
    return (
      <div className={styles.pushWarning}>
        <button className={styles.settingsIcon} type="button" onClick={handleAskForPushPermission}>
          <IconComponent Icon={ICON.SETTINGS} color={ICON_COLOR.WHITE} alt="push-settings" />
        </button>
        <button className={styles.warningText} type="button" onClick={handleAskForPushPermission}>
          In deinem Browser sind Push-Benachrichtigungen nicht aktiviert.
          <br />
          Klicke hier, um Push-Benachrichtigungen zu erlauben.
        </button>
        <button className={styles.closeIcon} type="button" onClick={handleClosePushPermission}>
          <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.WHITE} alt="close-push-settings" />
        </button>
      </div>
    );
  }

  return null;
};

export default PushWarning;
