import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import ProductPropType from '../../../models/ProductPropType';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

const ProductSelectedDisplay = ({ product, isMessage = false }) => {
  return (
    <div className={isMessage ? styles.productMessage : styles.productWrapperSansBorder}>
      <div className={styles.productArticleNumber}>
        {product.ArticleNumber}
      </div>
      <div className={styles.productDescription}>
        <div>{product.ArticleDescription1}</div>
        <div>{product.ArticleDescription2}</div>
        {/* <ProductQuantity
          product={product}
        /> */}
      </div>
    </div>
  );
};

ProductSelectedDisplay.propTypes = {
  product: ProductPropType.isRequired,
  isMessage: PropTypes.bool,
};

export default ProductSelectedDisplay;
