import { useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { idValidatorRequired, stringValidator } from 'gcs-common/helper/yupValidationHelper';
import { createBusinessUnit } from 'gcs-common/slices/administration/businessUnits/businessUnitsThunks';
import { getCreateBusinessUnitErrorMessage } from 'gcs-common/slices/administration/businessUnits/businessUnitsSelectors';
import { resetCreateBusinessUnitErrorMessage } from 'gcs-common/slices/administration/businessUnits/businessUnitsSlice';
import { fetchTenants } from 'gcs-common/slices/administration/tenants/tenantsThunks';
import { getTenants } from 'gcs-common/slices/administration/tenants/tenantsSelectors';
import styles from './styles.module.scss';
import FormikInput from '../../../FormikInput/FormikInput';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import FormikSelect from '../../../FormikSelect/FormikSelect';

export const BusinessUnitCreateValidationSchema = Yup.object().shape({
  friendlyName: stringValidator,
  tenantId: idValidatorRequired,
});

const initialData = {
  friendlyName: '',
  tenantId: undefined,
};

const BusinessUnitCreate = ({ onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateBusinessUnitErrorMessage);
  const tenants = useSelector(getTenants);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetCreateBusinessUnitErrorMessage()),
      ));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    dispatch(resetCreateBusinessUnitErrorMessage());
  }, [dispatch]);

  const onBusinessUnitNameChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateBusinessUnitErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const getTenantsPage = useCallback((searchQuery) => {
    dispatch(fetchTenants({
      pageIndex: 0,
      pageSize: 10,
      searchQuery,
    }));
  }, [dispatch]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(createBusinessUnit({ businessUnit: values }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={BusinessUnitCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>

          <div className={styles.row}>
            <FormikSelect
              options={tenants}
              onInputChange={getTenantsPage}
              label="Tenant*"
              labelKey="friendlyName"
              valueKey="id"
              name="tenantId"
            />
          </div>

          <div className={styles.row}>
            <FormikInput label="Name*" name="friendlyName" onKeyUp={onBusinessUnitNameChanged} />
          </div>

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

BusinessUnitCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default BusinessUnitCreate;
