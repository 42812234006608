export const LOGIN_FLOW_QUERY_PARAM = 'loginFlow';

export const LOGIN_FLOW = {
  /* pre-rule */
  AGENT: 'login_agent', // only code with email
  AGENT_LINK: 'login_agent_link', // code + link with email
  CRAFTSMAN: 'login_craftsman', // code with email & sms
  REGISTRATION: 'registration', // code with email & sms
  CRAFTNOTE: 'craftnote', // logins coming from craftnote

  /* post-rule - after the auth0 rule is running */
  FINISH_LOGIN_FLOW: 'finish',
};
