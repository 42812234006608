import USER_TYPE from './userTypeHelper';

export const calculateUserName = (user) => {
  if (!user) {
    return 'Gelöschter Nutzer';
  }
  if (user.userType === USER_TYPE.AGENT) {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user?.friendlyName || 'Unbekannter Agent';
  } if (user.userType === USER_TYPE.CRAFTSMAN) {
    if (user.craftsmanName) {
      return user.craftsmanName;
    }
    return user.friendlyName || 'Unbekannter Handwerker';
  }
  return user?.friendlyName || 'Gelöschter Nutzer';

};
