import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { ICON } from '../../constants/IconConstants';
// eslint-disable-next-line import/no-cycle
import CheckoutOPLoginForm, { hasBiometricCredentials, opCredentialIdentifier, setBiometricCredentialsForOp } from '../CheckoutOPLoginForm/CheckoutOPLoginForm';
import {
  goBackFromCheckoutAuthentication, goToCheckoutFinished,
} from '../../slices/quickOrdering/quickOrderingSlice';
import { getSelectedOrderId } from '../../slices/quickOrdering/quickOrderingSelectors';
import { getIsMobile, getIsNative } from '../../slices/init/initSelectors';
import { useSubmitOrderMutation } from '../../clients/api/entities/order-api';

const yupSchema = Yup.object().shape({
  username: Yup.string().required('Erforderlich'),
  password: Yup.string().required('Erforderlich'),
  faceId: Yup.boolean(),
});

export type QuickOrderSubmitFormInput = Yup.InferType<typeof yupSchema>;

const initialValues: QuickOrderSubmitFormInput = {
  username: '',
  password: '',
  faceId: false,
};

const CheckoutAuthentication = () => {

  const dispatch = useDispatch();
  const selectedOrderId = useSelector(getSelectedOrderId);
  const submitOrderMutation = useSubmitOrderMutation();


  const backToOrder = useCallback(() => {
    dispatch(goBackFromCheckoutAuthentication());
  }, [dispatch]);

  const isNative = useSelector(getIsNative);
  const isMobile = useSelector(getIsMobile);

  const onSubmit = async (values : QuickOrderSubmitFormInput) => {
    const isNativePlatform = isMobile && isNative;
    const isBiometricEnabled = isNativePlatform && (values.faceId
        || await hasBiometricCredentials(opCredentialIdentifier));
    if (isBiometricEnabled) {
      // store credentials on device upon successful login
      setBiometricCredentialsForOp({
        username: values.username,
        password: values.password,
      });
    }

    if (!selectedOrderId) {
      return;
    }

    await submitOrderMutation.mutateAsync({
      params: {
        path: {
          order_id: selectedOrderId,
        },
      },
      body: {
        opUsername: values.username,
        opPassword: values.password,
      },
    }).then(() => {
      dispatch(goToCheckoutFinished());
    });

  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <button
            type="button"
            onClick={backToOrder}
          >
            <ICON.ARROW style={{
              transform: 'rotate(180deg)',
              width: '32px',
              height: '32px',
            }}
            />
          </button>
        </div>
        <div className={styles.h1}>
          Schnellbestellung
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        component={(props) => (
          <CheckoutOPLoginForm {...props} submitOrderMutation={submitOrderMutation} />
        )}
        validationSchema={yupSchema}
        onSubmit={onSubmit}

      />
    </div>
  );
};

export default CheckoutAuthentication;
