import { createAsyncThunk } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import { getMessages } from '../messages/messagesSelectors';
import { setToolbarTab } from '../uiState/uiStateSlice';
import { TOOL_TABS } from '../../constants/toolUIFlags';
import { getPlatform } from '../init/initSelectors';
import { filesFromMessages } from '../../helper/mediaHelper';
import { debugLogger } from '../../helper/debugLogger';
import { createFileFromUrl } from '../../helper/fileHelper';

export const fetchTicketNotifications = createAsyncThunk(
  'tickets/fetchTicketNotifications',
  (
    _, { extra: { gccApiClient } },
  ) => {
    return gccApiClient.getETSTicketNotifications();
  },
);

export const deleteTicketNotification = createAsyncThunk(
  'tickets/deleteTicketNotification',
  (
    { id },
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.deleteETSTicketNotification(id);
  },
);

export const createTicket = createAsyncThunk(
  'tickets/createTicket',
  (
    {
      channelId, summary, description, customerOrderText, erpCustomerId, inputChannel,
    } = {},
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.createETSTicket({
      channelId,
      summary,
      description,
      customerOrderText,
      erpCustomerId,
      inputChannel,
    });
  },
);

export const fetchTickets = createAsyncThunk(
  'tickets/fetchTickets',
  async (
    arg,
    { dispatch, extra: { gccApiClient } },
  ) => {
    const channelId = arg?.channelId;

    const pageIndex = 0;
    const pageSize = 1000;
    const tickets = await gccApiClient.getETSTickets({ channelId, pageIndex, pageSize });

    if (tickets.pagination.total > pageSize) {
      // TODO: how to log a warning to sentry in common?
      debugLogger('ETS: fetchTickets - tickets exceed page size - user will not see all data.', { channelId, result: tickets.pagination });
    }

    // keep notifications & tickets in sync
    // (refetching tickets might have generated new notifications due to a server-side update)
    dispatch(fetchTicketNotifications());

    return tickets;
  },
);

export const fetchTicketsForDashboard = createAsyncThunk(
  'tickets/fetchTicketsForDashboard',
  async (
    {
      pageIndex, pageSize, sortOrder, sortedColumnId,
      status, startDate, endDate, erpCustomerIds, search,
      creatorId,
    },
    { extra: { gccApiClient } },
  ) => {
    const tickets = await gccApiClient.getETSTickets({
      pageIndex,
      pageSize,
      sortOrder,
      sortedColumnId,
      status,
      startDate,
      endDate,
      erpCustomerIds,
      search,
      creatorId,
    });
    return tickets;
  },
);

export const fetchTicket = createAsyncThunk(
  'tickets/fetchTicket',
  async (
    { ticketId },
    { dispatch, extra: { gccApiClient } },
  ) => {
    const ticket = await gccApiClient.getETSTicket(ticketId);

    // keep notifications & tickets in sync
    // (refetching tickets might have generated new notifications due to a server-side update)
    dispatch(fetchTicketNotifications());

    return ticket;
  },
);

export const fetchTicketParts = createAsyncThunk(
  'tickets/fetchTicketParts',
  (
    { ticketId },
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.getETSTicketParts(ticketId);
  },
);

export const newTicketFromMessages = createAsyncThunk(
  'tickets/newTicketFromMessages',
  (
    { channelId, messageIds },
    { getState, dispatch },
  ) => {
    const messages = getMessages(getState(), channelId);
    const selectedMessages = messageIds.map(id => messages[id]);

    const files = filesFromMessages(selectedMessages);

    dispatch(setToolbarTab({ tab: TOOL_TABS.TICKETS }));

    return { messages: selectedMessages, files };
  },
);

export const fetchTicketAttachments = createAsyncThunk(
  'tickets/fetchTicketAttachments',
  (
    { ticketId },
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.getETSTicketAttachments(ticketId);
  },
);

const uploadAttachment = async (attachment, platform, ticketId, gccApiClient) => {
  let file = attachment;
  if (!(file instanceof File)) {
    file = await createFileFromUrl(attachment.webPath, attachment.name, attachment.type);
  }
  return gccApiClient.uploadETSTicketAttachments({
    ticketId,
    attachments: [file],
  });
};

export const uploadTicketAttachments = createAsyncThunk(
  'tickets/uploadTicketAttachments',
  async (
    { ticketId, attachments },
    { extra: { gccApiClient }, rejectWithValue, getState },
  ) => {
    const currentState = getState();
    const platform = getPlatform(currentState);
    const uploadPromises = attachments.map(
      (attachment) => uploadAttachment(attachment, platform, ticketId, gccApiClient),
    );

    try {
      const responses = await Promise.all(uploadPromises);

      // Flatten the attachments arrays from the responses
      const uploadedAttachments = {
        attachments: responses.map(x => x.attachments).reduce((acc, cur) => [...acc, ...cur], []),
      };

      return uploadedAttachments;
    } catch (e) {
      captureException(e);
      return rejectWithValue({ errorCode: 'ets.attachment.upload.failed', openErrorDialog: true });
    }
  },
);

export const fetchTicketComments = createAsyncThunk(
  'tickets/fetchTicketComments',
  (
    { ticketId },
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.getETSTicketComments(ticketId);
  },
);

export const patchTicketStatus = createAsyncThunk(
  'tickets/patchTicketStatus',
  async ({ ticketId }, { extra: { gccApiClient } }) => {
    const commentResponse = await gccApiClient.patchETSTicketStatus({ ticketId });

    return commentResponse;
  },
);

export const addTicketComment = createAsyncThunk(
  'tickets/addTicketComment',
  async ({ ticketId, comment, attachments }, { dispatch, extra: { gccApiClient } }) => {
    const commentResponse = await gccApiClient.addETSTicketComment({ ticketId, comment });

    if (attachments.length) {
      await dispatch(uploadTicketAttachments({ ticketId, attachments }));
    }

    return commentResponse;
  },
);

export const deleteTicketComment = createAsyncThunk(
  'tickets/deleteTicketComment',
  (
    { ticketId, commentId },
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.deleteETSTicketComment({ ticketId, commentId });
  },
);

export const fetchTicketRating = createAsyncThunk(
  'tickets/fetchTicketRating',
  (
    { ticketId },
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.getETSTicketRating(ticketId);
  },
);

export const addTicketRating = createAsyncThunk(
  'tickets/createTicket',
  (
    {
      ticketId, rating, comment,
    },
    { extra: { gccApiClient } },
  ) => {
    return gccApiClient.addETSTicketRating({
      ticketId,
      rating,
      comment,
    });
  },
);

export const refetchTicketDetails = createAsyncThunk(
  'tickets/refetchTicketDetails',
  async (
    { ticketId },
    { dispatch },
  ) => {
    dispatch(fetchTicket({ ticketId }));
    dispatch(fetchTicketParts({ ticketId }));
    dispatch(fetchTicketAttachments({ ticketId }));
    dispatch(fetchTicketComments({ ticketId }));
    dispatch(fetchTicketRating({ ticketId }));
  },
);
