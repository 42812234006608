export const CRM_IMPORT_OPTIONS = [
  {
    key: 'An',
    value: true,
  },
  {
    key: 'Aus',
    value: false,
  },
];
