import { createBrowserRouter, Navigate, useLocation } from 'react-router-dom';
import {
  ADMINISTRATION_ROUTES, CHAT_ROUTES, ETS_ROUTES, LOGIN_ROUTES, REGISTRATION_ROUTES,
} from 'gcs-common/constants/routes';

import { Suspense } from 'react';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import ChatRoot from '../components/ChatRoot/ChatRoot';
import App from '../components/App/App';
import TermsAndPrivacy from '../components/TermsAndPrivacy/TermsAndPrivacy';
import Imprint from '../components/Imprint/Imprint';
import AuthProtectedRoute from '../components/ProtectedRoutes/AuthProtectedRoute';
import LoggedInProtectedRoute from '../components/ProtectedRoutes/LoggedInProtectedRoute';
import LoggedInRoot from '../components/LoggedInRoot/LoggedInRoot';
import LoginRoot from '../components/LoginRoot/LoginRoot';
import ChatWrapper from '../components/ChatWrapper/ChatWrapper';
import AdministrationProtectedRoute from '../components/ProtectedRoutes/AdministrationProtectedRoute';
import AdministrationWrapper from '../components/AdministrationWrapper/AdministrationWrapper';
import ChannelDetail from '../components/AdministrationWrapper/Channels/ChannelDetail/ChannelDetail';
import ErpCustomerDetail from '../components/AdministrationWrapper/ErpCustomer/ErpCustomerDetail/ErpCustomerDetail';
import ErpCustomerOverview from '../components/AdministrationWrapper/ErpCustomer/ErpCustomerOverview/ErpCustomerOverview';
import TeamDetail from '../components/AdministrationWrapper/Teams/TeamDetail/TeamDetail';
import TeamOverview from '../components/AdministrationWrapper/Teams/TeamOverview/TeamOverview';
import AgentDetail from '../components/AdministrationWrapper/Agent/AgentDetail/AgentDetail';
import AgentOverview from '../components/AdministrationWrapper/Agent/AgentOverview/AgentOverview';
import HouseDetail from '../components/AdministrationWrapper/House/HouseDetail/HouseDetail';
import HouseOverview from '../components/AdministrationWrapper/House/HouseOverview/HouseOverview';
import TenantDetail from '../components/AdministrationWrapper/Tenants/TenantDetail/TenantDetail';
import TenantOverview from '../components/AdministrationWrapper/Tenants/TenantOverview/TenantOverview';
import BusinessUnitDetail from '../components/AdministrationWrapper/BusinessUnits/BusinessUnitDetail/BusinessUnitDetail';
import BusinessUnitOverview from '../components/AdministrationWrapper/BusinessUnits/BusinessUnitOverview/BusinessUnitOverview';
import CustomerResponsibilityMembersOverview from '../components/AdministrationWrapper/CustomerResponsibilityMembers/CustomerResponsibilityMembersOverview/CustomerResponsibilityMembersOverview';
import FeatureFlagsOverview from '../components/AdministrationWrapper/FeatureFlags/FeatureFlagsOverview';
import TicketDashboard from '../components/Tickets/TicketDashboard/TicketDashboard';
import ApiProtectedRoute from '../components/ProtectedRoutes/ApiProtectedRoute';
import RegistrationRoot from '../components/RegistrationRoot/RegistrationRoot';
import RegisteredUserProtectedRoute from '../components/ProtectedRoutes/RegisteredUserProtectedRoute';
import UserProtectedRoute from '../components/ProtectedRoutes/UserProtectedRoute';
import Welcome from '../components/RegistrationRoot/Welcome/Welcome';
import LinkExternalAccount from '../components/RegistrationRoot/LinkExternalAccount/LinkExternalAccount';
import UserProfile from '../components/RegistrationRoot/UserProfile/UserProfile';
import DeleteAccountPage from '../components/DeleteAccountPage/DeleteAccountPage';
import PermissionProtectedRoute from '../components/ProtectedRoutes/PermissionProtectedRoute';
import ErrorBoundaryFallback from '../components/ErrorBoundaryFallback/ErrorBoundaryFallback';

export interface RegistrationLocationState {
  sourceLocation?: ReturnType<typeof useLocation>;
}

export const routes = [
  {
    path: '/',
    errorElement: <ErrorBoundaryFallback />,
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate to={CHAT_ROUTES.CHAT} replace />,
      },
      {
        element: (
          <AuthProtectedRoute />
        ),
        children: [
          {
            element: (
              <LoggedInProtectedRoute>
                <ApiProtectedRoute>
                  <UserProtectedRoute>
                    <LoggedInRoot />
                  </UserProtectedRoute>
                </ApiProtectedRoute>
              </LoggedInProtectedRoute>
            ),
            children: [
              {
                element: <RegisteredUserProtectedRoute />,
                children: [
                  {
                    path: CHAT_ROUTES.CHAT,
                    element: (
                      <Suspense fallback={<LoadingIndicatorFullScreen loadingText="Lädt Chat..." />}>
                        <ChatRoot />
                      </Suspense>
                    ),
                    children: [
                      {
                        path: ':routeChannelId',
                        element: <ChatWrapper />,
                      },
                    ],
                  },
                  {
                    path: ETS_ROUTES.DASHBOARD,
                    element: <TicketDashboard />,
                  },
                  {
                    path: ADMINISTRATION_ROUTES.ADMINISTRATION,
                    element: (
                      <AdministrationProtectedRoute>
                        <Suspense fallback={<LoadingIndicatorFullScreen loadingText="Lädt..." />}>
                          <AdministrationWrapper />
                        </Suspense>
                      </AdministrationProtectedRoute>
                    ),
                    children: [
                      {
                        path: 'erpCustomers/channel/:id',
                        element: <ChannelDetail />,
                      },
                      {
                        path: 'erpCustomers/:id',
                        element: <ErpCustomerDetail />,
                      },
                      {
                        path: 'erpCustomers',
                        element: <ErpCustomerOverview />,
                      },
                      {
                        path: 'teams/:id',
                        element: <TeamDetail />,
                      },
                      {
                        path: 'teams',
                        element: <TeamOverview />,
                      },
                      {
                        path: 'users/channel/:id',
                        element: <ChannelDetail />,
                      },
                      {
                        path: 'users/:id',
                        element: <AgentDetail />,
                      },
                      {
                        path: 'users',
                        element: <AgentOverview />,
                      },
                      {
                        path: 'houses/:id',
                        element: <HouseDetail />,
                      },
                      {
                        path: 'houses',
                        element: <HouseOverview />,
                      },
                      {
                        path: 'tenants/:id',
                        element: (
                          <PermissionProtectedRoute permission={['global_admin']}>
                            <TenantDetail />
                          </PermissionProtectedRoute>
                        ),
                      },
                      {
                        path: 'tenants',
                        element: (
                          <PermissionProtectedRoute permission={['global_admin']}>
                            <TenantOverview />
                          </PermissionProtectedRoute>
                        ),
                      },
                      {
                        path: 'business_units/:id',
                        element: (
                          <PermissionProtectedRoute permission={['global_admin']}>
                            <BusinessUnitDetail />
                          </PermissionProtectedRoute>
                        ),
                      },
                      {
                        path: 'business_units',
                        element: (
                          <PermissionProtectedRoute permission={['global_admin']}>
                            <BusinessUnitOverview />
                          </PermissionProtectedRoute>
                        ),
                      },
                      {
                        path: 'responsibilities',
                        element: (
                          <PermissionProtectedRoute permission={['administration']}>
                            <CustomerResponsibilityMembersOverview />
                          </PermissionProtectedRoute>
                        ),
                      },
                      {
                        path: 'feature-flags',
                        element: (
                          <PermissionProtectedRoute permission={['administration']}>
                            <FeatureFlagsOverview />
                          </PermissionProtectedRoute>
                        ),
                      },
                    ],

                  },
                ],
              },
              {
                element: <RegistrationRoot />,
                children: [
                  {
                    path: REGISTRATION_ROUTES.REGISTRATION,
                    element: <Welcome />,
                  },
                  {
                    path: REGISTRATION_ROUTES.REGISTRATION_TERMS,
                    element: <TermsAndPrivacy withTerms asModal />,
                  },
                  {
                    path: REGISTRATION_ROUTES.REGISTRATION_PRIVACY,
                    element: <TermsAndPrivacy withPrivacy asModal />,
                  },
                  {
                    path: REGISTRATION_ROUTES.REGISTRATION_PROFILE,
                    element: <UserProfile />,
                  },
                  {
                    path: REGISTRATION_ROUTES.REGISTRATION_EXTERNAL,
                    element: <LinkExternalAccount />,
                  },

                ],
              },
            ],
          },
          {
            path: LOGIN_ROUTES.LOGIN,
            element: <LoginRoot />,
          },
        ],
      },

      {
        path: LOGIN_ROUTES.LOGIN_TERMS,
        element: <TermsAndPrivacy withTerms withPrivacy withOptOut />,
      },
      {
        path: LOGIN_ROUTES.LOGIN_IMPRINT,
        element: <Imprint />,
      },
      {
        path: LOGIN_ROUTES.LOGIN_DELETE_ACCOUNT,
        element: <DeleteAccountPage />,
      },
    ],
  },
  // redirect all nonexisting routes to root
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];

const router = createBrowserRouter(routes);
export default router;
