import { useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { stringValidator } from 'gcs-common/helper/yupValidationHelper';
import {
  addUserToSelectedTeam,
} from 'gcs-common/slices/administration/teams/teamsThunks';
import { getAddUserToSelectedTeamErrorMessage,
  getAddUserToSelectedTeamDefaultErrorMessage } from 'gcs-common/slices/administration/teams/teamsSelectors';
import {
  resetAddUserToSelectedTeamDefaultErrorMessage,
  resetAddUserToSelectedTeamErrorMessage,
} from 'gcs-common/slices/administration/teams/teamsSlice';
import USER_TYPE from 'gcs-common/helper/userTypeHelper';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import TeamPropType from '../../../../models/TeamPropType';
import FormikUserSelect from '../../shared/FormikUserSelect/FormikUserSelect';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

export const TeamAddUserValidationSchema = Yup.object().shape({
  userId: stringValidator,
});

const TeamAddUser = ({ team, onCancel, onSuccess }) => {

  const { id: teamId } = team;

  const dispatch = useDispatch();
  const errorMessage = useSelector(getAddUserToSelectedTeamErrorMessage);
  const defaultErrorMessage = useSelector(getAddUserToSelectedTeamDefaultErrorMessage);

  useEffect(() => {
    dispatch(resetAddUserToSelectedTeamDefaultErrorMessage());
    dispatch(resetAddUserToSelectedTeamErrorMessage());
  }, [dispatch]);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetAddUserToSelectedTeamDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  const resetErrorOnChange = useCallback(() => {
    if (errorMessage) {
      dispatch(resetAddUserToSelectedTeamErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const submit = useCallback(async ({ userId }, { setSubmitting }) => {
    const result = await dispatch(addUserToSelectedTeam({ teamId, userId }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, teamId, onSuccess]);

  return (
    <Formik
      initialValues={{ userId: '' }}
      validationSchema={TeamAddUserValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>
          <div className={styles.row}>

            <FormikUserSelect
              userType={USER_TYPE.AGENT}
              selectKey="teamAddUserAgentSelection"
              label="Mitarbeiter*"
              name="userId"
              labelKey="friendlyName"
              valueKey="id"
              onChange={resetErrorOnChange}
            />
          </div>
          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

TeamAddUser.propTypes = {
  team: TeamPropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TeamAddUser;
