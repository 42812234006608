/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import CHAT_CONNECTION_STATE from '../../constants/ConnectionState';
import {
  initChatSuccess,
  updateConnectionState,
} from './chatConnectionThunks/chatConnectionThunks';

const initialState = {
  chatClientInit: false,
  connectionState: CHAT_CONNECTION_STATE.DISCONNECTED,
};

const chatConnectionSlice = createSlice({
  name: 'chatConnection',
  initialState,
  reducers: {},
  extraReducers: {
    [initChatSuccess.type]: (state) => {
      state.chatClientInit = true;
    },
    [updateConnectionState.type]: (state, action) => {
      const { connectionState } = action.payload;
      state.connectionState = connectionState;
    },
  },
});

export default chatConnectionSlice.reducer;
