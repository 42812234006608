import PropTypes from 'prop-types';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import IconComponent from '../../IconComponent/IconComponent';

const PageHeader = ({ onClose }) => {

  return (
    <div className={styles.pageHeader}>
      <div>
        Abwesenheit verwalten
      </div>
      <button type="button" onClick={onClose}>
        <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.WHITE} style={{ margin: '8px' }} />
      </button>
    </div>
  );
};

PageHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PageHeader;
