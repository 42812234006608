/* eslint-disable jsx-a11y/label-has-for */

import PropTypes from 'prop-types';
import { Field } from 'formik';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import styles from './styles.module.scss';

const FormikOpeningHours = ({
  textClassName,
  disabled = true,
  label = '',
  label1 = '',
  label2 = '',
  start1 = '',
  start2 = '',
  end1 = '',
  end2 = '',
  type = 'time',
  placeholder = '',
  ...props
}) => {

  return (
    <div className={styles.formikOpeningHoursContainer}>
      <div className={`${styles.formikOpeningHoursWrapper} ${disabled ? styles.disabled : ''}`}>
        <table>
          <tbody>
            <tr>
              <th>
                <div className={styles.formikOpeningHoursLabel}>{label}</div>
              </th>
            </tr>
            <tr>
              <th>
                <div className={styles.formikOpeningHoursLabel}>{label1}</div>
              </th>
              <td>
                <Field
                  id={start1}
                  className={textClassName || styles.formikOpeningHours}
                  placeholder={placeholder}
                  type={type}
                  name={start1}
                  disabled={disabled}
                  {...props}
                />
                <ValidationError name={start1} />
              </td>
              <td>
                <Field
                  id={end1}
                  className={textClassName || styles.formikOpeningHours}
                  placeholder={placeholder}
                  type={type}
                  name={end1}
                  disabled={disabled}
                  {...props}
                />
                <ValidationError name={end1} />
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.formikOpeningHoursLabel}>{label2}</div>
              </th>
              <td>
                <Field
                  id={start2}
                  className={textClassName || styles.formikOpeningHours}
                  placeholder={placeholder}
                  type={type}
                  name={start2}
                  disabled={disabled}
                  {...props}
                />
                <ValidationError name={start2} />
              </td>
              <td>
                <Field
                  id={end2}
                  className={textClassName || styles.formikOpeningHours}
                  placeholder={placeholder}
                  type={type}
                  name={end2}
                  disabled={disabled}
                  {...props}
                />
                <ValidationError name={end2} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

FormikOpeningHours.propTypes = {
  start1: PropTypes.string,
  end1: PropTypes.string,
  start2: PropTypes.string,
  end2: PropTypes.string,
  label: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  textClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormikOpeningHours;
