import { useMemo } from 'react';
import PropTypes from 'prop-types';
import MemberListItem from '../MemberListItem/MemberListItem';
import styles from './styles.module.scss';

const MemberListSection = ({ channelId, title = '', memberUserIds, onSelectMember }) => {
  const memberListItem = useMemo(() => {
    return memberUserIds ? memberUserIds.map((userId, index) => (
      <MemberListItem
        userId={userId}
        channelId={channelId}
        key={userId}
        className={index === memberUserIds.length - 1 ? styles.lastMemberListItem : ''}
        onClick={() => {
          if (onSelectMember) {
            onSelectMember({ userId });
          }
        }}
      />
    )) : null;
  }, [memberUserIds, channelId, onSelectMember]);

  return (
    <div>
      { memberUserIds.length > 0 && title
            && (
            <div className={styles.detailsSectionTitle}>
              {title}
            </div>
            )
      }

      <div className={styles.detailsWrapper} aria-label={title}>
        {memberListItem}
      </div>
    </div>
  );
};

MemberListSection.propTypes = {
  channelId: PropTypes.string.isRequired,
  title: PropTypes.string,
  memberUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectMember: PropTypes.func.isRequired,
};

export default MemberListSection;
