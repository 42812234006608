import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';

import styles from './styles.module.scss';

const SettingsButton = ({ text, icon, onClick = () => {} }) => {
  const isAgent = useSelector(getIsAgent);
  return (
    <button type="button" className={`${isAgent ? styles.containerDark : styles.container}`} onClick={onClick}>
      <div className={styles.icon}>
        {icon}
      </div>
      <div className={styles.textContainer}>
        {text}
      </div>
      <IconComponent Icon={ICON.ARROW} color={ICON_COLOR.DARKEST_GREY} style={{ marginLeft: 'auto', minWidth: '24px' }} />
    </button>
  );
};

SettingsButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default SettingsButton;
