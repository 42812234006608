import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  stringValidator,
  craftnoteOrganizationIdValidator,
  houseNumbersValidator,
  ldaNumbersValidator,
} from 'gcs-common/helper/yupValidationHelper';
import { fetchSelectedHouse, updateSelectedHouse } from 'gcs-common/slices/administration/houses/housesThunks';
import {
  houseSelectionReset,
} from 'gcs-common/slices/administration/houses/housesSlice';
import {
  getIsFetchSelectedHouseSucceeded,
  getSelectedHouseDetails,
  getSelectedHouseLoading,
} from 'gcs-common/slices/administration/houses/housesSelectors';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { toTimeConversion } from 'gcs-common/helper/houseAdministrationHelper';
import { fetchTeams } from 'gcs-common/slices/administration/teams/teamsThunks';
import fetchCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUser';
import { getSelectedHouseId } from 'gcs-common/slices/houses/housesSelectors';
import { USER_PERMISSIONS } from 'gcs-common/helper/userTypeHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import Tabs from '../../../Tabs/Tabs';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import styles from './styles.module.scss';
import backIcon from '../../../../img/back.svg';
import FormikInput from '../../../FormikInput/FormikInput';
import FormikList from '../../../FormikList/FormikList';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import FormikOpeningHours from '../../../FormikOpeningHours/FormikOpeningHours';
import EditIcon from '../../../../img/edit.svg?react';
import CheckIcon from '../../../../img/check_green.svg?react';
import CancelIcon from '../../../../img/close_red.svg?react';

import HouseReadConfirmation from '../HouseReadConfirmation/HouseReadConfirmation';
import HouseCustomMessages from '../HouseCustomMessage/HouseCustomMessages';
import HouseDataSource from '../HouseDataSource/HouseDataSource';
import { HOLIDAY_CODES } from '../../../../constants/holidayCodes';
import HouseCommunicationStructure from '../../HouseCommunicationStructure/CommunicationStructure/HouseCommunicationStructure/HouseCommunicationStructure';
import PermissionProtectedRoute from '../../../ProtectedRoutes/PermissionProtectedRoute';

const requiredFields = ['MoDoStart', 'MoDoEnd', 'MoDoStartLateShift', 'MoDoEndLateShift',
  'FrStart', 'FrEnd', 'FrStartLateShift', 'FrEndLateShift'];

const HouseDetail = () => {

  const HouseCreateValidationSchema = Yup.object().shape({
    friendlyName: stringValidator,
    province: stringValidator,
    MoDoStart: Yup.string().required('Erforderlich'),
    MoDoEnd: Yup.string().required('Erforderlich'),
    FrStart: Yup.string().required('Erforderlich'),
    FrEnd: Yup.string().required('Erforderlich'),
    MoDoStartLateShift: Yup.string().required('Erforderlich'),
    MoDoEndLateShift: Yup.string().required('Erforderlich'),
    FrStartLateShift: Yup.string().required('Erforderlich'),
    FrEndLateShift: Yup.string().required('Erforderlich'),
    houseNumbers: houseNumbersValidator,
    ldaNumbers: ldaNumbersValidator,
    craftnoteOrganizationId: Yup.string().when('craftnoteApiKey', {
      is: (craftnoteApiKey) => !!craftnoteApiKey,
      then: () => craftnoteOrganizationIdValidator.required(
        'Craftnote Organization ID wird benötigt wenn Craftnote API Key gesetzt ist',
      ),
      otherwise: () => craftnoteOrganizationIdValidator,
    }),
    craftnoteApiKey: Yup.string(),
  }, [['craftnoteApiKey', 'craftnoteOrganizationId']]);

  const hasGlobalAdminPermission = useSelector(getHasPermission('global_admin'));

  const agentHouseId = useSelector(getSelectedHouseId);
  const { id: houseId } = useParams();
  // const [preferenceChanged, setPreferenceChanged] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const selectedHouse = useSelector(getSelectedHouseDetails);
  const selectedHouseLoading = useSelector(getSelectedHouseLoading);
  const isSelectedHouseLoaded = useSelector(getIsFetchSelectedHouseSucceeded);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateCraftnoteApiKey = (values) => {
    // craftnote credentials can be set only once,
    // if organizationID not changed, API key should not be changed
    const errors = {};
    if (!selectedHouse?.craftnoteOrganizationId && !values.craftnoteOrganizationId) {
      return errors;
    }
    if (selectedHouse?.craftnoteOrganizationId === values.craftnoteOrganizationId) {
      return errors;
    }

    // check if craftnoteApiKey is a valid UUID
    if (!values.craftnoteApiKey.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)) {
      errors.craftnoteApiKey = 'Ungültige ID (Format: 00000000-0000-0000-0000-000000000000)';
    }

    return errors;
  };

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedHouse({ houseId }));
    return () => dispatch(houseSelectionReset());
  }, [dispatch, houseId]);

  const cancelEditing = useCallback((event) => {
    event.preventDefault();
    // setPreferenceChanged(false);
    setIsEditing(false);
    // setIsCanceled(true);
  }, []);

  const startEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const holidaySelectOptions = HOLIDAY_CODES.map(h => {
    const { name, code } = h;
    return { value: code, label: name };
  });

  const {
    businessUnitName,
    friendlyName,
    province,
    openingHours,
    lateShift,
    houseNumbers,
    ldaNumbers,
    craftnoteApiKey,
    craftnoteOrganizationId,
  } = selectedHouse || {};

  const initialValues = useMemo(() => {
    // Note: Formik/React creates a warning if a value is "null"
    // so we set all fields to at least ""
    // https://formik.org/docs/api/formik#initialvalues-values
    const currentOpeningHours = toTimeConversion(openingHours);
    const currentLateShift = toTimeConversion(lateShift);
    return ({
      businessUnitName: businessUnitName || '',
      friendlyName: friendlyName || '',
      province: province || '',
      craftnoteApiKey: craftnoteApiKey || '',
      craftnoteOrganizationId: craftnoteOrganizationId || '',
      MoDoStart: currentOpeningHours.MoDoStart || '',
      MoDoEnd: currentOpeningHours.MoDoEnd || '',
      FrStart: currentOpeningHours.FrStart || '',
      FrEnd: currentOpeningHours.FrEnd || '',
      MoDoStartLateShift: currentLateShift.MoDoStart || '',
      MoDoEndLateShift: currentLateShift.MoDoEnd || '',
      FrStartLateShift: currentLateShift.FrStart || '',
      FrEndLateShift: currentLateShift.FrEnd || '',
      houseNumbers: houseNumbers || '',
      ldaNumbers: ldaNumbers || '',
    });
  }, [
    openingHours, lateShift, businessUnitName,
    friendlyName, province, houseNumbers, ldaNumbers, craftnoteOrganizationId, craftnoteApiKey]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const changedValues = Object.keys(values).reduce((red, key) => {
      // all of those should be passed to create a valid data range
      if (values[key] === initialValues[key]) return red;
      return {
        ...red,
        [key]: values[key],
      };
    }, {});

    if (changedValues && Object.keys(changedValues).length === 0) {
      setIsEditing(false);
      setSubmitting(false);
      return;
    }

    requiredFields.forEach((key) => { changedValues[key] = values[key]; });
    const result = await dispatch(updateSelectedHouse({ houseId, values: changedValues }));
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich geändert'));
      setIsEditing(false);
      // setPreferenceChanged(false);
    }
    if (agentHouseId === houseId) {
      dispatch(fetchCurrentUser());
    }
    setSubmitting(false);
  }, [agentHouseId, dispatch, houseId, initialValues]);

  return (
    <div data-testid="houseDetailComponent">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={HouseCreateValidationSchema}
        validate={validateCraftnoteApiKey}
        onSubmit={submit}
        validateOnChange
      >
        {({
          isSubmitting, resetForm,
        }) => (
          <Form noValidate>

            <div className={styles.headerRow}>
              <div className={styles.row}>
                {hasGlobalAdminPermission
                && (
                  <button
                    className={styles.backButton}
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    <img src={backIcon} alt="back-icon" />
                  </button>
                )}
                <div className={styles.inputHeaderText}>{friendlyName}</div>

              </div>
              {selectedHouseLoading && <LoadingIndicator padding="0px" />}
              <div className={styles.buttonsWrapper}>
                {isEditing ? (
                  <>
                    <button
                      data-testid="saveButton"
                      className={styles.saveButton}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <CheckIcon />
                    </button>
                    <button
                      className={styles.saveButton}
                      type="button"
                      onClick={(event) => {
                        resetForm();
                        cancelEditing(event);
                      }}
                      disabled={isSubmitting}
                    >
                      <CancelIcon />
                    </button>
                  </>
                ) : (
                  <button
                    data-testid="editButton"
                    className={styles.saveButton}
                    type="button"
                    onClick={startEditing}
                    disabled={!isSelectedHouseLoaded}
                  >
                    <EditIcon />
                  </button>
                )}
                {isSubmitting && <LoadingIndicator padding="0px" />}
              </div>
            </div>

            <div className={styles.row}>

              <FormikInput
                disabled={!isEditing}
                label="Name"
                name="friendlyName"
              />

              <FormikInput disabled label="Marke" name="businessUnitName" />

            </div>

            <div className={styles.row}>

              <FormikList
                disabled={!isEditing || !hasGlobalAdminPermission}
                label="LDA"
                name="ldaNumbers"
                initialValue={ldaNumbers}
                distinctValues
              />
              <FormikList
                disabled={!isEditing || !hasGlobalAdminPermission}
                label="Hausnummer"
                name="houseNumbers"
                initialValue={houseNumbers}
                distinctValues
              />

            </div>

            <div className={styles.row}>

              <FormikSelect
                disabled={!isEditing}
                label="Bundesland"
                name="province"
                placeholder="Bitte wählen"
                options={holidaySelectOptions}
                labelKey="label"
                valueKey="value"
              />

            </div>

            <div className={styles.row}>

              <FormikOpeningHours
                disabled={!isEditing}
                label="Öffnungszeiten"
                label1="Mo-DO"
                label2="Fr"
                start1="MoDoStart"
                end1="MoDoEnd"
                start2="FrStart"
                end2="FrEnd"
              />

              <FormikOpeningHours
                disabled={!isEditing}
                label="Spätschicht"
                label1="Mo-DO"
                label2="Fr"
                start1="MoDoStartLateShift"
                end1="MoDoEndLateShift"
                start2="FrStartLateShift"
                end2="FrEndLateShift"
              />

            </div>

            <PermissionProtectedRoute permission={[USER_PERMISSIONS.GLOBAL_ADMIN.value]}>
              <>
                <div className={styles.row}>
                  <FormikInput
                    disabled={!isEditing || craftnoteOrganizationId?.length > 0}
                    label="Craftnote Organization Id"
                    name="craftnoteOrganizationId"
                  />
                  <FormikInput
                    disabled={!isEditing || craftnoteApiKey?.length > 0}
                    label="Craftnote API Key"
                    name="craftnoteApiKey"
                  />
                </div>
                {(craftnoteOrganizationId?.length > 0 && craftnoteApiKey?.length > 0) && (
                  <div className={styles.hint}>
                    Mit Craftnote verbunden (kann nur initial gesetzt werden).
                  </div>
                )}
              </>
            </PermissionProtectedRoute>

          </Form>
        )}
      </Formik>

      <div className={styles.tabWrapper}>
        <Tabs
          onActiveTabChanged={() => {}}
          disabled={!isSelectedHouseLoaded}
          tabs={{
            communicationStructure: {
              name: 'Kommunikationsstruktur',
              component: (
                <>
                  {isSelectedHouseLoaded && (
                    <HouseCommunicationStructure houseId={houseId} />
                  )}
                </>
              ),
            },
            customMessage: {
              name: 'Automatische Nachrichten',
              component: (
                <>
                  {isSelectedHouseLoaded && <HouseCustomMessages />}
                </>
              ),
            },
            dateSource: {
              name: 'Datenquellen',
              component: (
                <HouseDataSource />
              ),
            },
            readConfirmation: {
              name: 'Lesebestätigungen',
              component: (
                <HouseReadConfirmation />
              ),
            },
          }}
        />
      </div>
    </div>
  );
};
export default HouseDetail;
