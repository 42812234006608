/* eslint-disable css-modules/no-unused-class */
import styles from './styles.module.scss';

// Note: usually we dont use styled components, this is an exception
// as otherwise we would some hacky workarounds to style the react-select
// Lib: https://github.com/JedWatson/react-select

const selectCustomStyles = {
  menu: (provided) => ({
    ...provided,
    marginBottom: '0px',
    marginTop: '-2px',
    borderRadius: '0px 0px 8px 8px',
    borderRight: `1px solid ${styles.borderColors}`,
    borderBottom: `1px solid ${styles.borderColors}`,
    borderLeft: `1px solid ${styles.borderColors}`,
    background: '#FCFCFC',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided) => ({
    ...provided,
    borderTop: `1px solid ${styles.borderColors}`,
    div: {
      marginBottom: '0px',
    },
    ':hover': {
      backgroundColor: styles.optionBackgroundColorHover,
    },
    backgroundColor: styles.optionBackgroundColor,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 'unset',
    border: 'unset',
    boxShadow: 'unset',
    backgroundColor: 'transparent',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    display: state.isMulti ? 'none' : 'block',
  }),
  // Styles the class indicatorContainer, as per:
  // eslint-disable-next-line max-len
  // https://stackoverflow.com/questions/63702778/how-to-remove-the-padding-of-the-indicatorcontainer-first-child-of-indicatorsco
  clearIndicator: (provided) => ({
    ...provided,
    padding: '6px 8px 0 0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '32px',
    paddingLeft: '10px',
    div: {
      marginBottom: '0px',
    },
    overflow: 'visible',
    rowGap: '10px',
    columnGap: '6px',
  }),
  singleValue: (provided) => ({
    ...provided,
    height: '24px',
    alignItems: 'center',
    overflow: 'visible',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: state.data.color,
    color: 'white',
    minWidth: state.selectProps.optionBackgroundColor && '90%',
    div: {
      border: 'none',
      marginBottom: '0px',
    },

  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: '400',
  }),
};

export default selectCustomStyles;
