import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetRemovingUserFromSelectedGroupChannelDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSlice';
import {
  getIsRemovingUserFromGroupChannelLoading,
  getRemovingUserFromSelectedGroupChannelDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSelectors';
import {
  fetchSelectedAdministrationChannel, removeUserFromGroupChannel,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const GroupChannelMemberDelete = ({
  user,
  channelId,
  channelName,
  onCancel,
  onSuccess,
}) => {
  const isDeleting = useSelector(getIsRemovingUserFromGroupChannelLoading);
  const dispatch = useDispatch();
  const defaultErrorMessage = useSelector(
    getRemovingUserFromSelectedGroupChannelDefaultErrorMessage,
  );

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetRemovingUserFromSelectedGroupChannelDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetRemovingUserFromSelectedGroupChannelDefaultErrorMessage());
  }, [dispatch]);

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(removeUserFromGroupChannel(
        { channelId, memberId: user.id },
      ));
      if (!result.error) {
        onSuccess();
        await dispatch(fetchSelectedAdministrationChannel({ channelId }));
      }
    },
    [user, channelId, dispatch, onSuccess],
  );

  return (
    <div className={styles.column}>
      Möchten Sie von den Nutzer
      {' "'}
      {user.name}
      {'" '}
      wirklich aus dem Channel
      {' "'}
      {channelName}
      {'" '}
      entfernen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

GroupChannelMemberDelete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default GroupChannelMemberDelete;
