import { TWILIO_MAX_UPLOAD_SIZE, EXCEL_MAX_UPLOAD_SIZE } from './fileSizes';

export const FILE_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  EXCEL: 'EXCEL',
  PDF: 'PDF',
  DEFAULT: 'DEFAULT',
} as const;
export type FileTypeKey = keyof typeof FILE_TYPE;
export type FileTypeValue = typeof FILE_TYPE[FileTypeKey];

export const FILE_SUB_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;
export type FileSubTypeKey = keyof typeof FILE_SUB_TYPE;
export type FileSubTypeValue = typeof FILE_SUB_TYPE[FileSubTypeKey];

type FileTypeMappings = {
  [key in FileTypeValue]: {
    maxSize: number;
    fileExtensions: string[];
  }
};

export const FILE_TYPE_MAPPINGS: FileTypeMappings = {
  [FILE_TYPE.IMAGE]: {
    maxSize: TWILIO_MAX_UPLOAD_SIZE,
    fileExtensions: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml', 'image/webp'],
  },
  [FILE_TYPE.VIDEO]: {
    maxSize: TWILIO_MAX_UPLOAD_SIZE,
    fileExtensions: [
      'video/avi', 'video/mpeg', 'video/mp4', 'video/ogg', 'video/quicktime',
      'video/webm', 'video/x-matroska', 'video/x-ms-wmv', 'video/x-flv', 'video/mov',
    ],
  },
  [FILE_TYPE.DEFAULT]: {
    maxSize: TWILIO_MAX_UPLOAD_SIZE,
    fileExtensions: [],
  },
  [FILE_TYPE.EXCEL]: {
    maxSize: EXCEL_MAX_UPLOAD_SIZE,
    fileExtensions: ['xlsx', 'xls'],
  },
  [FILE_TYPE.PDF]: {
    maxSize: TWILIO_MAX_UPLOAD_SIZE,
    fileExtensions: ['application/pdf'],
  },
  [FILE_TYPE.TEXT]: {
    maxSize: TWILIO_MAX_UPLOAD_SIZE,
    fileExtensions: [],
  },
};

type FileSubTypeMappings = {
  [key in FileSubTypeValue]: {
    fileExtensions: string[];
  }
};

export const FILE_SUB_TYPE_MAPPINGS: FileSubTypeMappings = {
  [FILE_SUB_TYPE.IMAGE]: {
    fileExtensions: ['jpg', 'jpeg', 'png', 'gif', 'svg', 'svg+xml', 'webp'],
  },
  [FILE_SUB_TYPE.VIDEO]: {
    fileExtensions: ['mpeg', 'mp4', 'ogg', 'quicktime', 'webm', 'x-matroska', 'x-ms-wmv', 'x-flv'],
  },
};
