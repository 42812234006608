import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import Highlight from '../Highlight/Highlight';
import UnreadCountDisplay from '../UnreadCountDisplay/UnreadCountDisplay';
import ChannelBrandLogo from '../ChannelBrandLogo/ChannelBrandLogo';
import CheckBox from '../CheckBox/CheckBox';
import IconComponent from '../IconComponent/IconComponent';


const getChannelStyle = ({ isSelectedChannel, isAgent, isNewChannelOption, multiSelect }) => {
  const mainStyle = isAgent ? styles.channelListItem : styles.craftsmanChannelListItem;
  let selectedStyle = isAgent
    ? styles.unselectedChannelListItem
    : styles.unselectedCraftsmanChannelListItem;
  if (isSelectedChannel) {
    selectedStyle = isAgent
      ? styles.selectedChannelListItem
      : styles.selectedCraftsmanChannelListItem;
  }

  const isNewChannelOptionStyle = isNewChannelOption ? styles.newChannel : '';
  const isAgentStyle = isAgent ? styles.agentStyle : '';
  const groupChannelSelectedStyle = multiSelect && isSelectedChannel ? styles.selectedGroupChannelListItem : '';

  return `${mainStyle} ${selectedStyle} ${isNewChannelOptionStyle} ${isAgentStyle} ${groupChannelSelectedStyle}`.trim();
};

const ChannelListItemNormal = ({
  isSelectedChannel,
  unreadCount,
  otherUserTyping = false,
  channelImage = null,
  channelStatus,
  channelTitle,
  channelSubtitle,
  onChannelSelect = () => {},
  multiSelect = false,
  isNewChannelOption = false,
  numOpenTasks = null,
  channelBrand = null,
  channelMemberCount = 0,
  isSalesSpace = false,
  isHidden = false,
}) => {
  const isAgent = useSelector(getIsAgent);
  const channelStyle = useMemo(() => {
    return getChannelStyle({
      isSelectedChannel,
      isAgent,
      isNewChannelOption,
      multiSelect,
    });
  }, [isAgent, isSelectedChannel, isNewChannelOption, multiSelect]);

  const searchInput = useSelector(getSearchInput);

  return (
    <>
      <button
        type="button"
        onClick={onChannelSelect}
        className={channelStyle}
      >
        <div className={styles.channelInfo}>
          {!otherUserTyping
          && (
            <ImageWithStatus
              imageUrl={channelImage}
              userStatus={channelStatus}
              channelMemberCount={channelMemberCount}
              isSalesSpace={isSalesSpace}
              isAgent={isAgent}
              className={styles.imageWrapper}
            />
          )}
          {otherUserTyping
          && (
            <div className={styles.userTyping}>
              <IconComponent Icon={ICON.EDIT} alt="user-typing" />
            </div>
          )}
          <div className={styles.content}>
            <div className={styles.customerTitle}>
              <div className={styles.flexContainer}>
                <div className={styles.customerName}>
                  <Highlight search={searchInput}>{channelTitle || 'Unbekannt'}</Highlight>
                </div>
                {isAgent
                && (
                <div>
                  {channelBrand && <ChannelBrandLogo channelBrand={channelBrand} />}
                </div>
                )
              }
              </div>
            </div>
            <div className={styles.customerCompany}>
              <Highlight search={searchInput}>{channelSubtitle || 'Unbekannt'}</Highlight>
            </div>
            {isHidden && (
            <div className={styles.archivedIndicator}>
              Archiviert
            </div>
            )}
          </div>
          {unreadCount > 0 && (
            <div className={styles.unreadMsgIndicator}>
              <UnreadCountDisplay unreadCount={unreadCount} />
            </div>
          )}
          {numOpenTasks > 0 && (
            <div className={styles.todoIndicator}>
              {numOpenTasks}
            </div>
          )}
          {multiSelect && (
          <div className={styles.checkIconButton}>
            <CheckBox
              color={ICON_COLOR.NEW_CHAT_CHECKBOXES}
              isChecked={isSelectedChannel}
            />
          </div>
          )}
          {isAgent && !multiSelect && (
          <div className={styles.chevronButton}>
            <IconComponent Icon={ICON.ARROW} alt="Weiter" />
          </div>
          )}
        </div>
      </button>
    </>
  );
};

ChannelListItemNormal.propTypes = {
  isSalesSpace: PropTypes.bool,
  isSelectedChannel: PropTypes.bool.isRequired,
  unreadCount: PropTypes.number.isRequired,
  otherUserTyping: PropTypes.bool,
  channelMemberCount: PropTypes.number,
  channelImage: PropTypes.string,
  channelStatus: PropTypes.shape({
    text: PropTypes.string,
    // type: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
  channelTitle: PropTypes.string.isRequired,
  channelSubtitle: PropTypes.string.isRequired,
  onChannelSelect: PropTypes.func,
  multiSelect: PropTypes.bool,
  isNewChannelOption: PropTypes.bool,
  numOpenTasks: PropTypes.number,
  channelBrand: PropTypes.string,
  isHidden: PropTypes.bool,
};

export default ChannelListItemNormal;
