/* eslint-disable max-len */
import { ICON } from 'gcs-common/constants/IconConstants';
import { clearStore } from 'gcs-common/slices/store/storeActions';
import { useDispatch } from 'react-redux';
import { FC } from 'react';
import styles from './styles.module.scss';
import IconComponent from '../IconComponent/IconComponent';

interface FullScreenErrorDialogProps {
  dialogErrorTitle?: string,
  dialogErrorMsg?: string,
  dialogErrorCode?: string
}

const FullScreenErrorDialog: FC<FullScreenErrorDialogProps> = ({
  dialogErrorTitle,
  dialogErrorMsg,
  dialogErrorCode,
}) => {
  const dispatch = useDispatch();
  const resetApp = () => {
    dispatch(clearStore());
    window.location.reload();
  };

  return (
  // TODO rename data test id
    <div className={styles.errorWrapper} data-testid="errorBoundaryFallback">
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <IconComponent Icon={ICON.REPORT} />
      <h3>Ein Fehler ist aufgetreten.</h3>
      <p>Bitte laden Sie die App neu und kontaktieren Sie den Support, wenn das Problem weiterhin besteht.</p>

      {(dialogErrorTitle || dialogErrorMsg || dialogErrorCode) && (
        <div className={styles.details}>
          <h5>Technische Details</h5>
          <p>
            Uhrzeit:
            {' '}
            {new Date().toLocaleString('de-DE')}
          </p>
          {dialogErrorTitle && <p>{dialogErrorTitle}</p>}
          {dialogErrorMsg && <p>{dialogErrorMsg}</p>}
          {dialogErrorCode && <p>{dialogErrorCode}</p>}
        </div>
      )}

      <button type="button" onClick={resetApp}>Neu laden</button>
    </div>
  );
};


export default FullScreenErrorDialog;
