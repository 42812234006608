import { useSelector } from 'react-redux';
import CRAFTSMAN_CHANNEL_SECTIONS from 'gcs-common/constants/CraftsmanChannelSections';
import {
  getCraftsmanChannelSectionChannelIds,
} from 'gcs-common/slices/channelList/channelListSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { getSidebarCollapsed } from 'gcs-common/slices/uiState/uiStateSelectors';
import styles from './styles.module.scss';
import CraftsmanChannelSection from '../CraftsmanChannelSection/CraftsmanChannelSection';
import SidebarMessage from '../SidebarMessage/SidebarMessage';
import CraftsmanChannelSearchInput from '../CraftsmanChannelSearchInput/CraftsmanChannelSearchInput';
import HouseSwitcher from '../HouseSwitcher/HouseSwitcher';

function CraftsmanChannelList() {
  const searchInput = useSelector(getSearchInput);
  const sidebarCollapsed = useSelector(getSidebarCollapsed);

  const houseChannel = useSelector(
    getCraftsmanChannelSectionChannelIds(CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS),
  );

  const internalChannel = useSelector(
    getCraftsmanChannelSectionChannelIds(CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS),
  );

  return (
    <>
      {
        !sidebarCollapsed && (
          <>
            <CraftsmanChannelSearchInput />
            <HouseSwitcher />
            {!searchInput && houseChannel.length === 0 && (
              <SidebarMessage text="Um einen Chat zu starten, tippen Sie bitte auf den Plus-Button. " />
            )}
          </>
        )
      }
      <div className={styles.channelSectionsWrapper}>
        <CraftsmanChannelSection
          key={CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS}
          sectionName={CRAFTSMAN_CHANNEL_SECTIONS.HOUSE_CHATS}
          channelIds={houseChannel}
          className={`${(internalChannel.length > 3 || houseChannel.length > 3) && styles.channelListHouseSection}`}
        />
        <CraftsmanChannelSection
          key={CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS}
          sectionName={CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS}
          channelIds={internalChannel}
          className={`${(houseChannel.length > 3 || internalChannel.length > 3) && styles.channelListInternalSection}`}
        />
      </div>
    </>
  );
}

export default CraftsmanChannelList;
