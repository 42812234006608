import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../helper/errorMessages';
import fetchJoinedHouses from '../houses/housesThunks/fetchJoinedHouses';

export const fetchCraftsmanConnections = createAsyncThunk(
  'craftsmanConnections/fetchCraftsmanConnections',
  async (_, { rejectWithValue, dispatch, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getConnectionMembers();
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    dispatch(fetchJoinedHouses());
    return response;
  },
);

export const addCraftsmanConnection = createAsyncThunk(
  'craftsmanConnections/addConnection',
  async ({ username, password, brand }, { rejectWithValue, dispatch, extra: { gccApiClient } }) => {
    const response = await gccApiClient.addCraftsmanConnection(username, password);
    if (response.error_code) {
      const errorMessage = getErrorMsg(
        response.error_code,
        brand,
        brand,
        brand.toLowerCase(),
      ) || response.description; // use the error message from the backend, e.g. for wrong_house
      if (errorMessage) {
        return rejectWithValue({ errorMessage });
      }
    }
    dispatch(fetchJoinedHouses());
    return dispatch(fetchCraftsmanConnections());
  },
);


export const deleteCraftsmanConnection = createAsyncThunk(
  'craftsmanConnections/deleteConnection',
  async ({ connectionId }, { rejectWithValue, dispatch, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteCraftsmanConnection(connectionId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    dispatch(fetchJoinedHouses());
    return response;
  },
);

export const updateCraftsmanConnectionToCustomer = createAsyncThunk(
  'currentCraftsmanUser/editConnection',
  async ({ connectionId, username, password, brand },
    { dispatch, rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateCraftsmanConnectionToCustomer(
      connectionId, username, password,
    );
    dispatch(fetchCraftsmanConnections());
    if (response.error_code) {
      const errorMessage = getErrorMsg(
        response.error_code,
        brand,
        brand,
        brand.toLowerCase(),
      );
      if (errorMessage) {
        return rejectWithValue({ errorMessage });
      }
    }
    const { data } = response;
    return data;
  },
);
