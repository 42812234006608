/**
 * Currently we can select multiple messages in order to forward them
 * to 3 different "targets"
 */
const FORWARD_TARGET = {
  EMAIL: 'EMAIL', // forward to email via a EML file
  CHAT: 'CHAT', // forward to another user using the BEEM chat
  TICKET: 'TICKET', // forward to create a ETS ticket
};

export default FORWARD_TARGET;
