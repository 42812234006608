/* eslint-disable max-len */

import { MAX_TICKET_ATTACHMENT_SIZE } from 'gcs-common/slices/tickets/ticketsConstants';
import styles from './styles.module.scss';
import Button from '../../Button/Button';
import PopUpModal from '../../PopUpModal/PopUpModal';

const maxSizeInMb = (+(MAX_TICKET_ATTACHMENT_SIZE / 1024 / 1024).toFixed(2)).toLocaleString();

interface TicketErrorModalProps {
  onCancel: VoidFunction
}

const TicketErrorModal = (props: TicketErrorModalProps) => {
  const { onCancel } = props;

  return (
    <PopUpModal
      title="Anhang zu groß"
      onRequestClose={onCancel}
      isOpen
    >
      <div>
        <div className={styles.modalContent}>
          <p>
            Leider kann diese Datei nicht angehängt werden, da sie die aktuell maximal zulässige Größe von
            {' '}
            {maxSizeInMb}
            {' '}
            MB für Anhänge überschreitet.
          </p>
        </div>
        <div className={styles.buttonRow}>
          <Button
            className={styles.modalButton}
            type="button"
            onClick={onCancel}
          >
            Abbrechen
          </Button>
        </div>
      </div>
    </PopUpModal>
  );
};

export default TicketErrorModal;
