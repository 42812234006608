import { useCallback, useEffect } from 'react';
import { MAX_TICKET_ATTACHMENT_SIZE } from 'gcs-common/slices/tickets/ticketsConstants';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';

interface UseTicketAttachmentFileDropProps {
  fieldName: string;
  onFileSizeError: VoidFunction;
  onFileAdded?: (file: File) => void;
  onFileRemoved?: (fileIndex: number) => void;
  onFilesDropped?: (files: File[]) => void;
  onFileUrlDropped?: (fileUrl: string) => void;
  onFileUrlError?: (ex: unknown, fileUrl: string) => void;
  noClick?: boolean;
}

/**
 * A combined hook for handling file drops and file conversions.
 *
 * Supports dropping files as well as dropping file URLs.
 *
 * Adds uploaded files as array field to formik form.
 */
const useTicketAttachmentFileDrop = (props: UseTicketAttachmentFileDropProps) => {
  const {
    fieldName,
    onFileSizeError,
    onFileAdded,
    onFileRemoved,
    onFilesDropped,
    onFileUrlDropped,
    onFileUrlError,
    noClick = false,
  } = props;
  const { setValues } = useFormikContext();

  const addFiles = useCallback((files: File[]) => {
    // id helps to easily distinguish the files, e.g. to be used as key when rendering a list
    const filesWithId = files.map(file => {
      const newFile: File & { id?: string } = new File([file], file.name, { type: file.type });
      newFile.id = uuidv4();
      return newFile;
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises, no-void
    setValues((old: { [x: string]: File[]; }) => ({
      ...old,
      [fieldName]: [...(old[fieldName] || []), ...filesWithId],
    }));

    filesWithId.forEach(file => onFileAdded?.(file));
  }, [fieldName, onFileAdded, setValues]);

  const removeFile = (fileIndex: number) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, no-void
    setValues((old: { [x: string]: File[]; }) => ({
      ...old,
      [fieldName]: (old[fieldName] || []).filter((_, i) => i !== fileIndex),
    }));

    onFileRemoved?.(fileIndex);
  };

  const checkAndAddFiles = useCallback((files: File[]) => {
    const validFiles = files.filter(file => file.size <= MAX_TICKET_ATTACHMENT_SIZE);
    const invalidFiles = files.filter(file => file.size > MAX_TICKET_ATTACHMENT_SIZE);

    invalidFiles.forEach(() => onFileSizeError?.());

    if (validFiles.length > 0) {
      addFiles(validFiles);
    }
  }, [addFiles, onFileSizeError]);

  const handleDrop = (acceptedFiles: File[]) => {
    onFilesDropped?.(acceptedFiles);
    checkAndAddFiles(acceptedFiles);
  };

  const interceptUrlDrop = useCallback(async (event: DragEvent) => {
    const url = event.dataTransfer?.getData('text/plain');
    if (!url) return;

    event.preventDefault();

    onFileUrlDropped?.(url);

    try {
      const response = await fetch(url);

      const blob = await response.blob();

      const filename = url.split('/').pop();
      const file = new File([blob], filename!, { type: blob.type });

      checkAndAddFiles([file]);
    } catch (ex: unknown) {
      // ignore non-downloadable images (e.g. if CORS protected)
      onFileUrlError?.(ex, url);
    }
  }, [checkAndAddFiles, onFileUrlDropped, onFileUrlError]);

  const dropzone = useDropzone({ onDrop: handleDrop, noClick });

  useEffect(() => {
    // intercept drops to handle file URLs, e.g. when dragging & dropping an image from a BEEM chat.

    const dzElement = dropzone.rootRef.current;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    dzElement?.addEventListener('drop', interceptUrlDrop);

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return () => dzElement?.removeEventListener('drop', interceptUrlDrop);
  }, [dropzone.rootRef, interceptUrlDrop]);

  return { ...dropzone, addFiles, removeFile };
};

export default useTicketAttachmentFileDrop;
