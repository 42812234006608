import { channelMemberFetched, channelMemberRemoved, memberUpdated } from './membersSlice';
import { getUsers } from '../users/usersSelectors';
import { fetchUser } from '../users/usersThunks/fetchUser';
import { filterObjectToExcludeNull } from '../../helper/objectHelpers';

export const updateMember = ({ member }) => async (dispatch) => {
  const memberExcludeNull = filterObjectToExcludeNull(member);
  dispatch(memberUpdated({ member: memberExcludeNull }));
};

export const removeMember = ({ member }) => async (dispatch) => {
  const { channelId } = member;
  dispatch(channelMemberRemoved({ channelId, member }));
};

export const handleMemberJoined = ({ member }) => async (
  dispatch,
  getState,
) => {
  const { channelId } = member;
  dispatch(channelMemberFetched({ channelId, member }));
  const users = getUsers(getState());
  const newId = member.userId;
  if (!users[newId]) {
    await dispatch(fetchUser({ id: newId }));
  }
};
