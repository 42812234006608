import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class VideoMessageModel extends AbstractMessageModel {

  constructor({
    videoId,
    videoStatus,
    videoPlaybackId,
    videoTitle,
    videoWidth,
    videoHeight,
    videoSize,
    videoDuration,
    videoDownloadReady,
    videoDownloadResolution,
    ...args
  } = {}) {
    super({
      messageType: MESSAGE_TYPE.VIDEO,
      ...args,
    });

    this.videoId = videoId;
    this.videoStatus = videoStatus;
    this.videoPlaybackId = videoPlaybackId;
    this.videoTitle = videoTitle;
    this.videoWidth = videoWidth;
    this.videoHeight = videoHeight;
    this.videoSize = videoSize;
    this.videoDuration = videoDuration;
    this.videoDownloadReady = videoDownloadReady;
    this.videoDownloadResolution = videoDownloadResolution;
  }
}
