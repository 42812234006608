import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { getCraftsmanConnection, getIsManualCraftsmanConnection } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { useSelector } from 'react-redux';
import OpConnectionStatus from '../../OpConnectionStatus/OpConnectionStatus';

import styles from './styles.module.scss';

export const ConnectionComponent = ({ connectionId, goToEdit }) => {
  const connectionMember = useSelector(getCraftsmanConnection(connectionId));
  const isManual = useSelector(getIsManualCraftsmanConnection(connectionId));
  const goToConnectionDetails = useCallback(() => {
    goToEdit(connectionMember.id);
  }, [connectionMember, goToEdit]);

  if (isManual) {
    return (
      <div className={styles.connectionComponent}>
        <div className={styles.left}>
          <div>{`${connectionMember.connection.customerNumber} `}</div>
        </div>
        <button
          type="button"
          className={styles.primaryButton}
          onClick={goToConnectionDetails}
        >
          OP Konto
        </button>
      </div>
    );
  }

  return (
    <div className={styles.connectionComponent}>
      <div className={styles.left}>
        <OpConnectionStatus width="20px" height="20px" connectionId={connectionMember.id} />
        <div>{`${connectionMember.connection.customerNumber} (${connectionMember.opAccount.username})`}</div>
      </div>
      <button
        type="button"
        className={styles.primaryButton}
        onClick={goToConnectionDetails}
      >
        Bearbeiten
      </button>
    </div>
  );
};

ConnectionComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  connectionId: PropTypes.string.isRequired,
  goToEdit: PropTypes.func.isRequired,

};
