import AsyncSelect from 'react-select/async';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateFeatureFlag } from 'gcs-common/slices/administration/featureFlags/featureFlagsThunks';
import { fetchUserSelectOptions } from 'gcs-common/slices/users/usersThunks';
import EditingCell from '../EditingCell/EditingCell';

const calculateUserName = (user) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.name || user.userName;
};

const calculateUserLabel = (user) => {
  return `${calculateUserName(user)} (${user.email || user.phoneNumber})`;
};

const FeatureFlagUsersCell = ({ cell, value: users }) => {

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [options, setOptions] = useState([]);

  const featureFlagId = cell.row.original.id;

  const fetchOptions = useCallback(async (search) => {
    const userSelectOptions = await dispatch(fetchUserSelectOptions(search));
    return userSelectOptions.map(user => ({
      label: calculateUserLabel(user),
      value: user.id,
    }));
  }, [dispatch]);

  const setInitialOptions = useCallback(() => {
    const initialOptions = users
      .map((user) => ({ label: calculateUserLabel(user), value: user.id }));
    setOptions(initialOptions);
  }, [users]);

  useEffect(() => {
    setInitialOptions();
  }, [setInitialOptions]);

  const save = useCallback(async () => {
    const userIds = options?.map((option) => option.value) || [];
    await dispatch(updateFeatureFlag({ featureFlagId, userIds }));
  }, [dispatch, featureFlagId, options]);

  const cancel = useCallback(() => {
    setInitialOptions();
  }, [setInitialOptions]);

  return (
    <EditingCell
      onSave={save}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      onCancel={cancel}
    >
      <AsyncSelect
        isMulti
        loadOptions={fetchOptions}
        isDisabled={!isEditing}
        value={options}
        onChange={(selected) => {
          setOptions(selected);
        }}
        styles={{ container: (base) => ({ ...base, minWidth: '300px' }) }}
      />

    </EditingCell>
  );
};

FeatureFlagUsersCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FeatureFlagUsersCell;
