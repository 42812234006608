import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveHouseId } from 'gcs-common/slices/administration/houses/housesSelectors';
import {
  getAgentAssignmentsCount,
  fetchAgentAssignmentsCountLoading,
  getDisconnectAgentFromHouseLoading,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import { fetchAgentAssignmentsCount } from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import Button from '../../../Button/Button';
import styles from './styles.module.scss';
import AgentPropType from '../../../../models/AgentPropType';


const AgentDelete = ({ agent, onCancel, handleDelete }) => {
  const dispatch = useDispatch();
  const houseId = useSelector(getActiveHouseId);
  const isDeleting = useSelector(getDisconnectAgentFromHouseLoading);
  const isFetching = useSelector(fetchAgentAssignmentsCountLoading);
  const isLoading = isDeleting || isFetching;
  const agentAssignmentsCount = useSelector(getAgentAssignmentsCount(agent?.id));

  const message = useMemo(() => {
    return `Möchten Sie die Verknüpfung des Mitarbeiters "${(agent?.friendlyName) || 'Unbekannt'}" zu diesem Haus wirklich entfernen?`;
  }, [agent]);

  const infoMessage = useMemo(() => {
    return `Das Löschen von "${(agent?.friendlyName) || 'Unbekannt'}" hat zur Folge, dass:`;
  }, [agent]);

  const assignmentsText = useMemo(() => {
    if (agentAssignmentsCount === 0 || agentAssignmentsCount > 1) {
      return `der Mitarbeiter aus ${agentAssignmentsCount} Kommunikationszuweisungen entfernt wird`;
    }
    return `der Mitarbeiter aus ${agentAssignmentsCount} Kommunikationszuweisung entfernt wird`;
  }, [agentAssignmentsCount]);

  useEffect(() => {
    if (agent) {
      dispatch(fetchAgentAssignmentsCount({ agentId: agent.id, houseId }));
    }
  }, [dispatch, agent, houseId]);

  return (
    <div className={styles.column}>
      {isLoading
        ? <LoadingIndicator />
        : (
          <>
            <p>{message}</p>
            <p>{infoMessage}</p>
            <ul className={styles.infoList}>
              <li>seine Verantwortlichkeiten entfernt werden</li>
              <li>{assignmentsText}</li>
            </ul>
          </>
        )
      }
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={onCancel}
          disabled={isLoading}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          onClick={handleDelete}
          disabled={isLoading}
        >
          Entfernen
        </Button>
      </div>
    </div>
  );
};

AgentDelete.propTypes = {
  agent: AgentPropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AgentDelete;
