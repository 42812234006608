// eslint-disable-next-line import/no-cycle
import { preSelectChannelId, selectedChannelIdChanged } from '../channelsSlice';
import {
  getChannel,
  getPrimaryHouseForChannel,
} from '../../channel/channelSelectors';
import { latestMessageIsRead } from '../../messages/messageSelector';
import { getIsAgent } from '../../currentUser/currentUserSelectors';
// eslint-disable-next-line import/no-cycle
import setChannelMessagesConsumed from '../../channel/channelThunks/setChannelMessagesConsumed';
import { setSelectedChannelTypeFilter } from '../../channelList/channelListSlice';
import { CHANNEL_FILTER_TYPES, CHANNEL_TYPE } from '../../../helper/channelTypeHelper';
import { getSelectedHouseId } from '../../houses/housesSelectors';
import { selectedHouseIdChanged } from '../../houses/housesSlice';
import fetchMessages from '../../messages/messagesThunks/fetchMessages';

const changeSelectedChannelId = ({ selectedChannelId }) => async (dispatch, getState,
  { chatClient }) => {
  const channel = getChannel(selectedChannelId)(getState());
  const selectedHouseId = getSelectedHouseId(getState());
  const channelHouseId = getPrimaryHouseForChannel(selectedChannelId)(getState());

  const isAgent = getIsAgent(getState());

  if (isAgent) {
    const selectedChannelTypeFilter = channel?.channelType === CHANNEL_TYPE.SALES_SPACE
      ? CHANNEL_FILTER_TYPES.TEAM_CHATS : CHANNEL_FILTER_TYPES.DIRECT_CHATS;
    dispatch(setSelectedChannelTypeFilter({ selectedChannelTypeFilter }));
  }

  if (!isAgent && !!channelHouseId && selectedHouseId !== channelHouseId) {
    dispatch(selectedHouseIdChanged({ selectedHouseId: channelHouseId }));
  }

  dispatch(fetchMessages({
    channelId: selectedChannelId,
    singlePage: true,
  }));

  if (channel) {
    dispatch(selectedChannelIdChanged({ selectedChannelId }));

    const lastestIsRead = latestMessageIsRead(selectedChannelId)(getState());

    if (!lastestIsRead && !isAgent) {
      dispatch(setChannelMessagesConsumed({ channelId: selectedChannelId }));
    }
    if (channel.channelType === CHANNEL_TYPE.SALES_SPACE) {
      // This is special case:
      // We need this to trigger "participantJoined"
      // when user is joining to the sales space
      // we "refresh" channel members that were just added to channel
      // and participantJoined event fired
      chatClient.getChannelParticipants(channel.externalId);
    }
  } else { // wait until the channel was joined
    dispatch(preSelectChannelId({ preSelectedChannelId: selectedChannelId }));
  }
};

export default changeSelectedChannelId;
