/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../../helper/errorMessages';
import ASYNC_STATE from '../../../constants/AsyncState';
import paginateReducer from '../../../helper/reduxPaginationHelper';
import { byId } from '../../../helper/byKey';
import {
  createBusinessUnit,
  deleteBusinessUnit,
  fetchBusinessUnitDetails,
  fetchBusinessUnits,
  updateBusinessUnit,
} from './businessUnitsThunks';

const asyncState = {
  fetch: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchBusinessUnitDetails: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  delete: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  update: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  businessUnits: {},
  selectedBusinessUnitId: undefined,
};

const businessUnitsSlice = createSlice({
  name: 'businessUnits',
  initialState,
  reducers: {
    businessUnitSelected: (state, action) => {
      const { businessUnitId } = action.payload;
      state.selectedBusinessUnitId = businessUnitId;
    },
    businessUnitSelectionReset: (state) => {
      state.selectedBusinessUnitId = undefined;
    },
    resetCreateBusinessUnitErrorMessage: (state) => {
      state.create.errorMessage = undefined;
    },
    resetUpdateBusinessUnitErrorMessage: (state) => {
      state.update.errorMessage = undefined;
    },
    resetDeleteBusinessUnitErrorMessage: (state) => {
      state.delete.errorMessage = undefined;
    },
  },
  extraReducers: {
    [fetchBusinessUnits.pending]: (state) => {
      state.fetch.status = ASYNC_STATE.LOADING;
    },
    [fetchBusinessUnits.fulfilled]: paginateReducer(
      (state) => state.fetch,
      (state, action) => {
        const { data } = action.payload;
        state.fetch.status = ASYNC_STATE.SUCCEEDED;
        state.businessUnits = { ...byId(data) };
      },
    ),
    [fetchBusinessUnits.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetch.status = ASYNC_STATE.FAILED;
      state.fetch.error = error;
      state.fetch.errorMessage = getErrorMsg('default');
    },
    [deleteBusinessUnit.pending]: (state) => {
      state.delete.status = ASYNC_STATE.LOADING;
    },
    [deleteBusinessUnit.fulfilled]: (state) => {
      state.delete.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteBusinessUnit.rejected]: (state, action) => {
      const { error } = action.error;
      state.delete.status = ASYNC_STATE.FAILED;
      state.delete.error = error;
      state.delete.errorMessage = getErrorMsg('default');
    },
    [createBusinessUnit.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
    },
    [createBusinessUnit.fulfilled]: (state) => {
      state.create.status = ASYNC_STATE.SUCCEEDED;
    },
    [createBusinessUnit.rejected]: (state, action) => {
      const { error } = action.error;
      state.create.status = ASYNC_STATE.FAILED;
      state.create.error = error;
      state.create.errorMessage = getErrorMsg('default');
    },
    [updateBusinessUnit.pending]: (state) => {
      state.update.status = ASYNC_STATE.LOADING;
    },
    [updateBusinessUnit.fulfilled]: (state) => {
      state.update.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateBusinessUnit.rejected]: (state, action) => {
      const { error } = action.error;
      state.update.status = ASYNC_STATE.FAILED;
      state.update.error = error;
      state.update.errorMessage = getErrorMsg('default');
    },
    [fetchBusinessUnitDetails.pending]: (state) => {
      state.fetchBusinessUnitDetails.status = ASYNC_STATE.LOADING;
    },
    [fetchBusinessUnitDetails.fulfilled]: (state, action) => {
      const { businessUnit } = action.payload;
      const { id } = businessUnit;
      state.fetchBusinessUnitDetails.status = ASYNC_STATE.SUCCEEDED;
      state.selectedBusinessUnitId = id;
      state.businessUnits[id] = businessUnit;
    },
    [fetchBusinessUnitDetails.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchBusinessUnitDetails.status = ASYNC_STATE.FAILED;
      state.fetchBusinessUnitDetails.error = error;
      state.fetchBusinessUnitDetails.errorMessage = getErrorMsg('default');
    },
  },
});

export const {
  businessUnitSelected,
  businessUnitSelectionReset,
  resetCreateBusinessUnitErrorMessage,
  resetUpdateBusinessUnitErrorMessage,
  resetDeleteBusinessUnitErrorMessage,
} = businessUnitsSlice.actions;

export default businessUnitsSlice.reducer;
