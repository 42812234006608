import { getIsChatConnected } from '../../chatConnection/chatConnectionSelectors';
import { getChannel } from '../channelSelectors';

const setChannelStartTyping = ({ channelId }) => async (
  _, getState, { chatClient },
) => {
  const hasChatConnection = getIsChatConnected(getState());

  if (hasChatConnection) {
    const channel = getChannel(channelId)(getState());
    await chatClient.channelStartTyping({ externalChannelId: channel.externalId });
  }
};

export default setChannelStartTyping;
