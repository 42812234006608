import { ICON_COLOR, IconColorValue } from '../../constants/IconConstants';


export const TICKET_VIEWS = {
  FORM: 'form',
  DETAILS: 'details',
};

/**
 * We configured the Elastic Beanstalk to have a maximun upload limit of: 20MB.
 * In: gcs-api/src/.platform/nginx/conf.d/client_max_body_size.conf
 * Using a bit less than that to accomodate for metadata overhead.
 */
export const MAX_TICKET_ATTACHMENT_SIZE = 19.996 * 1024 * 1024;

export const DELETE_NOTIFICATION_AFTER_SECONDS = 0;

interface TicketStati {
  [key: string]: {
    title: string;
    iconColor: IconColorValue;
    backgroundColor: string;
    foregroundColor: string;
  }
}

export const TICKET_STATI: TicketStati = {
  sent: {
    title: 'Gesendet',
    iconColor: ICON_COLOR.BLUE,
    backgroundColor: 'var(--common-blue-4)',
    foregroundColor: 'var(--common-blue-10)',
  },
  declined: {
    title: 'Rückmeldung erforderlich',
    iconColor: ICON_COLOR.MUSTARD,
    backgroundColor: 'var(--common-yellow-4)',
    foregroundColor: 'var(--common-yellow-12)',
  },
  in_progress: {
    title: 'In Arbeit',
    iconColor: ICON_COLOR.BLUE,
    backgroundColor: 'var(--common-blue-4)',
    foregroundColor: 'var(--common-blue-10)',
  },
  wont_do: {
    title: 'Ersatzteil nicht verfügbar',
    iconColor: ICON_COLOR.LIGHT_RED,
    backgroundColor: 'var(--common-red-4)',
    foregroundColor: 'var(--common-red-11)',
  },
  done: {
    title: 'Erledigt',
    iconColor: ICON_COLOR.SUCCESS,
    backgroundColor: 'var(--common-green-4)',
    foregroundColor: 'var(--common-green-11)',
  },
} as const;

type TicketInputChannels = {
  [key in 'email' | 'phone' | 'chat' | 'craftsman']: {
    key: string;
    label: string;
    hidden?: boolean;
  };
};

export const TICKET_INPUT_CHANNELS: TicketInputChannels = {
  email: {
    key: 'email',
    label: 'E-Mail',
  },
  phone: {
    key: 'phone',
    label: 'Telefon',
  },
  chat: {
    key: 'chat',
    label: 'BEEM',
  },
  craftsman: {
    key: 'craftsman',
    label: 'Fachhandwerker',
    /**
     * This value is automatically set in the craftsman app
     * and shouldn't be selected by an agent.
     */
    hidden: true,
  },
} as const;

export type TicketInputChannelsKey = keyof typeof TICKET_INPUT_CHANNELS;
