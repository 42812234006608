import styles from './styles.module.scss';
import DeletedIcon from '../../../img/delete.svg?react';

function DeletedMessage() {
  return (
    <div className={styles.txtMsgBodyDeleted}>
      <DeletedIcon />
      Nachricht wurde gelöscht.
    </div>
  );
}

export default DeletedMessage;
