/* eslint-disable camelcase */

import { useSelector } from 'react-redux';
import {
  getMemberDetailsSelectedChannelId,
} from 'gcs-common/slices/memberDetails/memberDetailsSelectors';
import styles from './styles.module.scss';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import MemberDetails from '../MemberDetails/MemberDetails';

const UserProfile = () => {
  const memberDetailsSelectedChannelId = useSelector(
    getMemberDetailsSelectedChannelId,
  );

  return (
    <div className={styles.userProfileContainer}>
      <ProfileHeader
        channelId={memberDetailsSelectedChannelId}
        title="Profil"
      />

      <MemberDetails />
    </div>
  );
};

export default UserProfile;
