import moment from 'moment';

const timeStringToDouble = (str) => {
  return str && parseFloat(str.replace(':', '.'));
};

const stringToTimeString = (str) => {
  let timeStr;
  timeStr = str.toString().replace('.', ':');
  if (timeStr.indexOf(':') === -1) timeStr = timeStr.concat(':00');
  if (timeStr.length - timeStr.indexOf(':') === 2) timeStr = timeStr.concat('0');
  if (timeStr.indexOf(':') === 1) timeStr = '0'.concat(timeStr);
  return timeStr;
};


const convertOpeningHours = (monStartStr, monEndStr, frStartStr, frEndStr) => {
  if ([monStartStr, monEndStr, frStartStr, frEndStr].every(
    (val) => val === undefined,
  )) return undefined;
  const openingHours = {};
  const monStart = timeStringToDouble(monStartStr);
  const monEnd = timeStringToDouble(monEndStr);
  const frStart = timeStringToDouble(frStartStr);
  const frEnd = timeStringToDouble(frEndStr);
  openingHours[0] = [monStart, monEnd];
  openingHours[1] = [monStart, monEnd];
  openingHours[2] = [monStart, monEnd];
  openingHours[3] = [monStart, monEnd];
  openingHours[4] = [frStart, frEnd];
  openingHours[5] = [0, 0];
  openingHours[6] = [0, 0];
  return openingHours;
};


export const convertHouseUpdateValues = (values) => {
  const updatedValues = {};

  updatedValues.friendlyName = values.friendlyName;
  updatedValues.province = values.province;
  updatedValues.readReceiptAllowance = values.readReceiptAllowance;
  const openingHours = convertOpeningHours(values.MoDoStart,
    values.MoDoEnd, values.FrStart, values.FrEnd);
  updatedValues.openingHours = openingHours;
  const lateShift = convertOpeningHours(values.MoDoStartLateShift,
    values.MoDoEndLateShift, values.FrStartLateShift, values.FrEndLateShift);
  updatedValues.lateShift = lateShift;
  updatedValues.processDataSource = values.processDataSource;
  updatedValues.customMessages = values.customMessages;
  updatedValues.ldaNumbers = values.ldaNumbers;
  updatedValues.houseNumbers = values.houseNumbers;
  updatedValues.craftnoteOrganizationId = values.craftnoteOrganizationId;
  updatedValues.craftnoteApiKey = values.craftnoteApiKey;
  updatedValues.enableCrmImportsByEmail = values.crmImportConfiguration;

  return Object.fromEntries(Object.entries(updatedValues).filter((k, v) => v !== undefined));
};

export const toTimeConversion = (openingHours) => {
  const convertedOpeningHours = {};
  convertedOpeningHours.MoDoStart = openingHours ? stringToTimeString(openingHours[0][0]) : null;
  convertedOpeningHours.MoDoEnd = openingHours ? stringToTimeString(openingHours[0][1]) : null;
  convertedOpeningHours.FrStart = openingHours ? stringToTimeString(openingHours[4][0]) : null;
  convertedOpeningHours.FrEnd = openingHours ? stringToTimeString(openingHours[4][1]) : null;
  return convertedOpeningHours;
};

export const isCurrentMomentInOpeningHours = (currentOpeningHours, currentLateShift) => {
  const openingHours = toTimeConversion(currentOpeningHours);
  const lateShift = toTimeConversion(currentLateShift);
  const weekday = moment().day();
  if (weekday === 5) {
    return moment().isBetween(moment(openingHours.FrStart, 'HH:mm'), moment(openingHours.FrEnd, 'HH:mm'))
      || moment().isBetween(moment(lateShift.FrStart, 'HH:mm'), moment(lateShift.FrEnd, 'HH:mm'));
  }
  return moment().isBetween(moment(openingHours.MoDoStart, 'HH:mm'), moment(openingHours.MoDoEnd, 'HH:mm'))
    || moment().isBetween(moment(lateShift.MoDoStart, 'HH:mm'), moment(lateShift.MoDoEnd, 'HH:mm'));
};
