import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { getDialogError } from 'gcs-common/slices/errorDialogSlice/errorDialogSelectors';
import { ERROR_DIALOG } from 'gcs-common/constants/errorDialogConstants';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';
import PopUpModal from '../PopUpModal/PopUpModal';
import Button from '../Button/Button';
import FullScreenErrorDialog from '../FullScreenErrorDialog/FullScreenErrorDialog';

const ErrorDialog = () => {
  const {
    dialogErrorButtonText,
    dialogErrorMsg,
    dialogErrorTitle,
    dialogErrorCode,
    errorDialog,
  } = useSelector(getDialogError);
  const dispatch = useDispatch();
  const onCloseErrorDialog = useCallback(() => {
    dispatch(closeErrorDialog());
  }, [dispatch]);

  if (!dialogErrorMsg) {
    return <div data-testid="empty-error-dialog" />;
  }

  if (errorDialog === ERROR_DIALOG.FULLSCREEN) {
    return (
      <FullScreenErrorDialog
        dialogErrorTitle={dialogErrorTitle}
        dialogErrorMsg={dialogErrorMsg}
        dialogErrorCode={dialogErrorCode && `Technischer Fehler: ${dialogErrorCode}`}
      />
    );
  }

  if (errorDialog === ERROR_DIALOG.SNICKERS) {
    toast.error(dialogErrorMsg, {
      toastId: 'beemErrorToast',
      autoClose: 5000,
      onOpen: onCloseErrorDialog,
    });
    return null;
  }

  return (
    <PopUpModal
      className=""
      title={dialogErrorTitle || 'Es ist ein Fehler aufgetreten'}
      isOpen={!!dialogErrorMsg}
      onRequestClose={onCloseErrorDialog}
      zIndex={6}
    >
      <div className={styles.container}>
        <div>{dialogErrorMsg}</div>
        <div className={styles.dialogButtons}>
          <Button
            type="button"
            className={styles.dialogButton}
            onClick={onCloseErrorDialog}
          >
            {dialogErrorButtonText || 'OK'}
          </Button>
        </div>
      </div>
    </PopUpModal>
  );
};

export default ErrorDialog;
