import { useState, useCallback, useRef, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileImageForEditing, getTeamchatImageForEditing, isEditImageModalOpen } from 'gcs-common/slices/uiState/uiStateSelectors';
import updateProfilePicture from 'gcs-common/slices/currentUser/currentUserThunks/updateProfilePicture';
import { closeEditImageModal, updateProfileImageForEditing, updateTeamchatImageForEditing, updateTeamchatPicture } from 'gcs-common/slices/uiState/uiStateSlice';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import IconComponent from '../IconComponent/IconComponent';
import ModalWithCloseButton from '../PopUpModal/ModalWithCloseButton/ModalWithCloseButton';
import styles from './styles.module.scss';

const EditImageModal = () => {

  const isModalOpen = useSelector(isEditImageModalOpen);
  const profilePicture = useSelector(getProfileImageForEditing);
  const teamchatPicture = useSelector(getTeamchatImageForEditing);
  const dispatch = useDispatch();
  const [picture, setPicture] = useState('');
  const [scaleValue, setScaleValue] = useState(1);
  const [rotation, setRotation] = useState(0);

  const editor = useRef(null);

  useEffect(() => {
    if (teamchatPicture) {
      setPicture(teamchatPicture);
    } else {
      setPicture(profilePicture);
    }
  }, [teamchatPicture, profilePicture]);

  const onSaveImage = useCallback(async () => {
    const newPicture = editor.current !== null
      ? editor.current.getImageScaledToCanvas().toDataURL()
      : picture;
    if (teamchatPicture) {
      dispatch(updateTeamchatPicture({ teamchatPicture: newPicture }));
    } else {
      dispatch(updateProfilePicture({ image: newPicture, type: 'image/png' }));
    }
    dispatch(updateProfileImageForEditing({ profileImageForEditing: '' }));
    dispatch(updateTeamchatImageForEditing({ teamchatImageForEditing: '' }));
    setRotation(0);
    setScaleValue(1);
    dispatch(closeEditImageModal());
  }, [dispatch, picture, teamchatPicture]);

  const rotateRight = useCallback(() => {
    setRotation(rotation + 90);
  }, [rotation]);

  const rotateLeft = useCallback(() => {
    setRotation(rotation - 90);
  }, [rotation]);

  const zoomIn = () => {
    if (scaleValue < 2.8) {
      setScaleValue(scaleValue + 0.2);
    }
  };

  const zoomOut = () => {
    if (scaleValue > 0.6) {
      setScaleValue(scaleValue - 0.2);
    }
  };

  const onClose = () => {
    dispatch(closeEditImageModal());
    dispatch(updateProfileImageForEditing({ profileImageForEditing: '' }));
    dispatch(updateTeamchatImageForEditing({ teamchatImageForEditing: '' }));
    setRotation(0);
    setScaleValue(1);
  };

  return (
    <ModalWithCloseButton
      isOpen={isModalOpen}
      onRequestClose={onClose}
      className={styles.editImageModal}
      overlayClassName={styles.overlayEditImageModal}
    >
      <AvatarEditor
        image={picture}
        width={280}
        height={280}
        border={1}
        color={[241, 241, 241]} // RGBA
        scale={scaleValue}
        ref={editor}
        borderRadius={140}
        rotate={rotation}
        className={styles.avatarEditor}
      />
      <div className={styles.editButtons}>
        <button type="button" onClick={rotateLeft}>
          <IconComponent Icon={ICON.ROTATE} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} />
        </button>
        <button type="button" onClick={zoomOut}>
          <IconComponent Icon={ICON.MINUS} />
        </button>
        <button type="button" onClick={zoomIn}>
          <IconComponent Icon={ICON.PLUS} />
        </button>
        <button type="button" onClick={rotateRight}>
          <IconComponent Icon={ICON.ROTATE} />
        </button>
      </div>
      <button
        type="button"
        className={styles.saveImage}
        onClick={onSaveImage}
      >
        Speichern
      </button>
    </ModalWithCloseButton>
  );
};

export default EditImageModal;
