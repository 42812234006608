import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { pad } from 'gcs-common/helper/paddedNumberHelper';
import { processSelected } from 'gcs-common/slices/processes/processesSlice';
import { TOOL_TABS } from 'gcs-common/constants/toolUIFlags';
import { setToolbarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import CubeIcon from '../../../img/cube_primary.svg?react';
import styles from './styles.module.scss';

function ProcessMessage(props) {
  const { process } = props;
  const {
    Hint,
  } = process;
  // Note: The OR operator here, to read the messages
  // that are already saved in twilio properly
  const Number = process.ProcessNumber || process.Number;
  const TypeText = process.ProcessTypeDescription || process.TypeText;
  const Suffix = process.ProcessSuffix || process.Suffix;

  const dispatch = useDispatch();

  // TODO: Is this working here was some selectedProcessFromMessage?
  const setProcess = useCallback(() => {
    dispatch(setToolbarTab({ tab: TOOL_TABS.GCOP }));
    dispatch(processSelected({ selectedProcess: process }));
  }, [dispatch, process]);

  return (
    <div className={styles.process} role="presentation" onClick={() => setProcess()}>
      <div className={styles.magicCubeWrapper}>
        <CubeIcon alt="process-detail-cube" className={styles.magicCubeSmallIcon} />
      </div>
      <div>
        <div className={styles.processWrapper}>
          <div className={styles.processHeader}>{`${TypeText}: `}</div>
          <span>{`${Number}-${pad(Suffix)}`}</span>
          <div className={styles.processDetail}>{Hint}</div>
        </div>
      </div>
    </div>
  );

}

export default ProcessMessage;

ProcessMessage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  process: PropTypes.object.isRequired,
};
