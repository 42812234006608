import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLeftBarTab } from 'gcs-common/slices/uiState/uiStateSelectors';
import { LEFT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import Settings from '../Settings/Settings';
import Sidebar from '../Sidebar/Sidebar';
import NewChat from '../NewChat/NewChat';
import styles from './styles.module.scss';

const LeftBar = () => {

  const currentTab = useSelector(getLeftBarTab);
  const isAgent = useSelector(getIsAgent);

  const bar = useMemo(() => {
    switch (currentTab) {
      case LEFT_BAR_TABS.PROFILE:
        return (
          <Settings dark={isAgent} />
        );
      case LEFT_BAR_TABS.NEW_CHAT:
      case LEFT_BAR_TABS.NEW_GROUP_CHAT:
        return (
          <NewChat />
        );
      case LEFT_BAR_TABS.SIDEBAR:
      default:
        return (
          <Sidebar />
        );
    }
  }, [currentTab, isAgent]);

  return (
    <div className={styles.sidebarCol}>
      {bar}
    </div>
  );
};

export default LeftBar;
