import { useSelector } from 'react-redux';
import {
  getIsAgent,
  getIsCurrentUserHasConnections,
  getIsRegistered,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { Navigate, Outlet, useLocation } from 'react-router';
import { REGISTRATION_ROUTES } from 'gcs-common/constants/routes';
import type { RegistrationLocationState } from '../../router/router';
/**
 * Only renders children if the user has completed the registration flow,
 * otherwise redirects the agen/craftsman to pages where they can enter missing information.
 */
const RegisteredUserProtectedRoute = () => {
  const location = useLocation();
  const isRegistered = useSelector(getIsRegistered);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isAgent = useSelector(getIsAgent);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isCurrentUserHasConnections = useSelector(getIsCurrentUserHasConnections);

  if (isRegistered) {
    return <Outlet />;
  }

  if (isAgent) {
    return (
      <Navigate
        to={REGISTRATION_ROUTES.REGISTRATION}
        state={{ sourceLocation: location } satisfies RegistrationLocationState}
        replace
      />
    );
  }

  if (isCurrentUserHasConnections) {
    return (
      <Navigate
        to={REGISTRATION_ROUTES.REGISTRATION_PROFILE}
        state={{ sourceLocation: location } satisfies RegistrationLocationState}
        replace
      />
    );
  }

  return (
    <Navigate
      to={REGISTRATION_ROUTES.REGISTRATION_EXTERNAL}
      state={{ sourceLocation: location } satisfies RegistrationLocationState}
      replace
    />
  );

};

export default RegisteredUserProtectedRoute;
