import { getLatestMessageIndexForChannel } from '../../messages/messagesSelectors';
import { getMyMemberId } from '../../members/membersSelectors';
// eslint-disable-next-line import/no-cycle
import { memberUpdated } from '../../members/membersSlice';


const setChannelMessagesConsumed = ({ channelId }) => async (
  dispatch, getState, { gccApiClient },
) => {
  const lastConsumedMessageIndex = getLatestMessageIndexForChannel(channelId)(getState());
  const myMemberId = getMyMemberId(channelId)(getState());
  if (lastConsumedMessageIndex > -1 && myMemberId) {
    const member = await gccApiClient.updateChannelMember(myMemberId, {
      lastConsumedMessageIndex,
    });
    dispatch(memberUpdated({ member }));
  }
};

export default setChannelMessagesConsumed;
