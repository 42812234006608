
import styles from './styles.module.scss';

interface TicketCommentContentProps {
  html: string
}

const TicketCommentContent = ({ html }: TicketCommentContentProps) => {
  /**
   * We receive the comments as HTML.
   *
   * Directly rendering HTML is usually considered dangerous,
   * but the HTML is provided by Jira and not directly by the user.
   *
   * We trust that Jira returns non-dangerous HTML only.
   */
  return (
    // eslint-disable-next-line react/no-danger
    <div className={styles.commentHtml} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

export default TicketCommentContent;
