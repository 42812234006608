export const SalesSpaceChannelGrouping = {
  NONE: 'NONE',
  DATE: 'DATE',
};

export const SalesSpaceChannelsBetweenGroupingSort = {
  NONE: 'NONE',
  ALPHABETICAL: 'ALPHABETICAL',
  DATE: 'DATE',
};

export const SalesSpaceChannelsInGroupingSort = {
  DATE: 'DATE',
  NONE: 'NONE',
  ALPHABETICAL: 'ALPHABETICAL',
};

export const SalesSpaceChannelOrder = {
  NEW_MESSAGE: 'Neueste',
  ALPHABETICAL: 'Alphabetisch',
};

export const SalesSpaceChannelFilter = {
  FREE_AND_OWN: 'Freie und eigene Teamchats',
  ALL: 'Alle Teamchats',
};

export const UnreadMessagesFilter = {
  UNREAD: 'Ungelesene Nachrichten',
  ALL: 'Alle',
};
