import PropTypes from 'prop-types';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { CHIP_TYPE, CHIP_TOOLTIP_TEXT, ROLE_STATUS } from 'gcs-common/constants/ChipConstants';
import React from 'react';
import styles from './styles.module.scss';
import Icon from '../Icon/Icon';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

const Chip = React.forwardRef(({
  memberName = '',
  customerResponsibilityRoleName = '',
  memberType,
  roleStatus,
  isMainContactPerson = false,
  isCommunicationStructure = false,
  onRemove = null,
  disabled = false,
}, ref) => {
  const showResponsibility = customerResponsibilityRoleName;
  const isTeamChip = memberType === CHIP_TYPE.TEAM;

  const chipIcon = () => {
    if (isMainContactPerson) {
      return <Icon Icon={ICON.STAR_FILLED} color={ICON_COLOR.YELLOW} size={ICON_SIZE.X_SMALL} alt="star" />;
    }
    if (isTeamChip) {
      return <Icon Icon={ICON.GROUP} size={ICON_SIZE.X_SMALL} color={ICON_COLOR.HIGH_CONTRAST} alt="group" />;
    }
    return <Icon Icon={ICON.PERSON} size={ICON_SIZE.X_SMALL} color={ICON_COLOR.HIGH_CONTRAST} alt="person" />;
  };

  let tooltipText = '';
  if (roleStatus === ROLE_STATUS.MEMBER_NO_ROLE) {
    if (memberType === CHIP_TYPE.INDIVIDUAL && !isCommunicationStructure) {
      // eslint-disable-next-line max-len
      tooltipText = isMainContactPerson ? CHIP_TOOLTIP_TEXT.MAIN_CONTACT_NO_ROLE : CHIP_TOOLTIP_TEXT.AGENT_NO_ROLE;
    // Only these tooltips are shown at the house communication structure level
    } else if (memberType === CHIP_TYPE.TEAM) {
      tooltipText = CHIP_TOOLTIP_TEXT.TEAM_NO_ROLE;
    }

  } else if (roleStatus === ROLE_STATUS.ROLE_NO_MEMBER && !isCommunicationStructure) {
    if (memberType === CHIP_TYPE.INDIVIDUAL) {
      tooltipText = CHIP_TOOLTIP_TEXT.ROLE_NO_AGENT;
    } else if (memberType === CHIP_TYPE.TEAM) {
      tooltipText = CHIP_TOOLTIP_TEXT.ROLE_NO_TEAM;
    }
  }

  const removeMemberButton = () => {
    if (onRemove && !disabled) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div onClick={onRemove} role="button" className={styles.removeMember} tabIndex={0}>
          <Icon Icon={ICON.CLOSE} color={ICON_COLOR.ERROR} size={ICON_SIZE.X_SMALL} alt="error" />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`${styles.chipContainer} ${isTeamChip ? styles.teamChip : styles.individualChip}`}
      ref={ref}
      data-testid={`chip-container-${memberName}`}
    >
      <div className={`${styles.chip} ${showResponsibility ? styles.separation : ''}`}>
        {chipIcon()}
        <div className={styles.textWrapper}>
          {memberName}
        </div>
        {tooltipText && (
          <InfoTooltip text={tooltipText} />
        )}
        {!showResponsibility && (
          removeMemberButton()
        )}
      </div>
      {showResponsibility && (
        <div className={styles.responsibilityText}>
          {customerResponsibilityRoleName}
          {removeMemberButton()}
        </div>
      )}
    </div>
  );
});
Chip.propTypes = {
  memberName: PropTypes.string,
  memberType: PropTypes.oneOf(Object.values(CHIP_TYPE)).isRequired,
  isMainContactPerson: PropTypes.bool,
  customerResponsibilityRoleName: PropTypes.string,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  roleStatus: PropTypes.oneOf(Object.values(ROLE_STATUS)).isRequired,
  isCommunicationStructure: PropTypes.bool,
};

export default Chip;
