import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getSalesSpaceChannelsProcessing } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import { getDateTagForJoinedChannel } from 'gcs-common/slices/channelList/channelListSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import ChannelListDateTag from '../ChannelListDateTag/ChannelListDateTag';
import ChannelListItem from '../ChannelListItem/ChannelListItem';


const ChannelListItemWithDateTag = ({ channelId }) => {
  const dateTagDate = useSelector(getDateTagForJoinedChannel(channelId));
  const salesSpaceChannelsProcessing = useSelector(getSalesSpaceChannelsProcessing);
  if (salesSpaceChannelsProcessing.includes(channelId)) {
    return <LoadingIndicator key={channelId} />;
  }
  return (
    <>
      {dateTagDate && (
        <div className={styles.dateWrapper}>
          <ChannelListDateTag date={dateTagDate} />
        </div>
      )}
      <ChannelListItem channelId={channelId} />
    </>
  );
};

ChannelListItemWithDateTag.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelListItemWithDateTag;
