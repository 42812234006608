import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsHidden } from 'gcs-common/slices/channel/channelSelectors';
import { getToggleChannelVisibilityIsLoading } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { toggleChannelVisibility } from 'gcs-common/slices/channels/channelsThunks/toggleChannelVisibility';
import fetchCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUser';
import PropTypes from 'prop-types';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';
import Button from '../../Button/Button';

const ChannelArchiveButton = ({ selectedChannelId }) => {
  const isHidden = useSelector(getIsHidden(selectedChannelId));
  const dispatch = useDispatch();
  const isChannelArchiving = useSelector(getToggleChannelVisibilityIsLoading);

  const onChannelVisibilityButtonClick = useCallback(() => {
    if (!isChannelArchiving) {
      if (!isHidden) {
        dispatch(trackGALegacyEvent(LEGACY_EVENTS.HIDE_CHANNEL()));
      }
      dispatch(toggleChannelVisibility({ channelId: selectedChannelId }));
      dispatch(fetchCurrentUser());
    }
  }, [isHidden, isChannelArchiving, dispatch, selectedChannelId]);

  return (
    <>
      {!isHidden ? (
        <Button
          onClick={onChannelVisibilityButtonClick}
          className={styles.navbarButton}
          title="Chat archivieren"
          data-testid="channel-archive-button"
        >
          <IconComponent Icon={ICON.ARCHIVE} alt="Chat archivieren" />
        </Button>
      )
        : (
          <Button
            onClick={onChannelVisibilityButtonClick}
            className={styles.navbarButton}
            title="Chat wiederherstellen"
            data-testid="channel-un-archive-button"
          >
            <IconComponent Icon={ICON.UNARCHIVE} alt="Chat wiederherstellen" />
          </Button>
        )
      }
    </>
  );
};

ChannelArchiveButton.propTypes = {
  // TODO Set isRequired when SMOOT-3185 is done
  selectedChannelId: PropTypes.string,
};

export default ChannelArchiveButton;
