import { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedAgent, getCreateAgentGroupChannelDefaultErrorMessage,
  getCreateAgentGroupChannelErrorMessage, getAllAdministrationUsers,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import {
  resetCreateAgentGroupChannelDefaultErrorMessage, resetCreateAgentGroupChannelErrorMessage,
  resetAdministrationUsersByKey,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import {
  createAgentGroupChannel, fetchAgentGroupChannels, fetchSelectUsers,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import USER_TYPE from 'gcs-common/helper/userTypeHelper';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import FormikInput from '../../../FormikInput/FormikInput';
import FormikProfileImageSelect from '../../../FormikProfileImageSelect/FormikProfileImageSelect';
import FormikUserSelect from '../../shared/FormikUserSelect/FormikUserSelect';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const initialData = {
  channelName: '',
  channelImage: undefined,
  agentIds: [],
  craftsmanId: undefined,
};

const CompanyCreateValidationSchema = Yup.object().shape({
  channelName: Yup.string().required('Erforderlich'),
  channelImage: Yup.string().required('Erforderlich'),
});

const AgentGroupChannelCreate = ({ onCancel, onSuccess }) => {

  const selectedAgent = useSelector(getSelectedAgent) || {};
  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateAgentGroupChannelErrorMessage);
  const defaultErrorMessage = useSelector(getCreateAgentGroupChannelDefaultErrorMessage);
  const allUsers = useSelector(getAllAdministrationUsers);
  const [companyId, setCompanyId] = useState(undefined);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetCreateAgentGroupChannelDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateAgentGroupChannelErrorMessage());
    dispatch(resetCreateAgentGroupChannelDefaultErrorMessage());
  }, [dispatch]);

  const onChannelCraftsmanMembersChanged = useCallback((users) => {
    if (errorMessage) {
      dispatch(resetCreateAgentGroupChannelErrorMessage());
    }
    const firstCraftsmanCompanyId = users && users.length > 0
      ? allUsers[users[0]]?.companyId
      : undefined;
    setCompanyId(firstCraftsmanCompanyId);
    if (companyId !== firstCraftsmanCompanyId) {
      dispatch(resetAdministrationUsersByKey({
        selectKey: 'agentGroupChannelCreateCraftsmanSelection',
        selectedUser: users[0],
      }));
      dispatch(fetchSelectUsers({
        selectKey: 'agentGroupChannelCreateCraftsmanSelection',
        pageIndex: 0,
        pageSize: 10,
        userType: USER_TYPE.CRAFTSMAN,
        companyId: firstCraftsmanCompanyId,
      }));
    }
  }, [dispatch, errorMessage, allUsers, companyId]);

  const onChannelAgentMembersChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateAgentGroupChannelErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const {
    id,
    friendlyName,
    name,
  } = selectedAgent;

  const submit = useCallback(async (values, { setSubmitting }) => {
    const {
      channelName,
      channelImage,
      craftsmanId,
      agentIds,
    } = values;

    const result = await dispatch(createAgentGroupChannel({
      channelName,
      channelImage,
      fileType: 'image/png',
      craftsmanId,
      agentIds,
    }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
      setTimeout(() => {
        dispatch(fetchAgentGroupChannels({ agentId: id }));
      }, 1000);
    }
  }, [dispatch, id, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={CompanyCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting, values, isValid, dirty }) => {
        const { agentIds, craftsmanId, channelImage } = values;
        const isMissingUser = (
          (!agentIds || agentIds.length === 0) && !craftsmanId
        );

        return (
          <Form className={styles.column}>

            <FormikProfileImageSelect name="channelImage" />

            {!channelImage
            && (
              <div className={styles.validationErrorMessage}>
                Bitte wähle ein Bild für die Gruppe
              </div>
            )}

            <FormikInput label="Name*" name="channelName" />

            <div className={styles.userNameColumn}>
              <div className={styles.userNameLabel}>
                Mitarbeiter
              </div>
              <div className={styles.userName}>
                {friendlyName || name}
              </div>
            </div>

            <FormikUserSelect
              selectKey="agentGroupChannelCreateAgentSelection"
              userType={USER_TYPE.AGENT}
              key="agents"
              label="Mitarbeiter"
              name="agentIds"
              labelKey="friendlyName"
              valueKey="id"
              isMulti
              localFilter={[id]}
              onChange={onChannelAgentMembersChanged}
            />

            <FormikUserSelect
              selectKey="agentGroupChannelCreateCraftsmanSelection"
              userType={USER_TYPE.CRAFTSMAN}
              key="craftsmen"
              label="Kunde"
              name="craftsmanId"
              labelKey="friendlyName"
              valueKey="id"
              isMulti
              onChange={onChannelCraftsmanMembersChanged}
              companyId={companyId}
            />

            {isMissingUser
            && (
              <div className={styles.validationErrorMessage}>
                Bitte wähle mindestens einen weiteren Mitarbeiter oder Kunden
              </div>
            )}

            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

            <div className={styles.buttonRow}>
              <Button
                className={styles.modalButtonSecondary}
                type="button"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Abbrechen
              </Button>
              <Button
                className={styles.modalButton}
                type="submit"
                disabled={!(isValid && dirty) || isMissingUser || isSubmitting}
              >
                Speichern
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

AgentGroupChannelCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AgentGroupChannelCreate;
