const deleteAccountText = [
  <h2>Angaben zum Entwickler</h2>,
  `
  Project Lary: Project Lary im Google Play Store

  `,
  <h2>Anfrage zur Löschung ihrer Daten</h2>,
  <b>In der App</b>,
  `

    1. Öffnen Sie Ihre App.
    2. Wählen Sie das Menü oben links.
    3. Tippen Sie auf Ihr Profilbild.
    4. Gehen Sie zu „Account Einstellungen".
    5. Geben Sie Ihre Mobilnummer oder E-Mail-Adresse ein.
    6. Bestätigen Sie durch Drücken von „Account löschen".

  `,
  <b>Per E-Mail</b>,
  `

    1. Schreiben Sie eine E-Mail mit dem Betreff „BEEM Account löschen". Senden Sie die E-Mail an beem@project-lary.com.
    2. Innerhalb von 2-3 Werktagen erhalten Sie eine Bestätigungs-E-Mail oder SMS vom Support-Team.
    3. Bestätigen Sie die Löschung durch Antworten auf die Bestätigungs-E-Mail oder SMS. Nach der Bestätigung wird Ihr Account dauerhaft gelöscht und kann nicht wiederhergestellt werden.

  `,
  <h2>Accountlöschung und Aufbewahrungsfristen Ihrer Daten</h2>,
  `
  
  Nach der Bestätigung Ihrer Anfrage auf Löschung der Daten wird Ihr Account dauerhaft gelöscht und kann nicht wiederhergestellt werden. Folgende Daten werden dauerhaft entfernt:
  
  · Ihre BEEM Accountinformationen und Ihr Profilbild
  · Ihr kompletter Nachrichtenverlauf in allen Chats
  · Ihre Mitgliedschaft in allen Kanälen und Gruppen

  `,
];

export default deleteAccountText;
