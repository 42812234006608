import {
  useState, useEffect, useMemo, Dispatch, SetStateAction,
} from 'react';
import {
  getDashboardPaginatedTickets,
  getDashboardPaginatedTicketsIsLoadingOrIdle,
  getDashboardPagination,
  getCreatedTicketSucceededStatus,
  getTicketNotifications,
  getTicketNotificationsCount,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchTicketsForDashboard } from 'gcs-common/slices/tickets/ticketsThunks';
import { selectDashboardTicket } from 'gcs-common/slices/tickets/ticketsSlice';
import { Response } from 'gcs-common/types/typeHelper';
import { Column } from 'react-table';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import TicketStatus from '../../TicketStatus';
import TicketDashboardNotification from './TicketDashboardNotification/TicketDashboardNotification';
import styles from './styles.module.scss';
// eslint-disable-next-line import/no-cycle
import { FiltersValues } from '../TicketDashboard';

interface TicketsDashboardTableProps {
  setShowDetails: Dispatch<SetStateAction<boolean>>;
  filters: FiltersValues
}

const TicketDashboardTable = (props: TicketsDashboardTableProps) => {
  const { setShowDetails, filters } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data = useSelector(getDashboardPaginatedTickets());
  const dataIsLoading = useSelector(getDashboardPaginatedTicketsIsLoadingOrIdle());
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ticketNotifications = useSelector(getTicketNotifications());
  const ticketNotificationsCount = useSelector(getTicketNotificationsCount());
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const pagination = useSelector(getDashboardPagination());
  const ticketCreated = useSelector(getCreatedTicketSucceededStatus());
  const {
    status,
    creatorId,
    endDate,
    startDate,
    erpCustomerIds,
    search,
  } = filters;
  const columns: Column<Response<'/chat/ets/tickets/{ticket_id}', 'get'>>[] = useMemo(() => [
    {
      Header: 'Ticket ID',
      accessor: 'externalJiraKey',
      disableSortBy: true,
      Cell: (cell) => {
        const { value, row: { original } } = cell;
        const hasNotification = original.id in ticketNotifications;

        return (
          <div className={styles.idAndNotification}>
            {hasNotification && <TicketDashboardNotification />}
            {value}
          </div>
        );
      },
    },
    {
      Header: 'Ticket Titel',
      accessor: 'title',
      disableSortBy: true,
      Cell: (cell) => {
        const { value } = cell;
        return (
          <div className={styles.ellipsis}>
            {value}
          </div>
        );
      },
    },
    {
      Header: 'Kundenname',
      accessor: 'customerName',
      disableSortBy: true,
    },
    {
      Header: 'Kundennummer',
      accessor: 'customerNumber',
      disableSortBy: true,
    },
    {
      Header: 'Ersteller',
      accessor: 'creatorName',
      disableSortBy: true,
    },
    {
      Header: 'Datum',
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: (cell) => {
        const { value } = cell;
        const formattedDate = moment(value).format('DD.MM.YYYY');
        return (
          <div>{formattedDate}</div>
        );
      },
    },
    {
      Header: 'Letzte Aktualisierung',
      accessor: 'jiraLastSyncedAt',
      disableSortBy: true,
      Cell: (cell) => {
        const { value } = cell;
        const formattedDate = moment(value).format('DD.MM.YYYY, HH:mm');
        return (
          <div>{formattedDate}</div>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      Cell: (cell) => {
        const { value } = cell;
        return (
          <TicketStatus status={value} />
        );
      },
    },
  ], [ticketNotifications]);
  const getRowProps = (row: { original: { id: string; }; }) => {
    const hasNotification = row.original.id in ticketNotifications;

    return {
      className: hasNotification && styles.rowWithNotification,
    };
  };
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  // sorting is disabled for now (we rely on default sorting), since it is a complex UX topic
  // to marry sorting by column with the server-side sorting by notification
  const [sortedColumnId, setSortedColumnId] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const dispatch = useDispatch();

  const handlePagination = (pageIndex:number, pageSize:number) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  };

  useEffect(() => {
    // @ts-expect-error redux
    dispatch(fetchTicketsForDashboard({
      sortedColumnId,
      sortOrder,
      pageIndex: currentPageIndex,
      pageSize: currentPageSize,
      status,
      startDate,
      endDate,
      erpCustomerIds,
      creatorId,
      search,
    }));
  }, [dispatch, currentPageIndex, currentPageSize, sortedColumnId, sortOrder, search,
    status, startDate, endDate, erpCustomerIds, creatorId,
    /** Keep dashboard up2date by also refetching when notifications change. */
    ticketNotificationsCount]);

  const onTicketSelected = (ticket: Response<'/chat/ets/tickets/{ticket_id}', 'get'>) => {
    dispatch(selectDashboardTicket(ticket.id));
    setShowDetails(true);
  };

  useEffect(() => {
    if (ticketCreated) {
      // @ts-expect-error redux
      dispatch(fetchTicketsForDashboard({
        sortedColumnId,
        sortOrder,
        pageIndex: currentPageIndex,
        pageSize: currentPageSize,
        status,
        startDate,
        endDate,
        erpCustomerIds,
        creatorId,
        search,
      }));
    }
  }, [dispatch, ticketCreated,
    currentPageIndex, currentPageSize, sortedColumnId, sortOrder,
    status, startDate, endDate, erpCustomerIds, search, creatorId,
  ]);

  return (
    <div className={styles.dashboardTable}>
      {data && (
        <PaginatedTable
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onRowSelected={onTicketSelected}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data={data}
          // @ts-expect-error TODO Migrate PaginatedTable
          columns={columns}
          // @ts-expect-error TODO Migrate PaginatedTable to ts
          getRowProps={getRowProps}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          pagination={pagination}
          useNewStyles
          onPaginationChanged={handlePagination}
          sortIsLoading={dataIsLoading}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortedColumnId={sortedColumnId}
          setSortedColumnId={setSortedColumnId}
        />
      )}
    </div>
  );
};

export default TicketDashboardTable;
