import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { trackGAEvent, trackGAPageView } from '../slices/googleAnalytics/googleAnalyticsThunks';

function useTrackLocationChange() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname, search } = location;
  const url = `${pathname}${search}`;

  useEffect(() => {
    dispatch(trackGAPageView(url));

    const track = (category: string, action: string) => dispatch(trackGAEvent(category, action));

    if (pathname === '/processes') {
      track('MAGIC_CUBE', 'OPENED');
    } else if (pathname === '/login') {
      track('LOGIN', 'START_LOGIN');
    } else if (pathname === '/settings') {
      track('SETTINGS', 'OPENED');
    } else if (pathname === '/channel') {
      track('CHANNEL_DETAILS', 'OPENED');
    } else if (pathname === '/favorites') {
      track('FAVORITE', 'OPENED');
    } else if (pathname === '/settings/terms-of-use') {
      track('SETTINGS', 'TERMS_PAGE_OPENED');
    }
  }, [pathname, dispatch, url]);
}

export default useTrackLocationChange;
