/* eslint-disable jsx-a11y/label-has-for */

import PropTypes from 'prop-types';
import { Field } from 'formik';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import styles from './styles.module.scss';

const FormikInput = ({
  textClassName = undefined,
  errorClassName = undefined,
  label = '',
  disabled = false,
  name = '',
  type = 'text',
  placeholder = '',
  disableHoverOver = false,
  children = undefined,
  ...props
}) => {

  return (
    <div className={styles.formikInputContainer}>
      <div className={`${styles.formikInputWrapper} ${disabled || disableHoverOver ? styles.disabled : ''}`}>
        {label && <label className={styles.formikInputLabel} htmlFor={name}>{label}</label>}
        {children}
        <Field
          id={name}
          className={textClassName || styles.formikInput}
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={disabled}
          {...props}
        />
      </div>
      <div className={errorClassName || ''}>
        <ValidationError name={name} />
      </div>
    </div>
  );
};

FormikInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  textClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  disabled: PropTypes.bool,
  disableHoverOver: PropTypes.bool,
  children: PropTypes.node,
};

export default FormikInput;
