/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useSelector } from 'react-redux';
import { getDashboardTicketsCreators } from 'gcs-common/slices/tickets/ticketsSelectors';
import Select, { StylesConfig } from 'react-select';
import styles from './styles.module.scss';
// eslint-disable-next-line import/no-cycle
import { FiltersValues } from '../../TicketDashboard';

interface Creator {
  friendlyName: string;
  id: string;
} // TODO type

interface OptionType {
  value: string,
  label: string
}

interface CreatorFilterProps {
  setCreatorFilters: (filter: Partial<FiltersValues>)=>void;
  creatorId: string | null;
}

const CreatorFilter = (props:CreatorFilterProps) => {
  const { setCreatorFilters, creatorId } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const creators: Creator[] = useSelector(getDashboardTicketsCreators());

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const loaded = creators?.length > 0;

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const creatorsOptions = creators.map((el) => ({
    label: el.friendlyName,
    value: el.id,
  }));

  const handleChange = (creator: string | null) => {
    setCreatorFilters({ creatorId: creator || null });
  };

  const customStyles: StylesConfig<OptionType> = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
  };

  return (
    <div className={styles.creatorSelectContainer}>
      <Select<OptionType>
        /** Force re-render when options are loaded to update defaultValue. */
        key={loaded ? 'true' : 'false'}
        name="ticketCreatorId"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        options={creatorsOptions}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        defaultValue={creatorsOptions.find(x => x.value === creatorId)}
        isClearable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        onChange={(selectedOption) => handleChange(selectedOption?.value || null)}
        styles={customStyles}
        placeholder="Ersteller"
      />
    </div>
  );
};

export default CreatorFilter;
