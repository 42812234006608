import { getCurrentUserId } from '../../currentUser/currentUserSelectors';
import { channelMessagesUpsert } from '../messagesSlice';
import setChannelMessagesConsumed from '../../channel/channelThunks/setChannelMessagesConsumed';
import { MESSAGE_TYPE } from '../../../constants/MessageTypes';


const addMessages = ({
  channelMessages: channelMessagesToAdd,
}) => async (dispatch, getState) => {

  const channelIds = [...Object.keys(channelMessagesToAdd)];
  channelIds.map(async (channelId) => {
    const messagesToAdd = channelMessagesToAdd[channelId];
    const newestMessageToAdd = messagesToAdd[messagesToAdd.length - 1];

    const {
      authorId: newestMessageToAddAuthor,
    } = newestMessageToAdd;
    const currentUserId = getCurrentUserId(getState());

    await dispatch(channelMessagesUpsert({ channelMessages: { [channelId]: messagesToAdd } }));

    if (newestMessageToAddAuthor === currentUserId
        && newestMessageToAdd?.messageType !== MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION
        && newestMessageToAdd?.autogenerated !== true
    ) {
      dispatch(setChannelMessagesConsumed({ channelId }));
    }

  });
};

export default addMessages;
