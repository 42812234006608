export function buildAuth0UniversalLoginUrl({
  redirectUrl, loginFlow, appTheme,
} : { redirectUrl : string, loginFlow: string, appTheme: string }) {

  const debugAPIBaseUrl = import.meta.env.VITE_UNIVERSAL_LOGIN_BASE_URL as string;

  const params = {
    client_id: import.meta.env.VITE_SESSION_AUTH0_CLIENT_ID as string,
    audience: 'https://api.getbeem.de',
    appVersion: import.meta.env.VITE_BEEM_VERSION_NUMBER as string,
    loginFlow,
    theme: appTheme,
    prompt: 'login',
    scope: 'openid profile email offline_access',
    response_type: 'code',
    response_mode: 'query',
    redirect_uri: redirectUrl,
    ...(debugAPIBaseUrl && { debugAPIBaseUrl }),
  } as const;

  const baseUrl = `https://${import.meta.env.VITE_AUTH0_DOMAIN}/authorize`;
  const url = new URL(baseUrl);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });

  return url.toString();
}
