import PropTypes from 'prop-types';
import ChevronIcon from 'gcs-common/img/arrow.svg?react';
import styles from './styles.module.scss';

const ShowMoreNewChannelOptions = ({ onShowMore }) => (
  <div className={styles.showMoreButtonWrapper}>
    <button type="button" className={styles.showMoreButton} onClick={onShowMore}>
      Mehr anzeigen
      <ChevronIcon className={styles.showMoreChevronDown} />
    </button>
  </div>
);

ShowMoreNewChannelOptions.propTypes = {
  onShowMore: PropTypes.func.isRequired,
};

export default ShowMoreNewChannelOptions;
