import PropTypes from 'prop-types';
import WarningIcon from '../../img/warning.svg?react';
import styles from './styles.module.scss';

const SidebarMessage = ({ text = '' }) => (
  <div className={styles.menuMessage}>
    <WarningIcon className={styles.icon} />

    <p className={styles.menuMessageText}>{text}</p>
  </div>
);

SidebarMessage.propTypes = {
  text: PropTypes.string,
};

export default SidebarMessage;
