/* eslint-disable css-modules/no-unused-class */
import {
  ICON_COLOR,
  ICON_SIZE,
  ICON_TRANSFORM,
  IconColorValue,
  IconComponentType,
  IconSizeValue,
  IconTransformationValue,
} from 'gcs-common/constants/IconConstants';
import { CSSProperties } from 'react';
import styles from './styles.module.scss';

interface IconComponentProps {
  Icon: IconComponentType;
  color?: IconColorValue;
  size?: IconSizeValue;
  transform?: IconTransformationValue;
  style?: CSSProperties;
  alt?: string;
  className?: string;
}

const IconComponent = (props: IconComponentProps) => {
  const {
    Icon, color = ICON_COLOR.DARK_GREY, size = ICON_SIZE.MEDIUM, transform = ICON_TRANSFORM.NONE, style = {}, alt = '', className = '',
  } = props;

  const colorClass = styles[color];
  const sizeClass = styles[size];
  const transformClass = styles[transform];
  if (Icon) {
    return (
      <Icon
        className={`${colorClass} ${sizeClass} ${transformClass} ${className}`}
        style={style}
        aria-label={alt}
        role="img"
      />
    );
  }
  return null;
};

export default IconComponent;
