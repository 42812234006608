import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  fetchCustomerResponsibilityMembers,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersThunk';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import { getCustomerResponsibilitiesMembers,
  getCustomerResponsibilitiesMembersPaginationResult,

  getCustomerResponsibilitiesMembersStateLoading } from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSelector';
import {
  CustomerResponsibilityMemberTypes,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersConstants';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import { SORT_ORDER } from '../../../../constants/sortConstants';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addCompanyIcon from '../../../../img/plus_white.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import CustomerResponsibilityMemberCreate
  from '../CustomerResponsibilityMemberCreate/CustomerResponsibilityMemberCreate';
import agentIcon from '../../../../img/agent-icon.svg';
import teamIcon from '../../../../img/team-icon.svg';
import styles from './styles.module.scss';
import deletedIcon from '../../../../img/delete.svg';
import CustomerResponsibilityMemberDelete
  from '../CustomerResponsibilityMemberDelete/CustomerResponsibilityMemberDelete';

const columns = [
  {
    Header: 'Kundenummer',
    accessor: 'customerNumber',
  },
  {
    Header: 'Firmenname',
    accessor: 'customerName',
  },
  {
    Header: 'Typ',
    accessor: 'type',
    styles: {
      paddingRight: '0',
      paddingLeft: '0',
    },
    Cell: (cell) => {
      // eslint-disable-next-line react/destructuring-assignment
      const cellValueCheck = cell.value === CustomerResponsibilityMemberTypes.INDIVIDUAL;
      return (
        <div className={cellValueCheck ? styles.cellDivType : styles.cellDivTypeTeam}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <img src={cellValueCheck ? agentIcon : teamIcon} alt={cell.value} />
        </div>
      );
    },

  },
  {
    Header: 'Beteiligtenrolle',
    id: 'roleName',
    accessor: d => {
      const cellValueCheck = d.type === CustomerResponsibilityMemberTypes.INDIVIDUAL;
      return (
        <div
          className={cellValueCheck ? styles.cellDivRole : styles.cellDivRoleTeam}
        >
          {d.roleName}
        </div>
      );
    },
    styles: {
      paddingLeft: '0',
    },
  },
  {
    Header: 'Verkaufsmitarbeiter/Team',
    accessor: 'teamOrAgent',
  },
  {
    Header: 'Erstellt von',
    accessor: 'createdBy',
  },
  {
    Header: 'Geändert am',
    accessor: 'createdAt',
  },
];

const CustomerResponsibilityMembersTable = ({ filter }) => {
  const filteredColumns = columns.filter(column => !filter.includes(column.accessor));
  const onCustomerResponsibilityMemberSelected = useCallback(() => {}, []);
  // eslint-disable-next-line max-len
  const customerResponsibilityMembersLoading = useSelector(getCustomerResponsibilitiesMembersStateLoading);

  const customerResponsibilityMembers = useSelector(getCustomerResponsibilitiesMembers);
  // eslint-disable-next-line max-len
  const customerResponsibilityMembersPagination = useSelector(getCustomerResponsibilitiesMembersPaginationResult);
  const globalSearchInput = useSelector(getGlobalSearchInput);

  const dispatch = useDispatch();

  const [createCustomerResponsibilityMember,
    setCreateCustomerResponsibilityMember] = useState(false);
  const [deleteCustomerResponsibilityMember,
    setDeleteCustomerResponsibilityMember] = useState(null);

  const accessorToBeSorted = columns.find(element => element.Header).accessor;
  const [sortedColumnId, setSortedColumnId] = useState(accessorToBeSorted);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);

  // eslint-disable-next-line max-len
  const loadCustomerResponsibilityMembers = useCallback((pageIndex, pageSize, searchQuery, searchBy) => {
    dispatch(fetchCustomerResponsibilityMembers({
      pageIndex, pageSize, searchQuery, searchBy, sortOrder, sortedColumnId,
    }));
  }, [dispatch, sortedColumnId, sortOrder]);

  const customerResponsibilityMemberIsCreatedSuccessfully = useCallback(() => {
    setCreateCustomerResponsibilityMember(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setDeleteCustomerResponsibilityMember(original);
    },
    [],
  );

  const customerResponsibilityMemberIsDeletedSuccessfully = useCallback(() => {
    setDeleteCustomerResponsibilityMember(null);
    dispatch(showSuccessNotification('Erfolgreich entfernt'));
  }, [dispatch]);

  return (
    <>
      <PaginatedTable
        data={customerResponsibilityMembers}
        columns={filteredColumns}
        onPaginationChanged={loadCustomerResponsibilityMembers}
        pagination={customerResponsibilityMembersPagination}
        searchInput={globalSearchInput}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortedColumnId={sortedColumnId}
        setSortedColumnId={setSortedColumnId}
        sortIsLoading={customerResponsibilityMembersLoading}
        onRowSelected={onCustomerResponsibilityMemberSelected}
        renderActions={({ row }) => (
          <>
            <button
              type="button"
              onClick={(event) => deleteSelected(event, row)}
            >
              <img
                alt="deleted-icon"
                src={deletedIcon}
              />
            </button>
          </>
        )}
      />

      <AddFabButton
        icon={addCompanyIcon}
        title="Verantwortlichkeiten"
        onClick={() => setCreateCustomerResponsibilityMember(true)}
      />
      <PopUpModal
        title="Neue Verantwortlichkeit"
        onRequestClose={() => setCreateCustomerResponsibilityMember(false)}
        isOpen={createCustomerResponsibilityMember}
      >
        <CustomerResponsibilityMemberCreate
          onCancel={() => setCreateCustomerResponsibilityMember(false)}
          onSuccess={customerResponsibilityMemberIsCreatedSuccessfully}
        />
      </PopUpModal>
      {deleteCustomerResponsibilityMember && (
        <PopUpModal
          title="VERANTWORTLICHKEITEN LÖSCHEN"
          onRequestClose={() => setDeleteCustomerResponsibilityMember(null)}
          isOpen={!!deleteCustomerResponsibilityMember}
        >
          <CustomerResponsibilityMemberDelete
            onCancel={() => setDeleteCustomerResponsibilityMember(null)}
            customerResponsibilityMember={deleteCustomerResponsibilityMember}
            onSuccess={customerResponsibilityMemberIsDeletedSuccessfully}
          />
        </PopUpModal>
      )}
    </>
  );
};

CustomerResponsibilityMembersTable.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default CustomerResponsibilityMembersTable;
