import { getMessage } from '../messageSelector';
import { deleteMessageOffline } from '../../messageInput/messageInputActions/deleteMessageOffline';

const deleteMessage = ({
  messageId,
}) => async (dispatch, getState) => {
  /* eslint-disable camelcase */
  const {
    channelId,
    index: messageIndex,
  } = getMessage(messageId)(getState());
  dispatch(deleteMessageOffline({ channelId, messageIndex, messageId }));
};

export default deleteMessage;
