import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { ticketFormChanged } from 'gcs-common/slices/tickets/ticketsSlice';
import isEqual from 'lodash/isEqual';
import styles from './styles.module.scss';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import TicketDiscardModal from './TicketDiscardModal/TicketDiscardModal';

interface TicketDiscardButtonProps {
  channelId?: string | undefined;
  initialValues: unknown;
  onClose: VoidFunction;
  disabled: boolean;
}

const TicketDiscardButton = (props: TicketDiscardButtonProps) => {
  const { channelId, initialValues, onClose, disabled } = props;
  const { values } = useFormikContext();
  const dispatch = useDispatch();

  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const discard = () => {
    dispatch(ticketFormChanged({ channelId }));
    setShowDiscardModal(false);
    onClose();
  };

  const handleClick = () => {
    if (values && !isEqual(values, initialValues)) {
      setShowDiscardModal(true);
      return;
    }

    discard();
  };

  return (
    <>
      <button disabled={disabled} type="button" className={styles.discardButton} onClick={handleClick}>Abbrechen</button>

      {showDiscardModal && (
        <PopUpModal
          className={styles.discardModal}
          title="Ticketerstellung abbrechen"
          onRequestClose={() => setShowDiscardModal(false)}
          isOpen
          /** We need to be higher than the ticket dashboard drawer with z-index 10 */
          zIndex={20}
        >
          <TicketDiscardModal
            onCancel={() => setShowDiscardModal(false)}
            handleDiscard={discard}
          />
        </PopUpModal>
      )}
    </>
  );
};

export default TicketDiscardButton;
