import { nanoid } from '@reduxjs/toolkit';

const PREFIX = 'VID';

export const createTemporaryVideoId = () => {
  return `${PREFIX}_${nanoid()}`;
};

export const getVideoMetaData = (file) => {
  return new Promise(resolve => {
    const videoSrc = file instanceof File ? URL.createObjectURL(file) : file.webPath;
    const video = document.createElement('video');
    video.autoplay = false;
    video.src = videoSrc;
    video.onloadedmetadata = () => {
      const {
        videoWidth,
        videoHeight,
        duration,
      } = video;
      return resolve({
        width: videoWidth,
        height: videoHeight,
        duration,
        size: file.size,
        title: file.name,
      });
    };
  });
};

export const getVideoThumbnailUrl = (playbackId = '', width = 400, height = 0) => {
  return `https://image.mux.com/${playbackId}/thumbnail.png?width=${width}&height=${height}`;
};

export const calculateVideoUrl = (playbackId, quality = 'low') => {
  const videoQuality = ['high', 'medium', 'low'];
  if (!videoQuality.includes(quality)) {
    // eslint-disable-next-line no-param-reassign
    quality = 'low';
  }
  return `https://stream.mux.com/${playbackId}/${quality}.mp4`;
};

export const fetchVideoFromUrl = async (playbackId, videoDownloadResolution) => {
  let response;
  try {
    response = await fetch(calculateVideoUrl(playbackId, videoDownloadResolution));
  } catch (error) {
    throw new Error('Failed to fetch url for video download');
  }
  return response.url;
};

export const calculateVideoMessageSizeStyle = (width, height, maxWidth, maxHeight) => {
  const ratio = height / width;
  let style = {};

  if (ratio > 1) {
    style = {
      width: maxHeight / ratio,
      height: maxHeight,
    };
  } else {
    style = {
      width: maxWidth,
      height: maxWidth * ratio,
    };
  }

  return style;
};
