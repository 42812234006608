import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageImageUrl,
  getMessageThumbnail,
  getMessageIndex,
  getVideoPlaybackId, getMessageImageUrlError, getMessageMediaIsImage, getMessage,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import fetchAndPreloadImageUrl from 'gcs-common/slices/messages/messagesThunks/fetchAndPreloadImageUrl';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import brokenImage from 'gcs-common/img/broken_image.svg';
import { calculateVideoMessageSizeStyle } from 'gcs-common/helper/videoHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import VideoStreamThumbnail from '../../VideoStreamPlayer/VideoStreamThumbnail';

function MediaItem({ messageId }) {

  const thumbnailUrl = useSelector(getMessageThumbnail(messageId));
  const fullSizeUrl = useSelector(getMessageImageUrl(messageId));
  const messageIndex = useSelector(getMessageIndex(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const isChatConnected = useSelector(getIsChatConnected);
  const playbackId = useSelector(getVideoPlaybackId(messageId));
  const mediaDownloadFailed = useSelector(getMessageImageUrlError(messageId));
  const isImage = useSelector(getMessageMediaIsImage(messageId));
  const message = useSelector(getMessage(messageId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (isImage && !thumbnailUrl && isChatConnected) {
      dispatch(fetchAndPreloadImageUrl({ messageId }));
    }
  }, [dispatch, isImage, thumbnailUrl, isChatConnected, messageId]);

  const mediaUrl = thumbnailUrl || fullSizeUrl;

  const handleFailImage = (e) => {
    e.target.src = brokenImage;
  };

  const scrollToMessage = useCallback(() => {
    dispatch(scrollToMessageId({ channelId: selectedChannelId, messageId, messageIndex }));
  }, [dispatch, messageIndex, messageId, selectedChannelId]);

  if (isImage && mediaDownloadFailed) {
    return (
      <>
        <div className={styles.filesItem}>
          <div className={styles.img}>
            <img
              alt="Bild aus Nachricht"
              src={brokenImage}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={styles.filesItem}
            /* eslint-disable-next-line max-len */
        style={playbackId && calculateVideoMessageSizeStyle(message.videoWidth, message.videoHeight, 200, 250)}
      >
        {mediaUrl || playbackId ? (
          <button
            onClick={scrollToMessage}
            type="button"
          >
            {playbackId && (
              <VideoStreamThumbnail playbackId={playbackId} />
            )}
            {mediaUrl && (
            <img
              alt="Bild aus Nachricht"
              className={styles.img}
              src={mediaUrl}
              onError={handleFailImage}
            />
            )}
          </button>
        )
          : (
            <div className={styles.loadingIndicatorWrapper}>
              <LoadingIndicator padding="0" />
            </div>
          )
          }

      </div>
    </>
  );
}

MediaItem.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default MediaItem;
