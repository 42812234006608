import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { captureMessage } from '@sentry/react';
import { fileListKey } from 'gcs-common/helper/mediaHelper';
import documentImage from 'gcs-common/img/document.svg';
import LocalFilePreview from '../../../LocalFilePreview/LocalFilePreview';
import styles from './styles.module.scss';
import IconComponent from '../../../IconComponent/IconComponent';
import useTicketAttachmentFileDrop from '../../../../customHooks/useTicketAttachmentFileDrop';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import TicketErrorModal from '../../TicketAttachmentSizeErrorModal/TicketAttachmentSizeErrorModal';
// eslint-disable-next-line import/no-cycle
import { TicketFormValues } from '../TicketForm';

interface TicketAttachmentUploadProps {
  channelId?: string | undefined
}

const TicketAttachmentUpload = (props: TicketAttachmentUploadProps) => {
  const { channelId } = props;
  const { values } = useFormikContext<TicketFormValues>();
  const [showAttachmentErrorModal, setShowAttachmentErrorModal] = useState(false);

  const logFileAdded = () => {
    // @ts-expect-error sentry
    captureMessage('ETS Ticket Attachments - file added', { extra: { channelId }, tags: ['ets'] });
  };

  const logFileRemoved = (fileIndex: number) => {
    // @ts-expect-error sentry
    captureMessage('ETS Ticket Attachments - file removed', { extra: { channelId, fileIndex }, tags: ['ets'] });
  };

  const logFilesDropped = (files: File[]) => {
    // @ts-expect-error sentry
    captureMessage('ETS Ticket Attachments - files dropped', { extra: { channelId, files: files?.length }, tags: ['ets'] });
  };

  const logFileUrlDropped = (fileUrl: string) => {
    // @ts-expect-error sentry
    captureMessage('ETS Ticket Attachments - file URL converted', { extra: { channelId, fileUrl }, tags: ['ets'] });
  };

  const logFileUrlError = (ex: unknown, fileUrl: string) => {
    // @ts-expect-error sentry
    captureMessage('ETS Ticket Attachments - file URL invalid', { extra: { channelId, fileUrl, message: ex?.message, ex }, tags: ['ets'] });
  };

  useEffect(() => {
    // @ts-expect-error sentry
    captureMessage('ETS Ticket Attachments - channelId/values changed', { extra: { channelId, files: values?.attachments?.length }, tags: ['ets'] });
  }, [channelId, values?.attachments]);

  const onFileSizeError = () => {
    setShowAttachmentErrorModal(true);
    // @ts-expect-error sentry
    captureMessage('ETS Ticket Attachments - dropped file exceeding size limit', { extra: { channelId }, tags: ['ets'] });
  };

  const { getRootProps, getInputProps, removeFile } = useTicketAttachmentFileDrop({
    fieldName: 'attachments',
    onFileSizeError,
    onFileAdded: logFileAdded,
    onFileRemoved: logFileRemoved,
    onFilesDropped: logFilesDropped,
    onFileUrlDropped: logFileUrlDropped,
    onFileUrlError: logFileUrlError,
  });

  return (
    <div className={styles.imageWrapper}>
      {values.attachments.map((file, fileIndex) => (
        // @ts-expect-error TODO add id to File
        <div className={styles.previewWrapper} key={fileListKey(file)}>
          <LocalFilePreview file={file} filePlaceHolder={documentImage} />

          <div className={styles.buttonActions}>
            <button onClick={() => removeFile(fileIndex)} type="button">
              <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.DARKEST_GREY} />
            </button>
          </div>
        </div>
      ))}
      <div {...getRootProps()} className={styles.attachment}>
        <input {...getInputProps()} data-testid="attachments-input" />
        <IconComponent Icon={ICON.PLUS} size={ICON_SIZE.X_LARGE} />
      </div>
      {showAttachmentErrorModal && (
        <PopUpModal
          title="Ticketerstellung  abbrechen"
          onRequestClose={() => setShowAttachmentErrorModal(false)}
          isOpen
        >
          <TicketErrorModal
            onCancel={() => setShowAttachmentErrorModal(false)}
          />
        </PopUpModal>
      )}
    </div>
  );
};

export default TicketAttachmentUpload;
