import { nanoid } from '@reduxjs/toolkit';

const MESSAGE_PREFIX = 'PIM';
const FAVORITE_PREFIX = 'PID';

export const createClientMessageId = () => {
  return `${MESSAGE_PREFIX}${nanoid()}`;
};

export const isClientMessageId = (id) => {
  return id && `${id}`.startsWith(MESSAGE_PREFIX);
};

export const createClientFavoriteId = () => {
  return `${FAVORITE_PREFIX}${nanoid()}`;
};

export const isClientFavoriteId = (id) => {
  return id && id.startsWith(FAVORITE_PREFIX);
};
