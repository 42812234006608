import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTeamsLoginMutation } from 'gcs-common/clients/api/entities/auth/auth-api';
import { getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { THEME } from 'gcs-common/constants/themes';
import useMsTeamsClient from 'gcs-common/hooks/useMsTeamsClient';
import LoginWithSideMenu from './LoginWithSideMenu/LoginWithSideMenu';
import LoginClassic from './LoginClassic/LoginClassic';

const Login = () => {
  const theme = useSelector(getThemeIdentifier);
  const { isInitialized, getAuthToken } = useMsTeamsClient();
  const teamsLoginMutaion = useTeamsLoginMutation();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('#######', isInitialized);
    const msLogin = async () => {
      const token = await getAuthToken();
      if (token) {
        teamsLoginMutaion.mutateAsync({ body: { token } });
      }
    };
    if (isInitialized) {
      msLogin();
    }
  }, [isInitialized, getAuthToken, teamsLoginMutaion]);

  const loginComponent = useMemo(() => {
    switch (theme) {
      case THEME.AGENT: {
        return <LoginClassic />;
      }
      default: {
        return <LoginWithSideMenu />;
      }
    }
  }, [theme]);

  return (
    <>
      {loginComponent}
    </>
  );
};

export default Login;

Login.propTypes = {};
