import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getMessageShowUnreadNumber } from 'gcs-common/slices/messages/messageSelector';
import { getUnreadMessageCountForChannel } from 'gcs-common/slices/channels/unreadMessagesSelectors';
// eslint-disable-next-line import/no-cycle
import UnreadCountDisplay from '../UnreadCountDisplay/UnreadCountDisplay';
import styles from './styles.module.scss';

const NewMessageIndicator = ({ messageId }) => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const showUnreadNumber = useSelector(getMessageShowUnreadNumber(selectedChannelId, messageId));
  const unreadCount = useSelector(getUnreadMessageCountForChannel(selectedChannelId));

  if (!showUnreadNumber) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.line} />
      <div className={styles.text}>
        <UnreadCountDisplay unreadCount={unreadCount} />
        {' '}
        NEUE
        {' '}
        {unreadCount === 1 ? 'NACHRICHT' : 'NACHRICHTEN'}
      </div>
    </div>
  );

};

NewMessageIndicator.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default NewMessageIndicator;
