import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const AddFabButton = ({ icon, title, onClick, className = '' }) => {
  return (
    <button
      className={`${styles.addFabButton} ${className}`}
      type="button"
      onClick={onClick}
    >
      <img src={icon} alt="add-img" />
      {title && <span className={styles.addFabButtonTitle}>{title}</span>}
    </button>
  );
};

AddFabButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default AddFabButton;
