import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveHouseId } from '../houses/housesSelectors';
import { getSelectedAgentId } from '../administrationUsers/administrationUsersSelectors';
import { getSelectedErpCustomerId } from '../erpCustomers/erpCustomersSelectors';

export const fetchCustomerResponsibilityMembers = createAsyncThunk(
  'customerResponsibilityMembers/fetchCustomerResponsibilityMembers',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, mergeData = false,
  } = {}, { getState, rejectWithValue, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const selectedAgentId = getSelectedAgentId(getState()) || null;
    const selectedErpCustomerId = getSelectedErpCustomerId(getState()) || null;
    const response = await gccApiClient.getCustomerResponsibilityMembersList(
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, activeHouseId,
      selectedAgentId, selectedErpCustomerId,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination, mergeData };
  },
);

export const createCustomerResponsibilityMember = createAsyncThunk(
  'customerResponsibilityMembers/createCustomerResponsibilityMember',
  async ({ member } = {}, { dispatch, rejectWithValue, getState, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const response = await gccApiClient.createCustomerResponsibilityMember(member, activeHouseId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchCustomerResponsibilityMembers());
    return { member: response };
  },
);

export const deleteCustomerResponsibilityMember = createAsyncThunk(
  'customerResponsibilityMembers/deleteCustomerResponsibilityMember',
  async ({ responsibilityMemberId }, { dispatch, rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteCustomerResponsibilityMember(responsibilityMemberId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchCustomerResponsibilityMembers());
    return response;
  },
);

export const fetchAllResponsibilityRoles = createAsyncThunk(
  'customerResponsibilityMembers/fetchAllResponsibilityRoles',
  async ({ roleType, view, houseId }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAllCustomerResponsibilityRoles(roleType, view, houseId);
    const { data } = response;
    return data;
  },
);
