import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { shutdownChatClient } from 'gcs-common/slices/chatConnection/chatConnectionThunks/chatConnectionThunks';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
// eslint-disable-next-line max-len
// import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import { onChatConnected, onOffline, onOnline } from '../../slices/initialization/initializationThunks';

interface ChatProtectedRouteProps {
  children: ReactNode;
}
/**
 * Initializes the ChatClient.
 * Only render children components when the ChatClient is connected.
 */
const ChatProtectedRoute = (props: ChatProtectedRouteProps) => {
  const { children } = props;
  const isChatConnected: boolean = useSelector(getIsChatConnected);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isOnline: boolean = useSelector(getIsOnline);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(shutdownChatClient());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isOnline) {
      debugLogger('[ONLINE] Connecting ChatClient');
      dispatch(onOnline());
    } else {
      debugLogger('[OFFLINE] Shutting down ChatClient');
      dispatch(onOffline());
    }
  }, [dispatch, isOnline]);

  useEffect(() => {
    if (isChatConnected) {
      dispatch(onChatConnected());
    }
  }, [dispatch, isChatConnected]);

  // TODO SMOOT-4027 don't block the view of the app until chatClient is loaded
  // if (!isChatConnected) {
  //   return (
  //     <LoadingIndicatorFullScreen loadingText="Lädt Chat..." />
  //   );
  // }

  return (
    <>
      {children}
    </>
  );
};

export default ChatProtectedRoute;
