import { FILE_LIST_TYPES } from '../constants/fileListConstants';
import {
  CODE_TYPES, EXCEL_TYPES, POWERPOINT_TYPES, WORD_TYPES, ZIP_TYPES,
} from '../constants/contentTypeConstants';
import { ICON } from '../constants/IconConstants';


export const calculateMessageMediaURL = (mediaName: string) => {
  const baseUrl = import.meta.env.VITE_MEDIA_BASE_URL;
  return `${baseUrl}/${mediaName}`;
};

// TODO react-query
interface MessageType {
  messageType: string;
  mediaRelativePath: string;
  filename: string;
  contentType: string;
}

export const filesFromMessages = (messages: MessageType[]) => messages.filter(
  message => message.messageType === FILE_LIST_TYPES.MEDIA,
).map(message => ({
  url: calculateMessageMediaURL(message.mediaRelativePath),
  name: message.filename,
  type: message.contentType,
}));


// TODO react-query
interface MessageFileType {
  url:string;
  name:string;
  type:string;
}

export const downloadMessageFiles = (messageFiles: MessageFileType[]) => Promise.all(
  messageFiles.map(async (messageFile) => {
    const response = await fetch(messageFile.url);
    const blob = await response.blob();
    const file = new File([blob], messageFile.name, { type: messageFile.type });

    return file;
  }),
);

// TODO react-query
export const fileListKey = (file: File & { id:string }): string => file.id || `${file.name}:${file.type}:${file.size}:${file.lastModified}`;

export const mimeTypeIsImage = (attachmentType: string) => /^image\//.test(attachmentType);

export const calculateMediaIconFromType = (contentType: string) => {
  if (!contentType) {
    return ICON.FILE;
  }
  if (contentType.startsWith('image')) {
    return ICON.FILE_IMAGE;
  }
  if (contentType.startsWith('video')) {
    return ICON.FILE_VIDEO;
  }
  if (contentType.startsWith('audio')) {
    return ICON.FILE_AUDIO;
  }
  if (contentType === 'application/pdf') {
    return ICON.FILE_PDF;
  }
  if (contentType === 'text/plain') {
    return ICON.FILE_TEXT;
  }
  if (ZIP_TYPES.includes(contentType)) {
    return ICON.FILE_ARCHIVE;
  }
  if (WORD_TYPES.includes(contentType)) {
    return ICON.FILE_WORD;
  }
  if (EXCEL_TYPES.includes(contentType)) {
    return ICON.FILE_EXCEL;
  }
  if (POWERPOINT_TYPES.includes(contentType)) {
    return ICON.FILE_POWERPOINT;
  }
  if (CODE_TYPES.includes(contentType)) {
    return ICON.FILE_CODE;
  }
  return ICON.FILE;
};
