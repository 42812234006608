import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import NewChatButton from '../../NewChatButton/NewChatButton';
import styles from './styles.module.scss';
import ChannelSearchBar from '../../SearchBar/ChannelSearchBar/ChannelSearchBar';

function ChannelSearchInput({
  type,
  getSearchInput,
  setSearchInput,
  placeholder,
  rightButton,
}) {
  const searchInput = useSelector(getSearchInput);
  const dispatch = useDispatch();
  const onSearchInputChange = useCallback((event) => {
    dispatch(setSearchInput({ [type]: event.target.value }));
  }, [dispatch, setSearchInput, type]);
  useEffect(() => {
    return () => {
      dispatch(setSearchInput({ [type]: '' }));
    };
  }, [dispatch, setSearchInput, type]);
  return (
    <div className={type === 'forwardSearchInput' ? styles.searchForward : styles.search}>
      <ChannelSearchBar
        onChange={onSearchInputChange}
        placeholder={placeholder || 'Kontakte durchsuchen'}
        value={searchInput}
      />
      {rightButton || <NewChatButton />}
    </div>
  );
}

ChannelSearchInput.propTypes = {
  getSearchInput: PropTypes.func.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  rightButton: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  placeholder: PropTypes.string,
};

export default ChannelSearchInput;
