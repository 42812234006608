import AccountSettings from '../icons/account-settings.svg?react';
import Alarm from '../icons/alarm.svg?react';
import Archive from '../icons/archive.svg?react';
import Arrow from '../icons/arrow.svg?react';
import ArrowLong from '../icons/arrow-long.svg?react';
import Attachment from '../icons/attachment.svg?react';
import Close from '../icons/close.svg?react';
import Chat from '../icons/chat.svg?react';
import Call from '../icons/call.svg?react';
import Checkmark from '../icons/checkmark.svg?react';
import CheckmarkDouble from '../icons/checkmark-double.svg?react';
import CircleChecked from '../icons/circle-checked.svg?react';
import CircleUnchecked from '../icons/circle-unchecked.svg?react';
import CircleProgress from '../icons/circle-progress.svg?react';
import Calendar from '../icons/calendar.svg?react';
import Clock from '../icons/clock.svg?react';
import Copy from '../icons/copy.svg?react';
import Cube from '../icons/cube.svg?react';
import Dashboard from '../icons/dashboard.svg?react';
import Delete from '../icons/delete.svg?react';
import Download from '../icons/download.svg?react';
import DownloadAnimated from '../icons/download-animated.svg?react';
import Drag from '../icons/drag.svg?react';
import DropDown from '../icons/dropdown.svg?react';
import Edit from '../icons/edit.svg?react';
import Email from '../icons/email.svg?react';
import Emoji from '../icons/emoji.svg?react';
import Euro from '../icons/euro.svg?react';
import File from '../icons/file.svg?react';
import FileArchive from '../icons/file-zipper.svg?react';
import FileAudio from '../icons/file-audio.svg?react';
import FileCode from '../icons/file-code.svg?react';
import FileExcel from '../icons/file-excel.svg?react';
import FileImage from '../icons/file-image.svg?react';
import FilePdf from '../icons/file-pdf.svg?react';
import FilePowerpoint from '../icons/file-powerpoint.svg?react';
import FileText from '../icons/file-text.svg?react';
import FileVideo from '../icons/file-video.svg?react';
import FileWord from '../icons/file-word.svg?react';
import Forward from '../icons/forward.svg?react';
import Group from '../icons/group.svg?react';
import Help from '../icons/help.svg?react';
import HouseSwitch from '../icons/house-switch.svg?react';
import House from '../icons/house.svg?react';
import Image from '../icons/image.svg?react';
import Information from '../icons/information.svg?react';
import Keyboard from '../icons/keyboard.svg?react';
import Login from '../icons/login.svg?react';
import Logout from '../icons/logout.svg?react';
import Menu from '../icons/menu.svg?react';
import More from '../icons/more.svg?react';
import Minus from '../icons/minus.svg?react';
import Notification from '../icons/notification.svg?react';
import Photo from '../icons/photo.svg?react';
import Plus from '../icons/plus.svg?react';
import Refresh from '../icons/refresh.svg?react';
import Report from '../icons/report.svg?react';
import Rotate from '../icons/rotate.svg?react';
import Save from '../icons/save.svg?react';
import Search from '../icons/search.svg?react';
import Security from '../icons/security.svg?react';
import Send from '../icons/send.svg?react';
import Settings from '../icons/settings.svg?react';
import Share from '../icons/share.svg?react';
import Star from '../icons/star.svg?react';
import StarFilled from '../icons/star-filled.svg?react';
import Store from '../icons/store.svg?react';
import TodoList from '../icons/todo.svg?react';
import Unarchive from '../icons/unarchive.svg?react';
import Person from '../icons/person.svg?react';
import CycleArrows from '../icons/cycle-arrows.svg?react';
import QuickOrdering from '../icons/quick-ordering.svg?react';
import Eclipse from '../icons/eclipse.svg?react';
import Ets from '../icons/ets-logo.svg?react';
import NewSend from '../icons/new-send.svg?react';
import UserShade from '../icons/user-shade.svg?react';
import LoadingDots from '../icons/loading-dots.svg?react';
import EtsMobile from '../icons/ets-mobile.svg?react';

export const ICON = {
  ACCOUNT_SETTINGS: AccountSettings,
  ALARM: Alarm,
  CALL: Call,
  ARCHIVE: Archive,
  ARROW: Arrow,
  ARROW_LONG: ArrowLong,
  ATTACHMENT: Attachment,
  CALENDAR: Calendar,
  CLOSE: Close,
  CHAT: Chat,
  CHECKMARK: Checkmark,
  CHECKMARK_DOUBLE: CheckmarkDouble,
  CIRCLE_CHECKED: CircleChecked,
  CIRCLE_UNCHECKED: CircleUnchecked,
  CIRCLE_PROGRESS: CircleProgress,
  CLOCK: Clock,
  COPY: Copy,
  CUBE: Cube,
  DASHBOARD: Dashboard,
  DELETE: Delete,
  DOWNLOAD: Download,
  DOWNLOAD_ANIMATED: DownloadAnimated,
  DRAG: Drag,
  DROPDOWN: DropDown,
  EDIT: Edit,
  EMAIL: Email,
  EMOJI: Emoji,
  EURO: Euro,
  FILE: File,
  FILE_ARCHIVE: FileArchive,
  FILE_AUDIO: FileAudio,
  FILE_CODE: FileCode,
  FILE_EXCEL: FileExcel,
  FILE_IMAGE: FileImage,
  FILE_PDF: FilePdf,
  FILE_POWERPOINT: FilePowerpoint,
  FILE_TEXT: FileText,
  FILE_VIDEO: FileVideo,
  FILE_WORD: FileWord,
  FORWARD: Forward,
  GROUP: Group,
  HELP: Help,
  HOUSE_SWITCH: HouseSwitch,
  HOUSE: House,
  IMAGE: Image,
  INFORMATION: Information,
  KEYBOARD: Keyboard,
  LOGIN: Login,
  LOGOUT: Logout,
  MENU: Menu,
  MORE: More,
  MINUS: Minus,
  NOTIFICATION: Notification,
  PHOTO: Photo,
  PLUS: Plus,
  REFRESH: Refresh,
  REPORT: Report,
  ROTATE: Rotate,
  SAVE: Save,
  SEARCH: Search,
  SECURITY: Security,
  SEND: Send,
  SETTINGS: Settings,
  SHARE: Share,
  STAR: Star,
  STAR_FILLED: StarFilled,
  TODO_LIST: TodoList,
  UNARCHIVE: Unarchive,
  STORE: Store,
  PERSON: Person,
  CYCLE_ARROWS: CycleArrows,
  QUICKORDERING: QuickOrdering,
  ECLIPSE: Eclipse,
  ETS: Ets,
  NEW_SEND: NewSend,
  USER_SHADE: UserShade,
  LOADING_DOTS: LoadingDots,
  ETS_MOBILE: EtsMobile,
} as const;
type IconKey = keyof typeof ICON;
export type IconComponentType = typeof ICON[IconKey];

export const ICON_COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WHITE: 'white',
  BLACK: 'black',
  LIGHT_GREY: 'lightGrey',
  VERY_LIGHT_GREY: 'veryLightGrey',
  DARK_GREY: 'darkGrey',
  DARKEST_GREY: 'darkestGrey',
  HOUSE_SWITCH: 'houseSwitch',
  HIGHLIGHT: 'highlight',
  UPDATE_PROFILE_IMAGE: 'updateProfileImage',
  REGISTRATION_PROFILE_IMAGE: 'registrationProfileImage',
  NEW_CHAT_CHECKBOXES: 'newChatCheckboxes',
  LOGIN_ICONS: 'loginIcons',
  NEW_CHANNEL_BUTTON: 'newChannelButton',
  ERROR: 'error',
  PRIMARY_BUTTON: 'primaryButton',
  YELLOW: 'yellow',
  SUCCESS: 'success',
  WARNING: 'warning',
  LIGHT_RED: 'lightRed',
  BLUE: 'blue',
  MUSTARD: 'mustard',
  // TODO: new design system, see if they should stay like this
  HIGH_CONTRAST: 'highContrast',
  LOW_CONTRAST: 'lowContrast',
  PURPLE: 'purple',
} as const;
export type IconColorKey = keyof typeof ICON_COLOR;
export type IconColorValue = typeof ICON_COLOR[IconColorKey];

export const ICON_SIZE = {
  XXX_SMALL: 'xxxSmall',
  XXXX_SMALL: 'xxxxSmall',
  XX_SMALL: 'xxSmall',
  X_SMALL: 'xSmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'xLarge',
  XX_LARGE: 'xxLarge',
};
export type IconSizeKey = keyof typeof ICON_SIZE;
export type IconSizeValue = typeof ICON_SIZE[IconSizeKey];

export const ICON_TRANSFORM = {
  NONE: '',
  ROTATE_90: 'rotate90',
  ROTATE_180: 'rotate180',
  ROTATE_270: 'rotate270',
  FLIP_HORIZONTAL: 'flipHorizontal',
  FLIP_VERTICAL: 'flipVertical',
};
export type IconTransformationKey = keyof typeof ICON_TRANSFORM;
export type IconTransformationValue = typeof ICON_TRANSFORM[IconTransformationKey];
