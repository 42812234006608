import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getMessageSearchErrorMessage,
  getMessageSearchInput,
  getMessageSearchIsLoading,
  getMessageSearchResults,
} from 'gcs-common/slices/messageSearch/messageSearchSelectors';
import { searchChannelMessages } from 'gcs-common/slices/messageSearch/messageSearchThunks';
import { setMessageSearchInput } from 'gcs-common/slices/messageSearch/messageSearchSlice';
import {
  getChannelOldestLoadedMessage,
} from 'gcs-common/slices/messages/messagesSelectors';
import { ICON_SIZE } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import { SearchResultMessage } from '../SearchResultMessage/SearchResultMessage';
import styles from './styles.module.scss';
import ChannelSearchBar from '../SearchBar/ChannelSearchBar/ChannelSearchBar';

const MIN_INPUT_LENGTH = 3;

const Search = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const searchInput = useSelector(getMessageSearchInput);
  const searchLoading = useSelector(getMessageSearchIsLoading);
  const searchResultMessageIds = useSelector(getMessageSearchResults);
  const channelOldestMessage = useSelector(getChannelOldestLoadedMessage(selectedChannelId));
  const messageSearchIsLoading = useSelector(getMessageSearchIsLoading);
  const messageSearchErrorMessage = useSelector(getMessageSearchErrorMessage);
  const dispatch = useDispatch();

  const shouldSearch = searchInput.length >= MIN_INPUT_LENGTH;

  useEffect(() => {
    dispatch(setMessageSearchInput({ searchInput: '' }));
  }, [dispatch, selectedChannelId]);
  useEffect(() => {
    if (shouldSearch) {
      dispatch(searchChannelMessages({
        channelId: selectedChannelId,
        searchString: searchInput,
      }));
    }
  }, [dispatch, searchInput, selectedChannelId, shouldSearch, channelOldestMessage]);

  const onInputChange = useCallback((event) => {
    dispatch(setMessageSearchInput({ searchInput: event.target.value }));
  }, [dispatch]);

  let results = null;

  if (searchInput.length === 0) {
    results = <div className={styles.noResult}>Gib oben einen Suchbegriff ein.</div>;
  } else if (!shouldSearch) {
    results = (
      <div className={styles.noResult}>
        Bitte gebe drei oder mehr Zeichen ein, um mit der Suche zu beginnen
      </div>
    );

  } else if (searchLoading) {
    results = (
      <div className={styles.noResult}>
        <Spinner size={ICON_SIZE.XX_SMALL} />
        &nbsp;
        Suche...
      </div>
    );
  } else if (searchResultMessageIds.length === 0) {
    results = <div className={styles.noResult}>Keine Ergebnisse gefunden</div>;
  } else {
    // render messages
    results = (
      <>
        {messageSearchIsLoading && (
          <div className={styles.noResult}>
            <Spinner size={ICON_SIZE.XX_SMALL} />
            &nbsp;
            Suche...
          </div>
        )}
        {searchResultMessageIds.map(messageId => {
          return (
            <SearchResultMessage
              key={messageId}
              messageId={messageId}
            />
          );
        })}
      </>
    );
  }

  return (
    <div className={styles.searchList}>
      <div className={styles.title}>Suche</div>
      <ChannelSearchBar
        onChange={onInputChange}
        placeholder="Suchwort"
        value={searchInput}
        style={{ backgroundColor: '#f8f8f8', width: '220px', alignSelf: 'center' }}
      />
      {messageSearchErrorMessage && (
        <div className={styles.errorMsg}>
          Es ist ein Fehler aufgetreten.
          &nbsp;Möglicherweise konnten nicht alle Suchergebnisse geladen werden.
        </div>
      )}
      <div className={styles.searchResultList}>
        {results}
      </div>
    </div>
  );
};

Search.propTypes = {};

export default Search;
