import BaseConverter from './baseConverter';
import UserModel, { UserStatusModel } from '../../../models/UserModel';

// TODO: SMOOT-2846 This kind of temporarily, needs to be adjusted
// and uneeded parts need to be removed!
export default class UserConverter extends BaseConverter {
  // eslint-disable-next-line class-methods-use-this
  fromTwilioDataModel = (twilioUser) => {
    const {
      attributes, identity, friendlyName, isOnline,
    } = twilioUser;

    let userStatus;
    if (attributes.status) {
      userStatus = new UserStatusModel({
        ...attributes.status,
      });
    }

    const user = new UserModel({
      id: attributes.id,
      externalId: attributes.external_id,
      externalIdentity: identity,
      online: isOnline,
      email: attributes.email,
      phoneNumber: attributes.phone_number,
      salutation: attributes.salutation,
      firstName: attributes.first_name,
      lastName: attributes.last_name,
      craftsmanName: attributes.craftsman_name,
      friendlyName,
      userType: attributes.user_type,
      status: { ...userStatus },
      userCompany: attributes.user_company,
      imageUrl: attributes.image_url,
      archived: attributes.archived,
      salesSpacesUpdated: attributes.sales_spaces_updated,
      privacyAccepted: attributes.privacy_accepted,
      createdAt: attributes.created_at,
      createdBy: attributes.created_by,
      updatedAt: attributes.updated_at,
      updatedBy: attributes.updated_by,
      permissions: attributes.permissions,
      disabled: attributes.disabled,
      tenantId: attributes.tenant_id,
      businessUnitId: attributes.business_unit_id,
      businessUnitName: attributes.business_unit_name,
      craftsmanType: attributes.craftsman_type,
      hiddenChannels: attributes.hidden_channels,
    });
    // copy fields to serializable object,
    // to fix the warning that "non-serializable value was detected in the state"
    return user.toPlain();
  };
}
