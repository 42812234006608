import { createSelector } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import GCConfig from '../../themes/gc/config';
import GUTConfig from '../../themes/gut/config';
import EFGConfig from '../../themes/efg/config';
import HTIConfig from '../../themes/hti/config';
import ITGConfig from '../../themes/itg/config';
import DTGConfig from '../../themes/dtg/config';
import TFGConfig from '../../themes/tfg/config';
import HFHConfig from '../../themes/hfh/config';
import AgentConfig from '../../themes/agent/config';
import { THEME } from '../../constants/themes';
import { getIsMobile, getIsNative, getPackageId } from '../init/initSelectors';
import {
  calculateApiBaseUrlForEnv,
  extractThemeFromMobilePackage,
  extractThemeFromQueryParam,
  extractThemeFromWebUrl,
} from './themeHelper/themeHelpers';


const getDebugAppTheme = state => state.theme?.debugAppTheme;

export const getAppThemes = () => ({
  [THEME.GC]: GCConfig,
  [THEME.GUT]: GUTConfig,
  [THEME.EFG]: EFGConfig,
  [THEME.HTI]: HTIConfig,
  [THEME.ITG]: ITGConfig,
  [THEME.DTG]: DTGConfig,
  [THEME.TFG]: TFGConfig,
  [THEME.HFH]: HFHConfig,
  [THEME.AGENT]: AgentConfig,
});


export const getAppTheme = createSelector(
  getAppThemes, getDebugAppTheme, getIsMobile, getIsNative, getPackageId,
  (appThemes, debugTheme, isMobile, isNative, packageId) => {
    if (import.meta.env.DEV) {
      const themeFromQueryParam = extractThemeFromQueryParam();

      if (themeFromQueryParam) {
        return themeFromQueryParam;
      }

      if (debugTheme) {
        return debugTheme;
      }
    }

    let themeIdentifier = null;
    if (isMobile) {
      themeIdentifier = extractThemeFromMobilePackage(packageId, appThemes);
    } else {
      themeIdentifier = extractThemeFromWebUrl(appThemes);
    }

    if (!themeIdentifier) {
      debugLogger('Could not find theme identifier');
      captureMessage('Could not find theme identifier', { level: 'error' });
    }

    return themeIdentifier || THEME.GC;
  },
);

export const getAppConfig = createSelector(
  getAppTheme, getAppThemes,
  (appTheme, appThemes) => appThemes[appTheme] || appThemes.gc,
);

export const getAppName = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.appName,
);

export const getAppTitle = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.title,
);

export const getMetaDescription = createSelector(
  getAppConfig,
  (appConfig) => (appConfig && appConfig.description) || '',
);

export const getThemeIdentifier = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.identifier,
);

export const getIsAgentTheme = createSelector(
  getThemeIdentifier,
  (themeIdentifier) => themeIdentifier === THEME.AGENT,
);

export const getDefaultBrand = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.defaultBrand,
);

export const getFavicon = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.favicon,
);

export const getLogo = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.logo,
);

export const getChatLogo = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.chatLogo,
);

export const getSimpleLogo = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.simpleLogo,
);

export const getOpLogo = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.opLogo,
);

export const getTermsText = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.termsText,
);

export const getPrivacyText = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.privacyText,
);

export const getUsercentricsId = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.usercentricsId,
);

export const getImprintText = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.imprintText,
);

export const getDeleteAccountText = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.deleteAccountText,
);

export const getHelpUrl = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.helpUrl,
);

export const getAndroidStoreUrl = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.androidStoreUrl,
);

export const getiOSStoreUrl = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.iosStoreUrl,
);

export const getUrl = createSelector(
  getAppConfig,
  (appConfig) => appConfig && appConfig.url,
);

export const getApiBaseUrl = createSelector(
  getAppConfig,
  (appConfig) => appConfig && calculateApiBaseUrlForEnv(appConfig.apiBaseUrl),
);
