import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { useDebouncedCallback } from 'use-debounce';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import Image, { IMAGE_TYPE } from 'gcs-agent-web/src/components/Image/Image';
import styles from './styles.module.scss';
import { fetchArticleSuggestions } from '../../../slices/quickOrdering/quickOrderingThunks/fetchArticleSuggestions';
import { fetchArticleDetails } from '../../../slices/quickOrdering/quickOrderingThunks/fetchArticleDetails';
import { getSelectedChannelId } from '../../../slices/channels/channelsSelectors';

const ArticleOption = ({ innerProps, data }) => {
  const { value } = data;
  if (!value) return null;
  return (
    <div {...innerProps} className={styles.customOption}>
      <div className={styles.optionImage}>
        <Image type={IMAGE_TYPE.ARTICLE} src={value.picturePath} alt="Artikelbild" />
      </div>
      <div className={styles.optionContent}>
        <div className={styles.optionDescription}>
          {value.description1}
          {' '}
          {value.description2}
        </div>
        <div className={styles.optionNumber}>{value.productNumber}</div>
      </div>
    </div>
  );
};

ArticleOption.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.shape({
      picturePath: PropTypes.string,
      description1: PropTypes.string,
      description2: PropTypes.string,
      productNumber: PropTypes.string,
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: PropTypes.object,
};

const selectComponents = {
  Option: ArticleOption,
};

const selectStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  placeholder: (base) => ({
    ...base,
    color: styles.evenLighterGrey,
    fontSize: '14px',
  }),
  control: (base) => ({
    ...base,
    border: `1px solid ${styles.veryLightGrey}`,
    '&:hover': {
      border: `1px solid ${styles.veryLightGrey}`, // Keep the border consistent on hover
      cursor: 'text',
    },
  }),
};

const SelectArticles = () => {

  const [, { value: orderPositions }, { setValue: setOrderPositions }] = useField('orderPositions');
  const selectedChannelId = useSelector(getSelectedChannelId);

  const dispatch = useDispatch();
  const [loadOptions] = useDebouncedCallback(async (inputValue, callback) => {
    const products = await dispatch(fetchArticleSuggestions({ search: inputValue }));
    const options = products.map((product) => ({
      value: product,
      label: null,
    }));
    callback(options);

  }, 600);

  const selectRef = React.createRef();

  const loadArticleDetails = useCallback(async ({
    variant,
    supplier,
    runNumber,
    kbn,
    contextChannelId,
  }) => {
    const details = await dispatch(fetchArticleDetails({
      variant,
      supplier,
      runNumber,
      kbn,
      contextChannelId,
    }));
    return details;
  }, [dispatch]);

  const articleSelected = useCallback(async (option) => {
    if (!option) return;
    selectRef.current?.select?.select?.clearValue();
    if (orderPositions.find((p) => p.externalArticleId === option.value.id)) return;

    const newPosition = {
      kbn: option.value.productNumber,
      externalArticleId: option.value.id,
      picturePath: option.value.picturePath,
      description: `${option.value.description1} ${option.value.description2}`,
      variant: option.value.variant,
      supplier: option.value.supplier,
      runNumber: option.value.runNumber,
      // will be set later once article details are fetched
      quantityUnit: null,
      quantity: 1,
      opNetPrice: null,
      netPrice: null,
    };
    const {
      quantityUnit,
      netPrice,
    } = await loadArticleDetails({
      variant: option.value.variant,
      supplier: option.value.supplier,
      runNumber: option.value.runNumber,
      kbn: newPosition.kbn,
      contextChannelId: selectedChannelId,
    });
    newPosition.quantityUnit = quantityUnit;
    newPosition.netPrice = netPrice;
    newPosition.opNetPrice = netPrice;

    setOrderPositions([...orderPositions, newPosition]);

  }, [
    loadArticleDetails,
    orderPositions,
    selectRef,
    setOrderPositions,
    selectedChannelId,
  ]);

  return (
    <AsyncSelect
      ref={selectRef}
      loadOptions={loadOptions}
      components={selectComponents}
      onChange={articleSelected}
      placeholder="Artikel suchen (KBN, Artikelname...)"
      styles={selectStyles}
        // This is a workaround for a bug in react-select
        // that the placeholder is not reset when the value is cleared
      key={orderPositions}
      noOptionsMessage={({ inputValue }) => (inputValue ? 'Keine Artikel gefunden' : 'Tippe um zu suchen')}
      loadingMessage={() => 'Lade Artikel...'}
    />
  );
};

export default SelectArticles;
