import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateCurrentUser = createAsyncThunk(
  'currentUser/updateCurrentUser',
  async ({ values }, { rejectWithValue, extra: { gccApiClient } }) => {
    await gccApiClient.updateCurrentUser(values);
    const responseGet = await gccApiClient.getCurrentUser();
    if (responseGet.error_code) {
      return rejectWithValue({ errorCode: responseGet.error_code });
    }
    return { currentUser: responseGet };
  },
);
