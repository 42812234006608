/* eslint-disable jsx-a11y/label-has-for */
import {
  useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import CreatableSelect from 'react-select/creatable';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import styles from './styles.module.scss';
import selectCustomStyles from './customStyles';

const FormikList = ({
  label = '',
  disabled = false,
  name = '',
  placeholder = '',
  initialValue = [],
  distinctValues = false,
  ...props
}) => {
  const [field, , helpers] = useField(name);

  const createOption = (lab) => ({
    label: lab,
    value: lab,
  });

  const components = {
    DropdownIndicator: null,
  };

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  useEffect(() => {
    const optionsArray = [];
    if (initialValue) {
      // eslint-disable-next-line no-restricted-syntax
      for (const v of initialValue) {
        const option = createOption(v);
        optionsArray.push(option);
      }
      setValue(optionsArray);
    }
  }, [initialValue, disabled]);

  // This is for deleting options
  const handleChange = useCallback((option) => {
    helpers.setValue(option && option.map(op => op.value));
    setValue(option);
  }, [helpers]);

  const handleInputChange = (inputVal, event) => {
    if (event.action === 'input-change') {
      setInputValue(inputVal);
    }
    if (!inputValue) return;
    if (event.action === 'input-blur') {
      const newValue = (value !== null)
        ? [...value, createOption(inputValue)]
        : [createOption(inputValue)];
      helpers.setValue(newValue && newValue.map(op => op.value));
      setValue(newValue);
      setInputValue('');
    }
  };

  const handleKeyDown = useCallback((event) => {
    if (!inputValue) return;
    if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
      event.preventDefault();
      if (distinctValues && value?.filter(v => v.value === inputValue).length > 0) {
        setInputValue('');
        return;
      }
      const newValue = (value !== null)
        ? [...value, createOption(inputValue)]
        : [createOption(inputValue)];
      helpers.setValue(newValue && newValue.map(op => op.value));
      setValue(newValue);
      setInputValue('');
    }
  }, [helpers, value, setValue, inputValue, distinctValues]);

  return (
    <div className={styles.formikListContainer}>
      <div className={`${styles.formikListWrapper} ${disabled ? styles.disabled : ''}`}>
        {label && <label className={styles.formikListLabel} htmlFor={name}>{label}</label>}
        <CreatableSelect
          styles={selectCustomStyles}
          placeholder={placeholder}
          name={field.name}
          isDisabled={disabled}
          inputValue={inputValue}
          isClearable={false}
          isMulti
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          components={components}
          value={value}
          {...props}
        />
      </div>
      <ValidationError name={name} />
    </div>
  );
};

FormikList.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  textClassName: PropTypes.string,
  disabled: PropTypes.bool,
  initialValue: PropTypes.arrayOf(PropTypes.string),
  distinctValues: PropTypes.bool,
};

export default FormikList;
