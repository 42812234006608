import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { setSearchInput } from 'gcs-common/slices/channelList/channelListSlice';
import {
  getIsGroupChat,
  getSelectedNewChannelOptionIds,
} from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import { getCreateChannelIsLoading } from 'gcs-common/slices/channels/channelsSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import ChannelSearchInput from '../../AgentChannelList/ChannelSearchInput/ChannelSearchInput';
import styles from './styles.module.scss';

const NewChatAgentSearch = ({ onCreateChannel }) => {
  const newChannelOptionsSelectedIds = useSelector(getSelectedNewChannelOptionIds);
  const isGroupChat = useSelector(getIsGroupChat);
  const createChannelIsLoading = useSelector(getCreateChannelIsLoading);

  const isGroupChatEmpty = isGroupChat && newChannelOptionsSelectedIds.length === 0;
  const isCreateGroupChatLoading = (
    isGroupChat && newChannelOptionsSelectedIds.length > 0 && createChannelIsLoading
  );

  const isButtonDisabled = !isGroupChat || isGroupChatEmpty || isCreateGroupChatLoading;

  const isButtonDisabledStyle = isButtonDisabled ? styles.buttonDisabled : '';

  return (
    <div className={styles.searchWrapper}>
      <ChannelSearchInput
        getSearchInput={getSearchInput}
        isNewChat
        placeholder={isGroupChat ? 'Kontakte suchen' : 'Kontakte oder Gruppe suchen'}
        rightButton={(
          <button
            className={`${styles.newChatNext} ${isButtonDisabledStyle}`.trim()}
            disabled={isButtonDisabled}
            onClick={onCreateChannel}
            type="button"
          >
            <IconComponent Icon={ICON.ARROW} color={ICON_COLOR.WHITE} />
          </button>
        )}
        setSearchInput={setSearchInput}
        type="searchInput"
      />
    </div>
  );
};

NewChatAgentSearch.propTypes = {
  onCreateChannel: PropTypes.func.isRequired,
};

export default NewChatAgentSearch;
