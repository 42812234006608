import {
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLogo, getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { getIsRegistered } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { THEME } from 'gcs-common/constants/themes';
import styles from './styles.module.scss';
import type { RegistrationLocationState } from '../../router/router';

function RegistrationRoot() {

  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const themeIdentifier = useSelector(getThemeIdentifier);
  const logo = useSelector(getLogo);
  const isRegistered = useSelector(getIsRegistered);

  const location = useLocation();
  const sourceLocation = (location.state as RegistrationLocationState | null)?.sourceLocation;
  const to = sourceLocation?.pathname ? `${sourceLocation.pathname}${sourceLocation.search}` : '/';
  const isHFH = themeIdentifier === THEME.HFH;

  if (isRegistered) {
    return (
      <Navigate to={to} replace />
    );
  }

  return (
    <div className={`${styles.registrationRoot} ${isHFH && styles.registrationRootHFH}`}>
      <div className={styles.registrationOverlay} />
      <div className={styles.registrationWrapper}>
        <div className={styles.logoRight}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <img alt="gc-chat-logo" className={styles.chatLogoRight} src={logo} />
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default RegistrationRoot;
