import {
  useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getMessageIndex, getMessageIsFromMe } from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import styles from './styles.module.scss';
import LinkMeIcon from '../../../img/link_me.svg?react';
import LinkOtherIcon from '../../../img/link_other.svg?react';

function LinkItemLinkPreview({ messageId, linkPreview }) {
  const { matchStr, description, thumbImg } = linkPreview;
  const fromMe = useSelector(getMessageIsFromMe(messageId));
  const messageIndex = useSelector(getMessageIndex(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();

  const scrollToMessage = useCallback(() => {
    dispatch(scrollToMessageId({ channelId: selectedChannelId, messageId, messageIndex }));
  }, [dispatch, messageIndex, messageId, selectedChannelId]);

  const href = useMemo(() => {
    if (!matchStr?.match(/^[a-zA-Z]+:\/\//)) {
      return `http://${matchStr}`;
    }
    return matchStr;
  }, [matchStr]);

  const placeholder = fromMe ? <LinkMeIcon className={styles.linkMeIcon} /> : <LinkOtherIcon />;
  return (
    <button
      type="button"
      onClick={scrollToMessage}
      className={styles.linkButton}
    >
      <div className={`${styles.linkItem} ${fromMe ? styles.fromMe : ''}`}>
        <div className={`${styles.linkItemLeft} ${thumbImg ? '' : styles.emptyImage}`}>
          {thumbImg
            ? (
              <img
                alt="Link-Vorschau"
                src={thumbImg}
                className={styles.linkItemImg}
              />
            )
            : placeholder
          }
        </div>
        <div className={styles.linkItemRight}>
          <div className={styles.linkItemDescription}>{description}</div>
          <a
            href={href}
            rel="noopener noreferrer"
            target="_blank"
            className={styles.linkItemLink}
          >
            {matchStr}
          </a>
        </div>
      </div>
    </button>
  );
}

LinkItemLinkPreview.propTypes = {
  messageId: PropTypes.string.isRequired,
  linkPreview: PropTypes.shape({
    matchStr: PropTypes.string.isRequired,
    description: PropTypes.string,
    thumbImg: PropTypes.string,
  }).isRequired,
};

export default LinkItemLinkPreview;
