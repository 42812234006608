/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import moment from 'moment';
import fetchMessage from './messagesThunks/fetchMessage';
import downloadMedia from './messagesThunks/downloadMedia';
import fetchAndPreloadImageUrl from './messagesThunks/fetchAndPreloadImageUrl';
// eslint-disable-next-line import/no-cycle
import loadPreviousMessages from './messagesThunks/loadPreviousMessages';
// eslint-disable-next-line import/no-cycle
import ASYNC_STATE from '../../constants/AsyncState';
import { sendMessagesOffline, sendMessagesRollback } from '../messageInput/messageInputActions/sendMessagesOffline';
import { debugLogger } from '../../helper/debugLogger';
import { filterObjectByValue } from '../../helper/objectHelpers';
import { deleteMessageOffline } from '../messageInput/messageInputActions/deleteMessageOffline';
import { calculateChannelMessageOrder, calculateNewStateFromChannelMessages } from '../../helper/messagesHelpers';
import messageUpdateImageDimensions from './messagesThunks/messageUpdateImageDimensions';
import { byId } from '../../helper/byKey';
import { loadTwilioChannelMessages } from '../chatConnection/chatConnectionThunks/twilioLoadingThunk';
import mergeObjects from '../../helper/mergeObjects';

const asyncState = {
  loadPreviousMessages: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  downloadMedia: {
    status: ASYNC_STATE.IDLE,
  },
  loadTwilioChannelMessages: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
};

const initialState = {
  ...asyncState,
  channelMessages: {}, // messageIds per channel [channelId] => [...messageIds]
  messages: {}, // messages by id [messageId] => { ...message }
  fetchMessageErrors: {},
  messageImageUrls: {}, // { [messageId]: "imageurl"
  messageImageUrlErrors: {}, // { [messageId]: Error()
  // whether all messages were loaded until the very beginning of the channel history
  channelFirstMessageLoaded: {}, // { [channelId]: true  }
  // cache image dimension here for media message which do not have them (and potentially
  // cannot be updated)
  localImgDimensions: {}, // [messageId]: { w: 99, h: 99 }
};

const persistWhitelist = [
  'channelMessages',
  'messages',
  'channelFirstMessageLoaded',
  'localImgDimensions',
];

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    /**
     * Either adds or updates messages for several channels
     * If the state already contains the message (identified by the clientMessageId or the id)
     * this action updates the message with the remote message from Twilio.
     */
    channelMessagesUpsert: (state, action) => {
      // TODO nestedChannelMessages is now a list of MessageModels
      const { channelMessages: nestedChannelMessages, channelFirstMessageLoaded } = action.payload;
      if (channelFirstMessageLoaded) {
        state.channelFirstMessageLoaded = {
          ...state.channelFirstMessageLoaded,
          // don't override values with undefined
          ...filterObjectByValue(channelFirstMessageLoaded, (v) => v !== undefined),
        };
      }
      calculateNewStateFromChannelMessages(state, nestedChannelMessages);
    },
    resetLoadPreviousMessagesError: (state) => {
      state.loadPreviousMessages.error = null;
    },
  },
  extraReducers: {
    [fetchMessage.fulfilled]: (state, action) => {
      const { message } = action.payload;
      state.messages[message.id] = message;
    },
    [fetchMessage.rejected]: (state, action) => {
      const { error, meta: { arg: { channelId, messageIndex } } } = action;
      state.fetchMessageErrors[`${channelId}${messageIndex}`] = error.message || error;
    },
    [loadTwilioChannelMessages.pending]: (state) => {
      state.loadTwilioChannelMessages.status = ASYNC_STATE.LOADING;
    },
    [loadTwilioChannelMessages.fulfilled]: (state, action) => {
      state.loadTwilioChannelMessages.status = ASYNC_STATE.SUCCEEDED;
      const messages = action.payload;
      state.messages = mergeObjects(state.messages, byId(messages));
    },
    [loadTwilioChannelMessages.rejected]: (state) => {
      state.loadTwilioChannelMessages.status = ASYNC_STATE.FAILED;
    },
    [downloadMedia.pending]: (state) => {
      state.downloadMedia.status = ASYNC_STATE.LOADING;
    },
    [downloadMedia.fulfilled]: (state) => {
      state.downloadMedia.status = ASYNC_STATE.SUCCEEDED;
    },
    [downloadMedia.rejected]: (state) => {
      state.downloadMedia.status = ASYNC_STATE.FAILED;
    },
    [fetchAndPreloadImageUrl.fulfilled]: (state, action) => {
      const { payload: { url }, meta: { arg: { messageId } } } = action;
      state.messageImageUrls[messageId] = url;
    },
    [fetchAndPreloadImageUrl.rejected]: (state, action) => {
      const { error, meta: { arg: { messageId } } } = action;
      state.messageImageUrlErrors[messageId] = error;
    },
    [loadPreviousMessages.pending]: (state) => {
      state.loadPreviousMessages.status = ASYNC_STATE.LOADING;
      state.loadPreviousMessages.error = null;
    },
    [loadPreviousMessages.fulfilled]: (state) => {
      state.loadPreviousMessages.status = ASYNC_STATE.SUCCEEDED;
      state.loadPreviousMessages.error = null;
    },
    [loadPreviousMessages.rejected]: (state, action) => {
      const { error } = action;
      state.loadPreviousMessages.status = ASYNC_STATE.FAILED;
      state.loadPreviousMessages.error = error;
    },
    /**
     * Optimistically add the messages to the local state using the clientMessageId
     */
    [sendMessagesOffline]: (state, action) => {
      debugLogger('sendMessagesOffline', action);

      const { messages } = action.meta;

      messages.forEach((message) => {
        message.id = message.clientMessageId;
        message.datePendingCreated = moment().toISOString();
      });

      messages.forEach(message => {
        const { channelId, clientMessageId } = message;

        state.messages = mergeObjects(state.messages, { [clientMessageId]: message });
        const channelMessages = state.channelMessages[channelId] || [];
        state.channelMessages[channelId] = calculateChannelMessageOrder(
          state.messages,
          [
            ...channelMessages,
            clientMessageId,
          ],
        );
      });
    },
    [sendMessagesRollback]: (state, action) => {
      debugLogger('sendMessagesRollback', action);

      const { messages } = action.meta;

      messages.forEach(cm => {
        const { channelId, clientMessageId } = cm;
        delete state.messages[clientMessageId];
        state.channelMessages[channelId] = state.channelMessages[channelId]
          .filter(mId => mId !== clientMessageId);
      });

    },
    /**
     * Optimistically set the deleted flag in the message's attributes
     */
    [deleteMessageOffline]: (state, action) => {
      debugLogger('deleteMessageOffline', action);

      const { messageId } = action.meta;
      state.messages[messageId].deleted = true;
    },
    [messageUpdateImageDimensions.pending]: (state, action) => {
      const { messageId, width, height } = action.meta.arg;
      state.localImgDimensions[messageId] = { w: width, h: height };
    },
  },
});

export { persistWhitelist as messagesPersistWhitelist };
export const {
  channelMessagesUpsert,
  resetLoadPreviousMessagesError,
} = messagesSlice.actions;
export default messagesSlice.reducer;
