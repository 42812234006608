import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE_TYPE } from 'gcs-common/constants/MessageTypes';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getFavoriteForSelectedChannel } from 'gcs-common/slices/favorites/favoritesSelectors';
import { getUserName } from 'gcs-common/slices/user/userSelectors';
import { removeFavorite } from 'gcs-common/slices/favorites/favoritesThunks/removeFavorite';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import { getMessage, getMessageLoaded } from 'gcs-common/slices/messages/messageSelector';
import fetchMessage from 'gcs-common/slices/messages/messagesThunks/fetchMessage';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import MediaMessage from '../../MediaMessage/MediaMessage';
import AsyncMessageLoader from '../../AsyncMessageLoader/AsyncMessageLoader';
import styles from './styles.module.scss';
import DeletedMessage from '../../Message/DeletedMessage/DeletedMessage';
import VideoMessage from '../../VideoMessage/VideoMessage';

const Favorite = ({ favoriteId }) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentUserId);
  const favorite = useSelector(getFavoriteForSelectedChannel(favoriteId));
  const {
    channelId,
    messageId,
  } = favorite;

  const isMessageLoaded = useSelector(getMessageLoaded(messageId));
  const message = useSelector(getMessage(messageId));

  useEffect(() => {
    if (!isMessageLoaded && messageId) {
      dispatch(fetchMessage({ messageId }));
    }
  }, [dispatch, isMessageLoaded, messageId]);

  const {
    text, index, authorId, dateUpdated, messageType, deleted, forwardedMessageText,
  } = message || {};

  const time = moment(dateUpdated);

  const fromMe = currentUserId === authorId;
  const userName = useSelector(getUserName(authorId)) || '';

  const scrollToMessage = useCallback(() => {
    dispatch(scrollToMessageId({ channelId, messageId, messageIndex: index }));
  }, [channelId, dispatch, index, messageId]);

  const handleFavoriteRemoved = useCallback(() => {
    dispatch(removeFavorite({ channelId, favoriteId }));
  }, [channelId, dispatch, favoriteId]);

  let content = null;

  // TODO: SMOOT-2845 Fix
  if (
    messageType === MESSAGE_TYPE.MEDIA
    || messageType === MESSAGE_TYPE.MEDIA_FROM_MAIL
  ) {

    content = (
      <AsyncMessageLoader
        channelId={channelId}
        messageIndex={index}
        messageId={messageId}
      >
        <MediaMessage
          maxHeight={120}
          maxWidth={200}
          messageId={messageId}
          selectionDisabled
        />
      </AsyncMessageLoader>
    );
  } else if (messageType === MESSAGE_TYPE.VIDEO) {
    content = (
      <AsyncMessageLoader
        channelId={channelId}
        messageIndex={index}
        messageId={messageId}
      >
        <VideoMessage
          messageId={messageId}
          selectionDisabled
        />
      </AsyncMessageLoader>
    );
  } else if (deleted) {
    // Should never happen, just in case
    content = (
      <DeletedMessage />
    );
  } else {
    content = text || forwardedMessageText;
  }

  return (
    <div
      key={favorite.messageId}
      className={`${styles.favoriteWrapper} ${fromMe ? styles.me : ''}`}
    >
      <button
        type="button"
        onClick={handleFavoriteRemoved}
        className={styles.favoriteIconButton}
      >
        <IconComponent Icon={ICON.STAR_FILLED} color={ICON_COLOR.PRIMARY} style={{ marginLeft: '-4px' }} />
      </button>
      <button
        type="button"
        onClick={scrollToMessage}
        className={styles.favoriteContent}
      >
        <div className={styles.userName}>{userName}</div>
        <div className={styles.content}>{content}</div>
        <div className={styles.favoriteDate}>{time.format('LLL')}</div>
      </button>
    </div>
  );
};

Favorite.propTypes = {
  favoriteId: PropTypes.string.isRequired,
};

export default Favorite;
