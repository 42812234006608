import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import IconComponent from '../../IconComponent/IconComponent';

const MemberContactItem = ({ title, Icon, link = '' }) => {
  const text = link !== '' ? <a href={link}>{title}</a> : title;

  return (
    <div className={styles.itemWrapper}>
      <div className={styles.detailItem}>
        <IconComponent Icon={Icon} style={{ padding: '4px' }} />
        <div className={styles.detailName}>{text}</div>
      </div>
    </div>
  );
};

MemberContactItem.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  link: PropTypes.string,
};

export default MemberContactItem;
