/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchHouseCommunicationStructures,
  deleteHouseCommunicationStructure,
  createHouseCommunicationStructureMembers,
  updateHouseCommunicationStructureMembers,
  fetchAllAssignmentRoles,
  fetchAgentOptions,
  fetchTeamOptions,
} from './houseCommunicationStructureThunks';
import ASYNC_STATE from '../../../constants/AsyncState';
import { getErrorMsg } from '../../../helper/errorMessages';
import mergeArrays from '../../../helper/mergeArrays';

const asyncState = {
  fetchHouseCommunicationStructures: {
    status: ASYNC_STATE.IDLE,
  },
  delete: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  update: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  communicationStructures: [],
  assignmentRoles: [],
  agentOptions: [],
  teamOptions: [],
};

const houseCommunicationStructureSlices = createSlice({
  name: 'houseCommunicationStructures',
  initialState,
  reducers: {
    resetDeleteCommunicationStrcuturedefaultErrorMessage: (state) => {
      state.delete.defaultErrorMessage = null;
    },
    resetCreateCommunicationStructureDefaultErrorMessage: (state) => {
      state.create.defaultErrorMessage = null;
    },
    resetUpdateCommunicationStructureDefaultErrorMessage: (state) => {
      state.update.defaultErrorMessage = null;
    },
    resetAgentOptions: (state) => {
      state.agentOptions = [];
    },
    resetTeamOptions: (state) => {
      state.teamOptions = [];
    },
  },
  extraReducers: {
    [fetchHouseCommunicationStructures.pending]: (state) => {
      state.fetchHouseCommunicationStructures.status = ASYNC_STATE.LOADING;
    },
    [fetchHouseCommunicationStructures.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.communicationStructures = data;
      state.fetchHouseCommunicationStructures.status = ASYNC_STATE.SUCCEEDED;
    },
    [fetchHouseCommunicationStructures.rejected]: (state) => {
      state.fetchHouseCommunicationStructures.status = ASYNC_STATE.FAILED;
    },
    [deleteHouseCommunicationStructure.pending]: (state) => {
      state.delete.status = ASYNC_STATE.LOADING;
    },
    [deleteHouseCommunicationStructure.fulfilled]: (state) => {
      state.delete.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteHouseCommunicationStructure.rejected]: (state, action) => {
      state.delete.status = ASYNC_STATE.FAILED;
      const { payload: { errorCode } = {} } = action;
      state.delete.defaultErrorMessage = getErrorMsg(errorCode) || getErrorMsg('default');
    },
    [createHouseCommunicationStructureMembers.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
    },
    [createHouseCommunicationStructureMembers.fulfilled]: (state) => {
      state.create.status = ASYNC_STATE.SUCCEEDED;
    },
    [createHouseCommunicationStructureMembers.rejected]: (state, action) => {
      state.create.status = ASYNC_STATE.FAILED;
      const { payload: { errorCode } = {} } = action;
      state.create.defaultErrorMessage = getErrorMsg(errorCode) || getErrorMsg('default');
    },
    [updateHouseCommunicationStructureMembers.pending]: (state) => {
      state.update.status = ASYNC_STATE.LOADING;
    },
    [updateHouseCommunicationStructureMembers.fulfilled]: (state) => {
      state.update.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateHouseCommunicationStructureMembers.rejected]: (state, action) => {
      state.update.status = ASYNC_STATE.FAILED;
      const { payload: { errorCode } = {} } = action;
      state.update.defaultErrorMessage = getErrorMsg(errorCode) || getErrorMsg('default');
    },
    [fetchAllAssignmentRoles.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.assignmentRoles = mergeArrays(state.assignmentRoles, data);
    },
    [fetchAgentOptions.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.agentOptions = mergeArrays(state.agentOptions, data);
    },
    [fetchTeamOptions.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.teamOptions = mergeArrays(state.teamOptions, data);
    },
  },
});

export const {
  resetDeleteCommunicationStrcuturedefaultErrorMessage,
  resetCreateCommunicationStructureDefaultErrorMessage,
  resetUpdateCommunicationStructureDefaultErrorMessage,
  resetAgentOptions,
  resetTeamOptions,
} = houseCommunicationStructureSlices.actions;

export default houseCommunicationStructureSlices.reducer;
