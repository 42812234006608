// 'none' is fallback to the default 'primary' style
export const COLOR_MAP = ['none',
  '#741B47',
  '#666666',
  '#45818D',
  '#38761D',
  '#3C78D8',
];

// For consistency, the current user should always have the same color.
export const CURRENT_USER_COLOR = '#674EA7';
