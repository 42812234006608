/* eslint-disable css-modules/no-unused-class */
import styles from './styles.module.scss';

// Note: usually we dont use styled components, this is an exception
// as otherwise we would some hacky workarounds to style the react-select
// Lib: https://github.com/JedWatson/react-select

const selectCustomStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 'unset',
    border: 'unset',
    boxShadow: 'unset',
    backgroundColor: 'transparent',
    borderRadius: '0',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: styles.multivalueBackgroundColor,
    borderRadius: '3px',
    paddingRight: '5px',
    height: '28px',
    alignItems: 'center',
    paddingLeft: '2px',
  }),
  multiValueRemove: (provided, state) => (
    state.isDisabled ? {
      ...provided,
      display: 'none',
    } : {
      ...provided,
      color: 'white',
      backgroundColor: styles.multivalueBackgroundColor,
      borderRadius: '50%',
      paddingLeft: '0',
      paddingRight: '0',
      marginRight: '1px',
    }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: state.data.color,
    color: 'white',
    minWidth: state.selectProps.optionBackgroundColor && '90%',
  }),
};

export default selectCustomStyles;
