/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/react';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import IconComponent from '../IconComponent/IconComponent';
import styles from './styles.module.scss';

const WebcamInput = ({ onSnapshot }) => {
  const width = 560; // We will scale the photo width to this
  const [video] = useState(React.createRef());

  const [snapshot, setSnapshot] = useState(null);
  const [stream, setStream] = useState(null);
  const [webcamLoading, setWebcamLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  // This will be computed based on the input stream
  const [height, setHeight] = useState(width / (4 / 3));

  const canvas = useRef(null);

  const savePhoto = useCallback(async () => {
    const res = await fetch(snapshot);
    const blob = await res.blob();
    const file = new File([blob], 'webcam.png', { type: 'image/png' });
    onSnapshot(file);
  }, [onSnapshot, snapshot]);

  const clearPhoto = useCallback(() => {
    setSnapshot(null);
  }, []);

  // Capture a photo by fetching the current contents of the video
  // and drawing it into a canvas, then converting that to a PNG
  // format data URL. By drawing it on an offscreen canvas and then
  // drawing that to the screen, we can change its size and/or apply
  // other changes before drawing it.

  const takePhoto = useCallback(() => {
    const context = canvas.current.getContext('2d');
    if (width && height) {
      canvas.current.width = width;
      canvas.current.height = height;
      context.drawImage(video.current, 0, 0, width, height);

      const data = canvas.current.toDataURL('image/png');
      setSnapshot(data);
    } else {
      clearPhoto();
    }
  }, [clearPhoto, height, video]);

  const canPlay = useCallback(() => {
    setHeight(video.current.videoHeight / (video.current.videoWidth / width));

    // Firefox currently has a bug where the height can't be read from
    // the video, so we will make assumptions if this happens.
    if (Number.isNaN(height)) {
      setHeight(width / (4 / 3));
    }
  }, [height, video]);

  useEffect(() => {
    let mediaStream;
    (async () => {
      try {
        const constraints = { video: true, audio: false };
        mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        setStream(mediaStream);
      } catch (e) {
        setErrorMessage('Kamera konnte nicht geladen werden');
        captureException(e);
      }
    })();
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (video.current && stream) {
      const videoPlayer = video.current;
      videoPlayer.srcObject = stream;
      videoPlayer.play();
      videoPlayer.addEventListener('canplay', canPlay, false);
      return () => {
        videoPlayer.srcObject = null;
        videoPlayer.pause();
        videoPlayer.removeEventListener('canplay', canPlay);
      };
    }
    return undefined;
  }, [canPlay, video, stream, snapshot]);
  if (errorMessage) {
    return <div style={{ width, height }} className={styles.error}>{errorMessage}</div>;
  }
  return (
    <div className={styles.webCamWrapper}>
      {webcamLoading && (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
          <div> Kamera startet…</div>
        </div>
      )}
      {!snapshot ? (
        <video
          height={height}
          width={width}
          ref={video}
          className={styles.video}
          onLoadedData={() => setWebcamLoading(false)}
        />
      ) : (
        <img src={snapshot} alt="The screen capture will appear in this box." />
      )}
      {!webcamLoading && (
        <div className={styles.buttonWrapper}>
          {!snapshot
            ? <button type="button" onClick={takePhoto}><IconComponent Icon={ICON.PHOTO} color={ICON_COLOR.WHITE} size={ICON_SIZE.LARGE} /></button>
            : (
              <>
                <button type="button" onClick={clearPhoto}><IconComponent Icon={ICON.ROTATE} color={ICON_COLOR.WHITE} size={ICON_SIZE.LARGE} /></button>
                <button type="button" onClick={savePhoto}><IconComponent Icon={ICON.SAVE} color={ICON_COLOR.WHITE} size={ICON_SIZE.LARGE} /></button>
              </>
            )
          }
        </div>
      )}
      <canvas style={{ display: 'none' }} ref={canvas} />
    </div>
  );
};

WebcamInput.propTypes = {
  onSnapshot: PropTypes.func.isRequired,
};

export default WebcamInput;
