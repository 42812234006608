import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLeftBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { LEFT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../IconComponent/IconComponent';
import styles from './styles.module.scss';

const NewChatButton = () => {
  const dispatch = useDispatch();

  const openNewChannel = useCallback(() => {
    dispatch(setLeftBarTab({ leftBarTab: LEFT_BAR_TABS.NEW_CHAT }));
  }, [dispatch]);

  return (
    <button
      className={styles.newChannelButton}
      onClick={openNewChannel}
      type="button"
      title="Neuer Chat"
    >
      <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.WHITE} />
    </button>
  );
};

export default NewChatButton;
