/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { byId } from '../../helper/byKey';
import fetchNewChannelOptions from './newChannelOptionsThunks';
import ASYNC_STATE from '../../constants/AsyncState';
import mergeObjects from '../../helper/mergeObjects';

const asyncState = {
  fetchNewChannelOptions: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  allChannelOptions: {},
  displayNewChannelOptionsIds: [],
  selectedNewChannelOptionIds: [],
  pagination: {},
  groupChat: false,
};

const newChannelsSlice = createSlice({
  name: 'newChannels',
  initialState,
  reducers: {
    selectNewChannelOptions: (state, action) => {
      const { newChannelOptionId } = action.payload;
      state.selectedNewChannelOptionIds = state.selectedNewChannelOptionIds.find(
        (id) => id === newChannelOptionId,
      )
        ? state.selectedNewChannelOptionIds.filter((id) => id !== newChannelOptionId)
        : [...state.selectedNewChannelOptionIds, newChannelOptionId];
    },
    resetSelectedNewChannelOptions: (state) => {
      state.selectedNewChannelOptionIds = [];
    },
    setGroupChat: (state, action) => {
      const { groupChat } = action.payload;
      state.groupChat = groupChat;
    },
    resetNewChannelOptions: (state) => {
      state.displayNewChannelOptionsIds = [];
    },
  },
  extraReducers: {
    [fetchNewChannelOptions.pending]: (state) => {
      state.fetchNewChannelOptions.status = ASYNC_STATE.LOADING;
      state.fetchNewChannelOptions.errorMessage = null;
    },
    [fetchNewChannelOptions.fulfilled]: (state, action) => {
      state.fetchNewChannelOptions.status = ASYNC_STATE.SUCCEEDED;
      const { data, pagination } = action.payload;
      const ids = data.map(el => el.id);
      state.pagination = pagination;
      if (pagination.current === 1) {
        state.displayNewChannelOptionsIds = ids;
      } else {
        state.displayNewChannelOptionsIds = [...new Set(
          [...state.displayNewChannelOptionsIds, ...ids],
        )];
      }
      // keep all channel options that were ever fetched in store
      state.allChannelOptions = mergeObjects(state.allChannelOptions, byId(data));
    },
    [fetchNewChannelOptions.rejected]: (state) => {
      state.fetchNewChannelOptions.status = ASYNC_STATE.FAILED;
      state.fetchNewChannelOptions.errorMessage = 'Fehler beim Suchen';
    },
  },
});

export const {
  selectNewChannelOptions,
  setGroupChat,
  resetSelectedNewChannelOptions,
  resetNewChannelOptions,
} = newChannelsSlice.actions;

export default newChannelsSlice.reducer;
