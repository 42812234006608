
import styles from './styles.module.scss';

const NotificationBadge = () => {
  return (
    <div className={styles.badge}>
      NEU
    </div>
  );
};

export default NotificationBadge;
