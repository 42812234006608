import { useState, useMemo, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  getSelectedHouseDetails,
} from 'gcs-common/slices/administration/houses/housesSelectors';
import { updateSelectedHouse } from 'gcs-common/slices/administration/houses/housesThunks';
import styles from './styles.module.scss';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import EditIcon from '../../../../img/edit.svg?react';
import CheckIcon from '../../../../img/check_green.svg?react';
import CancelIcon from '../../../../img/close_red.svg?react';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const readReceiptAllowanceOptions = [
  { value: 'AGENTS_ONLY', label: 'Nur Mitarbeiter sehen Lesebestätigungen' },
  { value: 'ALL', label: 'Mitarbeiter und Kunden sehen Lesebestätigungen' },
  { value: 'NONE', label: 'Niemand sieht Lesebestätigungen' },
];

const validationSchema = Yup.object().shape({
  readReceiptAllowance: Yup.string().nullable().required('Erforderlich'),
});

const HouseReadConfirmation = () => {

  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const selectedHouse = useSelector(getSelectedHouseDetails);

  const startEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const { readReceiptAllowance } = selectedHouse || {};

  const initialValues = useMemo(() => {
    return ({
      readReceiptAllowance: readReceiptAllowance || '',
    });
  }, [readReceiptAllowance]);

  const cancelEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(false);
  }, []);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(updateSelectedHouse({ houseId: selectedHouse.id, values }));
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich geändert'));
      setIsEditing(false);
    }
    setSubmitting(false);
  }, [selectedHouse, dispatch]);

  return (
    <div className={styles.row}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submit}
        validateOnChange
      >
        {({
          isSubmitting, resetForm,
        }) => (
          <Form noValidate>
            <div className={styles.bottomHeader}>
              <div className={styles.buttonsWrapper}>
                {isEditing ? (
                  <>
                    <button
                      className={styles.saveButton}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <CheckIcon />
                    </button>
                    <button
                      className={styles.saveButton}
                      type="button"
                      onClick={(event) => {
                        resetForm();
                        cancelEditing(event);
                      }}
                      disabled={isSubmitting}
                    >
                      <CancelIcon />
                    </button>
                  </>
                ) : (
                  <button
                    className={styles.saveButton}
                    type="button"
                    onClick={startEditing}
                  >
                    <EditIcon />
                  </button>
                )}
              </div>
            </div>
            <FormikSelect
              label="Lesebestätigungen in Chats mit Kunden"
              name="readReceiptAllowance"
              placeholder="Bitte wählen"
              options={readReceiptAllowanceOptions}
              disabled={!isEditing}
              labelKey="label"
              valueKey="value"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default HouseReadConfirmation;
