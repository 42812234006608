import { useState } from 'react';
import {
  ICON,
  ICON_SIZE,
  ICON_COLOR,
} from 'gcs-common/constants/IconConstants';
import IconComponent from '../../../IconComponent/IconComponent';
import styles from './styles.module.scss';

interface TicketRatingModalProps {
  comment: string;
  rating: number;
  onCancel: VoidFunction;
  handleRating: (ratingValue: number, commentValue: string) => void;
}

const TicketRatingModal = (props: TicketRatingModalProps) => {
  const { comment, rating, onCancel, handleRating } = props;
  const [ratingValue, setRating] = useState(rating || 0);
  const [commentValue, setComment] = useState(comment || '');
  const rates = [
    {
      key: 0, value: 1,
    },
    {
      key: 1, value: 2,
    },
    {
      key: 2, value: 3,
    },
    {
      key: 3, value: 4,
    },
    {
      key: 4, value: 5,
    },
  ];
  return (
    <div className={styles.ratingModalContent}>
      {rates.map(rate => (
        <button type="button" key={`${rate.key}-rating`} onClick={() => setRating(rate.value)}>
          <IconComponent
            Icon={rate.value <= ratingValue ? ICON.STAR_FILLED : ICON.STAR}
            color={ICON_COLOR.YELLOW}
            size={ICON_SIZE.XX_LARGE}
          />
        </button>
      ))}
      <div className={styles.formElement}>
        <div className={styles.formElementTitle}>
          Bewertung
        </div>
        <textarea
          className={styles.comment}
          onChange={(e) => setComment(e.target.value)}
          value={commentValue}
        >
          {commentValue}
        </textarea>
      </div>
      <div className={styles.buttonWrapper}>
        <button type="button" onClick={onCancel} className={styles.discardTicket}>Abbrechen</button>
        <button
          type="button"
          onClick={() => handleRating(ratingValue, commentValue)}
          className={styles.createTicket}
        >
          Senden
        </button>
      </div>
    </div>
  );
};

export default TicketRatingModal;
