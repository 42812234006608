/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */

import { getEntitiesForKeys } from './objectHelpers';

const initialPaginationResult = { total: 0, size: 0, pages: 0 };

export const paginationCurrentIds = (paginationState) => {
  return paginationState.currentIds || [];
};

export const paginationIds = (paginationState) => {
  return paginationState.ids || [];
};

// Note: Current == last loaded
export const currentPaginationEntities = (paginationState, entities) => {
  return getEntitiesForKeys(entities, paginationCurrentIds(paginationState));
};

// Note: all users load for this paginationKey
export const toPaginationEntities = (paginationState, entities) => {
  return getEntitiesForKeys(entities, paginationIds(paginationState));
};

export const toPaginationPaginationResult = (paginationState) => {
  return paginationState.paginationResult || initialPaginationResult;
};

const initialPaginationState = {
  ids: [],
  currentIds: [],
  paginationResult: initialPaginationResult,
};

const defaultIdExtractor = entity => {
  if (!entity.id) {
    throw new Error('Could not retrieve id by id field from entity! '
      + 'Please provide mapEntityToId function to paginateReducer for entity', entity);
  }
  return entity.id;
};

/** Enhances a Reducer to set a pagination result,
 * given a function telling how to extract the pagination-substate from the state
 * and a callback to do the actual logic.
 * Additionally a function should be provided how to extract the id from an entity
 * if the id-field is !== id

 * Inspired by official redux real-world example:
 * https://github.com/reduxjs/redux/blob/master/examples/real-world/src/reducers/paginate.js
 * */
const paginateReducer = (
  mapStateToPaginationSubState,
  reducer,
  mapEntityToId = defaultIdExtractor,
) => {

  if (typeof mapStateToPaginationSubState !== 'function') {
    throw new Error('Expected mapStateToPaginationSubState to be a function.');
  }

  if (typeof mapEntityToId !== 'function') {
    throw new Error('Expected mapEntityToId to be a function.');
  }

  if (typeof reducer !== 'function') {
    throw new Error('Expected reducer to be a function.');
  }

  const paginate = (
    state = initialPaginationState,
    action,
  ) => {
    const { data, pagination } = action.payload;
    const entityIds = data.map(mapEntityToId);
    if (!state.ids) {
      state.ids = [];
    }
    state.ids.push(...entityIds);
    state.currentIds = entityIds;
    state.paginationResult = pagination;
  };

  return (state, action) => {
    const paginationSubState = mapStateToPaginationSubState(state, action);
    paginate(paginationSubState, action);
    reducer(state, action);
  };
};
export default paginateReducer;
