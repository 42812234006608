/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-cycle */
import { createSelector } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { CHANNEL_BRANDS, CHANNEL_TYPE, ChannelTypeInfo } from '../../helper/channelTypeHelper';
import {
  getChannelColorMaps,
  getChannels,
  getSelectedChannelId,
} from '../channels/channelsSelectors';
import { getCurrentUserId, getCurrentUser, getIsAgent } from '../currentUser/currentUserSelectors';
import { READ_RECEIPT_ALLOWANCE_TYPES } from '../../constants/ReadReceiptAllowanceTypes';
import {
  getChannelFirstMessageLoaded, getMessagesForChannelReversed,
} from '../messages/messagesSelectors';
import {
  getMemberIdsForChannel,
  getMembers,
  getMembersForChannel,
  getChannelMembers,
} from '../members/membersSelectors';
import { calculateUserStatus, ONLINE_STATUS, ONLINE_STATUS_PRIORITY } from '../../helper/userStatusHelper';
import {
  calculateChannelCorrespondent,
  calculateChannelMemberCount,
  calculateChannelMemberUserIds,
  calculateChannelSubtitle,
  calculateChannelType,
  calculateRelevantChannelUserId,
  calculateChannelCraftsmanUserIds,
  calculateChannelMembersSortedByFriendlyName,
  calculateChannelPrimaryHouseId,
  calculateChannelTitle,
  calculateDirectChannelUserId,
  calculateChannelSalesSpaceName,
} from '../../helper/channelHelpers';
import USER_TYPE from '../../helper/userTypeHelper';
import { getIsChatConnected } from '../chatConnection/chatConnectionSelectors';
import { getUsers } from '../users/usersSelectors';
import { isClientMessageId } from '../../helper/reduxOfflineHelper';
import { DEFAULT_LAST_CONSUMED_MESSAGE_INDEX } from '../messages/messageConstants';

export const getIsHidden = (channelId) => createSelector(
  getCurrentUser,
  (currentUser) => {
    return currentUser?.hiddenChannels?.includes(channelId);
  },
);

export const getChannel = (channelId) => createSelector(
  getChannels,
  (channels) => channels[channelId],
);

export const getChannelIdByExternalId = (externalId) => createSelector(
  getChannels,
  (channels) => {
    const channelsArray = Object.values(channels);
    const channel = channelsArray.find(c => c.externalId === externalId);
    return channel?.id;
  },
);

export const getPrimaryHouseForChannel = (channelId) => createSelector(
  getChannel(channelId),
  (channel) => channel && calculateChannelPrimaryHouseId(channel),
);

export const getChannelType = (channelId) => createSelector(
  getChannel(channelId),
  (channel) => calculateChannelType(channel),
);

export const getIsSalesSpaceChannelType = (channelId) => createSelector(
  getChannelType(channelId),
  (channelType) => channelType === CHANNEL_TYPE.SALES_SPACE,
);

export const getIsGroupChannel = (channelId) => createSelector(
  // eslint-disable-next-line no-use-before-define
  getChannelMemberCount(channelId),
  channelMemberCount => channelMemberCount > 2,
);

export const getMemberUserIdsForChannel = (channelId) => createSelector(
  getMemberIdsForChannel(channelId),
  getMembers,
  (channelMemberIds, members) => {
    return calculateChannelMemberUserIds(channelMemberIds, members) || [];
  },
);

export const getMemberAgentIdsForChannel = (channelId) => createSelector(
  getMemberUserIdsForChannel(channelId),
  getUsers,
  (channelMemberIds = [], users) => {
    return channelMemberIds
      .filter(id => users[id]?.userType === USER_TYPE.AGENT);
  },
);

export const getMemberCraftsmenIdsForChannel = (channelId) => createSelector(
  getMemberUserIdsForChannel(channelId),
  getUsers,
  (channelMemberIds = [], users) => {
    return channelMemberIds
      .filter(id => users[id]?.userType === USER_TYPE.CRAFTSMAN);
  },
);

export const getMemberCraftsmenCountForChannel = (channelId) => createSelector(
  getMemberCraftsmenIdsForChannel(channelId),
  (craftsmenIds) => {
    return craftsmenIds.length;
  },
);

export const getChannelIsInternal = channelId => createSelector(
  getChannelType(channelId),
  (channelType) => {
    return (channelType === CHANNEL_TYPE.INTERNAL_AGENT
      || channelType === CHANNEL_TYPE.INTERNAL_CRAFTSMAN);
  },
);

export const getChannelIsCraftnote = channelId => createSelector(
  getChannel(channelId),
  (channel) => {
    return channel?.connectedToCraftnote;
  },
);

export const getChannelBrand = (channelId) => createSelector(
  getChannelType(channelId),
  getChannelIsCraftnote(channelId),
  getMemberUserIdsForChannel(channelId),
  getUsers,
  getCurrentUserId,
  (channelType, isCraftnote, channelMemberIds, users, currentUserId) => {
    if (isCraftnote) return CHANNEL_BRANDS.CRAFTNOTE;
    if (channelType === CHANNEL_TYPE.INTERNAL_AGENT) return null;
    if (!channelMemberIds) return null;
    // eslint-disable-next-line no-restricted-syntax
    for (const id of channelMemberIds) {
      const businessUnit = users[id]?.businessUnitName;
      if (id !== currentUserId && businessUnit) {
        return businessUnit;
      }
    }
    return null;
  },
);

export const getIsSelectedChannel = (channelId) => createSelector(
  getSelectedChannelId,
  (selectedChannelId) => selectedChannelId === channelId,
);

export const getOtherMemberTyping = (channelId) => createSelector(
  getMembersForChannel(channelId),
  getCurrentUserId,
  (members, currentUserId) => {
    return members && members.find(member => (
      member
      && member.userId !== currentUserId
      && member.isTyping
    ));
  },
);

export const getChannelCorrespondent = (channelId) => createSelector(
  getMemberUserIdsForChannel(channelId),
  getUsers,
  getCurrentUserId,
  (channelMemberUserIds, users, currentUserId) => {
    return calculateChannelCorrespondent({
      channelMemberUserIds,
      users,
      currentUserId,
    });
  },
);

export const getChannelMemberCount = (channelId) => createSelector(
  getMemberUserIdsForChannel(channelId),
  channelMemberIds => calculateChannelMemberCount(channelMemberIds),
);

export const getChannelImage = (channelId) => createSelector(
  getChannelType(channelId),
  getUsers,
  getMemberUserIdsForChannel(channelId),
  getIsAgent,
  getCurrentUserId,
  getChannel(channelId),
  (
    channelType,
    users,
    channelMemberUserIds = [],
    isAgent,
    currentUserId,
    channel,
  ) => {

    if (channelType === CHANNEL_TYPE.SALES_SPACE) {
      if (isAgent) {
        const craftsmanId = calculateChannelCraftsmanUserIds({ channelMemberUserIds, users });
        return users[craftsmanId]?.imageUrl;
      }

      if (channel.mainContactId) {
        return users[channel.mainContactId]?.imageUrl;
      }

      return channel?.imageUrl;

    }

    const memberCount = channelMemberUserIds?.length;

    if (memberCount === 2) {
      const channelCorrespondent = calculateChannelCorrespondent({
        channelMemberUserIds,
        users,
        currentUserId,
      });

      return channelCorrespondent?.imageUrl;
    }

    const sortedUsers = calculateChannelMembersSortedByFriendlyName({
      channelMemberUserIds,
      users,
      currentUserId,
    });

    const candidateUser = sortedUsers.find(user => {
      return user?.imageUrl;
    });

    return candidateUser?.imageUrl;

  },
);

export const getDirectChannelUserId = (channelId) => createSelector(
  getChannelMembers,
  getUsers,
  getCurrentUserId,
  getMembers,
  (channelMembers, users, currentUserId, members) => {
    const channelMemberUserIds = calculateChannelMemberUserIds(channelMembers[channelId], members);
    return calculateDirectChannelUserId({
      channelMemberUserIds,
      users,
      currentUserId,
    });
  },
);

export const getSalesSpaceLogo = (channelId) => createSelector(
  getChannel(channelId),
  (channel) => {
    return channel?.imageUrl;
  },
);

export const getChannelSalesSpaceName = (channelId) => createSelector(
  getChannel(channelId),
  (channel) => {
    return calculateChannelSalesSpaceName(channel);
  },
);

export const getChannelTitle = (channelId) => createSelector(
  getChannel(channelId),
  getUsers,
  getChannelMembers,
  getMembers,
  getCurrentUserId,
  getIsAgent,
  (channel, users, channelMembers, members, currentUserId, isAgent) => {
    return calculateChannelTitle({
      channelId, channel, users, channelMembers, members, currentUserId, isAgent,
    });
  },
);

export const getChannelSubtitle = (channelId) => createSelector(
  getChannel(channelId),
  getChannelCorrespondent(channelId),
  getChannelMembers,
  getMembers,
  getUsers,
  getIsAgent,
  (channel, channelCorrespondent, channelMembers, members, users, isAgent) => {
    return calculateChannelSubtitle({
      channelId, channel, channelCorrespondent, channelMembers, members, users, isAgent,
    });
  },
);

export const getChannelCompanyName = ({ userId, channelId }) => createSelector(
  getChannel(channelId),
  getUsers,
  (channel, users) => {
    const primaryHouseId = calculateChannelPrimaryHouseId(channel);
    const user = users[userId];
    const channelConnection = user?.connections?.find(connection => {
      return connection.houseId === primaryHouseId;
    });
    return channelConnection?.friendlyName;
  },
);

export const getChannelCustomerNumber = ({ userId, channelId }) => createSelector(
  getChannel(channelId),
  getUsers,
  (channel, users) => {
    const primaryHouseId = calculateChannelPrimaryHouseId(channel);
    const user = users[userId];
    const channelConnection = user?.connections?.find(connection => {
      return connection.houseId === primaryHouseId;
    });
    return channelConnection?.customerNumber;
  },
);

export const getSalesSpaceMostAvailableAgent = (channelId) => createSelector(
  getUsers,
  getMemberUserIdsForChannel(channelId),
  getCurrentUserId,
  (users, channelMemberIds = [], currentUserId) => {

    const sortedIds = channelMemberIds
      .filter(id => users[id]?.type === USER_TYPE.AGENT)
      .filter(id => id !== currentUserId)
      .sort((id1, id2) => {
        const status1 = users[id1]?.status?.type;
        const status2 = users[id2]?.status?.type;
        return ONLINE_STATUS_PRIORITY[status1] - ONLINE_STATUS_PRIORITY[status2];
      });

    return users[sortedIds[0]];
  },
);

export const getMainContactId = (channelId) => createSelector(
  getChannel(channelId),
  (channel) => channel?.mainContactId,
);

export const getChannelStatus = (channelId) => createSelector(
  getChannelType(channelId),
  getUsers,
  getIsChatConnected,
  getMemberUserIdsForChannel(channelId),
  getIsAgent,
  getCurrentUserId,
  (channelType, users, isConnected, channelMemberIds = [], isAgent, currentUserId) => {

    const relevantUserId = calculateRelevantChannelUserId({
      channelMemberIds, isAgent, users, currentUserId, channelType,
    });
    const relevantUser = relevantUserId && users[relevantUserId];
    return calculateUserStatus(isConnected, relevantUser);
  },
);

export const getChannelAgentsLastConsumedMessageIndex = (channelId) => createSelector(
  getChannel(channelId),
  (channel) => {
    return (
      channel?.agentsLastConsumedMessageIndex
      ?? DEFAULT_LAST_CONSUMED_MESSAGE_INDEX
    );
  },
);

export const getOtherReadStatusForChannel = (channelId) => createSelector(
  getMembersForChannel(channelId),
  getCurrentUserId,
  (channelMembers, currentUserId) => {
    return channelMembers?.reduce((red, member) => {
      if (!member) {
        return red;
      }
      const { lastConsumedMessageIndex, userId } = member;
      if (userId === currentUserId) {
        return red;
      }
      return { ...red, [userId]: lastConsumedMessageIndex };
    }, {});
  },
);

export const getChannelOtherLatestReadMessageIndex = (channelId) => createSelector(
  getOtherReadStatusForChannel(channelId),
  getIsSalesSpaceChannelType(channelId),
  getChannelAgentsLastConsumedMessageIndex(channelId),
  (readStatus, isSalesSpace, agentsLastConsumedMessageIndex) => {
    // If no message in a channel was ever read by a user readStatus value is null
    // Math.min([..., null]) returns 0, falsely marking first message as read
    // -> transform null to -1 to preserve logic for first message in channel
    const hasReadIndex = readStatus && Object.values(readStatus).length > 0;

    if (!hasReadIndex && isSalesSpace) {
      return agentsLastConsumedMessageIndex ?? DEFAULT_LAST_CONSUMED_MESSAGE_INDEX;
    }
    if (!hasReadIndex) {
      return DEFAULT_LAST_CONSUMED_MESSAGE_INDEX;
    }

    const lastReadIndices = Object.values(readStatus).map(lastReadIndex => {
      return (
        Number.isInteger(lastReadIndex)
          ? lastReadIndex
          : DEFAULT_LAST_CONSUMED_MESSAGE_INDEX
      );
    });

    return Math.min(...lastReadIndices);
  },
);

export const getChannelHasMoreMessages = channelId => createSelector(
  getChannelFirstMessageLoaded,
  (channelFirstMessageLoaded) => {
    return !channelFirstMessageLoaded[channelId];
  },
);

export const getChannelColorMap = (channelId) => createSelector(
  getChannelColorMaps,
  (channelColorMaps) => {
    return channelColorMaps && channelColorMaps[channelId];
  },
);

export const getChannelShouldShowReadReceipt = (channelId) => createSelector(
  getCurrentUser,
  getChannelType(channelId),
  getChannels,
  (currentUser, channelType, channels) => {
    if (!currentUser) {
      return false;
    }

    if (channelType === CHANNEL_TYPE.INTERNAL_AGENT
      || channelType === CHANNEL_TYPE.INTERNAL_CRAFTSMAN) {
      return true;
    }
    const readReceiptAllowance = channels[channelId]?.readReceiptAllowance;
    switch (readReceiptAllowance) {
      case READ_RECEIPT_ALLOWANCE_TYPES.ALL:
        return true;
      case READ_RECEIPT_ALLOWANCE_TYPES.AGENTS_ONLY:
        return currentUser.isAgent;
      case READ_RECEIPT_ALLOWANCE_TYPES.NONE:
      default:
        return false;
    }
  },
);

export const getChannelLastDeliveredMessageIdFromMe = (channelId) => createSelector(
  getMessagesForChannelReversed(channelId),
  getCurrentUserId,
  (reversedMessages, currentUserId) => {
    if (!reversedMessages) {
      return null;
    }
    return reversedMessages.find(message => (
      message.authorId === currentUserId
      && !isClientMessageId(message.id)
      && !message.autogenerated
    ))?.id;
  },
);

export const getChannelLastMessageIdReadAndFromMe = (channelId) => createSelector(
  getChannelOtherLatestReadMessageIndex(channelId),
  getMessagesForChannelReversed(channelId),
  getCurrentUserId,
  (latestReadMessageIndex, reversedMessages, currentUserId) => {
    if (!reversedMessages) {
      return null;
    }

    return reversedMessages.find(message => (
      message.index <= latestReadMessageIndex
      && message.authorId === currentUserId
      && !message.autogenerated
    ))?.id;
  },
);

export const getAreAllOtherUsersOfflineForChannel = (channelId) => createSelector(
  getMemberUserIdsForChannel(channelId),
  getCurrentUserId,
  getUsers,
  (memberIds, currentUserId, users) => {
    // craftsman don't store status in attributes, so we ignore them
    // and only make sure all agents are offline
    const hasOtherNonOfflineUsers = memberIds && memberIds.some(id => {
      if (id === currentUserId) {
        return false;
      }
      const user = users[id];
      const type = user?.status?.type;
      return type && type !== ONLINE_STATUS.OFFLINE;
    });
    return !hasOtherNonOfflineUsers;
  },
);

export const getChannelOPUserId = (channelId) => createSelector(
  getMemberUserIdsForChannel(channelId),
  getUsers,
  getCurrentUserId,
  getIsAgent,
  (memberUserIds, users, currentUserId, isAgent) => {
    // NOTE: For agents the current "other" channel-user
    // is the user with the OP-Account, for craftsmen it
    // will always resolve as the current user
    if (!isAgent) {
      return currentUserId;
    }
    return memberUserIds
      && memberUserIds.find(
        memberUserId => users[memberUserId]?.userType === USER_TYPE.CRAFTSMAN,
      );
  },
);

export const getChannelDetailChannelTitle = (channelId) => createSelector(
  getChannelType(channelId),
  getIsAgent,
  (channelType, isAgent) => {
    if (isAgent) {
      return ChannelTypeInfo[channelType] && ChannelTypeInfo[channelType].name;
    }
    if (channelType === CHANNEL_TYPE.SALES_SPACE) {
      return 'Teamchat';
    }
    return 'Direkt';
  },
);

export const getChannelDescription = (channelId) => createSelector(
  getChannelType(channelId),
  getChannelCorrespondent(channelId),
  (
    channelType,
    channelCorrespondent,
  ) => {
    if (channelType === CHANNEL_TYPE.SALES_SPACE) {
      return null;
    }
    return channelCorrespondent?.customerCompany;
  },
);

export const getChannelCraftsmanConnections = (channelId) => createSelector(
  getChannel(channelId),
  getUsers,
  getChannelMembers,
  getMembers,
  (channel, users, channelMembers, members) => {
    const channelMemberUserIds = calculateChannelMemberUserIds(channelMembers[channelId], members);
    const craftsmanIds = calculateChannelCraftsmanUserIds({ channelMemberUserIds, users });
    const craftsmanUsers = Object.values(pick(users, craftsmanIds));

    const primaryHouseId = calculateChannelPrimaryHouseId(channel);
    const channelConnections = craftsmanUsers.map(x => x.connections?.find(connection => {
      return connection.houseId === primaryHouseId;
    }));

    return channelConnections;
  },
);

export const getNumOpenTasksForChannel = (channelId) => createSelector(
  getChannels,
  (channels) => {
    return channels[channelId]?.tasksCount || 0;
  },
);
