import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  getChannelIsFreeSalesSpace,
  getSalesSpaceChannelProcessing,
  getCurrentUserIsMemberOfChannel,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import {
  getChannelImage,
  getChannelStatus,
  getChannelSubtitle,
  getChannelTitle,
  getIsHidden,
  getMainContactId,
  getMemberAgentIdsForChannel,
  getNumOpenTasksForChannel,
  getSalesSpaceMostAvailableAgent,
} from 'gcs-common/slices/channel/channelSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import changeSelectedChannelId from 'gcs-common/slices/channels/channelsThunks/changeSelectedChannelId';
import { getUnreadMessageCountForChannel } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import joinSalesSpace from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsThunks/joinSalesSpace';
import leaveSalesSpace from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsThunks/leaveSalesSpace';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getTicketNotificationsCountForChannel } from 'gcs-common/slices/tickets/ticketsSelectors';
import { UNREAD_COUNT_DISPLAY_THRESHOLD } from 'gcs-common/constants/channelConstants';
import Highlight from '../Highlight/Highlight';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import styles from './styles.module.scss';

const SalesSpaceChannelListItem = ({ channelId }) => {

  const dispatch = useDispatch();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const channelImage = useSelector(getChannelImage(channelId));
  const channelStatus = useSelector(getChannelStatus(channelId));
  const channelTitle = useSelector(getChannelTitle(channelId));
  const channelSubtitle = useSelector(getChannelSubtitle(channelId));
  const searchInput = useSelector(getSearchInput);
  const isProcessing = useSelector(getSalesSpaceChannelProcessing(channelId));
  const isFree = useSelector(getChannelIsFreeSalesSpace(channelId));
  const isMember = useSelector(getCurrentUserIsMemberOfChannel(channelId));
  const agentIds = useSelector(getMemberAgentIdsForChannel(channelId));
  const mostAvailableAgent = useSelector(getSalesSpaceMostAvailableAgent(channelId));
  const mainContactPerson = useSelector(getMainContactId(channelId));
  const currentUserId = useSelector(getCurrentUserId);
  const isHidden = useSelector(getIsHidden(channelId));
  const numOpenTasks = useSelector(getNumOpenTasksForChannel(channelId));
  const ticketNotificationsCount = useSelector(getTicketNotificationsCountForChannel(channelId));
  const tasksAndTickets = numOpenTasks + ticketNotificationsCount;

  const isMainContactPerson = mainContactPerson === currentUserId;
  const otherAgentsCount = agentIds.length - (isMember ? 1 : 0);
  const isSelected = channelId === selectedChannelId;

  const numUnreadMessages = useSelector(getUnreadMessageCountForChannel(channelId));

  const onChannelSelected = () => {
    dispatch(changeSelectedChannelId({ selectedChannelId: channelId }));
  };

  const channelStyle = useMemo(() => {
    if (isSelected) {
      return styles.selectedSalesSpaceChannel;
    }
    return styles.unselectedSalesSpaceChannel;
  }, [isSelected]);

  const onJoinSalesSpace = () => {
    dispatch(joinSalesSpace({ channelId, currentUserId }));
  };

  const onLeaveSalesSpace = () => {
    dispatch(leaveSalesSpace({ channelId }));
  };

  const renderedUnread = numUnreadMessages > UNREAD_COUNT_DISPLAY_THRESHOLD ? `${numUnreadMessages}+` : numUnreadMessages;

  return (
    <button
      type="button"
      onClick={onChannelSelected}
      className={channelStyle}
    >
      <div
        className={`${styles.channelFreeIndicator} ${isFree && styles.channelFreeIndicatorActive}`}
      />
      <div className={styles.channelInfo}>
        <div className={styles.userImage}>
          <ImageWithStatus
            isSalesSpace
            isAgent
            className={styles.imageWrapper}
            imageUrl={channelImage}
            userStatus={channelStatus}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.customerTitle}>
            <Highlight search={searchInput}>{channelTitle}</Highlight>
          </div>
          <div className={styles.customerCompany}>
            <Highlight search={searchInput}>{channelSubtitle}</Highlight>
          </div>
          {isHidden && (
            <div className={styles.archivedIndicator}>
              Archiviert
            </div>
          )}
        </div>
        <div className={styles.unreadMsgContainer}>

          {(numUnreadMessages > 0 || tasksAndTickets > 0)
            && (
              <div className={styles.flexContainerRight}>

                {numUnreadMessages > 0 && (
                <div className={styles.unreadMsgIndicator}>
                  {renderedUnread}
                </div>
                )}

                {tasksAndTickets > 0 && (
                <div className={styles.todoIndicator}>
                  {tasksAndTickets}
                </div>
                )}
              </div>
            )
          }

          <div className={styles.imageStatusAndButtonContainer}>

            {otherAgentsCount > 0
           && (
             <>
               <div>
                 <ImageWithStatus
                   isSmall
                   isDot
                   imageUrl={mostAvailableAgent?.imageUrl}
                   userStatus={mostAvailableAgent?.status}
                 />
               </div>

               { otherAgentsCount > 1
               && (
               <div className={`${styles.agentCountIndicator} ${isSelected ? styles.white : ''}`}>
                 +
                 {otherAgentsCount - 1}
               </div>
               )
               }
             </>
           )
           }

            {
              !isMainContactPerson
              && (
              <div className={styles.buttonContainer}>
                {
                isMember
                  ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      role="button"
                      tabIndex="0"
                      onClick={onLeaveSalesSpace}
                      className={`${styles.leaveButton} ${isProcessing && styles.processing}`}
                    >
                      {/* Div here instead of button because it's nested in a button */}
                      Verlassen
                    </div>
                  )
                  : (
                    <div
                      role="button"
                      tabIndex="0"
                      onClick={onJoinSalesSpace}
                      className={`${styles.joinButton} ${isProcessing && styles.processing}`}
                    >
                      {/* Div here instead of button because it's nested in a button */}
                      Beitreten
                    </div>
                  )
                }
              </div>
              )
            }

          </div>

        </div>
      </div>

    </button>
  );
};

SalesSpaceChannelListItem.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default SalesSpaceChannelListItem;
