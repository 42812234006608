
import { ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Icon from '../../Icon/Icon';
import styles from './styles.module.scss';


const Radio = ({
  id, icon, text, value, name, disabled = false,
}) => {
  return (
    <div className={styles.container}>
      <Field
        type="radio"
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        className={styles.formikRadioOption}
      />

      <div className={styles.direction}>
        <Icon Icon={icon} color={ICON_COLOR.BLACK} size={ICON_SIZE.X_SMALL} alt="arrow" />
      </div>
      <div className={`${styles.radioOptionLabel} ${disabled && styles.disabled}`}>
        {text}
      </div>
    </div>
  );
};

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Radio;
