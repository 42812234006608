import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { Dispatch, SetStateAction } from 'react';
import styles from './styles.module.scss';
// eslint-disable-next-line import/no-cycle
import StatusFilter from './StatusFilter/StatusFilter';
import TicketSearchBar from '../../TicketSearchBar/TicketSearchBar';
import IconComponent from '../../../IconComponent/IconComponent';
import DateFilter from './DateFilter/DateFilter';
import ErpCustomerFilter from './ErpCustomerFilter/ErpCustomerFilter';
import CreatorFilter from './CreatorFilter/CreatorFilter';
// eslint-disable-next-line import/no-cycle
import { FiltersValues } from '../TicketDashboard';

interface TicketDashboardFiltersProps {
  setShowForm: Dispatch<SetStateAction<boolean>>;
  setFilters: Dispatch<SetStateAction<FiltersValues>>
  filters: FiltersValues;
}

const TicketDashboardFilters = (props: TicketDashboardFiltersProps) => {
  const { setShowForm, setFilters, filters } = props;
  const onSearchText = (value: FiltersValues['search']) => {
    setFilters({
      ...filters,
      search: value,
    });
  };
  const onFilterChange = (filter: Partial<FiltersValues>) => {
    setFilters({
      ...filters,
      ...filter,
    });
  };

  return (
    <div className={styles.filters}>
      <div className={styles.statusFilters}>
        <div className={styles.filterWrapper}>
          <StatusFilter setStatusFilter={onFilterChange} />
        </div>
        <div className={styles.header}>
          <TicketSearchBar searchInput={filters.search} setSearchInput={onSearchText} />
          <button type="button" className={styles.plus} onClick={() => setShowForm(true)}>
            <IconComponent
              Icon={ICON.PLUS}
              color={ICON_COLOR.WHITE}
              size={ICON_SIZE.SMALL}
            />
          </button>
        </div>
      </div>
      <div className={styles.inputFilters}>
        <DateFilter setDateFilter={onFilterChange} />
        <ErpCustomerFilter setErpCustomerFilter={onFilterChange} />
        <CreatorFilter setCreatorFilters={onFilterChange} creatorId={filters.creatorId} />
      </div>
    </div>
  );
};


export default TicketDashboardFilters;
