/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import ApiProvider from 'gcs-common/provider/ApiProvider';

// @ts-expect-error we don't have types for redux store yet
import store from '../../store';
import router from '../../router/router';
import { createWebPersister } from '../../offline-settings/react-query-persister';

// @ts-expect-error we don't have types for redux store yet
const beemStore = store;

const AppRoot = () => {
  const persister = createWebPersister();
  return (
    <>
      <Provider store={beemStore}>
        <ApiProvider persister={persister}>
          <RouterProvider router={router} />
        </ApiProvider>
      </Provider>
    </>
  );
};

export default AppRoot;
