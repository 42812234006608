import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRightBarTab } from 'gcs-common/slices/uiState/uiStateSelectors';
import { setRightBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import TermsAndPrivacy from '../TermsAndPrivacy/TermsAndPrivacy';
import Tools from '../Tools/Tools';
import AbsenceManager from '../AbsenceManager/AbsenceManager';
import ChannelProfile from '../ChannelProfile/ChannelProfile';
import UserProfile from '../UserProfile/UserProfile';

const RightBar = () => {
  const rightBarTab = useSelector(getRightBarTab);
  const dispatch = useDispatch();

  const closeDataPrivacy = useCallback(() => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.TOOLS }));
  }, [dispatch]);

  return useMemo(() => {
    switch (rightBarTab) {
      case RIGHT_BAR_TABS.MEMBER_DETAILS:
        return <UserProfile />;

      case RIGHT_BAR_TABS.CHANNEL_DETAILS:
        return <ChannelProfile />;

      case RIGHT_BAR_TABS.ABSENCE:
        return <AbsenceManager />;
      case RIGHT_BAR_TABS.TERMS:
        return (
          <TermsAndPrivacy
            onRightBar
            withTerms
            withPrivacy
            closeTermsAndPrivacy={closeDataPrivacy}
          />
        );
      case RIGHT_BAR_TABS.TOOLS:
      default:
        return <Tools />;
    }
  }, [closeDataPrivacy, rightBarTab]);
};

export default RightBar;
