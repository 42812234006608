export const PROCESS_DATA_SOURCE_COMSERV = 'ComServ';
export const PROCESS_DATA_SOURCE_ONLINE_PLUS = 'GCOnlinePlus';

export const PROCESS_DATA_SOURCE = [
  {
    key: PROCESS_DATA_SOURCE_COMSERV,
    value: 'ComServ',
  },
  {
    key: PROCESS_DATA_SOURCE_ONLINE_PLUS,
    value: 'GC ONLINE PLUS',
  },
];
