/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchSorter } from 'match-sorter';
import { fetchTickets } from 'gcs-common/slices/tickets/ticketsThunks';
import {
  getTickets,
  getTicketsLoading,
  getTicketNotificationsForChannel,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { createNewTicketSelected } from 'gcs-common/slices/tickets/ticketsSlice';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import orderBy from 'lodash/orderBy';
import { friendlyDate } from 'gcs-common/helper/dateHelper';
import { TICKET_STATI } from 'gcs-common/slices/tickets/ticketsConstants';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';
import TicketCard from './TicketCard/TicketCard';

import TicketSearchBar from '../TicketSearchBar/TicketSearchBar';

// TODO improve?
const searchKeys = ['title', (item: { createdAt: string, status: string; }) => friendlyDate(item.createdAt), (item: { createdAt: string, status: string; }) => TICKET_STATI[item.status]?.title || ''];

const TicketsOverview = () => {
  const dispatch = useDispatch();
  const channelId = useSelector(getSelectedChannelId);
  const ticketsLoading = useSelector(getTicketsLoading(channelId));
  const unfilteredTickets = useSelector(getTickets(channelId));
  const ticketNotifications = useSelector(getTicketNotificationsForChannel(channelId));
  const [searchInput, setSearchInput] = useState('');

  const fetchData = useCallback(() => {
    // @ts-expect-error redux
    dispatch(fetchTickets({ channelId }));
  }, [channelId, dispatch]);
  useEffect(() => {
    fetchData();
  }, [fetchData, ticketNotifications.length]);

  const filteredTickets = useMemo(() => {
    if (!unfilteredTickets) {
      return [];
    }

    const trimmedSearchInput = searchInput.trim();

    let tickets = unfilteredTickets;
    if (trimmedSearchInput) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      tickets = matchSorter(unfilteredTickets, trimmedSearchInput, { keys: searchKeys });
    }

    tickets = orderBy(
      tickets,
      [({ id }) => ticketNotifications.includes(id), 'jiraLastSyncedAt', 'createdAt'],
      ['desc', 'desc', 'desc'],
    );

    return tickets;
  }, [searchInput, unfilteredTickets, ticketNotifications]);

  const navigateToForm = () => {
    dispatch(createNewTicketSelected({ channelId }));
  };

  return (
    <div className={styles.ticketsWrapper}>
      <div className={styles.header}>
        <TicketSearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
        <button type="button" className={styles.plus} onClick={navigateToForm}>
          <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.WHITE} size={ICON_SIZE.SMALL} />
        </button>
      </div>
      <div>
        {ticketsLoading
          && filteredTickets.length < 1
          && (<LoadingIndicator />)}
        <div className={styles.ticketsList}>
          {filteredTickets.map(
            (ticket: { id: string; status: string; title: string; createdAt: string; }) => (
              <TicketCard
                id={ticket.id}
                key={ticket.id}
                status={ticket.status}
                title={ticket.title}
                createdAt={ticket.createdAt}
              />
            ),
          )}
        </div>
        {
          !ticketsLoading
          && filteredTickets.length === 0
          && (
            <div>
              <p>
                Sie haben noch keine Tickets erstellt.
                Sobald Sie ein Ticket einreichen,
                wird es hier angezeigt.
              </p>
            </div>
          )
        }
      </div>
    </div>

  );
};

export default TicketsOverview;
