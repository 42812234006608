import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CHANNEL_TYPE, ChannelTypeInfo } from 'gcs-common/helper/channelTypeHelper';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getAgentDirectChannels,
  getAgentDirectChannelsLoading,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import {
  fetchAgentDirectChannels,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import {
  deleteDirectChannel,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import Table from '../../../Table/Table';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import AgentDirectChannelCreate from '../AgentDirectChannelCreate/AgentDirectChannelCreate';
import DirectChannelDelete from '../../Channels/DirectChannelDelete/DirectChannelDelete';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addIcon from '../../../../img/plus_white.svg';
import LozengeCell from '../../../Table/LozengeCell/LozengeCell';
import deletedIcon from '../../../../img/delete.svg';
import detailsIcon from '../../../../img/arrow.svg';
import { stringSortingFunction, channelTypeSortFunction } from '../../../../helper/sortHelper';

const channelColumns = [
  {
    Header: 'Typ',
    accessor: 'channelType',
    filter: 'fuzzyText',
    Cell: (cell) => cell.value && LozengeCell({
      value: ChannelTypeInfo[cell.value] ? ChannelTypeInfo[cell.value].name : cell.value,
      color: ChannelTypeInfo[cell.value] ? ChannelTypeInfo[cell.value].color : '#827f7f',
    }),
    sortType: channelTypeSortFunction,
  },
  {
    Header: 'Haus',
    accessor: 'primaryHouse.friendlyName',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Name',
    accessor: 'friendlyName',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Kundennummer',
    accessor: 'customerId',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Nachrichten',
    accessor: 'messagesCount',
  },
  {
    Header: 'Teilnehmer',
    accessor: 'membersCount',
    filter: 'fuzzyText',
  },
  {
    Header: 'Erstellt von',
    accessor: 'createdBy',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Erstellt am',
    accessor: 'dateCreated',
    filter: 'fuzzyText',
    Cell: (cell) => {
      return cell.value ? moment(cell.value).format('ddd, D MMM YYYY HH:mm') : '-';
    },
  },
];

const AgentDirectChannelTable = ({ agentId, searchInputValue = '', channelTypes }) => {

  const agentDirectChannels = useSelector(getAgentDirectChannels);
  const agentDirectChannelsLoading = useSelector(getAgentDirectChannelsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showChannelCreate, setShowChannelCreate] = useState(false);
  const [showChannelDelete, setShowChannelDelete] = useState(false);

  const hasGlobalAdminPermission = useSelector(getHasPermission('global_admin'));

  useEffect(() => {
    if (agentId) {
      dispatch(fetchAgentDirectChannels({ agentId, channelTypes }));
    }
  }, [agentId, dispatch, channelTypes]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setShowChannelDelete(original);
    },
    [],
  );

  const channelCreated = useCallback(
    () => {
      setShowChannelCreate(false);
      dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
    },
    [dispatch],
  );

  const channelDeleted = useCallback(
    () => {
      setShowChannelDelete(null);
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    },
    [dispatch],
  );

  const onChannelSelected = (channel) => {
    const { id } = channel;
    navigate(`/administration/users/channel/${id}`);
  };

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteDirectChannel({
        channelId: showChannelDelete.id,
        agentId,
      }));
      if (!result.error) {
        channelDeleted();
        setTimeout(() => {
          dispatch(fetchAgentDirectChannels({ agentId }));
        }, 1000);
      }
    },
    [agentId, showChannelDelete, dispatch, channelDeleted],
  );

  const message = useMemo(() => {
    return `Möchten Sie den Channel mit "${showChannelDelete?.friendlyName}" wirklich löschen?`;
  }, [showChannelDelete]);

  const channelColumnsForChannelType = useMemo(() => {
    if (channelTypes.includes(CHANNEL_TYPE.INTERNAL_AGENT)) {
      return channelColumns.filter(column => column.accessor !== 'primaryHouse.friendlyName');
    }
    return channelColumns;
  }, [channelTypes]);

  return (
    <>
      <Table
        isLoading={agentDirectChannelsLoading}
        data={agentDirectChannels}
        columns={channelColumnsForChannelType}
        filterString={searchInputValue}
        onRowSelected={onChannelSelected}
        renderActions={({ row }) => (
          <>
            <button type="button">
              {/* eslint-disable-next-line max-len */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img
                alt="deleted-icon"
                src={deletedIcon}
                onClick={(event) => deleteSelected(event, row)}
              />
            </button>
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      {hasGlobalAdminPermission && (
        <AddFabButton
          title="Direkter Channel"
          icon={addIcon}
          onClick={() => setShowChannelCreate(true)}
        />
      )}
      {showChannelCreate && (
        <PopUpModal
          title="Direkten Channel erstellen"
          onRequestClose={() => setShowChannelCreate(false)}
          isOpen
        >
          <AgentDirectChannelCreate
            onCancel={() => setShowChannelCreate(false)}
            onSuccess={channelCreated}
          />
        </PopUpModal>
      )}
      {showChannelDelete && (
        <PopUpModal
          title="Channel löschen"
          onRequestClose={() => setShowChannelDelete(null)}
          isOpen
        >
          <DirectChannelDelete
            message={message}
            onCancel={() => setShowChannelDelete(null)}
            handleDelete={handleDelete}
          />
        </PopUpModal>
      )
      }
    </>
  );
};

AgentDirectChannelTable.propTypes = {
  searchInputValue: PropTypes.string,
  agentId: PropTypes.string.isRequired,
  channelTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CHANNEL_TYPE))).isRequired,
};

export default AgentDirectChannelTable;
