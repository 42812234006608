export default class BaseConverter {
  /**
   * Converts the Twilio Data model to our internal Data model
   * That means for example unpacking the Twilio attributes and flattening the structure
   */
  // eslint-disable-next-line class-methods-use-this
  fromTwilioDataModel = () => {
    throw new Error('Not implemented');
  };
}
