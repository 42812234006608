
import { ButtonHTMLAttributes } from 'react';
import styles from './styles.module.scss';

type OrderButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const OrderButton = ({ children, className = '', ...props } : OrderButtonProps) => {
  return (
  // eslint-disable-next-line react/button-has-type
    <button
      className={`${styles.orderButton} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default OrderButton;
