import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import PropTypes from 'prop-types';
import {
  getCurrentUser, getDeleteCurrentUserError, getDeleteCurrentUserLoading, getIsAgent,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { PHONE_OR_MAIL_REGEX } from 'gcs-common/constants/formValidation';
import deleteCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/deleteCurrentUser';
import { resetDeleteCurrentUserError } from 'gcs-common/slices/currentUser/currentUserSlice';
import { cleanPhoneNumber } from 'gcs-common/helper/formHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import Button from '../../Button/Button';
import ModalWithCloseButton from '../../PopUpModal/ModalWithCloseButton/ModalWithCloseButton';
import TextInput from '../../TextInput/TextInput';


const AccountSettings = ({ isOpen, onRequestClose }) => {
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false);
  const [userIdentifierInput, setUserIdentifierInput] = useState(undefined);

  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const isAgent = useSelector(getIsAgent);
  const isDeleting = useSelector(getDeleteCurrentUserLoading);
  const hasDeletionError = useSelector(getDeleteCurrentUserError);

  const onDeleteConfirmationOpened = useCallback(() => {
    setShowDeletionConfirmationModal(true);
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.SHOW_DELETE_CONFIRMATION()));
    dispatch(resetDeleteCurrentUserError());
  }, [dispatch]);

  const onDeleteAccountClicked = useCallback(() => {
    dispatch(deleteCurrentUser());
  }, [dispatch]);

  const onDeleteConfirmationClosed = useCallback(() => {
    setShowDeletionConfirmationModal(false);
    onRequestClose();
  }, [onRequestClose]);

  const onUserIdentifierChanged = useCallback((e) => {
    setUserIdentifierInput(e.target.value);
  }, []);

  const inputValid = useMemo(() => {
    const phoneNumber = currentUser?.phoneNumber;
    if (phoneNumber && userIdentifierInput) {
      if (cleanPhoneNumber(phoneNumber) === cleanPhoneNumber(userIdentifierInput)) {
        return true;
      }
    }
    return currentUser?.email?.toLowerCase() === userIdentifierInput?.toLowerCase();
  }, [currentUser, userIdentifierInput]);

  const isInvalidEmailOrPhone = useMemo(() => {
    return !inputValid && PHONE_OR_MAIL_REGEX.test(userIdentifierInput);
  }, [inputValid, userIdentifierInput]);

  const deletionConfirmationBody = useMemo(() => (
    <>
      <div className={styles.title}>
        BEEM ACCOUNT DATEN LÖSCHEN
      </div>
      {isDeleting ? <LoadingIndicator />
        : (
          <div className={styles.contentContainer}>
            <strong>
              Sie sind dabei, Ihren BEEM Account dauerhaft zu löschen.
            </strong>
            <p>
              <strong>
                Alle verknüpften Daten werden dabei gelöscht.
              </strong>
            </p>
            <strong>
              Sie werden dann ausgeloggt.
            </strong>

          </div>
        )
      }
      <div className={styles.buttonContainer}>
        <Button
          disabled={isDeleting}
          className={styles.buttonError}
          onClick={onDeleteAccountClicked}
        >
          Account löschen
        </Button>
        <Button
          disabled={isDeleting}
          className={styles.confirmationButton}
          onClick={onDeleteConfirmationClosed}
        >
          Abbrechen
        </Button>
      </div>
      {hasDeletionError && (
        <div className={styles.inputError}>
          Beim löschen is ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.
        </div>
      )}
    </>
  ), [hasDeletionError, isDeleting, onDeleteAccountClicked, onDeleteConfirmationClosed]);

  const accountDeletionBody = useMemo(() => (
    <>
      <div className={styles.title}>
        BEEM ACCOUNT DATEN LÖSCHEN
      </div>
      <div className={styles.contentContainer}>
        <p>
          <strong>
            Wenn Sie Ihren Account löschen, werden folgende Daten dauerhaft gelöscht:
          </strong>
        </p>
        <ul>
          <li>
            - BEEM Accountinformationen und Profilbild
          </li>
          <li>
            - Ihr kompletter Nachrichtenverlauf in allen Chats
          </li>
        </ul>
        <p>
          Sie werden aus allen Kanälen und Gruppenchats entfernt.
          {' '}
          <strong>
            Eine Wiederherstellung der
            Daten ist nachdem Löschen nicht mehr möglich.
          </strong>
        </p>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>
          {!isAgent && 'MOBILNUMMER ODER'}
          {' '}
          E-MAIL ADRESSE EINGEBEN*
        </div>
        <TextInput
          value={userIdentifierInput}
          className={styles.input}
          onChange={onUserIdentifierChanged}
        />
        {isInvalidEmailOrPhone && (
          <div className={styles.inputError}>
            {!isAgent && 'Mobilnummer oder'}
            {' '}
            E-Mail Adresse passt nicht zu den Accountinformationen
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          disabled={!inputValid}
          onClick={onDeleteConfirmationOpened}
          className={styles.button}
        >
          Account löschen
        </Button>
      </div>
    </>
  ), [
    inputValid,
    isAgent,
    isInvalidEmailOrPhone,
    onDeleteConfirmationOpened,
    onUserIdentifierChanged,
    userIdentifierInput,
  ]);

  return (
    <ModalWithCloseButton label="Account Einstellungen" onRequestClose={onRequestClose} isOpen={isOpen}>
      {showDeletionConfirmationModal ? deletionConfirmationBody : accountDeletionBody}
    </ModalWithCloseButton>
  );
};

AccountSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default AccountSettings;
