import PropTypes from 'prop-types';
import { pad } from 'gcs-common/helper/paddedNumberHelper';
import styles from './styles.module.scss';
import CubeIcon from '../../img/cube_primary.svg?react';

function ProcessDetailSmall({ process }) {
  const { ProcessNumber: Number, ProcessTypeDescription: TypeText, CustomerOrderText } = process;

  const formattedProcessNumber = `${Number}-${pad(process.ProcessSuffix)}`;
  return (
    <div
      className={styles.process}
      data-testid={`process-detail-${formattedProcessNumber}`}
      data-testprocessnumber={formattedProcessNumber}
    >
      <CubeIcon className={styles.magicCubeSmallIcon} />
      <div>
        <div className={styles.processHeader}>{TypeText}</div>
        <div className={styles.processNumber}>{formattedProcessNumber}</div>
        <div className={styles.processDetail}>
          Auftragstext:
          {' '}
          {CustomerOrderText}
        </div>
      </div>
    </div>
  );
}

export default ProcessDetailSmall;

ProcessDetailSmall.propTypes = {
  process: PropTypes.shape({
    CustomerOrderText: PropTypes.string,
    ProcessNumber: PropTypes.string,
    ProcessSuffix: PropTypes.string,
    ProcessTypeDescription: PropTypes.string,
  }).isRequired,
};
