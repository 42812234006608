import PropTypes from 'prop-types';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import Icon from '../Icon/Icon';
import styles from './styles.module.scss';

const PlusButton = ({ clickHandler = () => {} }) => {
  return (
    <button
      type="button"
      className={styles.plus}
      onClick={clickHandler}
    >
      <Icon
        Icon={ICON.PLUS}
        size={ICON_SIZE.X_SMALL}
        color={ICON_COLOR.WHITE}
      />
    </button>
  );
};

PlusButton.propTypes = {
  clickHandler: PropTypes.func,
};

export default PlusButton;
