import { MutationStatus, useMutationState } from '@tanstack/react-query';
import { TasksClientQueryKeys } from './tasks-api-helpers';
import { RequestBody, Parameters } from '../../../../../types/typeHelper';

interface MutationCache {
  status: MutationStatus
}

export const useCreateTaskMutationCache = ({ status }: MutationCache) => {
  const mutationState = useMutationState({
    filters: {
      mutationKey: [TasksClientQueryKeys.CREATE_TASK],
      status,
    },
  });

  return mutationState.map(
    (mutation) => (mutation.variables as { body: RequestBody<'/chat/tasks', 'post'> }).body.id,
  );
};

export const useUpdateTaskMutationCache = ({ status }: MutationCache) => {
  const mutationState = useMutationState({
    filters: {
      mutationKey: [TasksClientQueryKeys.UPDATE_TASK],
      status,
    },
  });

  return mutationState.map(
    (mutation) => (mutation.variables as { params: Parameters<'/chat/tasks/{task_id}', 'put'> }).params.path.task_id,
  );
};

export const useSetTaskDoneMutationCache = ({ status }: MutationCache) => {
  const mutationState = useMutationState({
    filters: {
      mutationKey: [TasksClientQueryKeys.SET_TASK_DONE],
      status,
    },
  });

  return mutationState.map(
    (mutation) => (mutation.variables as { params: Parameters<'/chat/tasks/{task_id}/done', 'patch'> }).params.path.task_id,
  );
};
