import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import createChannel from 'gcs-common/slices/channels/channelsThunks/createChannel';
import createSalesSpace from 'gcs-common/slices/channels/channelsThunks/createSalesSpace';
import { selectNewChannelOptions } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import { getNewChannelOption } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import { getCreateSalesSpaceIsLoading, getCreateChannelIsLoading } from 'gcs-common/slices/channels/channelsSelectors';
import ChannelListItemNormalNew from '../../ChannelListItemNormalNew/ChannelListItemNormalNew';

const NewChannelItem = ({ newChannelOptionId, multiSelect = false }) => {
  const dispatch = useDispatch();
  const newChannelOption = useSelector(getNewChannelOption(newChannelOptionId));
  const createSalesSpaceIsLoading = useSelector(getCreateSalesSpaceIsLoading);
  const createChannelIsLoading = useSelector(getCreateChannelIsLoading);

  const onChannelSelect = () => {
    const userId = newChannelOption?.userId ? [newChannelOption.userId] : null;
    const assignmentId = newChannelOption?.assignmentId;

    dispatch(selectNewChannelOptions({ newChannelOptionId }));

    if (!multiSelect) {
      if (assignmentId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !createSalesSpaceIsLoading && dispatch(createSalesSpace(
          { assignmentId, userIds: userId },
        ));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !createChannelIsLoading && dispatch(createChannel({ userIds: userId }));
      }
    }
  };

  return (
    <ChannelListItemNormalNew
      multiSelect={multiSelect}
      newChannelOptionId={newChannelOptionId}
      onChannelSelect={onChannelSelect}
    />
  );
};

NewChannelItem.propTypes = {
  newChannelOptionId: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
};

export default NewChannelItem;
