/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchHouseBranches } from './houseBranchesThunks';
import ASYNC_STATE from '../../../constants/AsyncState';
import paginateFullfilledReducer from '../../../helper/reduxPaginationHelper';
import { byId } from '../../../helper/byKey';
import mergeObjects from '../../../helper/mergeObjects';

const asyncState = {
  fetchHouseBranches: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  houseBranches: {},
};

const houseBranchesSlice = createSlice({
  name: 'houseBranches',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchHouseBranches.pending]: (state) => {
      state.fetchHouseBranches.status = ASYNC_STATE.LOADING;
    },
    [fetchHouseBranches.fulfilled]: paginateFullfilledReducer(
      (state) => state.fetchHouseBranches,
      (state, action) => {
        const { data } = action.payload;
        state.fetchHouseBranches.status = ASYNC_STATE.SUCCEEDED;
        state.houseBranches = mergeObjects(state.houseBranches, byId(data));
      },
    ),
    [fetchHouseBranches.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchHouseBranches.status = ASYNC_STATE.FAILED;
      state.fetchHouseBranches.error = error;
    },
  },
});

export default houseBranchesSlice.reducer;
