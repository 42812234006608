import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue';
import { deleteMessageOffline } from '../slices/messageInput/messageInputActions/deleteMessageOffline';
import { deleteOfflineHandleEnqueue } from './helper/deleteMessageOfflineHelper';
import { addFavoriteOfflineHandleEnqueue, removeFavoriteOfflineHandleEnqueue } from './helper/favoriteOfflineHelper';
import { removeFavoriteOffline } from '../slices/favorites/favoritesActions/removeFavoriteOffline';
import { addFavoriteOffline } from '../slices/favorites/favoritesActions/addFavoriteOffline';

const offlineQueue = {
  ...defaultQueue,
  /**
   * Configures how offline actions are enqueued into the outbox (see state offline.outbox)
   */
  enqueue(queue, action) {

    switch (action.type) {
      case deleteMessageOffline.type: {
        return deleteOfflineHandleEnqueue(queue, action);
      }
      case removeFavoriteOffline.type: {
        return removeFavoriteOfflineHandleEnqueue(queue, action);
      }
      case addFavoriteOffline.type: {
        return addFavoriteOfflineHandleEnqueue(queue, action);
      }
      default: {
        return [...queue, action];
      }
    }
  },
};

export default offlineQueue;
