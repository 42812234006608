/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import PLATFORM from 'gcs-common/constants/platformTypes';

const initialState = {
  platform: PLATFORM.WEB,
};

const initializationSlice = createSlice({
  name: 'initialization',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default initializationSlice.reducer;
