import { useSelector } from 'react-redux';
import { getPermissions } from '../slices/currentUser/currentUserSelectors';

function usePermission(permission) {
  const permissions = useSelector(getPermissions);

  const currentPermissions = permissions || [];

  for (let i = 0; i < permission.length; i++) {
    if (currentPermissions.includes(permission[i])) return true;
  }
  return false;
}

export default usePermission;
