import { convertMessageEnToDe } from 'gcs-common/helper/HouseCustomMessageHelper';

// maximum character length
export const MAX_MESSAGE_LENGTH = 1500;

function validateCustomMessage(customMessageTypes) {
  // eslint-disable-next-line func-names
  return this.test('validateCustomMessage', function (values) {
    const { path, createError } = this;
    const { messageType } = this.parent;
    const { [messageType]: message } = values;
    // check if message is empty
    if (messageType && !message) {
      return createError({ path, message: 'Erforderlich' });
    }
    // check if exceeds 1500 characters
    if (message && message.length === MAX_MESSAGE_LENGTH) {
      return createError({ path, message: `Maximal ${MAX_MESSAGE_LENGTH} Zeichen.` });
    }
    // check if msg contains parameters that are not defined in the default message parameter types
    const customMessageType = customMessageTypes.find(m => m.type === messageType);
    const msgDe = convertMessageEnToDe(message,
      customMessageType ? customMessageType.parameters : []) || '';
    const regex = new RegExp('{[^\\s]*}', 'gi');
    const parameters = [...msgDe.matchAll(regex)].map(m => m[0]);
    const allowedParameters = customMessageType ? customMessageType.parameters.map(p => `{${p.key_label}}`) : [];
    const invalid = parameters.filter(p => !allowedParameters.includes(p));
    if (invalid && invalid.length) {
      return createError({ path, message: `Ungültige Parameter ${invalid.join(', ')}. ` });
    }
    // check if msg contains square brackets
    const wrongBracketRegex = new RegExp('(\\([[a-z_]]*\\)|\\[[a-z_]*\\])', 'gi');
    const invalidBracket = [...msgDe.matchAll(wrongBracketRegex)].map(m => m[0]);
    if (invalidBracket && invalidBracket.length) {
      return createError({ path, message: 'Bitte verwenden Sie nur geschweifte Klammern um die Parameter.' });
    }
    return true;
  });
}

export default validateCustomMessage;
