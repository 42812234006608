import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';

import { useNavigate } from 'react-router';

const FeatureFlagProtectedRoute = ({ disabledFlag, children, redirectTo }) => {
  const navigate = useNavigate();
  const isDisabled = useSelector(getHasFeatureFlag(disabledFlag));

  if (isDisabled && redirectTo) {
    navigate('/');
  }

  if (isDisabled) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

FeatureFlagProtectedRoute.propTypes = {
  disabledFlag: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  redirectTo: PropTypes.string,
};

export default FeatureFlagProtectedRoute;
