import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import changeSelectedChannelId from 'gcs-common/slices/channels/channelsThunks/changeSelectedChannelId';
import { getTicketNotificationsCountForChannel } from 'gcs-common/slices/tickets/ticketsSelectors';
import {
  getChannelImage,
  getChannelStatus,
  getChannelSubtitle,
  getChannelType,
  getIsSelectedChannel,
  getOtherMemberTyping,
  getChannelBrand,
  getIsGroupChannel,
  getChannelMemberCount,
  getIsSalesSpaceChannelType,
  getIsHidden,
  getChannelTitle,
  getNumOpenTasksForChannel,
} from 'gcs-common/slices/channel/channelSelectors';
import {
  getUnreadMessageCountForChannel,
} from 'gcs-common/slices/channels/unreadMessagesSelectors';
import ChannelListItemNormal from '../ChannelListItemNormal/ChannelListItemNormal';

const ChannelListItemNormalWithId = ({ channelId }) => {
  const isGroupChannel = useSelector(getIsGroupChannel(channelId));
  const numOpenTasks = useSelector(getNumOpenTasksForChannel(channelId));
  const ticketNotificationsCount = useSelector(getTicketNotificationsCountForChannel(channelId));
  const tasksAndTickets = numOpenTasks + ticketNotificationsCount;
  const channelBrand = useSelector(getChannelBrand(channelId));
  const isSelectedChannel = useSelector(getIsSelectedChannel(channelId));
  const unreadCount = useSelector(getUnreadMessageCountForChannel(channelId));
  const otherUserTyping = useSelector(getOtherMemberTyping(channelId));
  const channelType = useSelector(getChannelType(channelId));
  const channelImage = useSelector(getChannelImage(channelId));
  const channelStatus = useSelector(getChannelStatus(channelId));
  const channelTitle = useSelector(getChannelTitle(channelId));
  const channelSubtitle = useSelector(getChannelSubtitle(channelId));
  const channelMemberCount = useSelector(getChannelMemberCount(channelId));
  const isSalesSpace = useSelector(getIsSalesSpaceChannelType(channelId));
  const isHidden = useSelector(getIsHidden(channelId));

  const dispatch = useDispatch();

  const selectChannel = useCallback(() => {
    dispatch(changeSelectedChannelId({ selectedChannelId: channelId }));
  }, [channelId, dispatch]);

  return (
    <ChannelListItemNormal
      isGroupChannel={isGroupChannel}
      isSelectedChannel={isSelectedChannel}
      unreadCount={unreadCount}
      otherUserTyping={otherUserTyping}
      channelType={channelType}
      channelImage={channelImage}
      channelStatus={channelStatus}
      channelTitle={channelTitle}
      channelSubtitle={channelSubtitle}
      onChannelSelect={selectChannel}
      numOpenTasks={tasksAndTickets}
      channelBrand={channelBrand}
      channelMemberCount={channelMemberCount}
      isSalesSpace={isSalesSpace}
      isHidden={isHidden}
    />
  );
};

ChannelListItemNormalWithId.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelListItemNormalWithId;
