/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getTicketComments, getTicketCommentsLoading, getTicketNotification } from 'gcs-common/slices/tickets/ticketsSelectors';
import orderBy from 'lodash/orderBy';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';

import TicketComment from './TicketComment/TicketComment';
import styles from './styles.module.scss';

interface TicketCommentsListProps {
  ticketId: string;
}

const TicketCommentsList = (props: TicketCommentsListProps) => {
  const { ticketId } = props;
  const comments = useSelector(getTicketComments(ticketId));
  const commentsLoading = useSelector(getTicketCommentsLoading(ticketId));
  const ticketNotification = useSelector(getTicketNotification(ticketId));

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const commentsSorted = useMemo(() => orderBy(comments, 'createdAt', 'desc'), [comments]);

  return (
    <div className={styles.commentsList}>
      {commentsLoading && !comments && <LoadingIndicator />}
      {comments && (commentsSorted.map(comment => (
        <TicketComment
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          key={comment.id}
          ticketId={ticketId}
          comment={comment}
          ticketNotification={ticketNotification}
        />
      )))}
    </div>
  );
};

export default TicketCommentsList;
