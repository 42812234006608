import TicketCommentsList from './TicketCommentsList/TicketCommentsList';
import TicketCommentForm from './TicketCommentForm/TicketCommentForm';
import styles from './styles.module.scss';

interface TicketCommentsProps {
  ticketId: string;
  readOnly?: boolean
}

const TicketComments = (props: TicketCommentsProps) => {
  const { ticketId, readOnly = false } = props;
  return (
    <div className={styles.commentsWrapper}>
      {!readOnly && <TicketCommentForm ticketId={ticketId} /> }

      <TicketCommentsList ticketId={ticketId} />
    </div>
  );
};

export default TicketComments;
