import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMetaDescription } from 'gcs-common/slices/theme/themeSelectors';

const MetaTags = () => {
  const metaDescription = useSelector(getMetaDescription);

  useEffect(() => {
    if (metaDescription) {
      document?.querySelector('meta[name="description"]')?.setAttribute('content', metaDescription);
    }
  }, [metaDescription]);

  return null;
};

export default MetaTags;
