import { ICON, ICON_TRANSFORM, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import IconComponent from '../../../IconComponent/IconComponent';
import styles from './styles.module.scss';

const TicketDashboardHeader = () => {
  const navigate = useNavigate();
  const backToBeem = () => {
    navigate(CHAT_ROUTES.CHAT);
  };

  return (
    <div className={styles.dashboardHeader}>
      <button className={styles.dashboardBackButton} type="button" onClick={() => backToBeem()}>
        <IconComponent
          Icon={ICON.ARROW}
          transform={ICON_TRANSFORM.FLIP_HORIZONTAL}
          size={ICON_SIZE.LARGE}
          color={ICON_COLOR.WHITE}
        />
      </button>
      <span>
        Ersatzteilanfragen
      </span>
    </div>
  );
};

export default TicketDashboardHeader;
