import { createAction } from '@reduxjs/toolkit';

export const removeFavoriteRollback = createAction('removeFavoriteRollback', (payload) => ({ meta: payload }));

export const removeFavoriteOffline = createAction('removeFavoriteOffline', (payload) => {
  return {
    meta: {
      ...payload,
      offline: {
        rollback: removeFavoriteRollback(payload),
      },
    },
  };
});
