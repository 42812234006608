import {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetUpdatedGroupChannelNameDefaultErrorMessage, resetUpdatedGroupChannelNameErrorMessage,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSlice';
import {
  getUpdateGroupChannelNameDefaultErrorMessage, getUpdateGroupChannelNameErrorMessage,
  getIsUpdateGroupChannelNameLoading,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSelectors';
import {
  updateSelectedChannelName,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';
import { CHANNEL_TYPE } from 'gcs-common/helper/channelTypeHelper';
import { filterByErpCustomerIdReset } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import backIcon from '../../../../img/back.svg';

import Table from '../../../Table/Table';
import InlineInput from '../../../InlineInput/InlineInput';
import deletedIcon from '../../../../img/delete.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import GroupChannelMemberDelete from '../GroupChannelMemberDelete/GroupChannelMemberDelete';
import { showSuccessNotification, showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addCompanyIcon from '../../../../img/plus_white.svg';
import GroupChannelAddMember from '../GroupChannelAddMember/GroupChannelAddMember';
import { userColumns } from '../TableColumns/TableColumns';

const GroupChannelDetail = ({ channelId, selectedChannel, selectedChannelLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorMessage = useSelector(getUpdateGroupChannelNameErrorMessage);
  const defaultErrorMessage = useSelector(getUpdateGroupChannelNameDefaultErrorMessage);
  const isUpdating = useSelector(getIsUpdateGroupChannelNameLoading);

  const [addMember, setAddMember] = useState(false);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetUpdatedGroupChannelNameDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetUpdatedGroupChannelNameDefaultErrorMessage());
    dispatch(resetUpdatedGroupChannelNameErrorMessage());
    return () => dispatch(filterByErpCustomerIdReset());
  }, [dispatch]);

  const [groupNameIsEmptyErrorMessage, setGroupNameIsEmptyErrorMessage] = useState('');
  const [showMemberDelete, setShowMemberDelete] = useState(null);

  const [groupChannelName, setGroupChannelName] = useState(null);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setShowMemberDelete(original);
    },
    [],
  );

  const onGroupChannelNameChanged = useCallback(
    async (key, value) => {
      if (errorMessage) {
        dispatch(resetUpdatedGroupChannelNameErrorMessage());
      }
      if (!value) {
        setGroupNameIsEmptyErrorMessage('Erfordlich');
      } else {
        setGroupNameIsEmptyErrorMessage('');
      }
      if (groupChannelName !== value) {
        setGroupChannelName(value);
        const result = await dispatch(updateSelectedChannelName({
          channelId,
          channelName: value,
        }));
        if (!result.error) {
          dispatch(showSuccessNotification('Erfolgreich gespeichert'));
        }
      }
    },
    [dispatch, channelId, errorMessage, groupChannelName],
  );

  const groupChannelMemberDeleted = useCallback(
    () => {
      setShowMemberDelete(null);
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    },
    [dispatch],
  );

  const memberIsAddedSuccessfully = useCallback(() => {
    setAddMember(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    if (groupChannelName && groupChannelName !== values.name) {
      setGroupChannelName(values.name);
      dispatch(updateSelectedChannelName({
        channelId,
        channelName: values.name,
      }));
    }
    setSubmitting(false);
  }, [dispatch, channelId, groupChannelName]);

  const initialValues = useMemo(() => {
    if (selectedChannel && selectedChannel.channelType === CHANNEL_TYPE.SALES_SPACE) {
      return {
        name: selectedChannel.channelName || '',
      };
    }
    return '';
  }, [selectedChannel]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.headerRow}>
              <div className={styles.row}>
                <button
                  className={styles.backButton}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  <img src={backIcon} alt="back-icon" />
                </button>
                <InlineInput
                  name="name"
                  textClassName={styles.inputHeaderText}
                  onChange={onGroupChannelNameChanged}
                  value={initialValues.name}
                />
              </div>
              {(selectedChannelLoading || isSubmitting || isUpdating) && <LoadingIndicator padding="0px" />}
            </div>

            {
              (errorMessage || groupNameIsEmptyErrorMessage)
              && (
                <div className={styles.errorMessage}>
                  {groupNameIsEmptyErrorMessage || errorMessage}
                </div>
              )
            }

            <div className={styles.tabWrapper}>
              {selectedChannel?.users.length > 1
                && (
                  <Table
                    columns={userColumns}
                    data={selectedChannel?.users}
                    renderActions={({ row }) => (
                      <>
                        <button
                          type="button"
                          onClick={(event) => deleteSelected(event, row)}
                        >
                          <img
                            alt="deleted-icon"
                            src={deletedIcon}
                          />
                        </button>
                      </>
                    )}
                  />
                )}
              {selectedChannel?.users.length === 1
                && (
                  <Table
                    columns={userColumns}
                    data={selectedChannel?.users}
                  />
                )}
            </div>
          </Form>
        )}
      </Formik>

      <AddFabButton
        icon={addCompanyIcon}
        title="Teilnehmer"
        onClick={() => setAddMember(true)}
      />

      {addMember && (
        <PopUpModal
          title="Teilnehmer hinzufügen"
          onRequestClose={() => setAddMember(false)}
          isOpen
        >
          <GroupChannelAddMember
            onCancel={() => setAddMember(false)}
            onSuccess={memberIsAddedSuccessfully}
          />
        </PopUpModal>
      )
      }

      {showMemberDelete && (
        <PopUpModal
          title="Teilnehmer entfernen"
          onRequestClose={() => setShowMemberDelete(null)}
          isOpen
        >
          <GroupChannelMemberDelete
            user={showMemberDelete}
            channelId={channelId}
            channelName={selectedChannel.channelName}
            onCancel={() => setShowMemberDelete(null)}
            onSuccess={groupChannelMemberDeleted}
          />
        </PopUpModal>
      )
      }

    </div>
  );
};

GroupChannelDetail.propTypes = {
  channelId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedChannel: PropTypes.object.isRequired,
  selectedChannelLoading: PropTypes.bool.isRequired,
};
export default GroupChannelDetail;
