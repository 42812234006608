import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIsUserLoading } from '../usersSelectors';

export const fetchUser = createAsyncThunk(
  'channels/userInfo/fetchUser',
  async ({ id }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getUser({ id });
    return { user: response };
  },
  {
    condition: (_, { getState }) => {
      return !getIsUserLoading(getState());
    },
  },
);
