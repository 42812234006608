import firebase from 'firebase/app';
import 'firebase/messaging';
import BaseClient from 'gcs-common/clients/baseClient';
import { captureException, captureMessage } from '@sentry/react';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import faviconNotificationGC from '../img/favicon-notification-gc.svg';
import faviconNotificationGUT from '../img/favicon-notification-gut.svg';
import faviconNotificationEFG from '../img/favicon-notification-efg.svg';
import faviconNotificationITG from '../img/favicon-notification-itg.svg';
import faviconNotificationDTG from '../img/favicon-notification-dtg.svg';
import faviconNotificationTFG from '../img/favicon-notification-tfg.svg';
import faviconNotificationHTI from '../img/favicon-notification-hti.svg';
import faviconNotificationBEEM from '../img/favicon-notification-beem.svg';
import firebaseConfig, { publicVapidKey } from '../config/firebaseConfig';

const NOTIFICATION_TITLE_STRING = 'Neue Nachricht!';

const calculateBrandFromUrl = (url) => new URL(url).hostname.split('beem.de')[0].toUpperCase();

const removeEnvironmentFromUrl = (url) => url.replace(/\b(staging.|dev.|rc.)\b/gi, '');

const BRANDS = {
  EFG: faviconNotificationEFG,
  GC: faviconNotificationGC,
  GUT: faviconNotificationGUT,
  HTI: faviconNotificationHTI,
  ITG: faviconNotificationITG,
  DTG: faviconNotificationDTG,
  TFG: faviconNotificationTFG,
};

/*
 * Handles all firebase related stuff. Firebase is currently used for desktop notifications of
 * twilio chat notifications. There  is also a service worker who handles background related stuff
 */
class FirebaseClient extends BaseClient {

  _blinkInterval;

  _originalTitle;

  _originalFavicon;

  _alternateTick = true;

  init = async (onTokenUpdated, onNavigateToChat) => {

    try {
      // setup firebase
      if (!firebase.messaging.isSupported() || firebase.apps.length) {
        return;
      }

      // Initialize Firebase
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      } else {
        firebase.app();
      }


      const messaging = firebase.messaging();
      const swRegistration = await navigator.serviceWorker.register('/sw.js');

      messaging.useServiceWorker(swRegistration);
      messaging.usePublicVapidKey(publicVapidKey);

      const permission = await Notification.requestPermission();

      if (permission !== 'granted') {
        return;
      }

      const pushNotificationsChannel = new BroadcastChannel('push-notifications');

      messaging.onMessage((message) => {
        pushNotificationsChannel.postMessage({ type: 'foregroundNotification', payload: message });
      });

      pushNotificationsChannel.addEventListener('message', event => {
        switch (event.data.type) {
          case 'error':
            debugLogger('Error in ServiceWorker', event.data.payload);
            captureMessage(
              `ServiceWorker: ${event.data.payload.message}`,
              {
                level: 'error',
                tags: {
                  serviceworker: 'sw.js',
                },
              },
            );
            break;
          case 'notification':
            this._startNotification();
            break;
          case 'navigate_to_chat':
            onNavigateToChat(event.data.payload);
            break;
          default:
            break;
        }
      });

      let visibilityChange;
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        visibilityChange = 'visibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        visibilityChange = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        visibilityChange = 'webkitvisibilitychange';
      }
      document.addEventListener(visibilityChange, () => {
        this._stopNotification();
      });

      messaging.onTokenRefresh(async () => {
        const token = await messaging.getToken();
        onTokenUpdated(token);
      });

      const token = await messaging.getToken();
      onTokenUpdated(token);

    } catch (error) {
      captureException(error);
      debugLogger('Could not register serviceWorker', error);
    }

  };


  _setFavicon = (iconUrl) => {
    const link = document.querySelector('link[rel*=\'icon\']');
    if (!link) {
      const newLink = document.createElement('link');
      newLink.type = 'image/x-icon';
      newLink.rel = 'shortcut icon';
      newLink.href = iconUrl;
      document.getElementsByTagName('head')[0].appendChild(newLink);
      return;
    }
    link.href = iconUrl;
  };

  _startNotification = () => {
    if (this._blinkInterval) {
      return;
    }
    this._originalTitle = document.title;
    const link = document.querySelector('link[rel*=\'icon\']');
    this._originalFavicon = link.href;
    const brand = this._getBrandFromUrl();
    this._setFavicon(BRANDS[brand] || faviconNotificationBEEM);
    document.title = NOTIFICATION_TITLE_STRING;

    this._blinkInterval = window.setInterval(() => {
      if (this._alternateTick) {
        document.title = this._originalTitle;
        this._alternateTick = false;
        return;
      }

      document.title = NOTIFICATION_TITLE_STRING;
      this._alternateTick = true;
    }, 1000);
  };

  _getBrandFromUrl = () => {
    const url = window.location.href.toLowerCase();
    const urlWithoutEnv = removeEnvironmentFromUrl(url);
    let brandName = calculateBrandFromUrl(urlWithoutEnv);
    if (brandName === 'LOCALHOST') {
      brandName = localStorage.getItem('debugAppTheme').toUpperCase();
    }
    return brandName;
  };

  _stopNotification = () => {
    if (!this._blinkInterval) {
      return;
    }
    window.clearInterval(this._blinkInterval);
    document.title = this._originalTitle;
    this._setFavicon(this._originalFavicon);
    this._blinkInterval = null;
  };


}

export default new FirebaseClient();
