import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getAddCraftsmanConnectionError, getAddCraftsmanConnectionFailed, getAddCraftsmanConnectionLoading } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { addCraftsmanConnection } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import { resetAddError } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSlice';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { getAppName, getDefaultBrand } from 'gcs-common/slices/theme/themeSelectors';
import Button from '../../../Button/Button';
import styles from './styles.module.scss';
import SettingsInput from '../../SettingsInput/SettingsInput';

const CraftsmanConnectionModalAdd = ({ goToOverview }) => {
  const appName = useSelector(getAppName);
  const defaultBrand = useSelector(getDefaultBrand);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const addCraftsmanError = useSelector(getAddCraftsmanConnectionError);
  const addConnectionLoading = useSelector(getAddCraftsmanConnectionLoading);
  const addConnectionFailed = useSelector(getAddCraftsmanConnectionFailed);
  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));

  useEffect(() => {
    dispatch(resetAddError());
  }, [dispatch, username, password]);

  const onResetError = useCallback(() => {
    dispatch(resetAddError());
  }, [dispatch]);

  const onChangeUsername = useCallback((value) => {
    setUsername(value);
  }, []);

  const onChangePassword = useCallback((value) => {
    setPassword(value);
  }, []);

  const addConnectionMember = useCallback(async () => {
    const response = await dispatch(addCraftsmanConnection({
      brand: defaultBrand, username, password,
    }));
    if (response.error) {
      return;
    }
    goToOverview();
  }, [dispatch, username, password, goToOverview, defaultBrand]);
  return (
    <>
      <div className={styles.title}>
        {`Bitte tragen Sie ihre ${appName}-ONLINE PLUS Anmeldedaten ein:`}
      </div>
      <div className={styles.accountRowWrapper}>
        <SettingsInput
          name="Benutzer"
          placeholder="Benutzer"
          value={username}
          onChange={onChangeUsername}
        />
        <SettingsInput
          name="Passwort"
          placeholder="Passwort"
          type="password"
          value={password}
          onChange={onChangePassword}
        />
        <div className={styles.errorMsg}>
          {isOpMaintenanceEnabled ? opMaintenanceMessage : addCraftsmanError}
        </div>

        {addConnectionFailed ? (
          <Button className={styles.errorBtn} onClick={onResetError}>
            Nochmal versuchen
          </Button>
        ) : (
          <button
            disabled={!(username && password && !addConnectionLoading)}
            className={styles.btn}
            onClick={addConnectionMember}
            type="button"
          >
            Überprüfen und aktualisieren
          </button>
        )}

      </div>
    </>
  );
};

CraftsmanConnectionModalAdd.propTypes = {
  goToOverview: PropTypes.func.isRequired,
};

export default CraftsmanConnectionModalAdd;
