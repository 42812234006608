import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { pad } from 'gcs-common/helper/paddedNumberHelper';
import { processSelected, productSelected } from 'gcs-common/slices/processes/processesSlice';
import { TOOL_TABS } from 'gcs-common/constants/toolUIFlags';
import { setToolbarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import styles from './styles.module.scss';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

const ProductMessage = ({ product }) => {
  const dispatch = useDispatch();

  const setProduct = useCallback(() => {
    dispatch(setToolbarTab({ tab: TOOL_TABS.GCOP }));
    dispatch(processSelected({ selectedProcess: product.containingProcess }));
    dispatch(productSelected({ selectedProduct: product }));
  }, [dispatch, product]);

  // Note: The OR operator here, to read the messages
  // that are already saved in twilio properly
  const articleNumber = product.ArticleNumber || product.ArtikelNummer;
  const articleDescription1 = product.ArticleDescription1 || product.Bezeichnung1;
  const articleDescription2 = product.ArticleDescription2 || product.Bezeichnung2;
  const processNumber = (
    product.containingProcess?.ProcessNumber || product.containingProcess?.Number
  );
  const processSuffix = (
    product.containingProcess?.ProcessSuffix || product.containingProcess?.Suffix
  );

  return (
    <div onClick={setProduct} role="presentation" className={styles.product}>
      <div className={styles.productArticleNumber}>
        {articleNumber}
      </div>
      <div className={styles.productDescription}>
        <div>{articleDescription1}</div>
        <div>{articleDescription2}</div>
        {/* <ProductQuantity
          product={product}
        /> */}
        <div className={styles.processNumber}>
          {product.containingProcess && `${processNumber}-${pad(processSuffix)}`}
        </div>
      </div>
    </div>
  );
};

ProductMessage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
};

export default ProductMessage;
