import { createSelector } from '@reduxjs/toolkit';
import {
  CommunicationAssignmentType,
} from '../houseCommunicationStructures/houseCommunicationStructuresConstants';
import {
  getCommunicationAssignmentMainRole, getHouseAgentsForCommunicationStructureOptions,
  getHouseTeamsForCommunicationStructureOptions, getCustomerResponsibilityRolesForAgentOptions,
  getCustomerResponsibilityRolesForTeamOptions,
} from '../houseCommunicationStructures/houseCommunicationStructureSelectors';
import { CustomerResponsibilityMemberTypes } from '../customerResponsibilityMembers/customerResponsibilityMembersConstants';
import {
  getCustomerResponsibilityMembersForErpCustomer,
} from '../customerResponsibilityMembers/customerResponsibilityMembersSelector';
import { ROLE_STATUS } from '../../../constants/ChipConstants';
import { VALUE_KEY } from '../../../constants/dropdownSelectConstants';

export const getErpCustomerCommunicationAssignments = (
  state,
) => state.erpCustomerAssignments.communicationAssignments;

export const getErpCustomerTeamAssignments = createSelector(
  getErpCustomerCommunicationAssignments,
  (communicationAssignments) => (
    // eslint-disable-next-line max-len
    communicationAssignments.filter(assignment => assignment.type === CommunicationAssignmentType.SALES_SPACE).map(assignment => assignment.id)
  ),
);

export const getErpCustomerDirectAssignments = createSelector(
  getErpCustomerCommunicationAssignments,
  (communicationAssignments) => (
    // eslint-disable-next-line max-len
    communicationAssignments.filter(assignment => assignment.type === CommunicationAssignmentType.DIRECT).map(assignment => assignment.id)
  ),
);

export const getCommunicationAssignment = (id) => createSelector(
  getErpCustomerCommunicationAssignments,
  (communicationAssignments) => communicationAssignments.find(assignment => assignment.id === id),
);

export const getIsCommunicationAssignmentGeneratedFromStructure = (id) => createSelector(
  getCommunicationAssignment(id),
  (communicationAssignment) => communicationAssignment?.isGeneratedFromStructure,
);

export const getCommunicationAssignmentMembers = (id) => createSelector(
  getCommunicationAssignment(id),
  (communicationAssignment) => {
    return communicationAssignment?.members
      ?.map(member => {
        if (member.customerResponsibilityRoleId && (member.agentId || member.teamId)) {
          return {
            ...member,
            roleStatus: ROLE_STATUS.MEMBER_WITH_ROLE,
            [VALUE_KEY]: member.customerResponsibilityRoleId,
          };
        } if (member.customerResponsibilityRoleId && (!member.agentId && !member.teamId)) {
          return {
            ...member,
            roleStatus: ROLE_STATUS.ROLE_NO_MEMBER,
            [VALUE_KEY]: member.customerResponsibilityRoleId,
          };
        } if (member.agentId) {
          return {
            ...member,
            roleStatus: ROLE_STATUS.MEMBER_NO_ROLE,
            [VALUE_KEY]: member.agentId,
          };
        } if (member.teamId) {
          return {
            ...member,
            roleStatus: ROLE_STATUS.MEMBER_NO_ROLE,
            [VALUE_KEY]: member.teamId,
          };
        }
        return null;
      });
  },
);

export const getCommunicationAssignmentMainAgent = (id) => createSelector(
  getCommunicationAssignmentMainRole,
  getCommunicationAssignment(id),
  getCommunicationAssignmentMembers(id),
  (assignmentMainRole, communicationAssignment, communicationAssignmentMembers) => {
    if (communicationAssignment?.type === CommunicationAssignmentType.SALES_SPACE) {
      return communicationAssignmentMembers?.find((member) => {
        // eslint-disable-next-line max-len
        return member.type === CustomerResponsibilityMemberTypes.INDIVIDUAL && member.roleId === assignmentMainRole?.id;
      });
    }
    return null;
  },
);


export const getCommunicationAssignmentMembersGeneratedFromStructure = (id) => createSelector(
  getCommunicationAssignmentMembers(id),
  getCommunicationAssignmentMainAgent(id),
  (communicationAssignmentMembers, mainAgent) => {
    const defaultMembers = communicationAssignmentMembers
      ?.filter(member => member.isGeneratedFromStructure === true && member?.id !== mainAgent?.id);
    return defaultMembers || [];
  },
);

export const getCommunicationAssignmentMembersGeneratedByUser = (id) => createSelector(
  getCommunicationAssignmentMembers(id),
  getCommunicationAssignmentMainAgent(id),
  (communicationAssignmentMembers, mainAgent) => {
    const customMembers = communicationAssignmentMembers
      ?.filter(member => member.isGeneratedFromStructure === false && member?.id !== mainAgent?.id);
    return customMembers || [];
  },
);

export const getAssignmentMembers = (id) => createSelector(
  getCommunicationAssignmentMembersGeneratedFromStructure(id),
  getCommunicationAssignmentMembersGeneratedByUser(id),
  (defaultMembers, customMembers) => [...defaultMembers, ...customMembers],
);


export const getMainContactPersonOptions = ({ erpCustomerId }) => createSelector(
  getHouseAgentsForCommunicationStructureOptions,
  getCustomerResponsibilityRolesForAgentOptions,
  getCustomerResponsibilityMembersForErpCustomer({ erpCustomerId }),
  (houseAgents, customerResponsibilityRolesForAgent, customerResponsibilityMembers) => {

    const individualResponsibilityMembers = customerResponsibilityMembers.filter(
      member => member.type === 'INDIVIDUAL',
    );

    const formattedRespMembers = individualResponsibilityMembers.map(e => {
      return {
        ...e,
        id: e.id,
        friendlyName: e.teamOrAgent,
        customerResponsibilityRoleName: e.roleName,
        customerResponsibilityRoleId: e.customerResponsibilityRoleId,
        type: e.type,
        roleStatus: ROLE_STATUS.MEMBER_WITH_ROLE,
        [VALUE_KEY]: e.customerResponsibilityRoleId,
      };
    });

    // Filter out responsibility roles that are already assigned to an agent
    const filteredResponsibilityRoles = customerResponsibilityRolesForAgent.filter(
      role => !formattedRespMembers.some(member => member.customerResponsibilityRoleId === role.id),
    );

    const formattedResponsibilityRoles = filteredResponsibilityRoles
      .map(r => {
        return {
          ...r,
          friendlyName: 'Kein Mitarbeiter',
          customerResponsibilityRoleName: r.friendlyName,
          roleStatus: ROLE_STATUS.ROLE_NO_MEMBER,
          [VALUE_KEY]: r.customerResponsibilityRoleId,
        };
      });

    return [...houseAgents,
      ...formattedRespMembers,
      ...formattedResponsibilityRoles,
    ];
  },
);

export const getAssignmentMembersOptions = ({ erpCustomerId }) => createSelector(
  getMainContactPersonOptions({ erpCustomerId }),
  getHouseTeamsForCommunicationStructureOptions,
  getCustomerResponsibilityRolesForTeamOptions,
  getCustomerResponsibilityMembersForErpCustomer({ erpCustomerId }),
  // eslint-disable-next-line max-len
  (individualOptions, houseTeams, customerResponsibilityRolesForTeam, customerResponsibilityMembers) => {
    const teamResponsibilityMembers = customerResponsibilityMembers.filter(
      member => member.type === 'TEAM',
    );

    const formattedRespMembers = teamResponsibilityMembers.map(e => {
      return {
        ...e,
        id: e.id,
        friendlyName: e.teamOrAgent,
        customerResponsibilityRoleName: e.roleName,
        customerResponsibilityRoleId: e.customerResponsibilityRoleId,
        type: e.type,
        roleStatus: ROLE_STATUS.MEMBER_WITH_ROLE,
        [VALUE_KEY]: e.customerResponsibilityRoleId,
      };
    });

    // Filter out responsibility roles that are already assigned to an agent
    const filteredResponsibilityRoles = customerResponsibilityRolesForTeam.filter(
      role => !formattedRespMembers.some(member => member.customerResponsibilityRoleId === role.id),
    );

    const formattedResponsibilityRoles = filteredResponsibilityRoles
      .map(r => {
        return {
          ...r,
          friendlyName: 'Kein Team',
          customerResponsibilityRoleName: r.friendlyName,
          roleStatus: ROLE_STATUS.ROLE_NO_MEMBER,
          [VALUE_KEY]: r.customerResponsibilityRoleId,
        };
      });

    return [...individualOptions,
      ...houseTeams,
      ...formattedRespMembers,
      ...formattedResponsibilityRoles,
    ];
  },
);


export const getCreateCommunicationAssignmentDefaultError = (
  state,
) => state.erpCustomerAssignments.create.defaultErrorMessage;

export const getUpdateCommunicationAssignmentDefaultError = (
  state,
) => state.erpCustomerAssignments.update.defaultErrorMessage;

export const getDeleteCommunicationAssignmentDefaultError = (
  state,
) => state.erpCustomerAssignments.delete.errorMessage;
