import { createSelector } from '@reduxjs/toolkit';
import { QuickOrderingState } from './quickOrderingSlice';

const getQuickOrderingState = (state: { quickOrdering: QuickOrderingState }) => state.quickOrdering;

export const getSelectedOrderId = createSelector(
  getQuickOrderingState,
  (state) => state.selectedOrderId,
);

export const getIsCreateNewOrder = createSelector(
  getQuickOrderingState,
  (state) => state.isCreateNewOrder,
);

export const getOrder = (orderId: string) => createSelector(
  getQuickOrderingState,
  (state) => state.orders[orderId],
);

export const getIsCheckoutAuthenticationOpen = createSelector(
  getQuickOrderingState,
  (state) => state.isCheckoutAuthenticationOpen,
);

export const getIsCheckoutFinished = createSelector(
  getQuickOrderingState,
  (state) => state.isCheckoutFinished,
);
