import { createAction } from '@reduxjs/toolkit';
import { captureMessage, captureException } from '@sentry/react';
import { getChannelIdByExternalId } from '../../channel/channelSelectors';
import { debugLogger } from '../../../helper/debugLogger';
import {
  updateUser,
} from '../../users/usersThunks';
import {
  handleMemberJoined,
  removeMember,
  updateMember,
} from '../../members/membersThunks';
import handleChannelJoined from '../../channels/channelsThunks/handleChannelJoined';
import handleChannelLeft from '../../channels/channelsThunks/handleChannelLeft';
import handleChannelRemoved from '../../channels/channelsThunks/handleChannelRemoved';
import handleChannelUpdated from '../../channels/channelsThunks/handleChannelUpdated';
import addMessages from '../../messages/messagesThunks/addMessages';
import updateMessage from '../../messages/messagesThunks/updateMessage';
import changeSelectedChannelId from '../../channels/channelsThunks/changeSelectedChannelId';
import AppMustUpgradeError from '../../../clients/gccApiClient/errors/AppMustUpgradeError';
import {
  ChatEvents,
  ChatClientStateEvents,
} from '../../../clients/chatClient/chatClient';
import { updateCurrentChatUser } from '../../currentUser/currentUserThunks/updateCurrentChatUser';

export const initChatSuccess = createAction('initChatSuccess');
export const updateConnectionState = createAction('updateConnectionState');

const MAX_INIT_RETRIES = 10;

export const initClient = ({ retryAttempts = 0 } = {}) => async (
  dispatch, getState, { chatClient, gccApiClient },
) => {
  try {
    const { twilioToken } = await gccApiClient.getTwilioToken();
    await chatClient.init(twilioToken);
    // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
    await dispatch(setupClientListeners());
    await dispatch(initChatSuccess());
  } catch (e) {
    if (e instanceof AppMustUpgradeError) {
      captureMessage('Did not init client because app version must upgrade', { level: 'info' });
      return;
    }
    debugLogger(e);
    captureException(e);
    if (retryAttempts < MAX_INIT_RETRIES) {
      captureMessage(
        'initClient failed. Trying again',
        {
          level: 'info',
          extra: { e, retryAttempts },
        },
      );
      await setTimeout(
        () => dispatch(initClient(
          { retryAttempts: retryAttempts + 1 },
        )),
        1000,
      );
    } else {
      captureMessage(
        'initClient has reached the maximum number of retries',
        {
          level: 'warning',
          extra: { e, retryAttempts },
        },
      );
    }
  }
};

export const shutdownChatClient = () => async (
  dispatch, getState, { chatClient },
) => {
  await chatClient.shutdown();
};

export const removeAllChatListeners = () => async (
  dispatch, getState, { chatClient },
) => {
  await chatClient.removeListeners(Object.values(ChatEvents));
};

export const setupClientListeners = () => async (
  dispatch, getState, { chatClient },
) => {

  const renewTwilioToken = async () => {
    // usually you would do this here:
    // const { twilio_token: twilioToken } = await gccApiClient.getTwilioToken();
    // await chatClient.updateToken(twilioToken);
    // but since updateToken does not work with the current version of the js twilio
    // chat client (^6.0.0), we have to do a reconnect
    // The "normal" procedure with updating the token would work for tokenAboutToExpire,
    // but if the client is already disconnected (e.g. computer was in sleep),
    // it fails to automatically connect again. For this reason a complete refresh must be forced.
    await chatClient.shutdown();
    dispatch(initClient());
  };

  const connectionError = async (error) => {
    const { terminal, message, httpStatusCode, errorCode } = error || {};
    debugLogger(
      ChatClientStateEvents.connectionError,
      error,
      terminal,
      message,
      httpStatusCode,
      errorCode,
    );
    captureMessage('Caught Twilio Client connectionError!', {
      level: 'info',
      tags: { terminal, message, httpStatusCode, errorCode },
    });
  };

  const connectionStateChanged = async (connectionState) => {
    debugLogger(ChatClientStateEvents.connectionStateChanged, connectionState);
    dispatch(updateConnectionState({ connectionState }));
  };

  chatClient.on(ChatClientStateEvents.tokenAboutToExpire, renewTwilioToken);
  chatClient.on(ChatClientStateEvents.tokenExpired, renewTwilioToken);
  chatClient.on(ChatClientStateEvents.connectionError, connectionError);
  chatClient.on(ChatClientStateEvents.connectionStateChanged, connectionStateChanged);
};

export const setupChatListenersBeforeInit = () => async (dispatch, getState, { chatClient }) => {

  const memberJoined = async (member) => {
    debugLogger(ChatEvents.memberJoined);
    dispatch(handleMemberJoined({ member }));
  };

  const memberLeft = async (member) => {
    debugLogger(ChatEvents.memberLeft);
    dispatch(removeMember({ member }));
  };

  const currentUserUpdated = ({ user }) => {
    debugLogger(ChatEvents.currentUserUpdated);
    dispatch(updateCurrentChatUser({ user }));
  };


  const channelLeft = (channel) => {
    debugLogger(ChatEvents.channelLeft);
    dispatch(handleChannelLeft({ channel }));
  };

  const channelRemoved = async (channel) => {
    debugLogger(ChatEvents.channelRemoved);
    dispatch(handleChannelRemoved({ channel }));
  };

  const channelUpdated = async ({ channel, updateReasons }) => {
    debugLogger(
      ChatEvents.channelUpdated,
      channel.id,
      channel.updatedAt,
      updateReasons,
    );
    dispatch(handleChannelUpdated({ channel }));
  };

  const messageAdded = async (message) => {
    debugLogger(ChatEvents.messageAdded, message);
    dispatch(addMessages({
      channelMessages: { [message.channelId]: [message] },
    }));
  };

  const messageUpdated = async ({ message, updateReasons }) => {
    debugLogger(ChatEvents.messageUpdated, message);
    dispatch(updateMessage({ message, updateReasons }));
  };

  const typingStarted = async (member) => {
    debugLogger(ChatEvents.typingStarted, member);
    dispatch(updateMember({ member }));
  };

  const typingEnded = async (member) => {
    debugLogger(ChatEvents.typingEnded, member);
    dispatch(updateMember({ member }));
  };


  const memberUpdated = async ({ member, updateReasons }) => {
    debugLogger(ChatEvents.memberUpdated, member, updateReasons);
    dispatch(updateMember({ member, updateReasons }));
  };

  chatClient.on(ChatEvents.currentUserUpdated, currentUserUpdated);

  // Implies the channel is no longer visible.
  // it has been unsubscribed to.
  chatClient.on(ChatEvents.channelRemoved, channelRemoved);

  // When a user is no longer in the channel. It might still be visible.
  chatClient.on(ChatEvents.channelLeft, channelLeft);

  chatClient.on(ChatEvents.channelUpdated, channelUpdated);
  chatClient.on(ChatEvents.memberUpdated, memberUpdated);
  chatClient.on(ChatEvents.memberLeft, memberLeft);
  chatClient.on(ChatEvents.memberJoined, memberJoined);
  chatClient.on(ChatEvents.messageAdded, messageAdded);
  chatClient.on(ChatEvents.messageUpdated, messageUpdated);
  chatClient.on(ChatEvents.typingStarted, typingStarted);
  chatClient.on(ChatEvents.typingEnded, typingEnded);
};

export const setupChatListenersAfterInit = () => async (dispatch, getState, { chatClient }) => {

  const channelJoined = (channel) => {
    debugLogger(ChatEvents.channelJoined);
    dispatch(handleChannelJoined({ channel }));
  };

  const userUpdated = async ({ user, updateReasons }) => {
    debugLogger(ChatEvents.userUpdated, user.id, updateReasons);
    dispatch(updateUser({ user }));
  };

  chatClient.on(ChatEvents.channelJoined, channelJoined);
  chatClient.on(ChatEvents.userUpdated, userUpdated);
};

export const setupFirebase = () => (dispatch, getState, { chatClient, firebaseClient }) => {
  const tokenUpdated = async (token) => {
    await chatClient.setPushRegistrationId('fcm', token);
  };
  const navigateToChat = (channelSid) => {
    const channelId = getChannelIdByExternalId(channelSid)(getState());
    dispatch(changeSelectedChannelId({ selectedChannelId: channelId }));
  };
  firebaseClient.init(tokenUpdated, navigateToChat);
};
