import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHouses } from 'gcs-common/slices/administration/houses/housesThunks';
import {
  getHouseTable,
  getHousesPaginationResult,
} from 'gcs-common/slices/administration/houses/housesSelectors';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import { HousesViews } from 'gcs-common/constants/views';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import detailsIcon from '../../../../img/arrow.svg';
import { SORT_ORDER } from '../../../../constants/sortConstants';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addCompanyIcon from '../../../../img/plus_white.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import HouseCreate from '../HouseCreate/HouseCreate';
import LozengeCell from '../../../Table/LozengeCell/LozengeCell';

const view = HousesViews.TABLE;
const columns = [
  {
    Header: 'Name',
    accessor: 'friendlyName', // TODO: Was changed!
  },
  {
    Header: 'LDA',
    accessor: 'ldaNumbers',
    Cell: (cell) => cell.value && cell.value.map(val => LozengeCell({
      value: val,
      color: '#808488',
    })),
  },
  {
    Header: 'Hausnummer',
    accessor: 'houseNumbers',
    Cell: (cell) => cell.value && cell.value.map(val => LozengeCell({
      value: val,
      color: '#808488',
    })),
  },
  {
    Header: 'Anzahl Firmen',
    accessor: 'companiesNumber',
  },
  {
    Header: 'Anzahl Mitarbeiter',
    accessor: 'usersNumber',
  },
];

const HouseTable = ({ onHouseSelected, housesLoading }) => {

  const houses = useSelector(getHouseTable);
  const housesPagination = useSelector(getHousesPaginationResult);
  const globalSearchInput = useSelector(getGlobalSearchInput);

  const dispatch = useDispatch();

  const [createHouse, setCreateHouse] = useState(false);
  const accessorToBeSorted = columns.find(element => element.Header).accessor;
  const [sortedColumnId, setSortedColumnId] = useState(accessorToBeSorted);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);
  const [reloadPage, setReloadPage] = useState(false);

  const loadHouses = useCallback((pageIndex, pageSize, searchQuery) => {
    dispatch(fetchHouses({
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, view,
    }));
  }, [dispatch, sortedColumnId, sortOrder]);

  const houseIsCreatedSuccessfully = useCallback(() => {
    setCreateHouse(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
    setReloadPage(!reloadPage);
  }, [dispatch, reloadPage]);

  return (
    <>
      <PaginatedTable
        onRowSelected={onHouseSelected}
        data={houses}
        columns={columns}
        onPaginationChanged={loadHouses}
        pagination={housesPagination}
        searchInput={globalSearchInput}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortedColumnId={sortedColumnId}
        setSortedColumnId={setSortedColumnId}
        sortIsLoading={housesLoading}
        renderActions={() => (
          <img
            alt="details-icon"
            src={detailsIcon}
          />
        )}
        reloadPage={reloadPage}
      />
      <AddFabButton
        icon={addCompanyIcon}
        title="Haus"
        onClick={() => setCreateHouse(true)}
      />
      <PopUpModal
        title="Neues Haus"
        onRequestClose={() => setCreateHouse(false)}
        isOpen={createHouse}
      >
        <HouseCreate
          onCancel={() => setCreateHouse(false)}
          onSuccess={houseIsCreatedSuccessfully}
        />
      </PopUpModal>
    </>
  );
};

HouseTable.propTypes = {
  onHouseSelected: PropTypes.func.isRequired,
  housesLoading: PropTypes.bool.isRequired,
};

export default HouseTable;
