/* eslint-disable css-modules/no-unused-class */

import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Button = ({
  typeButton = true, className = 'primary', onClick = null, text, disabled = false,
}) => {
  return (
    <button
      type={typeButton ? 'button' : 'submit'}
      className={styles[className]}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  typeButton: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Button;
