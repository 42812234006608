import { createAsyncThunk } from '@reduxjs/toolkit';

const updateChannel = createAsyncThunk(
  'channels/updateChannel',
  async ({ channelId, values }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateChatChannel(channelId, values);

    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }

    return { channel: response };
  },
);

export default updateChannel;
