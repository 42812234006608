import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAgent, getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getSidebarCollapsed } from 'gcs-common/slices/uiState/uiStateSelectors';
import {
  setLeftBarTab,
  setRightBarTab,
  setSidebarCollapsed,
} from 'gcs-common/slices/uiState/uiStateSlice';
import { LEFT_BAR_TABS, RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { ICON, ICON_COLOR, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { ETS_ROUTES } from 'gcs-common/constants/routes';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { useNavigate } from 'react-router';
import { getTicketNotificationsCount } from 'gcs-common/slices/tickets/ticketsSelectors';
import IconComponent from '../IconComponent/IconComponent';
import styles from './styles.module.scss';
import AnimatedSidebarButton from '../AnimatedSidebarButton/AnimatedSidebarButton';
import PermissionProtectedRoute from '../ProtectedRoutes/PermissionProtectedRoute';
import NotificationBadge from '../Tools/NotificationBadge/NotificationBadge';

function SidebarSettings() {
  const permission = ['administration', 'global_admin'];
  const isAgent = useSelector(getIsAgent);
  const sidebarCollapsed = useSelector(getSidebarCollapsed);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const etsDashboardEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.ETS_DASHBOARD_ENABLED));
  const etsDashboardNotificationsCount = useSelector(getTicketNotificationsCount());
  const userManagementDisabled = useSelector(
    getHasFeatureFlag(FEATURE_FLAGS.USER_MANAGEMENT_DISABLED),
  );

  const openSidebar = useCallback(() => {
    dispatch(setSidebarCollapsed({ sidebarCollapsed: false }));
  }, [dispatch]);
  const closeSidebar = useCallback(() => {
    dispatch(setSidebarCollapsed({ sidebarCollapsed: true }));
  }, [dispatch]);
  const selectAbsence = useCallback(() => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.ABSENCE }));
  }, [dispatch]);
  const openSettings = useCallback(() => {
    dispatch(setLeftBarTab({ leftBarTab: LEFT_BAR_TABS.PROFILE }));
  }, [dispatch]);
  const onShowAdministration = () => {
    if (userManagementDisabled) {
      dispatch(openErrorDialog({
        dialogErrorTitle: 'Nutzermanagement derzeit nicht verfügbar',
        dialogErrorMsg: 'Das Nutzermanagement ist derzeit wegen Wartungsarbeiten vorübergehend nicht verfügbar.',
      }));
    } else {
      navigate('/administration/erpCustomers');
    }
  };

  const onShowTicketDashboard = () => {
    navigate(ETS_ROUTES.DASHBOARD);
  };

  return (
    <div
      className={isAgent ? styles.optionWrapper : styles.optionWrapperCraftsman}
      style={
        { flexDirection: sidebarCollapsed ? 'column' : 'row' }}
    >
      <AnimatedSidebarButton className={styles.settingsButton} onClick={openSettings} title="Einstellungen">
        <IconComponent Icon={ICON.SETTINGS} alt="Einstellungen" />
      </AnimatedSidebarButton>
      {isAgent && (
        <AnimatedSidebarButton className={styles.settingsButton} onClick={selectAbsence} title="Kalender">
          <IconComponent Icon={ICON.CALENDAR} alt="Kalender" />
        </AnimatedSidebarButton>
      )}
      {isAgent && (
        <PermissionProtectedRoute permission={permission}>
          <AnimatedSidebarButton className={styles.settingsButton} onClick={onShowAdministration} title="Nutzermanagement">
            <IconComponent Icon={ICON.USER_SHADE} color={ICON_COLOR.DARK_GREY} alt="Nutzermanagement" />
          </AnimatedSidebarButton>
        </PermissionProtectedRoute>
      )}
      {isAgent && etsDashboardEnabled && (
        <AnimatedSidebarButton className={styles.settingsButton} onClick={onShowTicketDashboard} title="ETS Dashboard">
          <IconComponent Icon={ICON.DASHBOARD} alt="ETS Dashboard" color={ICON_COLOR.DARK_GREY} />
          {!!etsDashboardNotificationsCount
            && <NotificationBadge count={etsDashboardNotificationsCount} /> }
        </AnimatedSidebarButton>
      )}
      {sidebarCollapsed ? (
        <AnimatedSidebarButton className={styles.settingsButton} onClick={openSidebar} title="Ausklappen">
          <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.NONE} alt="Ausklappen" />
        </AnimatedSidebarButton>
      ) : (
        <AnimatedSidebarButton className={styles.settingsButton} onClick={closeSidebar} title="Einklappen">
          <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.ROTATE_180} alt="Einklappen" />
        </AnimatedSidebarButton>
      )}
    </div>

  );
}

SidebarSettings.propTypes = {};

export default SidebarSettings;
