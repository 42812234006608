import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchMessageError, getMessageLoaded } from 'gcs-common/slices/messages/messageSelector';
import fetchMessage from 'gcs-common/slices/messages/messagesThunks/fetchMessage';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';


function AsyncMessageLoader({ messageIndex, channelId, messageId, children }) {

  const isMessageLoaded = useSelector(getMessageLoaded(messageId));
  const error = useSelector(getFetchMessageError(channelId, messageIndex));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMessageLoaded && messageId) {
      dispatch(fetchMessage({ messageId }));
    }
  }, [dispatch, isMessageLoaded, messageId]);

  if (!isMessageLoaded) {
    if (error) {
      return <div>Fehler beim laden der Nachricht</div>;
    }
    return <LoadingIndicator padding="0" />;
  }
  return children;
}

export default AsyncMessageLoader;

AsyncMessageLoader.propTypes = {
  channelId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  messageIndex: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};
