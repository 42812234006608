import { FilePonyfill } from '@tanker/file-ponyfill';
import preloadImage from './preloadImage';
import { createFileURL, removeFileURL } from './fileUrlHelper';
import { FILE_TYPE_MAPPINGS, FILE_TYPE, FILE_SUB_TYPE_MAPPINGS, FILE_SUB_TYPE } from '../constants/fileTypes';

export const isImage = (file: File) => {
  const { fileExtensions } = FILE_TYPE_MAPPINGS[FILE_TYPE.IMAGE];
  return file && file.type && fileExtensions.includes(file.type.toLowerCase());
};

export const isPdf = (file: File) => {
  const { fileExtensions } = FILE_TYPE_MAPPINGS[FILE_TYPE.PDF];
  return file && file.type && fileExtensions.includes(file.type);
};

export const isVideo = (file: File) => {
  const { fileExtensions } = FILE_TYPE_MAPPINGS[FILE_TYPE.VIDEO];
  return file && file.type && fileExtensions.includes(file.type);
};

export const getFileType = (file: File) => {
  if (isImage(file)) {
    return FILE_TYPE.IMAGE;
  }
  if (isPdf(file)) {
    return FILE_TYPE.PDF;
  }
  if (isVideo(file)) {
    return FILE_TYPE.VIDEO;
  }
  return FILE_TYPE.DEFAULT;
};

export const calculateImageDimensions = async (imageFile: File) => {
  // @ts-expect-error TODO refactor
  const imageUrl = imageFile instanceof File ? createFileURL(imageFile) : imageFile.webPath;
  const preloadedImage = await preloadImage(imageUrl);
  removeFileURL(imageUrl);
  let { naturalHeight: h, naturalWidth: w } = preloadedImage.target;
  if (imageFile.type === 'image/svg+xml' && h === 0 && w === 0) { // Special to Firefox
    h = 150; w = 150;
  }
  return { h, w };
};

export const createFileFromUrl = async (url: string,
  name: string, type: string | undefined): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new FilePonyfill([blob], name, { type });
};

export const convertBase64ToFile = (fileString: string, filename: string, mimeType: string) => {
  // Convert base64 string to byte array
  const byteCharacters = atob(fileString);
  const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob object from the byte array
  const blob = new Blob([byteArray.buffer], { type: mimeType });

  // Create a File object from the Blob
  return new File([blob], filename, { type: mimeType });
};

export const getFileExtension = (fname: string): string => {
  if (fname.includes('/')) {
    return fname.split('/')[1]!;
  }
  // @ts-ignore
  // eslint-disable-next-line no-bitwise
  return fname.includes('.') && fname.slice((fname.lastIndexOf('.') - 1 >>> 0) + 2);
};

export const replaceAsteriskToExtensionInFileType = (fileName: string, fileType: string) => {
  if (fileType.split('/')[1] === '*') {
    const fileExtension = getFileExtension(fileName);
    return fileType.replace('*', fileExtension.toLowerCase());
  }
  return fileType;
};

export const replaceWithAllowedFileType = (fileType: string) => {
  if (typeof fileType !== 'string' || !fileType.includes('/')) {
    throw new Error('Invalid file type');
  }
  const splitedFileType = fileType.split('/');
  const type = splitedFileType[0];
  const subType = splitedFileType[1]!;
  const { fileExtensions: imageFileExtensions } = FILE_SUB_TYPE_MAPPINGS[FILE_SUB_TYPE.IMAGE];
  if (type !== 'image' && imageFileExtensions.includes(subType.toLowerCase())) {
    return `image/${subType.toLowerCase()}`;
  }
  const { fileExtensions: videoFileExtensions } = FILE_SUB_TYPE_MAPPINGS[FILE_SUB_TYPE.VIDEO];
  if (type !== 'video' && videoFileExtensions.includes(subType.toLowerCase())) {
    return `video/${subType.toLowerCase()}`;
  }
  if (type === 'video' && subType.toLowerCase() === 'mov') {
    return 'video/quicktime';
  }

  return fileType;
};


/**
 * Decodes the values of an object
 * e.g.
 * - from 'file%3A%2F%2F%2Fprivate%2Fvar%2Fmobile%2FContainers%2FShared%2FAppGroup%2Fapp%2Ffile.JPG'
 * - to 'file:///private/var/mobile/Containers/Shared/AppGroup/app/file.JPG'
 */
export const decodeObjectValues = (obj: { [key: string]: string | number; }) => {
  const decodedObj: { [key: string]: string | number; } = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === 'string') {
        decodedObj[key] = decodeURIComponent(value);
      } else {
        // Keep other types of values unchanged
        // @ts-ignore
        decodedObj[key] = value;
      }
    }
  }

  return decodedObj;
};
