import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getTeams, getTeamsLoading } from 'gcs-common/slices/administration/teams/teamsSelectors';
import { fetchTeams } from 'gcs-common/slices/administration/teams/teamsThunks';
import FormikSelect from '../../../FormikSelect/FormikSelect';

const TeamSelect = ({
  name,
  placeholder = 'Suchen...',
  labelKey,
  valueKey,
  isMulti = false,
  onChange,
  localFilter,
  ...props
}) => {

  const teams = useSelector(getTeams);
  const teamsLoading = useSelector(getTeamsLoading);

  const dispatch = useDispatch();

  const fetchTeamsPage = useCallback((searchQuery) => {
    dispatch(fetchTeams({
      pageIndex: 0,
      pageSize: 10,
      searchQuery,
    }));
  }, [dispatch]);

  return (
    <FormikSelect
      name={name}
      placeholder={placeholder}
      labelKey={labelKey}
      valueKey={valueKey}
      isMulti={isMulti}
      onChange={onChange}
      options={teams}
      isLoading={teamsLoading}
      onInputChange={fetchTeamsPage}
      localFilter={localFilter}
      {...props}
    />
  );
};

TeamSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  localFilter: PropTypes.arrayOf(PropTypes.shape),
};

export default TeamSelect;
