import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSelectedChannelId } from '../../channels/channelsSelectors';
import { getChannelOldestLoadedMessageIndex, getLoadPreviousMessagesIsLoading } from '../messagesSelectors';
// eslint-disable-next-line import/no-cycle
import { getChannelHasMoreMessages } from '../../channel/channelSelectors';
// eslint-disable-next-line import/no-cycle
import fetchMessages from './fetchMessages';

const loadPreviousMessages = createAsyncThunk(
  'messages/loadPreviousMessages',
  async (_, { dispatch, getState }) => {
    const selectedChannelId = getSelectedChannelId(getState());
    const oldestMessageIndex = getChannelOldestLoadedMessageIndex(selectedChannelId)(getState());

    await dispatch(fetchMessages({
      channelId: selectedChannelId,
      fromMessageIndex: oldestMessageIndex,
      singlePage: true,
    }));
  },
  {
    condition: (_, { getState }) => {
      const selectedChannelId = getSelectedChannelId(getState());
      return !getLoadPreviousMessagesIsLoading(getState())
        && getChannelHasMoreMessages(selectedChannelId)(getState());
    },
  },
);

export default loadPreviousMessages;
