import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import { fetchBusinessUnits } from 'gcs-common/slices/administration/businessUnits/businessUnitsThunks';
import {
  getBusinessUnits,
  getBusinessUnitsPaginationResult,
} from 'gcs-common/slices/administration/businessUnits/businessUnitsSelectors';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import detailsIcon from '../../../../img/arrow.svg';
import deletedIcon from '../../../../img/delete.svg';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addCompanyIcon from '../../../../img/plus_white.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import BusinessUnitDelete from '../BusinessUnitDelete/BusinessUnitDelete';
import BusinessUnitCreate from '../BusinessUnitCreate/BusinessUnitCreate';
import { SORT_ORDER } from '../../../../constants/sortConstants';

const columns = [
  {
    Header: 'Name',
    accessor: 'friendlyName',
  },
];

const BusinessUnitTable = ({
  onBusinessUnitSelected, businessUnitsLoading,
}) => {

  const businessUnits = useSelector(getBusinessUnits);
  const businessUnitsPagination = useSelector(getBusinessUnitsPaginationResult);
  const globalSearchInput = useSelector(getGlobalSearchInput);

  const dispatch = useDispatch();

  const [createBusinessUnit, setCreateBusinessUnit] = useState(false);
  const [deleteBusinessUnit, setDeleteBusinessUnit] = useState(null);

  const accessorToBeSorted = columns.find(element => element.Header).accessor;
  const [sortedColumnId, setSortedColumnId] = useState(accessorToBeSorted);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);

  const loadBusinessUnits = useCallback((pageIndex, pageSize, searchQuery) => {
    dispatch(fetchBusinessUnits({
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
    }));
  }, [dispatch, sortOrder, sortedColumnId]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setDeleteBusinessUnit(original);
    },
    [],
  );

  const businessUnitIsDeletedSuccessfully = useCallback(() => {
    setDeleteBusinessUnit(null);
    dispatch(showSuccessNotification('Erfolgreich entfernt'));
  }, [dispatch]);

  const businessUnitIsCreatedSuccessfully = useCallback(() => {
    setCreateBusinessUnit(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  return (
    <>
      <PaginatedTable
        onRowSelected={onBusinessUnitSelected}
        data={businessUnits}
        columns={columns}
        onPaginationChanged={loadBusinessUnits}
        searchInput={globalSearchInput}
        pagination={businessUnitsPagination}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortedColumnId={sortedColumnId}
        setSortedColumnId={setSortedColumnId}
        sortIsLoading={businessUnitsLoading}
        renderActions={({ row }) => (
          <>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              alt="deleted-icon"
              src={deletedIcon}
              onClick={(event) => deleteSelected(event, row)}
            />
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      <AddFabButton
        icon={addCompanyIcon}
        title="Business Unit"
        onClick={() => setCreateBusinessUnit(true)}
      />
      <PopUpModal
        title="Neue Business Unit"
        onRequestClose={() => setCreateBusinessUnit(false)}
        isOpen={createBusinessUnit}
      >
        <BusinessUnitCreate
          onCancel={() => setCreateBusinessUnit(false)}
          onSuccess={businessUnitIsCreatedSuccessfully}
        />
      </PopUpModal>
      {deleteBusinessUnit && (
        <PopUpModal
          title="Business Unit löschen"
          onRequestClose={() => setDeleteBusinessUnit(null)}
          isOpen={!!deleteBusinessUnit}
        >
          <BusinessUnitDelete
            businessUnit={deleteBusinessUnit}
            onCancel={() => setDeleteBusinessUnit(null)}
            onSuccess={businessUnitIsDeletedSuccessfully}
          />
        </PopUpModal>
      )}
    </>
  );
};

BusinessUnitTable.propTypes = {
  onBusinessUnitSelected: PropTypes.func.isRequired,
  businessUnitsLoading: PropTypes.bool.isRequired,
};

export default BusinessUnitTable;
