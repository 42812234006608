import { useDispatch, useSelector } from 'react-redux';
import { ticketIdSelected } from 'gcs-common/slices/tickets/ticketsSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getTicketNotification } from 'gcs-common/slices/tickets/ticketsSelectors';
import { friendlyDate } from 'gcs-common/helper/dateHelper';
import NotificationBadge from 'gcs-common/components/Notifications/NotificationBadge/NotificationBadge';
import styles from './styles.module.scss';
import TicketStatus from '../../TicketStatus';

interface TicketCardProps {
  id: string;
  title: string;
  status: string;
  createdAt: string;
}

const TicketCard = (props: TicketCardProps) => {
  const { id, status, title, createdAt } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const channelId = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ticketNotification = useSelector(getTicketNotification(id));

  const selectTicket = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dispatch(ticketIdSelected({ ticketId: id, channelId }));
  };

  return (
    <div role="button" tabIndex={-1} title={title} onClick={selectTicket} className={styles.ticket}>
      <div className={styles.ticketDetailLeft}>
        <span>Ticket Titel</span>
        <span>Erstelldatum</span>
        <span>
          {ticketNotification
            ? <NotificationBadge />
            : <div className={styles.status}> ETS </div>}
        </span>
      </div>
      <div className={styles.ticketDetailRight}>
        <span className={styles.title} title={title}>
          {title}
        </span>
        <span>
          {friendlyDate(createdAt)}
        </span>
        <TicketStatus status={status} />
      </div>
    </div>
  );

};

export default TicketCard;
