import { useDispatch, useSelector } from 'react-redux';
import {
  getHousesLoading,
  getHousesPaginationResult,
} from 'gcs-common/slices/administration/houses/housesSelectors';
import { houseSelected } from 'gcs-common/slices/administration/houses/housesSlice';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import HouseTable from '../HouseTable/HouseTable';

const HouseOverview = () => {

  const housesLoading = useSelector(getHousesLoading);
  const housesPagination = useSelector(getHousesPaginationResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onHouseSelected = ({ id }) => {
    dispatch(houseSelected({ houseId: id }));
    navigate(`/administration/houses/${id}`);
  };

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.inputHeaderText}>
          Häuser
          {' '}
          (
          {housesPagination && housesPagination.total}
          )
        </div>
        {housesLoading && <LoadingIndicator padding="0" />}
      </div>
      <HouseTable
        onHouseSelected={onHouseSelected}
        housesLoading={housesLoading}
      />
    </>
  );
};

export default HouseOverview;
