import PropTypes from 'prop-types';
import IconComponent from '../Icon/Icon';
import Toggle from '../Toggle/Toggle';
import styles from './styles.module.scss';


const MemberPermissionsItem = ({
  title,
  Icon,
  toggleEnabled = false,
  onToggle,
  testIdAttribute = '',
  disabled = false,
}) => {
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.detailItem}>
        <IconComponent Icon={Icon} style={{ padding: '4px' }} />
        <div className={styles.detailName}>{title}</div>
        <Toggle
          onClick={onToggle}
          isToggled={toggleEnabled}
          testIdAttribute={testIdAttribute}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

MemberPermissionsItem.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  toggleEnabled: PropTypes.bool,
  onToggle: PropTypes.func,
  testIdAttribute: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MemberPermissionsItem;
