import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getNumOpenTasksForChannel } from 'gcs-common/slices/channel/channelSelectors';
import { getTicketNotificationsCountForChannel } from 'gcs-common/slices/tickets/ticketsSelectors';
import { TOOL_TABS } from 'gcs-common/constants/toolUIFlags';
import { getCurrentToolBarTab, getFilteredTabs } from 'gcs-common/slices/uiState/uiStateSelectors';
import { setToolbarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import QuickOrdering from 'gcs-common/components/QuickOrdering/QuickOrdering';
import IconComponent from '../IconComponent/IconComponent';
import TaskList from '../TaskList/TaskList';
import FileList from '../FilesList/FileList';
import styles from './styles.module.scss';
import ProcessSelect from '../ProcessSelect/ProcessSelect';
import FavoriteList from '../FavoriteList/FavoriteList';
import Search from '../Search/Search';
import Tickets from '../Tickets/Tickets';
import PushWarning from './PushWarning/PushWarning';
import UnannouncedLogin from './UnannouncedLogin/UnannouncedLogin';
import NotificationBadge from './NotificationBadge/NotificationBadge';

const Tools = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const isAgent = useSelector(getIsAgent);
  const currentTabSelection = useSelector(getCurrentToolBarTab(selectedChannelId));
  const tabs = useSelector(getFilteredTabs(selectedChannelId));
  const numOpenTasks = useSelector(getNumOpenTasksForChannel(selectedChannelId));
  const ticketNotificationsCount = useSelector(
    getTicketNotificationsCountForChannel(selectedChannelId),
  );

  const dispatch = useDispatch();

  const setCurrentTabSelection = useCallback((tab) => {
    dispatch(setToolbarTab({ tab }));
  }, [dispatch]);

  const tabContent = useMemo(() => {
    switch (currentTabSelection) {
      default:
        return <FavoriteList />;
      case TOOL_TABS.TODOS:
        return <TaskList />;
      case TOOL_TABS.FILES:
        return <FileList />;
      case TOOL_TABS.SEARCH:
        return <Search />;
      case TOOL_TABS.GCOP:
        return <ProcessSelect />;
      case TOOL_TABS.TICKETS:
        return <Tickets />;
      case TOOL_TABS.ORDERS:
        return <QuickOrdering />;
    }
  }, [currentTabSelection]);

  const toolToRender = useMemo(() => {
    return tabs.map(tool => {
      const { name, altIcon } = tool;
      const selected = currentTabSelection === name;

      let iconName;
      let iconTitle;
      let notificationCount;
      switch (name) {
        default:
          iconName = ICON.STAR;
          iconTitle = 'Favoriten';
          break;
        case TOOL_TABS.TODOS:
          iconName = ICON.TODO_LIST;
          iconTitle = 'To-Dos';
          notificationCount = numOpenTasks;
          break;
        case TOOL_TABS.FILES:
          iconName = ICON.ATTACHMENT;
          iconTitle = 'Medien';
          break;
        case TOOL_TABS.SEARCH:
          iconName = ICON.SEARCH;
          iconTitle = 'Suche';
          break;
        case TOOL_TABS.GCOP:
          iconName = ICON.CUBE;
          iconTitle = 'ONLINE PLUS Vorgänge';
          break;
        case TOOL_TABS.TICKETS:
          iconName = ICON.ETS;
          iconTitle = 'ETS Tickets';
          notificationCount = ticketNotificationsCount;
          break;
        case TOOL_TABS.ORDERS:
          iconName = ICON.QUICKORDERING;
          iconTitle = 'Schnellbestellungen';
          break;
      }
      const style = selected ? styles.selected : styles.unselected;
      const iconColor = selected ? ICON_COLOR.WHITE : ICON_COLOR.DARK_GREY;

      return (
        <button
          key={name}
          className={`${styles.iconButton} ${style}`}
          type="button"
          onClick={() => setCurrentTabSelection(name)}
          alt={altIcon}
          title={iconTitle}
        >
          {!!notificationCount && <NotificationBadge count={notificationCount} />}

          <IconComponent Icon={iconName} color={iconColor} alt={iconTitle} />
        </button>
      );
    });
  }, [tabs, currentTabSelection, setCurrentTabSelection, ticketNotificationsCount, numOpenTasks]);

  return (
    <div className={styles.tools}>
      <div className={styles.toolsBanner}>
        {toolToRender}
      </div>
      <PushWarning />
      {isAgent && <UnannouncedLogin />}
      {tabContent}
    </div>
  );

};

export default Tools;
