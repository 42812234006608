import { useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  createErpCustomerUser, fetchErpCustomerUsers,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import {
  getCreateErpCustomerUserErrorMessage,
  getCreateErpCustomerUserDefaultErrorMessage,
  getSelectedErpCustomer,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import {
  resetCreateErpCustomerUserErrorMessage,
  resetCreateErpCustomerUserDefaultErrorMessage,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import { PHONE_OR_MAIL_REGEX, MAIL_REGEX } from 'gcs-common/constants/formValidation';
import FormikInput from '../../../FormikInput/FormikInput';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const UserCreateValidationSchema = Yup.object().shape({
  name: Yup.string().nullable().required('Erforderlich'),
  phoneOrEmail: Yup.string().nullable().required('Erforderlich').matches(PHONE_OR_MAIL_REGEX, 'Bitte gib eine gültige Mobilnummer oder E-Mail-Adresse ein.'),
});

const ErpCustomerUserCreate = ({ erpCustomerId, onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateErpCustomerUserErrorMessage);
  const defaultErrorMessage = useSelector(getCreateErpCustomerUserDefaultErrorMessage);
  const selectedErpCustomer = useSelector(getSelectedErpCustomer(erpCustomerId));

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetCreateErpCustomerUserDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateErpCustomerUserErrorMessage());
    dispatch(resetCreateErpCustomerUserDefaultErrorMessage());
  }, [dispatch]);

  const onEmailorPhoneChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateErpCustomerUserErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    if (MAIL_REGEX.test(values.phoneOrEmail)) {
      // eslint-disable-next-line no-param-reassign
      values.email = values.phoneOrEmail;
    } else {
      // eslint-disable-next-line no-param-reassign
      values.phoneNumber = values.phoneOrEmail.replace(/^(0049|0)/, '+49');
    }
    // eslint-disable-next-line no-param-reassign
    delete values.phoneOrEmail;
    const result = await dispatch(createErpCustomerUser({ user: values, erpCustomerId }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
      await dispatch(fetchErpCustomerUsers({ erpCustomerId }));
    }
  }, [dispatch, onSuccess, erpCustomerId]);

  const initialData = {
    erpCustomerName: selectedErpCustomer?.friendlyName,
    customerNumber: selectedErpCustomer?.customerNumber,
    name: undefined,
    phoneOrEmail: undefined,
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={UserCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>

          <div className={styles.row}>
            <FormikInput label="Firmename" name="erpCustomerName" disabled />
            <FormikInput label="Kundennummer" name="customerNumber" disabled />
          </div>

          <div className={styles.row}>
            <FormikInput label="Name*" type="text" name="name" />
          </div>

          <div className={styles.row}>
            <FormikInput label="Mobilnummer oder email-adresse*" type="text" name="phoneOrEmail" onKeyUp={onEmailorPhoneChanged} />
          </div>

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
ErpCustomerUserCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  erpCustomerId: PropTypes.string.isRequired,
};

export default ErpCustomerUserCreate;
