import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchMessage = createAsyncThunk(
  'messages/fetch',
  async ({ messageId }, { extra: { gccApiClient } }) => {
    const message = await gccApiClient.getMessage(messageId);
    if (!message) {
      // It could happen that a message which has a reply message is deleted from twilio
      // if the message is a media message and the sender is completely deleted
      return { message: { id: messageId, deleted: true } };
    }
    return { message };
  },
);

export default fetchMessage;
