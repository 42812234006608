import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getSidebarCollapsed } from 'gcs-common/slices/uiState/uiStateSelectors';
import AgentChannelListWrapper from '../AgentChannelList/AgentChannelListWrapper/AgentChannelListWrapper';
import CraftsmanChannelList from '../CraftsmanChannelList/CraftsmanChannelList';
import styles from './styles.module.scss';
import NavBar from '../NavBar/NavBar';
import SidebarSettings from '../SidebarSettings/SidebarSettings';

const Sidebar = () => {

  const isAgent = useSelector(getIsAgent);
  const sidebarCollapsed = useSelector(getSidebarCollapsed);

  const wrapperStyle = useMemo(() => {
    return sidebarCollapsed ? {} : { width: '390px' };
  }, [sidebarCollapsed]);
  return (
    <div className={styles.sidebarWrapper} style={wrapperStyle}>
      <NavBar />
      <div className={isAgent ? styles.rootSeparator : styles.rootSeparatorLight} />
      {isAgent ? (
        <AgentChannelListWrapper />
      ) : (
        <CraftsmanChannelList />
      )}
      <SidebarSettings />
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {};
