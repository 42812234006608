/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { byId } from '../../helper/byKey';
import fetchJoinedHouses from './housesThunks/fetchJoinedHouses';
import ASYNC_STATE from '../../constants/AsyncState';

const asyncState = {
  fetchJoinedHouses: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  houses: null,
  joinedHousesIds: null,
  selectedHouseId: null,
};

const persistWhitelist = [
  'houses',
  'joinedHousesIds',
  'selectedHouseId',
];

const userHousesSlice = createSlice({
  name: 'userHouses',
  initialState,
  reducers: {
    selectedHouseIdChanged: (state, action) => {
      const { selectedHouseId } = action.payload;
      state.selectedHouseId = selectedHouseId;

      return state;
    },
  },
  extraReducers: {
    [fetchJoinedHouses.pending]: (state) => {
      state.fetchJoinedHouses.status = ASYNC_STATE.LOADING;
    },
    [fetchJoinedHouses.fulfilled]: (state, action) => {
      const { joinedHouses } = action.payload;

      if (joinedHouses) {
        const joinedHousesIds = joinedHouses.map((house) => house.id);
        state.fetchJoinedHouses.status = ASYNC_STATE.SUCCEEDED;

        state.houses = byId(joinedHouses);
        state.joinedHousesIds = joinedHousesIds;

        const firstHouseId = joinedHousesIds?.[0];

        // Select the first house when fetching them for the first time
        // so the user can see channels without having to select one
        if (state.selectedHouseId === null && joinedHouses.length > 0) {
          state.selectedHouseId = firstHouseId;
        }

        // Select the first house if the selected one doesn't exist anymore
        if (!(joinedHousesIds.includes(state.selectedHouseId))) {
          state.selectedHouseId = firstHouseId;
        }
      }
    },
    [fetchJoinedHouses.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchJoinedHouses.status = ASYNC_STATE.FAILED;
      state.fetchJoinedHouses.error = error;
    },
  },
});

export { persistWhitelist as userHousesPersistWhitelist };

export const {
  selectedHouseIdChanged,
} = userHousesSlice.actions;

export default userHousesSlice.reducer;
