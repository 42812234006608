/* eslint-disable */

/**
 * formats a string by replacing all %s with arguments
 * Example:
 *
 *  formatString("Hello %s", "World")
 *   => Hello World
 */
export function formatString() {
  let a, b, c;
  a = arguments[0];
  b = [];
  for (c = 1; c < arguments.length; c++) {
    b.push(arguments[c]);
  }
  for (c in b) {
    a = a.replace(/%[a-z]/, b[c]);
  }
  return a;
}

/* eslint-enable */
