import { createSelector } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../constants/AsyncState';
import OP_CONNECTION_STATUS from '../../constants/OpConnectionStatus';

const getCraftsmanConnectionsState = state => state.craftsmanConnections;

// Craftsman Connections

export const getCraftsmanConnections = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => {
    return craftsmanConnectionsState.craftsmanConnections;
  },
);

export const getCraftsmanConnectionsArray = createSelector(
  getCraftsmanConnections,
  (craftsmanConnections) => {
    return Object.values(craftsmanConnections);
  },
);

export const hasConnections = createSelector(
  getCraftsmanConnectionsArray,
  (craftsmanConnectionsArray) => {
    return craftsmanConnectionsArray && craftsmanConnectionsArray.length > 0;
  },
);

export const fetchCraftsmanConnectionsLoading = state => (
  state.currentCraftsmanUser.fetchCraftsmanConnections.status === ASYNC_STATE.LOADING);

export const getCraftsmanConnection = id => createSelector(
  getCraftsmanConnections,
  (craftsmanConnections) => {
    return id ? craftsmanConnections[id] : null;
  },
);

export const getConnectionIdsForHouse = houseId => createSelector(
  getCraftsmanConnectionsArray,
  (craftsmanConnections) => {
    return craftsmanConnections.reduce((red, connection) => {
      if (connection.connection.houseId === houseId) {
        red.push(connection.id);
      }
      return red;
    }, []);
  },
);

export const getIsManualCraftsmanConnection = id => createSelector(
  getCraftsmanConnection(id),
  (craftsmanConnection) => {
    return craftsmanConnection?.opAccount == null;
  },
);

export const getUpdateCraftsmanConnectionToCustomerLoading = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => (
    craftsmanConnectionsState.updateCraftsmanConnectionToCustomer.status === ASYNC_STATE.LOADING),
);

export const getUpdateCraftsmanConnectionToCustomerFailed = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => (
    craftsmanConnectionsState.updateCraftsmanConnectionToCustomer.status === ASYNC_STATE.FAILED),
);

export const getUpdateCraftsmanConnectionToCustomerError = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => (
    craftsmanConnectionsState.updateCraftsmanConnectionToCustomer.errorMessage),
);


export const getdeleteCraftsmanConnectionLoading = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => (
    craftsmanConnectionsState.deleteCraftsmanConnection.status === ASYNC_STATE.LOADING),
);

export const getdeleteCraftsmanConnectionFailed = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => (
    craftsmanConnectionsState.deleteCraftsmanConnection.status === ASYNC_STATE.FAILED),
);

export const getdeleteCraftsmanConnectionError = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => craftsmanConnectionsState.deleteCraftsmanConnection.errorMessage,
);

export const getAddCraftsmanConnectionLoading = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => (
    craftsmanConnectionsState.addCraftsmanConnection.status === ASYNC_STATE.LOADING),
);

export const getAddCraftsmanConnectionFailed = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => (
    craftsmanConnectionsState.addCraftsmanConnection.status === ASYNC_STATE.FAILED),
);

export const getAddCraftsmanConnectionError = createSelector(
  getCraftsmanConnectionsState,
  (craftsmanConnectionsState) => craftsmanConnectionsState.addCraftsmanConnection.errorMessage,
);

export const getCraftsmanConnectionStatus = (connectionId) => createSelector(
  getCraftsmanConnection(connectionId),
  (craftsmanConnection) => craftsmanConnection?.opAccount.lastOpConnectionStatus,
);


export const getOpConnectionDetails = (connectionId) => createSelector(
  getCraftsmanConnection(connectionId),
  (currentUserOpState) => currentUserOpState?.opAccount.lastOpConnectionStatus,
);

export const getAccountUsername = (connectionId) => createSelector(
  getCraftsmanConnection(connectionId),
  (currentUserOpState) => currentUserOpState?.opAccount.username,
);
export const isOpConnectionPermissionGranted = (connectionId, permission) => createSelector(
  getCraftsmanConnection(connectionId),
  (opConnection) => !!opConnection?.opAccount?.[permission],
);

export const getOpConnectionStatusText = (connectionId) => createSelector(
  getCraftsmanConnectionStatus(connectionId),
  (opConnectionStatus) => {
    switch (opConnectionStatus) {
      case OP_CONNECTION_STATUS.CONNECTED: {
        return 'Verbunden';
      }
      case OP_CONNECTION_STATUS.DISCONNECTED: {
        return 'Nicht verbunden';
      }
      case OP_CONNECTION_STATUS.UNREACHABLE: {
        return 'Verbindungsprobleme';
      }
      default: {
        return 'Nicht verbunden';
      }
    }
  },
);
