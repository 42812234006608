import { createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/named
import {
  currentPaginationEntities, toPaginationEntities,
  toPaginationPaginationResult,
} from '../../../helper/reduxPaginationHelper';
import ASYNC_STATE from '../../../constants/AsyncState';
import { toHasError, toIsLoading } from '../../../helper/reduxLoadingStatusHelper';


export const getAllAdministrationUsers = (state) => state.administrationUsers.administrationUsers;

const getAdministrationUserStateBySelectKey = selectKey => state => (
  state.administrationUsers[selectKey] || {}
);

export const getUsersBySelectKey = selectKey => createSelector(
  [getAdministrationUserStateBySelectKey(selectKey), getAllAdministrationUsers],
  toPaginationEntities,
);

export const getUsersLoadingBySelectKey = selectKey => createSelector(
  [getAdministrationUserStateBySelectKey(selectKey)],
  toIsLoading,
);


export const getUserDetailsStateById = userId => state => {
  return state.administrationUsers.fetchUserDetails[userId] || {};
};

export const getUserDetailsLoading = userId => createSelector(
  getUserDetailsStateById(userId),
  userDetailState => toIsLoading(userDetailState),
);

export const getUserDetailsError = userId => createSelector(
  getUserDetailsStateById(userId),
  userDetailState => toHasError(userDetailState),
);

// Agents Table
const getAgentsTableState = state => (
  state.administrationUsers.fetchAgentsTable || {}
);

export const getAgentsTableAgents = createSelector(
  [getAgentsTableState, getAllAdministrationUsers],
  currentPaginationEntities,
);

export const getAgentsTableAgentsLoading = createSelector(
  [getAgentsTableState],
  toIsLoading,
);

export const getAgentsTableAgentsPaginationResult = createSelector(
  [getAgentsTableState],
  toPaginationPaginationResult,
);

export const getAgentCompanies = (state) => state.administrationUsers.agentCompanies;

export const getAgentCompaniesLoading = (state) => (
  state.administrationUsers.fetchAgentCompanies.status === ASYNC_STATE.LOADING
);

export const getAgentDirectChannels = (state) => state.administrationUsers.agentDirectChannels;

export const getAgentDirectChannelsLoading = (state) => (
  state.administrationUsers.fetchAgentDirectChannels.status === ASYNC_STATE.LOADING
);

export const getAgentGroupChannels = (state) => state.administrationUsers.agentGroupChannels || [];

export const getAgentGroupChannelsLoading = (state) => (
  state.administrationUsers.fetchAgentGroupChannels.status === ASYNC_STATE.LOADING
);

export const getSelectedAgentId = (state) => state.administrationUsers.selectedAgentId;

export const getAllAgentAssignmentsCount = (state) => (
  state.administrationUsers.agentAssignmentsCount
);

export const getAgentAssignmentsCount = agentId => createSelector(
  getAllAgentAssignmentsCount,
  (assignments) => assignments[agentId] || 0,
);

export const getSelectedAgent = createSelector(
  [getSelectedAgentId, getAllAdministrationUsers],
  (agentId, users) => users[agentId],
);
export const getSelectedAgentLoading = (state) => (
  state.administrationUsers.fetchSelected.status === ASYNC_STATE.LOADING
);

export const getCreateAgentErrorMessage = (
  state,
) => state.administrationUsers.create.errorMessage;

export const getCreateAgentDefaultErrorMessage = (
  state,
) => state.administrationUsers.create.defaultErrorMessage;

export const getDeleteAgentLoading = (state) => (
  state.administrationUsers.deleteAgent.status === ASYNC_STATE.LOADING
);

export const getDeleteAgentDefaultErrorMessage = (
  state,
) => state.administrationUsers.deleteAgent.defaultErrorMessage;

export const getCreateAgentDirectChannelDefaultErrorMessage = (
  state,
) => state.administrationUsers.createDirectChannel.defaultErrorMessage;

export const getCreateAgentDirectChannelErrorMessage = (
  state,
) => state.administrationUsers.createDirectChannel.errorMessage;

export const getCreateAgentGroupChannelDefaultErrorMessage = (
  state,
) => state.administrationUsers.createGroupChannel.defaultErrorMessage;

export const getCreateAgentGroupChannelErrorMessage = (
  state,
) => state.administrationUsers.createGroupChannel.errorMessage;

export const getDisconnectAgentFromHouseDefaultErrorMessage = (
  state,
) => state.administrationUsers.disconnectAgentFromHouse.defaultErrorMessage;

export const getDisconnectAgentFromHouseLoading = (
  state,
) => state.administrationUsers.disconnectAgentFromHouse.status === ASYNC_STATE.LOADING;

export const fetchAgentAssignmentsCountLoading = (
  state,
) => state.administrationUsers.fetchAgentAssignmentsCount.status === ASYNC_STATE.LOADING;

export const getIsFetchSelectedAgentSucceeded = (state) => (
  state.administrationUsers.fetchSelected.status === ASYNC_STATE.SUCCEEDED);
