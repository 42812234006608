import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSelectedAdministrationChannel = createAsyncThunk(
  'administrationChannels/fetchSelected',
  async ({ channelId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getChannelDetail(channelId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { channel: response };
  },
);

export const updateSelectedChannelName = createAsyncThunk(
  'administrationChannels/updateSelected',
  async ({ channelId, channelName }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateChannel(channelId, channelName);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { success: response };
  },
);

export const removeUserFromGroupChannel = createAsyncThunk(
  'administrationChannels/removeUserFromGroupChannel',
  async ({ channelId, memberId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteChannelMember(channelId, memberId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { channel: response };
  },
);

export const deleteGroupChannel = createAsyncThunk(
  'administrationUsers/deleteGroupChannel',
  async ({ channelId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteChannel(channelId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return response;
  },
);

export const deleteDirectChannel = createAsyncThunk(
  'administrationUsers/deleteDirectChannel',
  async ({ channelId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteChannel(channelId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return response;
  },
);

export const addUserToGroupChannel = createAsyncThunk(
  'administrationChannels/addUser',
  async ({ channelId, userId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.createChannelMember(channelId, userId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { success: response };
  },
);
