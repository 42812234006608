import { useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  idValidatorRequired,
  stringValidator,
  houseNumbersValidator,
  ldaNumbersValidator,
  validatorRequired,
} from 'gcs-common/helper/yupValidationHelper';
import { createHouse } from 'gcs-common/slices/administration/houses/housesThunks';
import { getCreateHouseErrorMessage } from 'gcs-common/slices/administration/houses/housesSelectors';
import { resetCreateHouseErrorMessage } from 'gcs-common/slices/administration/houses/housesSlice';
import { fetchBusinessUnits } from 'gcs-common/slices/administration/businessUnits/businessUnitsThunks';
import { getBusinessUnits } from 'gcs-common/slices/administration/businessUnits/businessUnitsSelectors';
import styles from './styles.module.scss';
import FormikInput from '../../../FormikInput/FormikInput';
import FormikList from '../../../FormikList/FormikList';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import { HOLIDAY_CODES } from '../../../../constants/holidayCodes';

export const HouseCreateValidationSchema = Yup.object().shape({
  friendlyName: stringValidator,
  businessUnitId: idValidatorRequired,
  ldaNumbers: ldaNumbersValidator,
  houseNumbers: houseNumbersValidator,
  province: validatorRequired,
});

const initialData = {
  friendlyName: '',
  businessUnitId: undefined,
  ldaNumbers: [],
  houseNumbers: [],
  province: undefined,
};

const HouseCreate = ({ onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateHouseErrorMessage);
  const businessUnits = useSelector(getBusinessUnits);

  const holidaySelectOptions = HOLIDAY_CODES.map(h => {
    const { name, code } = h;
    return { value: code, label: name };
  });

  const onProvinceChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateHouseErrorMessage());
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetCreateHouseErrorMessage()),
      ));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    dispatch(resetCreateHouseErrorMessage());
  }, [dispatch]);

  const onHouseNameChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateHouseErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const onHouseNumberChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateHouseErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const onLDANumberChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateHouseErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const getBusinessUnitsPage = useCallback((searchQuery) => {
    dispatch(fetchBusinessUnits({
      pageIndex: 0,
      pageSize: 10,
      searchQuery,
    }));
  }, [dispatch]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(createHouse({ house: values }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={HouseCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>

          <div className={styles.row}>
            <FormikSelect
              options={businessUnits}
              onInputChange={getBusinessUnitsPage}
              label="Business Unit*"
              labelKey="friendlyName"
              valueKey="id"
              name="businessUnitId"
            />
          </div>

          <div className={styles.row}>
            <FormikInput label="Name*" name="friendlyName" onKeyUp={onHouseNameChanged} />
          </div>

          <div className={styles.row}>
            <FormikSelect
              label="Bundesland*"
              name="province"
              placeholder="Bitte wählen"
              options={holidaySelectOptions}
              labelKey="label"
              valueKey="value"
              onInputChange={onProvinceChanged}
            />
          </div>

          <div className={styles.row}>
            <FormikList label="Hausnummer*" name="houseNumbers" onKeyUp={onHouseNumberChanged} distinctValues />
          </div>
          <div className={styles.row}>
            <FormikList label="LDA*" name="ldaNumbers" onKeyUp={onLDANumberChanged} distinctValues />
          </div>

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

HouseCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default HouseCreate;
