import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetUpdatedTeamDefaultErrorMessage,
  resetUpdatedTeamErrorMessage,
  teamSelectionReset,
} from 'gcs-common/slices/administration/teams/teamsSlice';
import { fetchSelectedTeam, updateSelectedTeam } from 'gcs-common/slices/administration/teams/teamsThunks';
import {
  getSelectedTeam,
  getSelectedTeamLoading,
  getUpdateTeamErrorMessage,
  getIsFetchSelectedTeamSucceeded,
} from 'gcs-common/slices/administration/teams/teamsSelectors';
import {
  CustomerResponsibilityMemberTypes,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersConstants';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import styles from './styles.module.scss';
import backIcon from '../../../../img/back.svg';

import { TeamCreateValidationSchema } from '../TeamCreate/TeamCreate';
import TeamUserTable from '../TeamUserTable/TeamUserTable';
import FormikInput from '../../../FormikInput/FormikInput';
import CheckIcon from '../../../../img/check_green.svg?react';
import CancelIcon from '../../../../img/close_red.svg?react';
import EditIcon from '../../../../img/edit.svg?react';
import CustomerResponsibilityRoleSelect
  from '../../shared/CustomerResponsibilityRoleSelect/CustomerResponsibilityRoleSelect';
import teamIcon from '../../../../img/team-icon.svg';

const TeamDetail = () => {
  const { id: teamId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorMessage = useSelector(getUpdateTeamErrorMessage);
  const [teamNameIsEmptyErrorMessage, setTeamNameIsEmptyErrorMessage] = useState('');

  const [customerResponsibilityRoleName, setCustomerResponsibilityRoleName] = useState('');
  const roleNotSelectedText = 'Keine Beteiligtenrolle zugewiesen';

  useEffect(() => {
    dispatch(resetUpdatedTeamErrorMessage());
    dispatch(resetUpdatedTeamDefaultErrorMessage());
  }, [dispatch]);

  const selectedTeam = useSelector(getSelectedTeam);
  const selectedTeamLoading = useSelector(getSelectedTeamLoading);
  const isSelectedTeamLoaded = useSelector(getIsFetchSelectedTeamSucceeded);

  useEffect(() => {
    (async () => {
      await dispatch(fetchSelectedTeam({ teamId }));
    }
    )();
    return () => dispatch(teamSelectionReset());
  }, [dispatch, teamId]);

  const startEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const cancelEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(false);
  }, []);

  const resetErrorOnChange = useCallback(() => {
    if (errorMessage) {
      dispatch(resetUpdatedTeamErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const initialValues = useMemo(() => {
    if (selectedTeam) {
      return {
        name: selectedTeam.name,
        customerResponsibilityRoleId: (selectedTeam.customerResponsibilityRole
            && selectedTeam.customerResponsibilityRole.id),
        customerResponsibilityRoleName: (selectedTeam.customerResponsibilityRole
            && selectedTeam.customerResponsibilityRole.friendlyName),
      };
    }
    return {};
  }, [selectedTeam]);

  const submit = useCallback(async (values, { setSubmitting }) => {

    if (!values.name) {
      setTeamNameIsEmptyErrorMessage('Erforderlich');
    } else {
      setTeamNameIsEmptyErrorMessage('');
    }

    const result = await dispatch(updateSelectedTeam({ teamId, values }));
    setSubmitting(false);
    if (!result.error) {
      setIsEditing(false);
      if (result.payload.team.customerResponsibilityRole) {
        setCustomerResponsibilityRoleName(
          result.payload.team.customerResponsibilityRole.friendlyName,
        );
      } else {
        setCustomerResponsibilityRoleName(roleNotSelectedText);
      }
      dispatch(showSuccessNotification('Erfolgreich geändert'));
    }
  }, [dispatch, teamId]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={TeamCreateValidationSchema}
        onSubmit={submit}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <div className={styles.headerRow}>
              <div className={styles.row}>
                <button
                  className={styles.backButton}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  <img src={backIcon} alt="back-icon" />
                </button>
                <FormikInput
                  name="name"
                  className={styles.inputHeaderText}
                  onKeyUp={resetErrorOnChange}
                  disabled
                />
              </div>
              {(selectedTeamLoading || isSubmitting) && <LoadingIndicator padding="0px" />}
              <div className={styles.buttonsWrapper}>
                {isEditing ? (
                  <>
                    <button
                      className={styles.saveButton}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <CheckIcon />
                    </button>
                    <button
                      className={styles.saveButton}
                      type="button"
                      onClick={(event) => {
                        resetForm();
                        cancelEditing(event);
                      }}
                      disabled={isSubmitting}
                    >
                      <CancelIcon />
                    </button>
                  </>
                ) : (
                  <button
                    className={styles.saveButton}
                    type="button"
                    onClick={startEditing}
                    disabled={!isSelectedTeamLoaded}
                  >
                    <EditIcon />
                  </button>
                )}
                {isSubmitting && <LoadingIndicator padding="0px" />}
              </div>
            </div>
            <div className={styles.row}>
              <FormikInput
                style={{ marginTop: '3px' }}
                disabled={!isEditing}
                label="Name*"
                name="name"
              />
              {
                    isEditing ? (
                      <CustomerResponsibilityRoleSelect
                        disabled={!isEditing}
                        roleType={CustomerResponsibilityMemberTypes.TEAM}
                        label="Beteiligtenrolle"
                        labelKey="friendlyName"
                        valueKey="id"
                        name="customerResponsibilityRoleId"
                        onChange={resetErrorOnChange}
                      />
                    ) : (
                      <div className={styles.roleLabelBlock}>
                        <span className={styles.roleLabelBlockName}>BETEILIGTENROLLE</span>
                        <br />
                        {((customerResponsibilityRoleName
                        || initialValues.customerResponsibilityRoleName)
                            && customerResponsibilityRoleName !== roleNotSelectedText) ? (
                              <span
                                className={styles.roleLabelName}
                              >
                                <img src={teamIcon} alt="team icon" />
                                {customerResponsibilityRoleName
                            || initialValues.customerResponsibilityRoleName}
                              </span>
                          ) : (
                            <span className={styles.roleNotExistText}>
                              {roleNotSelectedText}
                            </span>
                          )}
                      </div>
                    )}
            </div>
            {(errorMessage || teamNameIsEmptyErrorMessage)
              && isEditing && <div className={styles.errorMessage}>{errorMessage}</div>}

          </Form>
        )}
      </Formik>
      <div className={styles.tabWrapper}>
        {selectedTeam && <TeamUserTable team={selectedTeam} members={selectedTeam.members} />}
      </div>
    </div>
  );
};
export default TeamDetail;
