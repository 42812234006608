import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ICON,
  ICON_SIZE,
  ICON_COLOR,
} from 'gcs-common/constants/IconConstants';
import { addTicketRating } from 'gcs-common/slices/tickets/ticketsThunks';
import styles from './styles.module.scss';
import IconComponent from '../../IconComponent/IconComponent';
import PopUpModal from '../../PopUpModal/PopUpModal';
import TicketRatingModal from './TicketRatingModal/TicketRatingModal';

interface TicketRatingProps {
  ticketId: string;
  ticketStatus: string;
  comment: string;
  rating: number;
  onRatingAdded: VoidFunction
}

const TicketRating = (props:TicketRatingProps) => {
  const {
    ticketId, ticketStatus, comment, rating, onRatingAdded,
  } = props;
  const buttonStyle = ticketStatus === 'wont_do' ? styles.ratingPrimaryButton : styles.ratingSecondaryButton;
  const ratingText = rating ? 'Ihre Bewertung ändern' : 'Bewerten Sie uns';
  const [showRatingModal, setRatingModal] = useState(false);
  const dispatch = useDispatch();

  const rate = (newRating: number, newComment: string) => {
    // @ts-expect-error redux, eslint-disable-next-line
    dispatch(addTicketRating({
      ticketId,
      rating: newRating,
      comment: newComment,
    }));
    setRatingModal(false);
    onRatingAdded();
  };

  return (
    <div className={styles.ticketRating}>
      <button type="button" className={buttonStyle} onClick={() => setRatingModal(true)}>
        <div className={styles.ratingText}>
          <span>{ratingText}</span>
          {rating && (
            <div className={styles.ratingBadge}>
              (
              {rating}
              <IconComponent
                Icon={ICON.STAR_FILLED}
                size={ICON_SIZE.SMALL}
                color={ICON_COLOR.YELLOW}
              />
              )
            </div>
          )}
        </div>
      </button>
      {showRatingModal && (
        <PopUpModal
          onRequestClose={() => setRatingModal(false)}
          isOpen
          title=""
          /** We need to be higher than the ticket dashboard drawer with z-index 10 */
          zIndex={20}
        >
          <TicketRatingModal
            rating={rating}
            comment={comment}
            onCancel={() => setRatingModal(false)}
            handleRating={rate}
          />
        </PopUpModal>
      )}
    </div>
  );

};

export default TicketRating;
