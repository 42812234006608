import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTicketComment } from 'gcs-common/slices/tickets/ticketsThunks';
import styles from './styles.module.scss';
import Button from '../../../../../Button/Button';
import PopUpModal from '../../../../../PopUpModal/PopUpModal';

interface TicketCommentDeleteModalProps {
  ticketId: string;
  commentId: string;
  onClose: VoidFunction;
}

const TicketCommentDeleteModal = (props: TicketCommentDeleteModalProps) => {
  const { ticketId, commentId, onClose } = props;
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      // @ts-expect-error redux
      await dispatch(deleteTicketComment({ ticketId, commentId })); // eslint-disable-line

      onClose();
    } catch {
      setDeleting(false);
    }
  };

  return (
    <PopUpModal
      className={styles.deleteModal}
      onRequestClose={onClose}
      isOpen
      title=""
      /** We need to be higher than the ticket dashboard drawer with z-index 10 */
      zIndex={20}
    >
      <div>
        <div className={styles.modalContent}>
          <p>
            Möchten Sie diesen Kommentar wirklich löschen?
          </p>
        </div>
        <div className={styles.buttonRow}>
          <Button
            className={styles.modalButtonCancel}
            type="button"
            onClick={onClose}
            disabled={deleting}
          >
            Nein
          </Button>
          <Button
            className={styles.modalButtonDelete}
            type="button"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleDelete}
            disabled={deleting}
          >
            Ja
          </Button>
        </div>
      </div>
    </PopUpModal>
  );
};

export default TicketCommentDeleteModal;
