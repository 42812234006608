export const FILE_LIST_TYPES = {
  MEDIA: 'MEDIA',
  DOCS: 'DOCS',
  LINKS: 'LINKS',
};

export const ALL_FILE_LIST_TYPES = [
  FILE_LIST_TYPES.MEDIA,
  FILE_LIST_TYPES.DOCS,
  FILE_LIST_TYPES.LINKS,
];
