const DECAY_SCHEDULE = [
  1000, // 1 s
  1000,
  1000,
  1000,
  1000,
  1000 * 2,
  1000 * 2,
  1000 * 2,
  1000 * 5,
  1000 * 5,
  1000 * 5,
  1000 * 10,
  1000 * 15,
  1000 * 30,
  1000 * 30,
];

export const MAX_RETRIES = DECAY_SCHEDULE.length;

const offlineRetry = (action, retry) => {
  return DECAY_SCHEDULE[retry] || 1000;
};

export default offlineRetry;
