import { useCallback, useEffect, useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedHouseCustomMessages,
  getExampleCustomMessage,
  getCustomMessageTypes,
  getIsValidatingHouseCustomMessage,
  getSelectedHouseDetails,
  getValidateHouseCustomMessageErrorMessage,
} from 'gcs-common/slices/administration/houses/housesSelectors';
import {
  fetchHouseCustomMessageTypes,
  validateHouseCustomMessage,
  updateSelectedHouse,
} from 'gcs-common/slices/administration/houses/housesThunks';
import { useDebouncedCallback } from 'use-debounce';
import {
  resetExampleCustomMessage,
  resetValidateHouseCustomMessageErrorMessage,
} from 'gcs-common/slices/administration/houses/housesSlice';
import {
  convertMessageDeToEn,
  convertMessageEnToDe,
} from 'gcs-common/helper/HouseCustomMessageHelper';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import styles from './styles.module.scss';
import FormikInput from '../../../FormikInput/FormikInput';
import Highlight from '../../../Highlight/Highlight';
import EditIcon from '../../../../img/edit.svg?react';
import CheckIcon from '../../../../img/check_green.svg?react';
import CancelIcon from '../../../../img/close_red.svg?react';
import validateCustomMessage, { MAX_MESSAGE_LENGTH } from '../HouseCreateValidationHelper/HouseCreateValidationHelper';
import {
  showFailureNotification,
  showSuccessNotification,
} from '../../../../slices/notifications/ShowNotificationThunk';

Yup.addMethod(Yup.object, 'validateCustomMessage', validateCustomMessage);

const HouseCustomMessages = () => {

  const [isEditing, setIsEditing] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const validationErrorMessage = useSelector(getValidateHouseCustomMessageErrorMessage);

  const isValidatingCustomMessage = useSelector(getIsValidatingHouseCustomMessage);
  const houseCustomMessages = useSelector(getSelectedHouseCustomMessages);
  const exampleCustomMessage = useSelector(getExampleCustomMessage);
  const customMessageTypes = useSelector(getCustomMessageTypes);
  const selectedHouse = useSelector(getSelectedHouseDetails);
  const [selectedMessageType, setSelectedMessageType] = useState(undefined);
  const [initMessage, setInitMessage] = useState('');
  const [selectedCustomMessageParams, setSelectedCustomMessageParams] = useState([]);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    selectedCustomMessageType: Yup.string(),
    customMessages: Yup.object().shape().validateCustomMessage(customMessageTypes || []),
  });

  const startEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const { customMessages } = selectedHouse || {};

  const initialValues = useMemo(() => {
    return ({
      customMessages: customMessages || {},
    });
  }, [customMessages]);

  const {
    message: exampleMessage, parameters: exampleCustomParameters,
  } = exampleCustomMessage || {};

  const customMessageOptions = customMessageTypes.map(m => {
    const { type, label } = m;
    return { value: type, label };
  });

  const cancelEditing = useCallback((event) => {
    event.preventDefault();
    dispatch(resetExampleCustomMessage());
    setIsEditing(false);
    setIsCanceled(true);
  }, [dispatch]);

  useEffect(() => {
    if (validationErrorMessage) {
      dispatch(showFailureNotification(
        validationErrorMessage,
        dispatch(resetValidateHouseCustomMessageErrorMessage()),
      ));
    }
  }, [validationErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetValidateHouseCustomMessageErrorMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchHouseCustomMessageTypes());
  }, [dispatch]);

  const [validateDebouncedMessage] = useDebouncedCallback(async (msg, isValid, isValidating) => {
    if (!isValidating && isValid) {
      await dispatch(validateHouseCustomMessage(
        {
          customMessage: msg,
          parameters: selectedCustomMessageParams,
          messageType: selectedMessageType,
          houseId: selectedHouse.id,
        },
      ));
    }
  }, 500);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(updateSelectedHouse({ houseId: selectedHouse.id, values }));
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich geändert'));
      setIsEditing(false);
    }
    setSubmitting(false);
  }, [selectedHouse, dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={submit}
      validateOnChange
    >
      {({
        isSubmitting, resetForm, isValidating, isValid, values, setValues,
      }) => (
        <Form noValidate>
          <div className={styles.bottomHeader}>
            <div className={styles.buttonsWrapper}>
              {isEditing ? (
                <>
                  <button
                    className={styles.saveButton}
                    type="submit"
                    disabled={isSubmitting || isValidatingCustomMessage}
                  >
                    <CheckIcon />
                  </button>
                  <button
                    className={styles.saveButton}
                    type="button"
                    onClick={(event) => {
                      resetForm();
                      cancelEditing(event);
                    }}
                    disabled={isSubmitting}
                  >
                    <CancelIcon />
                  </button>
                </>
              ) : (
                <button
                  className={styles.saveButton}
                  type="button"
                  onClick={startEditing}
                >
                  <EditIcon />
                </button>
              )}
            </div>
          </div>
          <FormikSelect
            label="Wählen Sie eine Nachricht"
            name="messageType"
            placeholder="Bitte wählen"
            options={customMessageOptions}
            labelKey="label"
            valueKey="value"
            onChange={(msgType) => {
              if (selectedMessageType && !isValid) {
                setValues(() => ({
                  ...values,
                  messageType: msgType,
                  customMessages: {
                    ...values.customMessages,
                    [msgType]: initialValues.customMessages[msgType],
                  },
                }));
              }
              dispatch(resetExampleCustomMessage());
              setSelectedMessageType(msgType);
              const customMessageType = customMessageTypes.find(m => m.type === msgType);
              setSelectedCustomMessageParams(customMessageType.parameters);
              const { [msgType]: msg = customMessageType.default } = houseCustomMessages || {};
              const germanMessage = convertMessageEnToDe(msg, customMessageType.parameters);
              setInitMessage(germanMessage);
              setMessage(germanMessage);
              if (isEditing && !isValidating && isValid) {
                dispatch(validateHouseCustomMessage({
                  customMessage: msg,
                  parameters: customMessageType.parameters,
                  messageType: msgType,
                  houseId: selectedHouse.id,
                }));
              }
            }}
            isClearable={false}
            value={selectedMessageType}
          />
          {selectedMessageType && (
            <div className={styles.formikInputWrapper}>
              <FormikInput
                as="textarea"
                textClassName={`${styles.formikInput} ${isEditing ? styles.formikInputEdit : ''}`}
                errorClassName={styles.formikError}
                disabled={!isEditing}
                name="customMessages"
                disableHoverOver
                value={isCanceled ? initMessage : message}
                onChange={(event) => {
                  const msg = event.target.value;
                  setMessage(msg);
                  const customMessageEn = convertMessageDeToEn(msg, selectedCustomMessageParams);
                  setIsCanceled(false);
                  setValues(() => ({
                    ...values,
                    messageType: selectedMessageType,
                    customMessages: {
                      ...values.customMessages, [selectedMessageType]: customMessageEn,
                    },
                  }));
                  validateDebouncedMessage(msg, isValid, isValidating);
                }}
                maxLength={MAX_MESSAGE_LENGTH}
              >
                {isEditing && (
                <div className={styles.formikInfoWrapper}>
                  <div className={styles.formikInfoLarge}>
                    {'Sie können die folgende Parameter in die automatischen Meldungen aufnehmen.'
                    + '\n Bitte fügen Sie die Klammern {} hinzu,'
                    + ' wenn sie die Parameter verwenden.'}
                  </div>
                  <div className={`${styles.formikInfoLarge} ${styles.bold}`}>
                    {selectedCustomMessageParams.map(p => `{${p.key_label}}`).join(', ')}
                  </div>
                </div>
                )}
                {isEditing && exampleMessage && (
                  <div className={styles.exampleMessageWrapper}>
                    <div className={styles.exampleMessageHeader}>
                      Beispielnachricht
                    </div>
                    <div className={styles.formikInfoLarge}>
                      <Highlight search={exampleCustomParameters}>{exampleMessage}</Highlight>
                    </div>
                  </div>
                )}
              </FormikInput>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default HouseCustomMessages;
