// eslint-disable-next-line  import/no-unresolved
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

// Returns a merged object of two objects, where the values of the second object
// override the values of the first, but the attributes that do not exist in the
// second object are kept intact.
function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return srcValue;
  }
  return undefined;
}

const mergeObjects = (target, source) => mergeWith({}, target, source, customizer);

export default mergeObjects;
