import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openEditImageModal, updateTeamchatImageForEditing } from 'gcs-common/slices/uiState/uiStateSlice';
import { getTeamchatPicture } from 'gcs-common/slices/uiState/uiStateSelectors';
import PropTypes from 'prop-types';
import avatarPlaceHolder from 'gcs-common/img/user.svg';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import useFieldFast from '../../customHooks/useFieldFast';
import FileInputWrapper from '../FileInputWrapper/FileInputWrapper';
import styles from './styles.module.scss';

const FormikProfileImageSelect = (
  {
    name,
    disabled = false,
  },
) => {

  const dispatch = useDispatch();
  const [field, , helpers] = useFieldFast(name);
  const teamchatPicture = useSelector(getTeamchatPicture);

  useEffect(() => {
    helpers.setValue(teamchatPicture);
  }, [teamchatPicture, helpers]);

  const onPictureChoice = async (file) => {
    if (file !== null) {
      const reader = new FileReader();
      // Turns file into base64 string
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        const { result } = reader;
        dispatch(updateTeamchatImageForEditing({ teamchatImageForEditing: result }));
      };
      dispatch(openEditImageModal());
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.profileImage}>
        {!disabled && (
          <FileInputWrapper onChange={onPictureChoice} className={styles.overlay}>
            {!field.value ? (
              <p>Bild Hinzufügen</p>
            ) : (
              <p>Bild ändern</p>
            )}
          </FileInputWrapper>
        )}
        <img
          src={field.value || avatarPlaceHolder}
          className={styles.profileImagePic}
          alt="Profilbild des Users"
        />
        <ValidationError name={name} />
      </div>
    </div>
  );
};

FormikProfileImageSelect.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default FormikProfileImageSelect;
