import { createSelector } from '@reduxjs/toolkit';
import {
  getMessagesForChannel,
  getMessageIdsForChannel,
} from './messagesSelectors';

export const getMessageIdForChannelByLocalListIndex = (
  channelId, localListIndex,
) => createSelector(
  getMessageIdsForChannel(channelId),
  (messageIds) => {
    return messageIds && messageIds[localListIndex];
  },
);

export const getMessageLocalListIndexForChannelById = (
  channelId, messageId,
) => createSelector(
  getMessageIdsForChannel(channelId),
  (messageIds) => {
    return (messageIds && messageId && messageIds.indexOf(messageId)) ?? null;
  },
);

export const getMessageIndexForLocalListIndex = (channelId, localListIndex) => createSelector(
  getMessagesForChannel(channelId),
  (channelMessages) => {
    if (!channelMessages || channelMessages.length === 0) {
      return undefined;
    }
    return channelMessages && channelMessages[localListIndex]?.index;
  },
);
