import { useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ldaNumberValidator, stringValidator } from 'gcs-common/helper/yupValidationHelper';
import { createErpCustomer } from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import { getSelectedHouseLdaNumbers } from 'gcs-common/slices/administration/houses/housesSelectors';
import { getCreateErpCustomerErrorMessage, getCreateErpCustomerDefaultErrorMessage } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import { resetCreateErpCustomerErrorMessage, resetCreateErpCustomerDefaultErrorMessage } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import styles from './styles.module.scss';
import FormikInput from '../../../FormikInput/FormikInput';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const ErpCustomerValidationSchema = Yup.object().shape({
  friendlyName: stringValidator,
  customerNumber: Yup.string().matches(/^((TEON01_TE_+)|([0-9a-zA-Z]{6}$))/, 'Die Kundennummer muss aus 6 Zeichen bestehen.').required('Erforderlich'),
  zip: Yup.string().matches(/^[0-9]{5}$/, 'Die Postleitzahl muss 5-stellig sein'),
  ldaNumber: ldaNumberValidator,
});

const initialData = {
  friendlyName: '',
  description: '',
  shortName: '',
  customerNumber: '',
  ldaNumber: [],
  lsp: '',
  street: '',
  zip: '',
  city: '',
};

const ErpCustomerCreate = ({ onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateErpCustomerErrorMessage);
  const defaultErrorMessage = useSelector(getCreateErpCustomerDefaultErrorMessage);
  const houseLDAs = useSelector(getSelectedHouseLdaNumbers);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetCreateErpCustomerDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateErpCustomerErrorMessage());
    dispatch(resetCreateErpCustomerDefaultErrorMessage());
  }, [dispatch]);

  const onCustomerIdChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateErpCustomerErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    /* eslint-enable no-param-reassign */
    const result = await dispatch(createErpCustomer({
      erpCustomer: {
        ...values,
      },
    }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={ErpCustomerValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>

          <div className={styles.row}>
            <FormikInput label="Name*" name="friendlyName" />
            <FormikInput label="Kurzname" name="shortName" />
          </div>

          <div className={styles.row}>
            {/* Note: using onChange/onFocus might clash with formik-validation */}
            <FormikInput
              label="Kundennummer*"
              type="text"
              name="customerNumber"
              onKeyUp={onCustomerIdChanged}
            />
            <FormikSelect
              options={houseLDAs.map(lda => ({ key: lda, value: lda }))}
              label="LDA*"
              name="ldaNumber"
              valueKey="key"
              labelKey="value"
            />
          </div>

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.row}>
            <FormikInput label="LSP" name="lsp" />
          </div>

          <div className={styles.row}>
            <FormikInput label="Beschreibung" name="description" />
          </div>

          <div className={styles.row}>
            <FormikInput label="Straße und Hausnummer" name="street" />
          </div>

          <div className={styles.row}>
            <FormikInput label="PLZ" name="zip" />
            <FormikInput label="Stadt" name="city" />
          </div>

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ErpCustomerCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ErpCustomerCreate;
export { ErpCustomerValidationSchema };
