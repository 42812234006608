import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeMediaFullscreen } from 'gcs-common/slices/uiState/uiStateSlice';
import useOnClickOutside from 'gcs-common/hooks/useOnClickOutside';
import closeIcon from '../../img/close_white.svg';
import styles from './styles.module.scss';

interface MediaFullscreenProps {
  mediaFullscreenUrl: string;
}

const MediaFullscreen = (props: MediaFullscreenProps) => {
  const { mediaFullscreenUrl } = props;
  const imageRef = useRef(null);

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeMediaFullscreen());
  }, [dispatch]);

  useOnClickOutside(imageRef, onClose);

  return (
    <div className={styles.mediaFullscreenWrapper}>
      <button type="button" onClick={onClose}>
        <img
          className={styles.icon}
          src={closeIcon}
          alt="close"
        />
      </button>
      <div className={styles.img}>
        <img
          ref={imageRef}
          alt="Bild aus Nachricht"
          src={mediaFullscreenUrl}
        />
      </div>
    </div>
  );
};

export default MediaFullscreen;
