import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsMobile } from 'gcs-common/slices/init/initSelectors';
import { getJoinedHousesNames } from 'gcs-common/slices/houses/housesSelectors';
import { captureMessage } from '@sentry/react';
import {
  getUsercentricsUserGaveConsent,
  USERCENTRICS_IDS,
} from './useUsercentrics';
import { loadScript } from '../helper/loadScript';
import {
  getIsAgent,
  getCurrentUserId,
  getCurrentUserRoles,
} from '../slices/currentUser/currentUserSelectors';
import usePermission from './usePermission';

const appId = import.meta.env.VITE_USERLANE_APP_ID || null;

export default function useUserlane() {
  const currentUserId = useSelector(getCurrentUserId);
  const houses = useSelector(getJoinedHousesNames);
  const roles = useSelector(getCurrentUserRoles);
  const isAgent = useSelector(getIsAgent);
  const isHouseAdmin = usePermission(['administration']);
  const isMobile = useSelector(getIsMobile);

  const initialize = useCallback(async () => {
    /*
        INFO FROM DOC:
        If any additional commands are added in the snippet,
        make sure they are called before the initialize command.
        The initialize command confirms the passed user information.
        Therefore, whenever the page is reloaded, the passed user information is refreshed.
    */
    if (appId && currentUserId) {
      if (!window.Userlane) {
        const userlaneUserGaveConsent = getUsercentricsUserGaveConsent(USERCENTRICS_IDS.USERLANE);

        await loadScript('https://cdn.userlane.com/userlane.js', {
          scriptDataAttributes: {
            usercentrics: 'Userlane',
          },
          // type 'text/plain' is set so that browser is not executing the script
          // until user gives consent in usercentrics
          scriptType: userlaneUserGaveConsent ? 'application/javascript' : 'text/plain',
        });
      }

      if (!window.Userlane) {
        captureMessage('Userlane did not load');
        return;
      }

      const attributes = {
        // must be in snake_case
        is_agent: isAgent,
        is_house_admin: isHouseAdmin,
        houses,
        roles,
        environment: import.meta.env.VITE_BUILD_ENVIRONMENT,
        mobile: isMobile,
      };
      window.Userlane('identify', currentUserId, attributes);
      window.Userlane('init', appId);
    }
  }, [currentUserId, houses, roles, isAgent, isHouseAdmin, isMobile]);

  useEffect(() => {
    initialize();
  }, [initialize]);

}
