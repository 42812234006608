import { Virtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';
import {
  getSalesSpaceChannelsGroupings,
} from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import React from 'react';
import styles from './styles.module.scss';
import SalesSpaceChannelGroupingSection
  from '../../SalesSpaceListSection/SalesSpaceChannelGroupingSection';

// Note: To style the list a forwardRef must be passed
const List = React.forwardRef((props, ref) => (
  <div {...props} ref={ref} className={styles.listWrapper} />
));

const Footer = () => <div className={styles.listFooter} />;
const Item = (props) => <div {...props} className={styles.itemWrapper} />;

/**
 *  The list of all Sales Spaces Channels, divided into sections per company
 */
const SalesSpaceChannelList = () => {
  const channelsGroupings = useSelector(getSalesSpaceChannelsGroupings);

  return (
    <div className={styles.salesSpaceChannelList}>
      <Virtuoso
        data={channelsGroupings}
        itemContent={(index) => (
          <SalesSpaceChannelGroupingSection
            grouping={channelsGroupings[index]}
          />
        )}
        components={{
          Footer,
          List,
          Item,
        }}
      />
    </div>
  );
};

SalesSpaceChannelList.propTypes = {};

export default SalesSpaceChannelList;
