import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const AnimatedSidebarButton = ({
  className = '', onClick, children, disabled, tooltip, ...props
}) => {
  return (
    <button
      type="button"
      className={`${styles.animatedSidebarButton} ${className}`}
      onClick={onClick}
      disabled={disabled}
      title={tooltip}
      {...props}
    >
      {children}
    </button>
  );
};

AnimatedSidebarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default AnimatedSidebarButton;
