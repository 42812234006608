import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';


function LoadingIndicatorFullScreen({ loadingText } : { loadingText: string }) {
  return (
    <div className={styles.root}>
      <div className={styles.loadingRoot}>
        <LoadingIndicator />
        {loadingText}
      </div>
    </div>
  );
}

export default LoadingIndicatorFullScreen;
