import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBusinessUnit } from 'gcs-common/slices/administration/businessUnits/businessUnitsThunks';
import {
  getDeleteBusinessUnitErrorMessage,
  getDeleteBusinessUnitLoading,
} from 'gcs-common/slices/administration/businessUnits/businessUnitsSelectors';
import { resetDeleteBusinessUnitErrorMessage } from 'gcs-common/slices/administration/businessUnits/businessUnitsSlice';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import BusinessUnitPropType from '../../../../models/BusinessUnitPropType';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const BusinessUnitDelete = ({ businessUnit, onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getDeleteBusinessUnitErrorMessage);
  const isDeleting = useSelector(getDeleteBusinessUnitLoading);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetDeleteBusinessUnitErrorMessage()),
      ));
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteBusinessUnitErrorMessage());
  }, [dispatch]);

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteBusinessUnit({ businessUnitId: businessUnit.id }));
      if (!result.error) {
        onSuccess();
      }
    },
    [dispatch, businessUnit.id, onSuccess],
  );

  return (
    <div className={styles.column}>
      Möchten Sie den BusinessUnit
      {' "'}
      {businessUnit.friendlyName}
      {'" '}
      wirklich löschen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

BusinessUnitDelete.propTypes = {
  businessUnit: BusinessUnitPropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default BusinessUnitDelete;
