import { ICON, ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { TICKET_STATI } from 'gcs-common/slices/tickets/ticketsConstants';
import IconComponent from '../IconComponent/IconComponent';

interface TicketStatusProps {
  status: string
}

const TicketStatus = (props: TicketStatusProps) => {
  const { status } = props;
  const title = TICKET_STATI[status]?.title || 'Unbekannt';
  const iconColor = TICKET_STATI[status]?.iconColor || ICON_COLOR.LIGHT_GREY;

  return (
    <span>
      <IconComponent
        Icon={ICON.ECLIPSE}
        size={ICON_SIZE.XXX_SMALL}
        color={iconColor}
      />
      &nbsp;&nbsp;
      {title}
    </span>
  );

};

export default TicketStatus;
