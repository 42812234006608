import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTeam } from 'gcs-common/slices/administration/teams/teamsThunks';
import { getDeleteTeam, getDeleteTeamDefaultErrorMessage } from 'gcs-common/slices/administration/teams/teamsSelectors';
import { resetDeleteTeamDefaultErrorMessage } from 'gcs-common/slices/administration/teams/teamsSlice';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import TeamPropType from '../../../../models/TeamPropType';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const TeamDelete = ({ team, onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const defaultErrorMessage = useSelector(getDeleteTeamDefaultErrorMessage);
  const isDeleting = useSelector(getDeleteTeam);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetDeleteTeamDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteTeamDefaultErrorMessage());
  }, [dispatch]);

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteTeam({ teamId: team.id }));
      if (!result.error) {
        onSuccess();
      }
    },
    [dispatch, team.id, onSuccess],
  );

  return (
    <div className={styles.column}>
      Möchten Sie das Team
      {' "'}
      {team.name}
      {'" '}
      wirklich löschen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

TeamDelete.propTypes = {
  team: TeamPropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TeamDelete;
