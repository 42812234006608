import { onlineManager } from '@tanstack/react-query';
// eslint-disable-next-line import/no-cycle
import { TasksClientQueryKeys, useTasksApiHelper } from '../clients/api/entities/tasks/tasks-api-helpers';
import { reactQueryClient } from '../clients/api/client';

export const onCacheRehydrated = async () => {
  if (!onlineManager.isOnline()) {
    return;
  }

  await reactQueryClient.resumePausedMutations();
  await reactQueryClient.invalidateQueries();
};

export const onRestoreCacheSuccess = async () => {
  await onCacheRehydrated();
};

// due to a technical limitation, when starting the app and there are paused mutations in the queue,
// they can only succeed if the mutationFn exists, which will not always be the case
// e.g. component is not mounted
// to fix this, we set a default mutationFn for all mutations that are potetnially paused

export const setMutationsDefaults = () => {
  // Tasks Mutations
  const {
    addTask, updateTask, setTaskDone, deleteTask, updateTasksOrder,
  } = useTasksApiHelper();
  reactQueryClient.setMutationDefaults([TasksClientQueryKeys.CREATE_TASK], {
    mutationFn: addTask,
  });
  reactQueryClient.setMutationDefaults([TasksClientQueryKeys.UPDATE_TASK], {
    mutationFn: updateTask,
  });
  reactQueryClient.setMutationDefaults([TasksClientQueryKeys.SET_TASK_DONE], {
    mutationFn: setTaskDone,
  });
  reactQueryClient.setMutationDefaults([TasksClientQueryKeys.DELETE_TASK], {
    mutationFn: deleteTask,
  });
  reactQueryClient.setMutationDefaults([TasksClientQueryKeys.UPDATE_TASKS_ORDER], {
    mutationFn: updateTasksOrder,
  });
};
