import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import { addTicketComment } from 'gcs-common/slices/tickets/ticketsThunks';
import { getAddTicketCommentLoading, getUploadTicketAttachmentsLoading } from 'gcs-common/slices/tickets/ticketsSelectors';
import {
  ICON,
  ICON_COLOR,
  ICON_SIZE,
} from 'gcs-common/constants/IconConstants';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import IconComponent from '../../../IconComponent/IconComponent';
// eslint-disable-next-line import/no-cycle
import TicketInputComment from '../TicketCommentInput/TicketCommentInput';
import {
  showFailureNotification,
  showSuccessNotification,
} from '../../../../slices/notifications/ShowNotificationThunk';

interface TicketCommentFormProps {
  ticketId: string
}

export interface TicketCommentFormValues {
  comment: string,
  files: File[]
}

const TicketCommentForm = (props: TicketCommentFormProps) => {
  const { ticketId } = props;
  const dispatch = useDispatch();
  const addCommentLoading = useSelector(getAddTicketCommentLoading);
  const uploadAttachmentLoading = useSelector(getUploadTicketAttachmentsLoading);
  const loading = addCommentLoading || uploadAttachmentLoading;

  const initialValues: TicketCommentFormValues = {
    comment: '',
    files: [],
  };

  const validationSchema = Yup.object().shape({
    // comment text is mandatory (we can't just let the user upload attachments without text),
    // because Jira automation relies on a text comment being created for reopening a closed ticket
    comment: Yup.string().required('Erforderlich').matches(/\S/g, 'Erforderlich'),
  });

  const submit = async (values: TicketCommentFormValues,
    { setSubmitting, resetForm }: FormikHelpers<TicketCommentFormValues>) => {
    const { comment, files } = values;
    // @ts-expect-error redux
    const result = await dispatch(addTicketComment({ ticketId, comment, attachments: files })); // eslint-disable-line
    // @ts-expect-error redux
    if (!result.payload.errors && !result.payload.errorCode) { // eslint-disable-line
      dispatch(showSuccessNotification('Kommentar erstellt'));
      resetForm();
    } else {
      // @ts-expect-error redux
      const errors = JSON.stringify(result.payload.errors); // eslint-disable-line
      dispatch(showFailureNotification(errors, () => { }));
    }
    setSubmitting(false);
  };

  const handleCancel = (resetForm: VoidFunction) => {
    return () => {
      resetForm();
    };
  };

  return (
    <Formik<TicketCommentFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={submit}
    >
      {({ values, resetForm, isValid }) => (
        <Form className={styles.commentForm}>
          <TicketInputComment />

          <div className={styles.buttonWrapper}>
            <button
              type="submit"
              className={styles.sendButton}
              disabled={!isValid || loading}
            >
              <IconComponent
                Icon={ICON.NEW_SEND}
                color={ICON_COLOR.WHITE}
                size={ICON_SIZE.SMALL}
              />
            </button>
            {values.files.length ? (
              <button
                type="button"
                className={styles.cancelButton}
                onClick={handleCancel(resetForm)}
                disabled={loading}
              >
                <IconComponent Icon={ICON.CLOSE} size={ICON_SIZE.SMALL} color={ICON_COLOR.ERROR} />
              </button>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TicketCommentForm;
