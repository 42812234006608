import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';

const SettingsInput = ({
  name, value = '', onFinish, onChange, placeholder = '', type = 'text', disabled = false,
}) => {
  const [isEditing, setEditing] = useState(false);
  const inputEl = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputEl.current.focus();
    }
  }, [isEditing]);

  const onBlur = useCallback(() => {
    setEditing(false);
    if (onFinish) {
      onFinish(value);
    }

  }, [onFinish, value]);

  return (
    <button
      type="button"
      onClick={() => setEditing(true)}
      className={styles.settingsRowWrapper}
      disabled={disabled}
    >
      <div className={styles.settingsRowHeader}>{name}</div>
      <div className={styles.settingsRowTextWrapper}>
        <input
          ref={inputEl}
          className={styles.settingsRowTextWrapper}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={($event) => onChange($event.target.value)}
          onFocus={() => setEditing(true)}
          onBlur={onBlur}
          disabled={disabled}
        />
        {!isEditing && <IconComponent Icon={ICON.EDIT} alt="Edit" />}
      </div>
    </button>
  );
};

SettingsInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SettingsInput;
