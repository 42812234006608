import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  businessUnitSelectionReset,
  resetUpdateBusinessUnitErrorMessage,
} from 'gcs-common/slices/administration/businessUnits/businessUnitsSlice';
import {
  fetchBusinessUnitDetails,
  updateBusinessUnit,
} from 'gcs-common/slices/administration/businessUnits/businessUnitsThunks';
import {
  getSelectedBusinessUnit,
  getSelectedBusinessUnitLoading,
  getUpdateBusinessUnitErrorMessage,
} from 'gcs-common/slices/administration/businessUnits/businessUnitsSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { showFailureNotification, showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import styles from './styles.module.scss';
import InlineInput from '../../../InlineInput/InlineInput';
import backIcon from '../../../../img/back.svg';

import { BusinessUnitCreateValidationSchema } from '../BusinessUnitCreate/BusinessUnitCreate';

const BusinessUnitDetail = () => {
  const { id: businessUnitId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorMessage = useSelector(getUpdateBusinessUnitErrorMessage);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetUpdateBusinessUnitErrorMessage()),
      ));
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetUpdateBusinessUnitErrorMessage());
  }, [dispatch]);

  const selectedBusinessUnit = useSelector(getSelectedBusinessUnit);
  const selectedBusinessUnitLoading = useSelector(getSelectedBusinessUnitLoading);

  const [businessUnitName, setBusinessUnitName] = useState(null);

  useEffect(() => {
    (async () => {
      await dispatch(fetchBusinessUnitDetails({ businessUnitId }));
    }
    )();
    return () => dispatch(businessUnitSelectionReset());
  }, [dispatch, businessUnitId]);

  const onBusinessUnitChanged = useCallback(
    async (key, value) => {
      if (businessUnitName !== value) {
        setBusinessUnitName(value);
        const result = await dispatch(
          updateBusinessUnit({ businessUnitId, values: { [key]: value } }),
        );
        if (!result.error) {
          dispatch(showSuccessNotification('Erfolgreich geändert'));
        }
      }
    },
    [dispatch, businessUnitId, businessUnitName],
  );

  const submit = useCallback(async (values, { setSubmitting }) => {
    dispatch(updateBusinessUnit({ businessUnitId, values }));
    setSubmitting(false);
  }, [dispatch, businessUnitId]);

  const initialValues = useMemo(() => {
    if (selectedBusinessUnit) {
      return {
        friendlyName: selectedBusinessUnit.friendlyName || '',
      };
    }
    return {};
  }, [selectedBusinessUnit]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={BusinessUnitCreateValidationSchema}
        onSubmit={submit}
      >
        {({ isSubmitting }) => (
          <Form>

            <div className={styles.headerRow}>
              <div className={styles.row}>
                <button
                  className={styles.backButton}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  <img src={backIcon} alt="back-icon" />
                </button>
                <InlineInput
                  name="friendlyName"
                  textClassName={styles.inputHeaderText}
                  onChange={onBusinessUnitChanged}
                  value={initialValues.friendlyName}
                />
              </div>
              {(selectedBusinessUnitLoading || isSubmitting) && <LoadingIndicator padding="0px" />}
            </div>

            {
              (errorMessage)
              && <div className={styles.errorMessage}>{errorMessage}</div>
            }

          </Form>
        )}
      </Formik>
    </div>
  );
};
export default BusinessUnitDetail;
