
import * as Tooltip from '@radix-ui/react-tooltip';
import { ICON, ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Icon from '../Icon/Icon';
import styles from './styles.module.scss';

type Props = {
  text: string,
  iconColor?: typeof ICON_COLOR[keyof typeof ICON_COLOR],
  showIcon?: boolean,
};

function InfoTooltip({
  text,
  showIcon = true,
  iconColor = ICON_COLOR.HIGH_CONTRAST,
} : Props) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className={styles.tooltipIcon}>
            {showIcon && (
            <Icon Icon={ICON.INFORMATION} size={ICON_SIZE.X_SMALL} color={iconColor} alt="info" />
            )}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Content className={styles.tooltipText} align="start" side="bottom">
          {text}
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export default InfoTooltip;
