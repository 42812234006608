import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getMessageFirstLinkPreview, getMessageIsFromMe,
  getMessageShouldShowLinkPreview,
} from 'gcs-common/slices/messages/messageSelector';
import styles from './styles.module.scss';
import LinkMeIcon from '../../img/link_me.svg?react';
import LinkOtherIcon from '../../img/link_other.svg?react';

function LinkPreview({ messageId }) {
  const shouldShowLinkPreview = useSelector(getMessageShouldShowLinkPreview(messageId));
  const isFromMe = useSelector(getMessageIsFromMe(messageId));
  const linkPreview = useSelector(getMessageFirstLinkPreview(messageId));
  const { thumbImg, description } = linkPreview || {};

  if (!shouldShowLinkPreview) {
    return null;
  }
  const placeholder = isFromMe ? <LinkMeIcon className={styles.linkMeIcon} /> : <LinkOtherIcon />;
  return (
    <div className={`${styles.linkItem} ${isFromMe ? styles.fromMe : ''}`}>
      <div className={`${styles.linkItemLeft} ${thumbImg ? '' : styles.emptyImage}`}>
        {thumbImg
          ? (
            <img
              alt="Link-Vorschau"
              src={thumbImg}
              className={styles.linkItemImg}
            />
          )
          : placeholder
        }
      </div>
      <div className={styles.linkItemRight}>
        {description}
      </div>
    </div>
  );
}

LinkPreview.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default LinkPreview;
