import { captureException } from '@sentry/react';
import { getRegisteredGoogleAnalyticsEvents } from '../config/googleAnalyticsConfig';

const googleAnalyticsMiddleware = ({ dispatch }) => next => action => {
  next(action); // don't wait until GA Event is fired
  try {
    const registeredGoogleAnalyticsEvents = getRegisteredGoogleAnalyticsEvents(dispatch);

    const registeredEvents = registeredGoogleAnalyticsEvents
      .filter(gaEvent => action.type === gaEvent.reduxActionType);

    registeredEvents.forEach(registeredEvent => registeredEvent.event(action));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('An error occurred while sending Google Analytics Event', e);
    captureException(e);
  }
};

export default googleAnalyticsMiddleware;
