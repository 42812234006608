import {
  useEffect, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRightBarTab,
} from 'gcs-common/slices/uiState/uiStateSlice';
import { getAgentOnlineStatus } from 'gcs-common/slices/currentAgent/currentAgentSelectors';
import { fetchAgentOnlineStatus } from 'gcs-common/slices/currentAgent/currentAgentThunks';
import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import calendarNotice from '../../../img/calendar.svg';
import styles from './styles.module.scss';

const UnannouncedLogin = () => {

  const agentOnlineStatus = useSelector(getAgentOnlineStatus);
  const dispatch = useDispatch();

  const showAbsenceCalendar = useCallback(() => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.ABSENCE }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAgentOnlineStatus());
  }, [dispatch]);

  if (agentOnlineStatus !== 'AVAILABLE') {
    return (
      <div className={`${styles.pushWarning} ${styles.awayWarning}`}>
        <button
          className={styles.settingsIcon}
          style={{ backgroundColor: 'white' }}
          type="button"
          onClick={showAbsenceCalendar}
        >
          <img alt="calendar-notice" src={calendarNotice} />
        </button>
        <button className={styles.warningText} type="button" onClick={showAbsenceCalendar}>
          Sie haben noch eine aktive Abwesenheitsnotiz.
          Klicken Sie hier, um die Angaben zu Abwesenheit oder Spätschicht zu ändern
        </button>
      </div>
    );
  }
  return null;
};

export default UnannouncedLogin;
