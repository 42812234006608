import favicon from './assets/favicon.svg';
import simpleLogo from './assets/simple_logo.svg';
import logo from './assets/logo.svg';
import chatLogo from './assets/chat_logo.svg';
import privacy from '../commonAssets/beemPrivacyText';
import terms from '../commonAssets/termsText';
import imprint from './assets/imprintText';
import opLogo from './assets/efgop.png';
import deleteAccountText from '../commonAssets/deleteAccountText';

const config = {
  title: 'EFG BEEM',
  description: 'BEEM ermöglicht es dem Fachhandwerk, unkompliziert und zu jederzeit ihrem persönlichen EFG-Ansprechpartner Fragen zu stellen – und das rechtssicher!',
  identifier: 'efg',
  appName: 'EFG',
  defaultBrand: 'EFG',
  url: 'efgbeem.de',
  apiBaseUrl: 'https://api.efgbeem.de',
  helpUrl: 'https://hilfe.getbeem.de/',
  iosStoreUrl: 'https://apps.apple.com/us/app/efg-beem/id1612675009',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=de.efgbeem',
  supportEmail: 'support@getbeem.de',
  favicon,
  logo,
  chatLogo,
  simpleLogo,
  opLogo,
  packageId: 'de.efgbeem',
  privacyText: privacy,
  termsText: terms,
  usercentricsId: 'uohzQruE2',
  imprintText: imprint,
  deleteAccountText,
  welcomeText: 'Willkommen bei EFG BEEM!',
  identifierPlaceholder: 'Mobilnummer oder E-Mail Adresse',
  mainText: 'Bitte geben Sie Ihre Mobilnummer oder E-Mail Adresse ein, um sich anzumelden.',
};

export default config;
