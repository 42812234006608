import './polyfills';
import * as Sentry from '@sentry/react';
import Modal from 'react-modal';
import './scss/global.scss';
import { sentryConfig } from 'gcs-common/config/sentryConfig';
import { createRoot } from 'react-dom/client';
import AppRoot from './components/AppRoot/AppRoot';

Modal.setAppElement('#root');
Sentry.init(sentryConfig());


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppRoot />,
);
