// eslint-disable-next-line import/prefer-default-export
export const LEFT_BAR_TABS = {
  SIDEBAR: 'SIDEBAR',
  PROFILE: 'PROFILE',
  ADMINISTRATION: 'ADMINISTRATION',
  NEW_CHAT: 'NEW_CHAT',
  NEW_GROUP_CHAT: 'NEW_GROUP_CHAT',
};

export const RIGHT_BAR_TABS = {
  MEMBER_DETAILS: 'MEMBER_DETAILS',
  CHANNEL_DETAILS: 'CHANNEL_DETAILS',
  TERMS: 'TERMS',
  ABSENCE: 'ABSENCE',
  TOOLS: 'TOOLS',
};
