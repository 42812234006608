import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { channelUpdated } from '../../../../slices/channels/channelsSlice';
import { RequestBody, Response, Parameters } from '../../../../../types/typeHelper';
// eslint-disable-next-line import/no-cycle
import { useApiClient } from '../../client';

export enum TasksClientQueryKeys {
  GET_TASKS = 'GET_TASKS',
  GET_TASK_MEDIA_URLS = 'GET_TASK_MEDIA_URLS',
  CREATE_TASK = 'CREATE_TASK',
  SET_TASK_DONE = 'SET_TASK_DONE',
  UPDATE_TASK = 'UPDATE_TASK',
  DELETE_TASK = 'DELETE_TASK',
  UPDATE_TASKS_ORDER = 'UPDATE_TASKS_ORDER',
}

export type FetchTasksResponseData = Response<'/chat/tasks', 'get'>;

export const getGetTasksQueryKey = (channelId: string) => [TasksClientQueryKeys.GET_TASKS,
  { channel_id: channelId }];

export const useReduxStateUpdateHelper = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return {
    updateNumOpenTasksForChannel: (channelId: string) => {
      const tasksCached = queryClient.getQueryData<FetchTasksResponseData>(
        getGetTasksQueryKey(channelId),
      );
      const numOpenTasks = tasksCached ? tasksCached.tasks.filter((task) => !task.done).length : [];
      dispatch(channelUpdated({ channel: { id: channelId, tasksCount: numOpenTasks } }));
    },
  };
};

export const useTasksApiHelper = () => {
  const client = useApiClient();
  return {
    addTask: async ({ body }: {
      body: RequestBody<'/chat/tasks', 'post'>,
    }) => {
      return client.POST('/chat/tasks', {
        body,
      });
    },
    updateTask: async ({ params, body }: {
      params: Parameters<'/chat/tasks/{task_id}', 'put'>,
      body: RequestBody<'/chat/tasks/{task_id}', 'put'>,
    }) => {
      return client.PUT('/chat/tasks/{task_id}', {
        params, body,
      });
    },
    setTaskDone: async ({ params, body }: {
      params: Parameters<'/chat/tasks/{task_id}/done', 'patch'>,
      body: RequestBody<'/chat/tasks/{task_id}/done', 'patch'>,
    }) => {
      return client.PATCH('/chat/tasks/{task_id}/done', {
        params, body,
      });
    },
    deleteTask: async ({ params }: {
      params: Parameters<'/chat/tasks/{task_id}', 'delete'>,
    }) => {
      return client.DELETE('/chat/tasks/{task_id}', {
        params,
      });
    },
    updateTasksOrder: async ({ body }: {
      body: RequestBody<'/chat/tasks/order', 'put'>,
    }) => {
      return client.PUT('/chat/tasks/order', {
        body,
      });
    },
  };
};
