import PropTypes from 'prop-types';

export default PropTypes.shape({
  CreateDate: PropTypes.string,
  Currency: PropTypes.string,
  CustomerBaseCommission: PropTypes.string,
  CustomerOrderNo: PropTypes.string,
  CustomerOrderText: PropTypes.string,
  DeliveryDetailsPossible: PropTypes.bool,
  Hint: PropTypes.string,
  InvoiceNo: PropTypes.number,
  Netto: PropTypes.number,
  Number: PropTypes.string,
  Purchaser: PropTypes.string,
  RecieverCity: PropTypes.string,
  RecieverHouseNo: PropTypes.string,
  RecieverName1: PropTypes.string,
  RecieverName2: PropTypes.string,
  RecieverName3: PropTypes.string,
  RecieverStreet: PropTypes.string,
  RecieverZip: PropTypes.string,
  SentWithParcelService: PropTypes.bool,
  StateId: PropTypes.string,
  StatusText: PropTypes.string,
  Suffix: PropTypes.string,
  Type: PropTypes.string,
  TypeText: PropTypes.string,
  ValidDateTo: PropTypes.string,
  ValidText: PropTypes.string,
  ValidToDate: PropTypes.string,
  blnPDF: PropTypes.bool,
  blnProcessRetrievalIDS: PropTypes.bool,
  blnRG: PropTypes.bool,
  blnUGL: PropTypes.bool,
});
