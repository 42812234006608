import {
  useMemo,
  useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getMessageDateCreated,
  getMessageIsFavorite,
  getMessageIsFromMe,
  getMessageShouldShowOptionsMenu,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import LinkPreview from '../../LinkPreview/LinkPreview';
import MessageName from '../MessageName/MessageName';
import styles from './styles.module.scss';
import MessageOptionsMenu from '../MessageOptionsMenu/MessageOptionsMenu';

const BaseMessage = ({ messageId, children }) => {

  const isFromMe = useSelector(getMessageIsFromMe(messageId));
  const isFavorite = useSelector(getMessageIsFavorite(messageId));
  const dateCreated = useSelector(getMessageDateCreated(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const shouldShowOptionsMenu = useSelector(getMessageShouldShowOptionsMenu(
    selectedChannelId,
    messageId,
  ));

  const [showOptionMenuButton, setShowOptionMenuButton] = useState(false);

  const rootDomNode = useRef();

  const formattedTimestamp = useMemo(() => dateCreated && dateCreated.format('LT'), [dateCreated]);

  return (
    <div
      className={`${styles.msg} ${isFromMe ? styles.me : ''}`}
      ref={rootDomNode}
      onMouseEnter={() => setShowOptionMenuButton(true)}
      onMouseLeave={() => setShowOptionMenuButton(false)}
    >
      <div className={styles.msgContent}>
        <div className={styles.msgHeader}>
          {!isFromMe && (
            <MessageName
              channelId={selectedChannelId}
              messageId={messageId}
            />
          )}
          {shouldShowOptionsMenu
          && (
            <MessageOptionsMenu
              showIcon={showOptionMenuButton}
              messageId={messageId}
              parent={rootDomNode}
            />
          )}
        </div>
        {children}
        <LinkPreview messageId={messageId} />
        <div className={styles.msgFooter}>
          {isFavorite
          && (
            <IconComponent
              Icon={ICON.STAR_FILLED}
              color={ICON_COLOR.PRIMARY}
              size={ICON_SIZE.SMALL}
              style={{ marginLeft: '-4px' }}
              alt="Favorit"
            />
          )}
          <span className={styles.timeStamp}>{formattedTimestamp}</span>
        </div>
      </div>
    </div>
  );
};

BaseMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default BaseMessage;
