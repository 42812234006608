import { configureStore } from '@reduxjs/toolkit';
import { createOffline } from '@redux-offline/redux-offline';
import gccApiClient from 'gcs-common/clients/gccApiClient/gccApiClient';
import chatClient from 'gcs-common/clients/chatClient/chatClient';
import videoClient from 'gcs-common/clients/videoClient/videoClient';
import onExceptionMiddleware from 'gcs-common/middlewares/onExceptionMiddleware';
import googleAnalyticsMiddleware from 'gcs-common/middlewares/googleAnalyticsMiddleware';
import customStoreSerializableCheck
  from 'gcs-common/constants/customStoreSerializableCheck';
import openErrorDialogMiddleware from 'gcs-common/middlewares/openErrorDialogMiddleware';
import { sentryReduxEnhancer } from 'gcs-common/config/sentryReduxEnhancer';
import firebaseClient from './clients/firebaseClient';
import createRootReducer from './rootReducer';
import offlineConfig from './offlineConfig';

// this is to avoid a circular dependency
// eslint-disable-next-line import/no-mutable-exports
let store = null;
const getStore = () => store;

const { middleware: offlineMiddleware,
  enhanceReducer, enhanceStore } = createOffline(offlineConfig({ getStore }));

// Note: For middleware order is important!
const createStore = ({ preloadedState } = {}) => configureStore({
  reducer: enhanceReducer(createRootReducer()),
  middleware: (getDefaultMiddleware) => [
    onExceptionMiddleware,
    ...getDefaultMiddleware({
      thunk: {
        extraArgument: {
          gccApiClient, chatClient, firebaseClient, videoClient,
        },
      },
      // not necessary since we are using immer which detects mutations in dev mode out of the box
      // https://github.com/reduxjs/redux-toolkit/issues/805
      immutableCheck: false,
      serializableCheck: { isSerializable: customStoreSerializableCheck },
    }),
    offlineMiddleware,
    googleAnalyticsMiddleware,
    openErrorDialogMiddleware,
  ],
  enhancers: [sentryReduxEnhancer, enhanceStore],
  preloadedState,
});

store = createStore();

export { createStore };

export default store;
