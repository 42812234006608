import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentFriendlyName,
  getCurrentUser,
  getCurrentUserCompany,
  getIsCurrentUserLoading,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { updateCurrentUser } from 'gcs-common/slices/currentUser/currentUserThunks/updateCurrentUser';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import Button from '../../Button/Button';
import styles from './styles.module.scss';

import SelectPhotoSourceButtons from '../../SelectPhotoSourceButtons/SelectPhotoSourceButtons';
import UserProfilePictureButton from './UserProfilePictureButton/UserProfilePictureButton';

function UserProfile() {

  const currentFriendlyName = useSelector(getCurrentFriendlyName);
  const currentCompany = useSelector(getCurrentUserCompany);
  const isCurrentUserLoading = useSelector(getIsCurrentUserLoading);
  const user = useSelector(getCurrentUser);

  const [openSelectPhotoButtons, setOpenSelectPhotoButtons] = useState(false);
  const [userCompany, setUserCompany] = useState(currentCompany || '');
  const [userName, setUserName] = useState(currentFriendlyName || '');

  useEffect(() => {
    setUserCompany(currentCompany || '');
  }, [currentCompany]);
  useEffect(() => {
    setUserName(currentFriendlyName || '');
  }, [currentFriendlyName]);

  const [companyError, setCompanyError] = useState(false);
  const [friendlyNameError, setFriendlyNameError] = useState(false);

  const dispatch = useDispatch();

  const onNext = useCallback(async () => {
    setFriendlyNameError(false);
    setCompanyError(false);
    if (!userName) setFriendlyNameError(true);
    if (!userCompany) setCompanyError(true);
    if (!userCompany || !userName) {
      return;
    }
    dispatch(updateCurrentUser({ values: { user_company: userCompany, user_name: userName } }));
  }, [userName, userCompany, dispatch]);

  if (!user) {
    return (
      <div className={styles.inputSectionForm}>
        <LoadingIndicator />
      </div>
    );
  }

  const toggleSelectPhotoButtons = () => {
    setOpenSelectPhotoButtons(!openSelectPhotoButtons);
  };

  return (
    <div className={styles.userProfileWrapper}>
      {openSelectPhotoButtons && (
        <SelectPhotoSourceButtons
          toggleSelectPhotoButtons={toggleSelectPhotoButtons}
          isRegistration
        />
      )}
      <div className={styles.content}>
        <div className={`${styles.inputSectionForm} ${styles.loader} ${!isCurrentUserLoading && styles.hidden}`}>
          <LoadingIndicator />
        </div>
        <div className={`${styles.inputSectionForm} ${isCurrentUserLoading && styles.hidden}`}>
          {(companyError || friendlyNameError) && (
            <div className={styles.errorMsg} />
          )}
          <UserProfilePictureButton
            className={styles.profilePic}
            onClick={toggleSelectPhotoButtons}
          />
          <input
            className={styles.input + (friendlyNameError ? ` ${styles.errorBorder}` : '')}
            placeholder="Dein Name (für Mitteilungen)"
            type="text"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            style={{ backgroundColor: userName && 'white' }}
          />
          <input
            className={styles.input + (companyError ? ` ${styles.errorBorder}` : '')}
            placeholder="Deine Firma"
            type="text"
            value={userCompany}
            onChange={(event) => setUserCompany(event.target.value)}
            style={{ backgroundColor: !!userCompany && 'white' }}
          />
        </div>
        <Button onClick={onNext} registrationButton>
          Name und Firma hinzufügen
        </Button>
      </div>
    </div>
  );
}

UserProfile.propTypes = {};

export default UserProfile;
