import { createSelector } from '@reduxjs/toolkit';

const getUploadProgresses = state => state.videos.uploadProgress;

export const getVideoUploadProgress = (videoId) => createSelector(
  getUploadProgresses,
  (videos) => videos[videoId] || 0,
);

export const getVideoIsDownloading = state => state.videos.videoIsDownloading;
