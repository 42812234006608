import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MESSAGE_TYPE } from 'gcs-common/constants/MessageTypes';
import {
  getMessageProcessOrProductData,
  getMessageBody,
  getMessageDateCreated,
  getMessageIsDeleted,
  getMessageIsFavorite,
  getMessageIsForwarded,
  getMessageIsFromMe,
  getMessageType, getMessageOrder,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import OrderMessage from 'gcs-common/components/QuickOrdering/OrderMessage/OrderMessage';
import styles from './styles.module.scss';
import MediaMessage from '../MediaMessage/MediaMessage';
import ProcessMessage from '../ProcessSelect/ProcessMessage/ProcessMessage';
import ProductMessage from '../ProcessSelect/ProductMessage/ProductMessage';
import LinkifyBlank from '../LinkifyBlank/LinkifyBlank';
import StarFullIcon from '../../img/star_full.svg?react';
import DeletedMessage from './DeletedMessage/DeletedMessage';
import MessageName from './MessageName/MessageName';
import BaseMessage from './BaseMessage/BaseMessage';
import SubstituteNotification from './SubstituteNotification/SubstituteNotification';
import MessageOptionsMenu from './MessageOptionsMenu/MessageOptionsMenu';
import ForwardMessage from '../QuotedMessage/ForwardMessage/ForwardMessage';
import ReplyMessage from '../QuotedMessage/ReplyMessage/ReplyMessage';
import VideoMessage from '../VideoMessage/VideoMessage';

const Message = ({ messageId }) => {

  const isDeleted = useSelector(getMessageIsDeleted(messageId));
  const isForwarded = useSelector(getMessageIsForwarded(messageId));
  const messageType = useSelector(getMessageType(messageId));
  const isFromMe = useSelector(getMessageIsFromMe(messageId));
  const isFavorite = useSelector(getMessageIsFavorite(messageId));
  const processOrProduct = useSelector(getMessageProcessOrProductData(messageId));
  const order = useSelector(getMessageOrder(messageId));
  const dateCreated = useSelector(getMessageDateCreated(messageId));
  const messageBody = useSelector(getMessageBody(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);

  const [showOptionMenuButton, setShowOptionMenuButton] = useState(false);

  const rootDomNode = useRef();

  const dateCreatedFormatted = useMemo(() => dateCreated && dateCreated.format('LT'), [dateCreated]);

  if (isDeleted) {
    return (
      <BaseMessage messageId={messageId}>
        <DeletedMessage />
      </BaseMessage>
    );
  }
  if (isForwarded) {

    return (
      <BaseMessage
        messageId={messageId}
      >
        <div className={styles.txtMsgBody}>
          <div className={styles.replyWrapper}>
            <ForwardMessage
              messageId={messageId}
            />
          </div>
        </div>
      </BaseMessage>
    );
  }

  switch (messageType) {
    case MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION:
      return <SubstituteNotification messageId={messageId} />;
    case MESSAGE_TYPE.MEDIA_FROM_MAIL:
    case MESSAGE_TYPE.MEDIA:
      return (
        <div
          className={`${styles.msg} ${isFromMe ? styles.me : ''}`}
          ref={rootDomNode}
          onMouseEnter={() => setShowOptionMenuButton(true)}
          onMouseLeave={() => setShowOptionMenuButton(false)}
        >
          <div className={styles.msgMediaContent}>
            <div className={styles.msgMediaHeader}>
              {!isFromMe && (
                <MessageName
                  channelId={selectedChannelId}
                  messageId={messageId}
                />
              )}
              <MessageOptionsMenu
                showIcon={showOptionMenuButton}
                messageId={messageId}
                parent={rootDomNode}
              />
            </div>
            <MediaMessage
              messageId={messageId}
            />
            <div className={styles.msgFooter}>
              {isFavorite
              && (
                <StarFullIcon alt="Favorit" />)}
              <span>{dateCreatedFormatted}</span>
            </div>
          </div>
        </div>
      );
    case MESSAGE_TYPE.VIDEO:
      return (
        <div
          className={`${styles.msg} ${isFromMe ? styles.me : ''}`}
          ref={rootDomNode}
          onMouseEnter={() => setShowOptionMenuButton(true)}
          onMouseLeave={() => setShowOptionMenuButton(false)}
        >
          <div className={styles.msgMediaContent}>
            <div className={styles.msgMediaHeader}>
              {!isFromMe && (
                <MessageName
                  channelId={selectedChannelId}
                  messageId={messageId}
                />
              )}
              <MessageOptionsMenu
                showIcon={showOptionMenuButton}
                messageId={messageId}
                parent={rootDomNode}
              />
            </div>
            <VideoMessage
              messageId={messageId}
            />
            <div className={styles.msgFooter}>
              {isFavorite
              && (
                <StarFullIcon alt="Favorit" />)}
              <span>{dateCreatedFormatted}</span>
            </div>
          </div>
        </div>
      );
    case MESSAGE_TYPE.REPLY:
      return (
        <BaseMessage
          messageId={messageId}
        >
          <div className={styles.txtMsgBody}>
            <div className={styles.replyWrapper}>
              <ReplyMessage messageId={messageId} />
            </div>
          </div>
        </BaseMessage>
      );

    case MESSAGE_TYPE.PRODUCT:
    case MESSAGE_TYPE.PROCESS: {
      return processOrProduct === null ? '' : (
        <>
          <div className={`${styles.processMessage} ${isFromMe ? styles.me : ''}`}>
            <div className={styles.msgContentProduct}>
              {messageType === MESSAGE_TYPE.PROCESS
                ? <ProcessMessage process={processOrProduct} />
                : <ProductMessage product={processOrProduct} />}
            </div>
          </div>
          <BaseMessage
            messageId={messageId}
          >
            <div className={styles.txtMsgBody}>
              <LinkifyBlank>{messageBody}</LinkifyBlank>
            </div>
          </BaseMessage>
        </>
      );
    }
    case MESSAGE_TYPE.ORDER:
      return order === null ? '' : (
        <>
          <div className={`${styles.processMessage} ${isFromMe ? styles.me : ''}`}>
            <div className={styles.msgContentProduct}>
              <OrderMessage order={order} fromMe={isFromMe} />
            </div>
          </div>
          {messageBody ? (
            <BaseMessage
              messageId={messageId}
            >
              <div className={styles.txtMsgBody}>
                <LinkifyBlank>{messageBody}</LinkifyBlank>
              </div>
            </BaseMessage>
          ) : <div style={{ height: '15px' }} />}
        </>
      );
    default: {
      return (
        <BaseMessage
          messageId={messageId}
        >
          <div className={styles.txtMsgBody}>
            <LinkifyBlank>{messageBody}</LinkifyBlank>
          </div>
        </BaseMessage>
      );
    }
  }
};

export default Message;

Message.propTypes = { messageId: PropTypes.string.isRequired };
