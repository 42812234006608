import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessage, getMessageAuthorName } from '../../messages/messageSelector';
import { getPlatform } from '../../init/initSelectors';
import {
  getChannelInputForwardRecipientChannelId,
  getChannelInputStagedFiles,
  getChannelInputStagedForwardMessageIds,
  getChannelInputStagedMessageSubType, getChannelInputStagedOrder,
  getChannelInputStagedProcess,
  getChannelInputStagedProduct,
  getChannelInputStagedReplyMessageId,
  getChannelInputText,
} from '../messageInputSelector';
import { getCurrentUserId, getCurrentUserStatus, getIsAgent } from '../../currentUser/currentUserSelectors';
import { isVideo } from '../../../helper/fileHelper';
// eslint-disable-next-line import/no-cycle
import { channelInputReset } from '../messageInputSlice';
import { sendMessagesOffline } from '../messageInputActions/sendMessagesOffline';
import { getIsChatConnected } from '../../chatConnection/chatConnectionSelectors';
import { ONLINE_STATUS } from '../../../helper/userStatusHelper';
import { updateAgentStatus } from '../../currentAgent/currentAgentThunks';
import changeSelectedChannelId from '../../channels/channelsThunks/changeSelectedChannelId';
import scrollToMessageId from '../../chat/chatThunks/scrollToMessageId';
import {
  createForwardMessageModel,
  createMediaMessageModel, createOrderMessageModel,
  createProcessMessageModel,
  createProductMessageModel,
  createReplyMessageModel,
  createTextMessageModel,
  createVideoMessageModel,
} from '../messageInputHelpers/sendCurrentMessageStateHelper';

/**
 *
 * Entry point for sending messages
 *
 * Gets all the data from the state which is required for sending a message
 * creates one or more AbstractMessageModel(s) of a specific type
 * then triggers sending the message using the sendMessagesOffline action
 * finally performs various cleanup operations after sending the message
 *
 */
export const sendCurrentMessageState = createAsyncThunk(
  'messageInput/sendCurrentMessageState',
  async (
    { channelId },
    { dispatch, getState },
  ) => {
    const currentState = getState();
    const platform = getPlatform(currentState);
    const text = getChannelInputText(channelId)(currentState);
    const mediaFiles = getChannelInputStagedFiles(channelId)(currentState);
    const messageSubType = getChannelInputStagedMessageSubType(channelId)(currentState);
    const stagedReplyMessageId = getChannelInputStagedReplyMessageId(channelId)(currentState);
    const stagedProduct = getChannelInputStagedProduct(channelId)(currentState);
    const stagedProcess = getChannelInputStagedProcess(channelId)(currentState);
    const stagedOrder = getChannelInputStagedOrder(channelId)(currentState);

    const stagedForwardMessageIds = (
      getChannelInputStagedForwardMessageIds(channelId)(currentState)
    );
    const otherRecipients = getChannelInputForwardRecipientChannelId(channelId)(currentState);
    const isAgent = getIsAgent(currentState);
    const agentUserStatus = getCurrentUserStatus(currentState);
    const currentUserId = getCurrentUserId(currentState);

    const hasForwardMessageStaged = !!stagedForwardMessageIds.length;

    let messages = [];

    if (hasForwardMessageStaged) {
      const shouldIncludeSeparateTextMessage = stagedForwardMessageIds.length > 1;
      otherRecipients.forEach(recipient => {
        stagedForwardMessageIds.forEach(stagedForwardMessageId => {
          const message = getMessage(stagedForwardMessageId)(currentState);
          const authorName = getMessageAuthorName(stagedForwardMessageId)(currentState);
          const textBody = shouldIncludeSeparateTextMessage ? '' : text;
          const forwardedMessage = createForwardMessageModel({
            message,
            authorName,
            recipient,
            stagedForwardMessageId,
            text: textBody,
            currentUserId,
            platform,
          });
          messages.push(forwardedMessage);
        });
        if (shouldIncludeSeparateTextMessage && text) {
          const textMessage = createTextMessageModel({
            channelId: recipient, text, messageSubType, platform, currentUserId,
          });
          messages.push(textMessage);
        }
      });
    } else {
      if (mediaFiles.length > 0) {
        const mediaMessages = await Promise.all(
          mediaFiles.map(
            file => (isVideo(file)
              ? createVideoMessageModel({
                channelId, file, platform, currentUserId,
              })
              : createMediaMessageModel({
                channelId, file, platform, currentUserId,
              })),
          ),
        );
        messages = [...mediaMessages];
      }

      let message;
      if (stagedReplyMessageId && text?.length > 0) {
        message = createReplyMessageModel({
          stagedReplyMessageId, channelId, text, messageSubType, platform, currentUserId,
        });
      } else if (stagedProduct) {
        message = createProductMessageModel({
          stagedProduct, channelId, text, messageSubType, platform, currentUserId,
        });
      } else if (stagedProcess) {
        message = createProcessMessageModel({
          stagedProcess, channelId, text, messageSubType, platform, currentUserId,
        });
      } else if (stagedOrder) {
        message = createOrderMessageModel({
          stagedOrder, channelId, text, messageSubType, platform, currentUserId,
        });
      } else if (text && text.length > 0) {
        message = createTextMessageModel({
          channelId, text, messageSubType, platform, currentUserId,
        });
      }

      if (message) {
        messages.push({ ...message });
      }
    }

    if (messages.length === 0) {
      return;
    }

    dispatch(channelInputReset({ channelId }));

    await Promise.all(messages.map(async (message) => {
      await dispatch(sendMessagesOffline({ messages: [message] }));
    }));

    if (isAgent) {
      const isChatConnected = getIsChatConnected(getState());
      if (agentUserStatus.type !== ONLINE_STATUS.ONLINE && isChatConnected) {
        dispatch(updateAgentStatus({ statusType: ONLINE_STATUS.ONLINE }));
      }
    }

    const lastMessagePendingId = (
      messages[messages.length - 1]?.clientMessageId
    );

    if (hasForwardMessageStaged && otherRecipients && otherRecipients[0]) {
      const localSelectedChannelId = otherRecipients[0];
      dispatch(changeSelectedChannelId({ selectedChannelId: localSelectedChannelId }));
      dispatch(scrollToMessageId(
        { channelId: localSelectedChannelId, messageId: lastMessagePendingId },
      ));
    } else {
      dispatch(scrollToMessageId({ channelId, messageId: lastMessagePendingId }));
    }
  },
);
