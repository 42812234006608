/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  getChannelMessageDateTagsForSelectedChannel,
  getFetchMessageErrors,
  getLatestMessageForSelectedChannel,
  getMessageImageUrlErrors,
  getMessageImageUrls,
  getMessages,
  getMessagesForChannel,
} from './messagesSelectors';
import { getCurrentUserId, getIsAgent } from '../currentUser/currentUserSelectors';
import { getUsers } from '../users/usersSelectors';
import { getMembers, getMyMemberId } from '../members/membersSelectors';
import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import { getMessageFavorite } from '../favorites/favoritesSelectors';
import { CURRENT_USER_COLOR } from '../../constants/colorMap';
import {
  getChannel,
  getChannelColorMap,
  getChannelIsCraftnote,
} from '../channel/channelSelectors';
import { getUnreadMessageCountForChannel } from '../channels/unreadMessagesSelectors';
import { calculateMediaIconFromType } from '../../helper/mediaHelper';
import { calculateMaxReadIndexJoined } from '../../helper/messageHelpers';
import { isClientMessageId } from '../../helper/reduxOfflineHelper';
import { getIsChatConnected } from '../chatConnection/chatConnectionSelectors';
import { getSelectedMessageIds } from '../messageInput/messageInputSelector';
import { getUser } from '../user/userSelectors';
import { UserType } from '../administration/administrationUsers/administrationUsersConstants';
import { getCurrentUserIsMemberOfChannel } from '../salesSpaceChannels/salesSpaceChannelsSelectors';

const getLocalImgDimensions = (messageId) => (state) => {
  return state.messages.localImgDimensions[messageId];
};

export const getDateTagForMessage = (messageId) => createSelector(
  getChannelMessageDateTagsForSelectedChannel,
  (channelMessageDateTagsForSelectedChannel) => {
    return channelMessageDateTagsForSelectedChannel
      && channelMessageDateTagsForSelectedChannel[messageId];
  },
);
export const getFetchMessageError = (channelId, messageIndex) => createSelector(
  getFetchMessageErrors,
  (fetchMessageErrors) => {
    return fetchMessageErrors[`${channelId}${messageIndex}`];
  },
);

// note: this selector should only be used as dependency for other selectors
// and in thunks. Prefer to select message props through derived selectors
export const getMessage = (messageId) => createSelector(
  getMessages,
  (messages) => messages && messages[messageId],
);

export const getMessageLoaded = (messageId) => createSelector(
  getMessage(messageId),
  (message) => !!message,
);

export const getMessageHasSyncPending = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message && message.id === message.clientMessageId,
);

export const latestMessageIsRead = (channelId) => createSelector(
  getLatestMessageForSelectedChannel,
  getMyMemberId(channelId),
  getMembers,
  (latestMessage, myMemberId, members) => {
    const myMember = members[myMemberId];
    const { index: messageIndex } = latestMessage || {};
    const { lastConsumedMessageIndex: memberLastReadMessage } = myMember || {};
    return messageIndex === memberLastReadMessage;
  },
);

export const calculateChannelMessageReadIndex = (
  channelMessages, channel, currentUserId, isAgent, members, myMemberId,
) => {
  return calculateMaxReadIndexJoined({
    channel, channelMessages, currentUserId, isAgent, members, myMemberId,
  });
};


export const getMessageShowUnreadNumberMessageId = (channelId) => createSelector(
  getMessagesForChannel(channelId),
  getUnreadMessageCountForChannel(channelId),
  getChannel(channelId),
  getCurrentUserId,
  getIsAgent,
  getMembers,
  getMyMemberId(channelId),
  (
    channelMessages,
    unreadCount,
    channel,
    currentUserId,
    isAgent,
    members,
    myMemberId,
  ) => {
    if (!unreadCount) {
      return false;
    }

    if (!channelMessages) {
      return undefined;
    }

    const messageReadIndex = calculateChannelMessageReadIndex(
      channelMessages,
      channel,
      currentUserId,
      isAgent,
      members,
      myMemberId,
    );

    const message = channelMessages.find(m => m.index === messageReadIndex);

    return message?.id;
  },
);


export const getMessageShowUnreadNumber = (channelId, messageId) => createSelector(
  getMessagesForChannel(channelId),
  getUnreadMessageCountForChannel(channelId),
  getChannel(channelId),
  getCurrentUserId,
  getIsAgent,
  getMembers,
  getMyMemberId(channelId),
  (
    channelMessages,
    unreadCount,
    channel,
    currentUserId,
    isAgent,
    members,
    myMemberId,
  ) => {
    if (!unreadCount) {
      return false;
    }
    const messageBeforeIndex = channelMessages.findIndex(message => message.id === messageId) - 1;
    if (messageBeforeIndex < 0) {
      return false;
    }
    const messageReadIndex = calculateChannelMessageReadIndex(
      channelMessages,
      channel,
      currentUserId,
      isAgent,
      members,
      myMemberId,
    );

    const { index: thisMessageIndex } = channelMessages[messageBeforeIndex];

    return messageReadIndex === thisMessageIndex;
  },
);

export const getMessageIsFromMe = (messageId) => createSelector(
  getCurrentUserId,
  getMessage(messageId),
  (currentUserId, message) => {
    return message && message.authorId === currentUserId;
  },
);

export const getMessageChannelId = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message && message.channelId,
);

export const getMessageIsFavorite = (messageId) => createSelector(
  getMessageFavorite(messageId),
  (favorite) => {
    return !!favorite;
  },
);

export const getSelectedMessagesAreAllFavorites = (selectedMessageIds) => createSelector(
  ...selectedMessageIds.map((messageId) => getMessageIsFavorite(messageId)),
  (...favorites) => {
    return favorites.every((favorite) => favorite);
  },
);


export const getMessageIsDeleted = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.deleted,
);

export const getMessageIsForwarded = (messageId) => createSelector(
  getMessage(messageId),
  (message) => {
    return message?.isForwarded;
  },
);
export const getMessageType = (messageId) => createSelector(
  getMessage(messageId),
  (message) => {
    if (!message) {
      return null;
    }
    const { messageType } = message;
    if (messageType) {
      return messageType;
    }
    return message.messageType === 'MEDIA' ? MESSAGE_TYPE.MEDIA : MESSAGE_TYPE.TEXT;
  },
);


export const getMessageProcessOrProductData = (messageId) => createSelector(
  getMessage(messageId),
  getMessageType(messageId),
  (message, messageType) => {
    if (message && messageType === MESSAGE_TYPE.PROCESS) {
      return message.process;
    } if (message && messageType === MESSAGE_TYPE.PRODUCT) {
      return message.product;
    }
    return null;
  },
);

export const getMessageOrder = (messageId) => createSelector(
  getMessage(messageId),
  getMessageType(messageId),
  (message, messageType) => {
    if (message && messageType === MESSAGE_TYPE.ORDER) {
      return message.order;
    }
    return null;
  },
);

export const getMessageDateCreated = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message && moment(message.createdAt),
);

export const getMessageBody = (messageId) => createSelector(
  getMessage(messageId),
  getMessageIsForwarded(messageId),
  (message, isForwarded) => (isForwarded ? message?.forwardedMessageText : message?.text),
);

export const getSelectedMessagesBody = (selectedMessageIds) => createSelector(
  ...selectedMessageIds.map((messageId) => getMessageBody(messageId)),
  (...messagesBody) => messagesBody.join(', '),
);

export const getMessageForwardBody = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.text,
);

export const getMessageFirstLinkPreview = (messageId) => createSelector(
  getMessage(messageId),
  (message) => {
    if (!message) {
      return null;
    }
    const { linkPreviews } = message;
    return linkPreviews && linkPreviews.find((lp) => (lp.description || lp.thumbImg));
  },
);

export const getMessageShouldShowLinkPreview = (messageId) => createSelector(
  getMessageIsDeleted(messageId),
  getMessageIsForwarded(messageId),
  getMessageType(messageId),
  getMessageFirstLinkPreview(messageId),
  (isDeleted, isForwarded, messageType, firstLinkPreview) => {
    return !isDeleted
      && !isForwarded
      && messageType !== MESSAGE_TYPE.REPLY
      && !!firstLinkPreview;
  },
);
export const getMessageShouldShowOptionsMenu = (channelId, messageId) => createSelector(
  getMessageIsDeleted(messageId),
  getSelectedMessageIds(channelId),
  (
    isDeleted,
    selectedMessageIds,
  ) => {
    return !isDeleted && !selectedMessageIds.length;
  },
);

export const isSelectableMessage = (channelId, messageId) => createSelector(
  getMessageIsDeleted(messageId),
  getMessageType(messageId),
  (
    isDeleted,
    messageType,
  ) => {
    return !isDeleted && messageType !== MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION;
  },
);

export const getSubstituteNotificationText = (messageId) => createSelector(
  getIsAgent,
  getMessage(messageId),
  getMessageBody(messageId),
  (isAgent, message, messageBody) => {
    if (!message) {
      return undefined;
    }
    const { craftsmanText } = message;
    return isAgent ? messageBody : craftsmanText;
  },
);

export const getMessageReplyInfo = (messageId) => createSelector(
  getMessage(messageId),
  getMessageType(messageId),
  (message, messageType) => {
    if (!message || messageType !== MESSAGE_TYPE.REPLY) {
      return undefined;
    }
    const { replyMessageId: replyId, index: replyIndex } = message;
    return { replyId, replyIndex };
  },
);

export const getAuthorNameColor = (channelId, id) => createSelector(
  getCurrentUserId,
  getChannelColorMap(channelId),
  (currentUserId, channelColorMap) => {
    if (id === currentUserId) {
      return CURRENT_USER_COLOR;
    }
    return channelColorMap && channelColorMap[id];
  },
);

export const getMessageAuthorErpCustomerNumber = (
  channelId,
  originalAuthorId,
  forwardedAuthorId,
  currentUser,
) => createSelector(
  getChannel(channelId),
  getUser(originalAuthorId),
  getUser(forwardedAuthorId),
  getIsAgent,
  (
    channel, originalAuthor, forwardedAuthor, isAgent,
  ) => {
    if (forwardedAuthor?.type === UserType.AGENT
      && originalAuthor?.type === UserType.CRAFTSMAN
      && isAgent
    ) {
      const primaryHouseId = channel?.primaryHouseId;
      if (primaryHouseId?.length) {
        const channelConnection = originalAuthor?.connections?.find(connection => {
          return connection.houseId === primaryHouseId;
        });
        return channelConnection?.customerNumber;
      }

      const houseIds = (currentUser?.houses || []).map((house) => house.id);
      const connections = originalAuthor?.connections || [];

      const customerNumbers = connections
        .filter((connection) => houseIds.includes(connection.houseId))
        .map((connection) => connection.customerNumber)
        .filter((customerNumber) => customerNumber);

      return customerNumbers.join(', ');
    }
    return undefined;
  },
);

export const getMessageAuthorId = (messageId) => createSelector(
  getMessage(messageId),
  (message) => {
    if (!message) {
      return undefined;
    }
    const { authorId } = message;
    return authorId;
  },
);

export const getMessageAuthorName = (messageId) => createSelector(
  getMessageType(messageId),
  getUsers,
  getMessageAuthorId(messageId),
  (messageType, users, authorId) => {

    if (messageType === MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION) {
      return 'system';
    }
    /* eslint-disable camelcase */
    const { friendlyName } = users[authorId] || {};
    return friendlyName;
  },
);

export const getMessageQuotedAuthorName = (messageId) => createSelector(
  getMessage(messageId),
  getMessageIsForwarded(messageId),
  getMessageQuotedAuthorId(messageId),
  getUsers,
  (message, isForwarded, quotedAuthor, users) => {
    if (isForwarded) {
      return message?.forwardedMessageAuthorName;
    }
    const { friendlyName } = users[quotedAuthor] || {};
    return friendlyName;
  },
);


export const getMessageQuotedAuthorId = (messageId) => createSelector(
  getMessageIsForwarded(messageId),
  getMessageAuthorId(messageId),
  getMessage(messageId),
  (isForwarded, authorId, message) => {
    if (!isForwarded) {
      return authorId;
    }
    return message?.authorId || {};
  },
);


export const getMessageQuotedDateCreated = (messageId) => createSelector(
  getMessageIsForwarded(messageId),
  getMessageDateCreated(messageId),
  getMessage(messageId),
  (isForwarded, dateCreated, message) => {
    if (!isForwarded) {
      return dateCreated;
    }
    return message && moment(message.forwardedMessageCreatedAt);
  },
);

export const getMessageMediaRelativePath = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.mediaRelativePath,
);

export const getMessageReplyId = (messageId) => createSelector(
  getMessageReplyInfo(messageId),
  (messageReplyInfo) => messageReplyInfo?.replyId,
);

export const getPendingMediaMessageLocalFile = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message.localFile,
);

export const getClientMessageId = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.clientMessageId,
);

export const getMessageIsPending = (messageId) => () => {
  return isClientMessageId(messageId);
};


export const getMessageMediaFilename = (messageId) => createSelector(
  getMessage(messageId),
  (message) => {
    return message?.filename;
  },
);

export const getMessageMediaIsImage = (messageId) => createSelector(
  getMessage(messageId),
  (message) => {
    return !!message?.contentType?.match(/^image/);
  },
);

export const getMessageAttributesText = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.messageData?.text,
);

export const getMessageThumbnail = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.imgThumbnail || message?.pdfThumbnail,
);

export const getMessageHasMedia = (messageId) => createSelector(
  getMessage(messageId),
  getMessageType(messageId),
  getReplyMessageHasMedia(messageId),
  (message, messageType, replyMessageHasMedia) => {
    if (messageType === MESSAGE_TYPE.REPLY) {
      return replyMessageHasMedia;
    }
    return message && (message.mediaRelativePath || message.videoId);
  },
);

export const getReplyMessageHasMedia = (messageId) => createSelector(
  getMessageReplyId(messageId),
  getMessages,
  (replyId, messages) => {
    if (!replyId) {
      return undefined;
    }
    const message = messages && messages[replyId];
    return message && (message.mediaRelativePath || message.videoId);
  },
);

export const getMessageCanDownload = (channelId, messageId) => createSelector(
  getMessageHasSyncPending(messageId),
  getMessageHasMedia(messageId),
  (messagePending, isMedia) => {
    return !messagePending && isMedia;
  },
);

export const getMessageIndex = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message && message.index,
);

export const getMessageMediaHumanFilesize = (messageId) => createSelector(
  getMessage(messageId),
  (message) => {
    if (!message?.mediaSize) {
      return null;
    }
    const { mediaSize: size } = message;
    if (size >= 1000 * 1000 * 100) {
      return `${Math.round(size / (1000 * 1000 * 1000) * 10) / 10} GB`;
    }

    if (size >= 1000 * 100) {
      return `${Math.round(size / (1000 * 1000) * 10) / 10} MB`;
    }

    if (size >= 100) {
      return `${Math.round(size / 1000 * 10) / 10} KB`;
    }

    return `${size} B`;
  },
);

export const getMessageImgDimensions = (messageId) => createSelector(
  getLocalImgDimensions(messageId),
  getMessage(messageId),
  (localImgDimensions, message) => {
    if (localImgDimensions) {
      return localImgDimensions;
    }
    const { imgDimensionsWidth, imgDimensionsHeight } = message || {};
    return { w: imgDimensionsWidth, h: imgDimensionsHeight };
  },
);

export const getMessageImageUrl = (messageId) => createSelector(
  getMessageImageUrls,
  (messageImageUrls) => messageImageUrls[messageId],
);

export const getMessageImageUrlError = (messageId) => createSelector(
  getMessageImageUrlErrors,
  (messageImageUrlErrors) => messageImageUrlErrors[messageId],
);

export const getMessageLinkPreviews = (messageId) => createSelector(
  getMessage(messageId),
  (messageRaw) => messageRaw?.linkPreviews || [],
);

export const getMessageCanForward = (channelId, messageId) => createSelector(
  getMessageHasSyncPending(messageId), // TODO SMOOT-1331
  getCurrentUserIsMemberOfChannel(channelId),
  (messagePending, isJoined) => {
    return isJoined && !messagePending;
  },
);

export const getSelectedMessagesCanForward = (channelId, messageIds) => createSelector(
  ...messageIds.map((messageId) => getMessageCanForward(channelId, messageId)),
  (...allResults) => {
    return allResults.every((canForward) => canForward);
  },
);

export const getMessageCanReply = (channelId, messageId) => createSelector(
  getMessageHasSyncPending(messageId), // TODO SMOOT-1331
  getChannelIsCraftnote(channelId),
  getMessageMediaIsImage(messageId),
  getCurrentUserIsMemberOfChannel(channelId),
  (messagePending, isCraftnote, isImage, isJoined) => {
    return isJoined && !(isImage && isCraftnote) && !messagePending;
  },
);

export const getMessageCanReport = (messageId) => createSelector(
  getMessageIsFromMe(messageId),
  (isFromMe) => {
    return !isFromMe;
  },
);

export const getSelectedMessagesCanReport = (messageIds) => createSelector(
  ...messageIds.map((messageId) => getMessageCanReport(messageId)),
  (...allResults) => {
    return allResults.every((canReport) => canReport);
  },
);


export const getMessageCanDelete = (channelId, messageId) => createSelector(
  getMessageHasSyncPending(messageId),
  getChannelIsCraftnote(channelId),
  getMessageIsFromMe(messageId),
  getMessageIsDeleted(messageId),
  getCurrentUserIsMemberOfChannel(channelId),
  getIsChatConnected,
  (messagePending, isCraftnote, isFromMe, isDeleted, isJoined, isChatConnected) => {
    if (messagePending && isChatConnected) {
      // don't allow since it can cause issues when Twilio already started transmitting the message
      return false;
    }
    return isJoined && isFromMe && !isDeleted && !isCraftnote;
  },
);

export const getSelectedMessagesCanDelete = (channelId, messageIds) => createSelector(
  ...messageIds.map((messageId) => getMessageCanDelete(channelId, messageId)),
  getCurrentUserIsMemberOfChannel(channelId),
  getIsChatConnected,
  (...allResults) => {
    const isJoined = allResults[messageIds.length];
    const isChatConnected = allResults[messageIds.length + 1];
    const canDeleteArray = allResults.slice(0, messageIds.length);

    if (!isJoined || !isChatConnected) {
      return false;
    }

    return canDeleteArray.every((canDelete) => canDelete);
  },
);

export const getMessageCanFavorite = (channelId, messageId) => createSelector(
  getMessageHasSyncPending(messageId), // TODO SMOOT-1331
  (messagePending) => !messagePending,
);

export const getSelectedMessagesCanFavorite = (channelId, messageIds) => createSelector(
  ...messageIds.map((messageId) => getMessageCanFavorite(channelId, messageId)),
  (...allResults) => {
    return allResults.every((canFavorite) => canFavorite);
  },
);

export const getMessageCanCopy = (channelId, messageId) => createSelector(
  getMessageType(messageId),
  (messageType) => messageType === MESSAGE_TYPE.TEXT || messageType === MESSAGE_TYPE.REPLY,
);

export const getSelectedMessagesCanCopy = (channelId, messageIds) => createSelector(
  ...messageIds.map((messageId) => getMessageCanCopy(channelId, messageId)),
  (...allResults) => {
    return allResults.every((canCopy) => canCopy);
  },
);

export const getMediaMessageContentType = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.contentType,
);

export const getMessageFooterText = (messageId) => createSelector(
  getMessageHasMedia(messageId),
  getMessageMediaIsImage(messageId),
  getMessageMediaHumanFilesize(messageId),
  (hasMedia, isImage, fileSize) => {
    return (hasMedia && !isImage && fileSize) || null;
  },
);

export const getMediaMessageIcon = (messageId) => createSelector(
  getMediaMessageContentType(messageId),
  (contentType) => calculateMediaIconFromType(contentType),
);

/*
  Video Selectors
*/

export const getVideoTitle = (messageId) => createSelector(
  getMessage(messageId),
  (video) => video.videoTitle,
);

export const getVideoDimensions = (messageId) => createSelector(
  getMessage(messageId),
  (video) => ({ width: video.videoWidth, height: video.videoHeight }),
);

export const getVideoPlaybackId = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.videoPlaybackId,
);

export const getVideoDownloadReady = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.videoDownloadReady,
);

export const getVideoDownloadResolution = (messageId) => createSelector(
  getMessage(messageId),
  (message) => message?.videoDownloadResolution,
);
