/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ERROR_DIALOG } from '../../constants/errorDialogConstants';


interface ErrorDialogPayload {
  dialogErrorButtonText?: string;
  dialogErrorMsg?: string;
  dialogErrorTitle?: string;
  dialogErrorCode?: string;
  errorDialog?: string;
}

interface ErrorDialogState {
  dialogError: ErrorDialogPayload;
}

const initialState: ErrorDialogState = {
  dialogError: {
    dialogErrorButtonText: undefined,
    dialogErrorMsg: undefined,
    dialogErrorTitle: undefined,
    dialogErrorCode: undefined,
    errorDialog: undefined,
  },
};

const errorDialogSlice = createSlice({
  name: 'errorDialog',
  initialState,
  reducers: {
    openErrorDialog: (state, action: PayloadAction<ErrorDialogPayload>) => {
      const {
        dialogErrorButtonText,
        dialogErrorMsg,
        dialogErrorTitle,
        dialogErrorCode,
        errorDialog = ERROR_DIALOG.MODAL,
      } = action.payload;
      state.dialogError = {
        dialogErrorButtonText,
        dialogErrorMsg,
        dialogErrorTitle,
        dialogErrorCode,
        errorDialog,
      };
    },
    closeErrorDialog: (state) => {
      state.dialogError = {
        dialogErrorButtonText: undefined,
        dialogErrorMsg: undefined,
        dialogErrorTitle: undefined,
        errorDialog: undefined,
        dialogErrorCode: undefined,
      };
    },
  },
});


export const {
  openErrorDialog,
  closeErrorDialog,
} = errorDialogSlice.actions;

export default errorDialogSlice.reducer;
