import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function SelectedMessageIndicator({ selectedMessages }) {

  if (selectedMessages.length === 1) {
    return (
      <div className={styles.text}>
        1 Nachricht ausgewählt
      </div>
    );
  }
  return (
    <div className={styles.text}>
      {selectedMessages.length}
      {' '}
      Nachrichten ausgewählt
    </div>
  );
}

SelectedMessageIndicator.propTypes = {
  selectedMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SelectedMessageIndicator;
