import { createContext, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import createClient, { Middleware } from 'openapi-fetch';
import type { Persister } from '@tanstack/react-query-persist-client';
import { getApiBaseUrl } from '../slices/theme/themeSelectors';
import type { paths } from '../../types/openapi';
import { getErrorMsg } from '../helper/errorMessages';
import { DEBUG_USER_ID } from '../clients/gccApiClient/gccApiClient';
// eslint-disable-next-line import/no-cycle
import { onRestoreCacheSuccess, setMutationsDefaults } from '../offline-settings/react-query-rehydrations';
// eslint-disable-next-line import/no-cycle
import { BEEM_PERSISTED_STORE_BUSTER, cacheTime, reactQueryClient } from '../clients/api/client';
// eslint-disable-next-line import/no-cycle

type ClientType = ReturnType<typeof createClient<paths>>;

export const ApiContext = createContext<ClientType | null>(null);

const createAuthMiddleware = (): Middleware => ({
  async onRequest(req) {

    const debugUserId = localStorage.getItem(DEBUG_USER_ID);
    if (debugUserId) {
      req.headers.set('X-TEST-ENV-ONLY-User-Id', debugUserId);
    }

    return req;
  },
});

const createErrorMiddlware = (): Middleware => ({
  async onResponse(res) {
    if (res.status >= 400) {
      const error = await res.json();
      const { error_code: errorCode, description } = error;
      const errorMessage = (getErrorMsg(errorCode) || description || getErrorMsg('chat.default') || 'Unbekannter Fehler') as string;

      throw new Error(errorMessage);
    }
    return undefined;
  },
});

interface PersistedQueryProviderProps {
  children: ReactNode;
  persister: Persister;
}

const PersistedQueryProvider = (props: PersistedQueryProviderProps) => {
  const { children, persister } = props;

  setMutationsDefaults();

  return (
    <PersistQueryClientProvider
      client={reactQueryClient}
      persistOptions={{ persister, maxAge: cacheTime, buster: BEEM_PERSISTED_STORE_BUSTER }}
      onSuccess={onRestoreCacheSuccess}
    >
      {children}
      <ReactQueryDevtools initialIsOpen position="left" />
    </PersistQueryClientProvider>

  );
};

interface ApiProviderProps {
  children: ReactNode;
  persister: Persister;
}

const ApiProvider = (props: ApiProviderProps) => {
  const { children, persister } = props;

  const baseUrl = useSelector(getApiBaseUrl);
  /**
   * There are two clients here:
   * - reactQueryClient - the React query client
   * - openApiFetchClient - typed fetch client (openapi-fetch) using the OpenAPi spec from our API
   * ReactQueryClient is using the OpenApiFetchClient in the queryFn to make HTTP requests.
   */
  const openApiFetchClient = createClient<paths>({
    baseUrl,
    credentials: 'include',
  });

  openApiFetchClient.use(createAuthMiddleware());
  openApiFetchClient.use(createErrorMiddlware());
  return (
    <ApiContext.Provider value={openApiFetchClient}>
      <PersistedQueryProvider persister={persister}>
        {children}
      </PersistedQueryProvider>
    </ApiContext.Provider>
  );
};

export default ApiProvider;
