import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDashboardSelectedTicket } from 'gcs-common/slices/tickets/ticketsSelectors';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import useTicketNotifications from 'gcs-common/hooks/useTicketNotifications';
import styles from './styles.module.scss';
import TicketDashboardHeader from './TicketDashboardHeader/TicketDashboardHeader';
// eslint-disable-next-line import/no-cycle
import TicketDashboardFilters from './TicketDashboardFilters/TicketDashboardFilters';
import TicketForm from '../TicketForm/TicketForm';
// eslint-disable-next-line import/no-cycle
import TicketDashboardTable from './TicketDashboardTable/TicketDashboardTable';
import useEscKey from '../../../customHooks/useEscKey';
import TicketDetails from '../TicketDetails/TicketDetails';

export interface FiltersValues {
  status: string,
  startDate: string | null,
  endDate: string | null,
  erpCustomerIds: string[],
  creatorId: string | null,
  search: string,
}

const TicketDashboard = () => {
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedTicket = useSelector(getDashboardSelectedTicket());
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedCreatorId = useSelector(getCurrentUserId);
  const [filters, setFilters] = useState<FiltersValues>({
    status: '',
    startDate: null,
    endDate: null,
    erpCustomerIds: [],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    creatorId: selectedCreatorId,
    search: '',
  });

  const closeDrawer = () => {
    setShowForm(false);
    setShowDetails(false);
  };

  useEscKey(closeDrawer);

  useTicketNotifications();

  return (
    <div className={styles.ticketDashboard}>
      {(showDetails || showForm)
        && <div className={styles.overlay} onClick={closeDrawer} aria-hidden />}

      <TicketDashboardHeader />

      <TicketDashboardFilters setShowForm={setShowForm} setFilters={setFilters} filters={filters} />

      <TicketDashboardTable setShowDetails={setShowDetails} filters={filters} />

      {showForm && (
        <div className={styles.dashboardDrawer}>
          <TicketForm onClose={() => setShowForm(false)} />
        </div>
      )}

      {showDetails && selectedTicket && (
        <div className={styles.dashboardDrawer}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <TicketDetails ticket={selectedTicket} onClose={() => setShowDetails(false)} />
        </div>
      )}
    </div>
  );
};

export default TicketDashboard;
