import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputIsSendingDisabled,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { sendCurrentMessageState } from 'gcs-common/slices/messageInput/messageInputThunks/sendCurrentMessageState';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';
import Button from '../../Button/Button';

const SendButton = () => {

  const dispatch = useDispatch();
  const selectedChannelId = useSelector(getSelectedChannelId);
  const disabled = useSelector(getChannelInputIsSendingDisabled(selectedChannelId));

  const sendMessage = useCallback(async () => {
    await dispatch(sendCurrentMessageState({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  return (
    <Button
      disabled={disabled}
      type="button"
      onClick={sendMessage}
      className={styles.sendButton}
      alt="send icon"
    >
      <IconComponent Icon={ICON.SEND} color={ICON_COLOR.WHITE} style={{ marginLeft: '2px' }} />
    </Button>
  );
};

export default SendButton;
