import { useCallback, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedAgent, getCreateAgentDirectChannelDefaultErrorMessage,
  getCreateAgentDirectChannelErrorMessage,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import {
  resetCreateAgentDirectChannelDefaultErrorMessage, resetCreateAgentDirectChannelErrorMessage,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import {
  createAgentAgentDirectChannel,
  createAgentDirectChannel, fetchAgentDirectChannels,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import USER_TYPE from 'gcs-common/helper/userTypeHelper';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import Tabs from '../../../Tabs/Tabs';
import FormikUserSelect from '../../shared/FormikUserSelect/FormikUserSelect';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const initialData = {
  userId: undefined,
};

const CHANNEL_TYPE_SELECTION_TABS = {
  AGENTS: 'agents',
  CRAFTSMAN: 'craftsman',
};

const CompanyCreateValidationSchema = Yup.object().shape({
  userId: Yup.string().nullable().required('Erforderlich'),
});

const AgentDirectChannelCreate = ({ onCancel, onSuccess }) => {

  const selectedAgent = useSelector(getSelectedAgent) || {};
  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateAgentDirectChannelErrorMessage);
  const defaultErrorMessage = useSelector(getCreateAgentDirectChannelDefaultErrorMessage);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetCreateAgentDirectChannelDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateAgentDirectChannelDefaultErrorMessage());
    dispatch(resetCreateAgentDirectChannelErrorMessage());
  }, [dispatch]);

  const onChannelMembersChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateAgentDirectChannelErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const [currentTab, setCurrentTab] = useState(CHANNEL_TYPE_SELECTION_TABS.AGENTS);

  const onTabChanged = useCallback((tab) => {
    if (errorMessage) {
      dispatch(resetCreateAgentDirectChannelErrorMessage());
    }
    setCurrentTab(tab);
  }, [dispatch, errorMessage]);

  const {
    id: agentId,
    friendlyName,
    name,
  } = selectedAgent;

  const submit = useCallback(async (values, { setSubmitting }) => {
    let result;
    if (currentTab === CHANNEL_TYPE_SELECTION_TABS.AGENTS) {
      const { userId: agentId2 } = values;
      result = await dispatch(createAgentAgentDirectChannel({ agentId, agentId2 }));
    } else {
      const { userId } = values;
      result = await dispatch(createAgentDirectChannel({ agentId, userId }));
    }
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
      await dispatch(fetchAgentDirectChannels({ agentId }));
    }
  }, [currentTab, onSuccess, dispatch, agentId]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={CompanyCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>

          <div className={styles.userNameColumn}>
            <div className={styles.userNameLabel}>
              Mitarbeiter
            </div>
            <div className={styles.userName}>
              {friendlyName || name}
            </div>
          </div>

          <Tabs
            onActiveTabChanged={onTabChanged}
            tabs={{
              agents: {
                name: 'Mitarbeiter',
                component: (
                  <div className={styles.row}>

                    <FormikUserSelect
                      selectKey="agentDirectChannelCreateAgentSelection"
                      userType={USER_TYPE.AGENT}
                      key="agents"
                      label="Mitarbeiter*"
                      name="userId"
                      labelKey="friendlyName"
                      valueKey="id"
                      localFilter={[agentId]}
                      onChange={onChannelMembersChanged}
                    />
                  </div>

                ),
              },
              craftsmen: {
                name: 'Handwerker',
                component: (
                  <div className={styles.row}>

                    <FormikUserSelect
                      selectKey="agentDirectChannelCreateCraftsmanSelection"
                      userType={USER_TYPE.CRAFTSMAN}
                      key="craftsmen"
                      label="Handwerker*"
                      name="userId"
                      labelKey="friendlyName"
                      valueKey="id"
                      onChange={onChannelMembersChanged}
                    />
                  </div>

                ),
              },
            }}
          />

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AgentDirectChannelCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AgentDirectChannelCreate;
