/* eslint-disable no-case-declarations */
import { captureMessage } from '@sentry/react';
import BaseClient from '../baseClient';
import { byKey } from '../../helper/byKey';
import { debugLogger } from '../../helper/debugLogger';
import CHAT_CONNECTION_STATE from '../../constants/ConnectionState';
import { DisconnectedError } from './errors/DisconnectedError';
import ChannelConverter from './converters/channelConverter';
import UserConverter from './converters/userConverter';
import MemberConverter from './converters/memberConverter';
import MessageConverter from './converters/messageConverter';

export const ChatClientStateEvents = {
  tokenAboutToExpire: 'tokenAboutToExpire',
  tokenExpired: 'tokenExpired',
  connectionError: 'connectionError',
  connectionStateChanged: 'connectionStateChanged',
};
export const ChatEvents = {
  currentUserUpdated: 'currentUserUpdated',
  channelRemoved: 'conversationRemoved',
  channelLeft: 'conversationLeft',
  channelUpdated: 'conversationUpdated',
  memberLeft: 'participantLeft',
  memberUpdated: 'participantUpdated',
  memberJoined: 'participantJoined',
  messageAdded: 'messageAdded',
  messageUpdated: 'messageUpdated',
  typingStarted: 'typingStarted',
  typingEnded: 'typingEnded',
  channelJoined: 'conversationJoined',
  userUpdated: 'userUpdated',
};

export const MAX_RETRY_COUNT = 3;

class CachedTwilioEntity {
  // this manually caches twilio entities ONLY with the purpose that we can call functions on them.
  // e.g.
  _remoteGetter;

  _cache = {};

  constructor(remoteGetter, idKeyName = 'sid') {
    // necessary to wrap getter functions in order to not make them "use" the wrong context
    // if you want to know more https://www.codementor.io/@dariogarciamoya/understanding-this-in-javascript-with-arrow-functions-gcpjwfyuc
    this._remoteGetter = (...args) => remoteGetter(...args);
    this._idKeyName = idKeyName;
  }

  addToCache = (records) => {
    if (!Array.isArray(records)) {
      // eslint-disable-next-line no-param-reassign
      records = [records];
    }
    const recordsById = byKey(this._idKeyName, records);
    this._cache = {
      ...this._cache,
      ...recordsById,
    };
  };

  get = async (key) => {
    const cached = this._cache[key];
    if (cached) {
      return cached;
    }
    const fetched = await this._remoteGetter(key);
    this.addToCache(fetched);
    return fetched;
  };
}

class ChatClient extends BaseClient {

  _twilioClient;

  _chatConnectionState = CHAT_CONNECTION_STATE.DISCONNECTED;

  _init = false;

  _twilioChannels;

  _twilioUsers;

  _eventListeners = {};

  _twilioChannelConverter;

  _twilioMemberConverter;

  _twilioUserConverter;

  _twilioMessageConverter;

  // TODO: Wait for stateChanged?
  init = async (twilioToken) => {
    await this.shutdown();
    const clientOptions = { logLevel: 'silent' };
    // Dynamic import so that Vite can code split twilio into another chunk
    // and we reduce the size of the first chunk that is loaded
    const { Client } = await import('@twilio/conversations');
    const twilioClient = new Client(twilioToken, clientOptions);
    const { getConversationBySid, getUser } = twilioClient;
    this._patchGetUser(twilioClient);
    this._twilioChannels = new CachedTwilioEntity(getConversationBySid.bind(twilioClient));
    this._twilioUsers = new CachedTwilioEntity(getUser.bind(twilioClient), 'identity');
    this._twilioClient = twilioClient;
    this._twilioClient.on('connectionStateChanged', (connectionState) => {
      this._chatConnectionState = connectionState;
    });
    this._twilioChannelConverter = new ChannelConverter();
    this._twilioUserConverter = new UserConverter();
    this._twilioMessageConverter = new MessageConverter();
    this._twilioMemberConverter = new MemberConverter();
    this._init = true;
  };

  on = (eventName, callback) => {
    const oldListener = this._eventListeners[eventName];
    if (oldListener) {
      if (eventName === 'currentUserUpdated') {
        this._twilioClient.user.off('updated', oldListener);
      } else {
        this._twilioClient.off(eventName, oldListener);
      }
      debugLogger('WARNING: Tried to register more than one listener for event', eventName);
      captureMessage(
        `Tried to register more than one listener for event [${eventName}], 
        removed old listener`,
        { level: 'warning', tags: { eventName } },
      );
    }
    const wrappedCallback = this.eventMapper(eventName, callback);
    if (eventName === 'currentUserUpdated') {
      this._twilioClient.user.on('updated', wrappedCallback);
    } else {
      this._twilioClient.on(eventName, wrappedCallback);
    }
    this._eventListeners[eventName] = wrappedCallback;
  };

  // NOTE: Intercepts all events to convert data and a
  // waits until properties are fully load
  eventMapper = (eventName, callback) => async (eventData) => {
    switch (eventName) {
      case 'currentUserUpdated':
      case 'userUpdated': {
        const { user, updateReasons } = eventData;// eslint-disable-next-line no-underscore-dangle
        await user._ensureFetched();
        callback({ user: this._twilioUserConverter.fromTwilioDataModel(user), updateReasons });
        break;
      }
      case 'userSubscribed':
      case 'userUnsubscribed':
        await eventData.entityPromise;
        callback(this._twilioUserConverter.fromTwilioDataModel(eventData));
        break;
      case 'conversationUpdated': {
        if (eventData.updateReasons.includes('dateUpdated')
          && eventData.updateReasons.length === 1) {
          debugLogger('NOTE: We currently ignore tw channelUpdated:dateUpdated');
          return;
        }
        const { conversation, updateReasons } = eventData;
        await conversation.entityPromise;
        callback({ updateReasons,
          channel: this._twilioChannelConverter.fromTwilioDataModel(
            conversation,
          ) });
        break;
      }
      case 'conversationJoined':
      case 'conversationAdded':
      case 'conversationRemoved':
      case 'conversationLeft':
      case 'conversationInvited':
        await eventData.entityPromise;
        callback(this._twilioChannelConverter.fromTwilioDataModel(eventData));
        break;
      case 'participantUpdated': {
        const { participant, updateReasons } = eventData;
        callback({ updateReasons,
          member: this._twilioMemberConverter.fromTwilioDataModel(
            participant,
          ) });
        break;
      }
      case 'participantJoined':
      case 'participantLeft':
      case 'typingEnded':
      case 'typingStarted':
        callback(this._twilioMemberConverter.fromTwilioDataModel(eventData));
        break;
      case 'messageUpdated': {
        const { message, updateReasons } = eventData;
        callback({ updateReasons,
          message: this._twilioMessageConverter.fromTwilioDataModel(
            message,
          ) });
        break;
      }
      case 'messageAdded':
      case 'messageRemoved':
        callback(this._twilioMessageConverter.fromTwilioDataModel(eventData));
        break;
      default:
        callback(eventData);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  _patchGetUser = (twilioClient) => {
    // Unfortunately, the twilio client has an encoding issue therefore we must
    // manually properly encode an url after the user is fetched.
    const getUserRaw = twilioClient.getUser.bind(twilioClient);
    // eslint-disable-next-line no-param-reassign
    twilioClient.getUser = async (...args) => {
      const twUser = await getUserRaw(...args);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { self: selfLink, __URI_ALREADY_ENCODED } = twUser.links;
      if (!__URI_ALREADY_ENCODED) {
        twUser.links.self = encodeURI(selfLink);
        // eslint-disable-next-line no-underscore-dangle
        twUser.links.__URI_ALREADY_ENCODED = true;
      }
      return twUser;
    };
  };

  getInitStatus = () => {
    return this._init;
  };

  _getIsConnected = () => {
    if (!this._init) {
      this._throwNotInitError();
    }
    return this._chatConnectionState === CHAT_CONNECTION_STATE.CONNECTED;
  };

  channelStartTyping = async ({ externalChannelId }) => {
    debugLogger('channelStartTyping', externalChannelId);
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    const twilioChannel = await this._twilioChannels.get(externalChannelId);
    await twilioChannel.typing();
  };

  channelSetAllMessagesConsumed = async ({ externalChannelId }) => {
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    debugLogger('channelSetAllMessagesConsumed', externalChannelId);
    const twilioChannel = await this._twilioChannels.get(externalChannelId);
    try {
      await twilioChannel.setAllMessagesRead();
    } catch (error) {
      // Note: Bug in Twilio SDK (?):
      // This will always throw a 'not found' error, but it still seems to work
      // {status: 404, message: "Conversation not found", code: 50350}
    }
  };

  getChannelParticipants = async (channelExternalId) => {
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    const twilioChannel = await this._twilioChannels.get(channelExternalId);
    const participants = await twilioChannel.getParticipants();

    return {
      participants: participants?.map(
        participant => {
          return this._twilioMemberConverter.fromTwilioDataModel(participant);
        },
      ),
    };
  };

  getMessage = async (externalChannelId, messageIndex) => {
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    const twilioChannel = await this._twilioChannels.get(externalChannelId);
    const { items } = await twilioChannel.getMessages(1, messageIndex);
    const message = items[0] ? items[0] : null;
    if (message.state.index !== messageIndex) {
      // If the index of the returned message is different to the index of the searched message
      // means that the message with the specific index does not exist in twilio
      return undefined;
    }
    return this._twilioMessageConverter.fromTwilioDataModel(message);
  };

  getChannel = async (externalChannelId) => {
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    const twilioChannel = await this._twilioClient.getConversationBySid(externalChannelId);
    this._twilioChannels.addToCache(twilioChannel);
    return this._twilioChannelConverter.fromTwilioDataModel(twilioChannel);
  };

  getUser = async (externalIdentity) => {
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    const twilioUser = await this._twilioUsers.get(externalIdentity);
    return this._twilioUserConverter.fromTwilioDataModel(twilioUser);
  };

  setPushRegistrationId(notificationsChannelType, token) {
    debugLogger('setPushRegistrationId');
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    this._twilioClient.setPushRegistrationId(notificationsChannelType, token);
  }

  unsetPushRegistrationId(notificationsChannelType) {
    debugLogger('unsetPushRegistrationId');
    if (!this._getIsConnected()) {
      throw new DisconnectedError();
    }
    this._twilioClient.unsetPushRegistrationId(notificationsChannelType);
  }

  async removeAllListeners() {
    this._twilioClient.removeAllListeners();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._twilioClient.user && this._twilioClient.user.removeAllListeners();
    this._eventListeners = {};
  }

  async removeListeners(eventNames) {
    eventNames.forEach((eventName) => {
      const listener = this._eventListeners[eventName];
      if (listener) {
        if (eventName === 'currentUserUpdated') {
          this._twilioClient.user.off('updated', listener);
        } else {
          this._twilioClient.off(eventName, listener);
        }
        delete this._eventListeners[eventName];
      }
    });
  }

  async shutdown() {
    if (!this._twilioClient) {
      return;
    }
    await this._twilioClient.shutdown();
    this._eventListeners = {};
  }

}

export default new ChatClient();
