import favicon from './assets/favicon.svg';
import simpleLogo from './assets/simple_logo.svg';
import logo from './assets/logo.svg';
import chatLogo from './assets/chat_logo.svg';
import privacy from '../commonAssets/beemPrivacyText';
import terms from '../commonAssets/termsText';
import imprint from './assets/imprintText';
import opLogo from './assets/hfhop.png';

const config = {
  title: 'HFH BEEM',
  description: 'Einen einfachen, schnellen und rechtssicheren Kontakt zum persönlichen HFH-Kontakt ermöglicht BEEM – die smarte Chat-Plattform.',
  identifier: 'hfh',
  appName: 'HFH',
  defaultBrand: 'HFH',
  url: 'hfhbeem.de',
  apiBaseUrl: 'https://api.hfhbeem.de',
  helpUrl: 'https://hilfe.getbeem.de/',
  iosStoreUrl: 'https://apps.apple.com/us/app/hfh-beem/id1638748972',
  androidStoreUrl: 'https://play.google.com/store/apps/details?id=de.hfhbeem',
  supportEmail: 'support@getbeem.de',
  favicon,
  logo,
  chatLogo,
  simpleLogo,
  opLogo,
  packageId: 'de.hfhbeem',
  privacyText: privacy,
  termsText: terms,
  usercentricsId: 'CZ5jImIoB',
  imprintText: imprint,
  welcomeText: 'Willkommen bei HFH BEEM!',
  identifierPlaceholder: 'Mobilnummer oder E-Mail Adresse',
  mainText: 'Bitte geben Sie Ihre Mobilnummer oder E-Mail Adresse ein, um sich anzumelden.',
};

export default config;
