/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAgentAvailabilityDate,
  fetchAgentAvailabilityStatus,
  fetchAgentOnlineStatus,
  updateAgentAvailabilityStatus, updateAgentStatus,
} from './currentAgentThunks';

const asyncState = {
  fetch: {
    loading: 'idle',
    error: null,
    errorCode: null,
  },
  updateAgentStatus: {
    loading: 'idle',
    error: null,
  },
  fetchAgentOnlineStatus: {
    loading: 'idle',
    error: null,
  },
  agentAvailabilityStatusFetch: {
    loading: 'idle',
    error: null,
  },
  agentAvailabilityStatusUpdate: {
    loading: 'idle',
    error: null,
  },
};

const initialState = {
  ...asyncState,
  agentAvailabilityDates: {},
  agentAvailabilityStatus: undefined,
  agentOnlineStatus: 'AVAILABLE',
};

const currentAgentSlice = createSlice({
  name: 'currentAgentUser',
  initialState,
  reducers: {},
  extraReducers: {
    // FETCH
    [fetchAgentAvailabilityDate.pending]: (state) => {
      state.fetch.loading = 'pending';
    },
    [fetchAgentAvailabilityDate.fulfilled]: (state, action) => {
      const { agentAvailabilityDates } = action.payload;
      state.fetch.loading = 'idle';
      state.agentAvailabilityDates = agentAvailabilityDates;
    },
    [fetchAgentAvailabilityDate.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.fetch.loading = 'idle';
      state.error = action.error;
      state.errorCode = errorCode;
    },
    // FETCH AVAILABILITY MESSAGE
    [fetchAgentAvailabilityStatus.pending]: (state) => {
      state.agentAvailabilityStatusFetch.loading = 'pending';
    },
    [fetchAgentAvailabilityStatus.fulfilled]: (state, action) => {
      const { status, type } = action.payload;
      state.agentAvailabilityStatusFetch.loading = 'idle';
      state.agentAvailabilityStatus = { status, type };
    },
    [fetchAgentAvailabilityStatus.rejected]: (state, action) => {
      state.agentAvailabilityStatusFetch.loading = 'idle';
      state.error = action.error;
    },
    // UPDATE AVAILABILITY MESSAGE
    [updateAgentAvailabilityStatus.pending]: (state) => {
      state.agentAvailabilityStatusUpdate.loading = 'pending';
    },
    [updateAgentAvailabilityStatus.fulfilled]: (state, action) => {
      const { status, type } = action.payload;
      state.agentAvailabilityStatusUpdate.loading = 'idle';
      state.agentAvailabilityStatus = { status, type };
    },
    [updateAgentAvailabilityStatus.rejected]: (state, action) => {
      state.agentAvailabilityStatusUpdate.loading = 'idle';
      state.error = action.error;
    },
    // FETCH ONLINE STATUS
    [fetchAgentOnlineStatus.pending]: (state) => {
      state.fetchAgentOnlineStatus.loading = 'pending';
    },
    [fetchAgentOnlineStatus.fulfilled]: (state, action) => {
      const { agentOnlineStatus } = action.payload;
      state.fetchAgentOnlineStatus.loading = 'idle';
      state.agentOnlineStatus = agentOnlineStatus;
    },
    [fetchAgentOnlineStatus.rejected]: (state, action) => {
      state.fetchAgentOnlineStatus.loading = 'idle';
      state.fetchAgentOnlineStatus = action.error;
    },
    // UPDATE STATUS
    [updateAgentStatus.pending]: (state) => {
      state.updateAgentStatus.loading = 'pending';
    },
    [updateAgentStatus.fulfilled]: (state) => {
      state.updateAgentStatus.loading = 'idle';
    },
    [updateAgentStatus.rejected]: (state) => {
      state.updateAgentStatus.loading = 'idle';
    },
  },
});

export default currentAgentSlice.reducer;
