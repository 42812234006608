/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientId: undefined,
};

const persistWhitelist = [
  'clientId',
];

const googleAnalyticsSlice = createSlice({
  name: 'googleAnalytics',
  initialState,
  reducers: {
    setGoogleAnalyticsClientId: (state, action) => {
      const { clientId } = action.payload;
      state.clientId = clientId;
    },
  },
});

export { persistWhitelist as googleAnalyticsPersistWhitelist };
export const {
  setGoogleAnalyticsClientId,
} = googleAnalyticsSlice.actions;
export default googleAnalyticsSlice.reducer;
