export const AWAY_TYPES = {
  LATE_SHIFT: {
    color: '#fbb900',
    text: 'Spätschicht',
    hoverColor: '#ffe499',
  },
  AWAY: {
    color: 'rgba(202, 0, 9, 0.46)',
    text: 'Abwesenheit',
    hoverColor: '#d85258',
  },
  NONE: {
    color: 'white',
    text: 'Löschen',
    hoverColor: '#faebd7',
  },
  UNDECIDED: {
    color: '#c0c0c0',
    text: 'undecided',
    hoverColor: '#f1f1f1',
  },
};
