import {
  initClient,
  removeAllChatListeners, setupChatListenersAfterInit,
  setupChatListenersBeforeInit,
  setupFirebase,
  shutdownChatClient,
} from 'gcs-common/slices/chatConnection/chatConnectionThunks/chatConnectionThunks';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchFavorites from 'gcs-common/slices/favorites/favoritesThunks/fetchFavorites';
import { updateAgentStatusOnChatConnected } from 'gcs-common/slices/currentAgent/currentAgentThunks';
import { debugLogger } from 'gcs-common/helper/debugLogger';
import { initialChannelsLoadSucceeded } from 'gcs-common/slices/channels/channelsSlice';
import fetchCurrentUserChannels from 'gcs-common/slices/channels/channelsThunks/fetchCurrentUserChannels';
import { ALL_CHANNEL_TYPES } from 'gcs-common/helper/channelTypeHelper';
import GccApiForbiddenError from 'gcs-common/clients/gccApiClient/errors/GccApiForbiddenError';
import GccApiUnauthorizedError from 'gcs-common/clients/gccApiClient/errors/GccApiUnauthorizedError';
import { getApiBaseUrl } from 'gcs-common/slices/theme/themeSelectors';
import { initGccApi } from 'gcs-common/slices/gccApiConnection/gccApiConnectionThunks';
import fetchCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUser';
import fetchJoinedHouses from 'gcs-common/slices/houses/housesThunks/fetchJoinedHouses';
import { setIsInitialLogin } from 'gcs-common/slices/auth/authSlice';
import { getInitialLogin } from 'gcs-common/slices/auth/authSelectors';
import { showFailureNotification } from '../notifications/ShowNotificationThunk';
import { getOnChatConnectedLoading, getOnOnlineLoading } from './initializationSelectors';

export const onOnline = createAsyncThunk(
  'chatConnection/onOnline',
  async (_, { dispatch }) => {
    debugLogger('onOnline');
    await dispatch(fetchCurrentUserChannels({ channelTypes: ALL_CHANNEL_TYPES, skipTwilio: true }));
    dispatch(initialChannelsLoadSucceeded());
    dispatch(initClient());
  },
  {
    condition(_, { getState }) {
      return (
        !getOnOnlineLoading(getState())
      );
    },
  },
);

export const onChatConnected = createAsyncThunk(
  'initialization/onChatConnected',
  async (_, { dispatch, getState }) => {
    // We need to remove the chat listeners to add them in the correct order again
    await dispatch(removeAllChatListeners());
    await dispatch(setupChatListenersBeforeInit());

    await dispatch(fetchCurrentUserChannels({ channelTypes: ALL_CHANNEL_TYPES }));

    const isAgent = getIsAgent(getState());

    // WARNING: SUPER IMPORTANT!
    // set the setupChatListeners AFTER fetching of channels!
    // Otherwise, there will be
    // bazillion userUpdated-Events from twilio
    // for each user in each channel we load
    await Promise.all([
      dispatch(setupChatListenersAfterInit()),
      dispatch(setupFirebase()),
      dispatch(fetchCurrentUser()),
      dispatch(fetchFavorites()),
    ]);

    const initialLogin = getInitialLogin(getState());

    if (isAgent) {
      await dispatch(updateAgentStatusOnChatConnected({ initialLogin }));

    }
    if (initialLogin) {
      await dispatch(setIsInitialLogin(false));
    }
  },
  {
    condition(_, { getState }) {
      return !getOnChatConnectedLoading(getState());
    },
  },
);

export const onOffline = () => async (dispatch) => {
  await dispatch(shutdownChatClient());
};

export const initGccApiWeb = createAsyncThunk(
  'initialization/initGccApiWeb',
  async (_, { dispatch, getState }) => {

    const apiBaseUrl = getApiBaseUrl(getState());

    // TODO unify this with openErrorDialogMiddleware
    // the only reason we have to pass a callback to initGccApi is because
    // previously we did not have a common way to open the error modal in web and mobile
    const onApiRequestError = (e) => {
      if (e instanceof GccApiForbiddenError) {
        dispatch(showFailureNotification('Zugriff nicht erlaubt'));
      }
      if (e instanceof GccApiUnauthorizedError) {
        dispatch(showFailureNotification('Zugriff nicht erlaubt'));
      }
    };

    dispatch(initGccApi({
      onRequestError: onApiRequestError,
      apiBaseUrl,
    }));
  },
);

export const initAppFetchData = createAsyncThunk(
  'initialization/initAppFetchData',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(fetchCurrentUser()),
      dispatch(fetchJoinedHouses()),
    ]);
  },
);
