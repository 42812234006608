import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getMemberDetailsSelectedChannelId,
} from 'gcs-common/slices/memberDetails/memberDetailsSelectors';
import {
  getMemberAgentIdsForChannel,
  getMemberCraftsmenIdsForChannel,
  getIsSalesSpaceChannelType,
  getChannelSalesSpaceName,
  getSalesSpaceLogo,
} from 'gcs-common/slices/channel/channelSelectors';
import { currentUserIdSelected } from 'gcs-common/slices/memberDetails/memberDetailsSlice';
import styles from './styles.module.scss';
import MemberListSection from './MemberListSection/MemberListSection';

const ChannelDetails = ({ onSelectMember, logoFallbackSrc }) => {
  const selectedChannelId = useSelector(getMemberDetailsSelectedChannelId);
  const memberAgentUserIds = useSelector(
    getMemberAgentIdsForChannel(selectedChannelId),
  );
  const memberCraftsmanUserIds = useSelector(
    getMemberCraftsmenIdsForChannel(selectedChannelId),
  );
  const isSalesSpaceChannel = useSelector(getIsSalesSpaceChannelType(selectedChannelId));

  const logo = useSelector(getSalesSpaceLogo(selectedChannelId));
  const salesSpaceName = useSelector(getChannelSalesSpaceName(selectedChannelId));

  const communicationStructureDetails = useCallback(() => {
    return (
      <div className={styles.salesSpaceDetailsWrapper}>
        <img
          className={styles.channelImage}
          alt="profile"
          src={logo || logoFallbackSrc}
        />
        <div>
          {salesSpaceName}
        </div>
      </div>
    );
  }, [logo, logoFallbackSrc, salesSpaceName]);

  return (
    <div className={styles.memberDetailsContainer}>
      {isSalesSpaceChannel && communicationStructureDetails()}

      <MemberListSection
        userId={currentUserIdSelected}
        channelId={selectedChannelId}
        key="agentMemberListSection"
        memberUserIds={memberAgentUserIds}
        title="Mitarbeiter"
        onSelectMember={onSelectMember}
      />

      <MemberListSection
        userId={currentUserIdSelected}
        channelId={selectedChannelId}
        key="craftsmanMemberListSection"
        memberUserIds={memberCraftsmanUserIds}
        title="Kunden"
        onSelectMember={onSelectMember}
      />
    </div>
  );
};

ChannelDetails.propTypes = {
  onSelectMember: PropTypes.func.isRequired,
  logoFallbackSrc: PropTypes.string.isRequired,
};

export default ChannelDetails;
