import {
  Dispatch, FC, SetStateAction,
} from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { taskIdSelected } from 'gcs-common/slices/tasks/tasksSlice';
import { getSelectedTaskId } from 'gcs-common/slices/tasks/tasksSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';
import { INVALID_ID } from '../../../models/TaskPropType';
import Task from '../../Task/Task';

interface AddTaskProps {
  setTaskDueSelect: Dispatch<SetStateAction<FetchTasksResponseData['tasks'][number] | null>>,
}

const AddTask:FC<AddTaskProps> = ({ setTaskDueSelect }) => {
  const selectedTaskId = useSelector(getSelectedTaskId);
  const dispatch = useDispatch();

  const dummyTask = {
    id: INVALID_ID,
    description: '',
    done: false,
    channelId: INVALID_ID,
    order: 0,
    userId: '',
  };

  const handleClick = () => {
    if (selectedTaskId === null || selectedTaskId !== dummyTask.id) {
      const { id: taskId } = dummyTask;
      dispatch(taskIdSelected({ taskId }));
    }
  };

  return (
    <div>
      {selectedTaskId && selectedTaskId === dummyTask.id
      && (
        <Task
          task={dummyTask}
          setTaskDueSelect={setTaskDueSelect}
          dark={false}
          isDragging={false}
        />
      )}
      <button type="button" className={styles.addTask} onClick={handleClick}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.PRIMARY_BUTTON} style={{ marginRight: '6px' }} />
        To-Do hinzufügen
      </button>
    </div>
  );
};

AddTask.propTypes = {
  setTaskDueSelect: PropTypes.func.isRequired,
};

export default AddTask;
