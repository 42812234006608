/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { ICON } from '../../constants/IconConstants';
import {
  useGetConnectionMembers,
  useUpdateConnectionMemberMutation,
} from '../../clients/api/entities/connection-member-api';
import InfoBox from '../InfoBox/InfoBox';
import Card from '../Card/Card';
import OpPermissions from '../../helper/opPermissionsHelper';
import MemberPermissionsItem from '../MemberPermissionItem/MemberPermissionItem';
import styles from './styles.module.scss';


const MemberPermissions = ({ userId, houseId }: { userId: string, houseId: string }) => {

  const { data, isPending, isError } = useGetConnectionMembers({
    userId,
    houseId,
  });

  const updateConnectionMemberMutation = useUpdateConnectionMemberMutation();

  if (isPending) {
    return <LoadingIndicator />;
  }

  const connectionMember = data && data[0];

  if (!connectionMember) {
    return <></>;
  }

  if (isError) {
    return <div>Es ist ein Fehler aufgetreten.</div>;
  }

  const opAccount = connectionMember?.opAccount;

  if (!opAccount) {
    return (
      <>
        <div className={styles.detailsSectionTitle}>ONLINE PLUS</div>
        <InfoBox text="Eine ONLINE PLUS Verbindung für diesen Nutzer ist noch nicht eingerichtet." />
      </>
    );
  }

  if (opAccount.process_data_source === 'GCOnlinePlus') {
    return (
      <>
        <div className={styles.detailsSectionTitle}>ONLINE PLUS</div>
        <InfoBox text="Die Berechtigungen für diesen Kunden können in Online Plus verwaltet werden." />
      </>
    );
  }

  const updatePermissions = (permissionType: OpPermissions) => async (toggleValue: boolean) => {
    await updateConnectionMemberMutation.mutateAsync({
      params: {
        path: {
          connection_member_id: connectionMember.id,
        },
      },
      body: {
        [OpPermissions.PROCESSES_ENABLED]: opAccount.search_processes_enabled,
        [OpPermissions.SHOW_BRUTTO_PRICE]: opAccount.processes_show_brutto_price,
        [OpPermissions.SHOW_NETTO_PRICE]: opAccount.processes_show_netto_price,
        [permissionType]: toggleValue,
      },
    });
  };

  return (
    <>
      <div className={styles.detailsSectionTitle}>ONLINE PLUS</div>
      {opAccount.is_permissions_editable === false
            && <InfoBox text="Bitte kontaktiere deinen BEEM-Keyuser, um Änderungen vorzunehmen." />}
      <Card>
        <MemberPermissionsItem
          title="Vorgangssuche anzeigen"
          Icon={ICON.SEARCH}
          toggleEnabled={opAccount?.search_processes_enabled}
          onToggle={updatePermissions(OpPermissions.PROCESSES_ENABLED)}
          testIdAttribute="searchProcessesToggle"
          disabled={!opAccount.is_permissions_editable}
        />
        <MemberPermissionsItem
          title="Nettopreise anzeigen"
          Icon={ICON.EURO}
          toggleEnabled={opAccount?.processes_show_netto_price}
          onToggle={updatePermissions(OpPermissions.SHOW_NETTO_PRICE)}
          testIdAttribute="showNettoPriceToggle"
          disabled={!opAccount.is_permissions_editable}
        />
        <MemberPermissionsItem
          title="Listenpreise anzeigen"
          Icon={ICON.EURO}
          toggleEnabled={opAccount?.processes_show_brutto_price}
          onToggle={updatePermissions(OpPermissions.SHOW_BRUTTO_PRICE)}
          testIdAttribute="showBruttoPriceToggle"
          disabled={!opAccount.is_permissions_editable}
        />
      </Card>
    </>
  );
};

export default MemberPermissions;
