/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useDispatch, useSelector } from 'react-redux';
import { backToTicketsOverview } from 'gcs-common/slices/tickets/ticketsSlice';
import { getTicketView, getSelectedTicket } from 'gcs-common/slices/tickets/ticketsSelectors';
import { TICKET_VIEWS } from 'gcs-common/slices/tickets/ticketsConstants';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import TicketDetails from './TicketDetails/TicketDetails';
import TicketForm from './TicketForm/TicketForm';
import TicketsOverview from './TicketsOverview/TicketsOverview';


const Tickets = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedView = useSelector(getTicketView(selectedChannelId));
  const ticket = useSelector(getSelectedTicket(selectedChannelId));

  const dispatch = useDispatch();

  const navigateBack = () => {
    dispatch(backToTicketsOverview({ channelId: selectedChannelId }));
  };

  switch (selectedView) {
    case TICKET_VIEWS.FORM:
      return <TicketForm channelId={selectedChannelId} onClose={navigateBack} predefinedInputChannel="chat" />;

    case TICKET_VIEWS.DETAILS:
      if (ticket) {
        return <TicketDetails onClose={navigateBack} ticket={ticket} />;
      }

      return <LoadingIndicator />;

    default:
      return <TicketsOverview />;

  }
};

export default Tickets;
