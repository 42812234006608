import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFileListTab } from 'gcs-common/slices/fileList/fileListSelectors';
import { changeFileListTab } from 'gcs-common/slices/fileList/fileListSlice';
import { FILE_LIST_TYPES } from 'gcs-common/constants/fileListConstants';
import styles from './styles.module.scss';

const FileListTabButton = ({ fileListType }) => {
  const selectedFiletype = useSelector(getFileListTab);
  const dispatch = useDispatch();
  const onChangeFileListTab = useCallback(() => {
    dispatch(changeFileListTab({ fileListTab: fileListType }));
  }, [dispatch, fileListType]);
  const isSelected = selectedFiletype === fileListType;
  return (
    <button
      className={isSelected ? styles.isSelected : styles.isNotSelected}
      onClick={onChangeFileListTab}
      type="button"
    >
      {fileListType}
    </button>
  );

};

FileListTabButton.propTypes = {
  fileListType: PropTypes.oneOf(Object.values(FILE_LIST_TYPES)).isRequired,
};
export default FileListTabButton;
