/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { channelMessagesUpsert } from '../messages/messagesSlice';

// This is an arbitrary high number and is needed to help the virtual-list
// to identify where to prepend messages
export const MAX_MESSAGE_VIRTUAL_INDEX = 100000;


const initialState = {
  firstMessageVirtualIndex: {},
};

const virtualScrollerSlice = createSlice({
  name: 'virtualScroller',
  initialState,
  reducers: {},
  extraReducers: {
    [channelMessagesUpsert.type]: (state, action) => {
      const { channelMessages: nestedChannelMessages, prepend } = action.payload;
      if (prepend) {
        Object.entries(nestedChannelMessages).forEach(
          ([channelId, newMessagesForChannel]) => {
            if (!state.firstMessageVirtualIndex[channelId]) {
              state.firstMessageVirtualIndex[channelId] = MAX_MESSAGE_VIRTUAL_INDEX;
            }
            state.firstMessageVirtualIndex[channelId] = Math.max(
              0,
              state.firstMessageVirtualIndex[channelId] - newMessagesForChannel.length,
            );
          },
        );
      }
    },
  },
});

export default virtualScrollerSlice.reducer;
