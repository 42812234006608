/* eslint-disable css-modules/no-unused-class */

import PropTypes from 'prop-types';
import { ICON_COLOR, ICON_SIZE, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';


const IconComponent = ({
  Icon, color, size, transform, style, alt,
}) => {

  const colorClass = styles[color];
  const sizeClass = styles[size];
  const transformClass = styles[transform];
  if (Icon) {
    return <Icon className={`${colorClass} ${sizeClass} ${transformClass}`} style={style} alt={alt} aria-label={alt} />;
  }
  return null;
};

IconComponent.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  color: PropTypes.oneOf(Object.values(ICON_COLOR)),
  size: PropTypes.oneOf(Object.values(ICON_SIZE)),
  transform: PropTypes.oneOf(Object.values(ICON_TRANSFORM)),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  alt: PropTypes.string,
};

export default IconComponent;
