import {
  useState, useRef, useEffect, useLayoutEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { taskIdSelected } from 'gcs-common/slices/tasks/tasksSlice';
// import { openMediaFullscreen } from 'gcs-common/slices/uiState/uiStateSlice';
import { getSelectedTaskId } from 'gcs-common/slices/tasks/tasksSelectors';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import {
  useCreateTaskMutation, useUpdateTaskMutation,
  useDeleteTaskMutation, useSetTaskDoneMutation,
} from 'gcs-common/clients/api/entities/tasks/tasks-api';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { openAsMailToEmail } from 'gcs-common/slices/email/emailThunks';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import { useCreateTaskMutationCache, useSetTaskDoneMutationCache, useUpdateTaskMutationCache } from 'gcs-common/clients/api/entities/tasks/tasks-mutations-cache-selectors';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';
import PopupMenu from '../PopupMenu/PopupMenu';
import IconComponent from '../IconComponent/IconComponent';
import { INVALID_ID } from '../../models/TaskPropType';
import TaskEdit from './TaskEdit/TaskEdit';
import TaskDone from './TaskDone/TaskDone';
import TaskTodo from './TaskTodo/TaskTodo';
import CheckBox from '../CheckBox/CheckBox';

interface TaskProps {
  task: FetchTasksResponseData['tasks'][number],
  dark: boolean,
  setTaskDueSelect: Dispatch<SetStateAction<FetchTasksResponseData['tasks'][number] | null>>
  isDragging?: boolean,
  dragHandleProps?: DraggableProvidedDragHandleProps | null,
}

const Task = (props: TaskProps) => {
  const {
    task, dark, setTaskDueSelect, dragHandleProps, isDragging = false,
  } = props;
  const {
    id: taskId,
  } = task;
  // @ts-expect-error unused
  const { messageMediaRelativePaths } = task;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId = useSelector(getSelectedChannelId);

  const isNewCreatedTask = taskId === INVALID_ID;
  const pendingCreateTaskMutations = useCreateTaskMutationCache({ status: 'pending' });
  const pendingUpdateTaskMutations = useUpdateTaskMutationCache({ status: 'pending' });
  const pendingSetTaskDoneMutations = useSetTaskDoneMutationCache({ status: 'pending' });
  const isTaskPending = pendingCreateTaskMutations.includes(taskId)
    || pendingUpdateTaskMutations.includes(taskId) || pendingSetTaskDoneMutations.includes(taskId);


  const ref = useRef<HTMLDivElement>(null);

  const [isDraggable, setDraggable] = useState(true);
  const [editMenuVisible, setEditMenuVisible] = useState(false);

  // const { data } = useGetTaskMediaURLs({ task_id: taskId });
  // const taskMediaUrls = data?.urls;
  const selectedTaskId = useSelector(getSelectedTaskId);
  const createTaskMutation = useCreateTaskMutation();
  const updateTaskMutation = useUpdateTaskMutation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const deleteTaskMutation = useDeleteTaskMutation({ channelId: selectedChannelId });
  const setTaskDoneMutation = useSetTaskDoneMutation();
  const dispatch = useDispatch();

  const isSelected = selectedTaskId === taskId;

  const selectTask = (taskToSelect?: FetchTasksResponseData['tasks'][number]) => {
    dispatch(taskIdSelected({ taskId: taskToSelect ? taskToSelect.id : undefined }));
  };

  const onScrollToMessage = () => {
    if (!isSelected) {
      selectTask(task);
    }
    const { messageId } = task;
    // @ts-expect-error redux TS
    dispatch(scrollToMessageId({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      channelId: selectedChannelId,
      messageId,
    }));
  };

  const toggleDone = () => {
    if (!isNewCreatedTask) {
      setTaskDoneMutation.mutate({
        params: {
          path: {
            task_id: task.id,
          },
        },
        body: {
          done: !task.done,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          channelId: selectedChannelId,
        },
      });
      selectTask();
    }
  };

  const handleSubmit = (newText: string) => {
    if (isNewCreatedTask && newText !== '') {
      createTaskMutation.mutate({
        body: {
          id: uuidv4(),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          channelId: selectedChannelId ?? '',
          description: newText,
          done: false,
        },
      });
    } else if (task.description !== newText) {
      dispatch(trackGALegacyEvent(LEGACY_EVENTS.EDIT_TASK()));
      updateTaskMutation.mutate({
        params: {
          path: {
            task_id: task.id,
          },
        },
        body: {
          ...task,
          description: newText,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          channelId: selectedChannelId,
        },
      });
    }
    selectTask();
  };

  const handleDelete = () => {
    // not a dummy task
    if (task.id !== INVALID_ID) {
      deleteTaskMutation.mutate({
        params: {
          path: {
            task_id: task.id,
          },
        },
      });
    }
    selectTask();
  };

  const handleTaskDueSelect = () => setTaskDueSelect(task);

  // Task Media
  // const handleOpenFullScreenMedia = (mediaFullscreenUrl: string) => dispatch(
  //   openMediaFullscreen({
  //     mediaFullscreenUrl,
  //   }),
  // );

  const forwardEmail = () => {
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.TODO_FORWARD_EMAIL()));
    const { description: body } = task;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dispatch(openAsMailToEmail({ subject: '', body, messageMediaRelativePaths }));
  };

  const bgColor = isSelected || isDragging ? '#f5f5f5' : '';


  useEffect(() => {
    if (!isSelected) {
      setEditMenuVisible(false);
    }
  }, [isSelected]);

  useLayoutEffect(() => {
    if (ref.current) {
      if (isDraggable) {
        ref.current.setAttribute('draggable', 'true');
      } else {
        ref.current.setAttribute('draggable', 'false');
      }
    }
  }, [isDraggable]);

  return (
    <div
      className={`${styles.todoWrapper} ${dark ? styles.dark : ''}`}
      style={{ backgroundColor: bgColor }}
      ref={ref}
    >
      <div className={`${styles.todo}`}>
        <CheckBox
          onClick={toggleDone}
          isChecked={task.done}
          color={ICON_COLOR.DARK_GREY}
        />
        <div className={`${styles.descriptionSection} ${isTaskPending ? styles.isPending : ''}`}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {(isSelected && !task.done)
            ? (
              <TaskEdit
                parentRef={ref}
                handleSubmit={handleSubmit}
                currText={task.description}
                onMouseOver={() => setDraggable(false)}
                onMouseOut={() => setDraggable(true)}
              />
            ) : task.done ? (
              <TaskDone
                task={task}
                clickAction={() => selectTask(task)}
              />
            )
              : (
                <TaskTodo
                  task={task}
                  clickAction={() => selectTask(task)}
                />
              )
          }
        </div>
        <div className={styles.sideBar}>
          {!task.done && task.dueDate && (
            <button type="button" onClick={handleTaskDueSelect} className={styles.iconButton}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <IconComponent Icon={ICON.ALARM} color={ICON_COLOR.LIGHT_GREY} alt="clock-icon" />
            </button>
          )}
          {!task.done && task.messageId && (
            <button type="button" onClick={onScrollToMessage} className={styles.iconButton}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <IconComponent Icon={ICON.CHAT} color={ICON_COLOR.LIGHT_GREY} alt="msg-icon" />
            </button>
          )}
          {!task.done && messageMediaRelativePaths
          // eslint-disable-next-line no-undef, @typescript-eslint/no-unsafe-member-access
          && messageMediaRelativePaths.length !== 0 && (
            <button type="button" onClick={() => selectTask(task)} className={styles.iconButton}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <IconComponent Icon={ICON.IMAGE} color={ICON_COLOR.LIGHT_GREY} alt="image-icon" />
            </button>
          )}

          {isSelected && !isNewCreatedTask && (
            <>
              <button
                type="button"
                onClick={() => setEditMenuVisible(true)}
              >
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <IconComponent Icon={ICON.MORE} color={ICON_COLOR.LIGHT_GREY} alt="todo-menu" />
              </button>
              <PopupMenu
                onHide={() => setEditMenuVisible(false)}
                isVisible={editMenuVisible}
                menuClass={`${styles.editMenuPopup} ${task.done ? styles.doneEditMenuPopup : ''}`}
              >
                {task.messageId && (
                  <button type="button" onClick={onScrollToMessage}>
                    <IconComponent Icon={ICON.CHAT} alt="msg-icon" />
                    zur Nachricht gehen
                  </button>
                )
                }
                <button type="button" onClick={handleTaskDueSelect}>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <IconComponent Icon={ICON.ALARM} alt="clock-icon" />
                  Zeitpunkt auswählen
                </button>
                <button type="button" onClick={handleDelete}>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <IconComponent Icon={ICON.DELETE} alt="delete-todo" />
                  To Do löschen
                </button>
                <button type="button" onClick={forwardEmail}>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <IconComponent Icon={ICON.EMAIL} alt="forward-email-todo" />
                  Email
                </button>
              </PopupMenu>
            </>
          )}
          {!task.done
          && (
          <button type="button" {...dragHandleProps}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <IconComponent Icon={ICON.DRAG} color={task.done ? ICON_COLOR.DARK_GREY : ICON_COLOR.LIGHT_GREY} alt="move-todo" />
          </button>
          ) }
        </div>
      </div>
      {/* Task media feature  */}
      {/* {isSelected && messageMediaRelativePaths && messageMediaRelativePaths.length !== 0
        && (taskMediaUrls ? (
          <div className={styles.todoMedia}>
            {taskMediaUrls?.map((url) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img
                src={url}
                key={url}
                onClick={() => handleOpenFullScreenMedia(url)}
                alt="attached-media"
              />
            ))}
          </div>
        ) : <LoadingIndicator />)
      } */}
    </div>
  );
};

export default Task;
