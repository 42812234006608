/* eslint-disable camelcase */

// TODO: SMOOT-2846 This kind of temporarily, needs to be adjusted
import BaseConverter from './baseConverter';
import messageFactory from '../../../factory/messageFactory';
import serializeDate from '../../../helper/serializeDate';
import { snakeToCamelCase } from '../../../helper/toCamelCaseHelper';

export default class MessageConverter extends BaseConverter {
  // eslint-disable-next-line class-methods-use-this
  fromTwilioDataModel = (twilioMessage) => {
    const {
      body, attributes, sid, index, dateUpdated, dateCreated,
    } = twilioMessage;

    const messageData = {
      id: attributes.id,
      text: body,
      index: attributes.index ?? index,
      dateUpdated: serializeDate(dateUpdated),
      dateCreated: serializeDate(dateCreated),
      clientMessageId: attributes.client_message_id,
      craftsmanMessage: attributes.craftsman_message,
      messageType: attributes.message_type,
      messageSubType: attributes.message_sub_type,
      sentFrom: attributes.sent_from,
      craftnoteSender: attributes.craftnote_sender,
      craftnoteId: attributes.craftnote_id,
      craftnoteChannel: attributes.craftnote_channel,
      previousAuthorId: attributes.previous_author_id,
      autogenerated: attributes.autogenerated,
      deleted: attributes.deleted,

      // FORWARDED MESSAGE
      isForwarded: attributes.is_forwarded,
      forwardedMessageId: attributes.forwarded_message_id,
      forwardedMessageCreatedAt: attributes.forwarded_message_created_at,
      forwardedMessageAuthorName: attributes.forwarded_message_author_name,
      forwardedMessageText: attributes.forwarded_message_text,

      // FOR MEDIA MESSAGE ONLY
      pdfThumbnail: attributes.pdf_thumbnail,
      imgThumbnail: attributes.img_thumbnail,
      mediaRelativePath: attributes.media_relative_path,
      filename: attributes.filename,
      contentType: attributes.content_type,
      imgDimensionsWidth: attributes.img_dimensions_width,
      imgDimensionsHeight: attributes.img_dimensions_height,
      // legacy, currently not set in backend in twilio attributes
      messageData: attributes.message_data,
      mediaSize: attributes.media_size,

      // FOR VIDEO MESSAGE ONLY
      videoId: attributes.video_id,
      videoStatus: attributes.video_status,
      videoPlaybackId: attributes.video_playback_id,
      videoTitle: attributes.video_title,
      videoWidth: attributes.video_width,
      videoHeight: attributes.video_height,
      videoSize: attributes.video_size,
      videoDuration: attributes.video_duration,
      videoDownloadReady: attributes.video_download_ready,
      videoDownloadResolution: attributes.video_download_resolution,

      // FOR REPLY MESSAGE ONLY
      replyMessageId: attributes.reply_message_id,

      // FOR TEXT MESSAGE OR CRAFTSMAN_NOTIFICATION
      craftsmanText: attributes.craftsman_text,
      linkPreviews: snakeToCamelCase(attributes.link_previews),

      // FOR PROCESS MESSAGE ONLY
      process: attributes.process,
      // FOR PRODUCT MESSAGE ONLY
      product: attributes.product,

      externalId: sid,
      // note, when coming from twilio the channelId is a custom attribute
      channelId: attributes.channel_id,
      authorId: attributes.author_id,
      createdAt: attributes.created_at,
      updatedAt: attributes.updated_at,
      createdBy: attributes.created_by,
      updatedBy: attributes.updated_by,

      // FOR ORDER MESSAGES ONLY
      order: attributes.order,
    };

    const message = messageFactory(messageData);
    return message.toPlain();
  };
}
