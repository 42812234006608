/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import styles from './styles.module.scss';

interface TaskDoneProps {
  task: FetchTasksResponseData['tasks'][number];
  clickAction: VoidFunction,
}

const TaskDone:FC<TaskDoneProps> = ({ task, clickAction }) => {
  return (
    <div onClick={clickAction} className={styles.taskContent}>
      <div>
        {task.description}
      </div>
    </div>
  );
};

export default TaskDone;
