/* eslint-disable max-len */

import { ERROR_DIALOG } from '../../constants/errorDialogConstants';
import { HousesViews, TenantsViews } from '../../constants/views';
import BaseClient from '../baseClient';
import { attachQueryStringToUrl } from '../../helper/urlHelper';
import GccApiNetworkError from './errors/GccApiNetworkError';
import GccApiUnauthorizedError from './errors/GccApiUnauthorizedError';
import GccApiForbiddenError from './errors/GccApiForbiddenError';
import { debugLogger } from '../../helper/debugLogger';
import AppMustUpgradeError from './errors/AppMustUpgradeError';
import { camelToSnakeCase } from '../../helper/toSnakeCaseHelper';
import { filterObjectToExcludeNull } from '../../helper/objectHelpers';

export const DEFAULT_PAGE_SIZE = 100;
export const MESSAGE_LOADING_DIRECTIONS = {
  FORWARD: 'forward',
  BACKWARDS: 'backwards',
};
export const DEBUG_USER_ID = 'DEBUG_USER_ID';

class GccApiClient extends BaseClient {

  _requestErrorMiddleware = async () => {
    throw Error('GccApiClient not initialized');
  };

  _httpRequest = async ({ url, method, headers, body }) => {
    return fetch(url, {
      method,
      headers,
      body,
      credentials: 'include', // this makes sure that the cookies are sent
    });
  };

  _makeRequest = async (
    endpoint,
    {
      method,
      additionalHeaders,
      body,
      params,
      jsonifyBody = true,
      jsonifyResponse = true,
      rejectWithErrorCode = false,
      errorDialog = ERROR_DIALOG.NONE,
    } = {},
  ) => {
    try {
      let url = this._apiBaseUrl + endpoint;
      if (params) {
        url = attachQueryStringToUrl(url, params);
      }

      // eslint-disable-next-line no-underscore-dangle
      const appVersionNumber = import.meta.env.VITE_BEEM_VERSION_NUMBER;
      if (appVersionNumber) {
        // eslint-disable-next-line no-param-reassign
        additionalHeaders = {
          ...additionalHeaders,
          'X-BEEM-MOBILE-VERSION': appVersionNumber,
        };
      }

      const debugUserId = localStorage.getItem(DEBUG_USER_ID);
      if (debugUserId) {
        // eslint-disable-next-line no-param-reassign
        additionalHeaders = {
          ...additionalHeaders,
          'X-TEST-ENV-ONLY-User-Id': debugUserId,
        };
      }

      let response;
      try {
        response = await this._httpRequest({
          url,
          method: method || 'GET',
          headers: {
            ...additionalHeaders,
            ...(jsonifyBody && { 'Content-Type': 'application/json' }),
          },
          body: jsonifyBody ? body && JSON.stringify(body) : body,
        });
      } catch (e) {
        if (e instanceof TypeError) {
          debugLogger(e);
          // TypeError indicates a Network error https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
          // don't check for the error message since it is different in every browser
          // and might change without notice
          throw new GccApiNetworkError();
        }
        throw e;
      }

      let json;
      try {
        json = await response.json();
      } catch (e) {
        // throw Error later if needed
      }

      if (!response.ok) {
        let shouldLogout = false;
        if (response.status === 401 && json?.error_code?.startsWith('auth_error')) {
          shouldLogout = true;
          throw new GccApiUnauthorizedError(shouldLogout);
        }
        if (response.status === 401) {
          throw new GccApiUnauthorizedError(shouldLogout);
        }
        if (response.status === 403) {
          throw new GccApiForbiddenError();
        }
        if (response.status === 400 && json?.error_code?.startsWith('must_upgrade')) {
          throw new AppMustUpgradeError({ config: json?.config });
        }
      }

      if (jsonifyResponse && !json) {
        throw new Error('Could not parse JSON from response');
      }

      if (rejectWithErrorCode) {
        if (json.error_code || json.errorCode || !response.ok) {
          throw this._rejectWithValue({
            errorCode: json.error_code || json.errorCode,
            description: json.description,
            status: response.status,
            endpoint,
            errorDialog,
          });
        }
      }

      return jsonifyResponse ? json : response;
    } catch (e) {
      await this._requestErrorMiddleware(e);
      if (rejectWithErrorCode) {
        throw this._rejectWithValue({
          endpoint,
          errorDialog,
          errorCode: e.message,
          ...e?.payload,
        });
      } else {
        throw e;
      }
    }

  };

  init(requestErrorMiddleware, rejectWithValue, apiBaseUrl, httpRequest = undefined) {
    this._apiBaseUrl = apiBaseUrl;
    this._requestErrorMiddleware = requestErrorMiddleware;
    this._rejectWithValue = rejectWithValue;

    if (httpRequest !== undefined) {
      this._httpRequest = httpRequest;
    }
  }

  sessionLogout = async () => {
    return this._makeRequest('/auth/logout');
  };

  getTwilioToken = async () => {
    return this._makeRequest('/chat/current-user/twilio-token');
  };

  getVideoUploadSignedUrl = async () => {
    return this._makeRequest('/chat/video-upload-signed-url');
  };

  getMessageMediaPresignedUrl = async (fileName, fileExtension) => {
    return this._makeRequest('/chat/message-media-upload-url', {
      params: {
        file_name: fileName,
        file_extension: fileExtension,
      },
    });
  };

  getPresignedUrl = async (fileName) => {
    return this._makeRequest('/chat/media/upload-url', {
      params: {
        file_name: fileName,
      },
    });
  };

  getFavorites = async () => {
    return this._makeRequest('/chat/favorites');
  };

  getCurrentUserChannels = async ({ specificChannelId, channelTypes, onlyUnjoinedChannels } = {}) => {
    let params = {
      channel_types: channelTypes && JSON.stringify(channelTypes),
      specific_channel_id: specificChannelId,
      only_unjoined_channels: onlyUnjoinedChannels,
    };
    params = filterObjectToExcludeNull(params);
    return this._makeRequest('/chat/current-user/channels', {
      method: 'GET',
      params,
    });
  };

  updateChatChannel = async (channelId, values) => {
    return this._makeRequest(
      `/chat/channels/${channelId}`,
      {
        method: 'PATCH',
        body: values,
      },
    );
  };

  joinChannel = async (channelId, currentUserId) => {
    return this._makeRequest(
      `/chat/channels/${channelId}/members`,
      {
        method: 'POST',
        body: { userId: currentUserId },
      },
    );
  };

  leaveChannel = async (channelId) => {
    return this._makeRequest(
      `/chat/channels/${channelId}/members`,
      {
        method: 'DELETE',
      },
    );
  };

  getChannelMembers = async (channelId) => {
    return this._makeRequest(`/chat/channels/${channelId}/members`);
  };

  getChannelMember = async (memberId) => {
    return this._makeRequest(`/chat/members/${memberId}`, {
      method: 'GET',
      params: {
        memberId,
      },
    });
  };

  getCurrentUserMembers = async () => {
    return this._makeRequest('/chat/current-user/members');
  };

  getConnectionMembers = async (userId, houseId) => {
    return this._makeRequest('/chat/connection-members', {
      method: 'GET',
      params: { userId, houseId },
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.MODAL,
    });
  };

  addCraftsmanConnection = async (username, password) => {
    return this._makeRequest('/chat/connection-members', {
      method: 'POST',
      body: { username, password },
    });
  };

  updateCraftsmanConnectionToCustomer = async (connectionId, username, password) => {
    return this._makeRequest(`/chat/connection-members/${connectionId}`, {
      method: 'PUT',
      body: { username, password },
    });
  };

  updateOPPermissionsForUser = async (connectionId, showBruttoPrice, showNettoPrice, searchProcessesEnabled) => {
    return this._makeRequest(`/chat/connection-members/${connectionId}`, {
      method: 'PUT',
      body: { showBruttoPrice, showNettoPrice, searchProcessesEnabled },
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.MODAL,
    });
  };

  getHousesForUser = async () => {
    return this._makeRequest('/chat/current-user/houses', {
      rejectWithErrorCode: true,
    });
  };

  deleteCraftsmanConnection = async (connectionId) => {
    return this._makeRequest(`/chat/connection-members/${connectionId}`, { method: 'DELETE' });
  };

  addFavorite = async (channelId, messageId) => {
    return this._makeRequest('/chat/favorites', {
      method: 'POST',
      body: {
        channelId,
        messageId,
      },
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.MODAL,
    });
  };

  removeFavorite = async (favoriteId) => {
    return this._makeRequest(`/chat/favorites/${favoriteId}`, {
      method: 'DELETE',
    });
  };

  getMemberPromoEmail = async (memberId) => {
    return this._makeRequest(`/chat/members/${memberId}/promo-email`);
  };

  createMemberPromoEmail = async (memberId) => {
    return this._makeRequest(`/chat/members/${memberId}/promo-email`, {
      method: 'POST',
    });
  };

  updateChannelMember = async (memberId, values) => {
    return this._makeRequest(`/chat/members/${memberId}`, {
      method: 'PATCH',
      body: values,
    });
  };

  //
  setAgentAvailabilityStatus = async (status, statusType) => {
    return this._makeRequest('/chat/agent-availability-status', {
      method: 'POST',
      body: {
        type: statusType,
        status,
      },
    });
  };

  getAgentAvailabilityStatus = async (statusType) => {
    return this._makeRequest('/chat/agent-availability-status', {
      params: {
        type: statusType,
      },
    });
  };

  getCurrentUser = async () => {
    return this._makeRequest('/chat/current-user', {
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.FULLSCREEN,
    });
  };

  deleteCurrentUser = async () => {
    return this._makeRequest(
      '/chat/current-user',
      { method: 'DELETE' },
    );
  };

  updateCurrentUser = async (values) => {
    return this._makeRequest(
      '/chat/current-user',
      {
        method: 'PATCH',
        body: values,
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  getUser = async ({ id }) => {
    return this._makeRequest(`/chat/users/${id}`);
  };

  updateUserHiddenChannels = async (values) => {
    return this._makeRequest('/chat/current-user/hidden-channels', {
      method: 'PATCH',
      body: values,
    });
  };

  getAgentAvailability = async (channelId) => {
    return this._makeRequest(`/chat/channels/${channelId}/agent-availability`);
  };

  getProcesses = async (page = 1, queryType, queryString, Id = null, houseId) => {
    const params = { page };

    if (queryType) {
      params.query_type = queryType;
    }
    if (queryString) {
      params.query_string = queryString;
    }
    if (Id) {
      params.user_id = Id;
    }
    if (houseId) {
      params.house_id = houseId;
    }

    const data = await this._makeRequest('/chat/processes', {
      params,
    });
    return data;
  };

  queryAgentOnlineStatus = async () => {
    return this._makeRequest('/chat/agent-online-status');
  };

  getAgentAvailabilityDate = async () => {
    return this._makeRequest('/chat/agent-availability-date');
  };

  upsertAgentAvailabilityDate = async (date, type) => {
    return this._makeRequest('/chat/agent-availability-date', {
      method: 'POST',
      body: {
        date,
        type,
      },
    });
  };

  updateSelectedAgent = async (agentId, updateFields) => {
    return this._makeRequest(`/admin/agent/${agentId}`, {
      method: 'PATCH',
      body: updateFields,
    });
  };

  deleteAgentAvailabilityDate = async (date) => {
    return this._makeRequest('/chat/agent-availability-date', {
      method: 'DELETE',
      body: {
        date,
      },
    });
  };

  getProcessDetails = async (processNumber, suffix, Id = null, houseId) => {
    const params = {
      process_number: processNumber,
      suffix,
    };

    if (Id) {
      params.user_id = Id;
    }
    if (houseId) {
      params.house_id = houseId;
    }

    const data = await this._makeRequest('/chat/process-details', {
      params,
    });
    return data.items;
  };

  createShareableMediaUrl = async (messageMediaRelativePath) => {
    return this._makeRequest('/chat/external-media', {
      method: 'POST',
      body: {
        messageMediaRelativePath,
      },
    });
  };

  getProcessesFilterSuggestions = async (queryString, Id = null, houseId) => {
    const params = {};

    if (queryString) {
      params.query_string = queryString;
    }

    if (Id) {
      params.user_id = Id;
    }
    if (houseId) {
      params.house_id = houseId;
    }

    const data = await this._makeRequest('/chat/process-filter-suggestions', {
      params,
    });
    return data || [];
  };

  getTeamsForHouse = async (pageIndex = 0, pageSize = null, search = '', houseId, sortOrder = null, sortedColumnId = null) => {
    return this._makeRequest(`/admin/houses/${houseId}/teams?index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}`);
  };

  getTeam = async (teamId) => {
    return this._makeRequest(`/admin/teams/${teamId}`);
  };

  addUserToTeam = async (teamId, userId) => {
    return this._makeRequest(
      `/admin/teams/${teamId}/members`,
      {
        method: 'POST',
        body: {
          userId,
        },
      },
    );
  };

  removeUserFromTeam = async (teamId, userId) => {
    return this._makeRequest(
      `/admin/teams/${teamId}/members/${userId}`,
      {
        method: 'DELETE',
      },
    );
  };

  getCraftsmanUsersForHouse = async (houseId, erpCustomerId = null, pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null) => {
    return this._makeRequest(
      `/admin/houses/${houseId}/craftsman-users?erp_customer_id=${erpCustomerId}&index=${pageIndex}&size=${pageSize}&search=${encodeURIComponent(search)}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}`,
    );
  };

  getAgentUsersForHouse = async (houseId, pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null, view = 'plain_select') => {
    return this._makeRequest(`/admin/houses/${houseId}/agent-users?index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}&view=${view}`);
  };

  getBrands = async () => {
    return this._makeRequest('/get_brands');
  };

  getUserDetails = async (userIds) => {
    return this._makeRequest(
      '/admin/users-details-get',
      {
        method: 'POST',
        body: {
          userIds,
        },
      },
    );
  };

  getAgentCompanies = async (agentId) => {
    return this._makeRequest(`/get_agent_companies/${agentId}`);
  };

  getAgentChannels = async (agentId, channelTypes = []) => {
    return this._makeRequest(`/admin/agents/${agentId}/channels?channel_types=${JSON.stringify(channelTypes)}`);
  };

  getAgentAssignmentsCount = async (agentId, houseId = '') => {
    return this._makeRequest(`/admin/agent/${agentId}/assignments-count?house_id=${houseId}`);
  };

  createErpCustomer = async (erpCustomer, houseId) => {
    return this._makeRequest(
      '/admin/erp-customers',
      {
        method: 'POST',
        body: { ...erpCustomer, houseId },
      },
    );
  };

  updateErpCustomer = async (erpCustomerId, values) => {
    return this._makeRequest(
      `/admin/erp-customers/${erpCustomerId}`,
      {
        method: 'PUT',
        body: values,
      },
    );
  };

  getErpCustomer = async (erpCustomerId) => {
    return this._makeRequest(`/admin/erp-customers/${erpCustomerId}`);
  };

  deleteErpCustomer = async (erpCustomerId) => {
    return this._makeRequest(
      `/admin/erp-customers/${erpCustomerId}`,
      { method: 'DELETE' },
    );
  };

  getErpCustomers = async (pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null, houseId, view = 'customers_table') => {
    return this._makeRequest(
      `/admin/houses/${houseId}/erp-customers?view=${view}&index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}`,
    );
  };

  getHouseList = async (pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null, view = HousesViews.PLAIN) => {
    return this._makeRequest(`/admin/houses?index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}&view=${view}`);
  };

  getHouseDetails = async (houseId) => {
    return this._makeRequest(`/admin/houses/${houseId}`);
  };

  getHouseCustomMessageTypes = async () => {
    return this._makeRequest('/admin/custom-message-types');
  };

  validateHouseCustomMessage = async (customMessage, messageType, houseId) => {
    return this._makeRequest(
      `/admin/custom-message-types/validate?house_id=${houseId}`,
      {
        method: 'POST',
        body: { message: customMessage, type: messageType },
      },
    );
  };

  updateHouse = async (houseId, values) => {
    return this._makeRequest(
      `/admin/houses/${houseId}`,
      {
        method: 'PUT',
        body: values,
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.SNICKERS,
      },
    );
  };

  getErpCustomerUsers = async (erpCustomerId) => {
    return this._makeRequest(`/admin/connections/${erpCustomerId}/connection-members`);
  };

  getAllFeatureFlags = async () => {
    return this._makeRequest('/admin/feature-flags');
  };

  updateFeatureFlag = async (featureFlagId, { userIds, tenantIds, houseIds }) => {
    return this._makeRequest(`/admin/feature-flags/${featureFlagId}`, {
      method: 'PATCH',
      body: {
        userIds,
        tenantIds,
        houseIds,
      },
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.MODAL,
    });
  };

  deleteErpCustomerUser = async (userId, erpCustomerId) => {
    return this._makeRequest(
      `/admin/connections/${erpCustomerId}/connection-members/${userId}`,
      {
        method: 'DELETE',
      },
    );
  };

  getErpCustomerChannels = async (erpCustomerId, channelTypes = []) => {
    return this._makeRequest(`/admin/erp-customers/${erpCustomerId}/channels?channel_types=${JSON.stringify(channelTypes)}`);
  };

  createAgent = async (agent, houseId) => {
    return this._makeRequest(
      '/admin/agents',
      {
        method: 'POST',
        body: { agent, houseId },
      },
    );
  };

  deleteAgent = async (agentId) => {
    return this._makeRequest(
      `/admin/agents/${agentId}`,
      {
        method: 'DELETE',
      },
    );
  };

  disconnectAgentFromHouse = async (agentId, houseId) => {
    return this._makeRequest(
      `/admin/houses/${houseId}/agent-users/${agentId}`,
      {
        method: 'DELETE',
        body: { houseId },
      },
    );
  };

  createConnectionMember = async (user, erpCustomerId) => {
    return this._makeRequest(
      `/admin/connections/${erpCustomerId}/connection-members`,
      {
        method: 'POST',
        body: user,
      },
    );
  };

  deleteCompanyUser = async (userId) => {
    return this._makeRequest(`/delete_craftsman/${userId}`, { method: 'DELETE' });
  };

  createTeam = async (team, houseId) => {
    return this._makeRequest('/admin/teams', {
      method: 'POST',
      body: {
        customerResponsibilityRoleId: team.roleId,
        ...team,
        houseId,
      },
    });
  };

  deleteTeam = async (teamId) => {
    return this._makeRequest(`/admin/teams/${teamId}`, { method: 'DELETE' });
  };

  updateTeam = async (teamId, values) => {
    return this._makeRequest(
      `/admin/teams/${teamId}`,
      {
        method: 'PUT',
        body: values,
      },
    );
  };

  createChannel = async (userIds, primaryHouseId, channelType, channelImage = null, channelName = null) => {
    return this._makeRequest(
      '/admin/channels',
      {
        method: 'POST',
        body: {
          userIds,
          primaryHouseId,
          channelType,
          channelImage,
          channelName,
        },
      },
    );
  };

  createChannelFromAssignment = async (assignmentId, userIds, channelType) => {
    return this._makeRequest(
      '/chat/channels',
      {
        method: 'POST',
        body: {
          assignmentId,
          userIds,
          channelType,
        },
      },
    );
  };

  // TODO - Rename to createChannel when cleaning up admin createChannel function (line 588)
  createDirectChannel = async (userIds, selectedHouseId) => {
    return this._makeRequest(
      '/chat/channels',
      {
        method: 'POST',
        body: {
          userIds,
          selectedHouseId,
        },
      },
    );
  };

  createChannelMember = async (channelId, userId) => {
    return this._makeRequest(
      `/admin/channels/${channelId}/members`,
      {
        method: 'POST',
        body: {
          userId,
        },
      },
    );
  };

  deleteChannel = async (channelId) => {
    return this._makeRequest(
      `/admin/channels/${channelId}`,
      {
        method: 'DELETE',
      },
    );
  };

  deleteChannelMember = async (channelId, memberId) => {
    return this._makeRequest(
      `/admin/channels/${channelId}/members/${memberId}`,
      {
        method: 'DELETE',
      },
    );
  };

  sendEmailCode = async (email) => {
    return this._makeRequest(
      '/send_code',
      {
        method: 'POST',
        body: {
          code_receiver: email,
          is_craftsman: false,
        },
      },
    );
  };

  getHouseBranches = async (pageIndex = 0, pageSize = null, search = '') => {
    return this._makeRequest(`/get_house_branches_for_house?index=${pageIndex}&size=${pageSize}&search=${search}`);
  };

  ping = async () => {
    await this._makeRequest('/ping', { method: 'GET' });
  };

  keepTransactionInUse = async (transactionId) => {
    return this._makeRequest('/keep_transaction_in_use', {
      method: 'POST',
      body: {
        transactionId,
      },
    });
  };

  registerPush = async (token, uuid, deviceInfo) => {
    return this._makeRequest('/chat/push-devices', {
      method: 'POST',
      body: {
        token,
        uuid,
        deviceInfo,
      },
    });
  };

  getChannelDetail = async (channelId) => {
    return this._makeRequest(`/admin/channels/${channelId}`);
  };

  getTeamForUsers = async (houseId, userIds) => {
    return this._makeRequest(`/admin/houses/${houseId}/users-team`, {
      method: 'POST',
      body: {
        userIds,
      },
    });
  };
  // #### TODO: NEW

  getTenants = async (pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null, view = TenantsViews.TABLE) => {
    return this._makeRequest(`/admin/tenants?index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}&view=${view}`);
  };

  getTenantDetails = async (tenantId) => {
    return this._makeRequest(`/admin/tenants/${tenantId}`);
  };

  deleteTenant = async (tenantId) => {
    return this._makeRequest(
      `/admin/tenants/${tenantId}`,
      { method: 'DELETE' },
    );
  };

  updateTenant = async (tenantId, values) => {
    return this._makeRequest(
      `/admin/tenants/${tenantId}`,
      {
        method: 'PUT',
        body: values,
      },
    );
  };

  createTenant = async (tenant) => {
    return this._makeRequest(
      '/admin/tenants',
      {
        method: 'POST',
        body: tenant,
      },
    );
  };

  getBusinessUnits = async (pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null) => {
    return this._makeRequest(`/admin/business-units?index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}`);
  };

  getBusinessUnitDetails = async (businessUnitId) => {
    return this._makeRequest(`/admin/business-units/${businessUnitId}`);
  };

  deleteBusinessUnit = async (businessUnitId) => {
    return this._makeRequest(
      `/admin/business-units/${businessUnitId}`,
      { method: 'DELETE' },
    );
  };

  updateBusinessUnit = async (businessUnitId, values) => {
    return this._makeRequest(
      `/admin/business-units/${businessUnitId}`,
      {
        method: 'PUT',
        body: values,
      },
    );
  };

  createBusinessUnit = async (businessUnit) => {
    return this._makeRequest(
      '/admin/business-units',
      {
        method: 'POST',
        body: businessUnit,
      },
    );
  };

  createHouseCommunicationStructureMembers = async (structure) => {
    return this._makeRequest(
      '/admin/communication-strutures',
      {
        method: 'POST',
        body: { ...structure },
      },
    );
  };

  updateHouseCommunicationStructureMembers = async (id, structure) => {
    return this._makeRequest(
      `/admin/communication-strutures/${id}`,
      {
        method: 'PUT',
        body: { ...structure },
      },
    );
  };

  getCommunicationStruturesForHouse = async (houseId) => {
    return this._makeRequest(`/admin/houses/${houseId}/communication-strutures`);
  };

  deleteCommunicationStruture = async (id) => {
    return this._makeRequest(`/admin/communication-strutures/${id}`, {
      method: 'DELETE',
    });
  };

  getAssignmentsForErpCustomer = async (erpCustomerId) => {
    return this._makeRequest(`/admin/erp-customers/${erpCustomerId}/assignments`, {
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.MODAL,
    });
  };

  deleteAssignment = async (assignmentId) => {
    return this._makeRequest(
      `/admin/assignments/${assignmentId}`,
      {
        method: 'DELETE',
      },
    );
  };

  createErpCustomerAssignment = async (assignment, erpCustomerId) => {
    return this._makeRequest(
      `/admin/erp-customers/${erpCustomerId}/assignments`,
      {
        method: 'POST',
        body: { assignment },
      },
    );
  };

  updateAssignment = async (assignmentId, assignment) => {
    return this._makeRequest(
      `/admin/assignments/${assignmentId}`,
      {
        method: 'PATCH',
        body: { assignment },
      },
    );
  };

  getHouseAssignmentConfigurations = async (houseId, pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null) => {
    return this._makeRequest(`/get_assignment_configurations_for_house/${houseId}?index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}`);
  };
  //
  // getAgentGroupTypes = async (pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null) => {
  //   return this._makeRequest(`/get_agent_group_types?index=${pageIndex}&size=${pageSize}&search=${search}&sort_order=${sortOrder}&sorted_column_id=${sortedColumnId}`);
  // };
  //
  // getAgentGroupTypeDetails = async (agentGroupTypeId) => {
  //   return this._makeRequest(`/get_agent_group_type_details/${agentGroupTypeId}`);
  // };

  // deleteAgentGroupType = async (agentGroupTypeId) => {
  //   return this._makeRequest(`/delete_agent_group_type/${agentGroupTypeId}`,
  //       { method: 'DELETE' });
  // };
  //
  // updateAgentGroupType = async (agentGroupTypeId, values) => {
  //   return this._makeRequest(`/update_agent_group_type/${agentGroupTypeId}`,
  //       {
  //         method: 'PATCH',
  //         body: values,
  //       });
  // };
  //
  // createAgentGroupType = async (agentGroupType) => {
  //   return this._makeRequest('/create_agent_group_type',
  //       {
  //         method: 'POST',
  //         body: agentGroupType,
  //       });
  // };

  createHouse = async (house) => {
    return this._makeRequest(
      '/admin/houses',
      {
        method: 'POST',
        body: house,
      },
    );
  };

  getCustomerResponsibilityMembersList = async (pageIndex = 0, pageSize = null, search = '', sortOrder = null, sortedColumnId = null, houseId, agentId, erpCustomerId) => {
    return this._makeRequest(`/admin/customer-responsibility-members?house_id=${houseId}&agent_id=${agentId}&erp_customer_id=${erpCustomerId}&index=${pageIndex}&size=${pageSize}&sorted_column_id=${camelToSnakeCase(sortedColumnId)}&sort_order=${sortOrder}&search=${search}`);
  };

  createCustomerResponsibilityMember = async (customerResponsibilityMember, houseId) => {
    return this._makeRequest(
      '/admin/customer-responsibility-members',
      {
        method: 'POST',
        body: {
          ...customerResponsibilityMember,
          houseId,
        },
      },
    );
  };

  deleteCustomerResponsibilityMember = async (responsibilityMemberId) => {
    return this._makeRequest(`/admin/customer-responsibility-members/${responsibilityMemberId}`, {
      method: 'DELETE',
    });
  };

  getAllCustomerResponsibilityRoles = async (roleType, view = 'customer_responsibility', houseId) => {
    return this._makeRequest(`/admin/customer-responsibility-roles?role_type=${roleType}&view=${view}&house_id=${houseId}`);
  };

  getAllAssignmentRoles = async () => {
    return this._makeRequest('/admin/communication-assignment-roles');
  };

  geNewChannelOptions = async (newChannelOptionTypesToFetch, searchTerm, nextSlice, limit, houseId) => {
    const params = {};

    if (newChannelOptionTypesToFetch) {
      params.channel_types = JSON.stringify(newChannelOptionTypesToFetch);
    }

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (nextSlice) {
      params.slice = nextSlice;
    }

    if (limit) {
      params.limit = limit;
    }

    if (houseId) {
      params.house_id = houseId;
    }

    return this._makeRequest('/chat/current-user/new-channel-options', {
      params,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  trackCustomEvent = (payload) => {
    const formData = new FormData();

    formData.append('type', payload.type);
    formData.append('data', JSON.stringify(payload.data));

    // TODO: fix this when is called even though client is not initialized
    navigator.sendBeacon(`${this._apiBaseUrl}/monitoring/custom-event`, formData);
  };

  getMessage = async (messageId) => {
    return this._makeRequest(`/chat/messages/${messageId}`);
  };

  updateMessage = async (messageId, values) => {
    return this._makeRequest(
      `/chat/messages/${messageId}`,
      {
        method: 'PATCH',
        body: values,
      },
    );
  };

  /**
   *
   * @param channelId required
   * @param fromMessageIndex Keep undefined to load till the end
   * @param toMessageIndex Keep undefined to load till the end. IMPORTANT: It is only guaranteed
   * that the returned messages contain all messages to this index, there might be more messages
   * after / before this index. If the index is not found, an error is thrown if
   * throwIfToMessageIndexNotFound is true. The message with the index toMessageIndex is included
   * in the result
   * @param pageSize default: 30
   * @param singlePage default: false (loads till the end)
   * @param direction direction: BACKWARDS
   * @param includeAnchorMessage default: false
   * @param throwIfToMessageIndexNotFound default: true
   * @returns object {messages: [], firstMessageLoaded: boolean}
   */
  getChannelMessages = async ({
    channelId,
    fromMessageIndex = undefined,
    toMessageIndex = undefined,
    pageSize = DEFAULT_PAGE_SIZE,
    singlePage = false,
    includeAnchorMessage = false,
    throwIfToMessageIndexNotFound = true,
    direction = MESSAGE_LOADING_DIRECTIONS.BACKWARDS,
  }) => {
    // eslint-disable-next-line max-len
    debugLogger(`channelGetMessages ${channelId}, fromMessageIndex: ${fromMessageIndex}, toMessageIndex: ${toMessageIndex}, pageSize: ${pageSize}, singlePage: ${singlePage}, direction: ${direction}`);
    if (![
      MESSAGE_LOADING_DIRECTIONS.FORWARD,
      MESSAGE_LOADING_DIRECTIONS.BACKWARDS,
    ].includes(direction)) {
      throw new Error(`direction must be either ${MESSAGE_LOADING_DIRECTIONS.FORWARD} or ${MESSAGE_LOADING_DIRECTIONS.BACKWARDS}`);
    }
    if (fromMessageIndex !== undefined) {
      if (singlePage && toMessageIndex !== undefined) {
        throw new Error('If fromMessageIndex is given, only one of singlePage and toMessageIndex can be defined');
      }
    }
    if (toMessageIndex !== undefined) {
      if (fromMessageIndex === undefined) {
        throw new Error('If toMessageIndex is given, fromMessageIndex must be also defined');
      }
      if (singlePage) {
        throw new Error('If fromMessageIndex and toMessageIndex is given, singlePage is not allowed');
      }
    }
    if (
      (
        direction === MESSAGE_LOADING_DIRECTIONS.BACKWARDS
        && fromMessageIndex < toMessageIndex
      )
      || (
        direction === MESSAGE_LOADING_DIRECTIONS.FORWARD
        && fromMessageIndex > toMessageIndex
      )
    ) {
      throw Error(
        `Cannot load messages from ${fromMessageIndex} to ${toMessageIndex} in direction ${direction}`,
      );
    }

    let untilMessageIndexWasLoaded = false;
    let isLastPage = false;
    let messages = [];
    let currentAnchorIndex = fromMessageIndex;
    const skipAnchor = currentAnchorIndex !== undefined && !includeAnchorMessage;
    do {

      // eslint-disable-next-line no-await-in-loop
      const messagePage = await this.channelGetMessages(
        // +1 since in the results, the oldestMessage is also
        // included in the messages to be loaded
        {
          channelId,
          pageSize: skipAnchor ? pageSize + 1 : pageSize,
          fromMessageIndex: currentAnchorIndex,
          toMessageIndex,
          direction,
        },
      );

      if (skipAnchor && messagePage) {
        // anchorMessage is also included here, we don't want to have it duplicated
        messagePage.shift();
      }

      isLastPage = messagePage.length < pageSize;

      // If we want to load a specific index, we should reach exactly that index
      // but if we load a range, it might happen that toMessageIndex is not an actual index
      // which would fail
      if (toMessageIndex !== undefined && throwIfToMessageIndexNotFound) {
        // check if we already passed the toMessageIndex and without finding
        // the toMessageIndex and throw an error if we did
        untilMessageIndexWasLoaded = messagePage.some(m => m.index === toMessageIndex);
        if (!untilMessageIndexWasLoaded) {
          const tooFarBackwards = direction === MESSAGE_LOADING_DIRECTIONS.BACKWARDS
            && messagePage[0]?.index < toMessageIndex;
          const tooFarForward = direction === MESSAGE_LOADING_DIRECTIONS.FORWARD
            && messagePage[messagePage.length - 1]?.index > toMessageIndex;
          if (isLastPage || tooFarBackwards || tooFarForward) {
            throw Error(
              `Couldnt find message with index ${toMessageIndex}`,
            );
          }
        }
      }

      if (direction === MESSAGE_LOADING_DIRECTIONS.BACKWARDS) {
        currentAnchorIndex = messagePage[0]?.index;
        messages = [...messagePage, ...messages];
      } else {
        currentAnchorIndex = messagePage[messagePage.length - 1]?.index;
        messages = [...messages, ...messagePage];
      }

    } while (!(untilMessageIndexWasLoaded || isLastPage) && !singlePage);

    // channelMessagesUpsert expects dicts
    let firstMessageLoaded;

    if (direction === MESSAGE_LOADING_DIRECTIONS.BACKWARDS) {
      firstMessageLoaded = isLastPage;
    }
    return {
      messages,
      firstMessageLoaded,
    };
  };

  sendChannelMessages = async (messages) => {
    const sentMessages = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const message of messages) {
      // eslint-disable-next-line no-await-in-loop
      const sentMessage = await this._makeRequest(
        `/chat/channels/${message.channelId}/messages`,
        {
          method: 'POST',
          body: message,
          rejectWithErrorCode: true,
          errorDialog: ERROR_DIALOG.MODAL,
        },
      );
      sentMessages.push(sentMessage);
    }

    return sentMessages;
  };

  channelGetMessages = async ({
    channelId, fromMessageIndex, toMessageIndex, pageSize, direction,
  }) => {
    return this._makeRequest(
      `/chat/channels/${channelId}/messages`,
      {
        method: 'GET',
        params: {
          from_message_index: fromMessageIndex,
          to_message_index: toMessageIndex,
          page_size: pageSize,
          direction,
        },
      },
    );
  };

  createETSTicket = async ({
    channelId, erpCustomerId, summary, description, customerOrderText, inputChannel,
  }) => {
    return this._makeRequest(
      '/chat/ets/tickets',
      {
        method: 'POST',
        body: {
          channelId,
          erpCustomerId,
          summary,
          description,
          customerOrderText,
          inputChannel,
        },
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  addETSTicketComment = async ({ ticketId, comment }) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/comments`,
      {
        method: 'POST',
        body: {
          body: comment,
        },
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  patchETSTicketStatus = async ({ ticketId }) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/status`,
      {
        method: 'PATCH',
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  deleteETSTicketComment = async ({ ticketId, commentId }) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/comments/${commentId}`,
      {
        method: 'DELETE',
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  uploadETSTicketAttachments = async ({ ticketId, attachments }) => {
    const formData = new FormData();

    attachments.forEach((attachment) => {
      formData.append('file', attachment);
    });

    return this._makeRequest(`/chat/ets/tickets/${encodeURIComponent(ticketId)}/attachments`, {
      method: 'POST',
      body: formData,
      jsonifyBody: false,
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.MODAL,
    });
  };

  getETSTickets = async ({
    channelId = null,
    pageIndex = 0,
    pageSize = null,
    sortOrder = null,
    sortedColumnId = null,
    status = null,
    startDate = null,
    endDate = null,
    erpCustomerIds = null,
    search = null,
    creatorId = null,
  }) => {
    return this._makeRequest(
      '/chat/ets/tickets',
      {
        params: {
          index: pageIndex,
          size: pageSize,
          sorted_column_id: camelToSnakeCase(sortedColumnId),
          sort_order: sortOrder,
          status,
          start_date: startDate,
          end_date: endDate,
          erp_customer_ids: erpCustomerIds?.map(x => encodeURIComponent(x)).join(','),
          search,
          channel_id: channelId,
          creator_id: creatorId,
        },
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  getETSTicket = async (id) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(id)}`,
      {
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  getETSTicketParts = async (ticketId) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/parts`,
      {
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  getETSTicketAttachments = async (ticketId) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/attachments`,
      {
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  getETSTicketComments = async (ticketId) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/comments`,
      {
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  getETSTicketNotifications = async () => {
    return this._makeRequest(
      '/chat/current-user/ets-notifications',
      {
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.NONE, // periodic background task shouldn't trigger error popup
      },
    );
  };

  deleteETSTicketNotification = async (id) => {
    return this._makeRequest(`/chat/ets/notifications/${encodeURIComponent(id)}`, {
      method: 'DELETE',
      rejectWithErrorCode: true,
      errorDialog: ERROR_DIALOG.MODAL,
    });
  };

  getETSTicketRating = async (ticketId) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/rating`,
      {
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  addETSTicketRating = async ({ ticketId, rating, comment }) => {
    return this._makeRequest(
      `/chat/ets/tickets/${encodeURIComponent(ticketId)}/rating`,
      {
        method: 'POST',
        body: {
          rating,
          comment,
        },
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

  getArticleSuggestions = async (search) => {
    return this._makeRequest(`/chat/quick-order/articles/suggestions?search=${search}`);
  };

  getArticleDetails = async ({
    variant,
    supplier,
    runNumber,
    kbn,
    contextChannelId,
  }) => {
    return this._makeRequest('/chat/quick-order/articles/details', {
      method: 'POST',
      body: {
        variant,
        supplier,
        run_number: runNumber,
        kbn,
        context_channel_id: contextChannelId,
      },
    });
  };

  getUsers = async ({ search, view }) => {
    return this._makeRequest(`/admin/users?search=${encodeURIComponent(search)}&view=${view}`);
  };

  getErpCustomerForUser = async ({
    pageIndex = 0,
    pageSize = null,
    search = null,
    plainSelect = undefined,
  }) => {
    return this._makeRequest(
      '/chat/current-user/erp-customers',
      {
        params: {
          index: pageIndex,
          size: pageSize,
          search,
          plain_select: plainSelect,
        },
        rejectWithErrorCode: true,
        errorDialog: ERROR_DIALOG.MODAL,
      },
    );
  };

}

export default new GccApiClient();
