import { createAsyncThunk } from '@reduxjs/toolkit';
import assignmetChannelImageUploadHelper from '../../../helper/assignmetChannelImageUploadHelper';
import { getActiveHouseId } from '../houses/housesSelectors';

export const createHouseCommunicationStructureMembers = createAsyncThunk(
  'houses/createCommunicationStructure',
  async ({ structure }, { rejectWithValue, extra: { gccApiClient } }) => {

    if (structure.channelImage) {
      // eslint-disable-next-line no-param-reassign
      structure.channelImage = await assignmetChannelImageUploadHelper(structure);
    }

    const response = await gccApiClient.createHouseCommunicationStructureMembers(structure);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { structure: response };
  },
);

export const updateHouseCommunicationStructureMembers = createAsyncThunk(
  'houses/updateCommunicationStructure',
  async ({
    id, structure,
  }, { rejectWithValue, extra: { gccApiClient } }) => {
    if (structure.channelImage && structure.channelImage.substr(-4) !== '.png') {
      // eslint-disable-next-line no-param-reassign
      structure.channelImage = await assignmetChannelImageUploadHelper(structure);
    }
    const response = await gccApiClient.updateHouseCommunicationStructureMembers(
      id, structure,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { structure: response };
  },
);

export const fetchHouseCommunicationStructures = createAsyncThunk(
  'houses/fetchHouseCommunicationStructures',
  async ({ houseId }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getCommunicationStruturesForHouse(houseId);
    return { data: response };
  },
);

export const deleteHouseCommunicationStructure = createAsyncThunk(
  'houses/deleteHouseCommunicationStructure',
  async ({ id }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteCommunicationStruture(id);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { data: response };
  },
);

export const fetchAllAssignmentRoles = createAsyncThunk(
  'houses/fetchAllAssignmentRoles',
  async (_, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAllAssignmentRoles();
    return { data: response };
  },
);

export const fetchAgentOptions = createAsyncThunk(
  'houses/fetchAgents',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, houseId, view = 'plain_select',
  } = {}, { getState, rejectWithValue, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());

    const response = await gccApiClient.getAgentUsersForHouse(
      houseId || activeHouseId,
      pageIndex,
      pageSize,
      searchQuery,
      sortOrder,
      sortedColumnId,
      view,
    );

    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination };
  },
);

export const fetchTeamOptions = createAsyncThunk(
  'houses/fetchTeams',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, houseId,
  } = {}, { getState, rejectWithValue, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());

    const response = await gccApiClient.getTeamsForHouse(
      pageIndex, pageSize, searchQuery, houseId || activeHouseId, sortOrder, sortedColumnId,
    );

    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination };
  },
);
