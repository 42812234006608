import { hasStaleOpData } from 'gcs-common/slices/processes/processesSelectors';
import { useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import styles from './styles.module.scss';

const OpStatus = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const staleOpData = useSelector(hasStaleOpData(selectedChannelId));

  if (staleOpData) {
    return (
      <div className={styles.opStatus}>
        <div className={styles.opStatusText}>
          {`Aktuell gibt es Verbindungsprobleme zu ONLINE PLUS.
                    Deshalb kann es vorkommen, dass nicht alle Vorgänge angezeigt werden`}
        </div>
      </div>
    );
  }
  return null;
};

export default OpStatus;
