import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class TextMessageModel extends AbstractMessageModel {

  constructor({ text, linkPreviews, ...args } = {}) {
    super({
      messageType: MESSAGE_TYPE.TEXT,
      ...args,
    });

    this.text = text;
    this.linkPreviews = linkPreviews;
  }
}
