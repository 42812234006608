import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CHANNEL_TYPE, ChannelTypeInfo } from 'gcs-common/helper/channelTypeHelper';
import {
  getAgentGroupChannels,
  getAgentGroupChannelsLoading,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import { fetchAgentGroupChannels } from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import {
  deleteGroupChannel,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';
import moment from 'moment';
import { updateTeamchatPicture } from 'gcs-common/slices/uiState/uiStateSlice';
import { useNavigate } from 'react-router';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import Table from '../../../Table/Table';
import ProfileImageCell from '../../../Table/ProfileImageCell/ProfileImageCell';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import AgentGroupChannelCreate from '../AgentGroupChannelCreate/AgentGroupChannelCreate';
// import AddFabButton from '../../../AddFabButton/AddFabButton';
// import addIcon from '../../../../img/plus_white.svg';
import LozengeCell from '../../../Table/LozengeCell/LozengeCell';
import deletedIcon from '../../../../img/delete.svg';
import detailsIcon from '../../../../img/arrow.svg';
import GroupChannelDelete from '../../Channels/GroupChannelDelete/GroupChannelDelete';
import { stringSortingFunction } from '../../../../helper/sortHelper';

const channelColumns = [
  {
    Header: '',
    accessor: 'imageUrl',
    Cell: ProfileImageCell,
    disableSortBy: true,
  },
  {
    Header: 'Haus',
    accessor: 'primaryHouse.friendlyName',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Channel Type',
    accessor: 'channelType',
    filter: 'fuzzyText',
    Cell: (cell) => cell.value && LozengeCell({
      value: ChannelTypeInfo[cell.value] ? ChannelTypeInfo[cell.value].name : cell.value,
      color: ChannelTypeInfo[cell.value] ? ChannelTypeInfo[cell.value].color : '#827f7f',
    }),
  },
  {
    Header: 'Name',
    accessor: 'friendlyName',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Kundennummer',
    accessor: 'customerId',
  },
  {
    Header: 'Nachrichten',
    accessor: 'messagesCount',
  },
  {
    Header: 'Teilnehmer',
    accessor: 'membersCount',
    filter: 'fuzzyText',
  },
  {
    Header: 'Erstellt von',
    accessor: 'createdBy',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Erstellt am',
    accessor: 'dateCreated',
    Cell: (cell) => {
      return cell.value ? moment(cell.value).format('ddd, D MMM YYYY HH:mm') : '-';
    },
  },
];

// TODO rename component
const AgentGroupChannelTable = ({ agentId, searchInputValue = '', channelTypes }) => {

  const agentGroupChannels = useSelector(getAgentGroupChannels);
  const agentDirectChannelsLoading = useSelector(getAgentGroupChannelsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showChannelCreate, setShowChannelCreate] = useState(false);
  const [showChannelDelete, setShowChannelDelete] = useState(false);

  useEffect(() => {
    if (agentId) {
      dispatch(fetchAgentGroupChannels({ agentId, channelTypes }));
    }
  }, [agentId, dispatch, channelTypes]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setShowChannelDelete(original);
    },
    [],
  );

  const groupChannelCreated = useCallback(
    () => {
      setShowChannelCreate(false);
      dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
      dispatch(updateTeamchatPicture({ teamchatPicture: '' }));
    },
    [dispatch],
  );

  const onCancel = useCallback(
    () => {
      setShowChannelCreate(false);
      dispatch(updateTeamchatPicture({ teamchatPicture: '' }));
    },
    [dispatch],
  );

  const groupChannelDeleted = useCallback(
    () => {
      setShowChannelDelete(false);
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    },
    [dispatch],
  );

  const onChannelSelected = (channel) => {
    const { id } = channel;
    navigate(`/administration/users/channel/${id}`);
  };

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteGroupChannel(
        { channelId: showChannelDelete.id },
      ));
      if (!result.error) {
        groupChannelDeleted();
        setTimeout(() => {
          dispatch(fetchAgentGroupChannels({ agentId }));
        }, 1000);
      }
    },
    [showChannelDelete, dispatch, groupChannelDeleted, agentId],
  );

  const message = useMemo(() => {
    return `Möchten Sie den Gruppen Channel "${showChannelDelete?.friendlyName}" wirklich löschen?`;
  }, [showChannelDelete]);

  return (
    <>
      <Table
        isLoading={agentDirectChannelsLoading}
        data={agentGroupChannels}
        columns={channelColumns}
        filterString={searchInputValue}
        onRowSelected={onChannelSelected}
        renderActions={({ row }) => (
          <>
            <button type="button">
              {/* eslint-disable-next-line max-len */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img
                alt="deleted-icon"
                src={deletedIcon}
                onClick={(event) => deleteSelected(event, row)}
              />
            </button>
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      {/* Removed the feature temporarily to avoid confusion - SMOOT-3126 */}
      {/* <AddFabButton
        title="Gruppe"
        icon={addIcon}
        onClick={() => setShowChannelCreate(true)}
      /> */}
      {showChannelCreate && (
        <PopUpModal
          title="Gruppen Channel erstellen"
          onRequestClose={onCancel}
          isOpen
        >
          <AgentGroupChannelCreate
            onCancel={onCancel}
            onSuccess={groupChannelCreated}
          />
        </PopUpModal>

      )}
      {showChannelDelete && (
        <PopUpModal
          title="Channel löschen"
          onRequestClose={() => setShowChannelDelete(null)}
          isOpen
        >
          <GroupChannelDelete
            message={message}
            onCancel={() => setShowChannelDelete(null)}
            handleDelete={handleDelete}
          />
        </PopUpModal>
      )
      }
    </>
  );
};

AgentGroupChannelTable.propTypes = {
  searchInputValue: PropTypes.string,
  agentId: PropTypes.string.isRequired,
  channelTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CHANNEL_TYPE))).isRequired,
};

export default AgentGroupChannelTable;
