import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchCurrentUserErpCustomers = createAsyncThunk(
  'currentUser/fetchCurrentUserErpCustomers',
  async ({
    pageIndex, pageSize, search, plainSelect,
  } = { pageIndex: 0, pageSize: 10 }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getErpCustomerForUser(
      { pageIndex, pageSize, search, plainSelect },
    );
    const { data, pagination } = response;
    return { data, pagination };
  },
);

export default fetchCurrentUserErpCustomers;
