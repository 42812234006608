/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../../constants/AsyncState';

import {
  fetchCustomerResponsibilityMembers,
  createCustomerResponsibilityMember,
  fetchAllResponsibilityRoles, deleteCustomerResponsibilityMember,
} from './customerResponsibilityMembersThunk';

import paginateReducer from '../../../helper/reduxPaginationHelper';
import { getErrorMsg } from '../../../helper/errorMessages';
import mergeObjects from '../../../helper/mergeObjects';
import { byId } from '../../../helper/byKey';

const asyncState = {
  fetchCustomerResponsibilityMembers: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchCustomerResponsibilityRoles: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchCustomerResponsibilityRolesWithErpCustomerForHouse: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  createCustomerResponsibilityMember: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  deleteCustomerResponsibilityMember: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  customerResponsibilityMembers: {},
  customerResponsibilityRoles: [],
  customerResponsibilityRolesWithErpCustomerForHouse: [],
  selectedCustomerResponsibilityMember: undefined,
};


const customerResponsibilityMembersSlice = createSlice({
  name: 'customerResponsibilityMembers',
  initialState,
  reducers: {
    resetCreateCustomerResponsibilityMemberErrorMessage: (state) => {
      state.createCustomerResponsibilityMember.errorMessage = null;
    },
    resetDeleteCustomerResponsibilityMemberErrorMessage: (state) => {
      state.deleteCustomerResponsibilityMember.errorMessage = null;
    },
    resetCustomerResponsibilityMembers: (state) => {
      state.customerResponsibilityMembers = {};
    },
  },
  extraReducers: {
    [fetchCustomerResponsibilityMembers.pending]: (state) => {
      state.fetchCustomerResponsibilityMembers.status = ASYNC_STATE.LOADING;
    },
    [fetchCustomerResponsibilityMembers.fulfilled]: paginateReducer(
      (state) => state.fetchCustomerResponsibilityMembers,
      (state, action) => {
        const { data, mergeData } = action.payload;
        state.fetchCustomerResponsibilityMembers.status = ASYNC_STATE.SUCCEEDED;
        if (mergeData) {
          // eslint-disable-next-line max-len
          state.customerResponsibilityMembers = mergeObjects(state.customerResponsibilityMembers, byId(data));
          return;
        }
        state.customerResponsibilityMembers = { ...byId(data) };
      },
    ),
    [fetchCustomerResponsibilityMembers.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchCustomerResponsibilityMembers.status = ASYNC_STATE.FAILED;
      state.fetchCustomerResponsibilityMembers.error = error;
    },
    [fetchAllResponsibilityRoles.fulfilled]: (state, action) => {
      const responsibilityRoles = action.payload;
      state.fetchCustomerResponsibilityRoles.status = ASYNC_STATE.SUCCEEDED;
      state.customerResponsibilityRoles = responsibilityRoles;
    },
    [fetchAllResponsibilityRoles.pending]: (state) => {
      state.fetchCustomerResponsibilityRoles.status = ASYNC_STATE.LOADING;
    },
    [fetchAllResponsibilityRoles.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchCustomerResponsibilityRoles.status = ASYNC_STATE.FAILED;
      state.fetchCustomerResponsibilityRoles.error = error;
    },
    [createCustomerResponsibilityMember.pending]: (state) => {
      state.createCustomerResponsibilityMember.status = ASYNC_STATE.LOADING;
    },
    [createCustomerResponsibilityMember.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.createCustomerResponsibilityMember.status = ASYNC_STATE.FAILED;
      state.createCustomerResponsibilityMember.error = errorCode;
      if (errorCode && errorCode === 'create_customer_responsibility_member.role_id_and_customer_responsibility_id_exist') {
        state.createCustomerResponsibilityMember.errorMessage = getErrorMsg('create_customer_responsibility_member.role_for_customer_exists');
      } else {
        state.createCustomerResponsibilityMember.errorMessage = getErrorMsg('create_customer_responsibility_member.default');
      }
    },
    [createCustomerResponsibilityMember.fulfilled]: (state) => {
      state.createCustomerResponsibilityMember.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteCustomerResponsibilityMember.pending]: (state) => {
      state.deleteCustomerResponsibilityMember.status = ASYNC_STATE.LOADING;
    },
    [deleteCustomerResponsibilityMember.fulfilled]: (state) => {
      state.deleteCustomerResponsibilityMember.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteCustomerResponsibilityMember.rejected]: (state, action) => {
      const { error } = action.error;
      state.deleteCustomerResponsibilityMember.status = ASYNC_STATE.FAILED;
      state.deleteCustomerResponsibilityMember.error = error;
      state.deleteCustomerResponsibilityMember.errorMessage = getErrorMsg('default');
    },
  },
});

export const {
  resetCreateCustomerResponsibilityMemberErrorMessage,
  resetDeleteCustomerResponsibilityMemberErrorMessage,
  resetCustomerResponsibilityMembers,
} = customerResponsibilityMembersSlice.actions;

export default customerResponsibilityMembersSlice.reducer;
