
// taken from https://stackoverflow.com/a/13419367/3200478
// eslint-disable-next-line import/prefer-default-export
export const parseQs = (queryString) => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

export const attachQueryStringToUrl = (url, params) => {
  return Object.keys(params).reduce(
    (newUrl, key, id) => `${newUrl}${id ? '&' : '?'}${key}=${encodeURIComponent(params[key])}`,
    url,
  );
};
