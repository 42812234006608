import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGcopError,
  getGcopLoading, getGcopPassword,
  getGcopUsername,
} from 'gcs-common/slices/currentCraftsmanUser/currentCraftsmanUserSelectors';
import {
  resetError, setGcopPassword,
  setGcopUsername,
} from 'gcs-common/slices/currentCraftsmanUser/currentCraftsmanUserSlice';
import linkAccount from 'gcs-common/slices/currentCraftsmanUser/currentCraftsmanUserThunks/linkAccount';
import { getIsCurrentUserHasConnections } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getDefaultBrand } from 'gcs-common/slices/theme/themeSelectors';
import { REGISTRATION_ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';
import { useLogoutMutation } from 'gcs-common/clients/api/entities/auth/auth-api';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import Button from '../../Button/Button';
import styles from './styles.module.scss';
import MessageLikeTextBox from '../../MessageLikeTextBox/MessageLikeTextBox';

import RegistrationExit from '../RegistrationExit/RegistrationExit';

function LinkExternalAccount() {
  const isCurrentUserHasConnections = useSelector(getIsCurrentUserHasConnections);
  const loading = useSelector(getGcopLoading);
  const defaultBrand = useSelector(getDefaultBrand);
  const username = useSelector(getGcopUsername);
  const password = useSelector(getGcopPassword);
  const error = useSelector(getGcopError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const logoutMutation = useLogoutMutation();

  useEffect(() => {
    if (isCurrentUserHasConnections) {
      // either after successfully entering the credentials
      navigate(REGISTRATION_ROUTES.REGISTRATION_PROFILE);
    }
  }, [navigate, isCurrentUserHasConnections]);

  const onBackButtonEvent = useCallback((event) => {
    event.preventDefault();
    setBackButtonClicked(true);
    navigate(REGISTRATION_ROUTES.REGISTRATION_EXTERNAL);
  }, [navigate]);

  useEffect(() => {
    navigate(REGISTRATION_ROUTES.REGISTRATION_EXTERNAL);
    // Chrome has an extra security measure that disallow this event to be listened
    // without a user interaction.
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [navigate, onBackButtonEvent]);

  async function logoutUser() {
    await logoutMutation.mutateAsync();
  }

  const onResetError = useCallback(() => {
    dispatch(resetError());
  }, [dispatch]);
  const onLinkAccount = useCallback(async () => {
    await dispatch(linkAccount({ brand: defaultBrand, username, password }));
  }, [dispatch, defaultBrand, username, password]);

  if (logoutMutation.isPending) {
    return <LoadingIndicatorFullScreen loadingText="Sie werden ausgeloggt..." />;
  }

  return (
    <div className={styles.linkExternalWrapper}>
      <div className={styles.content}>
        {error ? (
          <>
            <MessageLikeTextBox width="70%" className={styles.errorMsg}>{error}</MessageLikeTextBox>
            <Button registrationButton onClick={onResetError}>
              Nochmal versuchen
            </Button>
          </>
        ) : (
          <>
            <MessageLikeTextBox width="70%">
              Bitte tragen Sie ihre ONLINE PLUS Anmeldedaten ein:
            </MessageLikeTextBox>
            <MessageLikeTextBox right>
              {(loading) ? (
                <LoadingIndicator />
              ) : (
                <>
                  <input
                    className={styles.input}
                    placeholder="Benutzer"
                    type="text"
                    id="username"
                    value={username}
                    onChange={(event) => dispatch(setGcopUsername({
                      gcopUsername: event.target.value,
                    }))}
                    style={{ backgroundColor: username && 'white' }}
                  />
                  <input
                    className={styles.input}
                    placeholder="Password"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(event) => dispatch(setGcopPassword({
                      gcopPassword: event.target.value,
                    }))}
                    style={{ backgroundColor: !!password && 'white' }}
                  />
                </>
              )}
            </MessageLikeTextBox>
            <Button
              registrationButton
              disabled={loading}
              className={styles.validateButton}
              onClick={onLinkAccount}
            >
              Überprüfen & weiter
            </Button>
          </>
        )
        }
        {backButtonClicked
          && (
            <RegistrationExit
              onSuccess={logoutUser}
              onRequestClose={() => setBackButtonClicked(false)}
              isOpen={backButtonClicked}
            />
          )
        }
      </div>
    </div>
  );
}

LinkExternalAccount.propTypes = {};

export default LinkExternalAccount;
