import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getChannelListChannelIds,
} from 'gcs-common/slices/channels/channelsSelectors';
import useChannelInitialization from 'gcs-common/hooks/useChannelInitialization';
import ChatNavBar from '../ChatNavBar/ChatNavBar';
import styles from './styles.module.scss';
import Chat from '../Chat/Chat';

function ChatWrapper() {
  const isAgent = useSelector(getIsAgent);
  const allChannels = useSelector(getChannelListChannelIds);
  const hasChannels = !!allChannels?.length;
  const rootSeparator = isAgent ? styles.rootSeparator : styles.rootSeparatorLight;

  useChannelInitialization();

  return (
    <div className={styles.chatWrapper}>
      {hasChannels && (
        <>
          <ChatNavBar />
          <div className={rootSeparator} />
          <Chat />
        </>
      )}
    </div>
  );
}

export default ChatWrapper;
