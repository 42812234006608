import { useSelector } from 'react-redux';
import {
  getCurrentUserImage,
  getHasCurrentUserImage,
  getIsUpdateProfilePicturePending,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import PlusPrimaryIcon from '../../../../img/plus_primary.svg?react';
import styles from './styles.module.scss';


const UserProfilePictureButton = (props) => {
  const currentUserHasImage = useSelector(getHasCurrentUserImage);
  const currentUserImage = useSelector(getCurrentUserImage);
  const isUpdatingProfilePicture = useSelector(getIsUpdateProfilePicturePending);

  let content;

  if (isUpdatingProfilePicture) {
    content = <LoadingIndicator />;
  } else if (currentUserHasImage) {
    content = (
      <div className={styles.profileImgContainer}>
        <img
          src={currentUserImage}
          className={styles.profileImage}
          alt="profile-user"
        />
        <div className={styles.overlay}>
          Ändern
        </div>
      </div>
    );
  } else {
    content = (
      <div className={styles.profileImgPlaceholder}>
        <PlusPrimaryIcon className={styles.plusButton} />
        <div className={styles.imgPlaceHolder}>Bild</div>
      </div>
    );
  }

  return (
    <button type="button" {...props}>
      {content}
    </button>
  );
};

export default UserProfilePictureButton;
