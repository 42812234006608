import { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { pad } from 'gcs-common/helper/paddedNumberHelper';
import {
  getProcessDetailsForProcess,
  getProcessDetailsLoading,
  getSelectedProcess,
  getSelectedProduct,
} from 'gcs-common/slices/processes/processesSelectors';
import {
  processSelected,
  productSelected,
} from 'gcs-common/slices/processes/processesSlice';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import { channelInputCleared } from 'gcs-common/slices/messageInput/messageInputSlice';
import { getChannelOPUserId } from 'gcs-common/slices/channel/channelSelectors';
import fetchProcessDetails from 'gcs-common/slices/processes/processesThunks/fetchProcessDetails';
import {
  convertOrderStatusToString,
  convertProcessTypeToString,
} from 'gcs-common/helper/processTextHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import RenderButtons from './RenderButtons/RenderButtons';
import ProductListItem from './ProductListItem/ProductListItem';
import ProductDetail from './ProductDetail/ProcessDetail';

import backIcon from '../../img/back.svg';
import styles from './styles.module.scss';

const ProcessDetail = () => {

  const selectedProcess = useSelector(getSelectedProcess);

  const {
    ProcessNumber: Number,
    ProcessSuffix: Suffix,
    OrderStatusText,
    OrderStatus,
    CreationDate: CreateDate,
    CustomerOrderText,
    CustomerOrderNumber,
    ProcessType,
    ProcessTypeDescription,
  } = selectedProcess;

  const selectedChannelId = useSelector(getSelectedChannelId);
  const loading = useSelector(getProcessDetailsLoading);
  const processDetails = useSelector(getProcessDetailsForProcess(Number, Suffix));
  const selectedProduct = useSelector(getSelectedProduct);
  const gcopUserId = useSelector(getChannelOPUserId(selectedChannelId));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProcessDetails({ Number,
      Suffix,
      id: gcopUserId,
      channelId: selectedChannelId }));
  }, [Number, Suffix, dispatch, gcopUserId, selectedChannelId]);

  const List = processDetails || [];

  const requestClose = () => {
    if (selectedProduct) {
      dispatch(productSelected({ selectedProduct: null }));
    } else {
      dispatch(processSelected({ selectedProcess: null }));
      dispatch(channelInputCleared({ channelId: selectedChannelId }));
    }
  };

  const handleSelectProduct = useCallback(
    (product) => {
      dispatch(productSelected({ selectedProduct: product }));
    },
    [dispatch],
  );

  return (
    <div className={styles.selectedProcessWrapper}>
      <div className={styles.selectedProcessHeader}>
        <button type="button" onClick={requestClose}>
          <img
            className={styles.backButtonIcon}
            src={backIcon}
            alt="exit-icon"
          />
        </button>
      </div>
      {(
        selectedProduct
          ? (
            <ProductDetail
              product={selectedProduct}
            />
          )
          : (
            <>
              <div className={styles.selectedProcessInfo}>
                <div
                  className={styles.dateTag}
                >
                  {moment(CreateDate).format('dddd,  L')}
                </div>
                <div className={styles.processName}>
                  {`${convertProcessTypeToString(ProcessType, ProcessTypeDescription)}: `}
                  <span>{`${Number}-${pad(Suffix)}`}</span>
                </div>
                <div className={styles.processHint}>
                  Auftragsnummer:
                  {' '}
                  {CustomerOrderNumber}
                </div>
                <div className={styles.processHint}>
                  Auftragstext:
                  {' '}
                  {CustomerOrderText}
                </div>
                <div className={styles.processState}>{`Status: ${convertOrderStatusToString(OrderStatus, OrderStatusText, ProcessType) || 'Nicht verfügbar'}`}</div>
              </div>
              <div className={styles.selectedProcessProductList}>
                {List && List.map(
                  (product) => (
                    <ProductListItem
                      key={product.ArticleNumber + product.Id}
                      product={product}
                      onSelectProduct={() => handleSelectProduct(product)}
                    />
                  ),
                )}
              </div>
            </>
          )
      )}
      <div className={styles.buttonContainer}>
        {loading && <LoadingIndicator />}
        {!loading && (
          <RenderButtons
            process={selectedProcess}
            product={selectedProduct}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessDetail;
