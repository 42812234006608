import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchFeatureFlags = createAsyncThunk(
  'featureFlags/fetchFeatureFlags',
  async (_, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAllFeatureFlags();
    return { featureFlags: response };
  },
);

export const updateFeatureFlag = createAsyncThunk(
  'featureFlags/updateFeatureFlag',
  async ({ featureFlagId, userIds, tenantIds, houseIds }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateFeatureFlag(featureFlagId, {
      userIds,
      tenantIds,
      houseIds,
    });
    return { featureFlags: response };

  },
);
