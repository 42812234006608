/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import ASYNC_STATE from '../../constants/AsyncState';
import {
  fetchTickets,
  fetchTicket,
  fetchTicketsForDashboard,
  fetchTicketParts,
  fetchTicketComments,
  createTicket,
  newTicketFromMessages,
  fetchTicketAttachments,
  uploadTicketAttachments,
  addTicketComment,
  deleteTicketComment,
  deleteTicketNotification,
  fetchTicketNotifications,
  fetchTicketRating,
} from './ticketsThunks';
import { byId } from '../../helper/byKey';
import mergeObjects from '../../helper/mergeObjects';
import { TICKET_VIEWS } from './ticketsConstants';

const asyncState = {
  fetchTickets: {
    // [channelId | null]: {
    //   status: ASYNC_STATE.IDLE,
    //   error: null,
    // }
  },
  fetchTicket: {
    // [ticketId]: {
    //   status: ASYNC_STATE.IDLE,
    //   error: null,
    // }
  },
  fetchTicketParts: {
    // [ticketId]: {
    //   status: ASYNC_STATE.IDLE,
    //   error: null,
    // }
  },
  fetchTicketAttachments: {
    // [ticketId]: {
    //   status: ASYNC_STATE.IDLE,
    //   error: null,
    // }
  },
  fetchTicketComments: {
    // [ticketId]: {
    //   status: ASYNC_STATE.IDLE,
    //   error: null,
    // }
  },
  fetchTicketRating: {
    // [ticketId]: {
    //   status: ASYNC_STATE.IDLE,
    //   error: null,
    // }
  },
  fetchTicketNotifications: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  createTicket: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  uploadTicketAttachments: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  addTicketComment: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  deleteTicketComment: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  deleteTicketNotification: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  fetchTicketsForDashboard: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
};

const initialState = {
  ...asyncState,
  tickets: {}, // tickets by id [ticketId] => { ...ticket }
  ticketView: {}, // selected view per channel [channelId] => { view: 'form' }
  ticketParts: {}, // ticket parts by ticket id [ticketId] => {...ticketParts}
  ticketAttachments: {}, // ticket attachments by ticket id [ticketId] => [...ticketAttachments]
  ticketComments: {}, // ticket comments by ticket id [ticketId] => [...ticketComments]
  ticketNotifications: {}, // notifications by ticket id [ticketId] => [...ticketNotifications]
  ticketRating: {}, // ticket rating by ticket id [ticketId] => {...ticketRating}
  channelTickets: {}, // ticketId per channel [channelId] => [...ticketIds]
  channelNotifications: {}, // notifications by channel id [channelId] => [...ticketIds]
  drafts: {}, // ticket form drafts by channel id [channelId] => {...formValues}
  dashboard: {
    selectedTicketId: null, // ticket id selected I.E: 980d002d-9425-487c-b197-00b263a4c080
    data: [], // paginated tickets I.E [...ticketObj]
    pagination: {}, // pagination data I.E {...total, ...size, ...pages}
    status: {}, // status count data
    creators: [], // ticket creators [{...creators}] this is used for the Creator Filter.
  },
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    ticketIdSelected: (state, action) => {
      const { channelId, ticketId, firstTime } = action.payload;

      state.ticketView[channelId || null] = {
        view: TICKET_VIEWS.DETAILS,
        ticketId,
        firstTime,
      };
    },
    createNewTicketSelected: (state, action) => {
      const { channelId } = action.payload || {};

      state.ticketView[channelId || null] = {
        view: TICKET_VIEWS.FORM,
      };
    },
    ticketFormChanged: (state, action) => {
      const { channelId, ticketId, formValues } = action.payload || {};

      if (!formValues) {
        delete state.drafts[channelId || ticketId || null];
      } else {
        state.drafts[channelId || ticketId || null] = formValues;
      }
    },
    backToTicketsOverview: (state, action) => {
      const { channelId } = action.payload || {};

      state.ticketView[channelId || null] = undefined;
    },
    clearFormPrefill: (state, action) => {
      let { channelId } = action.payload;
      channelId ||= null;

      if (channelId in state.ticketView) {
        state.ticketView[channelId].prefill = undefined;
      }
    },
    selectDashboardTicket: (state, action) => {
      const { payload } = action;
      state.dashboard.selectedTicketId = payload;
    },
  },
  extraReducers: {
    [fetchTickets.pending]: (state, action) => {
      const channelId = action.meta.arg?.channelId || null;

      state.fetchTickets[channelId] = {
        status: ASYNC_STATE.LOADING,
        error: null,
      };
    },
    [fetchTickets.fulfilled]: (state, action) => {
      const { payload, meta } = action;
      const channelId = meta.arg?.channelId || null;

      state.fetchTickets[channelId] = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };

      state.tickets = mergeObjects(state.tickets, byId(payload.data ?? []));
      state.channelTickets = Object.values(state.tickets).reduce((acc, ticket) => {
        let { channelId: ticketChannelId } = ticket;
        ticketChannelId ||= null;
        acc[ticketChannelId] = acc[ticketChannelId] ?? [];
        acc[ticketChannelId].push(ticket.id);
        return acc;
      }, {});
    },
    [fetchTickets.rejected]: (state, action) => {
      const { errorCode, meta } = action;
      const channelId = meta.arg?.channelId || null;

      state.fetchTickets[channelId] = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
      };
    },
    [fetchTicket.pending]: (state, action) => {
      const { meta: { arg: { ticketId } } } = action;

      state.fetchTicket[ticketId] = {
        status: ASYNC_STATE.LOADING,
        error: null,
      };
    },
    [fetchTicket.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { ticketId } } } = action;

      state.fetchTicket[ticketId] = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };

      state.tickets[ticketId] = payload;
      state.channelTickets = Object.values(state.tickets).reduce((acc, ticket) => {
        let { channelId: ticketChannelId } = ticket;
        ticketChannelId ||= null;
        acc[ticketChannelId] = acc[ticketChannelId] ?? [];
        acc[ticketChannelId].push(ticket.id);
        return acc;
      }, {});
    },
    [fetchTicket.rejected]: (state, action) => {
      const { errorCode, meta: { arg: { ticketId } } } = action;

      state.fetchTicket[ticketId] = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
      };
    },
    [fetchTicketParts.pending]: (state, action) => {
      const { meta: { arg: { ticketId } } } = action;

      state.fetchTicketParts[ticketId] = {
        status: ASYNC_STATE.LOADING,
        error: null,
      };
    },
    [fetchTicketParts.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { ticketId } } } = action;

      state.fetchTicketParts[ticketId] = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };

      state.ticketParts[ticketId] = payload;
    },
    [fetchTicketParts.rejected]: (state, action) => {
      const { errorCode, meta: { arg: { ticketId } } } = action;
      state.fetchTicketParts[ticketId] = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
      };
    },
    [createTicket.pending]: (state) => {
      state.createTicket.status = ASYNC_STATE.LOADING;
    },
    [createTicket.fulfilled]: (state) => {
      state.createTicket.status = ASYNC_STATE.SUCCEEDED;
    },
    [createTicket.rejected]: (state, action) => {
      state.createTicket.status = ASYNC_STATE.FAILED;
      state.createTicket.error = action.payload?.errorCode;
    },
    [newTicketFromMessages.fulfilled]: (state, action) => {
      const { payload: { messages, files }, meta: { arg: { channelId } } } = action;

      state.ticketView[channelId || null] = {
        view: TICKET_VIEWS.FORM,
        prefill: {
          description: messages?.map((message) => message.text).filter(text => !!text).join('\n\n'),
          files,
        },
      };
    },
    [fetchTicketAttachments.pending]: (state, action) => {
      const { meta: { arg: { ticketId } } } = action;

      state.fetchTicketAttachments[ticketId] = {
        status: ASYNC_STATE.LOADING,
        error: null,
      };
    },
    [fetchTicketAttachments.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { ticketId } } } = action;

      state.fetchTicketAttachments[ticketId] = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };

      state.ticketAttachments[ticketId] = payload.attachments;
    },
    [fetchTicketAttachments.rejected]: (state, action) => {
      const { errorCode, meta: { arg: { ticketId } } } = action;
      state.fetchTicketAttachments[ticketId] = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
      };
    },
    [uploadTicketAttachments.pending]: (state) => {
      state.uploadTicketAttachments.status = ASYNC_STATE.LOADING;
    },
    [uploadTicketAttachments.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { ticketId } } } = action;

      state.uploadTicketAttachments.status = ASYNC_STATE.SUCCEEDED;

      state.ticketAttachments[ticketId] = state.ticketAttachments[ticketId] ?? [];
      state.ticketAttachments[ticketId].push(...payload.attachments);
    },
    [uploadTicketAttachments.rejected]: (state, action) => {
      state.uploadTicketAttachments.status = ASYNC_STATE.FAILED;
      state.uploadTicketAttachments.error = action.payload?.errorCode;
    },
    [fetchTicketComments.pending]: (state, action) => {
      const { meta: { arg: { ticketId } } } = action;

      state.fetchTicketComments[ticketId] = {
        status: ASYNC_STATE.LOADING,
        error: null,
      };
    },
    [fetchTicketComments.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { ticketId } } } = action;

      state.fetchTicketComments[ticketId] = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };

      state.ticketComments[ticketId] = payload.comments;
    },
    [fetchTicketComments.rejected]: (state, action) => {
      const { errorCode, meta: { arg: { ticketId } } } = action;
      state.fetchTicketComments[ticketId] = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
      };
    },
    [addTicketComment.pending]: (state) => {
      state.addTicketComment.status = ASYNC_STATE.LOADING;
    },
    [addTicketComment.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { ticketId } } } = action;

      state.addTicketComment.status = ASYNC_STATE.SUCCEEDED;

      state.ticketComments[ticketId] = state.ticketComments[ticketId] ?? [];
      state.ticketComments[ticketId].push(payload);

      if (ticketId in state.ticketNotifications) {
        delete state.ticketNotifications[ticketId];
      }

    },
    [addTicketComment.rejected]: (state, action) => {
      state.addTicketComment.status = ASYNC_STATE.FAILED;
      state.addTicketComment.error = action.payload?.errorCode;
    },
    [deleteTicketComment.pending]: (state) => {
      state.deleteTicketComment.status = ASYNC_STATE.LOADING;
    },
    [deleteTicketComment.fulfilled]: (state, action) => {
      const { meta: { arg: { ticketId, commentId } } } = action;

      state.deleteTicketComment.status = ASYNC_STATE.SUCCEEDED;

      if (ticketId in state.ticketComments) {
        remove(state.ticketComments[ticketId], comment => comment.id === commentId);
      }
    },
    [deleteTicketComment.rejected]: (state, action) => {
      state.deleteTicketComment.status = ASYNC_STATE.FAILED;
      state.deleteTicketComment.error = action.payload?.errorCode;
    },
    [fetchTicketNotifications.pending]: (state) => {
      state.fetchTicketNotifications.status = ASYNC_STATE.LOADING;
    },
    [fetchTicketNotifications.fulfilled]: (state, action) => {
      const { payload } = action;

      state.fetchTicketNotifications.status = ASYNC_STATE.SUCCEEDED;
      state.ticketNotifications = mergeObjects(
        state.ticketNotifications,
        byId(payload.notifications ?? []),
      );

      state.channelNotifications = Object.values(state.ticketNotifications).reduce(
        (acc, ticket) => {
          let { channelId: ticketChannelId } = ticket;
          ticketChannelId ||= null;
          acc[ticketChannelId] = acc[ticketChannelId] ?? [];
          acc[ticketChannelId].push(ticket.id);
          return acc;
        }, {},
      );
    },
    [fetchTicketNotifications.rejected]: (state, action) => {
      state.fetchTicketNotifications.status = ASYNC_STATE.FAILED;
      state.fetchTicketNotifications.error = action.payload.errorCode;
    },
    [deleteTicketNotification.pending]: (state) => {
      state.deleteTicketNotification.status = ASYNC_STATE.LOADING;
    },
    [deleteTicketNotification.fulfilled]: (state, action) => {
      const { meta: { arg: { id } } } = action;

      state.deleteTicketNotification.status = ASYNC_STATE.SUCCEEDED;
      delete state.ticketNotifications[id];
    },
    [deleteTicketNotification.rejected]: (state, action) => {
      state.deleteTicketNotification.status = ASYNC_STATE.FAILED;
      state.deleteTicketNotification.error = action.payload.errorCode;
    },
    [fetchTicketRating.pending]: (state, action) => {
      const { meta: { arg: { ticketId } } } = action;

      state.fetchTicketRating[ticketId] = {
        status: ASYNC_STATE.LOADING,
        error: null,
      };
    },
    [fetchTicketRating.fulfilled]: (state, action) => {
      const { payload, meta: { arg: { ticketId } } } = action;

      state.fetchTicketRating[ticketId] = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };
      state.ticketRating[ticketId] = payload;
    },
    [fetchTicketRating.rejected]: (state, action) => {
      const { errorCode, meta: { arg: { ticketId } } } = action;
      state.fetchTicketRating[ticketId] = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
      };
    },
    [fetchTicketsForDashboard.pending]: (state) => {
      state.fetchTicketRating = {
        status: ASYNC_STATE.LOADING,
        error: null,
        dashboard: {
          data: [],
          pagination: {},
          status: {},
          creators: [],
        },
      };
    },
    [fetchTicketsForDashboard.fulfilled]: (state, action) => {
      const { payload } = action;
      const { pagination, data, status, creators } = payload;
      state.fetchTicketsForDashboard = {
        status: ASYNC_STATE.SUCCEEDED,
        error: null,
      };
      state.dashboard.data = data;
      state.dashboard.pagination = pagination;
      state.dashboard.status = status;
      state.dashboard.creators = creators;
    },
    [fetchTicketsForDashboard.rejected]: (state, action) => {
      const { errorCode } = action;
      state.fetchTicketsForDashboard = {
        status: ASYNC_STATE.FAILED,
        error: errorCode,
        dashboard: {
          data: [],
          pagination: {},
          status: {},
          creators: [],
        },
      };
    },
  },
});

export const {
  ticketsLoaded,
  ticketIdSelected,
  backToTicketsOverview,
  createNewTicketSelected,
  ticketFormChanged,
  clearFormPrefill,
  selectDashboardTicket,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
