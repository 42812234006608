/* eslint-disable react/forbid-prop-types */

import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import TextBox from 'gcs-common/components/Formik/TextBox/TextBox';
import Button from 'gcs-common/components/Button/Button';
import styles from './styles.module.scss';
import CommunicationAssignmentSettings from '../../ErpCustomerAssignments/CommunicationAssignmentSettings/CommunicationAssignmentSettings';
import ChannelImageSelect from '../../../ChannelImageSelect/ChannelImageSelect';
import AssignmentDirectionSelect from '../../ErpCustomerAssignments/AssignmentDirectionSelect/AssignmentDirectionSelect';
import MemberSelect from '../MemberSelect/MemberSelect';
import CommunicationStructureSettings from '../../HouseCommunicationStructure/CommunicationStructure/CommunicationStructureSettings/CommunicationStructureSettings';

const RowOpen = ({
  // Formik
  formikInitialValues,
  validationSchema,
  // Booleans
  isEditForm = false,
  isTeamRow = false,
  hasAdminPermission = false,
  isAssignmentGeneratedFromStructure = false,
  isHouseLevel = false,
  isDeleteEnabled = false,
  // Ids
  communicationAssignmentId = '',
  communicationStructureId = '',
  craftnoteId = '',
  // Members
  mainContactPerson = null,
  communicationStructureMembers = [],
  customAssignmentMembers = [],
  mainContactPersonOptions = [],
  memberOptions = [],
  // Functions
  onSubmit,
  onExpandButtonClick,
  onCancel,
  onDelete,
}) => {

  return (
    <div className={styles.form} data-testid="row-open">
      <Formik
        initialValues={formikInitialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange
      >
        {({
          resetForm, values, isSubmitting,
        }) => {
          return (
            <Form>
              {isEditForm && isHouseLevel && (
              <CommunicationStructureSettings
                communicationStructureId={communicationStructureId}
                onExpandButtonClick={onExpandButtonClick}
                rowType="open"
              />
              )}
              {isEditForm && !isHouseLevel && (
              <CommunicationAssignmentSettings
                communicationAssigmentId={communicationAssignmentId}
                onExpandButtonClick={onExpandButtonClick}
                rowType="open"
              />
              )}
              <div className={isEditForm ? styles.topOfForm : ''} />
              {isTeamRow ? (
                <div className={styles.rowChannel}>
                  <div className={styles.avatar}>
                    <ChannelImageSelect
                      name="channelImage"
                      label="Chat Bild"
                      disabled={!hasAdminPermission}
                    />
                  </div>
                  <div className={styles.channelName}>
                    <TextBox
                      name="channelName"
                      label="Chat Name"
                      disabled={!hasAdminPermission}
                      tooltipText={!isHouseLevel ? (
                        'Dieser Name wird im Teamchat angezeigt und kommt aus der Kommunikationsstruktur.'
                      ) : ('')}
                      isRequired
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.directChatHeader} />
              )}
              {/* Communication Structures in House Tab */}
              {isHouseLevel && (
                <>
                  {isTeamRow && (
                    <MemberSelect
                      label="Hauptkontakt"
                      name="mainRole"
                      disabled={!hasAdminPermission}
                      initialValues={mainContactPerson}
                      options={mainContactPersonOptions}
                      isMulti={false}
                      isCommunicationStructure
                      mainContactPerson={mainContactPerson}
                    />
                  )}
                  <MemberSelect
                    label="Kontakte"
                    name="communicationStructureMembers"
                    disabled={!hasAdminPermission}
                    initialValues={communicationStructureMembers}
                    options={memberOptions}
                    isCommunicationStructure
                  />
                </>
              )}
              {/* Communication Assignments in Customer Tab */}
              {!isHouseLevel && (
                <>
                  {isTeamRow && (
                    <MemberSelect
                      label="Hauptkontakt"
                      name="mainContact"
                      disabled={isAssignmentGeneratedFromStructure}
                      isMulti={false}
                      initialValues={mainContactPerson}
                      options={mainContactPersonOptions}
                      mainContactPerson={mainContactPerson}
                    />
                  )}
                  {isAssignmentGeneratedFromStructure && (
                    <MemberSelect
                      label="Zugewiesene Kontakte"
                      name="defaultAssignmentMembers"
                      disabled
                      initialValues={communicationStructureMembers}
                      options={memberOptions}
                      tooltipText="Über die Kommunikationsstrukur werden die Zuweisungen automatisch konfiguriert."
                    />
                  )}
                  <MemberSelect
                    label="Benutzerdefinierte Kontakte"
                    key="customMembersSelect"
                    name="customAssignmentMembers"
                    initialValues={customAssignmentMembers}
                    options={memberOptions}
                    tooltipText="Benutzerdefinierte Kontakte werden nicht mit der Kommunikationsstruktur
                                synchronisiert und bleiben nur bei diesen Kunden sichtbar."
                  />
                </>
              )}

              <div className={styles.rowDirection}>
                <AssignmentDirectionSelect
                  name="assignmentDirection"
                  disabled={!hasAdminPermission}
                />
                {isTeamRow && craftnoteId && (
                <span className={styles.autoGeneratedCheckbox}>
                  <Field
                    type="checkbox"
                    name="isCraftnoteAutogenerate"
                    disabled={!hasAdminPermission}
                  />
                  <span>Mit Craftnote synchronisieren</span>
                </span>
                )}
              </div>
              {hasAdminPermission && (
              <div className={styles.cancelSaveDiv}>
                {isDeleteEnabled && (
                  <Button
                    typeButton
                    className="error"
                    text="Löschen"
                    onClick={() => {
                      onDelete();
                      resetForm();
                    }}
                  />
                )}
                <Button
                  typeButton
                  className="secondary"
                  text="Abbrechen"
                  onClick={() => {
                    onCancel();
                    resetForm();
                  }}
                />
                <Button
                  className="primary"
                  typeButton={false}
                  text="Speichern"
                  disabled={
                    isSubmitting || JSON.stringify(values) === JSON.stringify(formikInitialValues)
                  }
                />
              </div>
              )}
            </Form>
          );
        }
        }
      </Formik>
    </div>
  );
};

RowOpen.propTypes = {
  // Formik
  formikInitialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  // Booleans
  isEditForm: PropTypes.bool,
  isTeamRow: PropTypes.bool,
  isAssignmentGeneratedFromStructure: PropTypes.bool,
  hasAdminPermission: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool,
  isHouseLevel: PropTypes.bool,
  // Ids
  communicationStructureId: PropTypes.string,
  communicationAssignmentId: PropTypes.string,
  craftnoteId: PropTypes.string,
  // Members
  mainContactPerson: PropTypes.object,
  communicationStructureMembers: PropTypes.arrayOf(PropTypes.object),
  customAssignmentMembers: PropTypes.arrayOf(PropTypes.object),
  memberOptions: PropTypes.arrayOf(PropTypes.object),
  mainContactPersonOptions: PropTypes.arrayOf(PropTypes.object),
  // Functions
  onSubmit: PropTypes.func.isRequired,
  onExpandButtonClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RowOpen;
