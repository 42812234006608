import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUserId } from '../../currentUser/currentUserSelectors';
import { addFavoriteOffline } from '../favoritesActions/addFavoriteOffline';
import { getMessage } from '../../messages/messageSelector';

export const addFavorite = createAsyncThunk(
  'favorites/add',
  async ({ channelId, messageId }, { dispatch, getState }) => {
    const currentUserId = getCurrentUserId(getState());
    const message = getMessage(messageId)(getState());
    dispatch(addFavoriteOffline({ channelId, message, messageId, currentUserId }));
  },
);
