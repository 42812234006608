import AsyncSelect from 'react-select/async';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateFeatureFlag } from 'gcs-common/slices/administration/featureFlags/featureFlagsThunks';
import { fetchHouseSelectOptions } from 'gcs-common/slices/administration/houses/housesThunks';
import EditingCell from '../EditingCell/EditingCell';

const FeatureFlagHousesCell = ({ cell, value: houses }) => {

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [options, setOptions] = useState([]);

  const featureFlagId = cell.row.original.id;

  const fetchOptions = useCallback(async (search) => {
    const houseSelectOptions = await dispatch(fetchHouseSelectOptions(search));
    return houseSelectOptions.map(house => ({
      label: house.friendlyName,
      value: house.id,
    }));
  }, [dispatch]);

  const setInitialOptions = useCallback(() => {
    const initialOptions = houses
      .map((house) => ({ label: house.friendlyName, value: house.id }));
    setOptions(initialOptions);
  }, [houses]);

  useEffect(() => {
    setInitialOptions();
  }, [setInitialOptions]);

  const save = useCallback(async () => {
    const houseIds = options?.map((option) => option.value) || [];
    await dispatch(updateFeatureFlag({ featureFlagId, houseIds }));
  }, [dispatch, featureFlagId, options]);

  const cancel = useCallback(() => {
    setInitialOptions();
  }, [setInitialOptions]);

  return (
    <EditingCell
      onSave={save}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      onCancel={cancel}
    >
      <AsyncSelect
        isMulti
        loadOptions={fetchOptions}
        isDisabled={!isEditing}
        value={options}
        onChange={(selected) => {
          setOptions(selected);
        }}
        styles={{ container: (base) => ({ ...base, minWidth: '300px' }) }}
      />

    </EditingCell>
  );
};

FeatureFlagHousesCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      friendlyName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FeatureFlagHousesCell;
