/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../../helper/errorMessages';
import {
  createAgent,
  fetchAgentCompanies,
  fetchAgentDirectChannels,
  fetchAgentGroupChannels,
  fetchSelectedAgent,
  fetchUserDetails,
  fetchSelectUsers,
  fetchAgentsTable,
  createAgentAgentDirectChannel,
  createAgentDirectChannel,
  createAgentGroupChannel,
  fetchListOfUserDetails,
  fetchAgentAssignmentsCount,
  updateSelectedAgent,
  deleteAgent,
  disconnectAgentFromHouse,
} from './administrationUsersThunks';
import ASYNC_STATE from '../../../constants/AsyncState';
import { byId } from '../../../helper/byKey';
import paginateReducer from '../../../helper/reduxPaginationHelper';
import mergeObjects from '../../../helper/mergeObjects';

const asyncState = {
  fetchAgentsTable: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  fetchUserDetails: {},
  fetchListOfUserDetails: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  disconnectAgentFromHouse: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  deleteAgent: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  fetchSelected: {
    status: ASYNC_STATE.IDLE,
  },
  fetchAgentCompanies: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  fetchAgentDirectChannels: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  fetchAgentGroupChannels: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  fetchAgentAssignmentsCount: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  createDirectChannel: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  createGroupChannel: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  updateSelectedAgent: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
};

const initialState = {
  ...asyncState,
  administrationUsers: {}, // users by id
  selectedAgentId: undefined,
  agentCompanies: [],
  agentDirectChannels: [],
  agentGroupChannels: [],
  agentAssignmentsCount: {},
};

const administrationUsersSlice = createSlice({
  name: 'administrationUsers',
  initialState,
  reducers: {
    agentSelected: (state, action) => {
      const { agentId } = action.payload;
      state.selectedAgentId = agentId;
    },
    agentSelectionReset: (state) => {
      state.selectedAgentId = undefined;
    },
    resetCreateAgentErrorMessage: (state) => {
      state.create.errorMessage = null;
    },
    resetCreateAgentDefaultErrorMessage: (state) => {
      state.create.defaultErrorMessage = null;
    },
    resetCreateAgentDirectChannelDefaultErrorMessage: (state) => {
      state.createDirectChannel.defaultErrorMessage = null;
    },
    resetCreateAgentDirectChannelErrorMessage: (state) => {
      state.createDirectChannel.errorMessage = null;
    },
    resetCreateAgentGroupChannelDefaultErrorMessage: (state) => {
      state.createGroupChannel.defaultErrorMessage = null;
    },
    resetCreateAgentGroupChannelErrorMessage: (state) => {
      state.createGroupChannel.errorMessage = null;
    },
    resetAdministrationUsers: (state) => {
      state.administrationUsers = {};
    },
    resetAdministrationUsersByKey: (state, action) => {
      if (!state[action.payload.selectKey]) {
        state[action.payload.selectKey] = { ids: [action.payload.selectedUser] };
      } else {
        state[action.payload.selectKey].ids = [action.payload.selectedUser];
      }
    },
    resetDisconnectAgentFromHouseDefaultErrorMessage: (state) => {
      state.disconnectAgentFromHouse.defaultErrorMessage = null;
    },
    resetDeleteAgentDefaultErrorMessage: (state) => {
      state.deleteAgent.defaultErrorMessage = null;
    },
  },
  extraReducers: {
    [createAgent.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
      state.create.errorMessage = undefined;
    },
    [createAgent.fulfilled]: (state) => {
      state.create.status = ASYNC_STATE.SUCCEEDED;
    },
    [createAgent.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.create.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_agent.email_exists') {
        state.create.errorMessage = getErrorMsg('create_agent.email_exists');
      } else {
        state.create.defaultErrorMessage = getErrorMsg('default');
      }
    },
    [deleteAgent.pending]: (state) => {
      state.deleteAgent.status = ASYNC_STATE.LOADING;
      state.deleteAgent.errorMessage = undefined;
    },
    [deleteAgent.fulfilled]: (state) => {
      state.deleteAgent.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteAgent.rejected]: (state) => {
      state.deleteAgent.status = ASYNC_STATE.FAILED;
      state.deleteAgent.defaultErrorMessage = getErrorMsg('default');
    },
    [disconnectAgentFromHouse.fulfilled]: (state) => {
      state.disconnectAgentFromHouse.status = ASYNC_STATE.SUCCEEDED;
    },
    [disconnectAgentFromHouse.pending]: (state) => {
      state.disconnectAgentFromHouse.status = ASYNC_STATE.LOADING;
    },
    [disconnectAgentFromHouse.rejected]: (state) => {
      state.disconnectAgentFromHouse.status = ASYNC_STATE.FAILED;
      state.disconnectAgentFromHouse.defaultErrorMessage = getErrorMsg('default');
    },
    [fetchSelectedAgent.pending]: (state) => {
      state.fetchSelected.status = ASYNC_STATE.LOADING;
    },
    [fetchSelectedAgent.fulfilled]: (state, action) => {
      const { agent } = action.payload;
      state.fetchSelected.status = ASYNC_STATE.SUCCEEDED;
      const { id: agentId } = agent;
      state.selectedAgentId = agentId;
      state.administrationUsers[agentId] = agent;
    },
    [fetchSelectedAgent.rejected]: (state) => {
      state.fetchSelected.status = ASYNC_STATE.FAILED;
    },
    [fetchSelectUsers.pending]: (state, action) => {
      const { selectKey } = action.meta.arg;
      if (!state[selectKey]) {
        state[selectKey] = {};
      }
      state[selectKey].status = ASYNC_STATE.LOADING;
    },
    [fetchSelectUsers.fulfilled]: paginateReducer(
      (state, action) => state[action.meta.arg.selectKey],
      (state, action) => {
        const { selectKey } = action.meta.arg;
        const { data } = action.payload;
        if (!state[selectKey]) {
          state[selectKey] = {};
        }
        state[selectKey].status = ASYNC_STATE.SUCCEEDED;
        state.administrationUsers = { ...byId(data), ...state.administrationUsers };
      },
    ),
    [fetchSelectUsers.rejected]: (state, action) => {
      const { selectKey } = action.meta.arg;
      const { error } = action.error;
      if (!state[selectKey]) {
        state[selectKey] = {};
      }
      state[selectKey].status = ASYNC_STATE.FAILED;
      state[selectKey].error = error;
      state[selectKey].errorMessage = 'Es ist ein Fehler aufgetreten';
    },
    [fetchAgentsTable.pending]: (state) => {
      state.fetchAgentsTable.status = ASYNC_STATE.LOADING;
    },
    [fetchAgentsTable.fulfilled]: paginateReducer(
      (state) => state.fetchAgentsTable,
      (state, action) => {
        const { data } = action.payload;
        state.fetchAgentsTable.status = ASYNC_STATE.SUCCEEDED;
        state.administrationUsers = { ...byId(data) };
      },
    ),
    [fetchAgentsTable.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchAgentsTable.status = ASYNC_STATE.FAILED;
      state.fetchAgentsTable.error = error;
    },
    [fetchAgentCompanies.pending]: (state) => {
      state.fetchAgentCompanies.status = ASYNC_STATE.LOADING;
    },
    [fetchAgentCompanies.fulfilled]: (state, action) => {
      const { agentCompanies } = action.payload;
      state.fetchAgentCompanies.status = ASYNC_STATE.SUCCEEDED;
      state.agentCompanies = agentCompanies;
    },
    [fetchAgentCompanies.rejected]: (state, action) => {
      state.fetchAgentCompanies.status = ASYNC_STATE.FAILED;
      state.fetchAgentCompanies.error = action.error;
    },
    [fetchAgentDirectChannels.pending]: (state) => {
      state.fetchAgentDirectChannels.status = ASYNC_STATE.LOADING;
    },
    [fetchAgentDirectChannels.fulfilled]: (state, action) => {
      const { agentDirectChannels } = action.payload;
      state.fetchAgentDirectChannels.status = ASYNC_STATE.SUCCEEDED;
      state.agentDirectChannels = agentDirectChannels;
    },
    [fetchAgentDirectChannels.rejected]: (state, action) => {
      state.fetchAgentDirectChannels.status = ASYNC_STATE.FAILED;
      state.fetchAgentDirectChannels.error = action.error;
    },
    [fetchUserDetails.pending]: (state, action) => {
      const { userId } = action.meta.arg;
      if (!state.fetchUserDetails[userId]) {
        state.fetchUserDetails[userId] = {};
      }
      state.fetchUserDetails[userId].status = ASYNC_STATE.LOADING;
    },
    [fetchUserDetails.fulfilled]: (state, action) => {
      const { user } = action.payload;
      if (!state.fetchUserDetails[user.id]) {
        state.fetchUserDetails[user.id] = {};
      }
      state.fetchUserDetails[user.id].status = ASYNC_STATE.SUCCEEDED;
      state.administrationUsers[user.id] = user;
    },
    [fetchUserDetails.rejected]: (state, action) => {
      const { userId } = action.meta.arg;
      if (!state.fetchUserDetails[userId]) {
        state.fetchUserDetails[userId] = {};
      }
      state.fetchUserDetails[userId].status = ASYNC_STATE.FAILED;
      state.fetchUserDetails[userId].error = action.error;
    },

    [fetchListOfUserDetails.pending]: (state) => {
      state.fetchListOfUserDetails.status = ASYNC_STATE.LOADING;
    },
    [fetchListOfUserDetails.fulfilled]: (state, action) => {
      const { users } = action.payload;
      state.fetchListOfUserDetails.status = ASYNC_STATE.SUCCEEDED;
      state.administrationUsers = mergeObjects(state.administrationUsers, byId(users));
    },
    [fetchListOfUserDetails.rejected]: (state, action) => {
      state.fetchListOfUserDetails.status = ASYNC_STATE.FAILED;
      state.fetchListOfUserDetails.error = action.error;
    },
    [fetchAgentGroupChannels.pending]: (state) => {
      state.fetchAgentGroupChannels.status = ASYNC_STATE.LOADING;
    },
    [fetchAgentGroupChannels.fulfilled]: (state, action) => {
      const { agentGroupChannels } = action.payload;
      state.fetchAgentGroupChannels.status = ASYNC_STATE.SUCCEEDED;
      state.agentGroupChannels = agentGroupChannels;
    },
    [fetchAgentGroupChannels.rejected]: (state, action) => {
      state.fetchAgentGroupChannels.status = ASYNC_STATE.FAILED;
      state.fetchAgentGroupChannels.error = action.error;
    },
    [fetchAgentAssignmentsCount.pending]: (state) => {
      state.fetchAgentAssignmentsCount.status = ASYNC_STATE.LOADING;
    },
    [fetchAgentAssignmentsCount.fulfilled]: (state, action) => {
      const { agentId, assignmentsCount } = action.payload;
      state.fetchAgentAssignmentsCount.status = ASYNC_STATE.SUCCEEDED;
      state.agentAssignmentsCount[agentId] = assignmentsCount;
    },
    [fetchAgentAssignmentsCount.rejected]: (state, action) => {
      state.fetchAgentAssignmentsCount.status = ASYNC_STATE.FAILED;
      state.fetchAgentAssignmentsCount.error = action.error;
    },
    [createAgentAgentDirectChannel.pending]: (state) => {
      state.createDirectChannel.status = ASYNC_STATE.LOADING;
    },
    [createAgentAgentDirectChannel.fulfilled]: (state) => {
      state.createDirectChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [createAgentAgentDirectChannel.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.createDirectChannel.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_agent_agent_channel.existing') {
        state.createDirectChannel.errorMessage = getErrorMsg('create_channel_for_agent.existing');
      } else {
        state.createDirectChannel.defaultErrorMessage = getErrorMsg('create_channel_for_agent.default');
      }
    },
    [createAgentDirectChannel.pending]: (state) => {
      state.createDirectChannel.status = ASYNC_STATE.LOADING;
    },
    [createAgentDirectChannel.fulfilled]: (state) => {
      state.createDirectChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [createAgentDirectChannel.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.createDirectChannel.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_agent_channel.existing') {
        state.createDirectChannel.errorMessage = getErrorMsg('create_channel_for_agent.existing');
      } else {
        state.createDirectChannel.defaultErrorMessage = getErrorMsg('create_channel_for_agent.default');
      }
    },
    [createAgentGroupChannel.pending]: (state) => {
      state.createGroupChannel.status = ASYNC_STATE.LOADING;
    },
    [createAgentGroupChannel.fulfilled]: (state) => {
      state.createGroupChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [createAgentGroupChannel.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.createGroupChannel.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_group_channel.existing') {
        state.createGroupChannel.errorMessage = getErrorMsg('create_channel_for_agent.existing');
      } else {
        state.createGroupChannel.defaultErrorMessage = getErrorMsg('create_channel_for_agent.default');
      }
    },
    [updateSelectedAgent.pending]: (state) => {
      state.updateSelectedAgent.status = ASYNC_STATE.LOADING;
    },
    [updateSelectedAgent.fulfilled]: (state) => {
      state.updateSelectedAgent.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateSelectedAgent.rejected]: (state, action) => {
      state.updateSelectedAgent.status = ASYNC_STATE.FAILED;
      state.updateSelectedAgent.error = action.error;
    },
  },
});

export const {
  agentSelected,
  agentSelectionReset,
  resetCreateAgentErrorMessage,
  resetCreateAgentDefaultErrorMessage,
  resetCreateAgentDirectChannelErrorMessage,
  resetCreateAgentDirectChannelDefaultErrorMessage,
  resetCreateAgentGroupChannelErrorMessage,
  resetCreateAgentGroupChannelDefaultErrorMessage,
  resetAdministrationUsers,
  resetAdministrationUsersByKey,
  resetDisconnectAgentFromHouseDefaultErrorMessage,
  resetDeleteAgentDefaultErrorMessage,
} = administrationUsersSlice.actions;

export default administrationUsersSlice.reducer;
