export const CHIP_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
};

export const CHIP_TOOLTIP_TEXT = {
  MAIN_CONTACT_NO_ROLE: 'Der Hauptkontakt wird nicht mit der Beteiligtenrolle synchronisiert.',
  AGENT_NO_ROLE: 'Der Kontakt wird nicht mit den Beteiligtenrollen synchronisiert.',
  TEAM_NO_ROLE: 'Das Team wird nicht mit den Beteiligtenrollen synchronisiert.',
  ROLE_NO_AGENT: 'Es gibt bei diesem Kunden keinen Mitarbeiter mit dieser Beteiligtenrolle.',
  ROLE_NO_TEAM: 'Es gibt bei diesem Kunden kein Team mit dieser Beteiligtenrolle.',
};

export const NAME_PLACEHOLDERS = {
  ROLE_NO_AGENT: 'Kein Mitarbeiter',
  ROLE_NO_TEAM: 'Kein Team',
};

export const ROLE_STATUS = {
  MEMBER_NO_ROLE: 'MEMBER_NO_ROLE',
  ROLE_NO_MEMBER: 'ROLE_NO_MEMBER',
  MEMBER_WITH_ROLE: 'MEMBER_WITH_ROLE',
};
