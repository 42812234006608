import { Outlet } from 'react-router-dom';
import useUserlane from 'gcs-common/hooks/useUserlane';

/*
 This component contains the parts of the application
 available for a "logged in" user.
 */
const LoggedInRoot = () => {
  useUserlane();

  return <Outlet />;
};

export default LoggedInRoot;
