import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAgentOptions, fetchTeamOptions,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureThunks';
import { useDebouncedCallback } from 'use-debounce';
import { getSelectedHouseDetails } from 'gcs-common/slices/administration/houses/housesSelectors';
import {
  fetchCustomerResponsibilityMembers,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersThunk';
import { VALUE_KEY } from 'gcs-common/constants/dropdownSelectConstants';
import {
  resetAgentOptions, resetTeamOptions,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSlices';
import {
  resetCustomerResponsibilityMembers,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSlice';

import Chip from 'gcs-common/components/Chip/Chip';
import DropdownSelect from 'gcs-common/components/Formik/DropdownSelect/DropdownSelect';
import styles from './styles.module.scss';

const MemberSelect = ({
  name,
  onChange,
  initialValues = [],
  disabled = false,
  label,
  options = [],
  tooltipText = null,
  isMulti = true,
  isCommunicationStructure = false,
  mainContactPerson = null,
}) => {

  const dispatch = useDispatch();

  const selectedHouse = useSelector(getSelectedHouseDetails);

  // all options for the assignment members select component
  // important that options and initialValues have the same shape

  const fetchMembers = useCallback((searchQuery = '') => {
    dispatch(fetchAgentOptions({
      pageIndex: 0,
      pageSize: 10,
      searchQuery,
      houseId: selectedHouse.id,
    }));
    dispatch(fetchCustomerResponsibilityMembers({
      pageIndex: 0,
      pageSize: 10,
      searchQuery,
      mergeData: true,
    }));
    dispatch(fetchTeamOptions({
      pageIndex: 0,
      pageSize: 10,
      searchQuery,
      houseId: selectedHouse.id,
    }));
  }, [dispatch, selectedHouse]);

  useEffect(() => {
    fetchMembers();

    return () => {
      // Reset the states to avoid showing the wrong data when the component is re-rendered
      dispatch(resetAgentOptions());
      dispatch(resetCustomerResponsibilityMembers());
      dispatch(resetTeamOptions());
    };
  }, [dispatch, fetchMembers]);

  const [handleInputChangedDebounced] = useDebouncedCallback(
    (inputValue) => {
      if (inputValue !== '') {
        fetchMembers(inputValue);
      }
    },
    300,
  );

  const formatOptionLabelForMembers = (option) => {

    let customerResponsibilityRoleName = null;
    let memberName = null;

    if (option.friendlyName) {
      memberName = option.friendlyName;
    }
    if (option.customerResponsibilityRoleName) {
      // eslint-disable-next-line prefer-destructuring
      customerResponsibilityRoleName = option.customerResponsibilityRoleName;
    }
    return (
      <Chip
        memberName={memberName}
        memberType={option.type}
        roleStatus={option.roleStatus}
        customerResponsibilityRoleName={customerResponsibilityRoleName}
        disabled={disabled}
        isCommunicationStructure={isCommunicationStructure}
        isMainContactPerson={option[VALUE_KEY] === mainContactPerson?.[VALUE_KEY]}
      />
    );
  };

  const formatMultiValue = ({ data, removeProps, innerProps }) => {
    const { friendlyName, customerResponsibilityRoleName, type, roleStatus } = data;

    const handleRemove = () => {
      removeProps.onClick();
    };

    return (
      <div {...innerProps}>
        <Chip
          memberName={friendlyName}
          memberType={type}
          roleStatus={roleStatus}
          customerResponsibilityRoleName={customerResponsibilityRoleName}
          onRemove={handleRemove}
          disabled={disabled}
          isCommunicationStructure={isCommunicationStructure}
        />
      </div>
    );
  };

  const customFilterOption = (option, inputValue) => {
    const searchQuery = inputValue.toLowerCase();
    const { friendlyName, customerResponsibilityRoleName } = option.data;
    return (
      friendlyName.toLowerCase().includes(searchQuery)
      // eslint-disable-next-line max-len
      || (customerResponsibilityRoleName && customerResponsibilityRoleName.toLowerCase().includes(searchQuery))
    );
  };

  return (
    <div className={styles.memberSelect}>
      <DropdownSelect
        name={name}
        label={label}
        formatOptionLabel={formatOptionLabelForMembers}
        formatMultiValue={formatMultiValue}
        // the text that is displayed if there are no options selected
        placeholder="Suchen..."
        // the key to select the label from the option shape, used for text search in the select
        labelKey="friendlyName"
        isMulti={isMulti}
        onChange={onChange}
        disabled={disabled}
        // all available options that can be (are currently are) selected
        options={options}
        // the options that currently are selected (must be same shape as options)
        initialValue={initialValues}
        onInputChange={handleInputChangedDebounced}
        tooltipText={tooltipText}
        customFilter={customFilterOption}
      />
    </div>
  );
};

MemberSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    agentId: PropTypes.string,
    teamId: PropTypes.string,
    roleId: PropTypes.string,
    customerResponsibilityRoleId: PropTypes.string,
    name: PropTypes.string,
    [VALUE_KEY]: PropTypes.string.isRequired,
  })),
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
  isMulti: PropTypes.bool,
  isCommunicationStructure: PropTypes.bool,
  mainContactPerson: (PropTypes.shape({
    id: PropTypes.string,
    agentId: PropTypes.string,
    teamId: PropTypes.string,
    roleId: PropTypes.string,
    customerResponsibilityRoleId: PropTypes.string,
    name: PropTypes.string,
    [VALUE_KEY]: PropTypes.string.isRequired,
  })),
};

export default MemberSelect;
