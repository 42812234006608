import { useCallback, useRef, useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { changeInputText } from 'gcs-common/slices/messageInput/messageInputThunks/changeInputText';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import useOnClickOutside from 'gcs-common/hooks/useOnClickOutside';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';

const SmileyMessageInput = () => {
  const emojiPickerRef = useRef(null);
  const [emojiShown, setEmojiShown] = useState(false);

  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();

  const onEmojiPicked = useCallback((n, e) => {
    const inputText = `:${e.name}:`;
    dispatch(changeInputText({
      channelId: selectedChannelId,
      inputText,
      append: true,
    }));
  }, [dispatch, selectedChannelId]);

  useOnClickOutside(emojiPickerRef, () => setEmojiShown(false));

  return (
    <div className={styles.mediaInputWrapper}>
      <button type="button" id="show-emoji-no" className={styles.smileyInputIcon} onClick={() => setEmojiShown(true)}>
        <IconComponent Icon={ICON.EMOJI} color={ICON_COLOR.LIGHT_GREY} />
      </button>
      {emojiShown
      && (
        <div className={styles.emojiTable} ref={emojiPickerRef}>
          <EmojiPicker assetPath="/assets/emojipicker" onEmojiClick={onEmojiPicked} />
        </div>
      )
      }
    </div>
  );
};

export default SmileyMessageInput;
