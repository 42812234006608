import PropTypes from 'prop-types';
import styles from './styles.module.scss';

export const IconToast = ({ message, icon }) => {
  return (
    <div className={styles.toast}>
      {icon && <img alt="toast-img" src={icon} className={styles.toastIcon} />}
      {message}
    </div>
  );
};

IconToast.propTypes = { message: PropTypes.string.isRequired, icon: PropTypes.string.isRequired };
