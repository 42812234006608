/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { HousesViews } from '../../../constants/views';
import { getErrorMsg } from '../../../helper/errorMessages';
import {
  fetchHouses,
  fetchHouse,
  fetchSelectedHouse,
  updateSelectedHouse,
  fetchHouseCustomMessageTypes,
  validateHouseCustomMessage,
  createHouse,
} from './housesThunks';
import ASYNC_STATE from '../../../constants/AsyncState';
import paginateReducer from '../../../helper/reduxPaginationHelper';
import { byId } from '../../../helper/byKey';


const asyncState = {
  fetchHouse: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchSelectedHouse: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchHouses: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  updateSelected: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  fetchHouseCustomMessageTypes: {
    status: ASYNC_STATE.IDLE,
  },
  validateCustomMessage: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  housesDetails: {},
  housesPlain: {},
  housesTable: {},
  brands: [],
  selectedHouseId: undefined,
  globallySelectedHouseId: undefined,
  customMessageTypes: [],
  exampleCustomMessage: {
    message: '',
    parameters: [],
  },
};

const housesSlice = createSlice({
  name: 'houses',
  initialState,
  reducers: {
    houseSelected: (state, action) => {
      const { houseId } = action.payload;
      state.selectedHouseId = houseId;
    },
    houseSelectionReset: (state) => {
      state.selectedHouseId = undefined;
    },
    globalHouseIdSelected: (state, action) => {
      const { houseId } = action.payload;
      state.globallySelectedHouseId = houseId;
    },
    resetValidateHouseCustomMessageErrorMessage: (state) => {
      state.validateCustomMessage.errorMessage = null;
    },
    resetExampleCustomMessage: (state) => {
      state.exampleCustomMessage = {
        message: '',
        parameters: [],
      };
    },
    resetCreateHouseErrorMessage: (state) => {
      state.create.errorMessage = null;
    },
  },
  extraReducers: {
    [fetchHouses.pending]: (state) => {
      state.fetchHouses.status = ASYNC_STATE.LOADING;
    },
    [fetchHouses.fulfilled]: paginateReducer(
      (state) => state.fetchHouses,
      (state, action) => {
        const { data, view } = action.payload;
        state.fetchHouses.status = ASYNC_STATE.SUCCEEDED;
        const newState = { ...byId(data) };
        // maintain two separate stores for different views
        if (view === HousesViews.TABLE) {
          state.housesTable = newState;
        } else {
          state.housesPlain = newState;
        }

      },
    ),
    [fetchHouses.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchHouses.status = ASYNC_STATE.FAILED;
      state.fetchHouses.error = error;
    },
    [fetchHouse.pending]: (state) => {
      state.fetchHouse.status = ASYNC_STATE.LOADING;
    },
    [fetchHouse.fulfilled]: (state, action) => {
      const { house } = action.payload;
      state.fetchHouse.status = ASYNC_STATE.SUCCEEDED;
      const { id } = house;
      state.housesDetails[id] = house;
    },
    [createHouse.rejected]: (state, action) => {
      const { errorCode } = action.payload;
      state.create.status = ASYNC_STATE.FAILED;
      state.create.errorMessage = getErrorMsg(errorCode) || getErrorMsg('create_house.default');
    },
    [createHouse.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
    },
    [createHouse.fulfilled]: (state, action) => {
      const { house } = action.payload;
      state.create.status = ASYNC_STATE.SUCCEEDED;
      const { id } = house;
      state.housesPlain[id] = house;
    },
    [fetchHouse.rejected]: (state, action) => {
      state.fetchHouse.status = ASYNC_STATE.FAILED;
      state.fetchHouse.error = action.error;
    },
    [fetchSelectedHouse.pending]: (state) => {
      state.fetchSelectedHouse.status = ASYNC_STATE.LOADING;
    },
    [fetchSelectedHouse.fulfilled]: (state, action) => {
      const { house } = action.payload;
      state.fetchSelectedHouse.status = ASYNC_STATE.SUCCEEDED;
      const { id } = house;
      state.housesDetails[id] = house;
      state.selectedHouseId = id;
    },
    [fetchSelectedHouse.rejected]: (state, action) => {
      state.fetchSelectedHouse.status = ASYNC_STATE.FAILED;
      state.fetchSelectedHouse.error = action.error;
    },
    [updateSelectedHouse.pending]: (state) => {
      state.updateSelected.status = ASYNC_STATE.LOADING;
    },
    [updateSelectedHouse.fulfilled]: (state, action) => {
      const { house } = action.payload;
      state.updateSelected.status = ASYNC_STATE.SUCCEEDED;
      const { id } = house;
      state.housesDetails[id] = house;
      state.selectedHouseId = id;
    },
    [updateSelectedHouse.rejected]: (state) => {
      state.updateSelected.status = ASYNC_STATE.FAILED;
    },
    [fetchHouseCustomMessageTypes.pending]: (state) => {
      state.updateSelected.status = ASYNC_STATE.LOADING;
    },
    [fetchHouseCustomMessageTypes.fulfilled]: (state, action) => {
      const { customMessageTypes } = action.payload;
      state.customMessageTypes = customMessageTypes;
      state.fetchHouseCustomMessageTypes.status = ASYNC_STATE.SUCCEEDED;
    },
    [fetchHouseCustomMessageTypes.rejected]: (state) => {
      state.fetchHouseCustomMessageTypes.status = ASYNC_STATE.FAILED;
    },
    [validateHouseCustomMessage.pending]: (state) => {
      state.validateCustomMessage.status = ASYNC_STATE.LOADING;
    },
    [validateHouseCustomMessage.fulfilled]: (state, action) => {
      const { exampleMessage, customizedParameters } = action.payload;
      state.exampleCustomMessage.message = exampleMessage;
      state.exampleCustomMessage.parameters = customizedParameters;
      state.validateCustomMessage.status = ASYNC_STATE.SUCCEEDED;
    },
    [validateHouseCustomMessage.rejected]: (state, action) => {
      const { payload: { errorCode } = {} } = action;
      state.validateCustomMessage.status = ASYNC_STATE.FAILED;
      state.validateCustomMessage.errorMessage = getErrorMsg(errorCode);
    },
  },
});

export const {
  houseSelected,
  houseSelectionReset,
  globalHouseIdSelected,
  resetExampleCustomMessage,
  resetValidateHouseCustomMessageErrorMessage,
  resetCreateHouseErrorMessage,
} = housesSlice.actions;

export default housesSlice.reducer;
