import { subDates } from '../../../../helper/dateHelper';
// eslint-disable-next-line import/no-cycle
import { useGetTasks } from './tasks-api';
import { FetchTasksResponseData } from './tasks-api-helpers';

export const transformDoneTasks = (data: FetchTasksResponseData | undefined): FetchTasksResponseData['tasks'] => {
  const tasks = data?.tasks;
  return tasks ? tasks.filter(task => task.done)
    .sort((left, right) => subDates(new Date(left.updatedAt!), new Date(right.updatedAt!))) : [];
};

export const transformNotDoneTasks = (data: FetchTasksResponseData | undefined): FetchTasksResponseData['tasks'] => {
  const tasks = data?.tasks;
  return tasks ? tasks.filter(task => !task.done)
    .sort((left, right) => left.order - right.order) : [];
};

export const useGetNotDoneTasks = (channelId: string) => {
  const { data: notDoneTasks } = useGetTasks<FetchTasksResponseData['tasks']>({ channel_id: channelId },
    transformNotDoneTasks);
  return notDoneTasks;
};

export const useGetDoneTasks = (channelId: string) => {
  const { data: doneTasks } = useGetTasks<FetchTasksResponseData['tasks']>({ channel_id: channelId }, transformDoneTasks);
  return doneTasks;
};
