import styles from './styles.module.scss';

const RenderHeader = () => {
  return (
    <div className={styles.title}>
      ONLINE PLUS Vorgänge
    </div>
  );
};
export default RenderHeader;
