import { useEffect, useState } from 'react';
import { ICON, ICON_COLOR, IconColorValue } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import IconComponent from '../IconComponent/IconComponent';

interface CheckBoxProps {
  onClick: VoidFunction;
  isChecked: boolean;
  color?: IconColorValue;
  disabled?: boolean;
}

const CheckBox = (props: CheckBoxProps) => {
  const { onClick = () => {},
    isChecked = false, color = ICON_COLOR.DARK_GREY, disabled = false } = props;

  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(isChecked);
  }, [isChecked]);

  return (
    <>
      <div
        onClick={onClick}
        className={`${styles.checkIconWrapper} ${disabled ? styles.disabled : ''}`}
        role="checkbox"
        tabIndex={0}
        aria-checked={toggled}
      >
        {toggled
          ? (
            <IconComponent
              Icon={ICON.CIRCLE_CHECKED}
              color={color}
            />
          )
          : (
            <IconComponent
              Icon={ICON.CIRCLE_UNCHECKED}
              color={ICON_COLOR.DARK_GREY}
            />
          )
    }

      </div>
    </>
  );
};

export default CheckBox;
