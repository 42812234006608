import { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { setRightBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import {
  fetchAgentAvailabilityDate,
  updateAvailabilityDate,
} from 'gcs-common/slices/currentAgent/currentAgentThunks';
import { AWAY_TYPES } from 'gcs-common/constants/awayTypes';
import { useOnClickOutside } from 'usehooks-ts';
import PopupMenu from '../PopupMenu/PopupMenu';
import './react-datepicker.scss';
import styles from './styles.module.scss';
import AgentAvailabilityMessageInput
  from '../AgentAvailabilityMessageInput/AgentAvailabilityMessageInput';
import PageHeader from './PageHeader/PageHeader';

// INFO: this is a legacy component, should be connected to redux at some point
const AbsenceManager = () => {

  const [date, setDate] = useState(null);

  const [endPickedDate, setEndPickedDate] = useState(null);
  const [pendingDates, setPendingDates] = useState([]);
  const [pickingState, setPickingState] = useState(null);
  const [awayDates, setAwayDates] = useState({});

  const dispatch = useDispatch();

  const [endHoverRange, setEndHoverRange] = useState(null);

  const innerRef = useRef(null);

  const resetSelection = useCallback(() => {
    setPendingDates([]);
    setDate(null);
    setPickingState(null);
    setEndPickedDate(null);
    setAwayDates(dates => {
      const newDates = { ...dates };
      // eslint-disable-next-line no-restricted-syntax
      for (const strDate of Object.keys(newDates)) {
        if (newDates[strDate] === AWAY_TYPES.UNDECIDED) delete newDates[strDate];
      }
      return newDates;
    });
  }, []);

  useOnClickOutside(
    innerRef,
    resetSelection,
  );

  useEffect(() => {
    (async () => {
      // TODO: THIS IS AN INTERMEDIARY SOLUTION
      const {
        payload: { agentAvailabilityDates: newAgentDates },
      } = await dispatch(fetchAgentAvailabilityDate());
      setAwayDates(newAgentDates);
    })();
  }, [dispatch]);

  // choose proper format for displaying
  const handleUpdateAvailabilityDate = useCallback((upDate, type) => {
    dispatch(updateAvailabilityDate({ upDate, type }));
  }, [dispatch]);

  const handleRangeSelect = (pickedState, range) => {
    const newDates = {};
    const pastDates = { ...awayDates };
    // eslint-disable-next-line no-restricted-syntax
    for (const strDate of range) {
      if (pickedState !== AWAY_TYPES.NONE) newDates[strDate] = pickedState;
      delete pastDates[strDate];
      const d = new Date(Number(strDate));
      handleUpdateAvailabilityDate(
        d,
        Object.keys(AWAY_TYPES).find(key => AWAY_TYPES[key] === pickedState),
      );
    }
    setAwayDates({ ...pastDates, ...newDates });
    // we already chose date, do not set pending again
    setPendingDates([]);
    setPickingState(null);
    setDate(null);
    setEndPickedDate(null);
  };

  const renderDayContents = (chosenDay, chosenDate) => {

    let bgColor = '#ffffff';

    const beforePicked = (endHoverRange && date && endHoverRange.getTime() <= chosenDate.getTime()
                          && date.getTime() >= chosenDate.getTime());
    const afterPicked = (endHoverRange && date && endHoverRange.getTime() >= chosenDate.getTime()
                         && date.getTime() <= chosenDate.getTime());

    const regionStart = (date && chosenDate.getTime() === date.getTime());
    const regionEnd = (endHoverRange && endHoverRange.getTime() === chosenDate.getTime());

    // highlighed previously fetched dates
    if (awayDates[chosenDate.getTime()]) bgColor = (awayDates[chosenDate.getTime()]).color;
    // highlight and overwrite currently selecting dates
    if (pickingState) {
      if (beforePicked || afterPicked) bgColor = pickingState.hoverColor;
      // to highlight the beginning and end of the regions
      if (regionStart || regionEnd) bgColor = pickingState.color;
    }

    let dateStyle = styles.normalStyle;

    if (date && regionEnd) {
      dateStyle = (endHoverRange.getTime() < date.getTime()
        ? styles.cornerLeftStyle : styles.cornerRightStyle);
    } else if (endHoverRange && regionStart) {
      dateStyle = (endHoverRange.getTime() < date.getTime()
        ? styles.cornerRightStyle : styles.cornerLeftStyle);
    } else if (awayDates[chosenDate.getTime()]) {
      // cornerup previously selected range
      const nextDate = new Date(chosenDate);
      nextDate.setDate(chosenDate.getDate() + 1);
      const next = awayDates[nextDate.getTime()];

      const prevDate = new Date(chosenDate);
      prevDate.setDate(chosenDate.getDate() - 1);
      const prev = awayDates[prevDate.getTime()];

      if (!next || next.color !== bgColor) dateStyle = styles.cornerRightStyle;
      if (!prev || prev.color !== bgColor) {
        dateStyle = (dateStyle === styles.cornerRightStyle
          ? styles.cornerCenterStyle : styles.cornerLeftStyle);
      }
    }

    if (regionStart) dateStyle += ` ${styles.selectedStyle}`;

    const chosenRegionEnd = date === null && endPickedDate
      && endPickedDate.getTime() === chosenDate.getTime();

    return (
      <div>
        <PopupMenu
          isVisible={!!(regionStart || chosenRegionEnd)}
          onHide={() => {}}
          menuClass={styles.statusTypeSelector}
        >
          <button
            type="button"
            onClick={() => {
              setPickingState(AWAY_TYPES.AWAY);
              // eslint-disable-next-line no-use-before-define
              handleRangeSelect(AWAY_TYPES.AWAY, pendingDates);
            }}
          >
            <span className={styles.itemAway} />
            {AWAY_TYPES.AWAY.text}
          </button>
          <button
            type="button"
            onClick={() => {
              setPickingState(AWAY_TYPES.LATE_SHIFT);
              // eslint-disable-next-line no-use-before-define
              handleRangeSelect(AWAY_TYPES.LATE_SHIFT, pendingDates);
            }}
          >
            <span className={styles.itemLateShift} />
            {AWAY_TYPES.LATE_SHIFT.text}
          </button>
          {(chosenDate.getTime() in awayDates)
           && (
             <button
               type="button"
               onClick={() => {
                 setPickingState(AWAY_TYPES.NONE);
                 // eslint-disable-next-line no-use-before-define
                 handleRangeSelect(AWAY_TYPES.NONE, pendingDates);
               }}
             >
               <span className={styles.itemNone} />
               {AWAY_TYPES.NONE.text}
             </button>
           )}
        </PopupMenu>
        <div
          className={dateStyle}
          style={{ backgroundColor: bgColor }}
          onMouseEnter={() => setEndHoverRange(chosenDate)}
        >
          {chosenDay}
        </div>

      </div>
    );
  };

  const handleSelect = (pickedDate) => {
    // start or finishing range selection
    if (pendingDates.length <= 1) {
      // fist time picking dates
      if (date === null) {
        setPickingState(AWAY_TYPES.UNDECIDED);
        setDate(pickedDate);
        setPendingDates([pickedDate.getTime().toString()]);
      // to avoid double clicking on single date for selection
      } else if (date.getTime() !== pickedDate.getTime()) {
        const newDates = {};

        const startTime = date.getTime() < pickedDate.getTime()
          ? new Date(date) : new Date(pickedDate);
        const endTime = date.getTime() < pickedDate.getTime()
          ? new Date(pickedDate) : new Date(date);

        for (const d = startTime; d <= endTime; d.setDate(d.getDate() + 1)) {
          const strDate = d.getTime().toString();
          newDates[strDate] = AWAY_TYPES.UNDECIDED;
        }
        setAwayDates({ ...awayDates, ...newDates });
        setPendingDates(Object.keys(newDates));
        setPickingState(null);
        setEndPickedDate(pickedDate);
        setDate(null);
      }
    }
  };

  const onClose = () => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.TOOLS }));
  };

  return (
    <div className={styles.absenceManager}>
      <PageHeader onClose={onClose} />
      <div className={styles.calendarContainer} ref={innerRef}>
        <DatePicker
          selected={date}
          onSelect={handleSelect}
          locale="de"
          inline
          renderDayContents={renderDayContents}
          dayClassName={() => styles.dayClass}
        />
      </div>
      <AgentAvailabilityMessageInput statusType="AWAY" />
      <AgentAvailabilityMessageInput statusType="LATE_SHIFT" />
    </div>
  );
};

export default AbsenceManager;
