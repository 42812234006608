import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTenant } from 'gcs-common/slices/administration/tenants/tenantsThunks';
import {
  getDeleteTenantErrorMessage,
  getDeleteTenantLoading,
} from 'gcs-common/slices/administration/tenants/tenantsSelectors';
import { resetDeleteTenantErrorMessage } from 'gcs-common/slices/administration/tenants/tenantsSlice';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import TenantPropType from '../../../../models/TenantPropType';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const TenantDelete = ({ tenant, onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getDeleteTenantErrorMessage);
  const isDeleting = useSelector(getDeleteTenantLoading);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetDeleteTenantErrorMessage()),
      ));
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteTenantErrorMessage());
  }, [dispatch]);

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteTenant({ tenantId: tenant.id }));
      if (!result.error) {
        onSuccess();
      }
    },
    [dispatch, tenant.id, onSuccess],
  );

  return (
    <div className={styles.column}>
      Möchten Sie den Tenant
      {' "'}
      {tenant.friendlyName}
      {'" '}
      wirklich löschen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

TenantDelete.propTypes = {
  tenant: TenantPropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TenantDelete;
