import { createSelector } from '@reduxjs/toolkit';
import USER_TYPE from '../../helper/userTypeHelper';
import { getUsers } from '../users/usersSelectors';
import { calculateUserStatus } from '../../helper/userStatusHelper';
import { getIsChatConnected } from '../chatConnection/chatConnectionSelectors';
import { calculateUserName } from '../../helper/userHelper';


export const getUser = (userId) => createSelector(
  getUsers,
  (users) => users[userId],
);

export const getUserLoaded = (userId) => createSelector(
  getUser(userId),
  (user) => !!user,
);

export const getUserName = (userId) => createSelector(
  getUser(userId),
  (user) => user && calculateUserName(user),
);

export const getUserType = (userId) => createSelector(
  getUser(userId),
  (user) => user?.userType,
);

export const getUserStatus = (userId) => createSelector(
  getUser(userId),
  getIsChatConnected,
  (user, isConnected) => {
    return calculateUserStatus(isConnected, user);
  },
);

export const getUserImage = (userId) => createSelector(
  getUser(userId),
  (user) => user?.imageUrl,
);

export const getUserCompany = (userId) => createSelector(
  getUser(userId),
  (user) => user?.userCompany,
);

export const getUserEmail = (userId) => createSelector(
  getUser(userId),
  (user) => user?.email,
);

export const getUserIsAgent = (userId) => createSelector(
  getUser(userId),
  (user) => {
    return user?.userType === USER_TYPE.AGENT;
  },
);

export const getUserPhoneNumber = (userId) => createSelector(
  getUser(userId),
  (user) => {
    return user?.phoneNumber;
  },
);

/**
 * This flag indicates whether the user has ever created an OP account.
 * It describes the status of the user and not the status of an OP account.
 * Having this flag allows to distinguish between two states:
 * i) the user does not have an OP account right now and ii) the user has never had an OP account
 */
export const getIsUserOPVerified = (userId) => createSelector(
  getUser(userId),
  (user) => user?.isGcopVerified,
);
