import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LOGIN_FLOW } from 'gcs-common/constants/loginFlows';
import { Link } from 'react-router-dom';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import {
  getAndroidStoreUrl,
  getAppTitle,
  getiOSStoreUrl, getLogo, getSimpleLogo,
  getThemeIdentifier,
} from 'gcs-common/slices/theme/themeSelectors';
import { LOGIN_ROUTES } from 'gcs-common/constants/routes';
import { THEME } from 'gcs-common/constants/themes';
import { useAuth0WebBrowserLoginFlow } from 'gcs-common/hooks/auth/useAuth0WebBrowserLoginFlow';
import styles from './styles.module.scss';

import ButtonBeem from '../../ButtonBeem/ButtonBeem';
import gcGruppeLogo from '../../../img/gc-gruppe-logo.svg';
import googlePlayIcon from '../../../img/google-play.svg';
import appleStoreIcon from '../../../img/apple-store.svg';
import digiBoxIcon from '../../../img/digital_box.svg';
import dtgLogingImage from '../../../img/login-bkg-dtg.svg';
import gutLogingImage from '../../../img/login-wave.svg';
import IconComponent from '../../IconComponent/IconComponent';

const LoginWithSideMenu = () => {
  const { openAuth0UniversalLoginWeb } = useAuth0WebBrowserLoginFlow();

  const appTitle = useSelector(getAppTitle);
  const themeIdentifier = useSelector(getThemeIdentifier);
  const androidUrl = useSelector(getAndroidStoreUrl);
  const iosUrl = useSelector(getiOSStoreUrl);

  const isGUT = themeIdentifier === THEME.GUT;
  const isGC = themeIdentifier === THEME.GC;
  const isEFG = themeIdentifier === THEME.EFG;
  const isHTI = themeIdentifier === THEME.HTI;
  const isITG = themeIdentifier === THEME.ITG;
  const isDTG = themeIdentifier === THEME.DTG;
  const isTFG = themeIdentifier === THEME.TFG;
  const isHFH = themeIdentifier === THEME.HFH;

  const logo = useSelector(getLogo);
  const simpleLogo = useSelector(getSimpleLogo);

  const open = useCallback((url) => {
    window.open(url, '_self');
  }, []);

  function handleRegistration() {
    openAuth0UniversalLoginWeb(LOGIN_FLOW.REGISTRATION);
  }

  function handleLogin() {
    openAuth0UniversalLoginWeb(LOGIN_FLOW.CRAFTSMAN);
  }

  const loginImage = () => {
    if (isGUT) {
      return {
        alt: 'G.U.T Login Image',
        src: gutLogingImage,
      };
    }
    if (isDTG) {
      return {
        alt: 'DTG Login Image',
        src: dtgLogingImage,
      };
    }
    return false;
  };
  return (
    <div className={`${styles.container} ${styles.fonts}`}>
      <div className={`${styles.columnLeft} ${isTFG && styles.columnLeftTFG} ${isHFH && styles.columnLeftHFH}`}>
        <div>
          {!isGC
            && (
              <img
                className={`${styles.simpleLogo} ${isGUT && styles.simpleLogoGut} ${isDTG && styles.simpleLogoDTG} ${isTFG && styles.simpleLogoTFG} ${isHFH && styles.simpleLogoHFH}`}
                width="50px"
                alt=""
                src={simpleLogo}
              />
            )}
          {(isGC || isEFG || isHTI || isDTG || isITG) && <img width="50px" alt="" src={gcGruppeLogo} />}
          <h1 className={styles.headline}>Nutzen Sie jetzt die Vorteile von BEEM!</h1>

          <span className={styles.subheadline}>Nachrichten, Dokumente, Bilder teilen</span>

          <div className={styles.description}>
            <div className={styles.icon}>
              <IconComponent
                Icon={ICON.IMAGE}
                color={ICON_COLOR.LOGIN_ICONS}
                size={ICON_SIZE.X_LARGE}
              />
            </div>
            <p className={styles.text}>
              Schneller Austausch von Informationen,
              transparente Dokumentation und Kommunikation.
            </p>
          </div>

          <span className={styles.subheadline}>ONLINE PLUS Vorgänge</span>
          <div className={styles.description}>
            <div className={styles.icon}>
              <IconComponent
                Icon={ICON.CUBE}
                color={ICON_COLOR.LOGIN_ICONS}
                size={ICON_SIZE.X_LARGE}
              />
            </div>
            <p className={styles.text}>
              Durch die ONLINE PLUS Integration einfach
              Vorgänge in BEEM einfügen und Bezug nehmen.
            </p>
          </div>
          <span className={styles.subheadline}>Erreichbarkeit</span>
          <div className={styles.description}>
            <div className={styles.icon}>
              <IconComponent
                Icon={ICON.CHAT}
                color={ICON_COLOR.LOGIN_ICONS}
                size={ICON_SIZE.X_LARGE}
              />
            </div>
            <p
              className={styles.text}
            >
              Einfach und direkte Erreichbarkeit Ihrer wichtigen Ansprechpartner in BEEM.
            </p>
          </div>
        </div>
        {!(isEFG || isITG || isDTG || isTFG || isHFH) && <img className={styles.digiBoxIcon} alt="" src={digiBoxIcon} />}
      </div>
      <div className={styles.columnRight}>
        <div>
          <img alt="gc-chat-logo" className={styles.chatLogo} src={logo} />
        </div>
        <div className={styles.columnContainer}>
          {(isDTG || isGUT) && (
            <div className={styles.loginImg}>
              <img src={loginImage().src} alt={loginImage().alt} />
            </div>
          )}
          <div className={`${styles.buttonSection} ${isGUT && styles.gutButtonSection} ${isDTG && styles.dtgButtonSection}`}>
            <div className={styles.welcomeHeadline}>
              {' '}
              Willkommen
              <br />
              bei
              {' '}
              {appTitle}
              !
            </div>
            <ButtonBeem
              onClick={handleLogin}
            >
              Login
            </ButtonBeem>
            <ButtonBeem
              secondary
              onClick={handleRegistration}
            >
              Registrieren
            </ButtonBeem>
            <p className={`${styles.download} ${styles.text}`}>Hier herunterladen</p>
            <div style={{ display: 'flex' }}>
              <button
                onClick={() => open(iosUrl)}
                className={styles.downloadButton}
                type="button"
              >
                <img alt="" className={styles.downloadIcon} src={appleStoreIcon} />
              </button>
              <button
                onClick={() => open(androidUrl)}
                className={styles.downloadButton}
                type="button"
              >
                <img alt="" className={styles.downloadIcon} src={googlePlayIcon} />
              </button>
            </div>
            <Link
              to={LOGIN_ROUTES.LOGIN_TERMS}
              className={styles.termCondition}
            >
              Nutzungsbedingungen & Datenschutzrichtlinien
            </Link>
            <Link
              to={LOGIN_ROUTES.LOGIN_IMPRINT}
              className={styles.termCondition}
            >
              Impressum
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWithSideMenu;

LoginWithSideMenu.propTypes = {};
