import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getMessageCanDelete,
  getMessageCanDownload,
  getMessageCanForward, getMessageCanReply,
  getMessageIsFavorite,
  getVideoDownloadReady,
  getMessageType, getMessageHasSyncPending, getReplyMessageHasMedia,
  getMessageIsForwarded,
  getMessageBody,
  getMessageAttributesText,
  getMessageHasMedia,
  getMessageMediaIsImage,
  getMessageMediaRelativePath,
  getMessageMediaFilename,
} from 'gcs-common/slices/messages/messageSelector';
import { getMessageFavoriteId } from 'gcs-common/slices/favorites/favoritesSelectors';
import {
  channelInputReplyMessageStaged,
  addSelectedMessageIds,
} from 'gcs-common/slices/messageInput/messageInputSlice';
import { addFavorite } from 'gcs-common/slices/favorites/favoritesThunks/addFavorite';
import { useCreateTaskMutation } from 'gcs-common/clients/api/entities/tasks/tasks-api';
import { removeFavorite } from 'gcs-common/slices/favorites/favoritesThunks/removeFavorite';
import deleteMessage from 'gcs-common/slices/messages/messagesThunks/deleteMessage';
import downloadMedia from 'gcs-common/slices/messages/messagesThunks/downloadMedia';
import { getMediaDownloadIsLoading } from 'gcs-common/slices/messages/messagesSelectors';
import FORWARD_TARGET from 'gcs-common/constants/ForwardTarget';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { MESSAGE_TYPE } from 'gcs-common/constants/MessageTypes';
import { ICON, ICON_COLOR, ICON_SIZE, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { getETSEnabledForChannel } from 'gcs-common/slices/uiState/uiStateSelectors';
import PopupMenu from '../../PopupMenu/PopupMenu';
import Button from '../../Button/Button';
import styles from './styles.module.scss';
import { Spinner } from '../../Spinner/Spinner';
import useWindowDimensions from '../../../customHooks/useWindowDimensions';
import IconComponent from '../../IconComponent/IconComponent';

const MessageOptionsMenu = ({ messageId, parent, showIcon }) => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const isFavorite = useSelector(getMessageIsFavorite(messageId));
  const favoriteId = useSelector(getMessageFavoriteId(messageId));
  const canForward = useSelector(getMessageCanForward(selectedChannelId, messageId));
  const canDelete = useSelector(getMessageCanDelete(selectedChannelId, messageId));
  const canReply = useSelector(getMessageCanReply(selectedChannelId, messageId));
  const canDownload = useSelector(getMessageCanDownload(selectedChannelId, messageId));
  const mediaDownloadIsLoading = useSelector(getMediaDownloadIsLoading);
  const isAgent = useSelector(getIsAgent);
  const isVideoDownloadReady = useSelector(getVideoDownloadReady(messageId));
  const messageType = useSelector(getMessageType(messageId));
  const isPending = useSelector(getMessageHasSyncPending(messageId));
  const replyHasMedia = useSelector(getReplyMessageHasMedia(messageId));
  const isForwarded = useSelector(getMessageIsForwarded(messageId));
  const etsEnabledForChannel = useSelector(getETSEnabledForChannel(selectedChannelId));
  const messageBody = useSelector(getMessageBody(messageId));
  const messageAttributesText = useSelector(getMessageAttributesText(messageId));
  const hasMedia = useSelector(getMessageHasMedia(messageId));
  const isImage = useSelector(getMessageMediaIsImage(messageId));
  const messageMediaRelativePath = useSelector(getMessageMediaRelativePath(messageId));
  const fileName = useSelector(getMessageMediaFilename(messageId));
  const createTaskMutation = useCreateTaskMutation();
  const dispatch = useDispatch();

  const handleTodoAdd = () => {
    let description = messageBody;
    if (!description) {
      description = messageAttributesText;
    }
    let messageMediaRelativePaths;

    if (hasMedia) {
      if (isImage) {
        messageMediaRelativePaths = [messageMediaRelativePath];
      }
      if (!description) {
        if (fileName) {
          description = fileName;
        } else {
          description = isImage ? 'Bild' : 'Datei';
        }
      }
      if (!isImage) {
        description = `📎 ${description}`;
      }
    } else if (!description) {
      description = 'Nachricht';
    }

    createTaskMutation.mutate({
      body: {
        id: uuidv4(),
        description,
        messageId,
        channelId: selectedChannelId,
        messageAttributesText,
        hasMedia,
        isImage,
        messageMediaRelativePaths,
        fileName,
      },
    });
  };

  const handleFavoriteAdd = useCallback(async () => {
    dispatch(addFavorite({ channelId: selectedChannelId, messageId }));
  }, [dispatch, messageId, selectedChannelId]);

  const handleFavoriteRemove = useCallback(async () => {
    dispatch(removeFavorite({ channelId: selectedChannelId, favoriteId }));
  }, [dispatch, favoriteId, selectedChannelId]);

  const handleDeleteMessage = useCallback(async () => {
    dispatch(deleteMessage({ messageId }));
  }, [dispatch, messageId]);

  const handleForwardToChat = useCallback(() => {
    dispatch(addSelectedMessageIds({
      selectedMessageId: messageId,
      channelId: selectedChannelId,
      target: FORWARD_TARGET.CHAT,
    }));
  }, [dispatch, messageId, selectedChannelId]);

  const handleReplyToMessage = useCallback(() => {
    dispatch(channelInputReplyMessageStaged(
      { channelId: selectedChannelId, messageId },
    ));
  }, [dispatch, messageId, selectedChannelId]);

  const handleForwardToEmail = useCallback(async () => {
    dispatch(addSelectedMessageIds({
      selectedMessageId: messageId,
      channelId: selectedChannelId,
      target: FORWARD_TARGET.EMAIL,
    }));
  }, [dispatch, messageId, selectedChannelId]);

  const enableEtsTicketCreation = useCallback(async () => {
    dispatch(addSelectedMessageIds({
      selectedMessageId: messageId,
      channelId: selectedChannelId,
      target: FORWARD_TARGET.TICKET,
    }));
  }, [dispatch, messageId, selectedChannelId]);

  const { height: viewportHeight } = useWindowDimensions();

  const [showMenu, setShowMenu] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const buttonRef = useRef(null);
  const isVideo = messageType === MESSAGE_TYPE.VIDEO;
  const isMedia = messageType === MESSAGE_TYPE.MEDIA || messageType === MESSAGE_TYPE.VIDEO;

  const handleToggleMenu = useCallback(() => {
    if (!showMenu && buttonRef && buttonRef.current && parent && parent.current) {
      setIsRight(
        parent.current.getBoundingClientRect().right
        - buttonRef.current.getBoundingClientRect().right < 80,
      );
      setIsTop(
        viewportHeight
        - buttonRef.current.getBoundingClientRect().top < viewportHeight / 2,
      );
    }
    setShowMenu(!showMenu);
  }, [buttonRef, viewportHeight, parent, showMenu]);

  const onDownloadMedia = async (e) => {
    e.stopPropagation();
    await dispatch(downloadMedia({ messageId }));
    handleToggleMenu();
  };

  return (
    <div
      ref={buttonRef}
      tabIndex={0}
      role="button"
      className={(isMedia && !isForwarded) ? styles.msgMediaOptionsButton : styles.msgOptionsButton}
      onKeyUp={() => handleToggleMenu()}
      onClick={() => handleToggleMenu()}
      title="Nachrichtenmenü"
    >
      {showMenu
        && (
          <PopupMenu
            onHide={setShowMenu}
            isVisible={showMenu}
            menuClass={isRight ? styles.menuRight : styles.menuLeft}
            upward={isTop}
          >
            {canReply && (
              <Button onClick={handleReplyToMessage} className={styles.textAlign}>
                <span className={styles.optionsIconWrapper}>
                  <IconComponent Icon={ICON.FORWARD} transform={ICON_TRANSFORM.FLIP_HORIZONTAL} alt="forward icon" />
                </span>
                Antworten
              </Button>
            )
            }
            {!replyHasMedia && canDownload && (
              <Button
                onClick={onDownloadMedia}
                className={styles.textAlign}
                disabled={isVideo && !isVideoDownloadReady}
              >
                {mediaDownloadIsLoading || (isVideo && !isVideoDownloadReady)
                  ? (
                    <Spinner />) : (
                    // eslint-disable-next-line react/jsx-indent
                    <span className={styles.optionsIconWrapper}>
                      <IconComponent Icon={ICON.DOWNLOAD} alt="Download icon" />
                    </span>
                  )}
                Herunterladen
              </Button>
            )
            }
            <Button onClick={handleTodoAdd} className={styles.textAlign} disabled={isPending}>
              <span className={styles.optionsIconWrapper}>
                <IconComponent Icon={ICON.TODO_LIST} alt="Todo icon" />
              </span>
              Als Todo markieren
            </Button>
            {isFavorite ? (
              <Button onClick={handleFavoriteRemove} className={styles.textAlign}>
                <span className={styles.optionsIconWrapper}>
                  <IconComponent Icon={ICON.STAR_FILLED} alt="Favoriten icon" />
                </span>
                Von Favoriten entfernen
              </Button>
            ) : (
              <Button onClick={handleFavoriteAdd} className={styles.textAlign} disabled={isPending}>
                <span className={styles.optionsIconWrapper}>
                  <IconComponent Icon={ICON.STAR} alt="Favoriten icon" />
                </span>
                Zu Favoriten hinzufügen
              </Button>
            )
            }
            {canForward && (
              <Button onClick={handleForwardToChat} className={styles.textAlign}>
                <span className={styles.optionsIconWrapper}>
                  <IconComponent Icon={ICON.FORWARD} alt="forward icon" />
                </span>
                Weiterleiten
              </Button>
            )}
            {canDelete && (
              // eslint-disable-next-line max-len
              <Button onClick={handleDeleteMessage} className={styles.textAlign} disabled={isPending}>
                <span className={styles.optionsIconWrapper}>
                  <IconComponent Icon={ICON.DELETE} alt="Delete icon" />
                </span>
                Nachricht löschen
              </Button>
            )
            }
            {isAgent && (
              <Button
                onClick={handleForwardToEmail}
                className={styles.textAlign}
                disabled={isPending}
              >
                <span className={styles.optionsIconWrapper}>
                  <IconComponent Icon={ICON.EMAIL} alt="Email icon" />
                </span>
                Als Email verschicken
              </Button>
            )}
            {etsEnabledForChannel && (
              <Button
                onClick={enableEtsTicketCreation}
                className={styles.textAlign}
                disabled={isPending}
              >
                <span className={styles.optionsIconWrapper}>
                  <IconComponent Icon={ICON.ETS} alt="ticket icon" size={ICON_SIZE.SMAL} />
                </span>
                ETS-Ticket erstellen
              </Button>
            )}
          </PopupMenu>
        )
      }
      {showIcon && (
        <IconComponent
          Icon={ICON.ARROW}
          transform={ICON_TRANSFORM.ROTATE_90}
          color={ICON_COLOR.DARKEST_GREY}
        />
      )}
    </div>
  );
};

MessageOptionsMenu.propTypes = {
  messageId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  parent: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  showIcon: PropTypes.bool.isRequired,
};

export default MessageOptionsMenu;
