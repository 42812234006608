/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import documentImage from 'gcs-common/img/document.svg';
import { mimeTypeIsImage } from 'gcs-common/helper/mediaHelper';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { getTicketNotification } from 'gcs-common/slices/tickets/ticketsSelectors';
import { openMediaFullscreen, closeMediaFullscreen } from 'gcs-common/slices/uiState/uiStateSlice';
import { Response } from 'gcs-common/types/typeHelper';
import { getMediaFullscreenUrl } from 'gcs-common/slices/uiState/uiStateSelectors';
import moment from 'moment';
import { createFileFromUrl } from 'gcs-common/helper/fileHelper';
import styles from './styles.module.scss';
import IconComponent from '../../../IconComponent/IconComponent';
import TicketAttachmentNotification from './TicketAttachmentNotification/TicketAttachmentNotification';
import MediaFullscreen from '../../../MediaFullscreen/MediaFullscreen';
import LocalFilePreview from '../../../LocalFilePreview/LocalFilePreview';

interface TicketAttachmentListProps {
  ticketId: string;
  attachments: Response<'/chat/ets/tickets/{ticket_id}/attachments', 'get'>['attachments']
}

interface FileAttachements {
  [key: string]: File | null;
}

const TicketAttachmentsList = (props: TicketAttachmentListProps) => {
  const { ticketId, attachments } = props;
  const [fileAttachments, setFileAttachments] = useState<FileAttachements>({} as FileAttachements);
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ticketNotification: { lastViewedAt: Date } = useSelector(getTicketNotification(ticketId));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const fullScreenUrl: string = useSelector(getMediaFullscreenUrl);

  const handleOpenFullScreenMedia = (attachment: Response<'/chat/ets/tickets/{ticket_id}/attachments', 'get'>['attachments'][number]) => () => {
    dispatch(openMediaFullscreen({ mediaFullscreenUrl: attachment.url }));
  };

  // runs only when component unmount.
  useEffect(() => {
    return () => {
      dispatch(closeMediaFullscreen());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchFiles = async () => {
      const pdfAttachments = attachments.filter(
        (attachment) => attachment.mimeType === 'application/pdf',
      );

      const fetchPromises = pdfAttachments.map(
        (attachment) => createFileFromUrl(attachment.url, attachment.name, attachment.mimeType)
          .then((file) => ({
            id: attachment.id,
            file,
          })),
      );

      const fetchedPdfAttachments = await Promise.all(fetchPromises);

      // Create the key-value pair map with attachment id as key
      const fileAttachmentsMap = attachments.reduce((acc, attachment) => {
        const fetchedPdf = fetchedPdfAttachments.find(
          (pdfAttachment) => pdfAttachment.id === attachment.id,
        );
        // Assign the file if it exists, otherwise set the value to null or undefined
        acc[attachment.id] = fetchedPdf ? fetchedPdf.file : null;
        return acc;
      }, {} as { [key: string]: File | null });

      setFileAttachments(fileAttachmentsMap);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments.length]);

  return (
    <div className={styles.imageContainer}>
      {attachments.map(attachment => (
        <div className={styles.imageWrapper} key={attachment.id}>
          {ticketNotification
            && moment(attachment.createdAt).isAfter(ticketNotification.lastViewedAt)
            && !attachment.fromBeem
            && <TicketAttachmentNotification />}

          { attachment.mimeType && mimeTypeIsImage(attachment.mimeType) ? (
            <button
              className={styles.imgButton}
              type="button"
              onClick={handleOpenFullScreenMedia(attachment)}
            >
              <img
                src={attachment.thumbnailUrl}
                alt={attachment.name}
              />
            </button>
          ) : (
          // {/* @ts-expect-error type from backend */}
            <LocalFilePreview
              file={fileAttachments[attachment.id]}
              filePlaceHolder={documentImage}
            />
          )}

          <div className={styles.buttonActions}>
            <div className={styles.downloadLink}>
              <a href={attachment.url} download={attachment.name} target="_blank" rel="noreferrer">
                <IconComponent Icon={ICON.DOWNLOAD} color={ICON_COLOR.DARKEST_GREY} />
              </a>
            </div>
          </div>
        </div>
      ))}
      {fullScreenUrl && <MediaFullscreen mediaFullscreenUrl={fullScreenUrl} />}
    </div>
  );
};

export default TicketAttachmentsList;
