export const CommunicationAssignmentType = {
  DIRECT: 'DIRECT',
  SALES_SPACE: 'SALES_SPACE',
};

export const CommunicationAssignmentDirection = {
  FROM_CRAFTSMAN: 'FROM_CRAFTSMAN',
  FROM_AGENT: 'FROM_AGENT',
  BIDIRECTIONAL: 'BIDIRICTIONAL',
};

export const CommunicationAssignmentRoleType = {
  MAIN_CONTACT: 'MAIN_CONTACT',
  MEMBER: 'MEMBER',
};
