import ASYNC_STATE from 'gcs-common/constants/AsyncState';

export const getOnOnlineLoading = state => (
  state.initialization.onOnline.status === ASYNC_STATE.LOADING
);

export const getOnOnlineIdle = state => (
  state.initialization.onOnline.status === ASYNC_STATE.IDLE
);

export const getOnOnlineSucceeded = state => (
  state.initialization.onChatConnected.status === ASYNC_STATE.SUCCEEDED
);

export const getOnChatConnectedLoading = state => (
  state.initialization.onChatConnected.status === ASYNC_STATE.LOADING
);

export const getOnChatConnectedIdle = state => (
  state.initialization.onChatConnected.status === ASYNC_STATE.IDLE
);

export const getOnChatConnectedSucceeded = state => (
  state.initialization.onChatConnected.status === ASYNC_STATE.SUCCEEDED
);

export const getInitAppFetchDataLoading = state => (
  state.initialization.initAppFetchData.status === ASYNC_STATE.LOADING
);

export const getInitAppFetchDataSucceeded = state => (
  state.initialization.initAppFetchData.status === ASYNC_STATE.SUCCEEDED
);

export const getInitAppFetchDataFailed = state => (
  state.initialization.initAppFetchData.status === ASYNC_STATE.FAILED
);
