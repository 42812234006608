import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class CraftsmanNotificationMessageModel extends AbstractMessageModel {

  constructor({ craftsmanText, ...args } = {}) {
    super({
      messageType: MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION,
      ...args,
    });

    this.craftsmanText = craftsmanText;
  }
}
