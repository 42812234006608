import Linkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

// eslint-disable-next-line react/prop-types
export default ({ children, ...props }) => (
  <Linkify componentDecorator={componentDecorator} {...props}>
    {children}
  </Linkify>
);
