const FEATURE_FLAGS = {
  OP_DISABLED: 'op_disabled',
  SUPPORT_PAGE_DISABLED: 'support_page_disabled',
  MOBILE_LOGIN_LINK_DISABLED: 'mobile_login_link_disabled',
  OP_MAINTENANCE: 'op_maintenance',
  ETS_ENABLED: 'ets_enabled',
  ETS_DASHBOARD_ENABLED: 'ets_dashboard_enabled',
  ETS_MOBILE_ENABLED: 'ets_mobile_enabled',
  QUICKORDERING_ENABLED: 'quickordering_enabled',
  USER_MANAGEMENT_DISABLED: 'user_management_disabled',
};

export default FEATURE_FLAGS;
