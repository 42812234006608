import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CHANNEL_TYPE } from 'gcs-common/helper/channelTypeHelper';
import {
  getNewChannelOption,
  getIsNewChannelSelected, isOptionHidden,
} from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import ChannelListItemNormal from '../ChannelListItemNormal/ChannelListItemNormal';

const ChannelListItemNormalNew = ({
  newChannelOptionId,
  multiSelect = false,
  onChannelSelect,
}) => {
  const newChannelOption = useSelector(getNewChannelOption(newChannelOptionId));
  const isSelected = useSelector(getIsNewChannelSelected(newChannelOptionId));
  const isHidden = useSelector(isOptionHidden(newChannelOptionId));

  return (
    <ChannelListItemNormal
      isSelectedChannel={isSelected}
      unreadCount={null}
      otherUserTyping={false}
      channelType={newChannelOption.type}
      channelImage={newChannelOption.imageUrl}
      channelStatus=""
      channelTitle={newChannelOption.title}
      channelSubtitle={newChannelOption.subtitle}
      multiSelect={multiSelect}
      onChannelSelect={onChannelSelect}
      isNewChannelOption
      isSalesSpace={newChannelOption.type === CHANNEL_TYPE.SALES_SPACE}
      isHidden={isHidden}
    />
  );
};

ChannelListItemNormalNew.propTypes = {
  newChannelOptionId: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
  onChannelSelect: PropTypes.func.isRequired,
};

export default ChannelListItemNormalNew;
