/* eslint-disable react/prop-types */

import styles from './styles.module.scss';

// eslint-disable-next-line react/prop-types
const LozengeCell = ({ value, color }) => {
  return (
    <div
      key={value}
      className={styles.lozenge}
      style={{ backgroundColor: color }}
    >
      {value}
    </div>
  );
};

export default LozengeCell;
