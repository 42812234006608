import { captureException } from '@sentry/react';
import mime from 'mime';
import FatalQueueError from '../errors/FatalQueueError';
import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import { VIDEO_STATUS } from '../../constants/videoStatus';
import { updateVideoUploadProgress } from '../../slices/videos/videosSlice';
import { openErrorDialog } from '../../slices/errorDialogSlice/errorDialogSlice';
import addMessages from '../../slices/messages/messagesThunks/addMessages';
import { byKey } from '../../helper/byKey';
import { getFileExtension } from '../../helper/fileHelper';

const sendMessageEffect = async (effect, action,
  {
    gccApiClient, videoClient, dispatch,
  }) => {
  let { messages } = action.meta;
  messages = await Promise.all(messages.map(async (message) => {
    if (message.isForwarded) {
      return message;
    }

    if (message.messageType === MESSAGE_TYPE.MEDIA) {
      const { fileToUpload: file, contentType: fileType, filename } = message;
      const extension = mime.getExtension(fileType) || getFileExtension(fileType);
      try {
        const {
          preSignedUrl,
          mediaFileName,
        } = await gccApiClient.getMessageMediaPresignedUrl(filename, extension);

        const response = await fetch(preSignedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': fileType,
          },
        });

        if (response.ok) {
          return {
            ...message,
            mediaRelativePath: mediaFileName,
          };
        }
        throw new Error(`Something went wrong! Status: ${response.status}, StatusText: ${response.statusText}`);
      } catch (e) {
        dispatch(openErrorDialog({ dialogErrorMsg: 'Beim Hochladen der Datei ist ein Fehler aufgetreten, bitte versuche es noch einmal.' }));
        captureException(e);
        throw new FatalQueueError(
          'An error occured while uploading the Media to AWS S3!',
          { e, clientMessageId: message.clientMessageId },
        );
      }

    } else if (message.messageType === MESSAGE_TYPE.VIDEO) {
      try {
        const { fileToUpload, videoId } = message;
        const muxUploadVideoId = await videoClient.upload(fileToUpload, (progress) => {
          dispatch(updateVideoUploadProgress({ videoId, percentage: progress }));
        });

        return {
          ...message,
          videoId: muxUploadVideoId,
          videoStatus: VIDEO_STATUS.PROCESSING,
        };
      } catch (e) {
        dispatch(openErrorDialog({ dialogErrorMsg: 'Beim Hochladen des Videos ist ein Fehler aufgetreten, bitte versuche es noch einmal.' }));
        captureException(e);
        throw new FatalQueueError(
          'An error occured while uploading the Video to the video client!',
          { e, clientMessageId: message.clientMessageId },
        );
      }
    }
    return message;
  }));

  const sentMessages = await gccApiClient.sendChannelMessages(messages);
  dispatch(addMessages({ channelMessages: byKey('channelId', sentMessages, true) }));
};

export default sendMessageEffect;
