import { createAsyncThunk } from '@reduxjs/toolkit';
import { initGccApiSuccess } from './gccApiConnectionSlice';
import GccApiUnauthorizedError from '../../clients/gccApiClient/errors/GccApiUnauthorizedError';
import logoutSuccessEffect from '../auth/authThunks/logout-effect';
import AuthUnauthorizedError from '../../clients/authClient/errors/AuthUnauthorizedError';

export const initGccApi = createAsyncThunk(
  'initGccApi',
  async (
    {
      onRequestError,
      onHttpRequest = undefined,
      apiBaseUrl = '',
    },
    { dispatch, rejectWithValue, extra: { gccApiClient, videoClient } },
  ) => {
    const requestErrorMiddleware = (e) => {
      if (e instanceof GccApiUnauthorizedError && e.shouldLogout) {
        dispatch(logoutSuccessEffect());
      }
      if (e instanceof AuthUnauthorizedError) {
        dispatch(logoutSuccessEffect());
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onRequestError && onRequestError(e);
    };

    gccApiClient.init(
      requestErrorMiddleware, rejectWithValue, apiBaseUrl, onHttpRequest,
    );

    const generateVideoUploadSignedUrl = async () => {
      return gccApiClient.getVideoUploadSignedUrl();
    };

    videoClient.init(generateVideoUploadSignedUrl);

    dispatch(initGccApiSuccess());
  },
);
