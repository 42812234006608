import { useDispatch, useSelector } from 'react-redux';
import { isWebcamModalOpen } from 'gcs-common/slices/uiState/uiStateSelectors';
import { closeWebcamModal, openEditImageModal, updateProfileImageForEditing } from 'gcs-common/slices/uiState/uiStateSlice';
import WebcamInput from '../WebcamInput/WebcamInput';
import ModalWithCloseButton from '../PopUpModal/ModalWithCloseButton/ModalWithCloseButton';
import styles from './styles.module.scss';

const WebcamModal = () => {

  const isModalOpen = useSelector(isWebcamModalOpen);
  const dispatch = useDispatch();

  const onSnapshot = (file) => {
    if (file !== null) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const { result } = reader;
        dispatch(updateProfileImageForEditing({ profileImageForEditing: result }));
      };
      dispatch(closeWebcamModal());
      dispatch(openEditImageModal());
    }
  };

  return (
    <ModalWithCloseButton
      isOpen={isModalOpen}
      onRequestClose={() => dispatch(closeWebcamModal())}
      className={styles.webcamModal}
      overlayClassName={styles.overlayWebcamModal}
    >
      <WebcamInput onSnapshot={onSnapshot} />
    </ModalWithCloseButton>
  );
};

export default WebcamModal;
