import { useCallback, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { stringValidator } from 'gcs-common/helper/yupValidationHelper';
import { createTenant } from 'gcs-common/slices/administration/tenants/tenantsThunks';
import { getCreateTenantErrorMessage } from 'gcs-common/slices/administration/tenants/tenantsSelectors';
import { resetCreateTenantErrorMessage } from 'gcs-common/slices/administration/tenants/tenantsSlice';
import styles from './styles.module.scss';
import FormikInput from '../../../FormikInput/FormikInput';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

export const TenantCreateValidationSchema = Yup.object().shape({
  friendlyName: stringValidator,
});

const initialData = {
  friendlyName: '',
};

const TenantCreate = ({ onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateTenantErrorMessage);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetCreateTenantErrorMessage()),
      ));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    dispatch(resetCreateTenantErrorMessage());
  }, [dispatch]);

  const onTenantNameChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateTenantErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(createTenant({ tenant: values }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={TenantCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>

          <div className={styles.row}>
            <FormikInput label="Name*" name="friendlyName" onKeyUp={onTenantNameChanged} />
          </div>

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

TenantCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TenantCreate;
