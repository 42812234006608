import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getHouse, getSelectedHouseId } from 'gcs-common/slices/houses/housesSelectors';
import changeSelectedHouseId from 'gcs-common/slices/houses/housesThunks/changeSelectedHouseId';
import { getTotalHouseUnreadCount } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import { ICON_COLOR } from 'gcs-common/constants/IconConstants';
import CheckBox from '../CheckBox/CheckBox';
import styles from './styles.module.scss';
import UnreadCountDisplay from '../UnreadCountDisplay/UnreadCountDisplay';

const HouseSwitcherItem = ({ handleClose, houseId }) => {
  const dispatch = useDispatch();
  const house = useSelector(getHouse(houseId));
  const selectedHouseId = useSelector(getSelectedHouseId);
  const totalUnreadCount = useSelector(getTotalHouseUnreadCount(houseId));

  const isSelected = selectedHouseId === houseId;

  return (
    <button
      className={`${styles.houseSwitcherItem} ${isSelected ? styles.houseSwitcherItemSelected : ''}`.trim()}
      key={houseId}
      onClick={() => {
        dispatch(changeSelectedHouseId({ selectedHouseId: houseId }));
        handleClose();
      }}
      type="button"
    >
      <div className={styles.left}>
        <div className={styles.checkIcon} alt="icon-check">
          <CheckBox isChecked={isSelected} color={ICON_COLOR.NEW_CHAT_CHECKBOXES} />
        </div>
        <span className={styles.houseSwitcherItemName}>{house.friendlyName}</span>
      </div>
      {totalUnreadCount > 0 && (
        <div className={styles.unreadMessages}>
          <UnreadCountDisplay unreadCount={totalUnreadCount} />
        </div>
      )}
    </button>
  );
};

HouseSwitcherItem.propTypes = {
  handleClose: PropTypes.func.isRequired,
  houseId: PropTypes.string.isRequired,
};

export default HouseSwitcherItem;
