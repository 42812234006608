import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMessageIds } from 'gcs-common/slices/messageInput/messageInputSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { addSelectedMessageIds, removeSelectedMessageIds } from 'gcs-common/slices/messageInput/messageInputSlice';
import { isSelectableMessage } from 'gcs-common/slices/messages/messageSelector';
import checkIcon from '../../img/check_white.svg';
import styles from './styles.module.scss';

const MessageSelectorContainer = ({ messageId, children }) => {
  const dispatch = useDispatch();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedMessageIds = useSelector(getSelectedMessageIds(selectedChannelId));
  const selectableMessage = useSelector(isSelectableMessage(selectedChannelId, messageId));
  const isSelected = selectedMessageIds && selectedMessageIds.includes(messageId);

  const onToggle = useCallback(() => {
    if (selectedMessageIds && selectedMessageIds.length !== 0) {
      if (isSelected) {
        dispatch(removeSelectedMessageIds(
          { selectedMessageId: messageId, channelId: selectedChannelId },
        ));
      } else {
        dispatch(addSelectedMessageIds(
          { selectedMessageId: messageId, channelId: selectedChannelId },
        ));
      }
    }

  }, [dispatch, isSelected, messageId, selectedChannelId, selectedMessageIds]);

  if (!selectableMessage) {
    return (children);
  }

  return (
    <div
      role="none"
      onClick={onToggle}
      className={`${styles.listItem} ${isSelected && styles.selectedListItem}`}

    >
      {selectedMessageIds.length !== 0 && (
        <div
          className={isSelected
            ? styles.selectedCheckIconWrapper
            : styles.checkIconWrapper}
        >
          {isSelected && (
            <img
              role="none"
              onClick={onToggle}
              src={checkIcon}
              alt="checkme"
            />
          )}
        </div>
      )}
      <div className={styles.children}>

        {children}
      </div>
    </div>
  );
};

export default MessageSelectorContainer;

MessageSelectorContainer.propTypes = {
  messageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
