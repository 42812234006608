import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const NotificationBadge = ({ count }) => {
  return <div className={styles.badge}>{count}</div>;
};

NotificationBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

export default NotificationBadge;
