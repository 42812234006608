import { TICKET_INPUT_CHANNELS, TicketInputChannelsKey } from 'gcs-common/slices/tickets/ticketsConstants';
import styles from './styles.module.scss';

interface TicketInfoProps {
  customerOrderText: string | undefined;
  customerNumber: string;
  creator: string | undefined;
  inputChannel: TicketInputChannelsKey | undefined;
  channelName: string | undefined;
  houseName: string | undefined;
}

const TicketInfo = (props: TicketInfoProps) => {
  const {
    customerOrderText, customerNumber, creator = '', inputChannel, channelName, houseName,
  } = props;
  const inputChannelLabel = [
    // TODO inputChannel is marked as Optional in backend. how to act on undefined?
    TICKET_INPUT_CHANNELS[inputChannel!]?.label,
    channelName,
  ].filter(x => x).join(' - ');

  return (
    <div className={styles.grid}>
      <div>
        Haus
      </div>
      <div className={styles.value}>{houseName}</div>

      <div>
        Kundennummer
      </div>
      <div className={styles.value}>{customerNumber}</div>

      <div>
        Kommission
      </div>
      <div className={styles.value}>{customerOrderText}</div>

      <div>
        Ersteller
      </div>
      <div className={styles.value}>{creator}</div>

      <div>
        Eingangskanal
      </div>
      <div className={styles.value}>
        {inputChannelLabel}
      </div>
    </div>
  );
};

export default TicketInfo;
