/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalSearchInput: '',
};

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    globalSearchInputUpdated: (state, action) => {
      const { globalSearchInput } = action.payload;
      state.globalSearchInput = globalSearchInput;
    },
    globalSearchInputReset: (state) => {
      state.globalSearchInput = '';
    },
  },
  extraReducers: {},
});

export const {
  globalSearchInputUpdated,
  globalSearchInputReset,
} = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
