import CHAT_CONNECTION_STATE from '../../constants/ConnectionState';

export const getIsChatClientInitialized = state => state.chatConnection.chatClientInit;

export const getChatConnectionState = state => (
  state.chatConnection.connectionState
);

export const getIsChatConnected = state => (
  state.chatConnection.connectionState === CHAT_CONNECTION_STATE.CONNECTED
);
