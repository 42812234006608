import { isPlain } from '@reduxjs/toolkit';

// Warning: Files are a real special case because to preview or
// make them serializable they need to be converted to ObjectURLs,
// which need then to be revoked after usage, so we make an exception here
// FormData data is really hard to parse and needs
// to be used to send messages to twilio
const customStoreSerializableCheck = (value) => {
  if (value instanceof FormData) {
    return true;
  }
  if (value instanceof File) {
    return true;
  }
  return isPlain(value);
};

export default customStoreSerializableCheck;
