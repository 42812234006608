import { getFirstChannelId } from 'gcs-common/slices/channelList/channelListSelectors';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';

import { useSelector } from 'react-redux';
import SidebarMessage from '../../SidebarMessage/SidebarMessage';
import AgentChannelList from '../AgentChannelList/AgentChannelList';
import DirectChannelSort from '../DirectChannelSort/DirectChannelSort';
import styles from './styles.module.scss';

function DirectChannelListWrapper() {
  const searchInput = useSelector(getSearchInput);
  const firstChannelSid = useSelector(getFirstChannelId);
  const hasChannels = !!firstChannelSid;
  return (
    <>
      <div className={styles.channelListTypeSelectorWrapper}>
        <div className={styles.filterList}>
          <DirectChannelSort />
        </div>
      </div>
      {!hasChannels && !searchInput && (
        <SidebarMessage text="Um einen Chat zu starten, tippen Sie bitte auf den Plus-Button." />
      )}
      <AgentChannelList />
    </>
  );
}

export default DirectChannelListWrapper;
