import PropTypes from 'prop-types';
import usePermission from 'gcs-common/hooks/usePermission';

const PermissionProtectedRoute = ({ permission, children }) => {
  const hasPermission = usePermission(permission);
  if (!hasPermission) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

PermissionProtectedRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  permission: PropTypes.array.isRequired,
  children: PropTypes.element.isRequired,
};

export default PermissionProtectedRoute;
