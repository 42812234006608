import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getApiBaseUrl, getAppName, getChatLogo, getLogo, getThemeIdentifier,
} from 'gcs-common/slices/theme/themeSelectors';
import { LOGIN_ROUTES } from 'gcs-common/constants/routes';
import { THEME } from 'gcs-common/constants/themes';
import { useAuth0WebBrowserLoginFlow } from 'gcs-common/hooks/auth/useAuth0WebBrowserLoginFlow';
import Button from '../../Button/Button';
import styles from './styles.module.scss';
import { getWebLoginFlow } from '../../../slices/auth/authSelectors';
import MessageLikeTextBox from '../../MessageLikeTextBox/MessageLikeTextBox';
import digiBoxIcon from '../../../img/digital_box.svg';

const LoginClassic = () => {
  const { openAuth0UniversalLoginWeb } = useAuth0WebBrowserLoginFlow();

  const logo = useSelector(getLogo);
  const chatLogo = useSelector(getChatLogo);

  const loginFlow = useSelector(getWebLoginFlow);
  const appName = useSelector(getAppName);
  const apiBaseUrl = useSelector(getApiBaseUrl);

  const theme = useSelector(getThemeIdentifier);

  const isAgentBeem = theme === THEME.AGENT;

  function handleLogin() {
    openAuth0UniversalLoginWeb(loginFlow);
  }

  return (
    <div className={styles.loginRoot}>
      <div className={styles.loginOverlay} />
      <div className={styles.loginWrapper}>
        <img alt="gc-chat-logo" className={`${isAgentBeem ? styles.chatLogoBeem : styles.chatLogo}`} src={logo} />
        {!isAgentBeem && <img alt="gc-chat-text" className={styles.chatText} src={chatLogo} />}
        {isAgentBeem ? (
          <MessageLikeTextBox className={styles.welcome}>
            Willkommen bei
            {` ${appName}`}
            !
          </MessageLikeTextBox>
        ) : (
          <MessageLikeTextBox className={styles.welcome}>
            Willkommen beim
            {` ${appName}`}
            -Chat!
          </MessageLikeTextBox>
        )}
        <Button
          className={styles.loginButton}
          onClick={() => {
            window.location = `${apiBaseUrl}/saml/authorize`;
          }}
        >
          Mit Microsoft anmelden
        </Button>
        <Button
          className={styles.loginButton}
          onClick={handleLogin}
        >
          Login
        </Button>
      </div>
      {isAgentBeem && <img className={styles.digiBoxIcon} alt="" src={digiBoxIcon} />}
      {isAgentBeem
      && (
      <div className={styles.linksWrapper}>
        <Link
          to={LOGIN_ROUTES.LOGIN_TERMS}
          className={styles.termCondition}
        >
          Nutzungsbedingungen & Datenschutzrichtlinien
        </Link>
        <Link
          to={LOGIN_ROUTES.LOGIN_IMPRINT}
          className={styles.termCondition}
        >
          Impressum
        </Link>
      </div>
      )
      }
    </div>
  );
};

export default LoginClassic;

LoginClassic.propTypes = {};
