import { useState } from 'react';
import { friendlyDateTime } from 'gcs-common/helper/dateHelper';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import TicketCommentContent from 'gcs-common/components/Tickets/TicketComments/TicketCommentContent/TicketCommentContent';
import { Response } from 'gcs-common/types/typeHelper';
import styles from './styles.module.scss';
import TicketCommentNotification from './TicketCommentNotification/TicketCommentNotification';
import TicketCommentDeleteModal from './TicketCommentDeleteModal/TicketCommentDeleteModal';

interface TicketCommentProps {
  ticketId: string;
  comment: Response<'/chat/ets/tickets/{ticket_id}/comments', 'get'>['comments'][number];
  ticketNotification: {
    lastViewedAt: string
  }; // TODO needs backend type
}

const TicketComment = (props: TicketCommentProps) => {
  const { ticketId, comment, ticketNotification } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentUserId = useSelector(getCurrentUserId);

  const canDelete = comment.authorId === currentUserId;

  const showNotification = ticketNotification
    && moment(comment.updatedAt || comment.createdAt).isAfter(ticketNotification.lastViewedAt)
    && !comment.fromBeem;

  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentLeft}>
        <div className={styles.commentLeftContent}>
          <div className={styles.commentIcon} style={{ backgroundImage: `url(${comment.authorImageUrl})` }} />

          {showNotification && <TicketCommentNotification />}
        </div>
      </div>
      <div className={styles.commentRight}>
        <div className={styles.commentHeader}>
          <div>{comment.authorName}</div>
          <div className={styles.commentTimestamp}>{friendlyDateTime(comment.createdAt)}</div>
        </div>
        <div className={styles.commentContent}>
          <TicketCommentContent html={comment.html} />
        </div>
        {canDelete && (
          <div>
            <button type="button" className={styles.deleteButton} onClick={() => setShowDeleteModal(true)}>
              Löschen
            </button>

            {showDeleteModal && (
              <TicketCommentDeleteModal
                ticketId={ticketId}
                commentId={comment.id}
                onClose={() => setShowDeleteModal(false)}
              />
            )}
          </div>
        )}
      </div>

    </div>
  );
};

export default TicketComment;
