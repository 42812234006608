import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ORDER_STATUS {
  LOCAL_DRAFT = 'LOCAL_DRAFT',
  OPEN = 'OPEN',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  ERROR = 'ERROR',
}

export const ORDER_STATUS_DESCRIPTION_AGENT = {
  [ORDER_STATUS.LOCAL_DRAFT]: 'Entwurf',
  [ORDER_STATUS.OPEN]: 'Offen - Warte auf Bestätigung durch Kunden',
  [ORDER_STATUS.ACCEPTED]: 'Bestellt - Kunde hat bestätigt',
  [ORDER_STATUS.SUBMITTED]: 'Bestellt - Kunde hat bestätigt',
  [ORDER_STATUS.REJECTED]: 'Abgelehnt - Kunden hat abgelehnt',
  [ORDER_STATUS.ERROR]: 'Fehler',
};

export const ORDER_STATUS_DESCRIPTION_CUSTOMER = {
  [ORDER_STATUS.LOCAL_DRAFT]: 'Entwurf',
  [ORDER_STATUS.OPEN]: 'Bereit - Kann bestellt werden. Falls gewünscht kannst du deine Bestellung noch anpassen',
  [ORDER_STATUS.ACCEPTED]: 'Bestellt - Die Bestellung ist jetzt unterwegs zu dir',
  [ORDER_STATUS.SUBMITTED]: 'Bestellt - Die Bestellung ist jetzt unterwegs zu dir',
  [ORDER_STATUS.REJECTED]: 'Abgelehnt',
  [ORDER_STATUS.ERROR]: 'Fehler',

};

export const ORDER_STATUS_COLOR = {
  [ORDER_STATUS.LOCAL_DRAFT]: styles.draft,
  [ORDER_STATUS.OPEN]: styles.open,
  [ORDER_STATUS.ACCEPTED]: styles.success,
  [ORDER_STATUS.SUBMITTED]: styles.success,
  [ORDER_STATUS.REJECTED]: styles.error,
  [ORDER_STATUS.ERROR]: styles.error,
};
