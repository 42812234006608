/* eslint-disable no-param-reassign, @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { debugLogger } from '../../helper/debugLogger';
import fetchFavorites from './favoritesThunks/fetchFavorites';
import { addFavoriteCommit, addFavoriteRollback, addFavoriteOffline } from './favoritesActions/addFavoriteOffline';
import { removeFavoriteOffline, removeFavoriteRollback } from './favoritesActions/removeFavoriteOffline';
import ASYNC_STATE from '../../constants/AsyncState';
import { extractTemporaryFavorites } from './helper/favoriteSliceHelper';

const asyncState = {
  fetch: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
};

const initialState = {
  ...asyncState,
  favorites: {},
};

const persistWhitelist = [
  'favorites',
];

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  extraReducers: {
    // REMOVE OFFLINE
    [removeFavoriteRollback]: (state, action) => {
      const { channelId, favoriteToBeDeleted, favoriteId } = action.meta;
      debugLogger(`Running removeFavoriteRollback for favoriteId=${favoriteId} with action=`, action);

      state.favorites[channelId][favoriteId] = favoriteToBeDeleted;
    },
    [removeFavoriteOffline]: (state, action) => {
      debugLogger('Running removeFavoriteOffline with action=', action);

      const { channelId, favoriteId } = action.meta;
      const { [favoriteId]: _toBeRemoved, ...rest } = state.favorites[channelId];

      state.favorites[channelId] = rest;
    },
    // ADD OFFLINE
    [addFavoriteRollback]: (state, action) => {
      debugLogger('Rolling back favorite action=', action);

      const { meta: { channelId, temporaryFavoriteId } } = action;
      const { [temporaryFavoriteId]: _toBeRemoved, ...rest } = state.favorites[channelId];

      state.favorites[channelId] = rest;
    },
    [addFavoriteCommit]: (state, action) => {
      const { favorite: { id } } = action.payload;
      const { channelId, temporaryFavoriteId } = action.meta;

      debugLogger(`Committing adding favorite with temporaryId=${temporaryFavoriteId}, action=`, action);

      const favorite = state.favorites[channelId][temporaryFavoriteId];
      favorite.id = id;

      const { [temporaryFavoriteId]: _toBeRemoved, ...rest } = state.favorites[channelId];
      state.favorites[channelId] = rest;
      state.favorites[channelId][favorite.id] = favorite;
    },
    [addFavoriteOffline]: (state, action) => {
      const { channelId, message, messageId, temporaryFavoriteId } = action.meta;

      debugLogger(`Running addFavoriteOffline for favoriteId=${temporaryFavoriteId}, action=`, action);

      const {
        text, index, author, dateUpdated,
      } = message;

      const favorite = {
        id: temporaryFavoriteId,
        message: {
          text, index, author, dateUpdated,
        },
        channelId,
        messageId,
      };

      if (!state.favorites[channelId]) {
        state.favorites[channelId] = {};
      }

      debugLogger(`Creating a new favorite with favoriteId=${favorite.id}`);

      state.favorites[channelId][favorite.id] = favorite;
    },
    // FETCH
    [fetchFavorites.pending]: (state) => {
      state.fetch.status = ASYNC_STATE.LOADING;
    },
    [fetchFavorites.fulfilled]: (state, action) => {
      const { favorites } = action.payload;

      // make sure not to delete recently added temporary favorites handled by addFavoriteCommit
      const temporaryFavorites = extractTemporaryFavorites(state.favorites);

      state.fetch.status = ASYNC_STATE.SUCCEEDED;
      state.favorites = merge(favorites, temporaryFavorites);
    },
    [fetchFavorites.rejected]: (state, action) => {
      state.fetch.status = ASYNC_STATE.FAILED;
      state.error = action.error;
    },
  },
});

export { persistWhitelist as favoritesPersistWhitelist };
export default favoritesSlice.reducer;
