import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openEditImageModal, updateProfileImageForEditing, openWebcamModal } from 'gcs-common/slices/uiState/uiStateSlice';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import useOnClickOutside from 'gcs-common/hooks/useOnClickOutside';
import IconComponent from '../IconComponent/IconComponent';
import FileInputWrapper from '../FileInputWrapper/FileInputWrapper';
import styles from './styles.module.scss';

const SelectPhotoSourceButtons = ({ toggleSelectPhotoButtons, isRegistration = false }) => {

  const buttonsRef = useRef(null);
  const dispatch = useDispatch();

  useOnClickOutside(buttonsRef, toggleSelectPhotoButtons);

  const takePhoto = () => {
    toggleSelectPhotoButtons();
    dispatch(openWebcamModal());
  };

  const onPictureChoice = async (file) => {
    if (file !== null) {
      const reader = new FileReader();
      // Turns file into base64 string
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        const { result } = reader;
        dispatch(updateProfileImageForEditing({ profileImageForEditing: result }));
      };
      dispatch(openEditImageModal());
      toggleSelectPhotoButtons();
    }
  };

  return (
    <div className={isRegistration ? `${styles.buttonGroup} ${styles.registrationButtons}` : `${styles.buttonGroup} ${styles.updateProfileImageButtons}`} ref={buttonsRef}>
      <button
        type="button"
        className={styles.takePhotoButton}
        onClick={takePhoto}
      >
        Foto aufnehmen
        <IconComponent
          Icon={ICON.PHOTO}
          color={isRegistration
            ? ICON_COLOR.REGISTRATION_PROFILE_IMAGE
            : ICON_COLOR.UPDATE_PROFILE_IMAGE
          }
        />
      </button>
      <FileInputWrapper onChange={onPictureChoice} className={styles.selectPhotoButton}>
        Bild Hochladen
        <IconComponent
          Icon={ICON.FILE}
          color={isRegistration
            ? ICON_COLOR.REGISTRATION_PROFILE_IMAGE
            : ICON_COLOR.UPDATE_PROFILE_IMAGE
          }
        />
      </FileInputWrapper>
    </div>
  );
};

SelectPhotoSourceButtons.propTypes = {
  toggleSelectPhotoButtons: PropTypes.func.isRequired,
  isRegistration: PropTypes.bool,
};

export default SelectPhotoSourceButtons;
