import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getMyMemberId, getMyPromoEmail, getPromoEmailLoading } from 'gcs-common/slices/members/membersSelectors';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import copyToClipboard from 'gcs-common/helper/copyToClipboard';
import { fetchMemberEmail } from 'gcs-common/slices/members/membersThunks/fetchMemberEmail';
import { createMemberEmail } from 'gcs-common/slices/members/membersThunks/createMemberEmail';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { ICON } from 'gcs-common/constants/IconConstants';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import IconComponent from '../../IconComponent/IconComponent';
import PopupMenu from '../../PopupMenu/PopupMenu';
import Button from '../../Button/Button';
import styles from './styles.module.scss';

function ChannelEmailPopup() {

  const [showChannelEmailPopup, setShowChannelEmailPopup] = useState(false);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const myMemberId = useSelector(getMyMemberId(selectedChannelId));
  const email = useSelector(getMyPromoEmail(myMemberId));
  const promoEmailLoading = useSelector(getPromoEmailLoading);
  const dispatch = useDispatch();
  const isUserInCurrentChannel = useSelector(getCurrentUserIsMemberOfChannel(selectedChannelId));
  const createEmail = useCallback(async () => {
    dispatch(createMemberEmail({ memberId: myMemberId }));
  }, [myMemberId, dispatch]);

  const onButtonClick = useCallback(() => {
    if (!promoEmailLoading) {
      dispatch(trackGALegacyEvent(LEGACY_EVENTS.DIRECT_MAIL_LINK()));
      if (!email && myMemberId) {
        dispatch(fetchMemberEmail({ memberId: myMemberId }));
      }
      setShowChannelEmailPopup(true);
    }
  }, [dispatch, email, myMemberId, promoEmailLoading]);

  const onOutsideClick = useCallback(() => {
    setShowChannelEmailPopup(false);
  }, []);

  useEffect(() => {
    setShowChannelEmailPopup(false);
  }, [myMemberId]);

  return (
    <div>
      <Button
        onClick={onButtonClick}
        className={styles.navbarButton}
        title="Mehr"
        data-testid="open-channel-email-popup-button"
      >
        <IconComponent Icon={ICON.MORE} alt="Mehr" />
      </Button>
      <PopupMenu
        isVisible={showChannelEmailPopup && isUserInCurrentChannel}
        onHide={onOutsideClick}
        menuItemsClass={styles.menuLeft}
      >
        {!email
          ? (
            <Button
              type="button"
              onClick={createEmail}
              data-testid="create-email-address-button"
            >
              <nobr>Generiere eine Email für Angebote</nobr>
            </Button>
          )
          : (
            <>
              <div style={{ border: 'none', padding: '4px 12px', whiteSpace: 'nowrap' }}>
                Email für Angebote aus WW90:
              </div>
              <div style={{ fontWeight: 'bold', padding: '4px 12px', whiteSpace: 'nowrap' }}>
                {email}
              </div>
              <Button
                type="button"
                onClick={() => copyToClipboard(email)}
                style={{ width: '100%' }}
                data-testid="copy-email-address-button"
              >
                Kopieren
              </Button>
            </>
          )
        }
      </PopupMenu>
    </div>
  );
}

ChannelEmailPopup.propTypes = {};

export default ChannelEmailPopup;
