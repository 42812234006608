

const imprintText = [
  <b>Verantwortlich im Sinne des § 5 TMG:</b>,
  `
  RHEIN-RUHR COLLIN KG
  Geschäftsbereich HTI
  Collinweg
  47059 Duisburg

  `,
  <b>Persönlich haftender Gesellschafter</b>,
  `
  Gerrit Collin
  Horst Collin

  `,
  <b>Registergericht:</b>,
  `
  Amtsgericht Duisburg

  `,
  <b>Registernummer:</b>,
  `
  HRA 5847

  `,
  <b>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</b>,
  `
  DE 114523715

  `,
  <b><i>Sie haben eine konkrete Frage?</i></b>,
  `
  `,
  <i>
    Wenden Sie sich bitte an das HTI-Haus in Ihrer Nähe! Wo Sie uns finden, sehen Sie
    <a href="http://www.hti-handel.de/de/standorte/list">hier</a>
    .
  </i>,
  `

  `,
  <b>REGION NORD</b>,
  `
  `,
  <b>HTI CORDES & GRAEFE KG</b>, ` | 28816 Stuhr
  Telefon: +49 421 8998-0 | E-Mail: `, <a href="mailto:info.bremen@hti-handel.de">info.bremen(at)hti-handel.de</a>,
  `
  
  `,
  <b>HTI COLLIN KG</b>, ` | 30165 Hannover
  Telefon: +49 511 74057-0 | E-Mail: `, <a href="mailto:info.hannover@hti-handel.de">info.hannover(at)hti-handel.de</a>,
  `
  
  `,
  <b>HTI FELDTMANN KG</b>, ` | 22549 Hamburg
  Telefon: +49 40 80720-0 | E-Mail: `, <a href="mailto:info@hti-feldtmann.de">info(at)hti-feldtmann.de</a>,
  `
  
  `,
  <b>REGION OST</b>,
  `
  `,
  <b>HTI BÄR & OLLENROTH KG</b>, ` | 15834 Rangsdorf
  Telefon: +49 33708 26-0 | E-Mail: `, <a href="mailto:verkauf.gm@hti-handel.de">verkauf.gm(at)hti-handel.de</a>,
  `
  
  `,
  <b>HTI DINGER & HORTMANN KG</b>, ` | 01665 Klipphausen
  Telefon: +49 35204 9660 | E-Mail: `, <a href="mailto:klipphausen.info@hti-handel.de">klipphausen.info(at)hti-handel.de </a>,
  `
  
  `,
  <b>HTI THÜRINGEN KG</b>, ` | 99087 Erfurt
  Telefon: +49 361 74039-0 | E-Mail: `, <a href="mailto:info.erfurt@hti-handel.de">info.erfurt(at)hti-handel.de</a>,
  `
  
  `,
  <b>REGION SÜD</b>,
  `
  `,
  <b>HTI GIENGER KG</b>, ` | 85570 Markt Schwaben
  Telefon: +49 8121 44-0 | E-Mail: `, <a href="mailto:info.ms@hti-handel.de">info.ms(at)hti-handel.de</a>,
  `
  
  `,
  <b>HTI ZEHNTER KG</b>, ` | 71083 Herrenberg-Gültstein
  Telefon: +49 7032 9793-0 | E-Mail: `, <a href="mailto:hti-zehnter@hti-handel.de">hti-zehnter(at)hti-handel.de</a>,
  `
  
  `,
  <b>REGION WEST</b>,
  `
  `,
  <b>HTI COLLIN & HOFMANN KG</b>, ` | 47059 Duisburg
  Telefon: +49 203 28900-3000 | E-Mail: `, <a href="mailto:info.collin-hofmann@hti-handel.de">info.collin-hofmann(at)hti-handel.de</a>,
  `
  
  `,
  <b>HTI COLLIN & SCHULTEN KG</b>, ` | 47059 Duisburg
  Telefon: +49 203 28900-0
  
  `,
  <b>HTI EISEN-RIEG KG</b>, ` | 64846 Groß Zimmern
  Telefon: +49 6071 4991-0 | E-Mail: `, <a href="mailto:hti.grosszimmern@hti-handel.de">hti.grosszimmern(at)hti-handel.de</a>,
  `
  
  `,
  <b>HTI HORTMANN KG</b>, ` | 57234 Wilnsdorf
  Telefon: +49 2739 8759-0 | E-Mail: `, <a href="mailto:info@hortmann-handel.de">info(at)hortmann-handel.de</a>,
  `
  
  
  `,
  <b>Rechtliche Hinweise</b>,
  `

  `,
  <b>1. Inhalt des Onlineangebotes</b>,
  `

  Der Anbieter übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Anbieter, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden sind grundsätzlich ausgeschlossen, sofern seitens des Anbieter kein nachweislich vorsätzliches oder grob fahrlässiges Ver­schulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Anbieter behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte An­kündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.

  `,
  <b>2. Verweise und Links</b>,
  `

  Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb des Verantwortungsbereiches des Anbieters liegen, würde eine Haftungsverpflichtung aus­schließlich in dem Fall in Kraft treten, in dem der Anbieter von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger In­halte zu verhindern. Der Anbieter erklärt daher ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhal­te der gelinkten/verknüpften Seiten. Deshalb distanziert er sich hiermit ausdrücklich von al­len Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert wur­den. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Anbieter eingerichteten Gästebü­chern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige In­halte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Drittanbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Doku­mentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.

  `,
  <b>3. Urheber- und Kennzeichenrecht</b>,
  `

  Der Anbieter ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafi­ken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Gra­fiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetan­gebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterlie­gen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nen­nung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter ge­schützt sind! Das Copyright für veröffentlichte, vom Anbieter selbst erstellte Objekte bleibt allein beim Anbieter der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Anbieters nicht gestattet.`,
];

export default imprintText;
