import ASYNC_STATE from '../constants/AsyncState';

export const toStatus = (state) => {
  return state?.status || ASYNC_STATE.IDLE;
};

export const toIsLoading = (state) => {
  return state?.status === ASYNC_STATE.LOADING;
};

export const toIsLoadingOrIdle = (state) => {
  return !state || state?.status === ASYNC_STATE.LOADING || state?.status === ASYNC_STATE.IDLE;
};

export const toHasError = (state) => {
  return state?.status === ASYNC_STATE.FAILED;
};

export const toHasSucceeded = (state) => {
  return state?.status === ASYNC_STATE.SUCCEEDED;
};
