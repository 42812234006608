import PropTypes from 'prop-types';

export default PropTypes.shape({
  Header: PropTypes.string,
  accessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  filter: PropTypes.string,
  Cell: PropTypes.func,
  sortType: PropTypes.func, // Function(rowA: <Row>, rowB: <Row>, columnId: String, desc: Bool)
  disableSortBy: PropTypes.bool,
});
