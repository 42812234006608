import { ChannelTypeInfo } from 'gcs-common/helper/channelTypeHelper';

export const stringSortingFunction = (rowA, rowB, columnId, desc) => {
  const sortAttributesArray = columnId.split('.');
  const valueA = sortAttributesArray.reduce((acc, curr) => {
    return acc[curr] || {};
  }, rowA.original)?.toString().toLowerCase() || '';
  const valueB = sortAttributesArray.reduce((acc, curr) => {
    return acc[curr] || {};
  }, rowB.original)?.toString().toLowerCase() || '';
  if (desc) {
    return valueA?.localeCompare(valueB) > 0 ? 1 : -1;
  }
  return valueB?.localeCompare(valueA) > 0 ? -1 : 1;
};

export const channelTypeSortFunction = (rowA, rowB, _, desc) => {
  const valueA = ChannelTypeInfo[rowA.values.channelType].name;
  const valueB = ChannelTypeInfo[rowB.values.channelType].name;
  if (desc) {
    return valueA?.localeCompare(valueB) > 0 ? 1 : -1;
  }
  return valueB?.localeCompare(valueA) > 0 ? -1 : 1;
};
