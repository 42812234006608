import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import { getMessageIdsForChannel } from 'gcs-common/slices/messages/messagesSelectors';
import {
  getChannelHasMore,
} from 'gcs-common/slices/uiState/uiStateSelectors';
import {
  getShouldScrollToMessageId,
} from 'gcs-common/slices/chat/chatSelectors';
import loadPreviousMessages from 'gcs-common/slices/messages/messagesThunks/loadPreviousMessages';
import { resetScrollToMessageId } from 'gcs-common/slices/chat/chatSlice';
import {
  getMessageLocalListIndexForChannelById,
} from 'gcs-common/slices/messages/virtualMessageSelector';
import { MAX_MESSAGE_VIRTUAL_INDEX } from 'gcs-common/slices/virtualScroller/virtualScrollerSlice';
// eslint-disable-next-line import/no-cycle
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import MessageWrapper from '../MessageWrapper/MessageWrapper';
import ChatScrollerFooter from '../ChatScrollerFooter/ChatScrollerFooter';
import styles from './styles.module.scss';

const VirtualMessageScroller = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const hasMore = useSelector(getChannelHasMore(selectedChannelId));
  const messageIds = useSelector(getMessageIdsForChannel(selectedChannelId));

  const localMessageMaxIndex = messageIds?.length - 1;

  const scrollToMessageId = useSelector(getShouldScrollToMessageId);
  const scrollToIndex = useSelector(
    getMessageLocalListIndexForChannelById(selectedChannelId, scrollToMessageId),
  );

  const virtuosoRef = useRef(undefined);

  const dispatch = useDispatch();

  const onLoadPreviousMessages = useCallback(async () => {
    if (hasMore) {
      dispatch(loadPreviousMessages());
    }
  }, [dispatch, hasMore]);

  useEffect(() => {
    if (scrollToIndex !== null) {
      virtuosoRef.current.scrollToIndex({
        index: scrollToIndex,
        align: 'start',
        behavior: 'auto',
      });
      dispatch(resetScrollToMessageId());
    }
  }, [dispatch, scrollToIndex]);

  const firstMessageVirtualIndex = useMemo(() => {
    return MAX_MESSAGE_VIRTUAL_INDEX - messageIds?.length;
  }, [messageIds]);

  const generateKey = useCallback((index) => {
    return messageIds[index - firstMessageVirtualIndex];
  }, [messageIds, firstMessageVirtualIndex]);

  const followOutput = useCallback((isAtBottom) => {
    // NOTE: If at bottom scroll smoothly for new messages, otherwise dont scroll
    if (isAtBottom) {
      return 'smooth';
    }
    return false;
  }, []);

  if (!messageIds) {
    return (
      <div className={styles.virtualMessageLoader}>
        <LoadingIndicator />
      </div>
    );

  }

  return (
    <Virtuoso
      ref={virtuosoRef}
      computeItemKey={generateKey}
      firstItemIndex={firstMessageVirtualIndex}
      initialTopMostItemIndex={localMessageMaxIndex}
      data={messageIds}
      startReached={onLoadPreviousMessages}
      itemContent={(_, messageId) => (
        <MessageWrapper messageId={messageId} />
      )}
      followOutput={followOutput}
      components={{
        Footer: () => <ChatScrollerFooter />,
      }}
    />
  );
};

export default VirtualMessageScroller;
