import BaseModel from './BaseModel';

export default class MemberModel extends BaseModel {
  mediaFromMailAddress;

  externalId;

  externalIdentity;

  channelId;

  userId;

  lastConsumedMessageIndex;

  unreadCount;

  isTyping; // this needs for our state when user is typing

  dateCreated;

  dateUpdated;

  type;

  constructor(
    {
      id,
      mediaFromMailAddress,
      externalId,
      externalIdentity,
      channelId,
      userId,
      lastConsumedMessageIndex,
      unreadCount,
      isTyping,
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
    },
  ) {
    super(id, createdAt, updatedAt, createdBy, updatedBy);
    this.mediaFromMailAddress = mediaFromMailAddress;
    this.externalId = externalId;
    this.externalIdentity = externalIdentity;
    this.channelId = channelId;
    this.userId = userId;
    this.lastConsumedMessageIndex = lastConsumedMessageIndex;
    this.unreadCount = unreadCount;
    this.isTyping = isTyping;
  }
}
