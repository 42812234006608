import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getAuthorNameColor, getMessageAuthorErpCustomerNumber, getMessageAuthorId,
  getMessageQuotedAuthorName,
  getMessageQuotedAuthorId,
} from 'gcs-common/slices/messages/messageSelector';
import { addUserToColorMap } from 'gcs-common/slices/channels/channelsSlice';
import styles from './styles.module.scss';

const QuotedMessageName = ({ channelId, messageId }) => {
  const authorName = useSelector(getMessageQuotedAuthorName(messageId, channelId));
  const authorId = useSelector(getMessageQuotedAuthorId(messageId));
  const forwardedFromAuthorId = useSelector(getMessageAuthorId(messageId));
  const usernameColor = useSelector(getAuthorNameColor(channelId, authorId));
  const currentUser = useSelector(getCurrentUser);
  const authorErpCustomerNumber = useSelector(getMessageAuthorErpCustomerNumber(
    channelId,
    authorId,
    forwardedFromAuthorId,
    currentUser,
  ));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!usernameColor) {
      // it is necessary to do this computation on demand
      // and not per selector since at any time messages from previously
      // unknown authors can be added at the beginning (load prev messages)
      // or the end (receive new message) of the channelMessages
      dispatch(addUserToColorMap({ channelId, id: authorId }));
    }
  }, [authorId, channelId, dispatch, usernameColor]);
  // do not show ugly flashing of default color if color is not yet defined but rather hide name
  const visibility = usernameColor ? 'visible' : 'hidden';
  return (
    <div style={{ color: usernameColor, visibility }} className={styles.nameHeader}>
      {authorName}
      {' '}
      {authorErpCustomerNumber
          && (
          <p className={styles.erpCustomerNumber}>
            {authorErpCustomerNumber}
          </p>
          )}
    </div>
  );
};

QuotedMessageName.propTypes = {
  channelId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default QuotedMessageName;
