/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../constants/AsyncState';
import fetchProcessDetails from './processesThunks/fetchProcessDetails';
import fetchProcessesFilterSuggestions from './processesThunks/fetchProcessesFilterSuggestions';
import fetchProcesses from './processesThunks/fetchProcesses';
import suggestionsReset from './processesActions/suggestionsReset';
import { getErrorMsg } from '../../helper/errorMessages';

const asyncState = {
  fetch: {
    status: ASYNC_STATE.IDLE,
    error: null,
    errorMessage: null,
  },
  search: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  processDetailsFetch: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

// Note: State is legacy
const initialState = {
  ...asyncState,
  processes: {},
  opConnectionStates: {},
  hasMore: true,
  message: '',
  searchInput: '',
  filter: null,
  page: 0,
  suggestions: [],
  processDetails: {},
  selectedProcess: null,
  selectedProduct: null,
};

const persistWhitelist = [
  'processes',
  'hasMore',
  'message',
];

const processesSlice = createSlice({
  name: 'processes',
  initialState,
  reducers: {
    processesReset: (state, action) => {
      const { channelId } = action.payload;
      state.processes[channelId] = [];
    },
    resetProcessFilter: (state) => {
      state.filter = null;
      state.searchInput = '';
    },
    suggestionSelected: (state, action) => {
      const { suggestion, channelId } = action.payload;
      state.searchInput = '';
      state.suggestions = [];
      state.processes[channelId] = [];
      state.filter = suggestion;
    },
    searchInputUpdated: (state, action) => {
      const { searchInput } = action.payload;
      state.searchInput = searchInput;
    },
    processSelected: (state, action) => {
      const { selectedProcess } = action.payload;
      state.selectedProcess = selectedProcess;
    },
    productSelected: (state, action) => {
      const { selectedProduct } = action.payload;
      state.selectedProduct = selectedProduct;
    },
    opConnectionStateCleared: (state, action) => {
      const { channelId } = action.payload;
      delete state.opConnectionStates[channelId];
    },
  },
  extraReducers: {
    [suggestionsReset]: (state) => {
      state.suggestions = [];
    },
    // FETCH
    [fetchProcesses.pending]: (state) => {
      state.fetch.status = ASYNC_STATE.LOADING;
    },
    [fetchProcesses.fulfilled]: (state, action) => {
      const {
        response,
        channelId,
        append,
      } = action.payload;
      state.fetch.status = ASYNC_STATE.SUCCEEDED;
      if (!state.processes[channelId] || !append) {
        state.processes[channelId] = [];
      }
      state.message = null;
      if (response) {
        state.processes[channelId].push(...response);
        state.hasMore = true;
      }
      state.opConnectionStates[channelId] = {
        ok: true,
        isStale: state.processes[channelId].some(p => p.stale),
      };
      if (response && response.length === 0) {
        state.hasMore = false;
      }
      state.fetch.error = null;
      state.fetch.errorMessage = null;
    },
    [fetchProcesses.rejected]: (state, action) => {
      const { errorCode, errorMessage, channelId } = action.payload;
      state.fetch.status = ASYNC_STATE.FAILED;
      state.fetch.error = errorCode;
      if (errorMessage) {
        state.fetch.errorMessage = errorMessage;
      } else {
        state.fetch.errorMessage = getErrorMsg('get_processes.default');
      }
      state.opConnectionStates[channelId] = {
        ok: false,
        errorCode,
        errorMessage: state.fetch.errorMessage,
      };
    },
    // SEARCH PROCESSES
    [fetchProcessesFilterSuggestions.pending]: (state) => {
      state.search.status = ASYNC_STATE.LOADING;
    },
    [fetchProcessesFilterSuggestions.fulfilled]: (state, action) => {
      const { suggestions } = action.payload;
      state.search.status = ASYNC_STATE.SUCCEEDED;
      state.suggestions = suggestions;
    },
    [fetchProcessesFilterSuggestions.rejected]: (state, action) => {
      state.search.status = ASYNC_STATE.FAILED;
      state.search.error = action.error;
    },
    // PROCESS DETAILS
    [fetchProcessDetails.pending]: (state) => {
      state.processDetailsFetch.status = ASYNC_STATE.LOADING;
    },
    [fetchProcessDetails.fulfilled]: (state, action) => {
      const { Number: processNumber, Suffix: processSuffix } = action.meta.arg;
      const key = `${processNumber}-${processSuffix}`;
      const { processDetails } = action.payload;
      state.processDetailsFetch.status = ASYNC_STATE.SUCCEEDED;
      state.processDetails[key] = processDetails;
    },
    [fetchProcessDetails.rejected]: (state, action) => {
      state.processDetailsFetch.status = ASYNC_STATE.FAILED;
      state.processDetailsFetch.error = action.error;
      state.processDetails = null;
    },
  },
});

export { persistWhitelist as processesPersistWhitelist };

export const {
  processesReset,
  suggestionSelected,
  productSelected,
  searchInputUpdated,
  processSelected,
  resetProcessFilter,
  opConnectionStateCleared,
} = processesSlice.actions;

export default processesSlice.reducer;
