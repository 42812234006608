import { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { stringValidator } from 'gcs-common/helper/yupValidationHelper';
import { createTeam, getTeamForUsers } from 'gcs-common/slices/administration/teams/teamsThunks';
import { getCreateTeamErrorMessage, getCreateTeamDefaultErrorMessage } from 'gcs-common/slices/administration/teams/teamsSelectors';
import { resetCreateTeamErrorMessage, resetCreateTeamDefaultErrorMessage } from 'gcs-common/slices/administration/teams/teamsSlice';
import USER_TYPE from 'gcs-common/helper/userTypeHelper';
import {
  CustomerResponsibilityMemberTypes,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersConstants';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import styles from './styles.module.scss';
import FormikInput from '../../../FormikInput/FormikInput';
import Button from '../../../Button/Button';
import FormikUserSelect from '../../shared/FormikUserSelect/FormikUserSelect';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import CustomerResponsibilityRoleSelect
  from '../../shared/CustomerResponsibilityRoleSelect/CustomerResponsibilityRoleSelect';

export const TeamCreateValidationSchema = Yup.object().shape({
  name: stringValidator,
  userIds: Yup.array().of(Yup.string()),
  roleId: Yup.string().uuid().nullable(),
});

const initialData = {
  name: '',
  userIds: undefined,
  roleId: undefined,
};

const TeamCreate = ({ onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateTeamErrorMessage);
  const defaultErrorMessage = useSelector(getCreateTeamDefaultErrorMessage);
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetCreateTeamDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateTeamErrorMessage());
  }, [dispatch]);

  const resetErrorOnChange = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateTeamErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const onUserIdsChanged = useCallback(async (userIds) => {
    setWarningMessage('');
    if (userIds.length) {
      const result = await dispatch(getTeamForUsers({ userIds }));
      if (Object.keys(result).length > 0) {
        setWarningMessage('Es wurde bereits ein Team mit den selben Teilnehmern erstellt. Klicken Sie auf "Speichern", wenn Sie das neue Team erstellen möchten.');
      }
    }
  }, [dispatch]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(createTeam({ team: values }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={TeamCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.column}>

          <div className={styles.row}>
            <FormikInput label="Name*" name="name" onKeyUp={resetErrorOnChange} />
          </div>

          {errorMessage === getErrorMsg('create_team.name_exists')
            && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.row}>

            <FormikUserSelect
              userType={USER_TYPE.AGENT}
              key="teamCreateAgentSelect"
              selectKey="teamCreateAgentSelect"
              label="Mitarbeiter"
              name="userIds"
              labelKey="friendlyName"
              valueKey="id"
              isMulti
              onChange={onUserIdsChanged}
            />

          </div>

          {warningMessage && <div className={styles.warningMessage}>{warningMessage}</div>}

          <div className={styles.row}>
            <CustomerResponsibilityRoleSelect
              roleType={CustomerResponsibilityMemberTypes.TEAM}
              label="Beteiligtenrolle"
              labelKey="friendlyName"
              valueKey="id"
              name="roleId"
              onChange={resetErrorOnChange}
            />
          </div>

          {errorMessage === getErrorMsg('create_team.agent_belong_to_another_team_with_selected_role')
           && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

TeamCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TeamCreate;
