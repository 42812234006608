import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import { fetchTeams } from 'gcs-common/slices/administration/teams/teamsThunks';
import { getTeams, getTeamsPaginationResult } from 'gcs-common/slices/administration/teams/teamsSelectors';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import detailsIcon from '../../../../img/arrow.svg';
import deletedIcon from '../../../../img/delete.svg';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addCompanyIcon from '../../../../img/plus_white.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import TeamDelete from '../TeamDelete/TeamDelete';
import TeamCreate from '../TeamCreate/TeamCreate';
import MultiProfileImage from '../../../MultiProfileImage/MultiProfileImage';
import { SORT_ORDER } from '../../../../constants/sortConstants';
import styles from './styles.module.scss';
import teamIcon from '../../../../img/team-icon.svg';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Teilnehmer',
    accessor: 'userCount',
  },
  {
    Header: 'Typ',
    id: 'type',
    styles: {
      paddingRight: '0',
      paddingLeft: '0',
    },
    accessor: d => {
      return (
        <div className={d.customerResponsibilityRole
          ? styles.cellDivTypeTeam : null}
        >
          {d.customerResponsibilityRole ? <img src={teamIcon} alt={d.type} /> : null}
        </div>
      );
    },
    disableSortBy: true,
  },
  {
    Header: 'Beteiligtenrolle',
    id: 'roleName',
    accessor: d => {
      return (
        <div className={d.customerResponsibilityRole ? styles.cellDivRoleTeam : null}>
          {d.customerResponsibilityRole ? d.customerResponsibilityRole.friendlyName : ''}
        </div>
      );
    },
    styles: {
      paddingLeft: '0',
    },
  },
  {
    Header: 'Team',
    accessor: 'members',
    Cell: ({ value }) => {
      return MultiProfileImage(
        {
          images: value.map(user => (user ? user.imageUrl : undefined)),
          count: 8,
        },
      );
    },
    disableSortBy: true,
  },
  {
    Header: 'Erstellt von',
    accessor: 'createdBy',
  },
  {
    Header: 'Geändert am',
    accessor: 'createdAt',
  },
];

const TeamTable = ({
  onTeamSelected, teamsLoading,
}) => {

  const teams = useSelector(getTeams);
  const teamsPagination = useSelector(getTeamsPaginationResult);
  const globalSearchInput = useSelector(getGlobalSearchInput);

  const dispatch = useDispatch();

  const [createTeam, setCreateTeam] = useState(false);
  const [deleteTeam, setDeleteTeam] = useState(null);

  const accessorToBeSorted = columns.find(element => element.Header).accessor;
  const [sortedColumnId, setSortedColumnId] = useState(accessorToBeSorted);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);

  const loadTeams = useCallback((pageIndex, pageSize, searchQuery) => {
    dispatch(fetchTeams({
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
    }));
  }, [dispatch, sortOrder, sortedColumnId]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setDeleteTeam(original);
    },
    [],
  );

  const teamIsDeletedSuccessfully = useCallback(() => {
    setDeleteTeam(null);
    dispatch(showSuccessNotification('Erfolgreich entfernt'));
  }, [dispatch]);

  const teamIsCreatedSuccessfully = useCallback(() => {
    setCreateTeam(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  return (
    <>
      <PaginatedTable
        onRowSelected={onTeamSelected}
        data={teams}
        columns={columns}
        onPaginationChanged={loadTeams}
        searchInput={globalSearchInput}
        pagination={teamsPagination}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortedColumnId={sortedColumnId}
        setSortedColumnId={setSortedColumnId}
        sortIsLoading={teamsLoading}
        renderActions={({ row }) => (
          <>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              alt="deleted-icon"
              src={deletedIcon}
              onClick={(event) => deleteSelected(event, row)}
            />
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      <AddFabButton
        icon={addCompanyIcon}
        title="Team"
        onClick={() => setCreateTeam(true)}
      />
      <PopUpModal
        title="Neues Team"
        onRequestClose={() => setCreateTeam(false)}
        isOpen={createTeam}
      >
        <TeamCreate
          onCancel={() => setCreateTeam(false)}
          onSuccess={teamIsCreatedSuccessfully}
        />
      </PopUpModal>
      {deleteTeam && (
        <PopUpModal
          title="Team löschen"
          onRequestClose={() => setDeleteTeam(null)}
          isOpen={!!deleteTeam}
        >
          <TeamDelete
            team={deleteTeam}
            onCancel={() => setDeleteTeam(null)}
            onSuccess={teamIsDeletedSuccessfully}
          />
        </PopUpModal>
      )}
    </>
  );
};

TeamTable.propTypes = {
  onTeamSelected: PropTypes.func.isRequired,
  teamsLoading: PropTypes.bool.isRequired,
};

export default TeamTable;
