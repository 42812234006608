import { Virtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import { getForwardChannelIds, getForwardSearchInput } from 'gcs-common/slices/channelList/channelListSelectors';
import { setForwardSearchInput } from 'gcs-common/slices/channelList/channelListSlice';
import styles from './styles.module.scss';
import ForwardChannelListItem from '../ForwardChannelListItem/ForwardChannelListItem';
import ForwardChannelListHeader from '../ForwardChannelListHeader/ForwardChannelListHeader';
import ChannelSearchInput from '../AgentChannelList/ChannelSearchInput/ChannelSearchInput';

const ForwardChannelList = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const forwardChannelIds = useSelector(getForwardChannelIds(selectedChannelId));
  const forwardSearchInput = useSelector(getForwardSearchInput);

  return (
    <div className={styles.forwardMessageWrapper}>
      <ForwardChannelListHeader />
      <ChannelSearchInput type="forwardSearchInput" getSearchInput={getForwardSearchInput} setSearchInput={setForwardSearchInput} light />
      {(!forwardSearchInput || forwardSearchInput.length === 0) && (
      <div className={styles.title}>
        LETZTE CHATS
      </div>
      )}
      <div className={styles.channelListWrapper}>
        <Virtuoso
          data={forwardChannelIds}
          itemContent={(_, channelId) => (
            <ForwardChannelListItem channelId={channelId} />
          )}
        />
      </div>
      <div className={styles.whitespacePadding} />
    </div>
  );
};

export default ForwardChannelList;
