import { selectedHouseIdChanged } from '../housesSlice';
import { getSelectedChannelId } from '../../channels/channelsSelectors';
import changeSelectedChannelId from '../../channels/channelsThunks/changeSelectedChannelId';
import { getFirstChannelForHouseId } from '../../channelList/channelListSelectors';
import { resetNewChannelOptions } from '../../newChannelOptions/newChannelOptionsSlice';

const changeSelectedHouseId = ({ selectedHouseId }) => async (
  dispatch, getState,
) => {
  const selectedChannelId = getSelectedChannelId(getState());
  const nextChannel = getFirstChannelForHouseId(selectedHouseId)(getState());

  if (nextChannel && nextChannel !== selectedChannelId) {
    dispatch(changeSelectedChannelId({ selectedChannelId: nextChannel }));
  }

  dispatch(selectedHouseIdChanged({ selectedHouseId }));
  dispatch(resetNewChannelOptions());
};

export default changeSelectedHouseId;
