import { channelsAdded } from '../channelsSlice';
import changeSelectedChannelToPreselectedChannel from './changeSelectedChannelToPreselected';
import fetchCurrentUserChannels from './fetchCurrentUserChannels';

const handleChannelJoined = ({ channel }) => async (dispatch) => {
  const channelId = channel.id;
  dispatch(channelsAdded({ channels: [channel] }));

  dispatch(fetchCurrentUserChannels({ specificChannelId: channelId }));

  dispatch(changeSelectedChannelToPreselectedChannel({ channelId }));
};

export default handleChannelJoined;
