import Modal from 'react-modal';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface PopUpModalProps {
  zIndex?: number;
  isOpen: boolean;
  title: ReactNode | string;
  onRequestClose: VoidFunction;
  className?: string;
  children: ReactNode;
}

const PopUpModal = (props: PopUpModalProps) => {
  const {
    title, children, isOpen, onRequestClose, className = '', zIndex = 2,
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Detail Modal"
      className={`${styles.modal} ${className || ''}`}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex,
        },
      }}
    >
      <div className={styles.content} data-testid="popupmodal">
        {title && (<div className={styles.header}>{title}</div>)}
        {children}
      </div>
    </Modal>
  );
};

export default PopUpModal;
