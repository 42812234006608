export const TOOL_TABS = {
  FAVORITES: 'FAVORITES',
  TODOS: 'TODOS',
  GCOP: 'GCOP',
  SEARCH: 'SEARCH',
  FILES: 'FILES',
  TICKETS: 'TICKETS',
  ORDERS: 'ORDERS',
};

export const TOOLS = [
  {
    name: TOOL_TABS.FAVORITES,
    altIcon: 'favourite-select-icon',
  },
  {
    name: TOOL_TABS.TODOS,
    altIcon: 'todo-select-icon',
  },
  {
    name: TOOL_TABS.FILES,
    altIcon: 'files-select-icon',
  },
  {
    name: TOOL_TABS.SEARCH,
    altIcon: 'search-select-icon',
  },
  {
    name: TOOL_TABS.GCOP,
    altIcon: 'gcop-select-icon',
  },
  {
    name: TOOL_TABS.TICKETS,
    altIcon: 'tickets-select-icon',
  },
  {
    name: TOOL_TABS.ORDERS,
    altIcon: 'orders-select-icon',
  },
];
