import { Virtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';
import { getDisplayChannelsIds } from 'gcs-common/slices/channelList/channelListSelectors/getDisplayChannelsIds';
import React from 'react';
import styles from './styles.module.scss';
import ChannelListItemWithDateTag
  from '../../ChannelListItemWithDateTag/ChannelListItemWithDateTag';

// Note: To style the list a forwardRef must be passed
const List = React.forwardRef((props, ref) => (
  <div {...props} ref={ref} className={styles.listWrapper} />
));

const Footer = () => <div className={styles.listFooter} />;
const Item = (props) => <div {...props} className={styles.itemWrapper} />;

const AgentChannelList = () => {
  const channelIds = useSelector(getDisplayChannelsIds);
  return (
    <div className={styles.channelList}>
      <Virtuoso
        data={channelIds}
        itemContent={(index) => (
          <ChannelListItemWithDateTag
            channelId={channelIds[index]}
          />
        )}
        components={{
          Footer,
          List,
          Item,
        }}
      />
    </div>
  );
};

export default AgentChannelList;
