/* eslint-disable max-len */
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { toIsLoading, toIsLoadingOrIdle, toHasSucceeded } from '../../helper/reduxLoadingStatusHelper';

const getTicketsState = state => state.tickets || {};

export const getTickets = (channelId) => createSelector(
  [getTicketsState],
  (ticketsState) => {
    if (channelId) {
      return (ticketsState.channelTickets[channelId || null] ?? []).map(ticketId => ticketsState.tickets[ticketId]);
    }

    return Object.values(ticketsState.tickets);
  },
);
export const getTicketsLoading = (channelId) => createSelector(
  [getTicketsState],
  ticketsState => toIsLoadingOrIdle(ticketsState.fetchTickets[channelId || null]),
);

const getTicketViewState = (channelId) => createSelector(
  [getTicketsState],
  (ticketsState) => ticketsState.ticketView[channelId || null],
);
export const getTicketView = (channelId) => createSelector(
  [getTicketViewState(channelId)],
  (ticketViewState) => ticketViewState?.view,
);
export const getTicketFormDraft = (channelOrTicketId) => createSelector(
  [getTicketsState],
  (ticketsState) => ticketsState.drafts[channelOrTicketId || null],
);
export const getChannelTicketFormPrefill = (channelId) => createSelector(
  [getTicketViewState(channelId)],
  (ticketViewState) => ticketViewState?.prefill,
);
export const getSelectedTicket = (channelId) => createSelector(
  [
    getTicketViewState(channelId),
    getTicketsState,
  ],
  (ticketViewState, ticketsState) => ticketsState.tickets[ticketViewState?.ticketId],
);

export const getSelectedTicketByTicketId = (ticketId) => createSelector(
  [getTicketsState],
  (ticketsState) => ticketsState.tickets[ticketId],
);

export const getTicketParts = ticketId => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.ticketParts[ticketId],
);
export const getTicketPartsLoading = ticketId => createSelector(
  [getTicketsState],
  ticketsState => toIsLoadingOrIdle(ticketsState.fetchTicketParts[ticketId]),
);

export const getTicketAttachments = ticketId => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.ticketAttachments[ticketId],
);
export const getTicketAttachmentsLoading = ticketId => createSelector(
  [getTicketsState],
  ticketsState => toIsLoadingOrIdle(ticketsState.fetchTicketAttachments[ticketId]),
);

export const getTicketComments = ticketId => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.ticketComments[ticketId],
);
export const getTicketCommentsLoading = ticketId => createSelector(
  [getTicketsState],
  ticketsState => toIsLoadingOrIdle(ticketsState.fetchTicketComments[ticketId]),
);

export const getCreateTicketLoading = createSelector(
  [getTicketsState],
  ticketsState => toIsLoading(ticketsState.createTicket),
);

export const getUploadTicketAttachmentsLoading = createSelector(
  [getTicketsState],
  ticketsState => toIsLoading(ticketsState.uploadTicketAttachments),
);

export const getAddTicketCommentLoading = createSelector(
  [getTicketsState],
  ticketsState => toIsLoading(ticketsState.addTicketComment),
);

export const getTicketNotifications = () => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.ticketNotifications,
);

export const getTicketNotificationsForChannel = (channelId) => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.channelNotifications[channelId] ?? [],
);

export const getTicketNotificationsCount = () => createSelector(
  [getTicketsState],
  ticketsState => Object.keys(ticketsState.ticketNotifications).length,
);

export const getTicketNotificationsCountForChannel = channelId => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.channelNotifications[channelId]?.length ?? 0,
);

export const getTicketNotification = ticketId => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.ticketNotifications[ticketId],
);

export const getChannelNotificationDates = createSelector(
  [getTicketsState],
  ticketState => {
    const channelNotificationDate = {};
    if (ticketState.ticketNotifications) {
      Object.values(ticketState.ticketNotifications).forEach(notification => {
        const { channelId, updatedAt } = notification;
        if (channelNotificationDate[channelId]) {
          const latestDate = moment.max(moment(channelNotificationDate[channelId]), moment(updatedAt));
          channelNotificationDate[channelId] = latestDate.toISOString();
        } else {
          channelNotificationDate[channelId] = updatedAt;
        }
      });
    }
    return channelNotificationDate;
  },
);

export const getTicketRating = ticketId => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.ticketRating[ticketId],
);

export const getDashboardPaginatedTickets = () => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.dashboard.data,
);

export const getDashboardPaginatedTicketsIsLoadingOrIdle = () => createSelector(
  [getTicketsState],
  ticketsState => toIsLoadingOrIdle(ticketsState.fetchTicketsForDashboard),
);

export const getDashboardPagination = () => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.dashboard.pagination,
);

export const getDasboardTicketsStatusCounts = () => createSelector(
  [getTicketsState],
  ticketsState => ticketsState.dashboard.status,
);

export const getDashboardSelectedTicketId = () => createSelector(
  [getTicketsState],
  (ticketsState) => ticketsState.dashboard.selectedTicketId,
);

export const getDashboardSelectedTicket = () => createSelector(
  [
    getDashboardPaginatedTickets(),
    getDashboardSelectedTicketId(),
  ],
  (paginatedTickets, ticketId) => paginatedTickets.find(ticket => ticket.id === ticketId),
);

export const getDashboardTicketsCreators = () => createSelector(
  [getTicketsState],
  ticketState => ticketState.dashboard.creators,
);

export const getCreatedTicketSucceededStatus = () => createSelector(
  [getTicketsState],
  ticketsState => toHasSucceeded(ticketsState.createTicket),
);

export const getTicketFirstTimeView = (channelId) => createSelector(
  [getTicketViewState(channelId)],
  (ticketViewState) => ticketViewState?.firstTime,
);

export const getTicketMessages = (ticketId, currentUserId) => createSelector(
  [
    getSelectedTicketByTicketId(ticketId),
    getTicketNotification(ticketId),
    getTicketComments(ticketId),
    getTicketAttachments(ticketId),
    getTicketParts(ticketId),
  ],
  (ticket, ticketNotification, comments, attachments, parts) => {
    if (!ticket || !comments || !attachments || !parts) return [];

    const result = [
      {
        type: 'comment',
        createdAt: ticket.createdAt,
        authorId: currentUserId,
        html: ticket.description,
      },
    ];

    result.push(
      ...comments.filter(c => !c.isBot).map(comment => ({
        type: 'comment',
        ...comment,
      })),
    );

    result.push(
      ...comments.filter(c => c.isBot).map(comment => ({
        type: 'botMessage',
        ...comment,
      })),
    );

    result.push(
      ...attachments.map(attachment => ({
        type: 'media',
        ...attachment,
      })),
    );

    if (ticketNotification) {
      // last date that the user interact with the ticket. Weonly display the dividir
      // before comments that are not from the user, and we stack them behind the divider
      // depending on its creation date
      const unreadIndicatorDate = new Date(
        Math.max(
          new Date(ticketNotification.lastViewedAt),
          ...result.filter(x => x.fromBeem && !x.isBot).map(x => new Date(x.createdAt)),
        ),
      ).toISOString();

      result.push({
        type: 'newMessagesIndicator',
        createdAt: moment(unreadIndicatorDate).toISOString(),
      });
    }

    if (ticket.status === 'done') {
      result.push(
        ...[...(parts.parts || []), ...(parts.alternatives || [])].map(part => ({
          type: 'part',
          ...part,
        })),
      );
    }
    return orderBy(result, x => new Date(x.createdAt), 'asc');
  },
);

export const getTicketMessagesLoading = (ticketId) => createSelector(
  [
    getTicketCommentsLoading(ticketId),
    getTicketAttachmentsLoading(ticketId),
    getTicketPartsLoading(ticketId),
  ],
  (commentsLoading, attachmentsLoading, partsLoading) => {
    return commentsLoading || attachmentsLoading || partsLoading;
  },
);
