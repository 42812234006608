import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const TextInput = ({
  type = 'text',
  className = '',
  field = {},
  ...otherProps
}) => {
  return (
    <input
      type={type}
      className={`${styles.textInput} ${className}`}
      {...field}
      {...otherProps}
    />
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object,
};

export default TextInput;
