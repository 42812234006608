/* eslint-disable jsx-a11y/no-autofocus */
import {
  ChangeEventHandler, FC, KeyboardEventHandler, RefObject, useState,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import useOnClickOutside from 'gcs-common/hooks/useOnClickOutside';
import styles from './styles.module.scss';

interface TaskEditProps {
  parentRef: RefObject<HTMLDivElement>,
  handleSubmit: (arg0: string) => void,
  currText: string,
  onMouseOver: VoidFunction,
  onMouseOut: VoidFunction,
}

const TaskEdit: FC<TaskEditProps> = ({
  parentRef, handleSubmit, currText, onMouseOver, onMouseOut,
}) => {
  const [text, setText] = useState(currText);

  // we want to enclose the full task box for the innerRef,
  // not only the text
  useOnClickOutside(parentRef, () => {
    handleSubmit(text);
  });

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = e => setText(e.target.value);

  const handleKeys: KeyboardEventHandler<HTMLTextAreaElement> = e => {
    if (e.key === 'Enter') {
      handleSubmit(text);
    }
  };

  return (
    <div
      className={styles.taskContent}
    >
      <TextareaAutosize
        className={styles.textArea}
        autoFocus
        value={text}
        maxRows={20}
        onKeyPress={handleKeys}
        onChange={handleChange}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </div>
  );
};

export default TaskEdit;
