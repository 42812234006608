import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getErpCustomerUsers,
  getErpCustomerUsersLoading,
  getDeleteErpCustomerUserErrorMessage,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import { deleteErpCustomerUser, fetchErpCustomerUsers } from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import { resetDeleteErpCustomerUserErrorMessage } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import Table from '../../../Table/Table';
import ProfileImageCell from '../../../Table/ProfileImageCell/ProfileImageCell';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addIcon from '../../../../img/plus_white.svg';
import deletedIcon from '../../../../img/delete.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import { showFailureNotification, showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import ErpCustomerUserCreate from '../ErpCustomerUserCreate/ErpCustomerUserCreate';
import { stringSortingFunction } from '../../../../helper/sortHelper';
import ErpCustomerUserDelete from './ErpCustomerUserDelete/ErpCustomerUserDelete';
import { Spinner } from '../../../Spinner/Spinner';

const userColumns = [
  {
    Header: 'Profilbild',
    accessor: 'imageUrl',
    Cell: ProfileImageCell,
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'friendlyName',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Angegebener Firmenname',
    accessor: 'customerCompany',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Telefonnummer',
    accessor: 'phoneNumber',
    filter: 'fuzzyText',
  },
  {
    Header: 'Email',
    accessor: 'email',
    filter: 'fuzzyText',
  },
  {
    Header: 'Datenschutz akzeptiert',
    accessor: 'privacyAccepted',
  },
];

const ErpCustomerUserTable = ({ erpCustomerId, searchInputValue = '' }) => {
  const erpCustomerUsers = useSelector(getErpCustomerUsers);
  const erpCustomerUsersLoading = useSelector(getErpCustomerUsersLoading);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showUserCreate, setShowUserCreate] = useState(false);
  const defaultErrorMessage = useSelector(getDeleteErpCustomerUserErrorMessage);
  const [erpCustomerUserToDelete, setErpCustomerUserToDelete] = useState(null);
  const [userIdsToDelete, setUserIdsToDelete] = useState([]);

  useEffect(() => {
    if (erpCustomerId) {
      dispatch(fetchErpCustomerUsers({ erpCustomerId }));
    }
  }, [erpCustomerId, dispatch]);

  const erpCustomerUserCreated = useCallback(
    () => {
      setShowUserCreate(false);
      dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
    },
    [dispatch],
  );

  const closeDeleteModal = useCallback(
    () => {
      setErpCustomerUserToDelete(null);
      setShowDeleteModal(false);
    },
    [],
  );

  const removeFromUserIdsToDelete = useCallback((userId) => {
    const filteredUserIds = userIdsToDelete.filter(id => id !== userId);
    setUserIdsToDelete(filteredUserIds);
  }, [userIdsToDelete]);

  const addToUserIdsToDelete = useCallback((userId) => {
    const userIds = [...userIdsToDelete, userId];
    setUserIdsToDelete(userIds);
  }, [userIdsToDelete]);

  const handleDelete = useCallback(
    async () => {
      const userId = erpCustomerUserToDelete?.id;
      addToUserIdsToDelete(userId);
      setShowDeleteModal(null);
      const result = await dispatch(deleteErpCustomerUser({
        userId, erpCustomerId,
      }));
      removeFromUserIdsToDelete(userId);
      setErpCustomerUserToDelete(null);
      if (!result.error) {
        dispatch(showSuccessNotification('Erfolgreich entfernt'));
      }
    },
    [erpCustomerUserToDelete,
      addToUserIdsToDelete,
      dispatch,
      erpCustomerId,
      removeFromUserIdsToDelete],
  );

  const deleteSelected = useCallback((event, rowData) => {
    event.stopPropagation();
    const { original } = rowData;
    setErpCustomerUserToDelete(original);
    setShowDeleteModal(true);
  }, []);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetDeleteErpCustomerUserErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  const extendedErpCustomerUsers = useMemo(() => {
    return erpCustomerUsers.map(user => {

      if (userIdsToDelete.includes(user.id)) {
        return { ...user, isDeleting: true };
      }
      return user;
    });
  }, [erpCustomerUsers, userIdsToDelete]);

  return (
    <>
      <Table
        isLoading={erpCustomerUsersLoading}
        data={extendedErpCustomerUsers}
        columns={userColumns}
        filterString={searchInputValue}
        renderActions={({ row }) => (
          <>
            {
              row.original.isDeleting ? (<Spinner />) : (
                <button type="button">
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    alt="deleted-icon"
                    src={deletedIcon}
                    onClick={(event) => deleteSelected(event, row)}
                  />
                </button>
              )
            }
          </>
        )
        }
      />
      <AddFabButton title="Nutzer" icon={addIcon} onClick={() => setShowUserCreate(true)} />
      {showUserCreate && (
        <PopUpModal
          title="Neuer nutzer"
          onRequestClose={() => setShowUserCreate(false)}
          isOpen
        >
          <ErpCustomerUserCreate
            erpCustomerId={erpCustomerId}
            onCancel={() => setShowUserCreate(false)}
            onSuccess={erpCustomerUserCreated}
          />
        </PopUpModal>
      )}
      {showDeleteModal && (
        <PopUpModal
          title="NUTZER löschen"
          onRequestClose={closeDeleteModal}
          isOpen
        >
          <ErpCustomerUserDelete
            erpCustomerUser={erpCustomerUserToDelete}
            onCancel={closeDeleteModal}
            handleDelete={handleDelete}
          />
        </PopUpModal>
      )
      }
    </>
  );
};

ErpCustomerUserTable.propTypes = {
  searchInputValue: PropTypes.string,
  erpCustomerId: PropTypes.string.isRequired,
};

export default ErpCustomerUserTable;
