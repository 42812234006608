import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLeftBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { LEFT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { setGroupChat } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';

const NewChatGroupItem = () => {
  const dispatch = useDispatch();
  const isAgent = useSelector(getIsAgent);

  const [isSelected, setIsSelected] = useState(false);

  // This will add the background then redirect to the new group chat page after 500ms
  // so the user can see the background before the redirect and it's consistent
  // with other channels/items buttons
  const handleClick = useCallback(() => {
    setIsSelected(true);

    setTimeout(() => {
      dispatch(setGroupChat({ groupChat: true }));
      dispatch(setLeftBarTab({ leftBarTab: LEFT_BAR_TABS.NEW_GROUP_CHAT }));
    }, 200);
  }, [dispatch]);

  return (
    <button
      className={`${styles.newGroupWrapper} ${isAgent ? styles.agentStyle : ''} ${isSelected ? styles.selected : ''}`.trim()}
      onClick={handleClick}
      type="button"
    >
      <div className={styles.groupChatIcon}>
        <IconComponent Icon={ICON.GROUP} color={ICON_COLOR.BLACK} size={ICON_SIZE.SMALL} />
      </div>

      <span className={styles.newGroupHeadline}>Neue Gruppe erstellen</span>

      <div className={styles.newChatNext} alt="Nächste">
        <IconComponent Icon={ICON.ARROW} />
      </div>
    </button>
  );
};

export default NewChatGroupItem;
