import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalUnreadCount } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import useTicketNotifications from 'gcs-common/hooks/useTicketNotifications';
import useChannelInitialization from 'gcs-common/hooks/useChannelInitialization';
import useMsTeamsClient from 'gcs-common/hooks/useMsTeamsClient';
import changeSelectedChannelId from 'gcs-common/slices/channels/channelsThunks/changeSelectedChannelId';
import ChatWorkspace from '../ChatWorkspace/ChatWorkspace';
import ChatProtectedRoute from '../ProtectedRoutes/ChatProtectedRoute';

/*
 This component handles chat initialization logic
 */
function ChatRoot() {
  const unreadMessages = useSelector(getTotalUnreadCount);
  const dispatch = useDispatch();
  const { getContext } = useMsTeamsClient();
  useEffect(() => {
    // set App Badge on installed PWA, currently available on Chrome and Edge
    if ('setAppBadge' in navigator) {
      navigator.setAppBadge(unreadMessages);
    }

    getContext().then(context => {
      // eslint-disable-next-line no-console
      console.log('####context', context.page.subPageId);
      const channelId = context.page.subPageId;
      if (channelId) {
        dispatch(changeSelectedChannelId({ selectedChannelId: channelId }));
      }
    });
  }, [dispatch, getContext, unreadMessages]);

  useTicketNotifications();
  useChannelInitialization();

  return (
    <ChatProtectedRoute>
      <ChatWorkspace />
    </ChatProtectedRoute>
  );
}

export default ChatRoot;
