export const assertNotUndefined = (...args) => {
  args.forEach(arg => {
    if (arg === undefined) {
      // Object.keys(arg)[0] prints the variable name
      // eslint-disable-next-line no-console
      console.error('Assert undefined check failed for variable');
      // eslint-disable-next-line no-console
      console.trace();
    }
  });
};
