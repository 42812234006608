import { Navigate } from 'react-router-dom';
import { LOGIN_ROUTES } from 'gcs-common/constants/routes';
import { useGetCurrentUserIsLoggedIn } from 'gcs-common/clients/api/entities/current-user/current-user-api';
import { PropsWithChildren } from 'react';

/**
 * Only render children when the user is logged in,
 * otherwise Navigate to LOGIN_ROUTES.LOGIN
 */
function LoggedInProtectedRoute({ children } : PropsWithChildren) {
  const { isSuccess } = useGetCurrentUserIsLoggedIn();

  if (!isSuccess) {
    return <Navigate to={LOGIN_ROUTES.LOGIN} />;
  }

  return children;
}

export default LoggedInProtectedRoute;
