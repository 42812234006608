/* eslint-disable no-param-reassign, import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { COLOR_MAP } from '../../constants/colorMap';
import { byId } from '../../helper/byKey';
import ASYNC_STATE from '../../constants/AsyncState';
import createChannel from './channelsThunks/createChannel';
import createSalesSpace from './channelsThunks/createSalesSpace';
import updateChannel from './channelsThunks/updateChannel';
import { toIds } from '../../helper/toKey';
import { loadTwilioChannels } from '../chatConnection/chatConnectionThunks/twilioLoadingThunk';
import { allChannelsFetched } from './channelsThunks/allChannelsFetched';
import mergeObjects from '../../helper/mergeObjects';
import { removeChannelIdsOfChannelsWithTypes, removeChannelsOfChannelsWithTypes } from './helper/channelsSliceHelper';

const asyncState = {
  fetchChannels: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  createChannel: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  createSalesSpace: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  updateChannel: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  loadTwilioChannels: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
};

const initialState = {
  ...asyncState,
  selectedChannelId: null,
  preSelectedChannelId: null, // will select this channel when channelJoined event is received
  channelColorMaps: {}, // { [channelId]: { [id]: "color" } }
  channelListChannelIds: [], // ids of all channels
  initialChannelsLoading: true,
  channels: {},
};

const persistWhitelist = [
  'selectedChannelId',
  'lockedChannelAttributes',
  'channelColorMaps',
  'channelListChannelIds',
  'initialChannelsLoading',
  'channels',
];

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    preSelectChannelId: (state, action) => {
      const { preSelectedChannelId } = action.payload;

      state.preSelectedChannelId = preSelectedChannelId;
    },
    selectedChannelIdChanged: (state, action) => {
      const { selectedChannelId } = action.payload;

      state.selectedChannelId = selectedChannelId;
    },
    initialChannelsLoadSucceeded: (state) => {
      state.initialChannelsLoading = false;
    },
    channelsAdded: (state, action) => {
      const { channels } = action.payload;
      state.channels = mergeObjects(state.channels, byId(channels));
      state.channelListChannelIds = [...new Set([
        ...state.channelListChannelIds,
        ...toIds(channels),
      ])];
    },
    channelUpdated: (state, action) => {
      const { channel, channel: { id } } = action.payload;
      state.channels[id] = mergeObjects(state.channels[id], channel);
    },
    channelRemoved: (state, action) => {
      const { channel } = action.payload;
      delete state.channels[channel.id];
      state.channelListChannelIds = state.channelListChannelIds.filter(id => id !== channel.id);
    },
    addUserToColorMap: (state, action) => {
      const { channelId, id } = action.payload;
      let oldColorMap = state.channelColorMaps[channelId];
      if (!oldColorMap) {
        state.channelColorMaps[channelId] = {};
        oldColorMap = {};
      }
      if (oldColorMap[id]) {
        return;
      }
      const numUsers = Object.keys(oldColorMap).length;
      state.channelColorMaps[channelId][id] = COLOR_MAP[numUsers % COLOR_MAP.length];
    },
    resetCreateChannelAsyncStatus: (state) => {
      state.createChannel = {
        status: ASYNC_STATE.IDLE,
        error: null,
      };
    },
    resetCreateSalesSpaceAsyncStatus: (state) => {
      state.createSalesSpace = {
        status: ASYNC_STATE.IDLE,
        error: null,
      };
    },
    resetPreSelectedChannelId: (state) => {
      state.preSelectedChannelId = null;
    },
  },
  extraReducers: {
    [allChannelsFetched.pending]: (state) => {
      state.fetchChannels.status = ASYNC_STATE.LOADING;
    },
    [allChannelsFetched.fulfilled]: (state, action) => {
      const { channels, replaceChannelTypes } = action.payload;
      state.fetchChannels.status = ASYNC_STATE.SUCCEEDED;

      if (replaceChannelTypes) {
        state.channelListChannelIds = removeChannelIdsOfChannelsWithTypes(
          state,
          replaceChannelTypes,
        );
        state.channels = removeChannelsOfChannelsWithTypes(
          state,
          replaceChannelTypes,
        );
      }

      state.channels = mergeObjects(state.channels, byId(channels));
      state.channelListChannelIds = [...new Set([
        ...state.channelListChannelIds,
        ...toIds(channels),
      ])];
    },
    [allChannelsFetched.rejected]: (state, action) => {
      state.fetchChannels.status = ASYNC_STATE.FAILED;
      state.error = action.error;
    },
    [updateChannel.pending]: (state) => {
      state.updateChannel.status = ASYNC_STATE.LOADING;
    },
    [updateChannel.fulfilled]: (state) => {
      state.updateChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateChannel.rejected]: (state, action) => {
      state.updateChannel.status = ASYNC_STATE.FAILED;
      state.updateChannel.error = action.error;
    },
    [createChannel.pending]: (state) => {
      state.createChannel.status = ASYNC_STATE.LOADING;
    },
    [createChannel.fulfilled]: (state) => {
      state.createChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [createChannel.rejected]: (state, action) => {
      state.createChannel.status = ASYNC_STATE.FAILED;
      state.createChannel.error = action.error;
    },
    [createSalesSpace.pending]: (state) => {
      state.createSalesSpace.status = ASYNC_STATE.LOADING;
    },
    [createSalesSpace.fulfilled]: (state) => {
      state.createSalesSpace.status = ASYNC_STATE.SUCCEEDED;
    },
    [createSalesSpace.rejected]: (state, action) => {
      state.createSalesSpace.status = ASYNC_STATE.FAILED;
      state.createSalesSpace.error = action.error;
    },
    [loadTwilioChannels.pending]: (state) => {
      state.loadTwilioChannels.status = ASYNC_STATE.LOADING;
    },
    [loadTwilioChannels.fulfilled]: (state, action) => {
      const channels = action.payload;
      state.loadTwilioChannels.status = ASYNC_STATE.SUCCEEDED;
      state.channels = mergeObjects(state.channels, byId(channels));
    },
    [loadTwilioChannels.rejected]: (state, action) => {
      state.loadTwilioChannels.status = ASYNC_STATE.FAILED;
      state.loadTwilioChannels.error = action.error;
    },
  },
});

export { persistWhitelist as channelsPersistWhitelist };
export const {
  selectedChannelIdChanged,
  preSelectChannelId,
  channelsAdded,
  channelRemoved,
  channelUpdated,
  addUserToColorMap,
  initialChannelsLoadSucceeded,
  resetCreateChannelAsyncStatus,
  resetCreateSalesSpaceAsyncStatus,
  resetPreSelectedChannelId,
} = channelsSlice.actions;

export default channelsSlice.reducer;
