import { useEffect, useState, useCallback, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const useGetFirstRowItemsLength = (ref) => {
  const [firstRowItemsLength, setFirstRowItemsLength] = useState(0);
  const isMounted = useRef(false);

  const calculateAndSetHiddenRolesNumber = useCallback(() => {
    if (isMounted.current && ref?.current) {
      const baseOffset = ref?.current?.offsetTop;
      const children = Array.from(ref?.current?.children || []);
      setFirstRowItemsLength(
        (children || []).filter(item => item?.offsetTop === baseOffset).length,
      );
    }
  }, [ref]);

  useResizeDetector({ targetRef: ref, onResize: calculateAndSetHiddenRolesNumber });

  useEffect(() => {
    isMounted.current = true;
    calculateAndSetHiddenRolesNumber();
    return () => {
      isMounted.current = false;
    };
  }, [calculateAndSetHiddenRolesNumber]);

  return firstRowItemsLength;
};

export default useGetFirstRowItemsLength;
