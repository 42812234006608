import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getMessageHasSyncPending,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
} from 'gcs-common/slices/messages/messagesSelectors';
import {
  getChannelLastDeliveredMessageIdFromMe,
  getChannelLastMessageIdReadAndFromMe,
  getChannelShouldShowReadReceipt,
} from 'gcs-common/slices/channel/channelSelectors';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import IconComponent from '../IconComponent/IconComponent';

const ReadNotification = ({ messageId }) => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const shouldShowReadReceipt = useSelector(getChannelShouldShowReadReceipt(
    selectedChannelId,
    messageId,
  ));
  const lastReadId = useSelector(getChannelLastMessageIdReadAndFromMe(selectedChannelId));
  const lastDeliveredId = useSelector(getChannelLastDeliveredMessageIdFromMe(selectedChannelId));
  const messageHasSyncPending = useSelector(getMessageHasSyncPending(messageId));

  const isLastRead = lastReadId === messageId;
  const isLastDelivered = lastDeliveredId === messageId;

  if (messageHasSyncPending) {
    return (
      <div className={styles.iconsContainer} data-testid="read-notification-component">
        <IconComponent
          Icon={ICON.CLOCK}
          size={ICON_SIZE.SMALL}
          alt="message-syncing-icon"
        />
      </div>
    );
  }

  if (!shouldShowReadReceipt || (!isLastRead && !isLastDelivered)) {
    return null;
  }

  return (
    <div className={styles.iconsContainer} data-testid="read-notification-component">
      <IconComponent
        Icon={ICON.CHECKMARK_DOUBLE}
        color={isLastRead ? ICON_COLOR.PRIMARY_BUTTON : ICON_COLOR.DARK_GREY}
        size={ICON_SIZE.SMALL}
        alt={isLastRead ? 'message-read-icon' : 'message-received-icon'}
      />
    </div>
  );
};

ReadNotification.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default ReadNotification;
