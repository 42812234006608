import { useState, useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSelectedHouseDetails,
} from 'gcs-common/slices/administration/houses/housesSelectors';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { updateSelectedHouse } from 'gcs-common/slices/administration/houses/housesThunks';
import { PROCESS_DATA_SOURCE } from 'gcs-common/constants/processDataSource';
import styles from './styles.module.scss';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import { CRM_IMPORT_OPTIONS } from '../../../../constants/crmImportConfiguration';
import EditIcon from '../../../../img/edit.svg?react';
import CheckIcon from '../../../../img/check_green.svg?react';
import CancelIcon from '../../../../img/close_red.svg?react';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const validationSchema = Yup.object().shape({
  processDataSource: Yup.string().nullable().required('Erforderlich'),
  crmImportConfiguration: Yup.string().nullable().required('Erforderlich'),
});

const HouseDataSource = () => {

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const selectedHouse = useSelector(getSelectedHouseDetails);
  const isGlobalAdmin = useSelector(getHasPermission('global_admin'));

  const startEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const { processDataSource } = selectedHouse || {};

  const { crmImportConfiguration } = selectedHouse || {};

  const initialValues = useMemo(() => {
    return ({
      processDataSource: processDataSource || '',
      crmImportConfiguration,
    });
  }, [processDataSource, crmImportConfiguration]);

  const cancelEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(false);
  }, []);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(updateSelectedHouse({ houseId: selectedHouse.id, values }));
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich geändert'));
      setIsEditing(false);
    }
    setSubmitting(false);
  }, [selectedHouse, dispatch]);

  return (
    <div className={styles.row}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submit}
        validateOnChange
      >
        {({
          isSubmitting, resetForm,
        }) => (
          <Form noValidate className={styles.fullwidth}>
            {isGlobalAdmin && (
            <div className={styles.bottomHeader}>
              <div className={styles.buttonsWrapper}>
                {isEditing ? (
                  <>
                    <button
                      className={styles.saveButton}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <CheckIcon />
                    </button>
                    <button
                      className={styles.saveButton}
                      type="button"
                      onClick={(event) => {
                        resetForm();
                        cancelEditing(event);
                      }}
                      disabled={isSubmitting}
                    >
                      <CancelIcon />
                    </button>
                  </>
                ) : (
                  <button
                    className={styles.saveButton}
                    type="button"
                    onClick={startEditing}
                  >
                    <EditIcon />
                  </button>
                )}
              </div>
            </div>
            )}
            <div className={styles.gaprow}>
              <FormikSelect
                label="Datenquelle für Vorgänge"
                options={PROCESS_DATA_SOURCE}
                labelKey="value"
                valueKey="key"
                disabled={!isEditing && !isGlobalAdmin}
                name="processDataSource"
              />
              <FormikSelect
                label="API-Konfiguration (CRM-import)"
                options={CRM_IMPORT_OPTIONS}
                labelKey="key"
                valueKey="value"
                disabled={!isEditing && !isGlobalAdmin}
                name="crmImportConfiguration"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default HouseDataSource;
