// eslint-disable-next-line  import/no-unresolved
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import merge from 'lodash/merge';

// Returns a merged array of two arrays of objects, based on their object IDs.
const mergeArrays = (currentStateArray, newDataArray) => {
  // Clone the current state to avoid direct mutation
  const mergedArray = cloneDeep(currentStateArray) || [];

  newDataArray.forEach(newItem => {
    const existingItemIndex = findIndex(mergedArray, { id: newItem.id });

    if (existingItemIndex !== -1) {
      mergedArray[existingItemIndex] = merge(mergedArray[existingItemIndex], newItem);
    } else {
      mergedArray.push(newItem);
    }
  });

  return mergedArray;
};

export default mergeArrays;
