import { useMemo } from 'react';
import { getNow, getTodayStart } from 'gcs-common/helper/dateHelper';
import Select, { StylesConfig } from 'react-select';
import { addDays } from 'date-fns';
import styles from './styles.module.scss';
// eslint-disable-next-line import/no-cycle
import { FiltersValues } from '../../TicketDashboard';

interface OptionType {
  label: string;
  value: {
    startDate: string;
    endDate: string
  }
}

interface DateFilterProps {
  setDateFilter: (filter: Partial<FiltersValues>) => void
}

const DateFilter = (props:DateFilterProps) => {
  const { setDateFilter } = props;

  const handleChange = (daterange: OptionType['value'] | undefined) => {
    setDateFilter(daterange || { startDate: null, endDate: null });
  };

  const options = useMemo(() => [
    {
      label: 'Heute',
      value: {
        startDate: getTodayStart().toISOString(),
        endDate: getNow().toISOString(),
      },
    },
    {
      label: 'Gestern',
      value: {
        startDate: addDays(getTodayStart(), -1).toISOString(),
        endDate: getTodayStart().toISOString(),
      },
    },
    {
      label: 'Letzte 7 Tage',
      value: {
        startDate: addDays(getNow(), -7).toISOString(),
        endDate: getNow().toISOString(),
      },
    },
    {
      label: 'Letzte 30 Tage',
      value: {
        startDate: addDays(getNow(), -30).toISOString(),
        endDate: getNow().toISOString(),
      },
    },
    {
      label: 'Letzte 90 Tage',
      value: {
        startDate: addDays(getNow(), -90).toISOString(),
        endDate: getNow().toISOString(),
      },
    },
    {
      label: 'Letztes Jahr',
      value: {
        startDate: addDays(getNow(), -365).toISOString(),
        endDate: getNow().toISOString(),
      },
    },
  ], []);

  const customStyles: StylesConfig<OptionType> = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
  };

  return (
    <div className={styles.dateSelectContainer}>
      <Select<OptionType>
        options={options}
        isClearable
        onChange={(selectedOption) => handleChange(selectedOption?.value)}
        styles={customStyles}
        placeholder="Datum"
      />
    </div>
  );
};

export default DateFilter;
