import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getChannelCountForChannelFilter } from 'gcs-common/slices/channelList/channelListSelectors';
import { getSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelTypeFilter';
import {
  CHANNEL_FILTER_TYPES,
} from 'gcs-common/helper/channelTypeHelper';
import { setSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSlice';
import { getHasMessagesUnreadForChannelFilter } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import styles from './styles.module.scss';
import Button from '../../Button/Button';

const ChannelTypeSelectionTab = ({ hideCount = false, filterType }) => {

  const channelTypeFilterCount = useSelector(getChannelCountForChannelFilter(filterType));
  const selectedChannelTypeFilter = useSelector(getSelectedChannelTypeFilter);
  const hasMessagesUnread = useSelector(getHasMessagesUnreadForChannelFilter(filterType));
  const dispatch = useDispatch();

  const selectChannelType = useCallback((selectedType) => {
    dispatch(setSelectedChannelTypeFilter({ selectedChannelTypeFilter: selectedType }));
  }, [dispatch]);

  const text = useMemo(() => {
    if (filterType === CHANNEL_FILTER_TYPES.DIRECT_CHATS) {
      return `Direkte Chats${!hideCount && channelTypeFilterCount !== undefined ? ` (${channelTypeFilterCount})` : ''}`;
    }
    return `TeamChats${!hideCount && channelTypeFilterCount !== undefined ? ` (${channelTypeFilterCount})` : ''}`;

  }, [hideCount, filterType, channelTypeFilterCount]);

  return (
    <div key={filterType}>
      <Button
        onClick={() => selectChannelType(filterType)}
        className={filterType === selectedChannelTypeFilter
          ? styles.selectedChannelFilterTypes
          : styles.channelTypeSelector}
      >
        <div className={styles.flexContainer}>
          {text}
          <div className={styles.dotContainer} data-testid="unread-messages-dot">
            <div className={`${styles.dot} ${hasMessagesUnread ? styles.active : ''}`} data-testid="unread-messages-dot-icon" />
          </div>
        </div>
      </Button>
    </div>
  );
};

ChannelTypeSelectionTab.propTypes = {
  hideCount: PropTypes.bool,
  filterType: PropTypes.string.isRequired,
};

export default ChannelTypeSelectionTab;
