/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import {
  getMemberDetailsSelectedChannelId,
} from 'gcs-common/slices/memberDetails/memberDetailsSelectors';
import {
  getIsSalesSpaceChannelType,
} from 'gcs-common/slices/channel/channelSelectors';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useCallback } from 'react';
import { currentUserIdSelected } from 'gcs-common/slices/memberDetails/memberDetailsSlice';
import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { setRightBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { getSimpleLogo } from 'gcs-common/slices/theme/themeSelectors';
import ChannelDetails from '../ChannelDetails/ChannelDetails';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import styles from './styles.module.scss';

const ChannelProfile = () => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentUserId);
  const selectedChannelId = useSelector(getMemberDetailsSelectedChannelId);
  const isSalesSpaceChannel = useSelector(getIsSalesSpaceChannelType(selectedChannelId));

  const appLogo = useSelector(getSimpleLogo);
  const channelInfoTitle = isSalesSpaceChannel ? 'Teamchatinfo' : 'Gruppeninfo';

  const openUserProfile = useCallback(({ userId }) => {
    if (currentUserId !== userId) {
      dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.MEMBER_DETAILS }));
      dispatch(currentUserIdSelected({ userId, channelId: selectedChannelId }));
    }
  }, [currentUserId, dispatch, selectedChannelId]);

  return (
    <div className={styles.channelProfileContainer}>
      <ProfileHeader
        title={channelInfoTitle}
        channelId={selectedChannelId}
      />

      <ChannelDetails logoFallbackSrc={appLogo} onSelectMember={openUserProfile} />
    </div>
  );
};

export default ChannelProfile;
