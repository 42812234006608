import { captureException } from '@sentry/react';
import { DisconnectedError } from '../../../clients/chatClient/errors/DisconnectedError';
import { LEGACY_EVENTS } from '../../../config/googleAnalyticsLegacyConfig';
import { debugLogger } from '../../../helper/debugLogger';
import { clearLocalStorageExcept } from '../../../helper/localStorageHelper';
import { trackGALegacyEvent } from '../../googleAnalytics/googleAnalyticsThunks';
import { resetOfflineQueueState } from '../../offline/offlineActions';
import { clearStore } from '../../store/storeActions';
import { SELECTED_USER, USERS } from '../../../constants/debugUserSwitcherConstants';

export const clearPersistenceWhiteList = [
  'persistence',
];

const logoutEffect = () => async (dispatch, getState, {
  chatClient,
  blobStorageClient,
  persistenceStorageEngine,
}) => {
  dispatch(trackGALegacyEvent(LEGACY_EVENTS.LOGOUT()));

  try {
    await chatClient.unsetPushRegistrationId('fcm');
  } catch (e) {
    if (e instanceof DisconnectedError) {
      // noop
      debugLogger('could not delete pushRegistrationId');
      // could not unregister push registration id bc not connected.
      // Not good but should not keep the logout from happening
    } else {
      captureException(e);
    }
  }

  dispatch(resetOfflineQueueState());
  dispatch(clearStore());

  if ('clearAppBadge' in navigator) {
    navigator.clearAppBadge();
  }

  try {
    // because it is not guaranteed that the storage was cleared with the clearStore
    // (persisting in redux-persist works asynchronously after a state update)
    await persistenceStorageEngine?.clear?.(
      { whitelist: clearPersistenceWhiteList },
    );
    clearLocalStorageExcept([SELECTED_USER, USERS, 'debugAppTheme', 'uc_ui_version', 'uc_user_interaction', 'uc_settings']);
    sessionStorage?.clear?.();
    await blobStorageClient?.clear?.();
  } catch (e) {
    // Not good but should not keep the logout from happening
    captureException(e);
  }
};

export default logoutEffect;
