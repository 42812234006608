import Select from 'react-select';
import { TICKET_INPUT_CHANNELS } from 'gcs-common/slices/tickets/ticketsConstants';

const inputChannelOptions = Object.entries(TICKET_INPUT_CHANNELS)
  .filter(([, value]) => !value.hidden)
  .map(([key, value]) => ({
    value: key,
    label: value.label,
  }));


interface TicketInputChannelSelectProps {
  onChange: (value: string)=> void;
}

const TicketInputChannelSelect = ({ onChange }: TicketInputChannelSelectProps) => {
  return (
    <Select
      name="erpCustomerId"
      options={inputChannelOptions}
      onChange={(selectedOption) => onChange(selectedOption?.value || '')}
      placeholder="Eingangskanal auswählen"
    />
  );
};

export default TicketInputChannelSelect;
