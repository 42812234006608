import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const NewChatSearchMessage = ({ searchTerm }) => (
  <div className={styles.newChatBodyHeader}>
    <span className={styles.newChatBodyHeaderText}>
      {searchTerm ? 'Suchergebnisse' : 'Vorschläge'}
    </span>
  </div>
);

NewChatSearchMessage.propTypes = {
  searchTerm: PropTypes.string,
};

export default NewChatSearchMessage;
