import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserName, getUserImage, getUserStatus } from 'gcs-common/slices/user/userSelectors';
import { getCurrentUserId, getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';

import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { setRightBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { currentUserIdSelected } from 'gcs-common/slices/memberDetails/memberDetailsSlice';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import styles from './styles.module.scss';

const NavBarMember = ({ userId, channelId, isMainContact = false, showNameUnderImage = true }) => {
  const dispatch = useDispatch();
  const isAgent = useSelector(getIsAgent);
  const userName = useSelector(getUserName(userId));
  const status = useSelector(getUserStatus(userId));
  const userImage = useSelector(getUserImage(userId));
  const currentUserId = useSelector(getCurrentUserId);

  const onClickHandler = () => {
    if (isAgent && currentUserId !== userId) {
      dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.MEMBER_DETAILS }));
      dispatch(currentUserIdSelected({ userId, channelId }));
    }
  };

  return (
    <div
      className={`${styles.profileWrapper} ${showNameUnderImage && styles.profileWrapperColumn}`}

    >
      <button
        className={currentUserId === userId ? styles.hideHoverEffect : ''}
        type="button"
        onClick={onClickHandler}
      >
        <ImageWithStatus
          className={styles.imageWrapper}
          imageUrl={userImage}
          userStatus={status}
          hasStar={isMainContact}
          onClick={onClickHandler}
        />
        <div className={styles.groupMemberName}>
          {userName}
        </div>
      </button>
    </div>
  );
};

NavBarMember.propTypes = {
  userId: PropTypes.string.isRequired,
  showNameUnderImage: PropTypes.bool,
  isMainContact: PropTypes.bool,
  channelId: PropTypes.string.isRequired,
};

export default NavBarMember;
