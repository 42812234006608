import { captureMessage } from '@sentry/react';
import { ERROR_DIALOG } from 'gcs-common/constants/errorDialogConstants';
import { debugLogger } from '../helper/debugLogger';
import FatalQueueError from './errors/FatalQueueError';
import GccApiNetworkError from '../clients/gccApiClient/errors/GccApiNetworkError';
import { MAX_RETRIES } from './offlineRetry';
import { DisconnectedError } from '../clients/chatClient/errors/DisconnectedError';
import AppMustUpgradeError from '../clients/gccApiClient/errors/AppMustUpgradeError';
import { openErrorDialog } from '../slices/errorDialogSlice/errorDialogSlice';
import { getErrorMsg } from '../helper/errorMessages';

/**
 * Decides if an effect that failed should be retried or discarded
 */
const offlineDiscard = ({ getStore }) => (error, action, retries) => {
  debugLogger('offlineDiscard error', { error, action, retries });

  const dispatch = getStore()?.dispatch;

  if (error instanceof AppMustUpgradeError) {
    // we don't retry if the API cannot process, because app version is too old
    return true;
  }

  if (error instanceof DisconnectedError || error instanceof GccApiNetworkError) {
    return false;
  }

  if (retries > MAX_RETRIES) {
    debugLogger('Will discard offline action because it reached MAX_RETRIES', MAX_RETRIES);
    captureMessage('Discarded offline action because of maximum retries reached', { level: 'error', tags: { action, error, retries } });
    return true;
  }

  if (error instanceof FatalQueueError) {
    debugLogger(`Will discard offline action for error='${error.message}'`);
    captureMessage('discarded offline action because of FatalQueueError', {
      level: 'error',
      tags: { action, error, retries, ...error.sentryTags },
    });
    return true;
  }

  if (error?.payload?.errorCode) {
    // discard the action if the api returned an error code, regardless of it was with 4xx or 5xx
    debugLogger(`Will discard offline action for errorCode='${error.payload.errorCode}'`);
    if (
      error?.payload?.errorDialog
        && error?.payload?.errorDialog !== ERROR_DIALOG.NONE
    ) {
      const errorMessage = getErrorMsg(error.payload.errorCode);
      const defaultErrorMessage = getErrorMsg('chat.default');
      dispatch(openErrorDialog({ dialogErrorMsg: errorMessage || defaultErrorMessage }));
    }
    return true;
  }

  return false;
};

export default offlineDiscard;
