import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Button from '../../Button/Button';
import PopUpModal from '../../PopUpModal/PopUpModal';

const RegistrationExit = ({ onSuccess, onRequestClose, isOpen }) => {

  return (
    <PopUpModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title=""
      className={styles.popUpModal}
    >
      <div className={styles.container}>
        <div className={styles.text}>
          <div className={styles.header}>Möchten Sie den Registrierungsprozess unterbrechen?</div>

          Sie können sich jederzeit erneut einloggen um Ihren
          Account zu vervollständigen.
        </div>
        <div className={styles.buttonGroup}>
          <Button
            type="button"
            className={styles.rejectButton}
            onClick={onSuccess}
          >
            JA
          </Button>
          <Button
            type="button"
            className={styles.acceptButton}
            onClick={onRequestClose}
          >
            NEIN
          </Button>
        </div>
      </div>
    </PopUpModal>
  );
};

RegistrationExit.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default RegistrationExit;
