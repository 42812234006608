import { createSelector } from '@reduxjs/toolkit';
import { LOGIN_FLOW } from 'gcs-common/constants/loginFlows';
import { getThemeIdentifier } from 'gcs-common/slices/theme/themeSelectors';
import { THEME } from 'gcs-common/constants/themes';

export const getWebLoginFlow = createSelector(
  getThemeIdentifier,
  (themeIdentifier) => {
    switch (themeIdentifier) {
      case THEME.AGENT:
        return LOGIN_FLOW.AGENT;
      case THEME.ITG:
      case THEME.GC:
      case THEME.GUT:
      case THEME.EFG:
      case THEME.HTI:
      case THEME.DTG:
      case THEME.TFG:
      case THEME.HFH:
        return LOGIN_FLOW.CRAFTSMAN;
      default:
        return LOGIN_FLOW.AGENT;
    }
  },
);
