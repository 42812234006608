/* eslint-disable react/prop-types */
/**
 * Table component: https://react-table.tanstack.com/
 * Pagination ("controlled"): https://react-table.tanstack.com/docs/api/usePagination
 * Action-Buttons: https://react-table.tanstack.com/docs/examples/row-selection
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { usePagination, useTable } from 'react-table';
import clsx from 'clsx';
import styles from './styles.module.scss';
import TableColumnPropType from '../../models/TableColumnPropType';
import TableDataPropType from '../../models/TableDataPropType';
import Arrow from '../../img/arrow.svg?react';
import PaginationPropType from '../../models/PaginationPropType';
import sortAscending from '../../img/sortUp.svg';
import sortAscendingLight from '../../img/sortUpLight.svg';
import sortDescending from '../../img/sortDown.svg';
import { Spinner } from '../Spinner/Spinner';
import { SORT_ORDER } from '../../constants/sortConstants';

const PaginatedTable = ({
  columns,
  data,
  renderActions,
  onRowSelected,
  searchInput,
  onPaginationChanged,
  pagination: {
    pages: controlledPageCount,
  } = {},
  sortOrder = null,
  setSortOrder,
  sortedColumnId = null,
  setSortedColumnId,
  sortIsLoading,
  getRowProps = () => ({}),
  reloadPage,
  useNewStyles = false,
}) => {

  const [currentSearchInput, setCurrentSearchInput] = useState(searchInput);

  const rowSelected = useCallback(
    (rowData) => () => {
      onRowSelected(rowData.original);
    },
    [onRowSelected],
  );

  const handleSortColumn = (column) => () => {
    if (!column.disableSortBy) {
      if (column.id === sortedColumnId) {
        if (sortOrder === SORT_ORDER.DESCENDING) {
          setSortOrder(SORT_ORDER.NOT_SORTED);
        } else if (sortOrder === SORT_ORDER.ASCENDING) {
          setSortOrder(SORT_ORDER.DESCENDING);
        } else {
          setSortOrder(SORT_ORDER.ASCENDING);
        }
      } else {
        setSortOrder(SORT_ORDER.ASCENDING);
      }
      setSortedColumnId(column.id);
    }
  };

  const tableSettings = useMemo(() => ({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 10 },
    manualPagination: true,
    pageCount: controlledPageCount,
  }), [columns, controlledPageCount, data]);

  const tableHooks = useCallback(
    (hooks) => {
      if (renderActions) {
        hooks.visibleColumns.push(cols => [
          ...cols,
          {
            id: 'actions',
            Cell: ({ cell }) => (
              <div className={styles.actionButtons}>
                {renderActions(cell)}
              </div>
            ),
          },
        ]);
      }
    },
    [renderActions],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(tableSettings, tableHooks, usePagination);

  useEffect(() => {
    if (onPaginationChanged) {
      onPaginationChanged(pageIndex, pageSize, searchInput);
    }
    // NOTE: We dont want to run this effect if the onPaginationChanged-changes
  }, [onPaginationChanged, pageIndex, pageSize, searchInput, reloadPage]);

  useEffect(() => {
    if (searchInput !== currentSearchInput) {
      gotoPage(0);
      setCurrentSearchInput(searchInput);
    }
  }, [currentSearchInput, gotoPage, searchInput]);

  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(0);
    }
  }, [pageIndex, pageCount, gotoPage]);

  return (
    <>
      <div className={styles.tableScroller}>
        <table
          {...getTableProps()}
          className={clsx(styles.table, { [styles.newStyles]: useNewStyles })}
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className={`
                    ${styles.headerCell}
                    ${styles.headerLeft}
                    ${column.headerPosition === 'center' ? styles.headerCenter : ''}
                    ${column.headerPosition === 'right' ? styles.headerRight : ''}
                    `}
                  >
                    <button
                      type="button"
                      onClick={handleSortColumn(column)}
                      className={styles.columnHeader}
                    >
                      <div className={styles.container}>
                        <div>
                          {column.render('Header')}
                        </div>
                        <div className={styles.sortImage}>
                          {sortedColumnId === column.id && sortOrder === 1 && !sortIsLoading
                          && (
                            <img
                              alt="sort-icon"
                              src={sortAscending}
                            />
                          )}
                          {sortedColumnId === column.id && sortOrder === -1 && !sortIsLoading
                          && (
                            <img
                              alt="sort-icon"
                              src={sortDescending}
                            />
                          )}
                          {(sortedColumnId !== column.id || (!sortOrder && !sortIsLoading))
                          && !column.disableSortBy
                          && (
                            <img
                              alt="sort-icon"
                              className={styles.sortLogoLight}
                              src={sortAscendingLight}
                            />
                          )}
                          {sortedColumnId === column.id && sortIsLoading
                          && (
                            <Spinner />
                          )}
                        </div>
                      </div>
                    </button>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page && page.length < 1 && !sortIsLoading && (
              <tr>
                <td colSpan={columns.length} className={clsx(styles.cell, styles.noResults)}>
                  Keine Ergebnisse gefunden
                </td>
              </tr>
            )}
            {page.map(row => {
              prepareRow(row);
              return (
                <tr
                  onClick={rowSelected(row)}
                  {...row.getRowProps(getRowProps(row))}
                  className={clsx(styles.row, row.getRowProps(getRowProps(row)).className)}
                  style={{ ...row.getRowProps(getRowProps(row)).style }}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            style: cell.column.styles,
                          },
                        ])}
                        className={styles.cell}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={clsx(styles.pagination, { [styles.newStyles]: useNewStyles })}>
        <button
          className={styles.invertedPaginationButton}
          type="button"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <Arrow />
          <Arrow />
        </button>
        <button
          className={styles.invertedPaginationButton}
          type="button"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <Arrow />
        </button>
        <div className={styles.paginationPart}>
          <input
            className={styles.paginationInput}
            type="number"
            value={pageIndex + 1}
            onChange={e => {
              const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageNumber);
            }}
          />
          |
          <span className={styles.paginationText}>{` ${pageOptions.length} `}</span>
        </div>
        <button
          className={styles.paginationButton}
          type="button"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <Arrow />
        </button>
        <button
          className={styles.paginationButton}
          type="button"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <Arrow />
          <Arrow />
        </button>
        <select
          className={styles.paginationSelect}
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(_pageSize => (
            <option key={_pageSize} value={_pageSize}>
              {_pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

PaginatedTable.propTypes = {
  columns: PropTypes.arrayOf(TableColumnPropType).isRequired,
  data: PropTypes.arrayOf(TableDataPropType).isRequired,
  onRowSelected: PropTypes.func.isRequired,
  renderActions: PropTypes.func,
  searchInput: PropTypes.string,
  pagination: PaginationPropType,
  sortOrder: PropTypes.number,
  setSortOrder: PropTypes.func.isRequired,
  sortedColumnId: PropTypes.string,
  setSortedColumnId: PropTypes.func.isRequired,
  sortIsLoading: PropTypes.bool.isRequired,
  getRowProps: PropTypes.func,
  useNewStyles: PropTypes.bool,
};

export default PaginatedTable;
