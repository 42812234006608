/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import ASYNC_STATE from '../../constants/AsyncState';
import { byId } from '../../helper/byKey';
import { fetchUser } from './usersThunks/fetchUser';
import { loadTwilioUsers } from '../chatConnection/chatConnectionThunks/twilioLoadingThunk';
import { allChannelsFetched } from '../channels/channelsThunks/allChannelsFetched';
import mergeObjects from '../../helper/mergeObjects';

const asyncState = {
  fetchUser: {
    loading: ASYNC_STATE.IDLE,
    error: null,
  },
};

const initialState = {
  ...asyncState,
  users: {}, // db users by id
};

const persistWhitelist = [
  'users',
];

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userUpdated: (state, action) => {
      const { user } = action.payload;
      const { id } = user;
      state.users[id] = mergeObjects(state.users[id], user);
    },
  },
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.fetchUser.loading = ASYNC_STATE.LOADING;
    },
    [fetchUser.fulfilled]: (state, action) => {
      const { user } = action.payload;
      state.users = mergeObjects(state.users, byId([user]));
      state.fetchUser.loading = ASYNC_STATE.SUCCEEDED;
    },
    [fetchUser.rejected]: (state, action) => {
      state.fetchUser.loading = ASYNC_STATE.FAILED;
      state.error = action.error;
    },
    [allChannelsFetched.fulfilled]: (state, action) => {
      const { users } = action.payload;
      state.users = mergeObjects(state.users, byId(users));
    },
    [loadTwilioUsers.fulfilled]: (state, action) => {
      const users = action.payload;
      state.users = mergeObjects(state.users, byId(users));
    },
  },
});

export { persistWhitelist as usersPersistWhitelist };
export const {
  userUpdated,
} = usersSlice.actions;
export default usersSlice.reducer;
