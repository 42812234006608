import { createSelector } from '@reduxjs/toolkit';

export const getHouses = (state) => state.userHouses.houses;

export const getHouse = (houseId) => createSelector(
  getHouses,
  (houses) => houses?.[houseId] || null,
);

export const getHouseName = (houseId) => createSelector(
  getHouse(houseId),
  (house) => house?.friendlyName || null,
);

export const getJoinedHousesIds = (state) => state?.userHouses?.joinedHousesIds;

export const getJoinedHousesNames = createSelector(
  getHouses,
  houses => (houses ? Object.keys(houses).map(houseId => houses[houseId].friendlyName) : []),
);

export const getSelectedHouseId = (state) => state?.userHouses?.selectedHouseId;
