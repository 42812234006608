/* eslint-disable no-use-before-define */
import { calculateChannelTitle, compareChannelByLatestMessageOrUpdate } from '../../helper/channelHelpers';
import { SalesSpaceChannelsInGroupingSort } from '../../helper/channelOrderingHelper';

export const ChannelOrder = {
  DATE: 'Neueste',
  NAME: 'Alphabetisch',
};

function sortChannels({
  sortingDirection,
  channelIds,
  channels,
  currentUserId,
  channelMembers,
  members,
  users,
  isAgent,
  channelNotificationDates,
}) {
  if (sortingDirection === ChannelOrder.NAME) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return sortByName({
      channelIds,
      channels,
      currentUserId,
      channelMembers,
      members,
      users,
      isAgent,
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return sortByDate({ channelIds, channels, channelNotificationDates });
}

export function sortByDate({ channelIds, channels, channelNotificationDates }) {
  return channelIds
    .map(id => channels[id])
    .sort((a, b) => compareChannelByLatestMessageOrUpdate(a, b, channelNotificationDates))
    .map(channel => channel?.id)
    .filter(id => id); // filter out undefined
}

function sortByName({
  channelIds,
  channels,
  currentUserId,
  channelMembers,
  members,
  users,
  isAgent,
}) {
  const channelTitles = channelIds.reduce((red, channelId) => {
    const channel = channels[channelId];
    const channelTitle = calculateChannelTitle({
      channelId,
      channel,
      users,
      channelMembers,
      members,
      currentUserId,
      isAgent,
    });
    return {
      ...red,
      [channelId]: channelTitle,
    };
  }, {});
  channelIds.sort((id1, id2) => {
    const t1 = channelTitles[id1];
    const t2 = channelTitles[id2];
    return t1?.localeCompare(t2);
  });
  return channelIds;
}

export default sortChannels;

export function sortSalesSpaceChannels({
  sortingDirection,
  channelIds,
  channels,
  channelNotificationDates,
}) {
  switch (sortingDirection) {
    case SalesSpaceChannelsInGroupingSort.DATE:
      return sortByDate({ channelIds, channels, channelNotificationDates });
    default:
      return channelIds;
  }
}
