export const isObjectEmpty = (obj) => {
  return Object.entries(obj).length === 0;
};

export const filterObjectByKey = (object, key) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([_key]) => _key !== key));
};

export const filterObjectIncludeMatchingKeys = (object, keysArr) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([_key]) => keysArr.includes(_key)));
};

export const filterObjectExcludeMatchingKeys = (object, keysArr) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([_key]) => !keysArr.includes(_key)));
};

export const filterObjectByValue = (object, filterFunc) => {
  return Object.fromEntries(Object.entries(object)
    .filter(([, v]) => filterFunc(v)));
};

export const getEntitiesForKeys = (entities, keys) => {
  return Object.entries(entities).reduce((users, [key, value]) => {
    if (keys.includes(key)) {
      users.push(value);
    }
    return users;
  }, []);
};

export const getByPath = (obj, path) => {
  return path.split('.').reduce((res, fieldName) => {
    return res[fieldName];
  }, obj);
};

export const setByPath = (obj, path, value) => {
  const fieldNames = path.split('.');
  const newObj = { ...obj };

  if (fieldNames.length === 1) {
    newObj[fieldNames[0]] = value;
  } else {
    newObj[fieldNames[0]] = setByPath(obj[fieldNames[0]], fieldNames.slice(1).join('.'), value);
  }
  return newObj;
};

export const countObjectValueArrayEntries = (obj) => {
  return Object.values(obj)
    .map(entry => entry.length)
    .reduce((old, next) => old + next, 0);
};


export const filterObjectToExcludeNull = (obj) => {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [key, filterObjectToExcludeNull(value)];
    }).filter(([_, value]) => value !== undefined && value !== null),
  );
};
