import { NEW_CHANNEL_SECTIONS_LIST } from 'gcs-common/constants/newChannelSections';
import NewChannelGroup from '../../NewChannelGroup/NewChannelGroup';

const GroupedNewChannelList = () => {

  return (
    <>
      {NEW_CHANNEL_SECTIONS_LIST.map((sectionName) => (
        <NewChannelGroup
          key={sectionName}
          sectionName={sectionName}
        />
      ))}
    </>
  );
};

export default GroupedNewChannelList;
