import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCommunicationStructure,
  getCommunicationStructureMainMember,
  getCommunicationStructureInitialMembers,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSelectors';
import {
  CommunicationAssignmentType,
  CommunicationAssignmentDirection,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructuresConstants';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import Icon from 'gcs-common/components/Icon/Icon';
import RowMemberList from 'gcs-common/components/RowMemberList/RowMemberList';
import styles from './styles.module.scss';
import CommunicationStructureSettings from '../CommunicationStructureSettings/CommunicationStructureSettings';

const CommunicationStructureRowCollapsed = ({
  communicationStructureId,
  setEditRowId,
  setNewCommunicationStructureAssignmentType,
}) => {

  const communicationStructure = useSelector(getCommunicationStructure(communicationStructureId));
  // eslint-disable-next-line max-len
  const isTeamRow = communicationStructure?.assignmentType === CommunicationAssignmentType.SALES_SPACE;
  const directionIcon = () => {
    // eslint-disable-next-line max-len
    if (communicationStructure?.assignmentDirection === CommunicationAssignmentDirection.BIDIRECTIONAL) {
      return <Icon Icon={ICON.CYCLE_ARROWS} color={ICON_COLOR.BLACK} size={ICON_SIZE.X_SMALL} />;
    }
    return <Icon Icon={ICON.ARROW_LONG} color={ICON_COLOR.BLACK} size={ICON_SIZE.X_SMALL} />;
  };
  const communicationMainRole = useSelector(getCommunicationStructureMainMember(
    communicationStructureId,
  ));

  const communicationStructureMembers = useSelector(
    getCommunicationStructureInitialMembers(communicationStructureId),
  );

  const onExpandButtonClick = useCallback(() => {
    setEditRowId(communicationStructure.id);
    setNewCommunicationStructureAssignmentType(null);
  }, [setEditRowId, setNewCommunicationStructureAssignmentType, communicationStructure]);

  const calculateMemberKey = (member) => {
    const {
      assignmentRoleId,
      customerResponsibilityRoleId: roleId,
      agentId,
      teamId,
    } = member;

    return `${communicationStructureId}${assignmentRoleId}${roleId || ''}${agentId || ''}${teamId || ''}`;
  };

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div className={styles.rowContainer} onClick={onExpandButtonClick} data-testid="communication-structure-row-collapsed">
      <div className={styles.dataRow}>
        <div className={styles.direction}>
          {directionIcon()}
        </div>
        {isTeamRow && (
          <div className={styles.nameContainer}>
            <div className={styles.name}>
              {communicationStructure.channelName}
            </div>
          </div>
        )}

        <RowMemberList
          isTeamRow={isTeamRow}
          communicationMainRole={communicationMainRole}
          communicationStructureMembers={communicationStructureMembers}
          calculateMemberKey={calculateMemberKey}
        />

      </div>
      <CommunicationStructureSettings
        communicationStructureId={communicationStructure.id}
        onExpandButtonClick={onExpandButtonClick}
        rowType="collapsed"
      />
    </div>
  );
};

CommunicationStructureRowCollapsed.propTypes = {
  communicationStructureId: PropTypes.string.isRequired,
  setEditRowId: PropTypes.func.isRequired,
  setNewCommunicationStructureAssignmentType: PropTypes.func.isRequired,
};

export default CommunicationStructureRowCollapsed;
