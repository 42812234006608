import { createAction } from '@reduxjs/toolkit';

export const deleteMessageRollback = createAction('deleteMessageRollback', (payload) => ({ meta: payload }));

export const deleteMessageOffline = createAction('deleteMessageOffline', (payload) => {
  return {
    meta: {
      ...payload,
      offline: {
        rollback: deleteMessageRollback(payload),
      },
    },
  };
});
