import { createSelector } from '@reduxjs/toolkit';
import {
  currentPaginationEntities,
  toPaginationPaginationResult,
} from '../../../helper/reduxPaginationHelper';
import ASYNC_STATE from '../../../constants/AsyncState';
import { toIsLoading } from '../../../helper/reduxLoadingStatusHelper';

export const getAllErpCustomers = (state) => state.erpCustomers.erpCustomers;
export const getSelectedErpCustomers = (state) => state.erpCustomers.fetchSelectedErpCustomers;
export const getSelectedErpCustomerId = (state) => state.erpCustomers.selectedErpCustomerId;

const getErpCustomersState = state => (
  state.erpCustomers.fetchErpCustomers || {}
);

export const getErpCustomers = createSelector(
  [getErpCustomersState, getAllErpCustomers],
  currentPaginationEntities,
);

export const getErpCustomersLoading = createSelector(
  [getErpCustomersState],
  toIsLoading,
);

export const getErpCustomersPaginationResult = createSelector(
  [getErpCustomersState],
  toPaginationPaginationResult,
);

export const getSelectedErpCustomer = erpCustomerId => createSelector(
  getAllErpCustomers,
  (erpCustomers) => erpCustomers[erpCustomerId],
);

export const getSelectedFilterByErpCustomerId = (state) => state.erpCustomers.filterByErpCustomerId;

export const getSelectedErpCustomerLoading = (state) => (
  state.erpCustomers.fetchSelected.status === ASYNC_STATE.LOADING);

export const getErpCustomerDirectChannels = (state) => state.erpCustomers.erpCustomerDirectChannels;
export const getErpCustomerDirectChannelsLoading = (state) => (
  state.erpCustomers.fetchDirectChannels.status === ASYNC_STATE.LOADING
);

export const getErpCustomerDirectChannelsErrorMessage = (
  state,
) => state.erpCustomers.fetchDirectChannels.errorMessage;

export const getErpCustomerGroupChannels = (state) => state.erpCustomers.erpCustomerGroupChannels;
export const getErpCustomerGroupChannelsLoading = (state) => (
  state.erpCustomers.fetchGroupChannels.status === ASYNC_STATE.LOADING
);

export const getErpCustomerUsers = (state) => state.erpCustomers.erpCustomerUsers;
export const getErpCustomerUsersLoading = (state) => (
  state.erpCustomers.fetchErpCustomerUsers.status === ASYNC_STATE.LOADING
);

export const getCreateErpCustomerErrorMessage = (
  state,
) => state.erpCustomers.create.errorMessage;

export const getCreateErpCustomerDefaultErrorMessage = (
  state,
) => state.erpCustomers.create.defaultErrorMessage;

export const getUpdateErpCustomerErrorMessage = (
  state,
) => state.erpCustomers.updateSelected.errorMessage;

export const getUpdateErpCustomerDefaultErrorMessage = (
  state,
) => state.erpCustomers.updateSelected.defaultErrorMessage;

export const getDeleteErpCustomerDefaultErrorMessage = (
  state,
) => state.erpCustomers.delete.defaultErrorMessage;

export const getCreateErpCustomerDirectChannelErrorMessage = (
  state,
) => state.erpCustomers.createAgentToCraftsmanDirectChannel.errorMessage
  || state.erpCustomers.createCraftsmanToCraftsmanDirectChannel.errorMessage;

export const getCreateErpCustomerUserDefaultErrorMessage = (
  state,
) => state.erpCustomers.createUser.defaultErrorMessage;

export const getCreateErpCustomerUserErrorMessage = (
  state,
) => state.erpCustomers.createUser.errorMessage;

export const getDeleteErpCustomerUserErrorMessage = (
  state,
) => state.erpCustomers.deleteErpCustomerUser.errorMessage;

export const getDeleteErpCustomerUserLoading = (state) => (
  state.erpCustomers.deleteErpCustomerUser.status === ASYNC_STATE.LOADING);

export const getIsFetchSelectedErpCustomerSucceeded = (state) => (
  state.erpCustomers.fetchSelected.status === ASYNC_STATE.SUCCEEDED);
