import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { removeUserFromSelectedTeam } from 'gcs-common/slices/administration/teams/teamsThunks';
import { getRemovingUserFromSelectedTeamLoading, getRemovingUserFromSelectedTeamDefaultErrorMessage } from 'gcs-common/slices/administration/teams/teamsSelectors';
import { resetRemovingUserFromSelectedTeamDefaultErrorMessage } from 'gcs-common/slices/administration/teams/teamsSlice';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import TeamPropType from '../../../../models/TeamPropType';
import AgentPropType from '../../../../models/AgentPropType';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const TeamRemoveUser = ({ user, team, onCancel, onSuccess }) => {

  const dispatch = useDispatch();
  const defaultErrorMessage = useSelector(getRemovingUserFromSelectedTeamDefaultErrorMessage);
  const isDeleting = useSelector(getRemovingUserFromSelectedTeamLoading);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetRemovingUserFromSelectedTeamDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetRemovingUserFromSelectedTeamDefaultErrorMessage());
  }, [dispatch]);

  const { id: userId, friendlyName } = user;
  const { id: teamId } = team;

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(removeUserFromSelectedTeam({ teamId, userId }));
      if (!result.error) {
        onSuccess();
      }
    },
    [dispatch, teamId, onSuccess, userId],
  );

  return (
    <div className={styles.column}>
      Möchten Sie die den Nutzer
      {' "'}
      {friendlyName}
      {'" '}
      aus dem Team entfernen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Entfernen
        </Button>
      </div>
    </div>
  );
};

TeamRemoveUser.propTypes = {
  team: TeamPropType.isRequired,
  user: AgentPropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TeamRemoveUser;
