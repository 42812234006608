/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  debugAppTheme: localStorage.getItem('debugAppTheme'),
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setDebugAppTheme: (state, action) => {
      const { debugAppTheme } = action.payload;
      state.debugAppTheme = debugAppTheme;
    },
  },
});

export const {
  setDebugAppTheme,
} = themeSlice.actions;

export default themeSlice.reducer;
