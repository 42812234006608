const CHAT_CONNECTION_STATE = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  DISCONNECTING: 'disconnecting',
  DISCONNECTED: 'disconnected',
  DENIED: 'denied',
} as const;
export type ChatConnectionStateKey = keyof typeof CHAT_CONNECTION_STATE;
export type ChatConnectionStateValue = typeof CHAT_CONNECTION_STATE[ChatConnectionStateKey];
export default CHAT_CONNECTION_STATE;
