import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getVideoUploadProgress } from 'gcs-common/slices/videos/videosSelectors';
import styles from './styles.module.scss';
import CubeIcon from '../../../img/cube_primary.svg?react';

const VideoUploadProgress = ({ videoId }) => {
  const uploadPercentage = useSelector(getVideoUploadProgress(videoId));

  if (uploadPercentage === 0) {
    return (
      <>
        <div className={styles.loaderContainer}>
          <CubeIcon alt="loading-indicator" className={styles.videoLoader} />
        </div>
        <h4>Warten auf Upload</h4>
      </>
    );
  }

  return (
    <>
      <div className={styles.loaderContainer}>
        <CubeIcon alt="loading-indicator" className={styles.videoLoader} />
      </div>
      <h4>Video Upload</h4>
      <div className={styles.uploadPercentage}>
        <span>
          {uploadPercentage}
          {' '}
          %
        </span>
      </div>
    </>
  );

};

export default VideoUploadProgress;

VideoUploadProgress.propTypes = {
  videoId: PropTypes.string.isRequired,
};
