import { getCurrentUser, getIsAgent } from '../currentUserSelectors';
import fetchCurrentUserChannels from '../../channels/channelsThunks/fetchCurrentUserChannels';
import { userUpdated } from '../../users/usersSlice';
import { CHANNEL_TYPE } from '../../../helper/channelTypeHelper';
import { currentUserUpdated } from '../currentUserSlice';

export const updateCurrentChatUser = ({ user }) => async (dispatch, getState) => {
  const currentChatUser = getCurrentUser(getState());
  const isAgent = getIsAgent(getState());

  if (currentChatUser && isAgent) {
    const { salesSpacesUpdated: oldSalesSpacesUpdatedTime } = currentChatUser;
    const { salesSpacesUpdated: newSalesSpacesUpdatedTime } = user;
    if (oldSalesSpacesUpdatedTime !== newSalesSpacesUpdatedTime) {
      dispatch(fetchCurrentUserChannels({ channelTypes: [CHANNEL_TYPE.SALES_SPACE] }));
    }
  }

  dispatch(userUpdated({ user }));
  dispatch(currentUserUpdated({ user }));
};
