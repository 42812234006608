import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import useFieldFast from '../../customHooks/useFieldFast';
import styles from './styles.module.scss';
import PlusIcon from '../../img/plus.svg?react';
import removeIcon from '../../img/remove.svg';
import FileInputWrapper from '../FileInputWrapper/FileInputWrapper';

const MAX_ALLOWED_IMAGE_SIZE = 250000000;

const ChannelImageSelect = (
  {
    name,
    label = '',
    disabled = false,
  },
) => {

  const [field, , helpers] = useFieldFast(name);
  const [cropActive, setCropActive] = useState(false);

  const avatarRef = useRef('avatar');
  const [img, setImg] = useState(field.value);

  const onChange = useCallback((preview) => {
    const newImage = preview[0];
    if (newImage.type.includes('image') && newImage.size < MAX_ALLOWED_IMAGE_SIZE) {
      setImg(newImage);
    }
  }, []);

  const onSelectImage = useCallback(() => {
    const canvas = avatarRef.current.getImageScaledToCanvas();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    canvas && helpers.setValue(canvas.toDataURL());
    setCropActive(false);
  }, [helpers]);

  const onPictureChoice = async (file) => {
    if (file !== null) {
      const reader = new FileReader();
      // Turns file into base64 string
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        const { result } = reader;
        setImg(result);
        setCropActive(true);
      };
    }
  };

  const onImageDelete = useCallback(() => {
    setCropActive(true);
    setImg(null);
    helpers.setValue(null);
  }, [helpers]);

  return (
    <div>
      <div>
        {label && <div className={styles.label}>{label}</div>}
      </div>
      {(cropActive || !field.value) && !disabled ? (
        <>
          <div className={styles.wrapper}>
            <Dropzone
              onDrop={onChange}
              style={{ width: '80px', height: '80px' }}
              disabled={disabled}
              accept="image/*"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={`${styles.emptyImage} ${styles.canAddImage}`}>
                  <AvatarEditor
                    ref={avatarRef}
                    width={80}
                    height={80}
                    border={0}
                    image={img}
                    onImageReady={onSelectImage}
                    position={{ x: 0.5, y: 0.5 }}
                    disabled={disabled}
                  />
                  <input {...getInputProps()} />
                  {!disabled && (
                    <div className={styles.placeholderContainer}>
                      <PlusIcon />
                    </div>
                  )
                  }
                </div>
              )}
            </Dropzone>
          </div>
          <ValidationError name={name} />
        </>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.profileImage}>
            {!disabled && (
              <button type="button" onClick={onImageDelete} className={styles.deleteOverlay}>
                <img src={removeIcon} alt="Delete" />
              </button>
            )}
            {!disabled && (
              <FileInputWrapper onChange={onPictureChoice} className={styles.overlay}>
                <p>Ändern</p>
              </FileInputWrapper>
            )}
            {!field.value && disabled ? (
              <div className={styles.emptyImage} />
            ) : (
              <img
                src={field.value}
                className={styles.profileImagePic}
                alt="channelImage"
              />
            )
            }
          </div>
        </div>
      )}
    </div>
  );
};

ChannelImageSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ChannelImageSelect;
