import { useCallback, useEffect } from 'react';
import { throttle } from 'throttle-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId, getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { updateAgentStatus } from 'gcs-common/slices/currentAgent/currentAgentThunks';
import { ONLINE_STATUS } from 'gcs-common/helper/userStatusHelper';
import { getUserStatus } from 'gcs-common/slices/user/userSelectors';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';

const IDLE_TIME = 15 * 60e3;

function IdleTimeManager() {

  const isAgent = useSelector(getIsAgent);
  const currentUserId = useSelector(getCurrentUserId);
  const currentStatus = useSelector(getUserStatus(currentUserId));
  const isChatConnected = useSelector(getIsChatConnected);
  const dispatch = useDispatch();

  const onIdleTime = useCallback(async () => {
    const { type } = currentStatus;
    if (type === ONLINE_STATUS.ONLINE && isChatConnected) {
      dispatch(updateAgentStatus({ statusType: ONLINE_STATUS.IDLE }));
    }
  }, [isChatConnected, currentStatus, dispatch]);

  useEffect(() => {
    if (!isAgent) {
      // only agent status is set to idle
      return undefined;
    }
    const events = [
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    let idleTimeout = window.setTimeout(onIdleTime, IDLE_TIME);
    const onNonIdleAction = throttle(20 * 1e3, async () => {
      if (idleTimeout) window.clearTimeout(idleTimeout);
      idleTimeout = window.setTimeout(onIdleTime, IDLE_TIME);
    });

    events.forEach(e => window.addEventListener(e, onNonIdleAction));
    return () => events.forEach(e => window.removeEventListener(e, onNonIdleAction));
  }, [onIdleTime, isAgent]);

  return (
    <div />
  );
}

export default IdleTimeManager;
