import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchCraftsmanConnections } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import { getJoinedHousesIds } from 'gcs-common/slices/houses/housesSelectors';
import styles from './styles.module.scss';
import { HouseConnections } from '../../HouseConnections/HouseConnections';

const CraftsmanConnectionsModalOverview = ({ goToEdit, goToAdd }) => {
  const joinedHouseIds = useSelector(getJoinedHousesIds);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCraftsmanConnections());
  }, [dispatch]);

  return (
    <div className={styles.body}>
      {joinedHouseIds.map(
        (houseId) => <HouseConnections key={houseId} houseId={houseId} goToEdit={goToEdit} />,
      )}

      <button className={styles.btn} onClick={goToAdd} type="button">
        Verknüpfung hinzufügen
      </button>
    </div>

  );
};

CraftsmanConnectionsModalOverview.propTypes = {
  goToEdit: PropTypes.func.isRequired,
  goToAdd: PropTypes.func.isRequired,
};

export default CraftsmanConnectionsModalOverview;
