/* eslint-disable no-param-reassign,import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showMobileLoginModal: false,
  pathBeforeRegistration: null, // so that we can redirect back when registration is finished
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setShowMobileLoginModal: (state, action) => {
      const { showMobileLoginModal } = action.payload;
      state.showMobileLoginModal = showMobileLoginModal;
    },
    savePathBeforeRegistration: (state, action) => {
      const { pathBeforeRegistration } = action.payload;
      state.pathBeforeRegistration = pathBeforeRegistration;
    },
  },
});

export const {
  setShowMobileLoginModal,
  savePathBeforeRegistration,
} = settingsSlice.actions;

export default settingsSlice.reducer;
