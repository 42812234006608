import * as PropTypes from 'prop-types';
import styles from './styles.module.scss';

const OrderDetail = ({ order }) => {
  return (
    <div className={styles.process}>
      <div>
        <div className={styles.processHeader}>
          {order.title || 'Bestellung'}
        </div>
        <div className={styles.processNumber}>
          Positionen:
          {order.orderPositions?.length}
        </div>
        <div className={styles.processDetail}>
          Auftragstext:
          {' '}
          {order.customerOrderText}
        </div>
        <div className={styles.processDetail}>
          Auftragsnummer:
          {' '}
          {order.customerOrderNumber}
        </div>
      </div>
    </div>
  );
};

OrderDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.any,
};

export default OrderDetail;
