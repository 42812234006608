/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../constants/AsyncState';
import { getErrorMsg } from '../../helper/errorMessages';
import linkAccount from './currentCraftsmanUserThunks/linkAccount';

import { fetchAgentAvailabilityMessage } from './currentCraftsmanUserThunks/fetchAgentAvailabilityMessage';

const asyncState = {
  updateGcopAccount: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchAgentAvailability: {
    loading: 'idle',
    error: null,
  },
};

const initialState = {
  ...asyncState,
  gcopEditing: false,
  gcopUsername: '',
  gcopPassword: '',
  agentAvailabilityMessage: {},
};

const currentCraftsmanUserSlice = createSlice({
  name: 'currentCraftsmanUser',
  initialState,
  reducers: {
    setGcopUsername: (state, action) => {
      const { gcopUsername } = action.payload;
      state.gcopUsername = gcopUsername;
    },
    setGcopPassword: (state, action) => {
      const { gcopPassword } = action.payload;
      state.gcopPassword = gcopPassword;
    },
    startGcopEditing: (state) => {
      state.gcopEditing = true;
    },
    stopGcopEditing: (state) => {
      state.gcopEditing = false;
    },
    resetError: (state) => {
      state.updateGcopAccount.errorMessage = null;
    },
  },
  extraReducers: {
    [linkAccount.pending]: (state) => {
      state.updateGcopAccount.status = ASYNC_STATE.LOADING;
      state.gcopEditing = false;
      state.updateGcopAccount.errorMessage = null;
      state.gcopPassword = '';
    },
    [linkAccount.fulfilled]: (state) => {
      state.updateGcopAccount.status = ASYNC_STATE.SUCCEEDED;
    },
    [linkAccount.rejected]: (state, action) => {
      state.updateGcopAccount.status = ASYNC_STATE.FAILED;
      const {
        payload: { errorMessage } = {},
      } = action;
      if (errorMessage) {
        state.updateGcopAccount.errorMessage = errorMessage;
      } else {
        state.updateGcopAccount.errorMessage = getErrorMsg('op_set_credentials.default');
      }
    },
    [fetchAgentAvailabilityMessage.pending]: (state) => {
      state.fetchAgentAvailability.loading = 'pending';
    },
    [fetchAgentAvailabilityMessage.fulfilled]: (state, action) => {
      const { agentAvailabilityMessage } = action.payload;
      const { channelId } = action.meta.arg;
      state.fetchAgentAvailability.loading = 'idle';
      state.agentAvailabilityMessage[channelId] = agentAvailabilityMessage.message;
    },
    [fetchAgentAvailabilityMessage.rejected]: (state, action) => {
      state.fetchAgentAvailability.loading = 'idle';
      state.error = action.error;
    },
  },
});

export const {
  setGcopPassword,
  setGcopUsername,
  resetError,
  startGcopEditing,
  stopGcopEditing,
} = currentCraftsmanUserSlice.actions;

export default currentCraftsmanUserSlice.reducer;
