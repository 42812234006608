import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentFriendlyName,
  getCurrentUserCompany,
  getCurrentUserImage,
  getHasCurrentUserImage,
  getIsUpdateProfilePicturePending,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import PropTypes from 'prop-types';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { updateCurrentUser } from 'gcs-common/slices/currentUser/currentUserThunks/updateCurrentUser';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import SettingsInput from '../SettingsInput/SettingsInput';
import Button from '../../Button/Button';
import ModalWithCloseButton from '../../PopUpModal/ModalWithCloseButton/ModalWithCloseButton';
import SelectPhotoSourceButtons from '../../SelectPhotoSourceButtons/SelectPhotoSourceButtons';

import styles from './styles.module.scss';

const ProfileEditModal = ({ isOpen, onRequestClose, label = 'Modal' }) => {

  const currentFriendlyName = useSelector(getCurrentFriendlyName);
  const currentUserImage = useSelector(getCurrentUserImage);
  const currentUserHasImage = useSelector(getHasCurrentUserImage);
  const currentCompany = useSelector(getCurrentUserCompany);
  const isChatConnected = useSelector(getIsChatConnected);
  const isUpdatingProfilePicture = useSelector(getIsUpdateProfilePicturePending);
  const dispatch = useDispatch();

  const [userCompany, setUserCompany] = useState(currentCompany || '');
  const [userName, setUserName] = useState(currentFriendlyName || '');
  const [openSelectPhotoButtons, setOpenSelectPhotoButtons] = useState(false);

  useEffect(() => {
    setUserCompany(currentCompany || '');
  }, [currentCompany]);

  useEffect(() => {
    setUserName(currentFriendlyName || '');
  }, [currentFriendlyName]);

  const updateRemoteCompany = useCallback(async (newCompany) => {
    if (newCompany && newCompany.length > 0) {
      dispatch(updateCurrentUser({ values: { user_company: newCompany } }));
    }
  }, [dispatch]);

  const updateRemoteName = useCallback(async (newName) => {
    if (newName && newName.length > 0) {
      dispatch(updateCurrentUser({ values: { user_name: newName } }));
    }
  }, [dispatch]);

  const toggleSelectPhotoButtons = () => {
    setOpenSelectPhotoButtons(!openSelectPhotoButtons);
  };

  return (
    <ModalWithCloseButton label={label} onRequestClose={onRequestClose} isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.profileContainer}>
          <button
            type="button"
            disabled={!isChatConnected}
            onClick={toggleSelectPhotoButtons}
          >
            <div className={styles.profileImage}>
              {!currentUserHasImage ? (
                <div className={styles.overlay}>
                  Bild Hinzufügen
                </div>
              ) : (
                <div className={styles.overlay}>
                  Profilbild ändern
                </div>
              )}
              { isUpdatingProfilePicture ? (
                <div className={styles.loadingWrapper}>
                  <LoadingIndicator />
                </div>
              ) : (
                <img
                  src={currentUserImage}
                  className={styles.profileImagePic}
                  alt="Profilbild des Users"
                />
              )}
            </div>
          </button>

          {openSelectPhotoButtons && (
            <SelectPhotoSourceButtons toggleSelectPhotoButtons={toggleSelectPhotoButtons} />
          )}

          <div className={styles.profileDetailsContainer}>
            <SettingsInput
              name="Dein Name"
              onFinish={updateRemoteName}
              value={userName}
              onChange={setUserName}
              disabled={!isChatConnected}
            />

            <SettingsInput
              name="Firma"
              onFinish={updateRemoteCompany}
              value={userCompany}
              onChange={setUserCompany}
              disabled={!isChatConnected}
            />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button onClick={onRequestClose} className={styles.button}>
            Schließen
          </Button>
        </div>
      </div>
    </ModalWithCloseButton>
  );
};

ProfileEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default ProfileEditModal;
