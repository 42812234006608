import { useCallback, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useDispatch, useSelector } from 'react-redux';
import { isFirefox, isWindows } from 'react-device-detect';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputDisabled, getChannelInputPlaceholder,
  getChannelInputText, getChannelInputIsSendingDisabled,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { channelInputFileStaged } from 'gcs-common/slices/messageInput/messageInputSlice';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import { TWILIO_MAX_UPLOAD_SIZE } from 'gcs-common/constants/fileSizes';
import { changeInputText } from 'gcs-common/slices/messageInput/messageInputThunks/changeInputText';
import { sendCurrentMessageState } from 'gcs-common/slices/messageInput/messageInputThunks/sendCurrentMessageState';
import { showFailureNotification } from '../../../slices/notifications/ShowNotificationThunk';
import styles from './styles.module.scss';
import usePasteMedia from '../../../customHooks/usePasteMedia';

const MessageInputField = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const inputText = useSelector(getChannelInputText(selectedChannelId));
  const inputPlaceholder = useSelector(getChannelInputPlaceholder(selectedChannelId));
  const inputDisabled = useSelector(getChannelInputDisabled(selectedChannelId));
  const sendingDisabled = useSelector(getChannelInputIsSendingDisabled(selectedChannelId));
  const dispatch = useDispatch();

  const textAreaRef = useRef(null);
  const firefoxDivRef = useRef(null);

  const onPasteMedia = useCallback((files) => {
    if (files.length) {
      files.forEach((file) => {
        if (file.size > TWILIO_MAX_UPLOAD_SIZE) {
          const errorMessage = getErrorMsg('files.wrongFileSize', file.name, TWILIO_MAX_UPLOAD_SIZE / 1000000);
          dispatch(showFailureNotification(errorMessage));
        } else {
          dispatch(channelInputFileStaged({ channelId: selectedChannelId, file }));
        }
      });
      textAreaRef.current.focus();
    }
  }, [dispatch, selectedChannelId]);

  usePasteMedia(firefoxDivRef, onPasteMedia);

  const onMessageChanged = useCallback((event) => {
    const input = event.target.value;
    dispatch(changeInputText({ channelId: selectedChannelId, inputText: input }));
  }, [dispatch, selectedChannelId]);

  const onKeyDown = useCallback((event) => {
    if (event.keyCode === 13 && event.shiftKey === false && !sendingDisabled) {
      event.preventDefault();
      // TODO: Correct?
      dispatch(sendCurrentMessageState({ channelId: selectedChannelId }));
    }
  }, [dispatch, selectedChannelId, sendingDisabled]);

  const onFocus = useCallback(() => {
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.BEGIN_FOCUS()));
  }, [dispatch]);

  useEffect(() => {
    if (selectedChannelId) {
      textAreaRef.current.focus();
    }
  }, [selectedChannelId]);

  return (
    <>
      {isFirefox && isWindows && (
        <div
          ref={firefoxDivRef}
          contentEditable
          style={{
            position: 'absolute',
            top: -1e4,
            left: -1e4,
            overflow: 'hidden',
          }}
        />
      )}
      <TextareaAutosize
        ref={textAreaRef}
        id="msgInput"
        maxRows={12}
        disabled={inputDisabled}
        onChange={onMessageChanged}
        value={inputText}
        className={styles.writeInput}
        placeholder={inputPlaceholder}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
    </>
  );
};

export default MessageInputField;
