import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Button from '../Button/Button';
import PopUpModal from '../PopUpModal/PopUpModal';

const ErrorPopUp = ({ title, errorMessage, onClose }) => {
  const [showModel, setShowModel] = useState(!!errorMessage);

  useEffect(() => {
    if (errorMessage) {
      setShowModel(true);
    }
  }, [errorMessage, onClose]);

  const closeModal = useCallback(() => {
    onClose();
    setShowModel(false);
  }, [onClose]);

  return (
    <PopUpModal
      title={title}
      isOpen={showModel}
      onRequestClose={closeModal}
      className=""
    >
      <div className={styles.container}>

        <div className={styles.text}>
          {errorMessage}
        </div>
        <Button
          className={styles.button}
          onClick={closeModal}
        >
          Schließen
        </Button>
      </div>
    </PopUpModal>
  );
};

ErrorPopUp.propTypes = {
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorPopUp;
