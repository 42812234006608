class BaseClient {
  _init = false;

  // eslint-disable-next-line class-methods-use-this
  init() {
    throw Error('Init must be implemented');
  }

  _throwNotInitError() {
    throw new Error(`${this.constructor.name} is not initialized`);
  }
}

export default BaseClient;
