import { createSelector } from '@reduxjs/toolkit';
import FEATURE_FLAGS from '../../helper/featureFlagsHelper';
import { getHasFeatureFlag, getIsAgent } from '../currentUser/currentUserSelectors';
import { TOOL_TABS } from '../../constants/toolUIFlags';
import { getChannelHasMoreMessages, getMemberCraftsmenCountForChannel, getChannelIsInternal } from '../channel/channelSelectors';
import { getLoadPreviousMessagesError } from '../messages/messagesSelectors';
import { getTotalUnreadCount } from '../channels/unreadMessagesSelectors';
import { getTicketNotificationsCount } from '../tickets/ticketsSelectors';

const getUiState = state => state.uiState;
const getTools = state => state.uiState.tools;
export const getMediaFullscreenUrl = state => state.uiState.mediaFullscreenUrl;
export const getVideoFullscreenId = state => state.uiState.videoFullScreenId;
export const getVideoMessageId = state => state.uiState.videoMessageId;
export const getToolbarTab = state => state.uiState.toolbarTab;
export const getShowPrivacyTerms = state => state.uiState.showPrivacyTerms;
export const getProfileImageForEditing = state => state.uiState.profileImageForEditing;
export const getTeamchatImageForEditing = state => state.uiState.teamchatImageForEditing;
export const getTeamchatPicture = state => state.uiState.teamchatPicture;

export const getLeftBarTab = createSelector(
  getUiState,
  (uiState) => uiState.leftBarTab,
);

export const getRightBarTab = createSelector(
  getUiState,
  (uiState) => uiState.rightBarTab,
);

export const getSidebarCollapsed = createSelector(
  getUiState,
  (uiState) => uiState.sidebarCollapsed,
);

export const getETSEnabledForChannel = (channelId) => createSelector(
  [
    getHasFeatureFlag(FEATURE_FLAGS.ETS_ENABLED),
    getIsAgent,
    getChannelIsInternal(channelId),
  ],
  (
    etsEnabled,
    isAgent,
    isInternalChannel,
  ) => etsEnabled && isAgent && channelId && !isInternalChannel,
);

export const getFilteredTabs = (channelId) => createSelector(
  [
    getTools,
    getHasFeatureFlag(FEATURE_FLAGS.QUICKORDERING_ENABLED),
    getChannelIsInternal(channelId),
    getHasFeatureFlag(FEATURE_FLAGS.OP_DISABLED),
    getMemberCraftsmenCountForChannel(channelId),
    getETSEnabledForChannel(channelId),
  ],
  (
    tools,
    isQuickorderingEnabled,
    isInternalChannel,
    isOpDisabled,
    channelCraftsmenCount,
    isEtsEnabledForChannel,
  ) => {
    const filters = [];
    if (isInternalChannel || isOpDisabled || channelCraftsmenCount > 1) {
      filters.push(TOOL_TABS.GCOP);
    }

    if (!isEtsEnabledForChannel) {
      filters.push(TOOL_TABS.TICKETS);
    }

    if (!isQuickorderingEnabled) {
      filters.push(TOOL_TABS.ORDERS);
    }
    return tools.filter(tab => !filters.includes(tab.name));
  },
);

export const getCurrentToolBarTab = (channelId) => createSelector(
  [getToolbarTab, getFilteredTabs(channelId)],
  (toolbarTab, filtered) => {
    if (!filtered.find(tab => tab.name === toolbarTab)) {
      return filtered[0] && filtered[0].name;
    }
    return toolbarTab;
  },
);

export const getUploadFileError = createSelector(
  getUiState,
  (uiState) => uiState.uploadFileError,
);

export const getChannelHasMore = (channelId) => createSelector(
  getLoadPreviousMessagesError,
  getChannelHasMoreMessages(channelId),
  (prevMessageLoadingError, channelHasMoreMessages) => {
    return !prevMessageLoadingError && channelHasMoreMessages;
  },
);

export const isEditImageModalOpen = state => state.uiState.showChangeEditImageModal;
export const isWebcamModalOpen = state => state.uiState.showWebcamModal;

export const getTotalNumberOfUnreadNotifications = createSelector(
  getTotalUnreadCount,
  getTicketNotificationsCount(),
  (unreadMessagesCount, ticketNotificationsCount) => unreadMessagesCount + ticketNotificationsCount,
);
