import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { getCurrentUserId } from '../currentUser/currentUserSelectors';
import {
  getUsers,
} from '../users/usersSelectors';
import {
  getChannelMembers,
  getMembers,
  getMembersForChannel,
} from '../members/membersSelectors';
import { SalesSpaceChannelsBetweenGroupingSort } from '../../helper/channelOrderingHelper';
import {
  calculateChannelIsFreeSalesSpaceChannel, calculateChannelMemberUserIds,
} from '../../helper/channelHelpers';
import { getIsChatConnected } from '../chatConnection/chatConnectionSelectors';
import { getSalesSpaceChannelIdsByGrouping } from './salesSpaceChannelsSelectors/getSalesSpaceChannelIdsByGrouping';


export const getSalesSpaceChannelsProcessing = (state) => {
  return state.salesSpaceChannels.channelsBeingProcessed;
};

export const getSelectedSalesSpaceChannelsBetweenGroupSort = state => state
  .salesSpaceChannels.selectedSalesSpaceChannelsBetweenGroupSort;

export const getSalesSpaceChannelProcessing = (channelId) => createSelector(
  getSalesSpaceChannelsProcessing,
  (substituteChannelsBeingProcessed) => {
    return substituteChannelsBeingProcessed.includes(channelId);
  },
);

export const getChannelIsFreeSalesSpace = (channelId) => createSelector(
  createStructuredSelector({
    channelMembers: getChannelMembers,
    members: getMembers,
    users: getUsers,
    isConnected: getIsChatConnected,
  }),
  ({
    channelMembers,
    members,
    users,
    isConnected,
  }) => {
    const memberIds = channelMembers[channelId];
    if (!memberIds) {
      return true;
    }
    const channelMemberIds = calculateChannelMemberUserIds(memberIds, members);
    return calculateChannelIsFreeSalesSpaceChannel({
      channelMemberUserIds: channelMemberIds,
      users,
      isConnected,
    });
  },
);

export const getCurrentUserIsMemberOfChannel = (channelId) => createSelector(
  getMembersForChannel(channelId),
  getCurrentUserId,
  (members, currentUserId) => {
    return !!members?.find(member => member?.userId === currentUserId);
  },
);


export const getSalesSpaceChannelsGroupings = createSelector(
  getSalesSpaceChannelIdsByGrouping,
  getSelectedSalesSpaceChannelsBetweenGroupSort,
  (byGrouping, selectedBetweenGroupSort) => {
    if (!byGrouping) {
      return [];
    }
    const grouping = Object.keys(byGrouping);

    // between grouping sort
    const comparator = (() => {
      switch (selectedBetweenGroupSort) {
        case SalesSpaceChannelsBetweenGroupingSort.DATE:
          // OR moment(str2, 'dddd, l').diff(moment(str1, 'dddd, l'))
          return (str1, str2) => (moment(str1, 'dddd, l').isBefore(moment(str2, 'dddd, l')) ? 1 : -1);
        case SalesSpaceChannelsBetweenGroupingSort.ALPHABETICAL:
        default:
          return (str1, str2) => str1.toLowerCase().localeCompare(str2.toLowerCase());
      }
    })();
    return grouping.sort(comparator);
  },
);

export const getSalesSpaceChannelIdsForGrouping = (grouping) => createSelector(
  getSalesSpaceChannelIdsByGrouping,
  (byGrouping) => {
    return byGrouping && byGrouping[grouping];
  },
);
