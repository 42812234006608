
import { THEME } from '../../../constants/themes';

export const extractThemeFromQueryParam = () => {

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.hash.substring(url.hash.indexOf('?') + 1));

  return params.get('theme');
};

export const extractThemeFromWebUrl = (appThemes) => {
  const hostName = window.location.hostname.toLowerCase();

  if (hostName === 'localhost') {
    return THEME.GC;
  }

  let themeIdentifier = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const theme of Object.values(appThemes)) {
    const { url } = theme;
    const urlName = url.includes('de') ? url.replace('.de', '') : url;
    const urlRegex = new RegExp(`(\\w*.)?${urlName}(-(staging|rc|dev|test))?.de`);
    if (urlRegex.test(hostName)) {
      themeIdentifier = theme.identifier;
      break;
    }
  }

  return themeIdentifier;
};


export const extractThemeFromMobilePackage = (packageId, appThemes) => {
  let themeIdentifier = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const theme of Object.values(appThemes)) {
    if (theme.packageId === packageId) {
      themeIdentifier = theme.identifier;
      break;
    }
  }

  return themeIdentifier;
};


export const calculateApiBaseUrlForEnv = (apiBaseUrl) => {
  const envApiBaseUrl = import.meta.env.VITE_API_BASE_URL;
  if (envApiBaseUrl) {
    return envApiBaseUrl;
  }

  const env = import.meta.env.VITE_BUILD_ENVIRONMENT;
  if (env === 'production') {
    return apiBaseUrl;
  }

  // split by .de and attach the env then back the domain
  const [baseDomain] = apiBaseUrl.split('.de');
  return `${baseDomain}-${env}.de`;
};
