import { userUpdated } from './usersSlice';
import { UsersViews } from '../../constants/views';

export const updateUser = ({ user }) => async (dispatch) => {
  dispatch(userUpdated({ user }));
};

export const fetchUserSelectOptions = (search) => (dispatch, getState, { gccApiClient }) => {
  return gccApiClient.getUsers({ search, view: UsersViews.SELECT_OPTIONS });
};
