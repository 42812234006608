import PropTypes from 'prop-types';
import styles from './styles.module.scss';

export const Spinner = ({ style = {} }) => {
  return (
    <div style={{ ...style }} className={styles.loader} />
  );
};

Spinner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};
