import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getActiveHouseId } from 'gcs-common/slices/administration/houses/housesSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { ROUTES } from 'gcs-common/constants/routes';
import styles from './styles.module.scss';
import AdministrationSearchBar from '../AdministrationSearchBar/AdministrationSearchBar';
import AdministrationSidebar from '../AdministrationSidebar/AdministrationSidebar';
import InternetConnectionBanner from '../InternetConnectionBanner/InternetConnectionBanner';
import PermissionProtectedRoute from '../ProtectedRoutes/PermissionProtectedRoute';
import FeatureFlagProtectedRoute from '../ProtectedRoutes/FeatureFlagProtectedRoute';

const AdministrationWrapper = () => {

  const activeHouseId = useSelector(getActiveHouseId);
  const permissions = ['administration', 'global_admin'];

  if (!activeHouseId) return null;

  return (
    <PermissionProtectedRoute permission={permissions}>
      <FeatureFlagProtectedRoute
        disabledFlag={FEATURE_FLAGS.USER_MANAGEMENT_DISABLED}
        redirectTo={ROUTES.ROOT}
      >
        <div className={styles.colWrapper}>
          <div className={styles.sidebarCol}>
            <AdministrationSidebar />
          </div>
          <div className={styles.administrationWrapper}>
            <div className={styles.searchWrapper}>
              <AdministrationSearchBar />
            </div>
            <InternetConnectionBanner />
            {/* WARNING: HOUSE SELECTION IS A SPECIAL CASE, WE FORCE A RELOAD OF THE */}
            {/* CURRENTLY DISPLAYED DATA WITH THIS KEY */}
            <div key={activeHouseId} className={styles.contentWrapper}>
              <Outlet />
            </div>
          </div>
        </div>
      </FeatureFlagProtectedRoute>
    </PermissionProtectedRoute>
  );
};

export default AdministrationWrapper;
