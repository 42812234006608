import BaseConverter from './baseConverter';
import MemberModel from '../../../models/MemberModel';

// TODO: SMOOT-2846 This kind of temporarily, needs to be adjusted
export default class MemberConverter extends BaseConverter {
  // eslint-disable-next-line class-methods-use-this
  fromTwilioDataModel = (twilioMember) => {
    const {
      identity,
      sid,
      attributes,
      isTyping,
    } = twilioMember;

    const member = new MemberModel({
      id: attributes.id,
      mediaFromMailAddress: attributes.media_from_mail_address,
      externalId: sid,
      externalIdentity: identity,
      channelId: attributes.channel_id,
      userId: attributes.user_id,
      lastConsumedMessageIndex: attributes.last_consumed_message_index,
      unreadCount: attributes.unread_count,
      createdAt: attributes.created_at,
      updatedAt: attributes.updated_at,
      createdBy: attributes.created_by,
      updatedBy: attributes.updated_by,
      isTyping,
    });
    // copy fields to serializable object,
    // to fix the warning that "non-serializable value was detected in the state"
    return member.toPlain();
  };
}
