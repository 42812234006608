/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import ASYNC_STATE from '../../constants/AsyncState';
import { byId, byKey } from '../../helper/byKey';
import { fetchMemberEmail } from './membersThunks/fetchMemberEmail';
import { createMemberEmail } from './membersThunks/createMemberEmail';
// eslint-disable-next-line import/no-cycle
import { loadTwilioChannelsMembers } from '../chatConnection/chatConnectionThunks/twilioLoadingThunk';
import { allChannelsFetched } from '../channels/channelsThunks/allChannelsFetched';
import mergeObjects from '../../helper/mergeObjects';

const asyncState = {
  memberEmailFetch: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  memberEmailCreate: {
    status: ASYNC_STATE.IDLE,
    error: null,
  },
  loadTwilioChannelsMembers: {
    status: ASYNC_STATE.IDLE,
  },
};

const initialState = {
  ...asyncState,
  members: {},
  memberEmails: {},
  channelMembers: {},
};

const persistWhitelist = [
  'members',
  'memberEmails',
  'channelMembers',
];

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    memberUpdated: (state, action) => {
      const { member, member: { id } } = action.payload;
      state.members[id] = mergeObjects(state.members[id], member);
    },
    channelMemberRemoved: (state, action) => {
      const { channelId, member: { id: memberId } } = action.payload;
      const channelMembers = state.channelMembers[channelId];

      if (channelMembers) {
        state.channelMembers[channelId] = channelMembers.filter((id) => id !== memberId);
      }
    },
    channelMemberFetched: (state, action) => {
      const { channelId, member, member: { id: memberId } } = action.payload;
      state.members[memberId] = member;
      const channelMembers = state.channelMembers[channelId];
      if (!channelMembers) {
        state.channelMembers[channelId] = [memberId];
      } else {
        state.channelMembers[channelId] = [...new Set([
          ...state.channelMembers[channelId],
          memberId,
        ])];
      }
    },
  },
  extraReducers: {
    [fetchMemberEmail.pending]: (state) => {
      state.memberEmailFetch.status = ASYNC_STATE.LOADING;
    },
    [fetchMemberEmail.fulfilled]: (state, action) => {
      const { memberId, email } = action.payload;
      state.memberEmailFetch.status = ASYNC_STATE.SUCCEEDED;
      if (email) {
        state.memberEmails[memberId] = email;
      }
    },
    [fetchMemberEmail.rejected]: (state, action) => {
      state.memberEmailFetch.status = ASYNC_STATE.FAILED;
      state.error = action.error;
    },
    [createMemberEmail.pending]: (state) => {
      state.memberEmailCreate.status = ASYNC_STATE.LOADING;
    },
    [createMemberEmail.fulfilled]: (state, action) => {
      const { memberId, email } = action.payload;
      state.memberEmailCreate.status = ASYNC_STATE.SUCCEEDED;
      if (email) {
        state.memberEmails[memberId] = email;
      }
    },
    [createMemberEmail.rejected]: (state, action) => {
      state.memberEmailCreate.status = ASYNC_STATE.FAILED;
      state.error = action.error;
    },
    [allChannelsFetched.fulfilled]: (state, action) => {
      const { members } = action.payload;

      state.members = mergeObjects(state.members, byId(members));
      const membersByChannelId = byKey('channelId', members, true);
      Object.entries(membersByChannelId).forEach(([channelId, membersForChannel]) => {
        state.channelMembers[channelId] = membersForChannel.map(member => member.id);
      });
    },
    [loadTwilioChannelsMembers.pending]: (state) => {
      state.loadTwilioChannelsMembers.status = ASYNC_STATE.LOADING;
    },
    [loadTwilioChannelsMembers.fulfilled]: (state, action) => {
      const members = action.payload;
      state.loadTwilioChannelsMembers.status = ASYNC_STATE.SUCCEEDED;

      const membersArr = Object.values(members).flat();

      const membersByChannelId = byKey('channelId', membersArr, true);
      Object.entries(membersByChannelId).forEach(([channelId, membersForChannel]) => {
        state.channelMembers[channelId] = membersForChannel.map(member => member.id);
      });
      state.members = mergeObjects(state.members, byId(membersArr));
    },
    [loadTwilioChannelsMembers.rejected]: (state) => {
      state.loadTwilioChannelsMembers.status = ASYNC_STATE.FAILED;
    },
  },
});

export { persistWhitelist as membersPersistWhitelist };
export const {
  memberUpdated,
  channelMemberRemoved,
  channelMemberFetched,
} = membersSlice.actions;
export default membersSlice.reducer;
