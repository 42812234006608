import BaseModel from './BaseModel';

export default class ChannelModel extends BaseModel {
  externalId;

  channelType;

  friendlyName;

  membersCount;

  messagesCount;

  createdBySource;

  imageUrl;

  connectedToCraftnote;

  agentsLastConsumedMessageIndex;

  unreadCount;

  lastMessageDate;

  primaryHouseId;

  communicationAssignmentId; // TODO: This is for/from SalesSpaces

  readReceiptAllowance;

  mainContactId;

  lastMessage;

  constructor(
    {
      friendlyName,
      externalId,
      channelType,
      membersCount,
      messagesCount,
      createdBySource,
      imageUrl,
      connectedToCraftnote,
      agentsLastConsumedMessageIndex,
      unreadCount,
      lastMessageDate,
      primaryHouseId,
      communicationAssignmentId,
      readReceiptAllowance,
      mainContactId,
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
      id,
      lastMessage,
    },
  ) {
    super(id, createdAt, updatedAt, createdBy, updatedBy);
    this.friendlyName = friendlyName;
    this.externalId = externalId;
    this.channelType = channelType;
    this.membersCount = membersCount;
    this.messagesCount = messagesCount;
    this.createdBySource = createdBySource;
    this.imageUrl = imageUrl;
    this.connectedToCraftnote = connectedToCraftnote;
    this.agentsLastConsumedMessageIndex = agentsLastConsumedMessageIndex;
    this.unreadCount = unreadCount;
    this.lastMessageDate = lastMessageDate;
    this.primaryHouseId = primaryHouseId;
    this.communicationAssignmentId = communicationAssignmentId;
    this.readReceiptAllowance = readReceiptAllowance;
    this.mainContactId = mainContactId;
    this.lastMessage = lastMessage;
  }
}
