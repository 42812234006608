import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildAgentStatus, ONLINE_STATUS } from '../../helper/userStatusHelper';
import { AWAY_TYPES } from '../../constants/awayTypes';
import fetchCurrentUser from '../currentUser/currentUserThunks/fetchCurrentUser';

export const fetchAgentAvailabilityDate = createAsyncThunk(
  'agentAvailabilityDate/fetch',
  async (_, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAgentAvailabilityDate();
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { dates = [] } = response;
    const newAway = dates.reduce((newDates, d) => {
      const isoDate = new Date(d.date);
      const properDate = new Date(isoDate.getFullYear(), isoDate.getMonth(), isoDate.getDate());
      // eslint-disable-next-line no-param-reassign
      newDates[properDate.getTime()] = AWAY_TYPES[d.type];
      return newDates;
    }, {});
    return { agentAvailabilityDates: newAway };
  },
);

export const fetchAgentAvailabilityStatus = createAsyncThunk(
  'agentAvailabilityStatus/fetch',
  async ({ statusType }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getAgentAvailabilityStatus(statusType);
    const { status, type } = response;
    return { status, type };
  },
);

export const updateAgentAvailabilityStatus = createAsyncThunk(
  'agentAvailabilityStatus',
  async ({ status: statusUpdate, statusType }, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.setAgentAvailabilityStatus(statusUpdate, statusType);
    const { status, type } = response;
    return { status, type };
  },
);


export const fetchAgentOnlineStatus = createAsyncThunk(
  'agentOnlineStatus/fetch',
  async (arg, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.queryAgentOnlineStatus();
    const { availability } = response;
    return { agentOnlineStatus: availability };
  },
);

export const updateAvailabilityDate = createAsyncThunk(
  'agentAvailability/update',
  async ({ upDate, type }, { extra: { gccApiClient } }) => {
    const d = moment(upDate);
    // we just store date in bare utc like format -> date + 000 time
    const bareDateUTCLike = `${d.toISOString(true).split('T')[0]}T00:00:00.000Z`;
    if (type !== 'NONE') {
      await gccApiClient.upsertAgentAvailabilityDate(bareDateUTCLike, type);
    } else {
      await gccApiClient.deleteAgentAvailabilityDate(bareDateUTCLike);
    }
  },
);

export const updateAgentStatus = createAsyncThunk(
  'agentStatus/updateAgentStatus',
  async ({ statusType }, { dispatch, extra: { gccApiClient } }) => {
    const status = buildAgentStatus(statusType);
    await gccApiClient.updateCurrentUser({ status });
    await dispatch(fetchCurrentUser());
  },
);

export const updateAgentStatusOnChatConnected = createAsyncThunk(
  'agentStatus/updateAgentStatus',
  async ({ initialLogin }, { dispatch, extra: { gccApiClient } }) => {
    const currentUser = await gccApiClient.getCurrentUser();
    const lastUpdate = currentUser.status?.updatedAt;
    const now = moment().format();
    const isStatusUpdatedToday = moment(now).isSame(lastUpdate, 'day');
    if (!isStatusUpdatedToday) {
      dispatch(updateAgentStatus({ statusType: ONLINE_STATUS.ONLINE }));
    } else if (initialLogin) {
      dispatch(updateAgentStatus({ statusType: ONLINE_STATUS.IDLE }));
    }
  },
);
