import { ComponentProps } from 'react';
import styles from './styles.module.scss';

interface ButtonProps extends ComponentProps<'button'> {
  registrationButton?: boolean,
}

const Button = (props: ButtonProps) => {
  const {
    children = undefined, type = 'button', registrationButton = false, className = '', ...otherProps
  } = props;
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${styles.button} ${className} ${registrationButton ? styles.registrationButton : ''}`}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default Button;
