export const byKey = (keyName, arr, multipleElementsPerKey = false) => {
  return arr.reduce((red, element) => {

    const { [keyName]: key } = element;
    if (multipleElementsPerKey) {
      if (red[key] !== undefined) {
        return { ...red, [key]: [...red[key], element] };
      }
      return { ...red, [key]: [element] };
    }
    return { ...red, [key]: element };
  }, {});
};

export const byId = (arr) => {
  return byKey('id', arr);
};
