function setFavicon(favicon) {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = favicon;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}

function setManifest(identifier) {
  const manifestDomElement = document.getElementById('manifest');
  if (manifestDomElement) {
    manifestDomElement.setAttribute('href', `${document.location.origin}/manifests/${identifier}/manifest.json`);
  }
}

export const initAppTheme = ({ title, identifier, favicon }) => () => {
  document.title = title;
  document.documentElement.setAttribute('data-theme', `${identifier}-theme`);
  setFavicon(favicon);
  setManifest(identifier);
};
