/* eslint-disable react/button-has-type */

import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ButtonBeem = ({
  children, type = 'button', secondary = false, ...otherProps
}) => {

  return (
    <button
      type={type}
      className={`${styles.button} ${secondary && styles.secondary}`}
      {...otherProps}
    >
      {children}
    </button>
  );
};

ButtonBeem.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  secondary: PropTypes.bool,
};

export default ButtonBeem;
