import { createSelector } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../../constants/AsyncState';
import { currentPaginationEntities, toPaginationPaginationResult } from '../../../helper/reduxPaginationHelper';
import { toIsLoading } from '../../../helper/reduxLoadingStatusHelper';

export const getAllBusinessUnits = (state) => state.businessUnits.businessUnits;
export const getSelectedBusinessUnitId = (state) => state.businessUnits.selectedBusinessUnitId;

export const getSelectedBusinessUnit = createSelector(
  [getSelectedBusinessUnitId, getAllBusinessUnits],
  (businessUnitId, businessUnits) => businessUnits[businessUnitId],
);

const getBusinessUnitsState = state => state.businessUnits.fetch || {};

export const getBusinessUnits = createSelector(
  [getBusinessUnitsState, getAllBusinessUnits],
  currentPaginationEntities,
);

export const getBusinessUnitsPaginationResult = createSelector(
  [getBusinessUnitsState],
  toPaginationPaginationResult,
);

export const getBusinessUnitsLoading = createSelector(
  [getBusinessUnitsState],
  toIsLoading,
);


export const getSelectedBusinessUnitLoading = (state) => (
  state.businessUnits.fetchBusinessUnitDetails.status === ASYNC_STATE.LOADING
);

export const getDeleteBusinessUnitLoading = (state) => (
  state.businessUnits.delete.status === ASYNC_STATE.LOADING
);

export const getUpdateBusinessUnitErrorMessage = (state) => (
  state.businessUnits.update.errorMessage
);

export const getCreateBusinessUnitErrorMessage = (state) => (
  state.businessUnits.create.errorMessage
);

export const getDeleteBusinessUnitErrorMessage = (state) => (
  state.businessUnits.delete.errorMessage
);
