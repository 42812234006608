/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ALL_FILE_LIST_TYPES } from '../../constants/fileListConstants';
import loadPreviousMessagesForFileList from './fileListThunks/loadPreviousMessagesForFileList';
import ASYNC_STATE from '../../constants/AsyncState';

const asyncState = {
  loadPreviousMessagesForFileList: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  fileListTab: ALL_FILE_LIST_TYPES[0],
};

const fileListSlice = createSlice({
  name: 'fileList',
  initialState,
  reducers: {
    changeFileListTab: (state, action) => {
      const { fileListTab } = action.payload;
      state.fileListTab = fileListTab;
    },
  },
  extraReducers: {
    [loadPreviousMessagesForFileList.pending]: (state) => {
      state.loadPreviousMessagesForFileList.status = ASYNC_STATE.LOADING;
      state.loadPreviousMessagesForFileList.errorMessage = null;
    },
    [loadPreviousMessagesForFileList.fulfilled]: (state) => {
      state.loadPreviousMessagesForFileList.status = ASYNC_STATE.SUCCEEDED;
      state.loadPreviousMessagesForFileList.errorMessage = null;
    },
    [loadPreviousMessagesForFileList.rejected]: (state) => {
      state.loadPreviousMessagesForFileList.status = ASYNC_STATE.FAILED;
      state.loadPreviousMessagesForFileList.errorMessage = 'Fehler beim laden der vorherigen Nachrichten';
    },

  },
});

export const {
  changeFileListTab,
} = fileListSlice.actions;

export default fileListSlice.reducer;
