import { isClientMessageId } from './reduxOfflineHelper';
import { filterObjectExcludeMatchingKeys } from './objectHelpers';
import { byId } from './byKey';
import mergeObjects from './mergeObjects';


const sortMessages = (a, b) => {
  const isPendingA = isClientMessageId(a.id);
  const isPendingB = isClientMessageId(b.id);
  if (isPendingA && isPendingB) {
    return 0;
  }
  if (isPendingA) {
    return 1;
  }
  if (isPendingB) {
    return -1;
  }
  return a.index - b.index;
};

export const calculateChannelMessageOrder = (messages, channelMessages) => {
  return [...new Set([...channelMessages])]
    .map(id => messages[id])
    .sort(sortMessages)
    .map(m => m.id);
};

/**
 * Returns the clientMessageIds from the state that are included in
 * the remote nestedChannelMessages
 */
export const findPendingIdsIncludedInRemote = (stateMessages, nestedChannelMessages) => {
  const remoteClientMessageIds = Object.values(nestedChannelMessages)
    .flat()
    .map(m => m?.clientMessageId);
  const localClientMessageIds = Object.values(stateMessages)
    .map(m => m.id)
    .filter(id => isClientMessageId(id));
  return localClientMessageIds
    .filter(id => remoteClientMessageIds
      .includes(id));
};


export const calculateNewStateFromChannelMessages = (state, nestedChannelMessages) => {

  const idsToRemove = findPendingIdsIncludedInRemote(state.messages, nestedChannelMessages);
  // eslint-disable-next-line no-param-reassign
  state.messages = filterObjectExcludeMatchingKeys(state.messages, idsToRemove);

  Object.entries(nestedChannelMessages).forEach(([channelId, newMessagesForChannel]) => {
    // eslint-disable-next-line no-param-reassign
    state.messages = mergeObjects(state.messages, byId(newMessagesForChannel));

    const oldChannelMessages = state.channelMessages[channelId] || [];
    const oldChannelMessagesFiltered = oldChannelMessages.filter(
      id => !idsToRemove.includes(id),
    );
    const newMessageIds = newMessagesForChannel.map(message => message.id);
    // eslint-disable-next-line no-param-reassign
    state.channelMessages[channelId] = calculateChannelMessageOrder(
      state.messages,
      [
        ...oldChannelMessagesFiltered,
        ...newMessageIds,
      ],
    );
  });
};
