import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getChannelImage,
  getChannelStatus,
  getChannelMemberCount,
  getIsSalesSpaceChannelType,
  getIsSelectedChannel,
  getOtherMemberTyping,
  getNumOpenTasksForChannel,
} from 'gcs-common/slices/channel/channelSelectors';
import { getUnreadMessageCountForChannel } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import changeSelectedChannelId from 'gcs-common/slices/channels/channelsThunks/changeSelectedChannelId';
import styles from './styles.module.scss';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import typingIcon from '../../img/edit_even_lighter.svg';
import AnimatedSidebarButton from '../AnimatedSidebarButton/AnimatedSidebarButton';
import UnreadCountDisplay from '../UnreadCountDisplay/UnreadCountDisplay';

const getChannelStyle = ({ isSelectedChannel, isAgent }) => {

  const mainStyle = isAgent ? styles.channelListItem : styles.craftsmanChannelListItem;
  let selectedStyle = isAgent
    ? styles.unselectedChannelListItem
    : styles.unselectedCraftsmanChannelListItem;
  if (isSelectedChannel) {
    selectedStyle = isAgent
      ? styles.selectedChannelListItem
      : styles.selectedCraftsmanChannelListItem;
  }

  return `${mainStyle} ${selectedStyle}`;
};

const ChannelListItemCollapsed = ({ channelId }) => {
  const isAgent = useSelector(getIsAgent);
  const isSelectedChannel = useSelector(getIsSelectedChannel(channelId));
  const numOpenTasks = useSelector(getNumOpenTasksForChannel(channelId));
  const unreadCount = useSelector(getUnreadMessageCountForChannel(channelId));
  const otherUserTyping = useSelector(getOtherMemberTyping(channelId));
  const channelMemberCount = useSelector(getChannelMemberCount(channelId));
  const isSalesSpace = useSelector(getIsSalesSpaceChannelType(channelId));
  const channelImage = useSelector(getChannelImage(channelId));
  const channelStatus = useSelector(getChannelStatus(channelId));

  const channelStyle = useMemo(() => {
    return getChannelStyle({ isSelectedChannel, isAgent });
  }, [isAgent, isSelectedChannel]);

  const dispatch = useDispatch();
  const selectChannel = useCallback(() => {
    dispatch(changeSelectedChannelId({ selectedChannelId: channelId }));
  }, [channelId, dispatch]);

  return (
    <AnimatedSidebarButton
      onClick={selectChannel}
      className={channelStyle}
    >
      <ImageWithStatus
        imageUrl={channelImage}
        userStatus={channelStatus}
        channelMemberCount={channelMemberCount}
        isSalesSpace={isSalesSpace}
        isAgent={isAgent}
        className={otherUserTyping ? styles.channelImageTyping : styles.channelImage}
      />
      {otherUserTyping
      && (
        <img className={styles.userTyping} alt="user-typing" src={typingIcon} />
      )}
      {unreadCount > 0 && (
        <div className={styles.unreadMsgIndicator}>
          <p><UnreadCountDisplay unreadCount={unreadCount} /></p>
        </div>
      )}
      {numOpenTasks > 0 && (
        <div className={styles.todoIndicator}>
          <p>{numOpenTasks}</p>
        </div>
      )}
    </AnimatedSidebarButton>
  );
};

ChannelListItemCollapsed.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelListItemCollapsed;
