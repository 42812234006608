import { Response } from 'gcs-common/types/typeHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import TicketPart from '../TicketPart/TicketPart';
import styles from './styles.module.scss';

interface TicketPartsListProps {
  title: string;
  parts: Response<'/chat/ets/tickets/{ticket_id}/parts', 'get'>['parts']
  isLoading?: boolean;
}

const TicketPartsList = (props: TicketPartsListProps) => {
  const { title, parts = undefined, isLoading = false } = props;
  return (
    <div className={styles.section}>
      <div className={styles.title}>{title}</div>
      <div>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (!parts || parts.length < 1) && <div>Keine Teile gefunden</div>}
        {!isLoading && parts && (
          <div>
            {parts.map((part) => (
              <TicketPart
                key={part.kbn || part.description}
                kbn={part.kbn}
                description={part.description}
                manufacturer={part.manufacturer}
                amount={part.amount}
                articleNumber={part.articleNumber}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketPartsList;
