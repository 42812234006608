import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';

interface TicketSearchBarProps {
  searchInput: string;
  setSearchInput:(arg0: string)=> void;
}

const TicketSearchBar = (props: TicketSearchBarProps) => {
  const { searchInput, setSearchInput } = props;
  return (
    <div className={styles.searchContainer}>
      <IconComponent Icon={ICON.SEARCH} />
      <input
        className={styles.searchInput}
        onChange={event => setSearchInput(event.target.value)}
        placeholder="Titel und ID durchsuchen"
        value={searchInput}
        type="search"
      />
    </div>
  );
};

export default TicketSearchBar;
