import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { fetchTicketNotifications } from 'gcs-common/slices/tickets/ticketsThunks';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';

/** Fetches and continously updates ETS ticket notifications. */
const useTicketNotifications = ({ refetchOnWindowFocus } = {}) => {
  const dispatch = useDispatch();
  const etsEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.ETS_ENABLED));
  const isOnline = useSelector(getIsOnline);

  useEffect(() => {
    if (!etsEnabled || !isOnline) return undefined;

    dispatch(fetchTicketNotifications());

    // periodically refetch notifications from server
    // to be replaced with websockets once we have them
    const timeInterval = 1 * 60 * 1000; // 1 minute
    const intervalId = setInterval(() => {
      dispatch(fetchTicketNotifications());
    }, timeInterval);

    // refetch notifications instantly whenever user is coming to back to the app
    const onFocus = () => {
      dispatch(fetchTicketNotifications());
    };
    if (refetchOnWindowFocus) {
      window.addEventListener('focus', onFocus);
    }

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('focus', onFocus);
    };
  }, [dispatch, etsEnabled, refetchOnWindowFocus, isOnline]);
};

export default useTicketNotifications;
