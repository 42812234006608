import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';

// PROBLEM: WOULD NEED TO KNOW ABOUT ALL FILES!!!!
const FileInputWrapper = (
  {
    children,
    onChange,
    disabled = false,
    multiple = true,
    allowUploadSamefile = false,
    ...props
  },
) => {

  const fileInputRef = useRef(null);

  const filePicked = useCallback((event) => {
    const files = Array.from(event.target.files);
    onChange(files);
    fileInputRef.current.files = null;
  }, [onChange]);

  const onFileAttached = useCallback((event) => {
    if (allowUploadSamefile) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = null;
    }
  }, [allowUploadSamefile]);

  const attachFile = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={filePicked}
        onClick={onFileAttached}
        disabled={disabled}
        multiple={multiple}
      />
      <button
        type="button"
        onClick={attachFile}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

FileInputWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  allowUploadSamefile: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape),
};

export default FileInputWrapper;
