import styles from './styles.module.scss';
import Button from '../../../../Button/Button';

interface TicketDiscardModalProps {
  onCancel: VoidFunction;
  handleDiscard: VoidFunction;
}

const TicketDiscardModal = (props: TicketDiscardModalProps) => {
  const { onCancel, handleDiscard } = props;
  return (
    <div>
      <div className={styles.modalContent}>
        <p>
          Ihre bereits eingegebenen Daten gehen verloren, wenn Sie die Ticketerstellung abbrechen.
          Sind sie sich sicher, dass Sie die Formulardaten verwerfen möchten?
        </p>
      </div>
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          onClick={handleDiscard}
        >
          Daten Verwerfen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={onCancel}
        >
          Zurück zum Formular
        </Button>
      </div>
    </div>
  );
};

export default TicketDiscardModal;
