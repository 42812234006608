import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDeleteAgentDefaultErrorMessage,
  getDeleteAgentLoading,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import {
  resetDeleteAgentDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import Button from '../../../../Button/Button';
import { showFailureNotification } from '../../../../../slices/notifications/ShowNotificationThunk';
import AgentPropType from '../../../../../models/AgentPropType';


const AgentDeletionModal = ({ agent, onCancel, handleDelete }) => {

  const isDeleting = useSelector(getDeleteAgentLoading);
  const dispatch = useDispatch();
  const defaultErrorMessage = useSelector(getDeleteAgentDefaultErrorMessage);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetDeleteAgentDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteAgentDefaultErrorMessage());
  }, [dispatch]);

  return (
    <div className={styles.column}>
      {isDeleting ? <LoadingIndicator /> : (
        <>
          <p>
            Möchten Sie den Mitarbeiter
            {' '}
            {agent?.friendlyName}
            {' '}
            wirklich löschen?
          </p>
          <div className={styles.warningMessage}>
            Warnung: Der Mitarbeiter wird vollständig aus dem System
            entfernt und kann nicht wieder hergestellt werden.
          </div>
        </>
      )}
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButton}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

AgentDeletionModal.propTypes = {
  agent: AgentPropType,
  onCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AgentDeletionModal;
