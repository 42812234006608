import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class ReplyMessageModel extends AbstractMessageModel {

  constructor({ replyMessageId, ...args } = {}) {
    super({
      messageType: MESSAGE_TYPE.REPLY,
      ...args,
    });

    this.replyMessageId = replyMessageId;
  }
}
