import {
  useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentUserImage,
  getIsAgent,
  getCurrentUserStatus,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { updateAgentStatus } from 'gcs-common/slices/currentAgent/currentAgentThunks';
import { ONLINE_STATUS } from 'gcs-common/helper/userStatusHelper';
import { getUpdateAgentStatusLoading } from 'gcs-common/slices/currentAgent/currentAgentSelectors';
import { ICON, ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import IconComponent from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import checkIcon from '../../img/check.svg';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import PopupMenu from '../PopupMenu/PopupMenu';
import Button from '../Button/Button';

const CurrentUserImage = () => {
  const isAgent = useSelector(getIsAgent);
  const imageUrl = useSelector(getCurrentUserImage);
  const currentStatus = useSelector(getCurrentUserStatus);
  const updateAgentStatusLoading = useSelector(getUpdateAgentStatusLoading);
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();

  const setAgentStatus = useCallback((statusType) => async () => {
    await dispatch(updateAgentStatus({ statusType }));
    setShowMenu(false);
  }, [dispatch]);

  const { type: currentStatusType } = currentStatus;

  if (!isAgent) {
    return (
      <ImageWithStatus
        imageUrl={imageUrl}
        userStatus={currentStatus}
      />
    );
  }

  return (
    <div
      tabIndex={0}
      role="button"
      className={styles.button}
      onKeyUp={() => setShowMenu(true)}
      onClick={() => setShowMenu(true)}
      data-testid="current-user-image"
      data-teststatus={currentStatus.type}
    >
      <ImageWithStatus
        key="profile"
        imageUrl={imageUrl}
        userStatus={currentStatus}
      />
      <PopupMenu
        key="popup-menu"
        isVisible={showMenu}
        onHide={() => setShowMenu(!showMenu)}
      >
        <div className={styles.statusTitle}>
          <span>Status setzen</span>
          {updateAgentStatusLoading && <Spinner size={ICON_SIZE.X_SMALL} />}
        </div>
        <Button
          type="button"
          onClick={setAgentStatus(ONLINE_STATUS.ONLINE)}
          className={styles.status}
          data-testid="current-user-online-status-online"
          data-teststatus={currentStatusType === ONLINE_STATUS.ONLINE}
        >
          {currentStatusType === ONLINE_STATUS.ONLINE
            ? <img alt="Ausgewählte Sortierart" src={checkIcon} />
            : <img alt="" src={checkIcon} style={{ opacity: 0 }} />
          }
          <IconComponent Icon={ICON.ECLIPSE} size={ICON_SIZE.XXX_SMALL} color={ICON_COLOR.SUCCESS} style={{ marginRight: '12px' }} />
          Aktiv
        </Button>
        <Button
          type="button"
          onClick={setAgentStatus(ONLINE_STATUS.IDLE)}
          className={styles.status}
          data-testid="current-user-online-status-idle"
          data-teststatus={currentStatusType === ONLINE_STATUS.IDLE}
        >
          {currentStatusType === ONLINE_STATUS.IDLE
            ? <img alt="Ausgewählte Sortierart" src={checkIcon} />
            : <img alt="" src={checkIcon} style={{ opacity: 0 }} />
          }
          <IconComponent Icon={ICON.ECLIPSE} size={ICON_SIZE.XXX_SMALL} color={ICON_COLOR.WARNING} style={{ marginRight: '12px' }} />
          <div className={styles.nowrap}>In Kürze verfügbar</div>
        </Button>
        <Button
          type="button"
          onClick={setAgentStatus(ONLINE_STATUS.OFFLINE)}
          className={styles.status}
          data-testid="current-user-online-status-offline"
          data-teststatus={currentStatusType === ONLINE_STATUS.OFFLINE}
        >
          {currentStatusType === ONLINE_STATUS.OFFLINE
            ? <img alt="Ausgewählte Sortierart" src={checkIcon} />
            : <img alt="" src={checkIcon} style={{ opacity: 0 }} />
          }
          <IconComponent Icon={ICON.ECLIPSE} size={ICON_SIZE.XXX_SMALL} color={ICON_COLOR.ERROR} style={{ marginRight: '12px' }} />
          Inaktiv
        </Button>
      </PopupMenu>
    </div>
  );
};
export default CurrentUserImage;

CurrentUserImage.propTypes = {
};
