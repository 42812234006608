import moment from 'moment';

export const friendlyDate = (createdAt: string | undefined) => {
  if (!createdAt) return '';
  const momentObj = moment.parseZone(createdAt);
  return momentObj.format('DD MMM YYYY');
};

export const friendlyDateTime = (createdAt: string) => {
  const momentObj = moment.parseZone(createdAt);
  return momentObj.format('D. MMMM YYYY, HH:mm');
};

export const friendlyTimeOrDate = (date: string | undefined) => {
  if (!date) return '';
  const parsed = new Date(date);

  if (parsed.toDateString() === new Date().toDateString()) {
    return parsed.toLocaleTimeString('de-DE', {
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  return parsed.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const getNow = () => new Date();
export const getTodayStart = () => new Date(new Date().setHours(0, 0, 0, 0));

export const addDays = (date: string, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const subDates = (date1: Date, date2: Date): number => {
  return date1.valueOf() - date2.valueOf();
};
