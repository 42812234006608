import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useEffect } from 'react';
import { ROUTES } from 'gcs-common/constants/routes';
import { useNavigate } from 'react-router';

/**
 * Only renders children when the user has the appropriate permissions,
 * otherwise redirect the user to ROUTES.ROOT.
 */
const AdministrationProtectedRoute = ({ children }) => {
  const hasGlobalAdminPermission = useSelector(getHasPermission('global_admin'));
  const hasAdministrationPermission = useSelector(getHasPermission('administration'));
  const navigate = useNavigate();

  useEffect(() => {
    if (hasGlobalAdminPermission === false && hasAdministrationPermission === false) {
      navigate(ROUTES.ROOT, { replace: true });
    }
  }, [hasGlobalAdminPermission, hasAdministrationPermission, navigate]);

  if (hasGlobalAdminPermission === false && hasAdministrationPermission === false) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

AdministrationProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AdministrationProtectedRoute;
