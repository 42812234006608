import {
  useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessageIndex,
  getMessageMediaFilename,
  getMessageThumbnail,
} from 'gcs-common/slices/messages/messageSelector';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import useStateRef from 'gcs-common/hooks/useStateRef';
import styles from './styles.module.scss';
import UploadIcon from '../../../img/upload.svg?react';

function DocItem({ messageId }) {
  const thumbnail = useSelector(getMessageThumbnail(messageId));
  const filename = useSelector(getMessageMediaFilename(messageId));
  const messageIndex = useSelector(getMessageIndex(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();

  const [stateRef, ref] = useStateRef();
  const height = useMemo(() => {
    if (stateRef?.clientWidth > 10) {
      return stateRef.clientWidth;
    }
    return 100;
  }, [stateRef]);

  const scrollToMessage = useCallback(() => {
    dispatch(scrollToMessageId({ channelId: selectedChannelId, messageId, messageIndex }));
  }, [dispatch, messageIndex, messageId, selectedChannelId]);

  const filnameParts = filename && filename.split('.');
  const ext = filnameParts && filnameParts[filnameParts.length - 1];
  const overlay = (
    <div className={styles.docOverlay}>
      <div className={styles.docDesc}>{ext}</div>
    </div>
  );

  return (
    <>
      <div
        ref={ref}
        className={styles.filesItem}
        style={{ height }}
      >
        <div className={styles.img}>

          <button
            onClick={scrollToMessage}
            type="button"
            className={styles.docScrollto}
          >
            {overlay}

            {thumbnail
              ? (
                <img
                  alt="Dokument"
                  className={styles.img}
                  style={{ maxHeight: `${height}px` }}
                  src={thumbnail}
                />
              )
              : <UploadIcon className={styles.img} style={{ maxHeight: `${height}px` }} />
            }
          </button>
        </div>
      </div>
    </>
  );
}

DocItem.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default DocItem;
