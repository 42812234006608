import { createSelector } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../constants/AsyncState';
import { getCurrentUserId } from '../currentUser/currentUserSelectors';

export const getMembers = state => state.members.members;

export const getChannelMembers = state => state.members.channelMembers;

export const getMemberEmails = state => state.members.memberEmails;

export const getPromoEmailLoading = state => {
  return state.members.memberEmailFetch.status === ASYNC_STATE.LOADING;
};

export const getMemberIdsForChannel = (channelId) => createSelector(
  getChannelMembers,
  (channelMembers) => channelMembers[channelId],
);

export const getMembersForChannel = (channelId) => createSelector(
  getMemberIdsForChannel(channelId),
  getMembers,
  (channelMemberIds, members) => {
    return channelMemberIds && channelMemberIds.map(memberId => members[memberId]);
  },
);

export const getMember = (memberId) => createSelector(
  getMembers,
  (members) => members[memberId],
);

export const getMemberByExternalId = (externalId) => createSelector(
  getMembers,
  (members) => {
    return members && Object.values(members).find(member => member.externalId === externalId);
  },
);

export const getMyMemberId = (channelId) => createSelector(
  getMembersForChannel(channelId),
  getCurrentUserId,
  (channelMembers, currentUserId) => {
    return channelMembers && channelMembers.find((member) => member.userId === currentUserId)?.id;
  },
);

export const getMyMember = (channelId) => createSelector(
  getMembersForChannel(channelId),
  getCurrentUserId,
  (channelMembers, currentUserId) => {
    return channelMembers && channelMembers.find((member) => member.userId === currentUserId);
  },
);

export const getMyPromoEmail = (memberId) => createSelector(
  getMemberEmails,
  (memberEmails) => memberEmails && memberEmails[memberId],
);
