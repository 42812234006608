import { useSelector } from 'react-redux';
import {
  getIsGroupChat,
  getNonSelectedNewChannelOptionIds,
} from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import NewChannelItem from '../../NewChannelItem/NewChannelItem';
import styles from './styles.module.scss';

const NonGroupedNewChannelList = () => {
  const isGroupChat = useSelector(getIsGroupChat);
  const newChannelOptionIds = useSelector(getNonSelectedNewChannelOptionIds);

  return (
    <div className={styles.wrapper}>
      {newChannelOptionIds?.map((newChannelOptionId) => (
        <NewChannelItem
          newChannelOptionId={newChannelOptionId}
          key={newChannelOptionId}
          multiSelect={isGroupChat}
        />
      ))}
    </div>
  );
};

export default NonGroupedNewChannelList;
