import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  createHouseCommunicationStructureMembers,
  fetchHouseCommunicationStructures,
  updateHouseCommunicationStructureMembers,
  deleteHouseCommunicationStructure,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureThunks';
import {
  resetCreateCommunicationStructureDefaultErrorMessage,
  resetUpdateCommunicationStructureDefaultErrorMessage,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSlices';
import {
  CommunicationAssignmentType,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructuresConstants';
import {
  getCommunicationAssignmentMainRole,
  getCommunicationAssignmentMemberRole,
  getCommunicationStructure,
  getCreateCommunicationStructureDefaultErrorMessage,
  getUpdateCommunicationStructureDefaultErrorMessage,
  getCommunicationStructureInitialMembers,
  getCommunicationStructureMainMember,
  getCommunicationStructureOptionsForMainRole,
  getCommunicationStructureMemberOptions,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSelectors';
import { getSelectedHouseDetails } from 'gcs-common/slices/administration/houses/housesSelectors';
import { VALUE_KEY } from 'gcs-common/constants/dropdownSelectConstants';
import {
  showFailureNotification,
  showSuccessNotification,
} from '../../../../../slices/notifications/ShowNotificationThunk';
import RowOpen from '../../../shared/RowOpen/RowOpen';

const CommunicationStructureRowOpen = ({
  houseId,
  isTeamRow = false,
  isEditForm = false,
  communicationStructureId = '',
  setNewCommunicationStructureAssignmentType,
  setEditRowId,
  isModalOpen = false,
  onDeletion,
}) => {

  const validationSchema = Yup.object().shape({
    channelName: isTeamRow && Yup.string().required('Erforderlich'),
    assignmentDirection: Yup.string().nullable().required('Erforderlich'),
    // eslint-disable-next-line max-len
    mainRole: isTeamRow && Yup.string().nullable().when('channelImage', {
      is: (role) => !role,
      then: schema => schema.required(
        'Wenn es keinen Hauptkontakt gibt, müssen Sie ein Titelbild auswählen. Das Titelbild wird für alle Teamchats angezeigt.',
      ),
      otherwise: schema => schema,
    }),
    communicationStructureMembers: Yup.array().of(Yup.string()).nullable()
      .when('mainRole', {
        is: (mainRole) => !mainRole || mainRole.length === 0,
        then: schema => (isTeamRow
          ? schema.required('Entweder Hauptkontakt oder Benutzerdefinierte Kontakte sind erforderlich')
          : schema.required('Mindestens ein Mitarbeiter oder Team ist erforderlich')),
        otherwise: schema => schema,
      }),
    channelImage: isTeamRow && Yup.string().nullable(),
    isCraftnoteAutogenerate: Yup.bool().nullable(),
  });

  const dispatch = useDispatch();

  const createDefaultErrorMessage = useSelector(getCreateCommunicationStructureDefaultErrorMessage);
  const updateDefaultErrorMessage = useSelector(getUpdateCommunicationStructureDefaultErrorMessage);
  const communicationAssignmentMainRole = useSelector(getCommunicationAssignmentMainRole);
  const communicationAssignmentMemberRole = useSelector(getCommunicationAssignmentMemberRole);

  const communicationStructure = useSelector(getCommunicationStructure(
    communicationStructureId,
  ));

  const mainContactPerson = useSelector(
    getCommunicationStructureMainMember(communicationStructureId),
  );

  const communicationStructureMemberOptions = useSelector(getCommunicationStructureMemberOptions);

  const communicationStructureMembers = useSelector(
    getCommunicationStructureInitialMembers(communicationStructureId),
  );

  const mainContactPersonOptions = useSelector(getCommunicationStructureOptionsForMainRole);

  // eslint-disable-next-line max-len
  const mainContactPersonId = mainContactPerson?.customerResponsibilityRoleId || mainContactPerson?.agentId || null;

  const communicationStructureMembersIds = communicationStructureMembers.map(
    (member) => member[VALUE_KEY],
  );
  const selectedHouse = useSelector(getSelectedHouseDetails);
  const craftnoteId = selectedHouse?.craftnoteOrganizationId;

  const hasGlobalAdminPermission = useSelector(getHasPermission('global_admin'));

  const {
    channelName,
    channelImage,
    assignmentDirection,
    isCraftnoteAutogenerate,
  } = communicationStructure || {};

  const initialValues = useMemo(() => {
    return {
      channelName: channelName || '',
      channelImage,
      assignmentDirection,
      mainRole: mainContactPersonId,
      communicationStructureMembers: communicationStructureMembersIds,
      isCraftnoteAutogenerate: (
        isCraftnoteAutogenerate !== undefined ? isCraftnoteAutogenerate : !!craftnoteId
      ),
    };
  }, [
    channelName, channelImage, assignmentDirection, mainContactPersonId,
    communicationStructureMembersIds,
    isCraftnoteAutogenerate, craftnoteId,
  ]);

  useEffect(() => {
    if (createDefaultErrorMessage || updateDefaultErrorMessage) {
      dispatch(showFailureNotification(
        createDefaultErrorMessage || updateDefaultErrorMessage,
        (() => {
          dispatch(resetCreateCommunicationStructureDefaultErrorMessage());
          dispatch(resetUpdateCommunicationStructureDefaultErrorMessage());
        })(),
      ));
    }
  }, [createDefaultErrorMessage, updateDefaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateCommunicationStructureDefaultErrorMessage());
    dispatch(resetUpdateCommunicationStructureDefaultErrorMessage());
  }, [dispatch]);

  const onExpandButtonClick = useCallback(() => {
    setEditRowId(null);
    setNewCommunicationStructureAssignmentType(null);
  }, [setEditRowId, setNewCommunicationStructureAssignmentType]);

  const diff = (a, b) => a.filter(x => !b.includes(x));

  const memberOptionsHelper = (memberIds, options, roleId) => {
    return memberIds
      .filter(id => id !== null)
      .map(id => {
        const memberOption = options.find(option => option[VALUE_KEY] === id);
        return {
          ...memberOption,
          communicationAssignmentRoleId: roleId,
        };
      });
  };

  const submit = useCallback(async (values, { setSubmitting }) => {
    // eslint-disable-next-line max-len
    const newMainContactPersonId = diff([values.mainRole], [mainContactPersonId]);
    const deletedMainContactPersonId = (diff([mainContactPersonId], [values.mainRole]));
    // eslint-disable-next-line max-len
    const newMembersIds = diff(values.communicationStructureMembers, communicationStructureMembersIds);
    // eslint-disable-next-line max-len
    const deletedMembersIds = diff(communicationStructureMembersIds, values.communicationStructureMembers);

    const toBeAddedMemberOptions = [
      ...memberOptionsHelper(
        newMembersIds,
        communicationStructureMemberOptions,
        communicationAssignmentMemberRole.id,
      ),
      ...memberOptionsHelper(
        newMainContactPersonId,
        mainContactPersonOptions,
        communicationAssignmentMainRole.id,
      ),
    ];

    const toBeDeletedMemberOptions = [
      ...memberOptionsHelper(
        deletedMembersIds,
        communicationStructureMemberOptions,
        communicationAssignmentMemberRole.id,
      ),
      ...memberOptionsHelper(
        deletedMainContactPersonId,
        mainContactPersonOptions,
        communicationAssignmentMainRole.id,
      ),
    ];

    if (isEditForm) {
      // eslint-disable-next-line max-len
      const communicationStructureAttributes = ['channelName', 'channelImage', 'assignmentDirection', 'isCraftnoteAutogenerate'];
      const updatedStructureValues = communicationStructureAttributes.reduce((red, attr) => {
        if (values[attr] !== communicationStructure[attr]) {
          // eslint-disable-next-line no-param-reassign
          red[attr] = values[attr];
        }
        return red;
      }, {});

      const result = await dispatch(updateHouseCommunicationStructureMembers({
        id: communicationStructureId,
        structure: {
          ...updatedStructureValues,
          newCommunicationStructureMembers: toBeAddedMemberOptions.map(option => ({
            customerResponsibilityRoleId: option.customerResponsibilityRoleId,
            communicationAssignmentRoleId: option.communicationAssignmentRoleId,
            friendlyName: option.friendlyName,
            teamId: option.teamId,
            agentId: option.agentId,
          })),
          deletedCommunicationStructureMembers: toBeDeletedMemberOptions.map(option => ({
            customerResponsibilityRoleId: option.customerResponsibilityRoleId,
            communicationAssignmentRoleId: option.communicationAssignmentRoleId,
            friendlyName: option.friendlyName,
            teamId: option.teamId,
            agentId: option.agentId,
          })),
        },
      }));
      if (!result.error) {
        dispatch(showSuccessNotification('Erfolgreich geändert'));
        setEditRowId(null);
      }
    } else {
      const members = [
        ...(values.teamRoles || []), ...(values.individualRoles || []),
      ].map(role => ({
        communicationAssignmentRoleId: communicationAssignmentMemberRole.id,
        ...role,
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      values.mainRole && members.push({
        communicationAssignmentRoleId: communicationAssignmentMainRole.id,
        ...values.mainRole,
      });

      const result = await dispatch(createHouseCommunicationStructureMembers({
        structure: {
          houseId,
          channelName: values.channelName,
          channelImage: values.channelImage,
          assignmentDirection: values.assignmentDirection,
          assignmentType: isTeamRow
            ? CommunicationAssignmentType.SALES_SPACE
            : CommunicationAssignmentType.DIRECT,
          isCraftnoteAutogenerate: values.isCraftnoteAutogenerate,
          communicationStructureMembers: toBeAddedMemberOptions.map(option => ({
            customerResponsibilityRoleId: option.customerResponsibilityRoleId,
            communicationAssignmentRoleId: option.communicationAssignmentRoleId,
            friendlyName: option.friendlyName,
            teamId: option.teamId,
            agentId: option.agentId,
          })),
        },
      }));
      if (!result.error) {
        dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
        setEditRowId(null);
      }
    }

    dispatch(fetchHouseCommunicationStructures({ houseId }));
    setSubmitting(false);
    setNewCommunicationStructureAssignmentType(null);

  }, [
    isEditForm, dispatch, houseId, setNewCommunicationStructureAssignmentType,
    setEditRowId,
    communicationAssignmentMainRole.id, communicationStructureId, communicationStructure,
    communicationAssignmentMemberRole.id, isTeamRow,
    mainContactPersonId, communicationStructureMembersIds,
    mainContactPersonOptions, communicationStructureMemberOptions,
  ]);

  const deleteCommunicationStructure = useCallback(async (id) => {
    const result = await dispatch(deleteHouseCommunicationStructure({ id }));
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
      onDeletion();
    } else {
      dispatch(showFailureNotification('Fehler beim Löschen'));
    }
  }, [dispatch, onDeletion]);

  return (
    <RowOpen
      // Formik
      formikInitialValues={initialValues}
      validationSchema={validationSchema}
      // Booleans
      isEditForm={isEditForm}
      isTeamRow={isTeamRow}
      hasAdminPermission={hasGlobalAdminPermission}
      isDeleteEnabled={!isModalOpen}
      isHouseLevel
      // Ids
      communicationStructureId={communicationStructureId}
      // Members
      mainContactPerson={mainContactPerson}
      communicationStructureMembers={communicationStructureMembers}
      mainContactPersonOptions={mainContactPersonOptions}
      memberOptions={communicationStructureMemberOptions}
      // Functions
      onSubmit={submit}
      onExpandButtonClick={onExpandButtonClick}
      onCancel={() => {
        setNewCommunicationStructureAssignmentType(null);
        setEditRowId(null);
      }}
      onDelete={() => {
        setNewCommunicationStructureAssignmentType(null);
        setEditRowId(null);
        deleteCommunicationStructure(communicationStructureId);
      }}
    />
  );
};

CommunicationStructureRowOpen.propTypes = {
  houseId: PropTypes.string.isRequired,
  isTeamRow: PropTypes.bool,
  isEditForm: PropTypes.bool,
  setNewCommunicationStructureAssignmentType: PropTypes.func.isRequired,
  communicationStructureId: PropTypes.string,
  setEditRowId: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  onDeletion: PropTypes.func.isRequired,
};


export default CommunicationStructureRowOpen;
