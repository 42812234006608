import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE_TYPE } from 'gcs-common/constants/MessageTypes';
import {
  getMessageAttributesText, getMessageChannelId,
  getMessageDateCreated, getMessageHasSyncPending,
  getMessageImageUrl, getMessageImageUrlError,
  getMessageImgDimensions,
  getMessageMediaFilename,
  getMessageMediaHumanFilesize,
  getMessageMediaIsImage,
  getMessageType, getPendingMediaMessageLocalFile, getMessageThumbnail,
} from 'gcs-common/slices/messages/messageSelector';
import downloadMedia from 'gcs-common/slices/messages/messagesThunks/downloadMedia';
import openMessageMediaFullscreen
  from 'gcs-common/slices/messages/messagesThunks/openMessageMediaFullscreen';
import messageUpdateImageDimensions
  from 'gcs-common/slices/messages/messagesThunks/messageUpdateImageDimensions';
import fetchAndPreloadImageUrl
  from 'gcs-common/slices/messages/messagesThunks/fetchAndPreloadImageUrl';
import { getIsChatConnected } from 'gcs-common/slices/chatConnection/chatConnectionSelectors';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import brokenImage from 'gcs-common/img/broken_image.svg';
import styles from './styles.module.scss';
import FileOutline from '../../img/file_outline.svg?react';
import CubeIcon from '../../img/cube_primary.svg?react';
import { showDisabledNotification } from '../../slices/notifications/ShowNotificationThunk';
import LocalFilePreview from '../LocalFilePreview/LocalFilePreview';
import useWindowDimensions from '../../customHooks/useWindowDimensions';

const MediaMessage = ({
  messageId, selectionDisabled = false, maxHeight = 300, maxWidth, CustomTextComponent = null,
}) => {

  const messageType = useSelector(getMessageType(messageId));
  const dateCreated = useSelector(getMessageDateCreated(messageId));
  const isImage = useSelector(getMessageMediaIsImage(messageId));
  const text = useSelector(getMessageAttributesText(messageId));
  const mediaThumbnail = useSelector(getMessageThumbnail(messageId));
  const filename = useSelector(getMessageMediaFilename(messageId));
  const humanFileSize = useSelector(getMessageMediaHumanFilesize(messageId));
  const imgDimensions = useSelector(getMessageImgDimensions(messageId));
  const mediaUrl = useSelector(getMessageImageUrl(messageId));
  const mediaDownloadFailed = useSelector(getMessageImageUrlError(messageId));
  const channelId = useSelector(getMessageChannelId(messageId));
  const isJoinedChannel = useSelector(getCurrentUserIsMemberOfChannel(channelId));
  const pendingLocalFile = useSelector(getPendingMediaMessageLocalFile(messageId));
  const messageHasSyncPending = useSelector(getMessageHasSyncPending(messageId));
  const isChatConnected = useSelector(getIsChatConnected);

  const dispatch = useDispatch();

  const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();

  const formattedDateCreated = useMemo(() => dateCreated.format('L'), [dateCreated]);

  const onDownloadMedia = useCallback(async () => {
    if (selectionDisabled) {
      return;
    }
    if (!messageHasSyncPending) {
      dispatch(downloadMedia({ messageId }));
    } else {
      dispatch(showDisabledNotification('Nachricht wird gerade synchronisiert.'));
    }
  }, [selectionDisabled, dispatch, messageId, messageHasSyncPending]);

  const onImageClicked = useCallback(async () => {
    if (selectionDisabled) {
      return;
    }
    dispatch(openMessageMediaFullscreen({ messageId }));
  }, [selectionDisabled, dispatch, messageId]);

  const onImageLoad = useCallback((event) => {
    if (!isImage || imgDimensions) {
      return;
    }
    const { naturalWidth, naturalHeight } = event.target;
    dispatch(messageUpdateImageDimensions({
      messageId, width: naturalWidth, height: naturalHeight,
    }));

  }, [dispatch, imgDimensions, isImage, messageId]);

  useEffect(() => {
    if (isImage && isChatConnected && !messageHasSyncPending) {
      dispatch(fetchAndPreloadImageUrl({ messageId }));
    }
  }, [isChatConnected, messageHasSyncPending, dispatch, isImage, messageId]);

  const style = useMemo(() => {
    let width = '50';
    let height = '50';
    const maxHeightValue = maxHeight || viewportHeight * 0.3;
    const maxWidthValue = maxWidth || viewportWidth * 0.25;

    if (imgDimensions) {
      const maxResizeWidth = imgDimensions.w > maxWidthValue ? maxWidthValue : imgDimensions.w;
      const maxResizeHeight = imgDimensions.h > maxHeightValue ? maxHeightValue : imgDimensions.h;
      const aspectRatio = imgDimensions.w / imgDimensions.h;

      if (aspectRatio >= 1) { // landscape format
        width = maxResizeWidth;
        height = width / aspectRatio;
      } else { // portrait format
        height = maxResizeHeight;
        width = height * aspectRatio;
      }

      if (width > maxWidthValue) {
        width = maxWidthValue;
        height = width / aspectRatio;
      }

      if (height > maxHeightValue) {
        height = maxHeightValue;
        width = height * aspectRatio;
      }
    }

    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  }, [imgDimensions, maxHeight, maxWidth, viewportWidth, viewportHeight]);

  if (!isImage) {
    const downloadDesc = filename || 'Datei herunterladen';
    return (
      <div className={styles.mediaDownloadWrapper}>
        {
        mediaThumbnail
          ? (
            <img
              src={mediaThumbnail}
              className={styles.previewImage}
              alt="preview-placeholder"
            />
          )
          : <FileOutline className={styles.previewImage} />
        }
        <div className={styles.mediaInfo}>
          <div
            role="button"
            tabIndex={0}
            className={styles.downloadLinkButton}
            onClick={onDownloadMedia}
          >
            <span className={styles.downloadLink}>
              {CustomTextComponent ? <CustomTextComponent text={downloadDesc} /> : downloadDesc}
            </span>
            &nbsp;
            [
            {humanFileSize}
            ]
          </div>
          {text && (
            <div className={styles.messageText}>
              {CustomTextComponent ? <CustomTextComponent text={text} /> : text}
            </div>
          )}
          {messageType === MESSAGE_TYPE.MEDIA_FROM_MAIL && (
            <div className={styles.dateTag}>
              &nbsp;
              {formattedDateCreated}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (mediaUrl || pendingLocalFile) {
    return (
      <>
        <div
          role="button"
          tabIndex={0}
          className={
            `${styles.imgButton} ${!isJoinedChannel && !selectionDisabled ? styles.disabled : ''}`
          }
          onClick={onImageClicked}
        >
          {pendingLocalFile
            ? (
              <LocalFilePreview
                alt="Bild aus Nachricht"
                file={pendingLocalFile}
                style={{ ...style }}
                className={styles.img}
                data-testid={`message-image-${filename}`}
              />
            )
            : (
              <img
                alt="Bild aus Nachricht"
                className={styles.img}
                src={mediaUrl}
                onLoad={onImageLoad}
                style={{ ...style }}
                data-testid={`message-image-${filename}`}
              />
            )
          }
        </div>
        {text && (
          <div className={styles.messageText}>
            {CustomTextComponent ? <CustomTextComponent text={text} /> : text}
          </div>
        )}
      </>
    );
  }

  if (mediaDownloadFailed) {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px' }}>
          <img
            alt="Bild aus Nachricht"
            src={brokenImage}
          />
        </div>
        <div className={styles.messageText}>
          Mediendateien in Vorschau nicht verfügbar
        </div>

      </>
    );

  }

  return (
    <>
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', ...style,
      }}
      >
        <CubeIcon alt="loading-indicator" className={styles.imgLoader} />
      </div>
      {text && (
        <div className={styles.messageText}>
          {CustomTextComponent ? <CustomTextComponent text={text} /> : text}
        </div>
      )}
    </>
  );

};

export default MediaMessage;
MediaMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  selectionDisabled: PropTypes.bool,
  CustomTextComponent: PropTypes.elementType,
};
