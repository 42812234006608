// TODO: NOTE: This currently is only for development - otherwise createAsync wont print any errors
import { captureException } from '@sentry/react';

const thunkErrorToErrorObject = (action) => {
  const { error, payload: { errorCode } = {} } = action;
  let name = action.type ? `Exception in ${action.type}` : error.name;
  if (errorCode) {
    name = `${name}/${errorCode}`;
  } else if (error?.message) {
    name = `${name}/${error.message}`;
  }
  const errorObject = new Error(name);
  errorObject.stack = error.stack;
  return errorObject;
};

const reportError = (action, error) => {
  if (import.meta.env.VITE_BUILD_ENVIRONMENT !== 'production') {
    if (typeof action === 'function') {
      // eslint-disable-next-line no-console
      console.error('A thunk-creator caused an exception:', error);
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  captureException(error, { extra: {
    message: action?.error?.message,
    payload: action?.payload,
    args: action?.meta?.arg,
  } });
};

// eslint-disable-next-line consistent-return
const onExceptionMiddleware = _store => next => action => {
  try {
    if (action.error) {
      // createAsyncThunk automatically dispatches a rejected action
      // on errors, we need to report the error
      const error = thunkErrorToErrorObject(action);
      reportError(action, error);
    }
    return next(action);
  } catch (error) {
    reportError(action, error);
  }
};

export default onExceptionMiddleware;
