import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSelectUsers, fetchListOfUserDetails,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import {
  getAllAdministrationUsers,
  getUserDetailsLoading,
  getUsersBySelectKey,
  getUsersLoadingBySelectKey, getUserDetailsError,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import FormikSelect from '../../../FormikSelect/FormikSelect';

const FormikUserSelect = ({
  selectKey,
  name,
  placeholder = 'Suchen...',
  labelKey,
  valueKey,
  isMulti = false,
  userType,
  onChange,
  houseId,
  initialUserIds,
  localFilter,
  initialValue,
  companyId,
  ...props
}) => {

  const allUsers = useSelector(getAllAdministrationUsers);
  const users = useSelector(getUsersBySelectKey(selectKey));
  const usersLoading = useSelector(getUsersLoadingBySelectKey(selectKey));
  const userLoading = useSelector(getUserDetailsLoading(initialUserIds));
  const userError = useSelector(getUserDetailsError(initialUserIds));

  const dispatch = useDispatch();

  useEffect(() => {
    if (initialUserIds && initialUserIds.length > 0) {
      dispatch(fetchListOfUserDetails({ userIds: initialUserIds }));
    }
  }, [dispatch, initialUserIds]);

  const initialValues = useMemo(() => {
    if (initialUserIds) {
      return Object.values(allUsers).map((user) => user);
    }
    if (initialValue) {
      return initialValue;
    }
    return undefined;
  }, [initialUserIds, initialValue, allUsers]);

  const getUsers = useCallback((searchQuery) => {
    dispatch(fetchSelectUsers({
      selectKey,
      pageIndex: 0,
      pageSize: 10,
      houseId,
      searchQuery,
      userType,
      erpCustomerId: companyId,
    }));
  }, [dispatch, selectKey, houseId, userType, companyId]);

  return (
    <FormikSelect
      name={name}
      hasError={userError}
      placeholder={placeholder}
      labelKey={labelKey}
      valueKey={valueKey}
      isMulti={isMulti}
      onChange={onChange}
      isLoading={usersLoading || userLoading}
      options={!isMulti && initialValue ? [initialValue, ...users] : users}
      onInputChange={getUsers}
      initialValue={initialValues}
      localFilter={localFilter}
      {...props}
    />
  );
};

FormikUserSelect.propTypes = {
  userType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  initialUserIds: PropTypes.arrayOf(PropTypes.string),
  houseId: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  localFilter: PropTypes.arrayOf(PropTypes.shape),
  companyId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
};

export default FormikUserSelect;
