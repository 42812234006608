export const clearLocalStorageExcept = (keysToKeep) => {
  if (!localStorage) {
    return;
  }
  const keysToKeepSet = new Set(keysToKeep);

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (!keysToKeepSet.has(key)) {
      localStorage.removeItem(key);
      i--;
    }
  }
};
