import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSelectedChannelId } from '../../channels/channelsSelectors';
import { getChannelOldestLoadedMessage } from '../../messages/messagesSelectors';
import { getChannelHasMoreMessages } from '../../channel/channelSelectors';
import { getPreviousFileListLoading } from '../fileListSelectors';
import fetchMessages from '../../messages/messagesThunks/fetchMessages';

const loadPreviousMessagesForFileList = createAsyncThunk(
  'fileList/loadPreviousMessagesForFileList',
  async (_, { dispatch, getState }) => {
    const selectedChannelId = getSelectedChannelId(getState());
    const oldestMessage = getChannelOldestLoadedMessage(selectedChannelId)(getState());
    const messageIndex = oldestMessage ? oldestMessage.index : undefined;

    await dispatch(fetchMessages({
      channelId: selectedChannelId,
      fromMessageIndex: messageIndex,
      pageSize: 98, // TODO: SMOOT-3273
      singlePage: true,
    }));
  },
  {
    condition: (_, { getState }) => {
      const selectedChannelId = getSelectedChannelId(getState());
      return !getPreviousFileListLoading(getState())
        && getChannelHasMoreMessages(selectedChannelId)(getState());
    },
  },
);

export default loadPreviousMessagesForFileList;
