/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useSelector } from 'react-redux';
import {
  getTicketParts,
  getTicketPartsLoading,
} from 'gcs-common/slices/tickets/ticketsSelectors';
import { Response } from 'gcs-common/types/typeHelper';
import styles from './styles.module.scss';
import TicketPartsList from './TicketPartsList/TicketPartsList';

interface TicketPartsProps {
  ticketId: string;
}

const TicketParts = (props: TicketPartsProps) => {
  const { ticketId } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parts: Response<'/chat/ets/tickets/{ticket_id}/parts', 'get'> = useSelector(getTicketParts(ticketId));
  const isLoading = useSelector(getTicketPartsLoading(ticketId));

  return (
    <div className={styles.partsContainer}>
      <TicketPartsList title="Ersatzteile" parts={parts?.parts} isLoading={!parts && isLoading} />
      {parts?.alternatives?.length > 0 && <TicketPartsList title="Alternative Artikel" parts={parts.alternatives} />}
      {/* @ts-expect-error type mismatch with backend */}
      {parts?.defectives?.length > 0 && <TicketPartsList title="Defekte Artikel" parts={parts.defectives.map((p) => ({ ...p, amount: undefined }))} />}
    </div>
  );
};

export default TicketParts;
