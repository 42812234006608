/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
import moment from 'moment';
import { captureException } from '@sentry/react';
import { CHANNEL_TYPE } from './channelTypeHelper';
import USER_TYPE from './userTypeHelper';
import { assertNotUndefined } from './assertHelpers';
import { SalesSpaceChannelGrouping } from './channelOrderingHelper';
import { calculateUserStatus, ONLINE_STATUS } from './userStatusHelper';
import { calculateUserName } from './userHelper';

export const calculateChannelType = channel => {
  const { channelType } = channel || {};
  return channelType;
};

export const calculateChannelPrimaryHouseId = channel => {
  const channelType = calculateChannelType(channel);
  if ([CHANNEL_TYPE.SALES_SPACE, CHANNEL_TYPE.DIRECT_CHAT].includes(channelType)) {
    return channel?.primaryHouseId;
  }
  return undefined;
};

export const calculateChannelTitle = ({
  channelId,
  channel,
  users,
  channelMembers,
  members,
  currentUserId,
  isAgent,
}) => {
  assertNotUndefined(
    users,
    channelMembers,
    members,
  );
  const channelType = calculateChannelType(channel);
  const channelMemberUserIds = calculateChannelMemberUserIds(channelMembers[channelId], members);
  const memberCount = channelMemberUserIds?.length;

  if (channelType === CHANNEL_TYPE.SALES_SPACE) {
    return calculateSalesSpaceChannelTitle({
      isAgent,
      channelMemberUserIds,
      users,
      channel,
    });
  }

  if (memberCount > 2) {
    return calculateChannelMembersSortedByFriendlyName(
      {
        channelMemberUserIds,
        users,
        currentUserId,
      },
    ).map(chatUser => calculateUserName(chatUser)).join(', ');
  }

  return calculateDirectChannelTitle({ channelMemberUserIds, users, currentUserId });
};

export const calculateChannelSalesSpaceName = channel => channel?.friendlyName || 'Unbekannt (Sales Space)';

export const calculateSalesSpaceChannelTitle = ({
  isAgent,
  channelMemberUserIds,
  users,
  channel,
}) => {
  const salesSpaceName = calculateChannelSalesSpaceName(channel);

  if (isAgent) {
    const craftsmanId = calculateChannelCraftsmanUserIds({ channelMemberUserIds, users })[0];
    if (!craftsmanId) {
      return `Gelöschter Handwerker - ${salesSpaceName}`;
    }
    const craftsmanFriendlyName = calculateUserName(users[craftsmanId]);
    return `${craftsmanFriendlyName} - ${salesSpaceName}`;
  }

  return users[channel.mainContactId]?.friendlyName || salesSpaceName;

};

export const calculateChannelMembersSortedByFriendlyName = ({
  channelMemberUserIds = [],
  users,
  currentUserId,
}) => {

  const {
    craftsmenList,
    agentsList,
  } = channelMemberUserIds.reduce((red, memberUserId) => {
    if (memberUserId === currentUserId) return red;

    const user = users[memberUserId];
    if (!user) {
      return red;
    }
    if (!calculateUserName(user)) {
      return red;
    }
    if (users[memberUserId]?.userType === USER_TYPE.AGENT) {
      red.agentsList.push(user);
    } else if (users[memberUserId]?.userType === USER_TYPE.CRAFTSMAN) {
      red.craftsmenList.push(user);
    }
    return red;
  }, {
    craftsmenList: [],
    agentsList: [],
  });
  craftsmenList.sort((a, b) => calculateUserName(a).localeCompare(calculateUserName(b)));
  agentsList.sort((a, b) => calculateUserName(a).localeCompare(calculateUserName(b)));

  return [...craftsmenList, ...agentsList];

};

export const calculateDirectChannelTitle = ({
  channelMemberUserIds,
  users,
  currentUserId,
}) => {
  const channelCorrespondent = calculateChannelCorrespondent({
    channelMemberUserIds,
    users,
    currentUserId,
  });

  return calculateUserName(channelCorrespondent);
};

export const calculateDirectChannelUserId = ({
  channelMemberUserIds,
  users,
  currentUserId,
}) => {
  const channelCorrespondent = calculateChannelCorrespondent({
    channelMemberUserIds,
    users,
    currentUserId,
  });
  return channelCorrespondent?.id;
};

export const calculateChannelCorrespondent = ({
  channelMemberUserIds,
  users,
  currentUserId,
}) => {
  const correspondentId = channelMemberUserIds?.find((id) => id !== currentUserId);
  return correspondentId && users[correspondentId];
};

export const calculateChannelMemberUserIds = (channelMemberIds, members) => {
  return channelMemberIds
    && channelMemberIds.map(memberId => members[memberId] && members[memberId].userId);
};

export const calculateChannelSubtitle = ({
  channelId,
  channel,
  channelCorrespondent,
  channelMembers,
  members,
  users,
  isAgent,
}) => {
  const channelType = calculateChannelType(channel);
  const channelMemberUserIds = calculateChannelMemberUserIds(channelMembers[channelId], members);
  const memberCount = channelMemberUserIds?.length || 0;
  switch (channelType) {
    case CHANNEL_TYPE.SALES_SPACE: {
      if (isAgent) {
        const craftsmanId = calculateChannelCraftsmanUserIds({ channelMemberUserIds, users })[0];
        const craftsmanUser = users[craftsmanId];
        return calculateSingleCraftsmanSubtitle({ channel, craftsmanUser });
      }

      if (channel.mainContactId) {
        const salesSpaceName = channel?.friendlyName;
        return `${salesSpaceName} ・ Teamchat`;
      }
      return 'Teamchat';
    }

    case CHANNEL_TYPE.DIRECT_CHAT: {
      if (isAgent) {
        const craftsmanId = calculateChannelCraftsmanUserIds({ channelMemberUserIds, users })[0];
        const craftsmanUser = users[craftsmanId];
        return calculateSingleCraftsmanSubtitle({ channel, craftsmanUser });
      }

      if (memberCount > 2) {
        return 'Gruppe';
      }
      const agentId = calculateChannelAgentUserIds({ channelMemberUserIds, users })[0];
      const customerCompany = users[agentId]?.attributes?.customer_company;
      let subtitle = 'Direkter Chat';
      if (customerCompany) {
        subtitle = `${customerCompany} ・ ${subtitle}`;
      }
      return subtitle;

    }

    case CHANNEL_TYPE.INTERNAL_AGENT: {
      if (memberCount > 2) {
        return 'Gruppe';
      }

      return 'Intern';
    }

    case CHANNEL_TYPE.INTERNAL_CRAFTSMAN: {
      if (memberCount > 2) {
        return 'Gruppe';
      }

      const craftsmanUser = users[channelCorrespondent?.id];
      return calculateCraftsmanCustomerCompaniesNames({ craftsmanUser });
    }

    default:
      return null;
  }
};

export const calculateSingleCraftsmanSubtitle = ({ channel, craftsmanUser }) => {
  const primaryHouseId = calculateChannelPrimaryHouseId(channel);
  const channelConnection = craftsmanUser?.connections?.find(connection => {
    return connection.houseId === primaryHouseId;
  });
  const customerNumber = channelConnection?.customerNumber;
  const customerCompany = channelConnection?.friendlyName;

  let subtitle = '';

  if (customerNumber) {
    subtitle += customerNumber;
  }

  if (customerCompany) {
    if (customerNumber) {
      subtitle += '・';
    }
    subtitle += customerCompany;
  }

  return subtitle;
};

export const calculateCraftsmanCustomerCompaniesNames = ({ craftsmanUser }) => {
  const craftsmanConnectionNames = craftsmanUser
    ?.connections?.map(c => c.friendlyName);
  if (craftsmanConnectionNames) {
    return craftsmanConnectionNames.join(', ');
  }
  return undefined;
};

export const calculateChannelMemberCount = (channelMemberUserIds) => {
  return channelMemberUserIds && channelMemberUserIds.length;
};

export const calculateSalesSpaceChannelGroupingName = (
  selectedGrouping,
  channel,
  channelNotificationDate,
) => {
  switch (selectedGrouping) {
    case SalesSpaceChannelGrouping.DATE: {
      const { lastMessageDate } = channel;
      if (!lastMessageDate) {
        return moment(new Date()).format('dddd, l');
      }
      if (moment(channelNotificationDate).isValid()) {
        return moment.max(moment(lastMessageDate), moment(channelNotificationDate)).format('dddd, l');
      }
      return moment(lastMessageDate).format('dddd, l');
    }
    default:
      return '';
  }
};

function getMaxDate(dates) {
  // Filter out null or undefined dates, then sort and return the latest
  return dates.filter(date => !!date).sort((a, b) => -a.localeCompare(b))[0];
}

export const compareChannelByLatestMessageOrUpdate = (
  channelA,
  channelB,
  channelNotificationDate,
) => {
  const notificationDateA = channelNotificationDate ? channelNotificationDate[channelA.id] : null;
  const notificationDateB = channelNotificationDate ? channelNotificationDate[channelB.id] : null;
  const dateCreateA = getMaxDate([channelA.lastMessageDate, channelA.updatedAt, notificationDateA]);
  const dateCreateB = getMaxDate([channelB.lastMessageDate, channelB.updatedAt, notificationDateB]);
  // ISO8601 is designed for lexicographical sort,
  // so '2007-01-17T08:00:00Z' < '2008-01-17T08:00:00Z'  ===  true
  // TODO: dateCreateA is missing and it will crash
  // temp solution!!
  return dateCreateA ? -dateCreateA.localeCompare(dateCreateB) : false;
};

export const calculateChannelIsFreeSalesSpaceChannel = ({
  channelMemberUserIds, users, isConnected,
}) => {
  return channelMemberUserIds
    .filter(id => users[id]?.userType === USER_TYPE.AGENT)
    .every(id => {
      const userStatus = calculateUserStatus(isConnected, users[id], true);
      return userStatus?.type === ONLINE_STATUS.OFFLINE
          || userStatus?.type === ONLINE_STATUS.UNKNOWN;
    });
};

export const calculateRelevantChannelUserId = ({
  channelMemberIds,
  isAgent,
  users,
  currentUserId,
  channelType,
}) => {

  // Currently relevant users only exist for sales_space channels (craftsman)
  // and all other channels with only 2 members, so relevantUserId is always unique

  const membersCount = channelMemberIds.length;
  let relevantUserId = null;

  if (channelType === CHANNEL_TYPE.SALES_SPACE) {
    if (isAgent) {
      relevantUserId = channelMemberIds.find(id => {
        return users[id]?.userType === USER_TYPE.CRAFTSMAN;
      });
    }
  } else if (membersCount === 2) {
    relevantUserId = channelMemberIds.find(id => id !== currentUserId);
  }

  return relevantUserId;
};

export const calculateChannelCraftsmanUserIds = ({
  channelMemberUserIds = [],
  users,
}) => {
  return channelMemberUserIds.filter(id => users[id]?.userType === USER_TYPE.CRAFTSMAN);
};

export const calculateChannelAgentUserIds = ({
  channelMemberUserIds = [],
  users,
}) => {
  return channelMemberUserIds.filter(id => users[id]?.userType === USER_TYPE.AGENT);
};

export const calculateUnreadCount = ({
  channel,
  member,
}) => {
  if (!channel) {
    captureException(new Error('missing channel for unread count calculation'));
    return 0;
  }

  if (!member && channel.channelType === CHANNEL_TYPE.SALES_SPACE) {
    return channel.unreadCount;
  }

  if (!member) {
    captureException(new Error('missing member for unread count calculation'));
    return 0;
  }

  return member.unreadCount;
};
