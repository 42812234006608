import { isClientFavoriteId } from '../../../helper/reduxOfflineHelper';

export function extractTemporaryFavorites(favoritesState) {
  const temporaryFavorites = {};

  Object.entries(favoritesState).forEach(([channelId, channelFavorites]) => {
    Object.entries(channelFavorites).forEach(([favoriteId, favorite]) => {
      if (isClientFavoriteId(favoriteId)) {
        if (!temporaryFavorites[channelId]) {
          temporaryFavorites[channelId] = {};
        }

        temporaryFavorites[channelId][favoriteId] = favorite;
      }
    });
  });

  return temporaryFavorites;
}
