import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentFriendlyName, getCurrentUserId,
  getCurrentUserCompany, getCurrentUserImage,
  getIsAgent,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { closeSettings, setRightBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { getUserStatus } from 'gcs-common/slices/user/userSelectors';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { ICON, ICON_COLOR, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { getFavicon } from 'gcs-common/slices/theme/themeSelectors';
import { useLogoutMutation } from 'gcs-common/clients/api/entities/auth/auth-api';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import IconComponent from '../IconComponent/IconComponent';
import openHelp from '../../slices/settings/settingsThunks/openHelp';
import styles from './styles.module.scss';
import Button from '../Button/Button';
import SettingsButton from './SettingsButton/SettingsButton';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import ProfileEditModal from './ProfileEditModal/ProfileEditModal';
import FeatureFlagProtectedRoute from '../ProtectedRoutes/FeatureFlagProtectedRoute';
import CraftsmanConnectionsModal from './CraftsmanConnectionsModal/CraftsmanConnectionsModal';
import AccountSettings from './AccountSettings/AccountSettings';

const Settings = () => {
  const dispatch = useDispatch();

  const isAgent = useSelector(getIsAgent);
  const logo = useSelector(getFavicon);
  const currentUserId = useSelector(getCurrentUserId);
  const status = useSelector(getUserStatus(currentUserId));
  const userImage = useSelector(getCurrentUserImage);
  const currentFriendlyName = useSelector(getCurrentFriendlyName);
  const currentCompany = useSelector(getCurrentUserCompany);

  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [accountSettingsOpen, setAccountSettingsOpen] = useState(false);
  const [editGcopAccountsOpen, setEditGcopAccountsOpen] = useState(false);

  const logoutMutation = useLogoutMutation();

  async function onLogout() {
    await logoutMutation.mutateAsync();
  }

  const openPrivacyTab = useCallback(() => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.TERMS }));
  }, [dispatch]);

  const onCloseSettings = useCallback(() => {
    dispatch(closeSettings());
  }, [dispatch]);

  const onOpenHelp = useCallback(() => {
    dispatch(openHelp());
  }, [dispatch]);

  const onEditProfile = useCallback(() => {
    setEditProfileOpen(true);
  }, []);

  const onAccountSettings = useCallback(() => {
    setAccountSettingsOpen(true);
    dispatch(trackGALegacyEvent(LEGACY_EVENTS.SHOW_DELETE_CONFIRMATION()));
  }, [dispatch]);

  const onEditGcopAccounts = useCallback(() => {
    setEditGcopAccountsOpen(true);
  }, []);

  const rootSeparator = isAgent ? '' : styles.rootSeparatorLight;

  if (logoutMutation.isPending) {
    return <LoadingIndicatorFullScreen loadingText="Sie werden ausgeloggt..." />;
  }

  return (
    <>
      <div className={`${styles.settingsContainer} ${isAgent && styles.dark}`}>
        <div className={`${styles.settingsHeader} ${isAgent && styles.settingsHeaderDark}`}>
          <Button type="button" onClick={onCloseSettings} className={styles.settingsBack} title="Zurück" alt="Zurück">
            <IconComponent
              Icon={ICON.ARROW}
              color={isAgent ? ICON_COLOR.PRIMARY : ICON_COLOR.HIGHLIGHT}
              transform={ICON_TRANSFORM.ROTATE_180}
            />
            <div>Einstellungen</div>
          </Button>
          <img className={styles.gcLogoIcon} src={logo} alt="Zurück" />
        </div>
        <div className={rootSeparator} />
        <div className={styles.profileSection}>
          <ImageWithStatus
            className={styles.userImage}
            imageUrl={userImage}
            userStatus={status}
          />
          <div className={styles.profileSectionDetails}>
            <div className={styles.userName}>{currentFriendlyName}</div>
            <div className={styles.company}>{currentCompany}</div>
            <button
              type="button"
              onClick={onEditProfile}
              className={styles.changeProfileButton}
            >
              Profil bearbeiten
            </button>
          </div>
        </div>
        {!isAgent && (
          <>
            <SettingsButton
              icon={(
                <IconComponent
                  Icon={ICON.HOUSE}
                  color={ICON_COLOR.DARKEST_GREY}
                />
              )}
              text="Verknüpfungen bearbeiten"
              onClick={onEditGcopAccounts}
            />
          </>
        )}
        <SettingsButton
          icon={(
            <IconComponent
              Icon={ICON.SECURITY}
              color={ICON_COLOR.DARKEST_GREY}
            />
          )}
          text="Nutzungsbedigungen & Datenschutzrichtlinien"
          onClick={openPrivacyTab}
        />
        {isAgent && (
        <FeatureFlagProtectedRoute disabledFlag={FEATURE_FLAGS.SUPPORT_PAGE_DISABLED}>
          <SettingsButton
            icon={(
              <IconComponent
                Icon={ICON.INFORMATION}
                color={ICON_COLOR.DARKEST_GREY}
              />
            )}
            onClick={onOpenHelp}
            text="Informationen und Support"
          />
        </FeatureFlagProtectedRoute>
        )}
        {!isAgent && (
          <SettingsButton
            icon={(
              <IconComponent
                Icon={ICON.ACCOUNT_SETTINGS}
                color={ICON_COLOR.DARKEST_GREY}
              />
            )}
            onClick={onAccountSettings}
            text="Account Einstellungen"
          />
        )}

        <div className={styles.logoutContainer}>
          <button type="button" className={styles.logoutButton} onClick={onLogout}>
            Logout
          </button>

        </div>
      </div>
      {accountSettingsOpen && (
      <AccountSettings
        isOpen
        onRequestClose={() => setAccountSettingsOpen(false)}
      />
      )}
      <ProfileEditModal isOpen={editProfileOpen} onRequestClose={() => setEditProfileOpen(false)} label="Profil Bearbeiten" />
      <CraftsmanConnectionsModal isOpen={editGcopAccountsOpen} onRequestClose={() => setEditGcopAccountsOpen(false)} label="ONLINE PLUS Konto Verknüpfungen Bearbeiten" />
    </>
  );
};

export default Settings;

Settings.propTypes = {};
