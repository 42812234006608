import { getAppTheme, getApiBaseUrl } from 'gcs-common/slices/theme/themeSelectors';
import { openErrorDialog } from 'gcs-common/slices/errorDialogSlice/errorDialogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setIsInitialLogin } from 'gcs-common/slices/auth/authSlice';
import { buildAuth0UniversalLoginUrl } from './auth0-login-helpers';


function showErrorModal(error, dispatch) {
  let errorMsg = 'Es ist ein Fehler während der Anmeldung aufgetreten.';

  if (error.message === 'Wrong email or verification code.') {
    errorMsg = 'Der Login-Link ist ungültig oder wurde bereits verwendet.';
  } else if (error.message === 'Invalid state') {
    errorMsg = 'Es ist ein Fehler während der Anmeldung aufgetreten. Fehler-Code: \'Invalid state\'';
  } else if (error.message === 'The verification code has expired. Please try to login again.') {
    errorMsg = 'Die Gültigkeit des Login-Links ist abgelaufen.';
  } else if (error.message === 'You\'ve reached the maximum number of attempts. Please try to login again.') {
    errorMsg = 'Zu viele Login-Versuche. Bitte später nochmal versuchen.';
  } else if (['failed_to_create_auth_session', 'failed_to_exchange_authorization_code'].includes(error.message)) {
    errorMsg = 'Es ist ein Fehler aufgetreten, bitte probieren Sie es später nochmal.';
  } else if (['missing_authorization_code', 'missing_theme', 'missing_redirect_uri', 'missing_forward_to'].includes(error.message)) {
    errorMsg = `Es ist ein Fehler während der Anmeldung aufgetreten. Fehler-Code: '${error.message}'`;
  }

  dispatch(openErrorDialog({ dialogErrorMsg: errorMsg }));
}

export function useAuth0WebBrowserLoginFlow() {
  const appTheme = useSelector(getAppTheme);
  const apiBaseUrl = useSelector(getApiBaseUrl);

  function openAuth0UniversalLoginWeb(loginFlow) {
    if (!loginFlow) {
      throw Error('No loginFlow parameter given!');
    }
    if (!apiBaseUrl) {
      throw Error('No apiBaseUrl parameter given!');
    }
    const redirectBaseUrl = `${apiBaseUrl}/auth/auth0/login`;
    const forwardTo = window.location.href;
    const redirectUrl = `${redirectBaseUrl}?redirectUri=${redirectBaseUrl}&theme=${appTheme}&forwardTo=${forwardTo}`;

    const url = buildAuth0UniversalLoginUrl({
      loginFlow,
      redirectUrl,
      appTheme,
    });

    window.location = url;
  }

  return {
    openAuth0UniversalLoginWeb,
  };
}

export function useHandleUniversalLoginWebRedirect() {
  const dispatch = useDispatch();
  // Check for errorCode in query parameters
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.size === 0) {
    return;
  }

  const error = {
    message: queryParams.get('error'),
  };

  if (error?.message) {
    showErrorModal(error, dispatch);
  }

  const isInitialLogin = queryParams.get('initialLogin');
  if (isInitialLogin === 'true') {
    dispatch(setIsInitialLogin(true));
  }

  // Remove queryParams from the URL without refreshing the page
  const newUrl = `${window.location.pathname}`;
  window.history.replaceState({}, '', newUrl);
}
