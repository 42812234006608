import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPrimaryHouseForChannel } from '../../channel/channelSelectors';
import { getIsAgent } from '../../currentUser/currentUserSelectors';
import suggestionsReset from '../processesActions/suggestionsReset';

const fetchProcessDetails = createAsyncThunk(
  'processes/processDetails/fetch',
  async ({
    id,
    Number,
    Suffix,
    channelId,
  }, { dispatch, getState, extra: { gccApiClient } }) => {
    try {
      const isAgent = getIsAgent(getState());
      const houseId = getPrimaryHouseForChannel(channelId)(getState());
      dispatch(suggestionsReset());

      const response = await gccApiClient.getProcessDetails(
        Number,
        Suffix,
        isAgent ? id : null,
        houseId,
      );
      return { processDetails: response };
    } catch (e) {
      throw e;
    }
  },
);

export default fetchProcessDetails;
