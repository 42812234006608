/* eslint-disable css-modules/no-unused-class */

import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import ErrorIcon from '../../../../common/src/img/alert_error.svg?react';
import WarningIcon from '../../../../common/src/img/alert_warning.svg?react';
import InfoIcon from '../../../../common/src/img/alert_info.svg?react';
import SuccessIcon from '../../../../common/src/img/alert_success.svg?react';

const Alert = ({ type = 'error', children = 'This is an error alert — check it out!' }) => {
  return (
    <div className={`${styles.alert} ${styles[type]}`}>
      <div className={styles.icon}>
        {type === 'error' && <ErrorIcon />}
        {type === 'warning' && <WarningIcon />}
        {type === 'info' && <InfoIcon />}
        {type === 'success' && <SuccessIcon />}
      </div>
      <div className={styles.message}>
        { children }
      </div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default Alert;
