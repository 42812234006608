import { Dispatch, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { useGetDoneTasks } from 'gcs-common/clients/api/entities/tasks/tasks-api-selectors';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import styles from './styles.module.scss';
import Task from '../Task/Task';

interface DoneListProps {
  setTaskDueSelect:Dispatch<SetStateAction<FetchTasksResponseData['tasks'][number] | null>>;
  showAll: boolean;
}

const DoneList = ({ setTaskDueSelect, showAll }: DoneListProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId = useSelector(getSelectedChannelId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const doneTasks = useGetDoneTasks(selectedChannelId);

  const [alwaysShown, possiblyHidden] = useMemo(() => {
    return [doneTasks?.slice(0, 3), doneTasks?.slice(3)];
  }, [doneTasks]);

  return (
    <div className={styles.doneList}>
      <div className={styles.sectionTitle}>
        Erledigt!
      </div>
      <div>
        {alwaysShown && alwaysShown.map((task) => {
          return (
            <Task
              dark
              task={task}
              key={task.id}
              setTaskDueSelect={setTaskDueSelect}
            />
          );
        })}
      </div>
      <div className={styles.possiblyHidden} style={{ height: showAll ? 'auto' : '10px', overflow: showAll ? 'auto' : 'visible' }}>
        {possiblyHidden && possiblyHidden.map((task) => {
          return (
            <Task
              dark
              task={task}
              key={task.id}
              setTaskDueSelect={setTaskDueSelect}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DoneList;
