import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProcessSearchInput,
  getSuggestions, getSuggestionsLoading,
} from 'gcs-common/slices/processes/processesSelectors';
import { suggestionSelected } from 'gcs-common/slices/processes/processesSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { PROCESSES_SUGGESTION_TYPE } from 'gcs-common/constants/processesSuggestionType';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';


const SuggestionList = () => {
  const searchInput = useSelector(getProcessSearchInput);
  const suggestionsLoading = useSelector(getSuggestionsLoading);
  const suggestions = useSelector(getSuggestions);
  const selectedChannelId = useSelector(getSelectedChannelId);

  const dispatch = useDispatch();

  const selectSuggestion = useCallback((suggestion) => {
    dispatch(suggestionSelected({ suggestion, channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  if (suggestionsLoading) {
    return <LoadingIndicator key="loading-indicator" />;
  }

  if (searchInput.length > 0 && suggestions.length <= 0) {
    return (
      <div className={styles.errorMessage}>
        {`Keine Vorschläge für die Suche nach '${searchInput}'.`}
      </div>
    );
  }

  return (
    <div className={styles.suggestionList}>
      {
        suggestions.map((suggestion) => (
          <button
            type="button"
            onClick={() => selectSuggestion(suggestion)}
            className={styles.suggestionItem}
            key={`${suggestion.name}-${suggestion.type}-${suggestion.processNumber}`}
          >
            <div className={styles.suggestion}>
              {suggestion.name}
            </div>
            <div className={styles.suggestionType}>
              {PROCESSES_SUGGESTION_TYPE[suggestion.type]}
            </div>
          </button>
        ))
      }
    </div>
  );
};

export default SuggestionList;
