/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import avatarPlaceholder from 'gcs-common/img/user.svg';
import styles from './styles.module.scss';

const MemberHeader = ({
  name = '', image = '',
}) => {
  return (
    <div className={styles.profilePictureContainer}>
      <div className={styles.profilePicture}>
        {image ? (
          <img src={image} alt="selected user" />
        ) : (
          <img src={avatarPlaceholder} alt="selected user" />
        )}
      </div>
      <div className={styles.friendlyName}>{name}</div>
    </div>
  );
};

MemberHeader.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
};

export default MemberHeader;
