const imprintText = [
  <b>Angaben gemäß § 5 TMG:</b>,
  `
  
  `,
  <span>
    <b>Unternehmen 1:</b>
    <br />
    Hülsen KG
  </span>,
  `
  Flughafendamm 9
  28199 Bremen
  E-Mail-Adresse: `, <a href="mailto:info@huelsenkg.de">info@huelsenkg.de</a>,
  `

  `,
  <b>Vertretungsberechtigte Geschäftsführer:</b>,
  `
  Oliver Hülsen

  `,
  <b>Registergericht:</b>,
  `
  Bremen

  `,
  <b>Registernummer:</b>,
  `
  HRA 29690

  `,
  <b>USt-IdNr.:</b>,
  `
  DE357220286


  `,
  <span>
    <b>Unternehmen 2:</b>
    <br />
    ROSKE KG
  </span>,
  `
  Miethepfad 9
  12307 Berlin
  E-Mail-Adresse: `, <a href="mailto:info@lapone.de">info@lapone.de</a>,
  `

  `,
  <b>Vertretungsberechtigte Geschäftsführer:</b>,
  `
  Frank Rohleder

  `,
  <b>Registergericht:</b>,
  `
  Berlin Charlottenburg

  `,
  <b>Registernummer:</b>,
  `
  HRA 44126B

  `,
  <b>USt-IdNr.:</b>,
  `
  DE272852373

  `,
  <b>Niederlassungsanschrift:</b>,
  `
  LAPONE
  Buchholzer Straße 47-54
  13156 Berlin


  `,
  <b>Rechtliche Hinweise</b>,
  `

  `,
  <b>1. Inhalt des Onlineangebotes</b>,
  `

  Der Anbieter übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Anbieter, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden sind grundsätzlich ausgeschlossen, sofern seitens des Anbieter kein nachweislich vorsätzliches oder grob fahrlässiges Ver­schulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Anbieter behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte An­kündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.

  `,
  <b>2. Verweise und Links</b>,
  `

  Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb des Verantwortungsbereiches des Anbieters liegen, würde eine Haftungsverpflichtung aus­schließlich in dem Fall in Kraft treten, in dem der Anbieter von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger In­halte zu verhindern. Der Anbieter erklärt daher ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhal­te der gelinkten/verknüpften Seiten. Deshalb distanziert er sich hiermit ausdrücklich von al­len Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert wur­den. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Anbieter eingerichteten Gästebü­chern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige In­halte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Drittanbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Doku­mentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.

  `,
  <b>3. Urheber- und Kennzeichenrecht</b>,
  `

  Der Anbieter ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafi­ken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Gra­fiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetan­gebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterlie­gen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nen­nung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter ge­schützt sind! Das Copyright für veröffentlichte, vom Anbieter selbst erstellte Objekte bleibt allein beim Anbieter der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Anbieters nicht gestattet.`,
];

export default imprintText;
