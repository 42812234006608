import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CHANNEL_BRANDS } from 'gcs-common/helper/channelTypeHelper';
import craftnoteLogo from '../../img/craftnote-logo.svg';
import gcLogo from '../../img/gc-logo.svg';
import gutLogo from '../../img/gut-logo.svg';

const srcs = {
  [CHANNEL_BRANDS.CRAFTNOTE]: craftnoteLogo,
  [CHANNEL_BRANDS.GC]: gcLogo,
  [CHANNEL_BRANDS['G.U.T.']]: gutLogo,
};
const ChannelBrandLogo = ({ channelBrand }) => {
  const src = useMemo(() => {
    // TODO: enable all brand logos in the future
    if (channelBrand !== CHANNEL_BRANDS.CRAFTNOTE) {
      return undefined;
    }
    return srcs[channelBrand];
  }, [channelBrand]);
  return src ? <img src={src} alt="brand" /> : null;
};

ChannelBrandLogo.propTypes = {
  channelBrand: PropTypes.oneOf(Object.values(CHANNEL_BRANDS)).isRequired,
};

export default ChannelBrandLogo;
