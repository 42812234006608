import { useSelector } from 'react-redux';
import { getDisplayChannelsIds } from 'gcs-common/slices/channelList/channelListSelectors/getDisplayChannelsIds';
import styles from './styles.module.scss';
import ChannelListItemCollapsed from '../../ChannelListItemCollapsed/ChannelListItemCollapsed';

function AgentChannelListCollapsed() {

  const displayChannelIds = useSelector(getDisplayChannelsIds);
  return (
    <div className={styles.channelList}>
      {displayChannelIds.map(channelId => (
        <ChannelListItemCollapsed channelId={channelId} key={channelId} />
      ))
      }
    </div>
  );
}

export default AgentChannelListCollapsed;
