import md5 from 'crypto-js/md5';

export const hashSensitiveData = (obj) => {
  const sensitiveFields = ['email', 'firstName', 'friendlyName', 'lastName', 'phoneNumber', 'externalIdentity'];
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => hashSensitiveData(item));
    }
    const newObj = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (sensitiveFields.includes(key) && typeof obj[key] === 'string') {
        newObj[key] = md5(obj[key]).toString();
      } else {
        newObj[key] = hashSensitiveData(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
};
