/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


import ASYNC_STATE from '../../../constants/AsyncState';
import { fetchFeatureFlags, updateFeatureFlag } from './featureFlagsThunks';

const asyncState = {
  fetchFeatureFlags: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  featureFlags: [],
};

const erpCustomersSlice = createSlice({
  name: 'erpCustomers',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFeatureFlags.pending]: (state) => {
      state.featureFlags = [];
      state.fetchFeatureFlags.status = ASYNC_STATE.LOADING;
    },
    [fetchFeatureFlags.fulfilled]: (state, action) => {
      const { payload } = action;
      state.fetchFeatureFlags.status = ASYNC_STATE.SUCCEEDED;
      state.featureFlags = payload.featureFlags;
    },
    [updateFeatureFlag.fulfilled]: (state, action) => {
      const { payload } = action;
      state.featureFlags = payload.featureFlags;
    },
  },
});

export default erpCustomersSlice.reducer;
