import PropTypes from 'prop-types';

export const INVALID_ID = '-1';

const TaskPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  done: PropTypes.bool.isRequired,
  channelId: PropTypes.string.isRequired,
  messageId: PropTypes.string,
  messageMediaRelativePaths: PropTypes.arrayOf(PropTypes.string),
  dueDate: PropTypes.string,
  order: PropTypes.number,
});

TaskPropType.default = {
  messageId: null,
  messageMediaRelativePaths: [],
  duedate: '',
  order: Number(INVALID_ID),
};


export default TaskPropType;
