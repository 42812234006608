/* eslint-disable jsx-a11y/label-has-for */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Select, { components } from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { VALUE_KEY } from 'gcs-common/constants/dropdownSelectConstants';
import styles from './styles.module.scss';
import ValidationError from '../ValidationError/ValidationError';
import selectCustomStyles from './customStyles';
import Icon from '../../Icon/Icon';
import InfoTooltip from '../../InfoTooltip/InfoTooltip';

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon Icon={ICON.CLOSE} color={ICON_COLOR.ERROR} size={ICON_SIZE.SMALL} alt="error" style={{ cursor: 'pointer' }} />
    </components.ClearIndicator>
  );
};

const DropdownSelect = ({
  name = '',
  options: providedOptions,
  label = '',
  disabled = false,
  placeholder = 'Suchen...',
  labelKey,
  isMulti = false,
  isLoading = false,
  onChange,
  onInputChange,
  initialValue,
  hasError = false,
  isClearable = true,
  compareOptionsFunction,
  getOptionLabel,
  formatOptionLabel,
  formatMultiValue = null,
  optionBackgroundColor,
  optionsWidth,
  customFilter,
  tooltipText = null,
}) => {
  const [field, , helpers] = useField(name);
  const selectRef = useRef(null);
  // Note: We cache previously selected options, otherwise they will be removed from the select
  const currentlySelectedCache = useRef(
    // eslint-disable-next-line no-nested-ternary
    Array.isArray(initialValue) ? initialValue : initialValue ? [initialValue] : [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let options = [...providedOptions];

  // Note: When loading asyncronlously, add provided options if they are not already there
  if (currentlySelectedCache.current && currentlySelectedCache.current.length > 0) {
    // eslint-disable-next-line max-len
    const isSelectedOptionMissing = currentlySelectedCache.current && currentlySelectedCache.current.some(cacheOption => (
      !providedOptions.some(providedOption => providedOption[VALUE_KEY] === cacheOption[VALUE_KEY])
    ));
    if (isSelectedOptionMissing) {
      options = [...currentlySelectedCache.current, ...options];
    }
  }

  const handleChange = useCallback((option) => {
    let value;
    if (isMulti) {
      value = option && option.map(op => op[VALUE_KEY]);
      currentlySelectedCache.current = option;
      if (!value) {
        value = [];
      }
    } else {
      value = option && option[VALUE_KEY];
      currentlySelectedCache.current = option ? [option] : [];
    }
    helpers.setValue(value);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onChange && onChange(value);
  }, [helpers, isMulti, onChange]);

  const [handleInputChangedDebounced] = useDebouncedCallback(
    (inputValue) => {
      if (onInputChange && (isMulti || (!isMulti && inputValue !== ''))) {
        onInputChange(inputValue);
      }
    },
    300,
  );

  const areOptionsEqual = useCallback((value, option) => {
    if (compareOptionsFunction) {
      return compareOptionsFunction(value, option);
    }
    return value === option;
  }, [compareOptionsFunction]);

  // eslint-disable-next-line no-shadow
  const getOptionValue = (option) => option[VALUE_KEY];

  const value = useMemo(() => {
    if (isMulti) {
      return field.value && field.value.map(val => (
        options ? options.find((option) => areOptionsEqual(getOptionValue(option), val)) : ''
      ));
    }
    return options ? options.find((option) => areOptionsEqual(getOptionValue(option), field.value)) : '';
  }, [field.value, isMulti, options, areOptionsEqual]);

  useEffect(() => {
    if (!disabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onInputChange && onInputChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <div className={styles.formikSelectContainer}>
      {label && (
      <label
        className={styles.formikSelectLabel}
        htmlFor={field.name}
      >
        {label}
        {tooltipText && (
          <InfoTooltip
            text={tooltipText}
          />
        )}
      </label>
      )}
      <div className={`${styles.formikSelectWrapper} ${disabled ? styles.disabled : ''}`}>
        {(hasError && disabled) ? (
          <div
            className={styles.formikSelectError}
          >
            <Icon Icon={ICON.CLOSE} color={ICON_COLOR.ERROR} alt="error" />
            Fehler
          </div>
        ) : (
          <div ref={selectRef}>
            <Select
                // Behavior
              onInputChange={handleInputChangedDebounced}
              closeMenuOnScroll={(e) => e.target.contains(selectRef.current)}
              isClearable={isClearable}
              isDisabled={disabled}
              isLoading={isLoading}
              isMulti={isMulti}
              noOptionsMessage={() => 'Keine Optionen'}
              loadingMessage={() => 'Lädt...'}
              menuPortalTarget={document.body}
              onChange={handleChange}
              onBlur={field.onBlur}
                // Styles
              styles={selectCustomStyles}
              formatOptionLabel={formatOptionLabel}
              menuPlacement="auto"
              optionBackgroundColor={optionBackgroundColor}
              optionsWidth={optionsWidth}
              components={{ ClearIndicator, MultiValue: formatMultiValue }}
                // Content
              name={field.name}
              placeholder={disabled ? '-' : placeholder}
              options={options} // the options that can be selected
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel || (option => (labelKey ? option[labelKey] : option))}
              value={value || null}
              filterOption={customFilter}
            />
          </div>
        )}
      </div>
      <div className={styles.validationErrorWrapper}>
        <ValidationError name={name} />
      </div>
    </div>
  );
};

DropdownSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  onInputChange: PropTypes.func,
  compareOptionsFunction: PropTypes.func,
  getOptionLabel: PropTypes.func,
  optionBackgroundColor: PropTypes.string,
  optionsWidth: PropTypes.string,
  customFilter: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  formatMultiValue: PropTypes.func,
  tooltipText: PropTypes.string,
};

export default DropdownSelect;
