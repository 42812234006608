import { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { FILE_TYPE, FILE_TYPE_MAPPINGS } from 'gcs-common/constants/fileTypes';
import { getFileExtension } from 'gcs-common/helper/fileHelper';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import styles from './styles.module.scss';
import importIcon from '../../img/upload_white.svg';

const { FILE } = NativeTypes;
const DNDFileUploadWrapper = ({
  children, onError, onChange, showUploadImage = true, allowedFileType = FILE_TYPE.DEFAULT,
}) => {
  const [droppedFiles, setDroppedFiles] = useState(null);

  const validateFile = useCallback((file) => {
    const { maxSize } = FILE_TYPE_MAPPINGS[allowedFileType];
    if (file.size > maxSize) {
      onError(file, getErrorMsg('files.wrongFileSize', file.name, maxSize / 1000000));
      return false;
    }
    if (allowedFileType !== FILE_TYPE.DEFAULT) {
      const { fileExtensions } = FILE_TYPE_MAPPINGS[allowedFileType];
      const extension = allowedFileType === FILE_TYPE.EXCEL
        ? getFileExtension(file.name) : file.type;
      if (fileExtensions.indexOf(extension) === -1) {
        onError(file, getErrorMsg('files.wrongFileType', file.name, fileExtensions.join(', ')));
        return false;
      }
    }
    return true;
  }, [allowedFileType, onError]);

  const onFileDrop = useCallback((files) => {
    const isFolder = x => !x.type;
    const filteredDrops = [];
    // do not drop folders and ignore random stuff from dnd library
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    files && Object.keys(files).filter(k => k !== 'dropEffect' && !isFolder(files[k]))
      .forEach((key) => filteredDrops.push(files[key]));
    if (filteredDrops.length) {
      const validFiles = filteredDrops.filter(validateFile);
      onChange(validFiles);
    }
  }, [validateFile, onChange]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: useMemo(() => [FILE], []),
    drop: (item) => {
      setDroppedFiles(item.files);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (droppedFiles) {
      onFileDrop(droppedFiles);
      setDroppedFiles(null); // reset after handling
    }
  }, [droppedFiles, onFileDrop]);

  const isDropActive = canDrop && isOver;

  const DropFileOverlay = () => (
    <div className={styles.dropOverlay}>
      {showUploadImage && (
        <div className={styles.uploadLogo}>
          <div className={styles.uploadImg}>
            <img src={importIcon} alt="upload" />
          </div>
        </div>
      )}
      {showUploadImage && (
        <div className={styles.uploadText}>
          Laden Sie ihre Dateien hoch
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.content} ref={drop}>
      {children}
      {isDropActive && <DropFileOverlay />}
    </div>
  );
};

DNDFileUploadWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  showUploadImage: PropTypes.bool,
  allowedFileType: PropTypes.string,
};

export default DNDFileUploadWrapper;
