import { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { idValidatorRequired, stringValidator } from 'gcs-common/helper/yupValidationHelper';
import {
  createCustomerResponsibilityMember,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersThunk';
import {
  getCreateCustomerResponsibilityMemberErrorMessage,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSelector';
import {
  resetCreateCustomerResponsibilityMemberErrorMessage,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSlice';
import {
  CustomerResponsibilityMemberTypes,
  CustomerResponsibilityMemberTypesOptions,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersConstants';
import USER_TYPE from 'gcs-common/helper/userTypeHelper';
import { fetchSelectedErpCustomer } from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import { erpCustomerSelectionReset } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import FormikUserSelect from '../../shared/FormikUserSelect/FormikUserSelect';
import ErpCustomerSelect from '../../shared/ErpCustomerSelect/ErpCustomerSelect';
import CustomerResponsibilityRoleSelect
  from '../../shared/CustomerResponsibilityRoleSelect/CustomerResponsibilityRoleSelect';
import TeamSelect from '../../shared/TeamSelect/TeamSelect';

export const ResponsibilityMemberCreateValidationSchema = Yup.object().shape({
  erpCustomerId: idValidatorRequired,
  type: stringValidator,
  teamOrAgent: idValidatorRequired,
  role: idValidatorRequired,
});

const initialData = {
  erpCustomerId: undefined,
  type: '',
  teamOrAgent: undefined,
  role: undefined,
};

const CustomerResponsibilityMemberCreate = ({ onCancel, onSuccess }) => {
  const { id: erpCustomerId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateCustomerResponsibilityMemberErrorMessage);
  const [disableErpCustomerSelect, setDisableErpCustomerSelect] = useState(false);
  useEffect(() => {
    (async () => {
      if (erpCustomerId && location.pathname.includes('erpCustomers')) {
        await dispatch(fetchSelectedErpCustomer({ erpCustomerId }));
        initialData.erpCustomerId = erpCustomerId;
        setDisableErpCustomerSelect(true);
      } else {
        initialData.erpCustomerId = undefined;
        setDisableErpCustomerSelect(false);
      }
    })();
    return () => dispatch(erpCustomerSelectionReset());
  }, [erpCustomerId, location, dispatch]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetCreateCustomerResponsibilityMemberErrorMessage()),
      ));
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    dispatch(resetCreateCustomerResponsibilityMemberErrorMessage());
  }, [dispatch]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const result = await dispatch(createCustomerResponsibilityMember({ member: { ...values } }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={ResponsibilityMemberCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting, values }) => (
        <Form className={styles.column}>

          <div className={styles.row}>
            <ErpCustomerSelect
              label="Kundennummer/Firmennummer*"
              labelKey="nameWithNumber"
              valueKey="id"
              name="erpCustomerId"
              disabled={disableErpCustomerSelect}
            />
          </div>

          <div className={styles.row}>
            <FormikSelect
              options={CustomerResponsibilityMemberTypesOptions}
              label="Typ*"
              labelKey="label"
              valueKey="value"
              name="type"
            />
          </div>

          {values.type === CustomerResponsibilityMemberTypes.TEAM
                        && (
                        <div className={styles.row}>
                          <TeamSelect
                            label="Verkaufsmitarbeiter/Team*"
                            labelKey="name"
                            valueKey="id"
                            name="teamOrAgent"
                          />
                        </div>
                        )}
          {values.type === CustomerResponsibilityMemberTypes.INDIVIDUAL
                        && (
                        <div className={styles.row}>
                          <FormikUserSelect
                            selectKey="customerResponsibilityCreateAgentSelection"
                            userType={USER_TYPE.AGENT}
                            label="Verkaufsmitarbeiter/Team*"
                            name="teamOrAgent"
                            labelKey="friendlyName"
                            valueKey="id"
                          />
                        </div>
                        )
                    }
          {values.type === CustomerResponsibilityMemberTypes.INDIVIDUAL
                        && (
                        <div className={styles.row}>
                          <CustomerResponsibilityRoleSelect
                            roleType={CustomerResponsibilityMemberTypes.INDIVIDUAL}
                            label="Beteiligtenrolle*"
                            labelKey="friendlyName"
                            valueKey="id"
                            name="role"
                          />
                        </div>
                        )
                    }
          {values.type === CustomerResponsibilityMemberTypes.TEAM
                        && (
                        <div className={styles.row}>
                          <CustomerResponsibilityRoleSelect
                            roleType={CustomerResponsibilityMemberTypes.TEAM}
                            label="Beteiligtenrolle*"
                            labelKey="friendlyName"
                            valueKey="id"
                            name="role"
                          />
                        </div>
                        )
                    }

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CustomerResponsibilityMemberCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CustomerResponsibilityMemberCreate;
