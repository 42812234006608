export const HousesViews = {
  TABLE: 'TABLE',
  PLAIN: 'PLAIN',
  SELECT_OPTIONS: 'SELECT_OPTIONS',
};

export const UsersViews = {
  SELECT_OPTIONS: 'SELECT_OPTIONS',
};

export const TenantsViews = {
  TABLE: 'TABLE',
  SELECT_OPTIONS: 'SELECT_OPTIONS',
};
