/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import scrollToMessageId from './chatThunks/scrollToMessageId';
import ASYNC_STATE from '../../constants/AsyncState';


const asyncState = {
  scrollToMessageId: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  scrollDownLock: false,
  shouldScrollDownChat: false,
  shouldScrollToMessageId: null,
};


const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setScrollDownLock: (state, action) => {
      const { scrollDownLock } = action.payload;
      state.scrollDownLock = scrollDownLock;
    },
    scrollDownChat: (state) => {
      state.shouldScrollDownChat = true;
    },
    resetScrollDownChat: (state) => {
      state.shouldScrollDownChat = false;
    },
    resetScrollToMessageId: (state) => {
      state.shouldScrollToMessageId = null;
    },
    resetScrollToMessageError: (state) => {
      state.scrollToMessageId.errorMessage = null;
      state.scrollToMessageId.status = ASYNC_STATE.IDLE;
    },
  },
  extraReducers: {
    [scrollToMessageId.pending]: (state) => {
      state.scrollToMessageId.status = ASYNC_STATE.LOADING;
      state.scrollToMessageId.errorMessage = null;
    },
    [scrollToMessageId.fulfilled]: (state, action) => {
      state.scrollToMessageId.status = ASYNC_STATE.SUCCEEDED;
      const { messageId } = action.payload;
      state.scrollDownLock = false;
      state.shouldScrollToMessageId = messageId;
    },
    [scrollToMessageId.rejected]: (state) => {
      state.scrollToMessageId.status = ASYNC_STATE.FAILED;
      state.scrollToMessageId.errorMessage = 'Nachricht konnte nicht geladen werden';
    },

  },
});

export const {
  setScrollDownLock,
  scrollDownChat,
  resetScrollDownChat,
  resetScrollToMessageId,
  resetScrollToMessageError,
} = chatSlice.actions;

export default chatSlice.reducer;
