import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSubstituteNotificationText } from 'gcs-common/slices/messages/messageSelector';
import styles from './styles.module.scss';

/**
 * Kept for legacy reasons - "substitute channels" feature got replaced by "sales spaces"
 * But we still need so show substitute notification messages from old chats
 */
function SubstituteNotification({ messageId }) {
  const substituteNotificationText = useSelector(getSubstituteNotificationText(messageId));
  return (
    <div className={styles.chatNotification}>
      {substituteNotificationText}
    </div>
  );
}

SubstituteNotification.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default SubstituteNotification;
