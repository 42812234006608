import PropTypes from 'prop-types';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../../IconComponent/IconComponent';
import styles from './styles.module.scss';

const EditingCell = ({
  isEditing, setIsEditing, onSave, onCancel, children,
}) => {

  return (
    <div
      className={styles.wrapper}
    >
      {children}
      {isEditing ? (
        <>
          <button
            className={styles.saveButton}
            type="button"
            onClick={async () => {
              await onSave();
              setIsEditing(!isEditing);
            }}
          >
            <IconComponent Icon={ICON.SAVE} />
          </button>
          <button
            className={styles.saveButton}
            type="button"
            onClick={async () => {
              await onCancel();
              setIsEditing(!isEditing);
            }}
          >
            <IconComponent Icon={ICON.CLOSE} />
          </button>
        </>
      ) : (
        <button
          className={styles.saveButton}
          type="button"
          onClick={() => {
            setIsEditing(!isEditing);
          }}
        >
          <IconComponent Icon={ICON.EDIT} />
        </button>
      )}
    </div>
  );
};

EditingCell.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default EditingCell;
