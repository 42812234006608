import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchJoinedHouses = createAsyncThunk(
  'userHouses/fetchJoinedHouses',
  async (_, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getHousesForUser();

    return { joinedHouses: response };
  },
);

export default fetchJoinedHouses;
