import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';
import styles from './styles.module.scss';
import ProductPropType from '../../../models/ProductPropType';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

const ProductListItem = ({ product, onSelectProduct }) => {

  if (!product.ArticleNumber) {
    /* This is to remove <br> tags from GC Online Plus, also replace; with newline */
    let description1 = product.ArticleDescription1;
    description1 = reactStringReplace(description1, '<br>', () => (<br />));
    description1 = reactStringReplace(description1, ';', () => (<br />));

    let description2 = product.ArticleDescription2;
    description2 = reactStringReplace(description2, '<br>', () => (<br />));
    description2 = reactStringReplace(description2, ';', () => (<br />));

    return (
      <>
        <div className={styles.productTextOnly}>
          {description1}
        </div>
        <div className={styles.productTextOnly}>
          {description2}
        </div>
      </>
    );
  }

  return (
    <button
      type="button"
      onClick={() => onSelectProduct(product)}
      className={styles.productSmallWrapper}
      data-testid={`process-article-${product.ArticleNumber}`}
      data-testarticlenumber={product.ArticleNumber}
    >
      <div className={styles.productArticleWrapper}>
        <div className={styles.productArticleNumber}>
          {product.ArticleNumber}
        </div>
      </div>
      <div className={styles.productDescription}>
        <div>{product.ArticleDescription1}</div>
        <div>{product.ArticleDescription2}</div>
        {/* <ProductQuantity
          product={product}
        /> */}
      </div>
    </button>
  );
};

ProductListItem.propTypes = {
  onSelectProduct: PropTypes.func.isRequired,
  product: ProductPropType.isRequired,
};

export default ProductListItem;
