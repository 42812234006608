import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import activeIcon from 'gcs-common/img/circle_ticked_green.svg';
import unreachableIcon from 'gcs-common/img/status_unreachable.svg';
import inactiveIcon from 'gcs-common/img/status_disconnected.svg';
import OP_CONNECTION_STATUS from 'gcs-common/constants/OpConnectionStatus';
import { getCraftsmanConnectionStatus, getOpConnectionStatusText } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import styles from './styles.module.scss';

const OpConnectionStatus = ({ connectionId, withText = false }) => {
  const opConnectionStatus = useSelector(getCraftsmanConnectionStatus(connectionId));
  const statusText = useSelector(getOpConnectionStatusText(connectionId));

  const icon = useMemo(() => {
    switch (opConnectionStatus) {
      case OP_CONNECTION_STATUS.CONNECTED: {
        return (
          <img
            data-testid={`connection-${connectionId}-connected`}
            className={styles.icon}
            src={activeIcon}
            alt="Aktiv"
          />
        );
      }
      case OP_CONNECTION_STATUS.DISCONNECTED: {
        return (
          <img
            data-testid={`connection-${connectionId}-disconnected`}
            className={styles.icon}
            src={inactiveIcon}
            alt="Inaktiv"
          />
        );
      }
      case OP_CONNECTION_STATUS.UNREACHABLE: {
        return (
          <img
            data-testid={`connection-${connectionId}-unreachable`}
            className={styles.icon}
            src={unreachableIcon}
            alt="Inaktiv"
          />
        );
      }
      default: {
        return (<img className={styles.icon} src={inactiveIcon} alt="Inaktiv" />);
      }
    }
  }, [opConnectionStatus, connectionId]);

  return (
    <div className={styles.container}>
      <div className={styles.tooltipContainer}>
        {icon}
      </div>
      {withText && <div className={styles.text}>{statusText}</div>}
    </div>
  );
};

export default OpConnectionStatus;

OpConnectionStatus.propTypes = {
  withText: PropTypes.bool,
  connectionId: PropTypes.string.isRequired,
};
