import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPrimaryHouseForChannel } from '../../channel/channelSelectors';
import { getIsAgent } from '../../currentUser/currentUserSelectors';
import suggestionsReset from '../processesActions/suggestionsReset';

const fetchProcessesFilterSuggestions = createAsyncThunk(
  'processes/filter_suggestions/fetch',
  async ({
    queryString,
    id,
    channelId,
  }, { dispatch, getState, extra: { gccApiClient } }) => {
    const houseId = getPrimaryHouseForChannel(channelId)(getState());
    const isAgent = getIsAgent(getState());
    dispatch(suggestionsReset);

    const response = await gccApiClient.getProcessesFilterSuggestions(
      queryString,
      isAgent ? id : null,
      houseId,
    );

    // deduplicate based on Suggestion + Type
    const seen = {};
    const uniqueSuggestions = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of response) {
      const suggestionId = `${item.Suggestion}-${item.Type}`;
      if (seen[suggestionId] !== true) {
        seen[suggestionId] = true;
        uniqueSuggestions.push(item);
      }
    }

    const suggestions = uniqueSuggestions.map(a => ({
      name: a.Suggestion,
      type: a.Type,
      processNumber: a.ProcessNumber,
    }));

    return { suggestions };
  },
);

export default fetchProcessesFilterSuggestions;
