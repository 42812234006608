import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAppTheme, getAppTitle, getFavicon } from '../slices/theme/themeSelectors';
import { initAppTheme } from '../slices/theme/themeThunks';

const useTheme = () => {
  const appTheme = useSelector(getAppTheme);
  const favicon = useSelector(getFavicon);
  const appTitle = useSelector(getAppTitle);
  const dispatch = useDispatch();

  // just a workaround for now to set the appTheme outside of redux,
  // so when initializing the openapi-fetch client it is available
  localStorage.setItem('appTheme', appTheme);

  useEffect(
    () => {
      dispatch(initAppTheme({ title: appTitle, identifier: appTheme, favicon }));
    },
    [dispatch, appTitle, favicon, appTheme],
  );
};

export default useTheme;
