import { useSelector } from 'react-redux';
import { getSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelTypeFilter';
import { setSearchInput } from 'gcs-common/slices/channelList/channelListSlice';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { CHANNEL_FILTER_TYPES } from 'gcs-common/helper/channelTypeHelper';
import styles from './styles.module.scss';
import ChannelTypeSelector from '../ChannelTypeSelector/ChannelTypeSelector';
import SalesSpaceChannelListWrapper from '../SalesSpaceChannelListWrapper/SalesSpaceChannelListWrapper';
import ChannelSearchInput from '../ChannelSearchInput/ChannelSearchInput';
import DirectChannelListWrapper from '../DirectChannelListWrapper/DirectChannelListWrapper';

function AgentChannelListNormal() {

  const channelTypeFilter = useSelector(getSelectedChannelTypeFilter);

  return (
    <>
      <ChannelSearchInput type="searchInput" getSearchInput={getSearchInput} setSearchInput={setSearchInput} />
      <div className={styles.channelListTopBar}>
        <ChannelTypeSelector />
      </div>
      {channelTypeFilter === CHANNEL_FILTER_TYPES.DIRECT_CHATS
        ? (
          <DirectChannelListWrapper />
        )
        : <SalesSpaceChannelListWrapper />
      }
    </>
  );
}

export default AgentChannelListNormal;

AgentChannelListNormal.propTypes = {};
