import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Toggle = ({
  isToggled = false,
  onClick,
  testIdAttribute = undefined,
  disabled = false,
}) => {

  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(isToggled);
  }, [isToggled]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        onClick={() => !disabled && onClick(!toggled)}
        className={`${styles.toggleBackground} ${toggled && styles.toggledBackground} ${disabled && styles.disabled}`}
        role="switch"
        tabIndex={0}
        aria-checked={toggled}
        aria-disabled={disabled}
        data-testid={testIdAttribute}
      >
        <div className={`${styles.toggleCircle} ${toggled && styles.toggledCircle}`} />
      </div>
    </>
  );
};

Toggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  isToggled: PropTypes.bool,
  testIdAttribute: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Toggle;
