import { createAsyncThunk } from '@reduxjs/toolkit';

/* *
* These functions are important!
* If we want to get events (e.g.: 'messageUpdated', etc.) from twilio
* for our instances (e.g.: 'channels', 'members', etc.)
* We have to fetch these instances from Twilio to subscribe to twilio events.
* Also they are needed to load data from twilio which can be delivered by sockets
* and then we can overwrite state (channels, users, members, messages) with data from twilio
* Twilio data flow documentation https://gcdigitalteam.atlassian.net/wiki/spaces/SO/pages/1197015113/Chat+2.0+Data+Flow
* */

export const loadTwilioChannelsMembers = createAsyncThunk(
  'members/loadTwilioChannelsMembers',
  async ({ channels }, { extra: { chatClient } }) => {
    const channelsMembers = {};

    await Promise.all(channels.map(async channel => {
      const { participants: members } = await chatClient.getChannelParticipants(channel.externalId);
      channelsMembers[channel.id] = members;
      return channelsMembers[channel.id];
    }));

    return channelsMembers;
  },
);

export const loadTwilioCurrentUser = createAsyncThunk(
  'users/loadTwilioCurrentUser',
  async ({ currentUser }, { extra: { chatClient } }) => {
    const externalUserIdentity = currentUser.externalIdentity;
    if (chatClient.getInitStatus()) {
      return chatClient.getUser(externalUserIdentity);
    }
    return currentUser;
  },
);

export const loadTwilioUsers = createAsyncThunk(
  'users/loadTwilioUsers',
  async ({ users }, { extra: { chatClient } }) => {
    const externalUserIdentities = users.map(user => user.externalIdentity);
    return Promise.all(externalUserIdentities.map(async (identity) => {
      return chatClient.getUser(identity);
    }));
  },
);

export const loadTwilioChannelMessages = createAsyncThunk(
  'messages/loadTwilioChannelMessages',
  async ({ channel, messageIndexes }, { extra: { chatClient } }) => {
    return Promise.all(messageIndexes.map(async (messageIndex) => {
      return chatClient.getMessage(channel.externalId, messageIndex);
    }));
  },
);


export const loadTwilioChannels = createAsyncThunk(
  'channels/loadTwilioChannels',
  async ({ channels }, { extra: { chatClient } }) => {
    return Promise.all(channels.map(async channel => {
      return chatClient.getChannel(channel.externalId);
    }));
  },
);
