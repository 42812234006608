import { ReactNode } from 'react';
import styles from './styles.module.scss';

const Card = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.cardContainer}>{children}</div>
  );
};

export default Card;
