/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { allChannelsFetched } from './allChannelsFetched';
import {
  loadTwilioChannels,
  loadTwilioChannelsMembers,
  loadTwilioUsers,
} from '../../chatConnection/chatConnectionThunks/twilioLoadingThunk';

/**
 * Fetches all channels (joined and unjoined if param is set) of the current user
 * with twilio members, channels and users
 */

const fetchCurrentUserChannels = createAsyncThunk(
  'channels/fetchCurrentUserChannels',
  async ({ specificChannelId, channelTypes, onlyUnjoinedChannels, skipTwilio = false } = {},
    { dispatch, extra: { gccApiClient } }) => {

    const response = await gccApiClient.getCurrentUserChannels({
      specificChannelId,
      channelTypes,
      onlyUnjoinedChannels,
    });

    dispatch(allChannelsFetched({ ...response, replaceChannelTypes: channelTypes }));

    if (!skipTwilio) {

      const { channels, users } = response;
      await Promise.all([
        dispatch(loadTwilioChannels({ channels })),
        dispatch(loadTwilioChannelsMembers({ channels })),
        dispatch(loadTwilioUsers({ users })),
      ]);

    }

    return response;
  },
);


export default fetchCurrentUserChannels;
