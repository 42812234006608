import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelInputCleared } from 'gcs-common/slices/messageInput/messageInputSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import styles from './styles.module.scss';
import forwardIcon from '../../img/forward_white.svg';
import closeWhiteIcon from '../../img/x_white.svg';

const ForwardChannelListHeader = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(channelInputCleared({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  return (
    <div className={styles.header}>
      <img
        src={forwardIcon}
        className={styles.fileTypeLogo}
        alt="main-preview-img"
      />
      <div className={styles.headerText}>
        Nachricht weiterleiten
      </div>
      <button
        className={styles.closeButton}
        type="button"
        onClick={onClose}
      >
        <img
          src={closeWhiteIcon}
          alt="main-preview-close"
        />
      </button>
    </div>
  );
};

ForwardChannelListHeader.propTypes = {};

export default ForwardChannelListHeader;
