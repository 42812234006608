import {
  useState, useMemo,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getHiddenTaskReminders, getSelectedTaskId } from 'gcs-common/slices/tasks/tasksSelectors';
import { taskIdSelected, taskReminderHidden } from 'gcs-common/slices/tasks/tasksSlice';
import { LEGACY_EVENTS } from 'gcs-common/config/googleAnalyticsLegacyConfig';
import { trackGALegacyEvent } from 'gcs-common/slices/googleAnalytics/googleAnalyticsThunks';
import { useUpdateTasksOrderMutation } from 'gcs-common/clients/api/entities/tasks/tasks-api';
import { useGetNotDoneTasks } from 'gcs-common/clients/api/entities/tasks/tasks-api-selectors';
import { subDates } from 'gcs-common/helper/dateHelper';
import { moveArrayElement } from 'gcs-common/helper/arrayHelper';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import styles from './styles.module.scss';
import DoneList from '../DoneList/DoneList';
import TaskDatePicker from '../TaskDatePicker/TaskDatePicker';
import TaskDue from './TaskDue/TaskDue';
import AddTask from './AddTask/AddTask';
import Task from '../Task/Task';
import TaskListSeparator from './TaskListSeperator/TaskListSeparator';

const MS_IN_DAY = 1000 * 60 * 60 * 24;

const TaskList = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedTaskId = useSelector(getSelectedTaskId);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const notDoneTasks = useGetNotDoneTasks(selectedChannelId);
  const updateTasksOrderMutation = useUpdateTasksOrderMutation();
  const dispatch = useDispatch();

  const hiddenTaskReminders = useSelector(getHiddenTaskReminders);

  const earliestDueTask = useMemo<FetchTasksResponseData['tasks'][number] | null>(() => {
    let minTimeRemaining = Number.MAX_SAFE_INTEGER;
    let earliestDue = null;

    notDoneTasks?.forEach(task => {
      if (task.dueDate && (!hiddenTaskReminders.includes(task.id))) {
        const timeRemaining = subDates(new Date(task.dueDate), new Date()) / MS_IN_DAY;
        if (timeRemaining > 0 && timeRemaining < 1.0 && timeRemaining < minTimeRemaining) {
          earliestDue = task;
          minTimeRemaining = timeRemaining;
        }
      }
    });

    return earliestDue;
  }, [notDoneTasks, hiddenTaskReminders]);

  const earliestPastDueTask = useMemo<FetchTasksResponseData['tasks'][number] | null>(() => {
    let minTimeOverdue = Number.MAX_SAFE_INTEGER;
    let earliestPastDue = null;

    notDoneTasks?.forEach(task => {
      if (task.dueDate && (!hiddenTaskReminders.includes(task.id))) {
        const timeRemaining = subDates(new Date(task.dueDate), new Date()) / MS_IN_DAY;
        if (timeRemaining < 0 && Math.abs(timeRemaining) < minTimeOverdue) {
          earliestPastDue = task;
          minTimeOverdue = Math.abs(timeRemaining);
        }
      }
    });
    return earliestPastDue;
  }, [notDoneTasks, hiddenTaskReminders]);

  const [taskDueSelect, setTaskDueSelect] = useState<FetchTasksResponseData['tasks'][number] | null>(null);
  // whether tasks are collapsed or not
  const [isCollapsed, setCollapsed] = useState(false);

  const selectTaskId = (taskId: string) => dispatch(taskIdSelected({ taskId }));


  const agentTaskList = useMemo(() => {
    return notDoneTasks?.map((task, index) => (
      <Draggable
        isDragDisabled={selectedTaskId !== task.id}
        key={task.id}
        draggableId={task.id}
        index={index}
      >
        {(draggableProvided, draggableSnapshot) => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
          >
            <Task
              isDragging={draggableSnapshot.isDragging}
              task={task}
              key={task.id}
              setTaskDueSelect={setTaskDueSelect}
              dragHandleProps={draggableProvided.dragHandleProps}
              dark={false}
            />
          </div>
        )}
      </Draggable>
    ));
  }, [notDoneTasks, selectedTaskId]);

  const hideTaskReminder = (task: FetchTasksResponseData['tasks'][number]) => dispatch(taskReminderHidden(task.id));

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return;

    const reorderedTasks = moveArrayElement<FetchTasksResponseData['tasks'][number]>(
      notDoneTasks ?? [],
      result.source.index,
      result.destination.index,
    );

    updateTasksOrderMutation.mutate({
      body: {
        tasks: reorderedTasks.map((task, index) => ({ ...task, order: index })),
      },
    });
  };

  return (
    <div className={styles.taskList}>
      {taskDueSelect
        ? (
          <TaskDatePicker
            task={taskDueSelect}
            onSubmit={() => setTaskDueSelect(null)}
            onClose={() => setTaskDueSelect(null)}
          />
        )
        : (
          <>
            <div className={styles.taskTitle}>
              To-Dos
            </div>
            {(earliestDueTask && (!hiddenTaskReminders.includes(earliestDueTask.id)))
              && (
                <TaskDue
                  dueTask={earliestDueTask}
                  onClick={() => selectTaskId(earliestDueTask.id)}
                  onClose={() => {
                    hideTaskReminder(earliestDueTask);
                  }}
                />
              )}
            {(earliestPastDueTask && (!hiddenTaskReminders.includes(earliestPastDueTask.id)))
              && (
                <TaskDue
                  dueTask={earliestPastDueTask}
                  onClick={() => selectTaskId(earliestPastDueTask.id)}
                  taskDueClass={styles.notificationRed}
                  onClose={() => {
                    hideTaskReminder(earliestPastDueTask);
                  }}
                />
              )}
            <div className={isCollapsed ? styles.taskContentCollapsed : styles.taskContent}>
              {agentTaskList?.length !== 0 && (
                <>
                  <div className={styles.taskTypeHeader}>
                    Meine To Dos
                  </div>
                  <div>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {agentTaskList}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </>
              )}
              <AddTask
                setTaskDueSelect={setTaskDueSelect}
              />
            </div>
            <TaskListSeparator
              action={() => {
                dispatch(trackGALegacyEvent(LEGACY_EVENTS.TOGGLE_FINISHED_LIST()));
                setCollapsed(!isCollapsed);
              }}
              isCollapsed={isCollapsed}
            />
            <DoneList
              showAll={isCollapsed}
              setTaskDueSelect={setTaskDueSelect}
            />
          </>
        )
      }
    </div>
  );
};

export default TaskList;
