import PropTypes from 'prop-types';
import PlayIcon from 'gcs-common/img//movie.svg?react';
import { getVideoThumbnailUrl } from 'gcs-common/helper/videoHelper';
import styles from './styles.module.scss';

const VideoStreamThumbnail = ({ playbackId, title = 'video', onClick = () => {} }) => {
  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={styles.videoThumbnailContainer} tabIndex={0} role="button" onClick={onClick}>
      <div className={styles.iconContainer}>
        <PlayIcon alt="video-indicator" className={styles.playIcon} />
      </div>
      <img src={getVideoThumbnailUrl(playbackId)} alt={title} data-testid="video-thumbnail-preview" />
    </div>
  );
};

export default VideoStreamThumbnail;

VideoStreamThumbnail.propTypes = {
  playbackId: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
};
