import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside } from 'usehooks-ts';
import checkIcon from '../../img/check.svg';
import Button from '../Button/Button';
import styles from './styles.module.scss';

const PopupMenuWithSections = ({
  sections, onHide, isVisible, menuClass, menuItemsClass, upward = false,
}) => {

  const innerRef = useRef(null);

  useOnClickOutside(
    innerRef,
    () => onHide(!isVisible),
  );

  const selectOption = (option, selectionCallback) => () => {
    onHide(!isVisible);
    selectionCallback(option);
  };

  if (isVisible) {
    return (
      <div ref={innerRef} className={`${styles.menu}${menuClass ? ` ${menuClass}` : ''}`}>
        <div style={upward ? { bottom: 0 } : { top: 0 }} className={`${styles.menuItems}${menuItemsClass ? ` ${menuItemsClass}` : ''}`}>
          <div>
            {sections.map(section => (
              <div key={section.header}>
                <div>{section.header}</div>
                {section.options.map(option => (
                  <Button type="button" key={option} onClick={selectOption(option, section.selectionCallback)}>
                    {section.selectedOption === option
                      ? <img alt="Ausgewählte Option" src={checkIcon} />
                      : <img alt="" src={checkIcon} style={{ opacity: 0 }} /> // This is to align menu items.
                    }
                    {option}
                  </Button>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};
PopupMenuWithSections.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHide: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  menuClass: PropTypes.string,
  menuItemsClass: PropTypes.string,
  upward: PropTypes.bool,
};

export default PopupMenuWithSections;
