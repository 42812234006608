import { calculateImageDimensions, createFileFromUrl, isImage } from '../../../helper/fileHelper';
import MediaMessageModel from '../../../models/message/MediaMessageModel';
import { createClientMessageId } from '../../../helper/reduxOfflineHelper';
import { MESSAGE_SUB_TYPE } from '../../../constants/MessageTypes';
import ProductMessageModel from '../../../models/message/ProductMessageModel';
import ReplyMessageModel from '../../../models/message/ReplyMessageModel';
import ProcessMessageModel from '../../../models/message/ProcessMessageModel';
import TextMessageModel from '../../../models/message/TextMessageModel';
import { createTemporaryVideoId, getVideoMetaData } from '../../../helper/videoHelper';
import VideoMessageModel from '../../../models/message/VideoMessageModel';
import { VIDEO_STATUS } from '../../../constants/videoStatus';
import messageFactory from '../../../factory/messageFactory';
import OrderMessageModel from '../../../models/message/OrderMessageModel';

export const createMediaMessageModel = async ({
  channelId,
  file,
  platform,
  currentUserId,
}) => {
  const mediaMessage = new MediaMessageModel();
  mediaMessage.clientMessageId = createClientMessageId();
  mediaMessage.messageSubType = MESSAGE_SUB_TYPE.NONE;
  mediaMessage.sentFrom = platform;
  mediaMessage.filename = file.name;
  mediaMessage.channelId = channelId;
  mediaMessage.text = '';
  mediaMessage.authorId = currentUserId;
  mediaMessage.contentType = file.type;
  mediaMessage.mediaSize = file.size;

  if (file instanceof File) {
    mediaMessage.fileToUpload = new Blob([file], { type: file.type });
  } else {
    mediaMessage.fileToUpload = await createFileFromUrl(
      file.webPath,
      file.name,
      file.type,
    );
  }
  const imgDimensions = isImage(file) ? await calculateImageDimensions(file) : null;
  mediaMessage.imgDimensionsWidth = imgDimensions?.w || 0;
  mediaMessage.imgDimensionsHeight = imgDimensions?.h || 0;

  return mediaMessage.toPlain();
};

export const createVideoMessageModel = async ({
  channelId,
  file,
  platform,
  currentUserId,
}) => {
  const metadata = await getVideoMetaData(file);

  const videoMessage = new VideoMessageModel();
  videoMessage.channelId = channelId;
  videoMessage.authorId = currentUserId;
  videoMessage.clientMessageId = createClientMessageId();
  videoMessage.sentFrom = platform;
  videoMessage.videoId = createTemporaryVideoId();
  videoMessage.videoStatus = VIDEO_STATUS.UPLOADING;
  videoMessage.text = '';
  videoMessage.fileToUpload = file;
  videoMessage.videoTitle = metadata.title;
  videoMessage.videoWidth = metadata.width;
  videoMessage.videoHeight = metadata.height;
  videoMessage.videoSize = metadata.size;
  videoMessage.videoDuration = metadata.duration;

  if (!(file instanceof File)) {
    videoMessage.videoType = file.type;
    videoMessage.fileToUpload = await createFileFromUrl(file.webPath, metadata.title, file.type);
  }

  return videoMessage.toPlain();
};

export const createForwardMessageModel = ({
  message, authorName, recipient, stagedForwardMessageId, text, currentUserId, platform,
}) => {
  const forwardMessage = messageFactory({
    ...message,
    channelId: recipient,
    isForwarded: true,
    forwardedMessageId: stagedForwardMessageId,
    forwardedMessageCreatedAt: message.createdAt || message.dateCreated,
    forwardedMessageAuthorName: authorName,
    forwardedMessageText: message.forwardedMessageText || message.text,
    text,
    authorId: currentUserId,
    sentFrom: platform,
    clientMessageId: createClientMessageId(),
  });

  delete forwardMessage.id;
  delete forwardMessage.index;
  delete forwardMessage.externalId;
  delete forwardMessage.createdAt;
  delete forwardMessage.dateCreated;
  delete forwardMessage.updatedAt;
  delete forwardMessage.dateUpdated;
  delete forwardMessage.createdBy;
  delete forwardMessage.updatedBy;

  return forwardMessage.toPlain();
};

export const createReplyMessageModel = ({
  stagedReplyMessageId, channelId, text, messageSubType, platform, currentUserId,
}) => {
  return (new ReplyMessageModel({
    replyMessageId: stagedReplyMessageId,
    channelId,
    text,
    messageSubType,
    sentFrom: platform,
    clientMessageId: createClientMessageId(),
    isForwarded: false,
    authorId: currentUserId,
  })).toPlain();
};

export const createProductMessageModel = ({
  stagedProduct, channelId, text, messageSubType, platform, currentUserId,
}) => {
  return (new ProductMessageModel({
    product: stagedProduct,
    channelId,
    text,
    messageSubType,
    sentFrom: platform,
    clientMessageId: createClientMessageId(),
    isForwarded: false,
    authorId: currentUserId,
  })).toPlain();
};

export const createProcessMessageModel = ({
  stagedProcess, channelId, text, messageSubType, platform, currentUserId,
}) => {
  return (new ProcessMessageModel({
    process: stagedProcess,
    channelId,
    text,
    messageSubType,
    sentFrom: platform,
    clientMessageId: createClientMessageId(),
    isForwarded: false,
    authorId: currentUserId,
  })).toPlain();
};

export const createOrderMessageModel = ({
  stagedOrder, channelId, text, messageSubType, platform, currentUserId,
}) => {
  return (new OrderMessageModel({
    order: stagedOrder,
    channelId,
    text,
    messageSubType,
    sentFrom: platform,
    clientMessageId: createClientMessageId(),
    isForwarded: false,
    authorId: currentUserId,
  })).toPlain();
};


export const createTextMessageModel = ({
  channelId, text, messageSubType, platform, currentUserId,
}) => {
  return (new TextMessageModel({
    channelId,
    text,
    messageSubType,
    sentFrom: platform,
    clientMessageId: createClientMessageId(),
    isForwarded: false,
    authorId: currentUserId,
  })).toPlain();
};
