import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetDeleteCommunicationStrcuturedefaultErrorMessage,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSlices';
import {
  fetchHouseCommunicationStructures,
  deleteHouseCommunicationStructure,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureThunks';
import {
  getDeleteCommunicationStructureDefaultErrorMessage,
  getIsDeleting,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSelectors';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const CommunicationStructureDelete = ({
  communicationStructureId,
  houseId,
  onCancel,
  onSuccess,
}) => {

  const dispatch = useDispatch();
  const defaultErrorMessage = useSelector(getDeleteCommunicationStructureDefaultErrorMessage);
  const isDeleting = useSelector(getIsDeleting);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetDeleteCommunicationStrcuturedefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteCommunicationStrcuturedefaultErrorMessage());
  }, [dispatch]);

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteHouseCommunicationStructure({
        id: communicationStructureId,
      }));
      if (!result.error) {
        onSuccess();
        dispatch(fetchHouseCommunicationStructures({ houseId }));
      }
    },
    [dispatch, communicationStructureId, onSuccess, houseId],
  );

  return (
    <div className={styles.column}>
      Möchten Sie das Kommunikastionstruktur wirklich löschen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

CommunicationStructureDelete.propTypes = {
  communicationStructureId: PropTypes.string.isRequired,
  houseId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CommunicationStructureDelete;
