import { useCallback, useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedAdministrationChannel, getAddUserToGroupChannelDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSelectors';
import {
  resetAddUserToGroupChannelDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSlice';
import {
  resetAdministrationUsersByKey,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import {
  fetchSelectedAdministrationChannel, addUserToGroupChannel,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';
import USER_TYPE from 'gcs-common/helper/userTypeHelper';
import { getSelectedFilterByErpCustomerId } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import Tabs from '../../../Tabs/Tabs';
import FormikUserSelect from '../../shared/FormikUserSelect/FormikUserSelect';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const initialData = {
  userId: undefined,
};

const AddMemberValidationSchema = Yup.object().shape({
  userId: Yup.string().nullable().required('Erforderlich'),
});

const GroupChannelAddMember = ({ onCancel, onSuccess }) => {

  const selectedAdministrationChannel = useSelector(getSelectedAdministrationChannel) || {};
  const selectedErpCustomerId = useSelector(getSelectedFilterByErpCustomerId) || null;

  const dispatch = useDispatch();
  const defaultErrorMessage = useSelector(getAddUserToGroupChannelDefaultErrorMessage);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetAddUserToGroupChannelDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetAdministrationUsersByKey({ selectKey: 'groupChannelAddCraftsmanMemberSelection' }));
    dispatch(resetAddUserToGroupChannelDefaultErrorMessage());
  }, [dispatch]);

  const {
    id: channelId,
    channelName,
    users,
  } = selectedAdministrationChannel;

  const userIds = useMemo(() => {
    return users.map(user => user.id);
  }, [users]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const { userId } = values;
    const result = await dispatch(addUserToGroupChannel({ userId, channelId }));
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
      setTimeout(() => {
        dispatch(fetchSelectedAdministrationChannel({ channelId }));
      }, 1000);
    }
  }, [onSuccess, dispatch, channelId]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={AddMemberValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting, resetForm }) => (
        <Form className={styles.column}>

          <div className={styles.userNameColumn}>
            <div className={styles.userNameLabel}>
              Gruppen Channel
            </div>
            <div className={styles.userName}>
              {channelName}
            </div>
          </div>

          <Tabs
            onActiveTabChanged={resetForm}
            tabs={{
              agents: {
                name: 'Mitarbeiter',
                component: (
                  <div className={styles.row}>

                    <FormikUserSelect
                      selectKey="groupChannelAddAgentMemberSelection"
                      userType={USER_TYPE.AGENT}
                      key="agents"
                      label="Mitarbeiter*"
                      name="userId"
                      labelKey="friendlyName"
                      valueKey="id"
                      localFilter={userIds}
                    />
                  </div>

                ),
              },
              craftsmen: {
                name: 'Handwerker',
                component: (
                  <div className={styles.row}>

                    <FormikUserSelect
                      selectKey="groupChannelAddCraftsmanMemberSelection"
                      userType={USER_TYPE.CRAFTSMAN}
                      key="craftsmen"
                      label="Handwerker*"
                      name="userId"
                      labelKey="friendlyName"
                      valueKey="id"
                      localFilter={userIds}
                      companyId={selectedErpCustomerId}
                    />
                  </div>

                ),
              },
            }}
          />

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

GroupChannelAddMember.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default GroupChannelAddMember;
