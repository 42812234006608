import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getMessage,
  getMessageChannelId,
} from 'gcs-common/slices/messages/messageSelector';
import { openVideoFullscreen } from 'gcs-common/slices/uiState/uiStateSlice';
import { VIDEO_STATUS } from 'gcs-common/constants/videoStatus';
import { calculateVideoMessageSizeStyle } from 'gcs-common/helper/videoHelper';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import { showDisabledNotification } from '../../slices/notifications/ShowNotificationThunk';

import styles from './styles.module.scss';
import VideoUploadProgress from './VideoUploadProgress/VideoUploadProgress';
import VideoProcessing from './VideoProcessing/VideoProcessing';
import VideoDeleted from './VideoDeleted/VideoDeleted';
import VideoStreamThumbnail from '../VideoStreamPlayer/VideoStreamThumbnail';

const VideoMessage = ({ messageId, selectionDisabled = false }) => {
  const dispatch = useDispatch();

  const video = useSelector(getMessage(messageId));
  const channelId = useSelector(getMessageChannelId(messageId));
  const isJoinedChannel = useSelector(getCurrentUserIsMemberOfChannel(channelId));

  const onVideoClicked = useCallback(() => {
    if (selectionDisabled) {
      return;
    }
    if (isJoinedChannel) {
      dispatch(openVideoFullscreen({ videoId: video.videoPlaybackId }));
    } else {
      dispatch(showDisabledNotification('Vollbildansicht in Vertretungs-Pool nicht möglich.'));
    }
  }, [selectionDisabled, isJoinedChannel, dispatch, video]);

  return (
    <div
      className={styles.videoMessageContainer}
      style={calculateVideoMessageSizeStyle(video.videoWidth, video.videoHeight, 250, 300)}
    >
      {video.videoStatus === VIDEO_STATUS.UPLOADING && (
      <VideoUploadProgress videoId={video.videoId} />
      )}
      {video.videoStatus === VIDEO_STATUS.PROCESSING && (
      <VideoProcessing />
      )}
      {video.videoStatus === VIDEO_STATUS.READY && (
      <VideoStreamThumbnail
        playbackId={video.videoPlaybackId}
        title={video.videoTitle}
        onClick={onVideoClicked}
      />
      )}
      {video.videoStatus === VIDEO_STATUS.DELETED && (
      <VideoDeleted />
      )}
    </div>
  );
};

export default VideoMessage;

VideoMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
  selectionDisabled: PropTypes.bool,
};
