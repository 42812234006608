import { createAsyncThunk } from '@reduxjs/toolkit';
import { MESSAGE_TYPE } from '../../../constants/MessageTypes';
import {
  getMessageMediaFilename,
  getMessageReplyId,
  getMessageType,
  getMessageMediaRelativePath,
  getVideoPlaybackId,
  getVideoTitle,
  getVideoDownloadResolution,
} from '../messageSelector';
import { calculateMessageMediaURL } from '../../../helper/mediaHelper';
import { fetchVideoFromUrl } from '../../../helper/videoHelper';

const downloadMedia = createAsyncThunk(
  'messages/downloadMedia',
  async ({ messageId }, { getState, rejectWithValue }) => {
    const messageType = getMessageType(messageId)(getState());
    const mediaMessageId = messageType === MESSAGE_TYPE.REPLY
      ? getMessageReplyId(messageId)(getState())
      : messageId;
    let url;
    let filename;
    if ([MESSAGE_TYPE.MEDIA, MESSAGE_TYPE.MEDIA_FROM_MAIL].includes(messageType)) {
      filename = getMessageMediaFilename(mediaMessageId)(getState());
      const mediaRelativePath = getMessageMediaRelativePath(messageId)(getState());
      url = `${calculateMessageMediaURL(mediaRelativePath)}?no-cache=${Date.now()}`;
    } else if (messageType === MESSAGE_TYPE.VIDEO) {
      filename = getVideoTitle(mediaMessageId)(getState());
      const videoPlaybackId = getVideoPlaybackId(mediaMessageId)(getState());
      const videoDownloadResolution = getVideoDownloadResolution(mediaMessageId)(getState());
      url = await fetchVideoFromUrl(videoPlaybackId, videoDownloadResolution);
    }
    try {
      const response = await fetch(url);
      const myBlob = await response.blob();
      const mediaUrl = await URL.createObjectURL(myBlob);
      const link = document.createElement('a');
      link.href = mediaUrl;
      link.setAttribute(
        'download',
        filename,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return mediaUrl;

    } catch (error) {
      return rejectWithValue({ openErrorDialog: true, errorCode: 'load_media.default' });
    }
  },
);

export default downloadMedia;
