import Modal from 'react-modal';

import PropTypes from 'prop-types';
import BackIcon from 'gcs-common/img/arrow_back.svg?react';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';
import AnimatedSidebarButton from '../../AnimatedSidebarButton/AnimatedSidebarButton';

const ModalWithCloseButton = ({
  isOpen, onRequestClose, label = '', children, onGoBack, className = '', overlayClassName = '',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      contentLabel={label}
      className={`${styles.modal} ${className}`}
      overlayClassName={`${styles.overlay} ${overlayClassName}`}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        {onGoBack ? (
          <AnimatedSidebarButton
            className={styles.settingsButton}
            onClick={onGoBack}
            tooltip="Schließen"
          >
            <BackIcon className={styles.backIcon} />
          </AnimatedSidebarButton>
        ) : (<div />)}
        <div className={styles.label}>{label}</div>
        <AnimatedSidebarButton
          className={styles.settingsButton}
          onClick={onRequestClose}
          tooltip="Schließen"
        >
          <IconComponent Icon={ICON.CLOSE} />
        </AnimatedSidebarButton>
      </div>
      <div className={styles.container}>
        {children}
      </div>
    </Modal>
  );
};

ModalWithCloseButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
};

export default ModalWithCloseButton;
