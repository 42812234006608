import { useCallback, useState } from 'react';

const useStateRef = () => {
  const [stateRef, setStateRef] = useState(null);
  const ref = useCallback(newRef => {
    setStateRef(newRef);
  }, []);
  return [stateRef, ref];
};
export default useStateRef;
