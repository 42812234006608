
import { CSSProperties } from 'react';
import styles from './styles.module.scss';
import IconComponent from '../Icon/Icon';
import {
  ICON, ICON_SIZE, ICON_COLOR, IconSizeValue, IconColorValue,
} from '../../constants/IconConstants';

interface SpinnerProps {
  size?: IconSizeValue;
  color?: IconColorValue;
  style?: CSSProperties;
}

const Spinner = (props: SpinnerProps) => {
  const { size = ICON_SIZE.X_SMALL, color = ICON_COLOR.DARK_GREY, style = {} } = props;
  return (
    <span style={{ ...style }} className={styles.loader}>
      <IconComponent Icon={ICON.CIRCLE_PROGRESS} size={size} color={color} />
    </span>
  );
};

export default Spinner;
