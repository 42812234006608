import ChannelModel from '../../../models/ChannelModel';
import BaseConverter from './baseConverter';
import serializeDate from '../../../helper/serializeDate';

export default class ChannelConverter extends BaseConverter {
  // eslint-disable-next-line class-methods-use-this
  fromTwilioDataModel = (twilioChannel) => {
    const {
      attributes,
      sid,
      friendlyName,
    } = twilioChannel;

    const channel = new ChannelModel({
      id: attributes.id,
      friendlyName,
      externalId: sid,
      channelType: attributes.channel_type,
      membersCount: attributes.members_count,
      messagesCount: attributes.messages_count,
      createdBySource: attributes.created_by_source,
      imageUrl: attributes.image_url,
      connectedToCraftnote: attributes.connected_to_craftnote,
      agentsLastConsumedMessageIndex: attributes.agents_last_consumed_message_index,
      unreadCount: attributes.unread_count,
      lastMessageDate: attributes.last_message_date,
      primaryHouseId: attributes.primary_house_id,
      communicationAssignmentId: attributes.communication_assignment_id,
      readReceiptAllowance: attributes.read_receipt_allowance,
      mainContactId: attributes.main_contact_id,
      createdAt: attributes.created_at,
      updatedAt: attributes.updated_at,
      createdBy: attributes.created_by,
      updatedBy: attributes.updated_by,
    });
    if (twilioChannel.lastMessage) {
      channel.lastMessage = {
        dateCreated: serializeDate(twilioChannel.lastMessage.dateCreated),
        index: twilioChannel.lastMessage.index,
      };
      channel.lastMessageDate = serializeDate(twilioChannel.lastMessage.dateCreated);
    }
    // copy fields to serializable object,
    // to fix the warning that "non-serializable value was detected in the state"
    return channel.toPlain();
  };
}
