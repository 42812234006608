export const convertMessageEnToDe = (msg, parameters) => {
  let msgDe = msg;
  parameters
    .forEach(p => {
      msgDe = msgDe.replaceAll(p.key, p.key_label);
    });
  return msgDe;
};

export const convertMessageDeToEn = (msg, parameters) => {
  let msgEn = msg;
  parameters
    .forEach(p => {
      msgEn = msgEn.replaceAll(p.key_label, p.key);
    });
  return msgEn;
};
