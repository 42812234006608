import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { getHouse, getJoinedHousesIds, getSelectedHouseId } from 'gcs-common/slices/houses/housesSelectors';
import { getTotalUnreadCountFromUnselectedHouse } from 'gcs-common/slices/channels/unreadMessagesSelectors';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../IconComponent/IconComponent';
import HouseSwitcherItem from '../HouseSwitcherItem/HouseSwitcherItem';
import styles from './styles.module.scss';

const HouseSwitcher = () => {
  const joinedHousesIds = useSelector(getJoinedHousesIds);
  const selectedHouseId = useSelector(getSelectedHouseId);
  const selectedHouse = useSelector(getHouse(selectedHouseId));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef();
  const totalUnreadCount = useSelector(getTotalUnreadCountFromUnselectedHouse);

  const handleClose = () => popupRef?.current.closePopup();

  return (
    <div className={styles.houseSwitcher}>
      <div className={styles.button}>
        <div className={styles.labelWrapper}>
          <span className={styles.label}>
            {selectedHouse?.friendlyName || 'Haus auswählen'}
          </span>
        </div>
        <div className={styles.iconContainer}>
          {totalUnreadCount > 0 && (
            <div className={styles.unreadMessages} />
          )}
          <Popup
            arrow={false}
            closeOnDocumentClick
            closeOnEscape
            contentStyle={{ border: 'none', padding: '0px', width: 'auto' }}
            mouseEnterDelay={0}
            mouseLeaveDelay={300}
            on="click"
            onClose={() => !!isPopupOpen && setIsPopupOpen(false)}
            open={isPopupOpen}
            overlayStyle={{ cursor: 'auto', zIndex: 2 }}
            position="bottom left"
            ref={popupRef}
            trigger={(
              <div className={styles.buttonIcon}>
                {' '}
                <IconComponent Icon={ICON.HOUSE_SWITCH} color={ICON_COLOR.HOUSE_SWITCH} />
              </div>
)}
          >
            <div className={styles.popup}>
              <div className={styles.popupTitle}>
                <span>Haus Wechseln</span>
              </div>
              <div className={styles.popupContent}>
                {joinedHousesIds?.map((houseId) => (
                  <HouseSwitcherItem
                    handleClose={handleClose}
                    houseId={houseId}
                    key={houseId}
                  />
                ))}
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default HouseSwitcher;
