import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchHouseBranches = createAsyncThunk(
  'house/fetchHouseBranches',
  async ({ pageIndex, pageSize, searchQuery }, { extra: { gccApiClient } }) => {
    const { data, pagination } = await gccApiClient.getHouseBranches(
      pageIndex,
      pageSize,
      searchQuery,
    );
    return { data, pagination };
  },
);
