import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getGccApiClientInit } from 'gcs-common/slices/gccApiConnection/gccApiConnectionSelectors';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import { initGccApiWeb } from '../../slices/initialization/initializationThunks';

/**
 * Initializes the ApiClient.
 * Only render children components when the ApiClient is initialized.
 */
const ApiProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();

  const gccApiClientInit = useSelector(getGccApiClientInit);

  useEffect(() => {
    if (!gccApiClientInit) {
      dispatch(initGccApiWeb());
    }
  }, [dispatch, gccApiClientInit]);

  if (!gccApiClientInit) {
    return (
      <LoadingIndicatorFullScreen loadingText="Lädt..." />
    );
  }

  return (
    <>
      {children}
    </>
  );
};

ApiProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ApiProtectedRoute;
