import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSalesSpaceChannelProcessing } from '../salesSpaceChannelsSelectors';
import fetchCurrentUserChannels from '../../channels/channelsThunks/fetchCurrentUserChannels';
import { CHANNEL_TYPE } from '../../../helper/channelTypeHelper';
import { getIsSalesSpaceChannelType } from '../../channel/channelSelectors';
import { getIsAgent } from '../../currentUser/currentUserSelectors';

const leaveSalesSpace = createAsyncThunk(
  'salesSpaces/leaveSalesSpace',
  async ({ channelId }, { dispatch, getState, rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.leaveChannel(channelId);

    if (response.error_code) {
      return rejectWithValue({ openErrorDialog: true, errorCode: response.error_code });
    }

    const isSalesSpace = getIsSalesSpaceChannelType(channelId)(getState());
    const isAgent = getIsAgent(getState());

    await dispatch(fetchCurrentUserChannels({ specificChannelId: channelId }));

    if (isSalesSpace && isAgent) {
      dispatch(fetchCurrentUserChannels({ channelTypes: [CHANNEL_TYPE.SALES_SPACE] }));
    }

    return response;
  },
  {
    condition({ channelId }, { getState }) {
      return (
        !getSalesSpaceChannelProcessing(channelId)(getState())
      );
    },
  },
);

export default leaveSalesSpace;
