import { createFileFromUrl } from './fileHelper';
import gccApiClient from '../clients/gccApiClient/gccApiClient';

export default async (assignment) => {

  const fileName = `${assignment.channelName}_${Date.now()}.png`;
  const fileType = 'image/png';
  const file = await createFileFromUrl(assignment.channelImage, fileName, fileType);
  const {
    preSignedUrl,
    imageUrl: newImageUrl,
  } = await gccApiClient.getPresignedUrl(fileName);
  const uploadImageResponse = await fetch(preSignedUrl, {
    method: 'PUT',
    body: file,
  });
  if (!uploadImageResponse.ok) {
    throw new Error('Failed uploading group picture');
  }
  // eslint-disable-next-line no-param-reassign
  return encodeURI(newImageUrl);

};
