import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSalesSpaceChannelProcessing } from '../salesSpaceChannelsSelectors';

const joinSalesSpace = createAsyncThunk(
  'salesSpaces/joinSalesSpace',
  async ({ channelId, currentUserId }, { _, rejectWithValue,
    extra: { gccApiClient } }) => {
    const response = await gccApiClient.joinChannel(channelId, currentUserId);

    if (response.error_code) {
      return rejectWithValue({ openErrorDialog: true, errorCode: response.error_code });
    }

    return response;
  },
  {
    condition({ channelId }, { getState }) {
      return (
        !getSalesSpaceChannelProcessing(channelId)(getState())
      );
    },
  },
);

export default joinSalesSpace;
