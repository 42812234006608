import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getPrivacyText, getTermsText } from 'gcs-common/slices/theme/themeSelectors';
import { showUsercentricsSettings } from 'gcs-common/hooks/useUsercentrics';
import { useNavigate } from 'react-router';
import closeIcon from '../../img/close_white.svg';
import styles from './styles.module.scss';
import Button from '../Button/Button';

const TermsAndPrivacy = ({
  asModal = false, withTerms = false, withPrivacy = false,
  onRightBar = false, withOptOut = false,
}) => {
  const navigate = useNavigate();
  const privacyText = useSelector(getPrivacyText);
  const termsText = useSelector(getTermsText);

  const header = useMemo(() => {
    if (withTerms && withPrivacy) return 'Nutzungsbedignungen & Datenschutzrichtlinien';
    return withTerms ? 'Nutzungsbedignungen' : 'Datenschutzrichtlinien';
  }, [withTerms, withPrivacy]);

  return (
    <div className={asModal ? styles.containerModal : styles.container}>
      <div className={styles.header}>
        <div>{header}</div>
        <Button className={styles.noBorder} type="button" onClick={() => navigate(-1)}>
          <img src={closeIcon} alt="Zurück" />
        </Button>
      </div>
      <div className={styles.textWrapper}>
        <div>{(withTerms && termsText)}</div>
        <div>{(withPrivacy && privacyText)}</div>
      </div>
      {onRightBar && (
        <div className={styles.cookieSettingsContainer}>
          <button className={styles.cookieSettingsButton} onClick={showUsercentricsSettings} type="button">Datenschutzeinstellungen bearbeiten</button>
        </div>
      )}
      {withOptOut && (
        <button className={styles.cookieSettingsButton} onClick={showUsercentricsSettings} type="button">Datenschutzeinstellungen bearbeiten</button>
      )}
    </div>
  );
};

TermsAndPrivacy.propTypes = {
  asModal: PropTypes.bool,
  withTerms: PropTypes.bool,
  withPrivacy: PropTypes.bool,
  onRightBar: PropTypes.bool,
  withOptOut: PropTypes.bool,
};

export default TermsAndPrivacy;
