import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  channelInputFileStaged,
} from 'gcs-common/slices/messageInput/messageInputSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputIsSendingFiles, getChannelInputStagedFiles,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';
import FileInputButton from '../../FileInputWrapper/FileInputWrapper';

const MessageInputFileButton = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const isSendingFiles = useSelector(getChannelInputIsSendingFiles(selectedChannelId));
  const files = useSelector(getChannelInputStagedFiles(selectedChannelId));
  const dispatch = useDispatch();

  const onFileInputChanged = useCallback((inputFiles) => {
    inputFiles.forEach(file => {
      dispatch(channelInputFileStaged({ channelId: selectedChannelId, file }));
    });
  }, [dispatch, selectedChannelId]);

  return (
    <FileInputButton
      className={styles.fileInputButton}
      onChange={onFileInputChanged}
      disabled={isSendingFiles}
      value={files}
      title="Datei hochladen"
    >
      <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.LIGHT_GREY} alt="Datei hochladen" />
    </FileInputButton>
  );
};

export default MessageInputFileButton;
