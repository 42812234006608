/* eslint-disable jsx-a11y/label-has-for */

import PropTypes from 'prop-types';
import { Field } from 'formik';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import styles from './styles.module.scss';

const FormikCheckBox = ({
  textClassName,
  checkboxClassName,
  label = '',
  text = '',
  disabled = false,
  name = '',
  placeholder = '',
  ...props
}) => {
  return (
    <div className={styles.formikInputContainer}>
      <div className={`${styles.formikInputWrapper} ${disabled ? styles.disabled : ''}`}>
        {label && <label className={styles.formikInputLabel} htmlFor={name}>{label}</label>}
        <div className={styles.container}>
          {text && <div className={`${styles.formikInputText} ${textClassName || ''}`}>{text}</div>}
          <div>
            <Field
              type="checkbox"
              id={name}
              className={`${styles.formikInput} ${checkboxClassName || ''}`}
              placeholder={placeholder}
              name={name}
              disabled={disabled}
              {...props}
            />
          </div>
        </div>
      </div>
      <ValidationError name={name} />
    </div>
  );
};

FormikCheckBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  textClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormikCheckBox;
