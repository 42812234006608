const firebaseConfig = {
  apiKey: 'AIzaSyC3dqfcOc30gYOM7q9V5UJ-nzk8bD224kQ',
  authDomain: 'gcs-craftsman-app.firebaseapp.com',
  databaseURL: 'https://gcs-craftsman-app.firebaseio.com',
  projectId: 'gcs-craftsman-app',
  storageBucket: '',
  messagingSenderId: '484664504903',
  appId: '1:484664504903:web:80757cb0ebd88bbe',
};

export const publicVapidKey = 'BAmOm_GZEdTTo8gISnuEYJ286dl41u8bPuAQJt6aZmmci3W1yK_YA2sj6d3VOsJSQgU9tQYrooYqzD7kjnlbvY0';

export default firebaseConfig;
