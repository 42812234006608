import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchErpCustomerAssignments,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsThunks';
import {
  getErpCustomerTeamAssignments, getErpCustomerDirectAssignments,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsSelectors';
import {
  fetchAllAssignmentRoles,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureThunks';
import {
  CommunicationAssignmentType,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructuresConstants';
import PlusButton from 'gcs-common/components/PlusButton/PlusButton';
import ErpCustomerAssignmentRowCollapsed from './ErpCustomerAssignmentRowCollapsed/ErpCustomerAssignmentRowCollapsed';
import ErpCustomerAssignmentRowOpen from './ErpCustomerAssignmentRowOpen/ErpCustomerAssignmentRowOpen';
import styles from './styles.module.scss';
import CommuniactionStructureModal from '../CommunicationStructureModal/CommuniactionStructureModal';

const ErpCustomerAssignments = ({ erpCustomerId }) => {

  const [openRowId, setOpenRowId] = useState(null);
  const dispatch = useDispatch();
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const teamAssignmentIds = useSelector(getErpCustomerTeamAssignments);
  const directAssignmentIds = useSelector(getErpCustomerDirectAssignments);

  // eslint-disable-next-line max-len
  const [newCommunicationStructureAssignmentType, setNewCommunicationStructureAssignmentType] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const openCommunicationStructureModal = (option, type) => {
    setNewCommunicationStructureAssignmentType(type);
    setIsOpen(option);
  };

  useEffect(() => {
    dispatch(fetchErpCustomerAssignments({ erpCustomerId }));
  }, [dispatch, erpCustomerId, triggerRefetch]);

  useEffect(() => {
    dispatch(fetchAllAssignmentRoles({}));
  }, [dispatch, triggerRefetch]);

  const handleSuccessfulDeletion = () => setTriggerRefetch(!triggerRefetch);

  const canCreateSalesSpaceAssignment = (
    !(newCommunicationStructureAssignmentType === CommunicationAssignmentType.SALES_SPACE)
  );

  const canCreateDirectAssignment = (
    !(newCommunicationStructureAssignmentType === CommunicationAssignmentType.DIRECT)
  );

  return (
    <>
      <div>
        <div className={styles.rowHeader}>
          <div className={styles.chat}>
            <span>Teamchat</span>
            <span>Kontakte</span>
          </div>
          {canCreateSalesSpaceAssignment && (
            <PlusButton
              clickHandler={() => {
                openCommunicationStructureModal(true, CommunicationAssignmentType.SALES_SPACE);
              }}
            />
          )
        }
        </div>
        {teamAssignmentIds.map(id => {
          return (
            (openRowId === id)
              ? (
                <ErpCustomerAssignmentRowOpen
                  erpCustomerId={erpCustomerId}
                  setOpenRowId={setOpenRowId}
                  communicationAssignmentId={openRowId}
                  isEditForm
                  isTeamRow
                  key={id}
                  setNewCommunicationStructureAssignmentType={
                    setNewCommunicationStructureAssignmentType
                  }
                  onDeletion={handleSuccessfulDeletion}
                />
              ) : (
                <ErpCustomerAssignmentRowCollapsed
                  communicationAssignmentId={id}
                  setOpenRowId={setOpenRowId}
                  key={id}
                />
              )
          );
        })}
        {newCommunicationStructureAssignmentType === CommunicationAssignmentType.SALES_SPACE && (
          <CommuniactionStructureModal
            isOpen={isOpen}
            onRequestClose={() => openCommunicationStructureModal(false, false)}
            title="Communiacation Structure"
          >
            <ErpCustomerAssignmentRowOpen
              erpCustomerId={erpCustomerId}
              isTeamRow
              setOpenRowId={setOpenRowId}
              setNewCommunicationStructureAssignmentType={
                setNewCommunicationStructureAssignmentType
              }
              isModalOpen={isOpen}
              onDeletion={handleSuccessfulDeletion}
            />
          </CommuniactionStructureModal>
        )}
        {(teamAssignmentIds.length === 0) && (
          <div className={styles.emptyMessage}>
            Keine Daten gefunden.
          </div>
        )}
      </div>

      <div>
        <div className={styles.rowHeader}>
          <div className={styles.chat}>
            <span>Direkte Chats</span>
          </div>
          {canCreateDirectAssignment
          && (
            <PlusButton
              clickHandler={() => {
                openCommunicationStructureModal(true, CommunicationAssignmentType.DIRECT);
              }}
            />
          )
        }
        </div>
        {directAssignmentIds.map(id => {
          return (
            (openRowId === id)
              ? (
                <ErpCustomerAssignmentRowOpen
                  erpCustomerId={erpCustomerId}
                  isEditForm
                  setOpenRowId={setOpenRowId}
                  key={id}
                  communicationAssignmentId={openRowId}
                  setNewCommunicationStructureAssignmentType={
                    setNewCommunicationStructureAssignmentType
                  }
                  onDeletion={handleSuccessfulDeletion}
                />
              ) : (
                <ErpCustomerAssignmentRowCollapsed
                  communicationAssignmentId={id}
                  setOpenRowId={setOpenRowId}
                  key={id}
                />
              )
          );
        })}
        {newCommunicationStructureAssignmentType === CommunicationAssignmentType.DIRECT && (
          <CommuniactionStructureModal
            isOpen={isOpen}
            onRequestClose={() => openCommunicationStructureModal(false, false)}
            title="Communiacation Structure"
          >
            <ErpCustomerAssignmentRowOpen
              erpCustomerId={erpCustomerId}
              setOpenRowId={setOpenRowId}
              setNewCommunicationStructureAssignmentType={
                setNewCommunicationStructureAssignmentType
              }
              isModalOpen={isOpen}
              onDeletion={handleSuccessfulDeletion}
            />
          </CommuniactionStructureModal>
        )}
        {(directAssignmentIds.length === 0) && (
          <div className={styles.emptyMessage}>
            Keine Daten gefunden.
          </div>
        )}
      </div>
    </>
  );
};

ErpCustomerAssignments.propTypes = {
  erpCustomerId: PropTypes.string.isRequired,
};

export default ErpCustomerAssignments;
