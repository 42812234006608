export const NEW_CHANNEL_SECTIONS = {
  HOUSE: 'house',
  INTERNAL: 'internal',
  SELECTED_MEMBERS: 'selected_members',
  SALES_SPACE: 'sales_space',
  DIRECT: 'direct',
};

export const NEW_CHANNEL_SECTIONS_LIST = [
  NEW_CHANNEL_SECTIONS.HOUSE,
  NEW_CHANNEL_SECTIONS.INTERNAL,
];

export const ALTERNATIVE_NEW_CHANNEL_SECTIONS_LIST = [
  NEW_CHANNEL_SECTIONS.SALES_SPACE,
  NEW_CHANNEL_SECTIONS.DIRECT,
];
