/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { MESSAGE_SUB_TYPE } from '../../constants/MessageTypes';
import FORWARD_TARGET from '../../constants/ForwardTarget';
// eslint-disable-next-line import/no-cycle
import { sendCurrentMessageState } from './messageInputThunks/sendCurrentMessageState';

const asyncState = {
  sendCurrentMessageState: {
    loading: 'idle',
    error: null,
  },
};

const initialState = {
  ...asyncState,
  channelInputs: {},
};

const getChannelInputState = (state, channelId) => {
  if (!state.channelInputs[channelId]) {
    state.channelInputs[channelId] = {
      text: '',
      textPlaceholder: null,
      stagedProduct: null,
      stagedProcess: null,
      stagedReplyMessageId: null,
      stagedForwardMessageIds: [],
      stagedForwardRecipientsChannelIds: [], // channelIds to which messages get forwarded
      selectedMessageIds: [],
      selectedMessageIdsForwardingTarget: FORWARD_TARGET.CHAT,
      stagedMessageSubType: MESSAGE_SUB_TYPE.NONE,
      stagedFiles: [],
      stagedOrder: null,
    };
  }
  return state.channelInputs[channelId];
};

const clearChannelInputState = (channelInputState) => {
  // Note: In general we want to keep text for state reset
  // that's why it is not cleared here
  // ( e.g. channelInputReplyMessageStaged -> keep existing text)
  channelInputState.stagedProduct = null;
  channelInputState.stagedProcess = null;
  channelInputState.stagedReplyMessageId = null;
  channelInputState.stagedForwardMessageIds = [];
  channelInputState.selectedMessageIds = [];
  channelInputState.selectedMessageIdsForwardingTarget = FORWARD_TARGET.CHAT;
  channelInputState.stagedForwardRecipientsChannelIds = [];
  channelInputState.stagedMessageSubType = MESSAGE_SUB_TYPE.NONE;
  channelInputState.stagedFiles = [];
  channelInputState.stagedOrder = null;
};

const messageInputSlice = createSlice({
  name: 'messageInput',
  initialState,
  reducers: {
    channelInputTextChanged: (state, action) => {
      const { channelId, text, append } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      if (append) {
        const current = channelInputState.text;
        channelInputState.text = current ? current + text : text;
      } else {
        channelInputState.text = text;
      }
    },
    channelInputFileStaged: (state, action) => {
      const { channelId, file } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.stagedFiles.push(file);
    },
    channelInputFileUnstaged: (state, action) => {
      const { channelId, file } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.stagedFiles = channelInputState.stagedFiles.filter(f => {
        if ('path' in f) {
          return f.path !== file.path;
        }
        return f !== file;
      });
    },
    channelInputForwardRecipientChannelIdStaged: (state, action) => {
      const { channelId, recipientChannelId } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.stagedForwardRecipientsChannelIds.push(recipientChannelId);
    },
    channelInputForwardRecipientChannelIdUnstaged: (state, action) => {
      const { channelId, recipientChannelId } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.stagedForwardRecipientsChannelIds = channelInputState
        .stagedForwardRecipientsChannelIds.filter(id => id !== recipientChannelId);
    },
    channelInputReplyMessageStaged: (state, action) => {
      const { channelId, messageId } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      clearChannelInputState(channelInputState);
      channelInputState.stagedReplyMessageId = messageId;
    },
    channelInputForwardMessagesStaged: (state, action) => {
      const { channelId, messageIds } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      clearChannelInputState(channelInputState);
      channelInputState.stagedForwardMessageIds = messageIds;
    },
    channelInputProductStaged: (state, action) => {
      const {
        channelId, subType, text, product,
      } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      clearChannelInputState(channelInputState);
      channelInputState.stagedMessageSubType = subType;
      channelInputState.text = text;
      channelInputState.stagedProduct = product;
    },
    channelInputProcessStaged: (state, action) => {
      const {
        channelId, subType, text, process,
      } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      clearChannelInputState(channelInputState);
      channelInputState.stagedMessageSubType = subType;
      channelInputState.text = text;
      channelInputState.stagedProcess = process;
    },
    channelInputOrderStaged: (state, action) => {
      const {
        channelId, text, order, textPlaceholder,
      } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      clearChannelInputState(channelInputState);
      channelInputState.text = text;
      channelInputState.stagedOrder = order;
      channelInputState.textPlaceholder = textPlaceholder;
    },
    channelInputReset: (state, action) => {
      const { channelId } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.text = '';
      clearChannelInputState(channelInputState);
    },
    channelInputCleared: (state, action) => {
      const { channelId } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      clearChannelInputState(channelInputState);
    },
    addSelectedMessageIds: (state, action) => {
      const { channelId, selectedMessageId, target = undefined } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.selectedMessageIds = [...new Set(
        [...channelInputState.selectedMessageIds, selectedMessageId],
      )];
      if (target) {
        channelInputState.selectedMessageIdsForwardingTarget = target;
      }
    },
    removeSelectedMessageIds: (state, action) => {
      const { channelId, selectedMessageId } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.selectedMessageIds = channelInputState.selectedMessageIds
        .filter(id => id !== selectedMessageId);
    },
    resetSelectedMessageIds: (state, action) => {
      const { channelId } = action.payload;
      const channelInputState = getChannelInputState(state, channelId);
      channelInputState.selectedMessageIds = [];
    },
  },
  extraReducers: {
    [sendCurrentMessageState.pending]: (state) => {
      state.sendCurrentMessageState.loading = 'pending';
    },
    [sendCurrentMessageState.fulfilled]: (state) => {
      state.sendCurrentMessageState.loading = 'idle';
    },
    [sendCurrentMessageState.rejected]: (state, action) => {
      state.sendCurrentMessageState.loading = 'idle';
      state.sendCurrentMessageState.error = action.error;
    },
  },
});

export const {
  channelInputReset,
  channelInputCleared,
  channelInputReplyMessageStaged,
  channelInputTextChanged,
  channelInputFileStaged,
  channelInputFileUnstaged,
  channelInputProductStaged,
  channelInputProcessStaged,
  channelInputForwardMessagesStaged,
  channelInputForwardRecipientChannelIdStaged,
  channelInputForwardRecipientChannelIdUnstaged,
  addSelectedMessageIds,
  removeSelectedMessageIds,
  resetSelectedMessageIds,
  channelInputOrderStaged,
} = messageInputSlice.actions;
export default messageInputSlice.reducer;
