export const loadScript = (url, options, tagToAppend = 'body') => new Promise((resolve, reject) => {
  const s = document.createElement('script');
  s.src = url;
  s.onload = resolve;
  s.onerror = reject;
  const { scriptDataAttributes, scriptId, scriptType } = options || {};
  if (scriptDataAttributes) {
    Object.entries(scriptDataAttributes).forEach(([key, value]) => {
      s.dataset[key] = value;
    });
  }
  if (scriptId) {
    s.id = scriptId;
  }
  if (scriptType) {
    s.type = scriptType;
  }
  s.async = true;
  document[tagToAppend].appendChild(s);
});
