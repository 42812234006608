/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHasMore,
  getProcessesForChannel,
  getProcessesLoading,
} from 'gcs-common/slices/processes/processesSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import fetchProcesses from 'gcs-common/slices/processes/processesThunks/fetchProcesses';
import { getChannelOPUserId } from 'gcs-common/slices/channel/channelSelectors';
import { useDebouncedCallback } from 'use-debounce';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import Process from '../Process/Process';

import styles from './styles.module.scss';

const ProcessList = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const processesLoading = useSelector(getProcessesLoading);
  const processes = useSelector(getProcessesForChannel(selectedChannelId));
  const channelOpUserId = useSelector(getChannelOPUserId(selectedChannelId));

  const hasMore = useSelector(getHasMore);
  const dispatch = useDispatch();

  const [loadNextProcessPage] = useDebouncedCallback(async (page) => {
    dispatch(fetchProcesses({ page, channelId: selectedChannelId, id: channelOpUserId }));
  }, 500);

  useEffect(() => {
    dispatch(fetchProcesses({ page: 1,
      channelId: selectedChannelId,
      id: channelOpUserId,
      append: false }));
  }, [channelOpUserId, dispatch, selectedChannelId]);

  if (processesLoading && processes.length === 0) {
    return <LoadingIndicator key="loading-indicator" />;
  }

  if (!processesLoading && processes.length === 0) {
    return <div className={styles.errorMessage}>Keine Vorgänge gefunden.</div>;
  }

  return (
    <div className={styles.processList}>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadNextProcessPage}
        hasMore={hasMore}
        useWindow={false}
        initialLoad={false}
        threshold={100}
        momentumScrollQuirks={false}
      >
        {processes.map(
          (process, index) => {
            return (
              <Process
                key={`${process.ProcessNumber}-${process.ProcessSuffix}-${index}`}
                process={process}
                index={index}
              />
            );
          },
        )}
        {processesLoading && <LoadingIndicator key="loading-indicator" />}
      </InfiniteScroll>
    </div>
  );
};

export default ProcessList;
