import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { FILE_LIST_TYPES } from '../../constants/fileListConstants';
import { getMessages, getMessageIdsForChannel } from '../messages/messagesSelectors';
import ASYNC_STATE from '../../constants/AsyncState';

export const getFileListTab = state => state.fileList.fileListTab;
export const getPreviousFileListLoading = state => {
  return state.fileList.loadPreviousMessagesForFileList.status === ASYNC_STATE.LOADING;
};
export const getPreviousFileListErrorMessage = state => {
  return state.fileList.loadPreviousMessagesForFileList.errorMessage;
};

export const getFileListMessageIds = (channelId) => createSelector(
  getMessageIdsForChannel(channelId),
  getMessages,
  getFileListTab,
  (channelMessageIds, messages, fileListTab) => {
    return channelMessageIds ? channelMessageIds.filter(messageId => {
      const message = messages[messageId];
      if (message.deleted) {
        return false;
      }
      if (fileListTab === FILE_LIST_TYPES.LINKS) {
        return !!message.linkPreviews;
      }

      const hasMedia = message.mediaRelativePath;
      const hasVideo = message.videoId;
      const isImage = hasMedia && message.contentType.match(/^image/);

      if (fileListTab === FILE_LIST_TYPES.MEDIA) {
        return (hasMedia && isImage) || hasVideo;
      }
      if (fileListTab === FILE_LIST_TYPES.DOCS) {
        return hasMedia && !isImage;
      }
      return false;
    }).reverse() : [];
  },
);

export const getGroupedFilesByMonth = (channelId) => createSelector(
  getFileListMessageIds(channelId),
  getMessages,
  (fileListMessageIds, messages) => {
    return fileListMessageIds.reduce((acc, messageId) => {
      const message = messages[messageId];
      const { createdAt: dateCreatedRaw } = message;
      const dateCreated = moment(dateCreatedRaw).format('MMMM YYYY');
      if (!acc[dateCreated]) {
        acc[dateCreated] = [];
      }
      acc[dateCreated].push(messageId);
      return acc;
    }, {});
  },
);
