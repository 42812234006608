import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteCraftsmanConnection } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import {
  getCraftsmanConnection,
  getdeleteCraftsmanConnectionFailed,
  getdeleteCraftsmanConnectionError,
  getdeleteCraftsmanConnectionLoading,
} from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { resetRemoveError } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSlice';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import Button from '../../../Button/Button';
import styles from './styles.module.scss';


const CraftsmanConnectionModalRemove = ({ goToEdit, goToOverview, connectionId }) => {
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState(false);
  const connectionMember = useSelector(getCraftsmanConnection(connectionId));
  const removeConnectionIsLoading = useSelector(getdeleteCraftsmanConnectionLoading);
  const removeConnectionFailed = useSelector(getdeleteCraftsmanConnectionFailed);
  const error = useSelector(getdeleteCraftsmanConnectionError);
  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));

  useEffect(() => {
    setConfirmed(false);
  }, []);

  const onDeleteConnection = useCallback(async () => {
    setConfirmed(true);
    dispatch(deleteCraftsmanConnection({ connectionId }));
  }, [dispatch, connectionId]);

  const goBack = useCallback(async () => {
    return goToEdit(connectionId);
  }, [goToEdit, connectionId]);

  const onResetError = useCallback(() => {
    setConfirmed(false);
    dispatch(resetRemoveError());
  }, [dispatch]);

  const body = useMemo(() => {
    if (removeConnectionIsLoading) {
      return <LoadingIndicator />;
    }
    if (removeConnectionFailed) {
      return (
        <>
          <div className={styles.errorMsg}>
            {isOpMaintenanceEnabled ? opMaintenanceMessage : error}
          </div>
          <Button className={styles.tryAgainBtn} onClick={onResetError}>
            Nochmal versuchen
          </Button>
        </>
      );
    }
    if (!confirmed) {
      return (
        <>
          {`Sind Sie sich sicher, dass Sie die Verknüpfung '${connectionMember.connection.customer_number}' aus ihrem Account entfernen wollen? `}
          <div className={styles.buttonContainer}>
            <Button className={styles.secondary} onClick={() => goBack(connectionId)}>Nein</Button>
            <Button className={styles.primary} onClick={onDeleteConnection}>Ja</Button>
          </div>
        </>
      );
    }
    return (
      <>
        Die Verknüpfung wurde entfernt.
        <div className={styles.buttonContainer}>
          <Button className={styles.primary} onClick={goToOverview}>OK</Button>
        </div>
      </>
    );

  }, [
    confirmed,
    connectionMember,
    connectionId,
    error,
    goBack,
    goToOverview,
    onDeleteConnection, onResetError,
    removeConnectionFailed,
    removeConnectionIsLoading,
    isOpMaintenanceEnabled,
  ]);

  return (
    <>
      {body}
    </>
  );
};

CraftsmanConnectionModalRemove.propTypes = {
  goToEdit: PropTypes.func.isRequired,
  goToOverview: PropTypes.func.isRequired,
  connectionId: PropTypes.string.isRequired,
};

export default CraftsmanConnectionModalRemove;
