/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  SalesSpaceChannelGrouping,
  SalesSpaceChannelOrder,
  SalesSpaceChannelsBetweenGroupingSort,
  SalesSpaceChannelsInGroupingSort,
  SalesSpaceChannelFilter,
  UnreadMessagesFilter,
} from '../../helper/channelOrderingHelper';
import ASYNC_STATE from '../../constants/AsyncState';
import leaveSalesSpace from './salesSpaceChannelsThunks/leaveSalesSpace';
import joinSalesSpace from './salesSpaceChannelsThunks/joinSalesSpace';


const asyncState = {
  leaveSalesSpace: {}, // { [key]: { status } }
  joinSalesSpace: {}, // { [key]: { status } }
};

const initialState = {
  ...asyncState,
  channelsBeingProcessed: [],
  selectedSalesSpaceChannelOrder: SalesSpaceChannelOrder.NEW_MESSAGE,
  selectedSalesSpaceChannelsGrouping: SalesSpaceChannelGrouping.DATE,
  selectedSalesSpaceChannelsInGroupSort: SalesSpaceChannelsInGroupingSort.DATE,
  selectedSalesSpaceChannelsBetweenGroupSort: SalesSpaceChannelsBetweenGroupingSort.DATE,
  selectedSalesSpaceChannelFilter: SalesSpaceChannelFilter.FREE_AND_OWN,
  unreadMessagesFilterSelected: UnreadMessagesFilter.ALL,
};

const salesSpaceChannelsSlice = createSlice({
  name: 'salesSpaceChannels',
  initialState,
  reducers: {
    setSelectedSalesSpaceChannelOrder: (state, action) => {
      const { selectedSalesSpaceChannelOrder } = action.payload;
      state.selectedSalesSpaceChannelOrder = selectedSalesSpaceChannelOrder;
    },
    setSelectedSalesSpaceChannelsGrouping: (state, action) => {
      const { selectedSalesSpaceChannelsGrouping } = action.payload;
      state.selectedSalesSpaceChannelsGrouping = selectedSalesSpaceChannelsGrouping;
    },
    setSelectedSalesSpaceChannelsInGroupSort: (state, action) => {
      const { selectedSalesSpaceChannelsInGroupSort } = action.payload;
      state.selectedSalesSpaceChannelsInGroupSort = selectedSalesSpaceChannelsInGroupSort;
    },
    setSelectedSalesSpaceChannelFilter: (state, action) => {
      const { selectedSalesSpaceChannelFilter } = action.payload;
      state.selectedSalesSpaceChannelFilter = selectedSalesSpaceChannelFilter;
    },
    setSalesSpaceUnreadMessagesFilterSelected: (state, action) => {
      const { unreadMessagesFilterSelected } = action.payload;
      state.unreadMessagesFilterSelected = unreadMessagesFilterSelected;
    },
  },
  extraReducers: {
    [leaveSalesSpace.pending]: (state, action) => {
      const { meta: { arg: { channelId } } } = action;
      state.channelsBeingProcessed = [...state.channelsBeingProcessed, channelId];
      if (!state.leaveSalesSpace[channelId]) {
        state.leaveSalesSpace[channelId] = {};
      }
      state.leaveSalesSpace[channelId].status = ASYNC_STATE.LOADING;

    },
    [leaveSalesSpace.fulfilled]: (state, action) => {
      const { meta: { arg: { channelId } } } = action;
      state.leaveSalesSpace[channelId].status = ASYNC_STATE.SUCCEEDED;
      state.channelsBeingProcessed = state.channelsBeingProcessed
        .filter(idsBeingProcessed => channelId !== idsBeingProcessed);
    },
    [leaveSalesSpace.rejected]: (state, action) => {
      const { meta: { arg: { channelId } } } = action;

      state.leaveSalesSpace[channelId].status = ASYNC_STATE.FAILED;

      state.channelsBeingProcessed = state.channelsBeingProcessed
        .filter(idsBeingProcessed => channelId !== idsBeingProcessed);
    },
    [joinSalesSpace.pending]: (state, action) => {
      const { meta: { arg: { channelId } } } = action;
      state.channelsBeingProcessed = [...state.channelsBeingProcessed, channelId];

      if (!state.joinSalesSpace[channelId]) {
        state.joinSalesSpace[channelId] = {};
      }
      state.joinSalesSpace[channelId].status = ASYNC_STATE.LOADING;
    },
    [joinSalesSpace.fulfilled]: (state, action) => {
      const { meta: { arg: { channelId } } } = action;
      state.joinSalesSpace[channelId].status = ASYNC_STATE.SUCCEEDED;
      state.channelsBeingProcessed = state.channelsBeingProcessed
        .filter(idsBeingProcessed => channelId !== idsBeingProcessed);
    },
    [joinSalesSpace.rejected]: (state, action) => {
      const { meta: { arg: { channelId } } } = action;
      state.joinSalesSpace[channelId].status = ASYNC_STATE.FAILED;
      state.channelsBeingProcessed = state.channelsBeingProcessed
        .filter(idsBeingProcessed => channelId !== idsBeingProcessed);
    },
  },
});

export const {
  setSelectedSalesSpaceChannelOrder,
  setSelectedSalesSpaceChannelsGrouping,
  setSelectedSalesSpaceChannelsInGroupSort,
  setSelectedSalesSpaceChannelFilter,
  setSalesSpaceUnreadMessagesFilterSelected,
} = salesSpaceChannelsSlice.actions;

export default salesSpaceChannelsSlice.reducer;
