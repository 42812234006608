import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GroupChannelTypeInfo } from 'gcs-common/helper/channelTypeHelper';
import {
  getErpCustomerGroupChannelsLoading,
  getErpCustomerGroupChannels,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import {
  deleteGroupChannel,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';

import { fetchErpCustomerGroupChannels } from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import { filterByErpCustomerIdSelected } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Table from '../../../Table/Table';
import ProfileImageCell from '../../../Table/ProfileImageCell/ProfileImageCell';
import detailsIcon from '../../../../img/arrow.svg';
import deletedIcon from '../../../../img/delete.svg';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import GroupChannelDelete from '../../Channels/GroupChannelDelete/GroupChannelDelete';
import LozengeCell from '../../../Table/LozengeCell/LozengeCell';

import { stringSortingFunction } from '../../../../helper/sortHelper';

const erpCustomerColumns = [
  {
    Header: 'Gruppenbild',
    accessor: 'imageUrl',
    Cell: ProfileImageCell,
    disableSortBy: true,
  },
  {
    Header: 'Typ',
    accessor: 'groupType',
    filter: 'fuzzyText',
    Cell: (cell) => {
      if (!cell.value) return null;
      return cell.value && LozengeCell({
        value: GroupChannelTypeInfo[cell.value]
          ? GroupChannelTypeInfo[cell.value].name : cell.value,
        color: GroupChannelTypeInfo[cell.value]
          ? GroupChannelTypeInfo[cell.value].color : '#827f7f',
      });
    },
  },
  {
    Header: 'Name',
    accessor: 'friendlyName',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Nachrichten',
    accessor: 'messagesCount',
  },
  {
    Header: 'Teilnehmer',
    accessor: 'membersCount',
    filter: 'fuzzyText',
  },
  {
    Header: 'Letzte Nachricht gesendet',
    accessor: 'lastMessageDate',
    filter: 'fuzzyText',
    Cell: (cell) => {
      return cell.value ? moment(cell.value).format('ddd, D MMM YYYY HH:mm') : '';
    },
  },
  {
    Header: 'Erstellt von',
    accessor: 'createdBy',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Erstellt am',
    accessor: 'dateCreated',
    Cell: (cell) => {
      return cell.value ? moment(cell.value).format('ddd, D MMM YYYY HH:mm') : '-';
    },
  },
];

const ErpCustomerGroupChannelTable = ({ erpCustomerId, searchInputValue = '' }) => {
  const erpCustomerGroupChannels = useSelector(getErpCustomerGroupChannels);
  const erpCustomerGroupChannelsLoading = useSelector(getErpCustomerGroupChannelsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showChannelDelete, setShowChannelDelete] = useState(false);

  useEffect(() => {
    if (erpCustomerId) {
      dispatch(fetchErpCustomerGroupChannels({ erpCustomerId }));
    }
  }, [erpCustomerId, dispatch]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setShowChannelDelete(original);
    },
    [],
  );

  const groupChannelDeleted = useCallback(
    () => {
      setShowChannelDelete(false);
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    },
    [dispatch],
  );

  const onChannelSelected = (channel) => {
    const { id } = channel;
    dispatch(filterByErpCustomerIdSelected({ erpCustomerId }));
    navigate(`/administration/erpCustomers/channel/${id}`);
  };

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteGroupChannel(
        { channelId: showChannelDelete.id },
      ));
      if (!result.error) {
        groupChannelDeleted();
        setTimeout(() => {
          dispatch(fetchErpCustomerGroupChannels({ erpCustomerId }));
        }, 1000);
      }
    },
    [showChannelDelete, dispatch, groupChannelDeleted, erpCustomerId],
  );

  const message = useMemo(() => {
    return `Möchten Sie den Gruppen Channel "${showChannelDelete?.friendlyName}" wirklich löschen?`;
  }, [showChannelDelete]);

  return (
    <>
      <Table
        isLoading={erpCustomerGroupChannelsLoading}
        data={erpCustomerGroupChannels}
        columns={erpCustomerColumns}
        filterString={searchInputValue}
        onRowSelected={onChannelSelected}
        renderActions={({ row }) => (
          <>
            <button type="button">
              {/* eslint-disable-next-line max-len */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img
                alt="deleted-icon"
                src={deletedIcon}
                onClick={(event) => deleteSelected(event, row)}
              />
            </button>
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      {showChannelDelete && (
        <PopUpModal
          title="Channel löschen"
          onRequestClose={() => setShowChannelDelete(null)}
          isOpen
        >
          <GroupChannelDelete
            message={message}
            onCancel={() => setShowChannelDelete(null)}
            handleDelete={handleDelete}
          />
        </PopUpModal>
      )
      }
    </>
  );
};

ErpCustomerGroupChannelTable.propTypes = {
  searchInputValue: PropTypes.string,
  erpCustomerId: PropTypes.string.isRequired,
};

export default ErpCustomerGroupChannelTable;
