import PropTypes from 'prop-types';

import { NavLink, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

const AnimatedSidebarListItem = ({
  className = '', to, name, icon, iconSelected,
}) => {

  const { pathname } = useLocation();

  const selected = pathname.includes(to);

  return (
    <NavLink
      className={({ isActive }) => `${styles.animatedSidebarButton} ${isActive ? styles.isActive : ''} ${className}`}
      to={to}
    >
      <div className={styles.administrationSidebarIcon}>
        <img src={selected ? iconSelected : icon} alt={name} />
      </div>
      {name}
    </NavLink>
  );
};

AnimatedSidebarListItem.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iconSelected: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default AnimatedSidebarListItem;
