import { channelRemoved } from '../channelsSlice';
import { getIsAgent } from '../../currentUser/currentUserSelectors';
import { getIsSalesSpaceChannelType } from '../../channel/channelSelectors';

const handleChannelLeft = ({ channel }) => async (dispatch, getState) => {
  const { id: channelId } = channel;
  const isAgent = getIsAgent(getState());
  const isSalesSpace = getIsSalesSpaceChannelType(channelId)(getState());

  // remove channel only if it's not unjoined agent from the sales-space;
  if (!isSalesSpace && !isAgent) {
    dispatch(channelRemoved({ channel }));
  }
};

export default handleChannelLeft;
