import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPrimaryHouseForChannel } from '../../channel/channelSelectors';
import { getFilter } from '../processesSelectors';
import { getCraftsmanBusinessUnit, getIsAgent } from '../../currentUser/currentUserSelectors';
import { getErrorMsg } from '../../../helper/errorMessages';
import { FETCH_PROCESSES_ERRORS } from '../processesConstants';

const fetchProcesses = createAsyncThunk(
  'processes/fetch',
  async ({
    page, channelId, id, append = true,
  }, { getState, rejectWithValue, extra: { gccApiClient } }) => {

    const filter = getFilter(getState());
    const isAgent = getIsAgent(getState());
    const businessUnit = getCraftsmanBusinessUnit(getState());
    const houseId = getPrimaryHouseForChannel(channelId)(getState());
    const { name: queryString, type: queryType } = filter || {};

    const response = await gccApiClient.getProcesses(
      page,
      queryType,
      queryString,
      isAgent ? id : null,
      houseId,
    );
    if (response.error_code) {
      const { error_code: errorCode } = response;
      let errorMessage;
      const errorsDifferentMessage = [
        FETCH_PROCESSES_ERRORS.BLOCKED_ACCOUNT,
        FETCH_PROCESSES_ERRORS.NO_OP_ACCOUNT,
        FETCH_PROCESSES_ERRORS.MISSING_CREDENTIALS,
        FETCH_PROCESSES_ERRORS.SEARCH_DISABLED,
      ];

      if (errorsDifferentMessage.includes(response.error_code)) {
        errorMessage = getErrorMsg(`${errorCode}.${isAgent ? 'agent' : 'craftsman'}`, businessUnit, businessUnit);
      } else {
        errorMessage = getErrorMsg(errorCode, businessUnit, businessUnit);
      }
      return rejectWithValue({ errorMessage, errorCode, channelId });
    }

    return { response, channelId, append };
  },
);

export default fetchProcesses;
