import { createAction } from '@reduxjs/toolkit';
import { createClientFavoriteId } from '../../../helper/reduxOfflineHelper';

// we need to move the payload of the action into the action's meta
// because redux-offline will overwrite the payload with the return value from the effect
export const addFavoriteRollback = createAction('addFavoriteRollback', (payload) => ({ meta: payload }));

export const addFavoriteCommit = createAction('addFavoriteCommit', (payload) => ({ meta: payload }));

export const addFavoriteOffline = createAction('addFavoriteOffline', (payload) => {
  const temporaryFavoriteId = createClientFavoriteId();
  const meta = {
    ...payload,
    temporaryFavoriteId,
  };

  return {
    meta: {
      ...meta,
      offline: {
        commit: addFavoriteCommit(meta),
        rollback: addFavoriteRollback(meta),
      },
    },
  };
});
