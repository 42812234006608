import JSEMOJI from 'emoji-js';
import { checkText } from 'smile2emoji';

// emoji set up, by default it loads apple smileys
const jsemoji = new JSEMOJI();
jsemoji.replace_mode = 'unified';

const replaceWithEmoji = (text) => {
  const withTextEmojis = checkText(text);
  return jsemoji.replace_colons(withTextEmojis);
};

// eslint-disable-next-line import/prefer-default-export
export { replaceWithEmoji };
