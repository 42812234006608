import { useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelSalesSpaceName,
  getMainContactId,
  getMemberAgentIdsForChannel,
  getSalesSpaceLogo,
} from 'gcs-common/slices/channel/channelSelectors';
import { ICON } from 'gcs-common/constants/IconConstants';
import { getSimpleLogo } from 'gcs-common/slices/theme/themeSelectors';
import IconComponent from '../IconComponent/IconComponent';
import NavBarMember from '../NavBarMember/NavBarMember';
import styles from './styles.module.scss';

const SalesSpaceChannelHeaderBar = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const memberAgentIds = useSelector(getMemberAgentIdsForChannel(selectedChannelId));
  const logo = useSelector(getSalesSpaceLogo(selectedChannelId));
  const salesSpaceName = useSelector(getChannelSalesSpaceName(selectedChannelId));
  const appLogo = useSelector(getSimpleLogo);

  const mainContactPersonId = useSelector(getMainContactId(selectedChannelId));

  return (
    <div className={styles.container}>
      <img
        className={styles.channelImage}
        alt="profile"
        src={logo || appLogo}
      />
      <div>
        {salesSpaceName}
      </div>
      <div className={styles.arrowIcon}>
        <IconComponent Icon={ICON.ARROW} />
      </div>
      <div className={styles.groupMembers}>
        {memberAgentIds.map(userId => {
          return (
            <NavBarMember
              userId={userId}
              channelId={selectedChannelId}
              isMainContact={mainContactPersonId === userId}
              key={userId}
              showNameUnderImage={false}
            />
          );
        })}
      </div>
    </div>

  );
};

export default SalesSpaceChannelHeaderBar;
