import { convertPriceToString } from 'gcs-common/helper/processTextHelper';
import styles from './styles.module.scss';
import ProductPropType from '../../../models/ProductPropType';
// import ProductQuantity from '../../ProductQuantity/ProductQuantity';

const ProductDetail = ({ product }) => {
  return (
    <div className={styles.productDetailWrapper}>
      <div className={styles.productDetail}>
        <div className={styles.productArticleNumber}>
          {product.ArticleNumber}
        </div>
        <div className={styles.productDescription}>
          <div>{product.ArticleDescription1}</div>
          <div>{product.ArticleDescription2}</div>
          {/* <ProductQuantity
            product={product}
          /> */}
          <span>{`Listenpreis: ${convertPriceToString(product?.GrossSalesPrice, product) || 'Nicht verfügbar'}`}</span>
          <span>{`Nettopreis: ${convertPriceToString(product?.NettoSalesPrice, product) || 'Nicht verfügbar'}`}</span>
        </div>
      </div>
    </div>
  );
};

ProductDetail.propTypes = {
  product: ProductPropType.isRequired,
};

export default ProductDetail;
