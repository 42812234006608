/* eslint-disable no-console */
import { useState, useEffect, useCallback } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

interface MsTeamsClient {
  isInitialized: boolean;
  getContext: () => Promise<microsoftTeams.app.Context | null>;
  getAuthToken: () => Promise<string | null>;
  error: Error | null;
}

const useMsTeamsClient = (): MsTeamsClient => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  // Initialize the Teams client
  const init = useCallback(async () => {
    if (!isInitialized) {
      try {
        await microsoftTeams.app.initialize();
        console.log('### Teams initialized');
        setIsInitialized(true);
      } catch (initError) {
        console.error('### Teams initialization failed', initError);
        setError(initError as Error);
      }
    }
  }, [isInitialized]);

  // Get the Microsoft Teams context
  const getContext = useCallback(async (): Promise<microsoftTeams.app.Context | null> => {
    if (!isInitialized) {
      throw new Error('Teams not initialized!');
    }
    try {
      const context = await microsoftTeams.app.getContext();
      return context;
    } catch (contextError) {
      console.error('### Failed to get Teams context', contextError);
      setError(contextError as Error);
      return null;
    }
  }, [isInitialized]);

  // Get the Microsoft Teams auth token
  const getAuthToken = useCallback(async (): Promise<string | null> => {
    if (!isInitialized) {
      throw new Error('Teams not initialized!');
    }
    try {
      const token = await microsoftTeams.authentication.getAuthToken();
      console.log('### Teams getAuthToken success', token);
      return token;
    } catch (authError) {
      console.error('### Teams getAuthToken failed', authError);
      setError(authError as Error);
      return null;
    }
  }, [isInitialized]);

  // Ensure initialization happens when the hook is first used
  useEffect(() => {
    init();
  }, [init]);

  return {
    isInitialized,
    getContext,
    getAuthToken,
    error,
  };
};

export default useMsTeamsClient;
