import { getIsHidden } from '../../channel/channelSelectors';
import { getCurrentUser } from '../currentUserSelectors';
import { currentUserUpdated } from '../currentUserSlice';

const updateCurrentUserArchivedChannels = ({ channelId }) => async (dispatch, getState) => {
  const isChannelHidden = getIsHidden(channelId)(getState());
  const currentUser = getCurrentUser(getState());

  if (isChannelHidden) {
    const hiddenChannels = currentUser.hiddenChannels.filter(id => id !== channelId);
    const updatedUser = { ...currentUser, hiddenChannels };

    dispatch(currentUserUpdated({ user: updatedUser }));
  }
};

export default updateCurrentUserArchivedChannels;
