import { createSelector } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';
import moment from 'moment';
import { isClientMessageId } from '../../helper/reduxOfflineHelper';
import { getSelectedChannelId } from '../channels/channelsSelectors';
import { getCurrentUserId } from '../currentUser/currentUserSelectors';
import ASYNC_STATE from '../../constants/AsyncState';
import { DEFAULT_LAST_CONSUMED_MESSAGE_INDEX } from './messageConstants';

export const getMessageState = state => state.messages;
export const getMessages = state => state.messages.messages;
export const getChannelMessages = state => state.messages.channelMessages;
export const getFetchMessageErrors = state => state.messages.fetchMessageErrors;
export const getMessageImageUrls = state => state.messages.messageImageUrls;
export const getMessageImageUrlErrors = state => state.messages.messageImageUrlErrors;
export const getChannelFirstMessageLoaded = state => state.messages.channelFirstMessageLoaded;
export const getMediaDownloadIsLoading = state => (
  state.messages.downloadMedia.status === ASYNC_STATE.LOADING
);

export const getMessageIdsForSelectedChannel = createSelector(
  getSelectedChannelId,
  getChannelMessages,
  (selectedChannelId, channelMessages) => {
    return channelMessages && channelMessages[selectedChannelId];
  },
);

// Note: should not export this since we only want to hand over message Ids
// and select messages within the components
const getMessagesForSelectedChannel = createSelector(
  [getMessageIdsForSelectedChannel, getMessages],
  (messageIdsForSelectedChannel, messages) => {
    return messageIdsForSelectedChannel && messageIdsForSelectedChannel.map(id => messages[id]);
  },
);

export const getLatestMessageForSelectedChannel = createSelector(
  getMessagesForSelectedChannel,
  (messages) => messages && messages[messages.length - 1],
);

export const getLatestMessageIdForSelectedChannel = createSelector(
  getLatestMessageForSelectedChannel,
  (latestMessage) => {
    return latestMessage && latestMessage.id;
  },
);

export const getMessageIdsForChannel = (channelId) => createSelector(
  getChannelMessages,
  (channelMessages) => {
    return channelMessages && channelMessages[channelId];
  },
);

export const getMessagesForChannel = (channelId) => createSelector(
  getMessageIdsForChannel(channelId),
  getMessages,
  (messageIds, allMessages) => {
    return messageIds && messageIds.map(messageId => allMessages[messageId]);
  },
);

export const getLatestMessageIndexForChannel = (channelId) => createSelector(
  getMessagesForChannel(channelId),
  (messages) => {
    const index = messages?.slice().reverse().find(m => (
      m.index > -1 && !isClientMessageId(m.id)
    ))?.index;
    if (messages && index === undefined) {
      captureMessage(
        'getLatestMessageIndexForChannel: undefined index',
        {
          level: 'warning',
          extra: { channelId, messages },
        },
      );
    }
    return index ?? DEFAULT_LAST_CONSUMED_MESSAGE_INDEX;
  },
);

export const getMessagesForChannelReversed = createSelector(
  (channelId) => channelId,
  (channelId) => createSelector(
    getMessageIdsForChannel(channelId),
    getMessages,
    (messageIds, allMessages) => {
      if (!messageIds) {
        return undefined;
      }
      const reversed = [...messageIds].reverse();
      return reversed.map(messageId => allMessages[messageId]);
    },
  ),
);

export const getChannelMessageDateTagsForSelectedChannel = createSelector(
  getMessagesForSelectedChannel,
  (messagesForChannel) => {
    const dates = {};
    const dateCache = {};
    // Note: slight performance optimization, as this function was initially slow
    messagesForChannel.forEach((message, index) => {
      const { id, createdAt: dateMessageCreated } = message;
      const dateCreated = moment(dateMessageCreated);
      dateCache[id] = dateCreated;
      let showDateTag = (index === 0);
      if (!showDateTag) {
        const {
          id: idBefore,
          createdAt: dateMessageCreatedBefore,
        } = messagesForChannel[index - 1];
        const dateCreateBefore = dateCache[idBefore]
          ? dateCache[idBefore]
          : moment(dateMessageCreatedBefore);
        showDateTag = !dateCreated.isSame(dateCreateBefore, 'day');
      }
      if (showDateTag) {
        dates[id] = dateCreated;
      }
    });
    return dates;
  },
);

export const getChannelOldestLoadedMessage = (channelId) => createSelector(
  getMessagesForChannel(channelId),
  (channelMessages) => {
    if (!channelMessages || channelMessages.length === 0) {
      return null;
    }
    return channelMessages[0];
  },
);

export const getChannelOldestLoadedMessageIndex = (channelId) => createSelector(
  getChannelOldestLoadedMessage(channelId),
  (oldestLoadedMessage) => {
    return oldestLoadedMessage ? oldestLoadedMessage.index : undefined;
  },
);

export const getChannelNewestMessage = (channelId) => createSelector(
  getMessagesForChannel(channelId),
  (channelMessages) => {
    if (!channelMessages || channelMessages.length === 0) {
      return null;
    }
    return channelMessages[channelMessages.length - 1];
  },
);

export const getChannelNewestLoadedMessageIndex = (channelId) => createSelector(
  getMessagesForChannel(channelId),
  (messages) => messages && messages.slice().reverse().find(m => m.index > -1)?.index,
);

export const getChannelNewestMessageIsFromMe = (channelId) => createSelector(
  [getChannelNewestMessage(channelId), getCurrentUserId],
  (newestMessage, currentUserId) => {
    if (!newestMessage) {
      return false;
    }
    const { author } = newestMessage;
    return author === currentUserId;
  },
);

export const getImageMessageIds = createSelector(
  getMessagesForSelectedChannel,
  (messages) => {
    return messages && messages
      .filter(msg => !msg.deleted)
      .filter(msg => msg && msg.contentType && !!msg.contentType.match(/^image/))
      .map(msg => msg.id);
  },
);

/**
 * UI stuff
 */
export const getLoadPreviousMessagesError = createSelector(
  getMessageState,
  (uiState) => uiState.loadPreviousMessages.error,
);

export const getLoadPreviousMessagesIsLoading = createSelector(
  getMessageState,
  (uiState) => uiState.loadPreviousMessages.status === ASYNC_STATE.LOADING,
);
