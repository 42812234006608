import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ICON } from '../../constants/IconConstants';
import styles from './styles.module.scss';
import OrderButton from '../OrderButton/OrderButton';
import { getIsMobile } from '../../slices/init/initSelectors';
import { getOrder, getSelectedOrderId } from '../../slices/quickOrdering/quickOrderingSelectors';

const CheckoutFinished = () => {
  const navigate = useNavigate();
  const isMobile = useSelector(getIsMobile);
  const selectedOrderId = useSelector(getSelectedOrderId);
  const selectedOrder = useSelector(getOrder(selectedOrderId || ''));

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <ICON.CHECKMARK style={{
          width: '80px',
          height: '80px',
          color: styles.success,
          margin: '0 auto',
        }}
        />
        <div className={styles.h1}>
          Bestellung abgeschlossen
        </div>
        <div className={styles.processNumber}>
          {`Vorgangsnummer: ${selectedOrder?.processNumber}`}
        </div>
        <div className={styles.h2}>
          Sobald Ihr Innendienstmitarbeiter die Bestellung freigegeben hat,
          erscheint sie unter &quot;Vorgänge&quot;
        </div>
        {isMobile && (
        <OrderButton onClick={() => navigate('/')}>
          Zurück zum Chat
        </OrderButton>
        )}
      </div>
    </div>
  );
};

export default CheckoutFinished;
