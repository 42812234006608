import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { closeVideoFullscreen } from 'gcs-common/slices/uiState/uiStateSlice';
import closeIcon from '../../img/close_white.svg';
import styles from './styles.module.scss';
import VideoStreamPlayer from '../VideoStreamPlayer/VideoStreamPlayer';

const VideoFullscreen = ({ videoId }) => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeVideoFullscreen());
  }, [dispatch]);

  return (
    <div className={styles.videoFullscreenWrapper}>
      <div className={styles.closeBtnWrapper}>
        <button className={styles.closeBtn} type="button" onClick={onClose}>
          <img
            className={styles.icon}
            src={closeIcon}
            alt="close"
          />
        </button>
      </div>
      <VideoStreamPlayer videoId={videoId} />
    </div>
  );
};

export default VideoFullscreen;
VideoFullscreen.propTypes = {
  videoId: PropTypes.string.isRequired,
};
