/* eslint-disable react/jsx-one-expression-per-line */
import { useSelector } from 'react-redux';
import { getDeleteAccountText } from 'gcs-common/slices/theme/themeSelectors';
import { useNavigate } from 'react-router';
import closeIcon from '../../img/close_white.svg';
import styles from './styles.module.scss';
import Button from '../Button/Button';

const DeleteAccountPage = () => {
  const navigate = useNavigate();
  const deleteAccountText = useSelector(getDeleteAccountText);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>Daten löschen in BEEM Apps</div>
        <Button className={styles.noBorder} type="button" onClick={() => navigate(-1)}>
          <img src={closeIcon} alt="Zurück" />
        </Button>
      </div>
      <div className={styles.textWrapper}>
        <p>{deleteAccountText}</p>
      </div>
    </div>
  );
};

export default DeleteAccountPage;
