import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getErpCustomers,
  getErpCustomersPaginationResult,
  getDeleteErpCustomerDefaultErrorMessage,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import { fetchErpCustomers, deleteErpCustomer } from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import { resetDeleteErpCustomerDefaultErrorMessage } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import { showSuccessNotification, showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import PaginatedTable from '../../../PaginatedTable/PaginatedTable';
import addErpCustomerIcon from '../../../../img/plus_white.svg';
import ErpCustomerCreate from '../ErpCustomerCreate/ErpCustomerCreate';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import deletedIcon from '../../../../img/delete.svg';
import detailsIcon from '../../../../img/arrow.svg';
import ErpCustomerDelete from '../ErpCustomerDelete/ErpCustomerDelete';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import { SORT_ORDER } from '../../../../constants/sortConstants';
import { Spinner } from '../../../Spinner/Spinner';
import LozengeCell from '../../../Table/LozengeCell/LozengeCell';

const columns = [
  {
    Header: 'Kundennummer',
    accessor: 'customerNumber',
  },
  {
    Header: 'LDA',
    accessor: 'ldaNumber',
    Cell: (cell) => cell.value && LozengeCell({
      value: cell.value,
      color: '#808488',
    }),
  },
  {
    Header: 'Firmenname',
    accessor: 'friendlyName',
  },
  {
    Header: '# Nutzer',
    accessor: 'userCount',
  },
];

const ErpCustomerTable = ({ onErpCustomerSelected, erpCustomersLoading }) => {

  const erpCustomers = useSelector(getErpCustomers);
  const erpCustomersPagination = useSelector(getErpCustomersPaginationResult);
  const globalSearchInput = useSelector(getGlobalSearchInput);
  const deleteErpCustomerErrorMessage = useSelector(getDeleteErpCustomerDefaultErrorMessage);
  const [erpCustomerToDelete, setErpCustomerToDelete] = useState(null);
  const [showDeleteErpCustomerModal, setShowDeleteErpCustomerModal] = useState(false);
  const dispatch = useDispatch();
  const [createErpCustomer, setCreateErpCustomer] = useState(false);
  const accessorToBeSorted = columns.find(element => element.Header).accessor;
  const [sortedColumnId, setSortedColumnId] = useState(accessorToBeSorted);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASCENDING);
  const [erpCustomerIdsToDelete, setErpCustomerIdsToDelete] = useState([]);

  const loadErpCustomers = useCallback((pageIndex, pageSize, searchQuery) => {
    dispatch(fetchErpCustomers({
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
    }));
  }, [dispatch, sortOrder, sortedColumnId]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setErpCustomerToDelete(original);
      setShowDeleteErpCustomerModal(true);
    },
    [],
  );

  useEffect(() => {
    if (deleteErpCustomerErrorMessage) {
      dispatch(showFailureNotification(
        deleteErpCustomerErrorMessage,
        dispatch(resetDeleteErpCustomerDefaultErrorMessage()),
      ));
    }
  }, [deleteErpCustomerErrorMessage, dispatch]);

  const removeFromErpCustomerIdsToDelete = useCallback((erpCustomerId) => {
    const filteredErpCustomerIds = erpCustomerIdsToDelete.filter(id => id !== erpCustomerId);
    setErpCustomerIdsToDelete(filteredErpCustomerIds);
  }, [erpCustomerIdsToDelete]);

  const addToErpCustomerIdsToDelete = useCallback((erpCustomerId) => {
    const erpCustomerIds = [...erpCustomerIdsToDelete, erpCustomerId];
    setErpCustomerIdsToDelete(erpCustomerIds);
  }, [erpCustomerIdsToDelete]);

  const handleDelete = useCallback(async () => {
    const erpCustomerId = erpCustomerToDelete.id;
    addToErpCustomerIdsToDelete(erpCustomerId);
    setShowDeleteErpCustomerModal(false);
    const result = await dispatch(deleteErpCustomer({ erpCustomerId }));
    removeFromErpCustomerIdsToDelete(erpCustomerId);
    setErpCustomerToDelete(null);
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    }

  }, [
    addToErpCustomerIdsToDelete,
    erpCustomerToDelete,
    dispatch,
    removeFromErpCustomerIdsToDelete]);

  const closeDeleteModal = useCallback(
    () => {
      setErpCustomerToDelete(null);
      setShowDeleteErpCustomerModal(false);
    },
    [],
  );

  const erpCustomerIsCreatedSuccessfully = useCallback(() => {
    setCreateErpCustomer(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  const extendedErpCustomers = useMemo(() => {
    return erpCustomers.map(erpCustomer => {
      if (erpCustomerIdsToDelete.includes(erpCustomer.id)) {
        return { ...erpCustomer, isDeleting: true };
      }
      return erpCustomer;
    });
  }, [erpCustomers, erpCustomerIdsToDelete]);

  return (
    <>
      <PaginatedTable
        onRowSelected={onErpCustomerSelected}
        data={extendedErpCustomers}
        onPaginationChanged={loadErpCustomers}
        columns={columns}
        searchInput={globalSearchInput}
        pagination={erpCustomersPagination}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortedColumnId={sortedColumnId}
        setSortedColumnId={setSortedColumnId}
        sortIsLoading={erpCustomersLoading}
        renderActions={({ row }) => (
          <>
            {row.original.isDeleting ? (<Spinner />) : (
              /* eslint-disable-next-line max-len */
              /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
              <img
                alt="deleted-icon"
                src={deletedIcon}
                onClick={(event) => deleteSelected(event, row)}
              />
            )
            }
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      <AddFabButton
        icon={addErpCustomerIcon}
        title="Kunde"
        onClick={() => setCreateErpCustomer(true)}
      />
      {createErpCustomer && (
        <PopUpModal
          title="Neuer Kunde"
          onRequestClose={() => setCreateErpCustomer(false)}
          isOpen
        >
          <ErpCustomerCreate
            onCancel={() => setCreateErpCustomer(false)}
            onSuccess={erpCustomerIsCreatedSuccessfully}
          />
        </PopUpModal>
      )
      }
      {showDeleteErpCustomerModal && (
        <PopUpModal
          title="Kunden löschen"
          onRequestClose={closeDeleteModal}
          isOpen
        >
          <ErpCustomerDelete
            erpCustomer={erpCustomerToDelete}
            onCancel={closeDeleteModal}
            handleDelete={handleDelete}
          />
        </PopUpModal>
      )
      }
    </>
  );
};

ErpCustomerTable.propTypes = {
  onErpCustomerSelected: PropTypes.func.isRequired,
  erpCustomersLoading: PropTypes.bool.isRequired,
};

export default ErpCustomerTable;
