import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

class MessageLikeTextbox extends Component {
  render() {
    const { className = '', right = false, children, width = '100%' } = this.props;
    return (
      <div
        style={{ maxWidth: width }}
        className={`${styles.messageLookalike} ${className} ${right ? styles.right : ''}`}
      >
        {children}
      </div>
    );
  }
}

export default MessageLikeTextbox;

MessageLikeTextbox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  right: PropTypes.bool,
};
