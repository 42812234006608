import { useState } from 'react';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import IconComponent from '../IconComponent/IconComponent';
import styles from './styles.module.scss';

const OpMaintenanceBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  if (!showBanner) return null;

  return (
    <div className={styles.opMaintenanceBanner}>
      <div className={styles.bannerText}>{opMaintenanceMessage}</div>
      <button className={styles.closeButton} type="button" onClick={() => setShowBanner(false)}>
        <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.BLACK} />
      </button>
    </div>
  );
};

export default OpMaintenanceBanner;
