import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getDirectCommunicationStructureIds, getTeamCommunicationStructureIds,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSelectors';
import {
  fetchHouseCommunicationStructures, fetchAllAssignmentRoles,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureThunks';
import {
  fetchAllResponsibilityRoles,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersThunk';
import {
  CommunicationAssignmentType,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructuresConstants';
import {
  getCustomerResponsibilityRoles,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersSelector';
import PlusButton from 'gcs-common/components/PlusButton/PlusButton';
import CommunicationStructureRowCollapsed from '../CommunicationStructureRowCollapsed/CommunicationStructureRowCollapsed';
import CommunicationStructureRowOpen from '../CommunicationStructureRowOpen/CommunicationStructureRowOpen';
import styles from './styles.module.scss';
import CommuniactionStructureModal from '../../../CommunicationStructureModal/CommuniactionStructureModal';

const HouseCommunicationStructure = ({ houseId }) => {

  const hasGlobalAdminPermission = useSelector(getHasPermission('global_admin'));

  const dispatch = useDispatch();
  // eslint-disable-next-line max-len
  const [newCommunicationStructureAssignmentType, setNewCommunicationStructureAssignmentType] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const openCommunicationStructureModal = (option, type) => {
    setNewCommunicationStructureAssignmentType(type);
    setIsOpen(option);
  };

  const directCommunicationStructureIds = useSelector(getDirectCommunicationStructureIds);
  const teamCommunicationStructureIds = useSelector(getTeamCommunicationStructureIds);
  const customerResponsibilityRoles = useSelector(getCustomerResponsibilityRoles);

  useEffect(() => {
    dispatch(fetchHouseCommunicationStructures({ houseId }));
    dispatch(fetchAllResponsibilityRoles({ view: 'communication_structure', houseId }));
    dispatch(fetchAllAssignmentRoles({}));
  }, [dispatch, houseId, triggerRefetch]);

  if (!customerResponsibilityRoles?.length) {
    return null;
  }

  const handleSuccessfulDeletion = () => setTriggerRefetch(!triggerRefetch);

  return (
    <>
      <div>
        <div className={styles.rowHeader}>
          <div className={styles.chat}>
            <span>Teamchat</span>
            <span>Kontakte</span>
          </div>
          {hasGlobalAdminPermission
          && !(newCommunicationStructureAssignmentType === CommunicationAssignmentType.SALES_SPACE)
          && (
            <PlusButton
              clickHandler={() => {
                openCommunicationStructureModal(true, CommunicationAssignmentType.SALES_SPACE);
                setEditRowId(null);
              }}
            />
          )
        }
        </div>
        {teamCommunicationStructureIds.map(id => {
          return (
            (editRowId === id)
              ? (
                <CommunicationStructureRowOpen
                  houseId={houseId}
                  isTeamRow
                  isEditForm
                  // eslint-disable-next-line max-len
                  setNewCommunicationStructureAssignmentType={setNewCommunicationStructureAssignmentType}
                  setEditRowId={setEditRowId}
                  communicationStructureId={editRowId}
                  key={id}
                  onDeletion={handleSuccessfulDeletion}
                />
              ) : (
                <CommunicationStructureRowCollapsed
                  communicationStructureId={id}
                  // eslint-disable-next-line max-len
                  setNewCommunicationStructureAssignmentType={setNewCommunicationStructureAssignmentType}
                  setEditRowId={setEditRowId}
                  key={id}
                />
              )
          );
        })}
        {newCommunicationStructureAssignmentType === CommunicationAssignmentType.SALES_SPACE && (
          <CommuniactionStructureModal
            isOpen={isOpen}
            onRequestClose={() => openCommunicationStructureModal(false, false)}
            title="Communiacation Structure"
          >
            <CommunicationStructureRowOpen
              houseId={houseId}
              isTeamRow
              setNewCommunicationStructureAssignmentType={
                setNewCommunicationStructureAssignmentType
              }
              setEditRowId={setEditRowId}
              isModalOpen={isOpen}
              onDeletion={handleSuccessfulDeletion}
            />
          </CommuniactionStructureModal>
        )}
      </div>

      <div>
        <div className={styles.rowHeader}>
          <div className={styles.chat}>
            <span>Direkte Chats</span>
          </div>
          {hasGlobalAdminPermission
          && !(newCommunicationStructureAssignmentType === CommunicationAssignmentType.DIRECT)
          && (
            <PlusButton
              clickHandler={() => {
                openCommunicationStructureModal(true, CommunicationAssignmentType.DIRECT);
                setEditRowId(null);
              }}
            />
          )
        }
        </div>
        {directCommunicationStructureIds.map(id => {
          return (
            (editRowId === id)
              ? (
                <CommunicationStructureRowOpen
                  houseId={houseId}
                  isEditForm
                  setEditRowId={setEditRowId}
                  // eslint-disable-next-line max-len
                  setNewCommunicationStructureAssignmentType={setNewCommunicationStructureAssignmentType}
                  communicationStructureId={editRowId}
                  key={id}
                  onDeletion={handleSuccessfulDeletion}
                />
              ) : (
                <CommunicationStructureRowCollapsed
                  communicationStructureId={id}
                  // eslint-disable-next-line max-len
                  setNewCommunicationStructureAssignmentType={setNewCommunicationStructureAssignmentType}
                  setEditRowId={setEditRowId}
                  key={id}
                />
              )
          );
        })}
        {(newCommunicationStructureAssignmentType === CommunicationAssignmentType.DIRECT) && (
          <CommuniactionStructureModal
            isOpen={isOpen}
            onRequestClose={() => openCommunicationStructureModal(false, false)}
            title="Communiacation Structure"
          >
            <CommunicationStructureRowOpen
              houseId={houseId}
              setNewCommunicationStructureAssignmentType={
                setNewCommunicationStructureAssignmentType
              }
              setEditRowId={setEditRowId}
              isModalOpen={isOpen}
            />
          </CommuniactionStructureModal>
        )}
      </div>
    </>
  );
};

HouseCommunicationStructure.propTypes = {
  houseId: PropTypes.string.isRequired,
};

export default HouseCommunicationStructure;
