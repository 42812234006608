/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../../helper/errorMessages';
import {
  createErpCustomer,
  deleteErpCustomer,
  fetchErpCustomers,
  fetchErpCustomerDirectChannels, fetchErpCustomerGroupChannels, fetchErpCustomerUsers,
  fetchSelectedErpCustomer,
  updateErpCustomer,
  createCraftsmanToCraftsmanDirectChannel,
  createAgentToCraftsmanDirectChannel,
  createErpCustomerUser,
  fetchSelectedErpCustomers,
  deleteErpCustomerUser,
} from './erpCustomersThunks';
import ASYNC_STATE from '../../../constants/AsyncState';
import { byId } from '../../../helper/byKey';
import paginateReducer from '../../../helper/reduxPaginationHelper';

const asyncState = {
  fetchErpCustomers: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  delete: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  fetchSelected: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  updateSelected: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  fetchDirectChannels: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchGroupChannels: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchErpCustomerUsers: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  createAgentToCraftsmanDirectChannel: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  createCraftsmanToCraftsmanDirectChannel: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  createUser: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  fetchSelectedErpCustomers: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  deleteErpCustomerUser: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  erpCustomers: {},
  selectedErpCustomerId: undefined,
  filterByErpCustomerId: undefined,
  erpCustomerDirectChannels: [],
  erpCustomerGroupChannels: [],
  erpCustomerUsers: [],
  fetchSelectedErpCustomers: [],
};

const erpCustomersSlice = createSlice({
  name: 'erpCustomers',
  initialState,
  reducers: {
    erpCustomerSelected: (state, action) => {
      const { erpCustomerId } = action.payload;
      state.selectedErpCustomerId = erpCustomerId;
    },
    erpCustomerSelectionReset: (state) => {
      state.selectedErpCustomerId = undefined;
    },
    filterByErpCustomerIdSelected: (state, action) => {
      const { erpCustomerId } = action.payload;
      state.filterByErpCustomerId = erpCustomerId;
    },
    filterByErpCustomerIdReset: (state) => {
      state.filterByErpCustomerId = undefined;
    },
    resetCreateErpCustomerErrorMessage: (state) => {
      state.create.errorMessage = null;
    },
    resetCreateErpCustomerDefaultErrorMessage: (state) => {
      state.create.defaultErrorMessage = null;
    },
    resetUpdateSelectedErpCustomerErrorMessage: (state) => {
      state.updateSelected.errorMessage = null;
    },
    resetUpdateSelectedErpCustomerDefaultErrorMessage: (state) => {
      state.updateSelected.defaultErrorMessage = null;
    },
    resetDeleteErpCustomerDefaultErrorMessage: (state) => {
      state.delete.defaultErrorMessage = null;
    },
    resetCreateErpCustomerDirectChannelErrorMessage: (state) => {
      state.createAgentToCraftsmanDirectChannel.errorMessage = null;
      state.createCraftsmanToCraftsmanDirectChannel.errorMessage = null;
    },
    resetCreateErpCustomerUserDefaultErrorMessage: (state) => {
      state.createUser.defaultErrorMessage = null;
    },
    resetCreateErpCustomerUserErrorMessage: (state) => {
      state.createUser.errorMessage = null;
    },
    resetErpCustomerDirectChannelsErrorMessage: (state) => {
      state.fetchDirectChannels.errorMessage = null;
    },
    resetDeleteErpCustomerUserErrorMessage: (state) => {
      state.deleteErpCustomerUser.errorMessage = null;
    },
  },
  extraReducers: {
    [fetchErpCustomers.pending]: (state) => {
      state.erpCustomers = {};
      state.fetchErpCustomers.status = ASYNC_STATE.LOADING;
    },
    [fetchErpCustomers.fulfilled]: paginateReducer(
      (state) => state.fetchErpCustomers,
      (state, action) => {
        const { data } = action.payload;
        state.fetchErpCustomers.status = ASYNC_STATE.SUCCEEDED;
        state.erpCustomers = { ...byId(data) };
      },
    ),
    [fetchErpCustomers.rejected]: (state, action) => {
      state.fetchErpCustomers.status = ASYNC_STATE.FAILED;
      state.fetchErpCustomers.error = action.error;
    },
    [fetchSelectedErpCustomers.pending]: (state) => {
      state.fetchSelectedErpCustomers.status = ASYNC_STATE.LOADING;
    },
    [fetchSelectedErpCustomers.fulfilled]: (state, action) => {
      const erpCustomers = action.payload;
      state.fetchSelected.status = ASYNC_STATE.SUCCEEDED;
      state.fetchSelectedErpCustomers = erpCustomers;

    },
    [fetchSelectedErpCustomers.rejected]: (state, action) => {
      state.fetchSelectedErpCustomers.status = ASYNC_STATE.FAILED;
      state.fetchSelectedErpCustomers.error = action.error;
    },
    [createErpCustomer.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
    },
    [createErpCustomer.fulfilled]: (state) => {
      state.create.status = ASYNC_STATE.SUCCEEDED;
    },
    [createErpCustomer.rejected]: (state, action) => {
      const {
        payload: { errorCode, description } = {},
      } = action;
      state.create.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_erp_customer.existing') {
        state.create.errorMessage = getErrorMsg('create_erp_customer.existing');
      } else {
        state.create.defaultErrorMessage = getErrorMsg(errorCode) || description || getErrorMsg('create_erp_customer.default');
      }
    },
    [deleteErpCustomer.pending]: (state) => {
      state.delete.status = ASYNC_STATE.LOADING;
    },
    [deleteErpCustomer.fulfilled]: (state) => {
      state.delete.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteErpCustomer.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.delete.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode.split('.')[1] === 'execution_in_progress') {
        state.delete.defaultErrorMessage = getErrorMsg('delete_erpCustomer.execution_in_progress');
      } else {
        state.delete.defaultErrorMessage = getErrorMsg('default');
      }
    },
    [fetchSelectedErpCustomer.pending]: (state) => {
      state.fetchSelected.status = ASYNC_STATE.LOADING;
    },
    [fetchSelectedErpCustomer.fulfilled]: (state, action) => {
      const { erpCustomer } = action.payload;
      state.fetchSelected.status = ASYNC_STATE.SUCCEEDED;
      const { id } = erpCustomer;
      state.erpCustomers[id] = erpCustomer;
      state.selectedErpCustomerId = id;
    },
    [fetchSelectedErpCustomer.rejected]: (state, action) => {
      state.fetchSelected.status = ASYNC_STATE.FAILED;
      state.fetchSelected.error = action.error;
    },
    [updateErpCustomer.pending]: (state) => {
      state.updateSelected.status = ASYNC_STATE.LOADING;
    },
    [updateErpCustomer.fulfilled]: (state, action) => {
      const { erpCustomer } = action.payload;
      state.updateSelected.status = ASYNC_STATE.SUCCEEDED;
      const { id } = erpCustomer;
      state.erpCustomers[id] = erpCustomer;
      state.selectedErpCustomerId = id;
    },
    [updateErpCustomer.rejected]: (state, action) => {
      const {
        payload: { errorCode, description } = {},
      } = action;
      state.updateSelected.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'update_erpCustomer.existing') {
        state.updateSelected.errorMessage = getErrorMsg('create_erpCustomer.existing');
      } else {
        state.updateSelected.defaultErrorMessage = getErrorMsg(errorCode) || description || getErrorMsg('default');
      }
    },
    [fetchErpCustomerDirectChannels.pending]: (state) => {
      state.fetchDirectChannels.status = ASYNC_STATE.LOADING;
    },
    [fetchErpCustomerDirectChannels.fulfilled]: (state, action) => {
      const { channels } = action.payload;
      state.fetchDirectChannels.status = ASYNC_STATE.SUCCEEDED;
      state.erpCustomerDirectChannels = channels;
    },
    [fetchErpCustomerDirectChannels.rejected]: (state) => {
      state.fetchDirectChannels.status = ASYNC_STATE.FAILED;
      state.fetchDirectChannels.errorMessage = getErrorMsg('fetch_channels.default');
    },
    [fetchErpCustomerGroupChannels.pending]: (state) => {
      state.fetchGroupChannels.status = ASYNC_STATE.LOADING;
    },
    [fetchErpCustomerGroupChannels.fulfilled]: (state, action) => {
      const { channels } = action.payload;
      state.fetchGroupChannels.status = ASYNC_STATE.SUCCEEDED;
      state.erpCustomerGroupChannels = channels;
    },
    [fetchErpCustomerGroupChannels.rejected]: (state, action) => {
      state.fetchGroupChannels.status = ASYNC_STATE.FAILED;
      state.fetchGroupChannels.error = action.error;
    },
    [fetchErpCustomerUsers.pending]: (state) => {
      state.fetchErpCustomerUsers.status = ASYNC_STATE.LOADING;
    },
    [fetchErpCustomerUsers.fulfilled]: (state, action) => {
      const { users } = action.payload;
      state.fetchErpCustomerUsers.status = ASYNC_STATE.SUCCEEDED;
      state.erpCustomerUsers = users;
    },
    [fetchErpCustomerUsers.rejected]: (state, action) => {
      state.fetchErpCustomerUsers.status = ASYNC_STATE.FAILED;
      state.fetchErpCustomerUsers.error = action.error;
    },
    [createAgentToCraftsmanDirectChannel.pending]: (state) => {
      state.createAgentToCraftsmanDirectChannel.status = ASYNC_STATE.LOADING;
    },
    [createAgentToCraftsmanDirectChannel.fulfilled]: (state) => {
      state.createAgentToCraftsmanDirectChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [createAgentToCraftsmanDirectChannel.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.createAgentToCraftsmanDirectChannel.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_agent_channel.existing') {
        state.createAgentToCraftsmanDirectChannel.errorMessage = getErrorMsg('create_channel_for_erpCustomer.existing');
      } else {
        state.createAgentToCraftsmanDirectChannel.errorMessage = getErrorMsg('create_channel_for_erpCustomer.default');
      }
    },

    [createCraftsmanToCraftsmanDirectChannel.pending]: (state) => {
      state.createCraftsmanToCraftsmanDirectChannel.status = ASYNC_STATE.LOADING;
    },
    [createCraftsmanToCraftsmanDirectChannel.fulfilled]: (state) => {
      state.createCraftsmanToCraftsmanDirectChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [createCraftsmanToCraftsmanDirectChannel.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.createCraftsmanToCraftsmanDirectChannel.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_craftsman_craftsman_channel.existing') {
        state.createCraftsmanToCraftsmanDirectChannel.errorMessage = getErrorMsg('create_craftsman_craftsman_channel.existing');
      } else {
        state.createCraftsmanToCraftsmanDirectChannel.errorMessage = getErrorMsg('create_craftsman_craftsman_channel.default');
      }
    },
    [createErpCustomerUser.pending]: (state) => {
      state.createUser.status = ASYNC_STATE.LOADING;
    },
    [createErpCustomerUser.fulfilled]: (state) => {
      state.createUser.status = ASYNC_STATE.SUCCEEDED;
    },
    [createErpCustomerUser.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.createUser.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'add_erp_customer_user.existing') {
        state.createUser.errorMessage = getErrorMsg('add_erp_customer_user.existing');
      } else {
        state.createUser.defaultErrorMessage = getErrorMsg('add_erp_customer_user.default');
      }
    },
    [deleteErpCustomerUser.pending]: (state) => {
      state.deleteErpCustomerUser.status = ASYNC_STATE.LOADING;
    },
    [deleteErpCustomerUser.fulfilled]: (state) => {
      state.deleteErpCustomerUser.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteErpCustomerUser.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.deleteErpCustomerUser.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode.split('.')[1] === 'execution_in_progress') {
        state.deleteErpCustomerUser.errorMessage = getErrorMsg('delete_erpCustomer_user.execution_in_progress');
      } else {
        state.deleteErpCustomerUser.errorMessage = getErrorMsg('default');
      }
    },
  },
});

export const {
  erpCustomerSelected,
  erpCustomerSelectionReset,
  filterByErpCustomerIdSelected,
  filterByErpCustomerIdReset,
  resetCreateErpCustomerErrorMessage,
  resetCreateErpCustomerDefaultErrorMessage,
  resetUpdateSelectedErpCustomerErrorMessage,
  resetUpdateSelectedErpCustomerDefaultErrorMessage,
  resetDeleteErpCustomerDefaultErrorMessage,
  resetCreateErpCustomerDirectChannelErrorMessage,
  resetCreateErpCustomerUserDefaultErrorMessage,
  resetCreateErpCustomerUserErrorMessage,
  resetErpCustomerDirectChannelsErrorMessage,
  resetDeleteErpCustomerUserErrorMessage,
} = erpCustomersSlice.actions;

export default erpCustomersSlice.reducer;
