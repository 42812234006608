import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { resetError } from 'gcs-common/slices/currentCraftsmanUser/currentCraftsmanUserSlice';
import { useDispatch } from 'react-redux';
import ModalWithCloseButton from '../../PopUpModal/ModalWithCloseButton/ModalWithCloseButton';
import CraftsmanConnectionsModalOverview from './CraftsmanConnectionsModalOverview/CraftsmanConnectionsModalOverview';
import CraftsmanConnectionModalEdit from './CraftsmanConnectionModalEdit/CraftsmanConnectionModalEdit';
import CraftsmanConnectionModalRemove from './CraftsmanConnectionModalRemove/CraftsmanConnectionModalRemove';
import CraftsmanConnectionModalAdd from './CraftsmanConnectionModalAdd/CraftsmanConnectionModalAdd';

export const GCOP_ACCOUNTS_MODAL_STATE = {
  OVERVIEW: 'overview',
  EDIT: 'edit',
  ADD: 'add',
  REMOVE: 'remove',
};

const CraftsmanConnectionsModal = ({ isOpen, onRequestClose }) => {

  const dispatch = useDispatch();

  const [state, setState] = useState(GCOP_ACCOUNTS_MODAL_STATE.OVERVIEW);
  const [connectionId, setConnectionId] = useState(null);

  const goToEdit = useCallback((id) => {
    setConnectionId(id);
    setState(GCOP_ACCOUNTS_MODAL_STATE.EDIT);
  }, []);

  const goToAdd = useCallback(() => {
    setState(GCOP_ACCOUNTS_MODAL_STATE.ADD);
  }, []);

  const goToRemove = useCallback((id) => {
    setConnectionId(id);
    setState(GCOP_ACCOUNTS_MODAL_STATE.REMOVE);
  }, []);

  const goToOverview = useCallback(() => {
    setState(GCOP_ACCOUNTS_MODAL_STATE.OVERVIEW);
  }, []);

  const closeModal = useCallback((e) => {
    setState(GCOP_ACCOUNTS_MODAL_STATE.OVERVIEW);
    dispatch(resetError());
    onRequestClose(e);
  }, [dispatch, onRequestClose]);

  const content = useMemo(() => {
    switch (state) {
      case GCOP_ACCOUNTS_MODAL_STATE.OVERVIEW:
        return (
          <CraftsmanConnectionsModalOverview
            goToEdit={goToEdit}
            goToAdd={goToAdd}
          />
        );
      case GCOP_ACCOUNTS_MODAL_STATE.EDIT:
        return <CraftsmanConnectionModalEdit goToRemove={goToRemove} connectionId={connectionId} />;
      case GCOP_ACCOUNTS_MODAL_STATE.ADD:
        return <CraftsmanConnectionModalAdd goToOverview={goToOverview} />;
      case GCOP_ACCOUNTS_MODAL_STATE.REMOVE:
        return (
          <CraftsmanConnectionModalRemove
            goToEdit={goToEdit}
            goToOverview={goToOverview}
            connectionId={connectionId}
          />
        );
      default:
        return <></>;
    }
  }, [state, goToEdit, goToAdd, goToRemove, connectionId, goToOverview]);

  const headline = useMemo(() => {
    switch (state) {
      case GCOP_ACCOUNTS_MODAL_STATE.OVERVIEW:
      case GCOP_ACCOUNTS_MODAL_STATE.EDIT:
      case GCOP_ACCOUNTS_MODAL_STATE.REMOVE:
        return 'Verknüpfungen bearbeiten';
      case GCOP_ACCOUNTS_MODAL_STATE.ADD:
        return 'Verknüpfungen hinzufügen';

      default:
        return '';
    }
  }, [state]);

  const goBack = useMemo(() => {
    switch (state) {
      case GCOP_ACCOUNTS_MODAL_STATE.EDIT:
      case GCOP_ACCOUNTS_MODAL_STATE.ADD:
        return goToOverview;
      case GCOP_ACCOUNTS_MODAL_STATE.REMOVE:
        return goToEdit;
      default:
        return undefined;
    }
  }, [goToEdit, goToOverview, state]);

  return (
    <ModalWithCloseButton
      label={headline}
      onRequestClose={closeModal}
      isOpen={isOpen}
      onGoBack={goBack}
    >
      {content}
    </ModalWithCloseButton>
  );
};

CraftsmanConnectionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default CraftsmanConnectionsModal;
