import { combineReducers, configureStore } from '@reduxjs/toolkit';
import currentUserSlice from './currentUser/currentUserSlice';
import favoritesSlice from './favorites/favoritesSlice';
import tasksSlice from './tasks/tasksSlice';
import processesSlice from './processes/processesSlice';
import currentAgentUserSlice from './currentAgent/currentAgentSlice';
import currentCraftsmanUserSlice from './currentCraftsmanUser/currentCraftsmanUserSlice';
import usersSlice from './users/usersSlice';
import channelsSlice from './channels/channelsSlice';
import newChannelOptionsSlice from './newChannelOptions/newChannelOptionsSlice';
import messagesSlice from './messages/messagesSlice';
import salesSpaceChannelsSlice from './salesSpaceChannels/salesSpaceChannelsSlice';
import chatConnectionSlice from './chatConnection/chatConnectionSlice';
import gccApiConnectionSlice from './gccApiConnection/gccApiConnectionSlice';
import uiStateSlice from './uiState/uiStateSlice';
import errorDialogSlice from './errorDialogSlice/errorDialogSlice';
import channelListSlice from './channelList/channelListSlice';
import erpCustomersSlice from './administration/erpCustomers/erpCustomersSlice';
import houseSlice from './administration/houses/housesSlice';
import globalSearchSlice from './administration/globalSearch/globalSearchSlice';
import teamsSlice from './administration/teams/teamsSlice';
import customerResponsibilityMembersSlice
  from './administration/customerResponsibilityMembers/customerResponsibilityMembersSlice';
import administrationChannelsSlice from './administration/administrationChannels/administrationChannelsSlice';
import administrationUsersSlice from './administration/administrationUsers/administrationUsersSlice';
import themeSlice from './theme/themeSlice';
import houseBranchesSlice from './administration/houseBranches/houseBranchesSlice';
import messageInputSlice from './messageInput/messageInputSlice';
import chatSlice from './chat/chatSlice';
import messageSearchSlice from './messageSearch/messageSearchSlice';
import fileListSlice from './fileList/fileListSlice';
import virtualScrollerSlice from './virtualScroller/virtualScrollerSlice';
import userHousesSlice from './houses/housesSlice';
import tenantsSlice from './administration/tenants/tenantsSlice';
import businessUnitsSlice from './administration/businessUnits/businessUnitsSlice';
import craftsmanConnectionsSlice from './craftsmanConnections/craftsmanConnectionsSlice';
import houseCommunicationStructuresSlice
  from './administration/houseCommunicationStructures/houseCommunicationStructureSlices';
import erpCustomerCommunicationAssignmentSlice
  from './administration/erpCustomerAssignments/erpCustomerAssignmentsSlices';
import videosSlice from './videos/videosSlice';
import fileToAppSharingSlice from './fileToAppSharing/fileToAppSharingSlice';
import membersSlice from './members/membersSlice';
import attPermissionSlice from './attPermission/attPermissionSlice';
import memberDetailsSlice from './memberDetails/memberDetailsSlice';
import ticketsSlice from './tickets/ticketsSlice';
import quickOrderingSlice from './quickOrdering/quickOrderingSlice';
import featureFlagsSlice from './administration/featureFlags/featureFlagsSlice';
import googleAnalyticsSlice from './googleAnalytics/googleAnalyticsSlice';
import initSlice from './init/initSlice';
import authSlice from './auth/authSlice';

export const commonReducers = {
  administrationChannels: administrationChannelsSlice,
  administrationUsers: administrationUsersSlice,
  attPermission: attPermissionSlice,
  auth: authSlice,
  businessUnits: businessUnitsSlice,
  channelList: channelListSlice,
  channels: channelsSlice,
  chat: chatSlice,
  chatConnection: chatConnectionSlice,
  craftsmanConnections: craftsmanConnectionsSlice,
  currentAgentUser: currentAgentUserSlice,
  currentCraftsmanUser: currentCraftsmanUserSlice,
  currentUser: currentUserSlice,
  customerResponsibilityMembers: customerResponsibilityMembersSlice,
  erpCustomerAssignments: erpCustomerCommunicationAssignmentSlice,
  erpCustomers: erpCustomersSlice,
  errorDialog: errorDialogSlice,
  favorites: favoritesSlice,
  featureFlags: featureFlagsSlice,
  fileList: fileListSlice,
  fileToAppSharing: fileToAppSharingSlice,
  gccApiConnection: gccApiConnectionSlice,
  globalSearch: globalSearchSlice,
  googleAnalytics: googleAnalyticsSlice,
  houseBranches: houseBranchesSlice,
  houseCommunicationStructures: houseCommunicationStructuresSlice,
  houses: houseSlice,
  init: initSlice,
  memberDetails: memberDetailsSlice,
  members: membersSlice,
  messageInput: messageInputSlice,
  messageSearch: messageSearchSlice,
  messages: messagesSlice,
  newChannelOptions: newChannelOptionsSlice,
  processes: processesSlice,
  quickOrdering: quickOrderingSlice,
  salesSpaceChannels: salesSpaceChannelsSlice,
  tasks: tasksSlice,
  teams: teamsSlice,
  tenants: tenantsSlice,
  theme: themeSlice,
  tickets: ticketsSlice,
  uiState: uiStateSlice,
  userHouses: userHousesSlice,
  users: usersSlice,
  videos: videosSlice,
  virtualScroller: virtualScrollerSlice,
};

// can be used for tests etc.
export const createStore = ({ preloadedState = {} } = {}) => configureStore({
  reducer: combineReducers(commonReducers),
  preloadedState,
});
