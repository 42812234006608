import { useDispatch, useSelector } from 'react-redux';
import {
  getAgentsTableAgentsLoading, getAgentsTableAgentsPaginationResult,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import { agentSelected } from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import AgentTable from '../AgentTable/AgentTable';

const agentTableView = 'agentTableView';

const AgentOverview = () => {

  const agentsLoading = useSelector(getAgentsTableAgentsLoading);
  const agentsPagination = useSelector(getAgentsTableAgentsPaginationResult);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAgentSelected = ({ id }) => {
    dispatch(agentSelected({ agentId: id }));
    navigate(`/administration/users/${id}`);
  };

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.inputHeaderText}>
          Mitarbeiter
          {' '}
          (
          {agentsPagination && agentsPagination.total}
          )
        </div>
        {agentsLoading && <LoadingIndicator padding="0" />}
      </div>
      <AgentTable
        onAgentSelected={onAgentSelected}
        view={agentTableView}
        agentsLoading={agentsLoading}
      />
    </>
  );
};

export default AgentOverview;
