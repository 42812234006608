import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsDeleting,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSelectors';
import {
  deleteErpCustomerAssignment, fetchErpCustomerAssignments,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsThunks';
import {
  getDeleteCommunicationAssignmentDefaultError,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsSelectors';
import {
  resetDeleteCustomerAssignmentDefaultErrorMessage,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsSlices';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const ErpCustomerAssignmentsDelete = ({
  erpCustomerId,
  communicationAssignmentId,
  onCancel,
  onSuccess,
}) => {

  const dispatch = useDispatch();
  const errorMessage = useSelector(getDeleteCommunicationAssignmentDefaultError);
  const isDeleting = useSelector(getIsDeleting);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetDeleteCustomerAssignmentDefaultErrorMessage()),
      ));
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteCustomerAssignmentDefaultErrorMessage());
  }, [dispatch]);

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteErpCustomerAssignment({
        assignmentId: communicationAssignmentId,
      }));
      if (!result.error) {
        onSuccess();
        dispatch(fetchErpCustomerAssignments({ erpCustomerId }));
      }
    },
    [communicationAssignmentId, dispatch, erpCustomerId, onSuccess],
  );

  return (
    <div className={styles.column}>
      Möchten Sie die Kommunikationszuweisung wirklich löschen?
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          disabled={isDeleting}
          onClick={handleDelete}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

ErpCustomerAssignmentsDelete.propTypes = {
  erpCustomerId: PropTypes.string.isRequired,
  communicationAssignmentId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ErpCustomerAssignmentsDelete;
