/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../../helper/errorMessages';
import ASYNC_STATE from '../../../constants/AsyncState';
import paginateReducer from '../../../helper/reduxPaginationHelper';
import { byId } from '../../../helper/byKey';
import {
  createTenant,
  deleteTenant,
  fetchTenantDetails,
  fetchTenants,
  fetchTenantSelectOptions,
  updateTenant,
} from './tenantsThunks';

const asyncState = {
  fetch: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchTenantDetails: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  delete: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  update: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  tenants: {},
  selectedTenantId: undefined,
  tenantSelectOptions: [],
};

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    tenantSelected: (state, action) => {
      const { tenantId } = action.payload;
      state.selectedTenantId = tenantId;
    },
    tenantSelectionReset: (state) => {
      state.selectedTenantId = undefined;
    },
    resetCreateTenantErrorMessage: (state) => {
      state.update.errorMessage = undefined;
    },
    resetUpdateTenantErrorMessage: (state) => {
      state.update.errorMessage = undefined;
    },
    resetDeleteTenantErrorMessage: (state) => {
      state.delete.errorMessage = undefined;
    },
  },
  extraReducers: {
    [fetchTenants.pending]: (state) => {
      state.fetch.status = ASYNC_STATE.LOADING;
    },
    [fetchTenants.fulfilled]: paginateReducer(
      (state) => state.fetch,
      (state, action) => {
        const { data } = action.payload;
        state.fetch.status = ASYNC_STATE.SUCCEEDED;
        state.tenants = { ...byId(data) };
      },
    ),
    [fetchTenants.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetch.status = ASYNC_STATE.FAILED;
      state.fetch.error = error;
      state.fetch.errorMessage = getErrorMsg('default');
    },
    [deleteTenant.pending]: (state) => {
      state.delete.status = ASYNC_STATE.LOADING;
    },
    [deleteTenant.fulfilled]: (state) => {
      state.delete.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteTenant.rejected]: (state, action) => {
      const { error } = action.error;
      state.delete.status = ASYNC_STATE.FAILED;
      state.delete.error = error;
      state.delete.errorMessage = getErrorMsg('default');
    },
    [createTenant.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
    },
    [createTenant.fulfilled]: (state) => {
      state.create.status = ASYNC_STATE.SUCCEEDED;
    },
    [createTenant.rejected]: (state, action) => {
      const { error } = action.error;
      state.create.status = ASYNC_STATE.FAILED;
      state.create.error = error;
      state.create.errorMessage = getErrorMsg('default');
    },
    [updateTenant.pending]: (state) => {
      state.update.status = ASYNC_STATE.LOADING;
    },
    [updateTenant.fulfilled]: (state) => {
      state.update.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateTenant.rejected]: (state, action) => {
      const { error } = action.error;
      state.update.status = ASYNC_STATE.FAILED;
      state.update.error = error;
      state.update.errorMessage = getErrorMsg('default');
    },
    [fetchTenantDetails.pending]: (state) => {
      state.fetchTenantDetails.status = ASYNC_STATE.LOADING;
    },
    [fetchTenantDetails.fulfilled]: (state, action) => {
      const { tenant } = action.payload;
      const { id } = tenant;
      state.fetchTenantDetails.status = ASYNC_STATE.SUCCEEDED;
      state.selectedTenantId = id;
      state.tenants[id] = tenant;
    },
    [fetchTenantDetails.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchTenantDetails.status = ASYNC_STATE.FAILED;
      state.fetchTenantDetails.error = error;
      state.fetchTenantDetails.errorMessage = getErrorMsg('default');
    },
    [fetchTenantSelectOptions.fulfilled]: (state, action) => {
      state.tenantSelectOptions = action.payload;
    },
  },
});

export const {
  tenantSelected,
  tenantSelectionReset,
  resetCreateTenantErrorMessage,
  resetUpdateTenantErrorMessage,
  resetDeleteTenantErrorMessage,
} = tenantsSlice.actions;

export default tenantsSlice.reducer;
