/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../../helper/errorMessages';
import {
  fetchSelectedAdministrationChannel, updateSelectedChannelName, removeUserFromGroupChannel,
  deleteGroupChannel, deleteDirectChannel, addUserToGroupChannel,
} from './administrationChannelsThunks';
import ASYNC_STATE from '../../../constants/AsyncState';

const asyncState = {
  fetchSelected: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  updateSelected: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
    errorMessage: null,
  },
  removeUserFromGroupChannel: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  deleteGroupChannel: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  deleteDirectChannel: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  addUserToGroupChannel: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  channels: {},
  selectedChannelId: undefined,
};

const administrationChannelsSlice = createSlice({
  name: 'administrationChannels',
  initialState,
  reducers: {
    resetUpdatedGroupChannelNameErrorMessage: (state) => {
      state.updateSelected.errorMessage = null;
    },
    resetUpdatedGroupChannelNameDefaultErrorMessage: (state) => {
      state.updateSelected.defaultErrorMessage = null;
    },
    resetRemovingUserFromSelectedGroupChannelDefaultErrorMessage: (state) => {
      state.removeUserFromGroupChannel.defaultErrorMessage = null;
    },
    resetDeleteGroupChannelDefaultErrorMessage: (state) => {
      state.deleteGroupChannel.defaultErrorMessage = null;
    },
    resetDeleteDirectChannelDefaultErrorMessage: (state) => {
      state.deleteDirectChannel.defaultErrorMessage = null;
    },
    resetAddUserToGroupChannelDefaultErrorMessage: (state) => {
      state.addUserToGroupChannel.defaultErrorMessage = null;
    },
  },
  extraReducers: {
    [fetchSelectedAdministrationChannel.pending]: (state) => {
      state.fetchSelected.status = ASYNC_STATE.LOADING;
    },
    [fetchSelectedAdministrationChannel.fulfilled]: (state, action) => {
      const { channel } = action.payload;
      const { id } = channel;
      state.fetchSelected.status = ASYNC_STATE.SUCCEEDED;
      state.selectedChannelId = id;
      state.channels[id] = channel;
    },
    [fetchSelectedAdministrationChannel.rejected]: (state, action) => {
      state.fetchSelected.status = ASYNC_STATE.FAILED;
      state.fetchSelected.error = action.error;
    },
    [updateSelectedChannelName.pending]: (state) => {
      state.updateSelected.status = ASYNC_STATE.LOADING;
    },
    [updateSelectedChannelName.fulfilled]: (state) => {
      state.updateSelected.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateSelectedChannelName.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.updateSelected.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'update_group_channel_name.channel_exists') {
        state.updateSelected.errorMessage = getErrorMsg('update_group_channel_name.channel_exists');
      } else {
        state.updateSelected.defaultErrorMessage = getErrorMsg('update_group_channel_name.default');
      }
    },
    [removeUserFromGroupChannel.pending]: (state) => {
      state.removeUserFromGroupChannel.status = ASYNC_STATE.LOADING;
    },
    [removeUserFromGroupChannel.fulfilled]: (state) => {
      state.removeUserFromGroupChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [removeUserFromGroupChannel.rejected]: (state) => {
      state.removeUserFromGroupChannel.status = ASYNC_STATE.FAILED;
      state.updateSelected.defaultErrorMessage = getErrorMsg('delete_user_from_group.default');
    },
    [deleteGroupChannel.pending]: (state) => {
      state.deleteGroupChannel.status = ASYNC_STATE.LOADING;
    },
    [deleteGroupChannel.fulfilled]: (state) => {
      state.deleteGroupChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteGroupChannel.rejected]: (state) => {
      state.deleteGroupChannel.status = ASYNC_STATE.FAILED;
      state.deleteGroupChannel.defaultErrorMessage = getErrorMsg('delete_channel.default');
    },
    [deleteDirectChannel.pending]: (state) => {
      state.deleteDirectChannel.status = ASYNC_STATE.LOADING;
    },
    [deleteDirectChannel.fulfilled]: (state) => {
      state.deleteDirectChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteDirectChannel.rejected]: (state) => {
      state.deleteDirectChannel.status = ASYNC_STATE.FAILED;
      state.deleteDirectChannel.defaultErrorMessage = getErrorMsg('delete_channel.default');
    },
    [addUserToGroupChannel.pending]: (state) => {
      state.addUserToGroupChannel.status = ASYNC_STATE.LOADING;
    },
    [addUserToGroupChannel.fulfilled]: (state) => {
      state.addUserToGroupChannel.status = ASYNC_STATE.SUCCEEDED;
    },
    [addUserToGroupChannel.rejected]: (state) => {
      state.addUserToGroupChannel.status = ASYNC_STATE.FAILED;
      state.addUserToGroupChannel.defaultErrorMessage = getErrorMsg('add_user_to_group_channel.default');
    },
  },
});

export const {
  resetUpdatedGroupChannelNameErrorMessage,
  resetUpdatedGroupChannelNameDefaultErrorMessage,
  resetRemovingUserFromSelectedGroupChannelDefaultErrorMessage,
  resetDeleteGroupChannelDefaultErrorMessage,
  resetDeleteDirectChannelDefaultErrorMessage,
  resetAddUserToGroupChannelDefaultErrorMessage,
} = administrationChannelsSlice.actions;

export default administrationChannelsSlice.reducer;
