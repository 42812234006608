import { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getDateTagForMessage } from 'gcs-common/slices/messages/messageSelector';
import styles from './styles.module.scss';

const MessageDateTag = ({ messageId }) => {
  const dateTagDate = useSelector(getDateTagForMessage(messageId));
  const displayDate = useMemo(() => {
    if (!dateTagDate) {
      return null;
    }
    return dateTagDate.isSame(moment(), 'day') ? 'Heute' : dateTagDate.format('L');
  }, [dateTagDate]);

  if (!dateTagDate) {
    return null;
  }

  return (
    <div className={styles.msg}>
      <div className={styles.dateTag}>
        {displayDate}
      </div>
    </div>
  );
};

export default MessageDateTag;

MessageDateTag.propTypes = {
  messageId: PropTypes.string.isRequired,
};
