import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputStagedFiles,
  getChannelInputStagedForwardMessageIds,
  getChannelInputQuotedMessage,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { channelInputCleared } from 'gcs-common/slices/messageInput/messageInputSlice';
import MessageInputField from './MessageInputField/MessageInputField';
import styles from './styles.module.scss';
import SmileyMessageInput from './SmileyMessageInput/SmileyMessageInput';
import MessageInputFileButton from './MessageInputFileButton/MessageInputFileButton';
import MessageSuggestions from './MessageSuggestions/MessageSuggestions';
import StageSelectedItem from './StageSelectedItem/StageSelectedItem';
import ForwardChannelList from '../ForwardChannelList/ForwardChannelList';
import QuotedMessage from '../QuotedMessage/QuotedMessage';
import SendButton from './SendButton/SendButton';
import FilePreviewer from '../FilePreviewer/FilePreviewer';

const MessageInputArea = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const files = useSelector(getChannelInputStagedFiles(selectedChannelId));
  const quotedMessageId = useSelector(getChannelInputQuotedMessage(selectedChannelId));
  const dispatch = useDispatch();

  const resetQuotedMessage = useCallback(() => {
    dispatch(channelInputCleared({ channelId: selectedChannelId }));

  }, [dispatch, selectedChannelId]);

  const stagedForwardMessageIds = useSelector(
    getChannelInputStagedForwardMessageIds(selectedChannelId),
  );

  return (
    <>
      {stagedForwardMessageIds.length !== 0 && <ForwardChannelList />}
      {files.length !== 0
        && <FilePreviewer multiple hoverBoundaryClass={styles.writeField} />
      }
      <div className={styles.writeField}>
        <div className={styles.writeInputWrapper}>
          {quotedMessageId && (
            <div className={styles.replyWrapper}>
              <QuotedMessage
                onCancel={resetQuotedMessage}
                messageId={quotedMessageId}
              />
            </div>
          )}
          {stagedForwardMessageIds.length > 1 && (
            <div className={styles.text}>
              {stagedForwardMessageIds.length}
              {' '}
              Nachrichten ausgewählt
            </div>
          )}
          <StageSelectedItem />
          <MessageSuggestions />
          <div className={styles.inputElementWrapper}>
            <MessageInputFileButton />
            <div className={styles.inputElements}>
              <SmileyMessageInput />
              <MessageInputField />
            </div>
            <SendButton />
          </div>
        </div>
      </div>
    </>
  );
};
export default MessageInputArea;
