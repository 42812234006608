/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ChannelOrder } from './channelSorter';
import { ChannelBrand } from './channelFilter';
import { CHANNEL_FILTER_TYPES } from '../../helper/channelTypeHelper';
import { UnreadMessagesFilter } from '../../helper/channelOrderingHelper';


const initialState = {
  searchInput: '',
  forwardSearchInput: '',
  selectedChannelOrder: ChannelOrder.DATE,
  selectedChannelBrand: ChannelBrand().ALLE,
  selectedChannelTypeFilter: CHANNEL_FILTER_TYPES.DIRECT_CHATS,
  unreadMessagesFilterSelected: UnreadMessagesFilter.ALL,
};

const channelListSlice = createSlice({
  name: 'channelList',
  initialState,
  reducers: {
    setSearchInput: (state, action) => {
      const { searchInput } = action.payload;
      state.searchInput = searchInput;
    },
    setForwardSearchInput: (state, action) => {
      const { forwardSearchInput } = action.payload;
      state.forwardSearchInput = forwardSearchInput;
    },
    setSelectedChannelOrder: (state, action) => {
      const { selectedChannelOrder } = action.payload;
      state.selectedChannelOrder = selectedChannelOrder;
    },
    setSelectedChannelBrand: (state, action) => {
      const { selectedChannelBrand } = action.payload;
      state.selectedChannelBrand = selectedChannelBrand;
    },
    setSelectedChannelTypeFilter: (state, action) => {
      const { selectedChannelTypeFilter } = action.payload;
      state.selectedChannelTypeFilter = selectedChannelTypeFilter;
    },
    setUnreadMessagesFilterSelected: (state, action) => {
      const { unreadMessagesFilterSelected } = action.payload;
      state.unreadMessagesFilterSelected = unreadMessagesFilterSelected;
    },
  },
});

export const {
  setSearchInput,
  setForwardSearchInput,
  setSelectedChannelOrder,
  setSelectedChannelFilterTypes,
  setSelectedChannelBrand,
  setSelectedChannelTypeFilter,
  setUnreadMessagesFilterSelected,
} = channelListSlice.actions;

export default channelListSlice.reducer;
