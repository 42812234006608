import { UserTypeInfo } from 'gcs-common/helper/userTypeHelper';
import ProfileImageCell from '../../../Table/ProfileImageCell/ProfileImageCell';
import LozengeCell from '../../../Table/LozengeCell/LozengeCell';
import { stringSortingFunction } from '../../../../helper/sortHelper';

export const userColumns = [
  {
    Header: 'Typ',
    accessor: 'type',
    Cell: (cell) => cell.value && LozengeCell({
      value: UserTypeInfo[cell.value] ? UserTypeInfo[cell.value].name : cell.value,
      color: UserTypeInfo[cell.value] ? UserTypeInfo[cell.value].color : '#827f7f',
    }),
  },
  {
    Header: 'Profilbild',
    accessor: 'imageUrl',
    Cell: ProfileImageCell,
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    sortType: stringSortingFunction,
  },
];
