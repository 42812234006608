/* eslint-disable jsx-a11y/label-has-for */

import PropTypes from 'prop-types';
import { Field } from 'formik';
import styles from './styles.module.scss';
import ValidationError from '../ValidationError/ValidationError';
import InfoTooltip from '../../InfoTooltip/InfoTooltip';
// TODO: Once styling is done use this component instead of FormikInput.

const TextBox = ({
  textClassName,
  errorClassName,
  label = '',
  disabled = false,
  name = '',
  type = 'text',
  placeholder = '',
  disableHoverOver = false,
  children,
  tooltipText = null,
  isRequired = false,
  ...props
}) => {

  return (
    <div className={styles.formikInputContainer}>
      {label && (
      <label className={styles.formikInputLabel} htmlFor={name}>
        {label}
        {isRequired && !disabled && (
          <span className={styles.requiredStar}>*</span>
        )}
        {tooltipText && (
          <InfoTooltip
            text={tooltipText}
          />
        )}
      </label>
      )}
      <div className={`${styles.formikInputWrapper} ${disabled || disableHoverOver ? styles.disabled : ''}`}>
        {children}
        <Field
          id={name}
          className={textClassName || styles.formikInput}
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={disabled}
          {...props}
        />
      </div>
      <div className={errorClassName || styles.emptyErrorContainer}>
        <ValidationError name={name} />
      </div>
    </div>
  );
};

TextBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  textClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  disabled: PropTypes.bool,
  disableHoverOver: PropTypes.bool,
  tooltipText: PropTypes.string,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
};

export default TextBox;
