import PropTypes from 'prop-types';
import {
  CommunicationAssignmentDirection,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructuresConstants';
import Radio from 'gcs-common/components/Formik/Radio/Radio';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import { ICON } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';

const permissionsOptions = [
  {
    text: 'Mitarbeiter und Kunden können Chat erstellen',
    value: CommunicationAssignmentDirection.BIDIRECTIONAL,
    icon: ICON.CYCLE_ARROWS,
  }, {
    text: 'Nur Mitarbeiter kann Chat erstellen',
    value: CommunicationAssignmentDirection.FROM_AGENT,
    icon: ICON.ARROW_LONG,
  },
];

const AssignmentDirectionSelect = ({ disabled = false, name }) => {

  return (
    <div className={styles.assignmentDirection}>
      <span className={`${styles.assignmentDirTitle} ${disabled && styles.disabled}`}>
        Berechtigungen
        {!disabled && <span className={styles.requiredStar}>*</span>}
      </span>
      {permissionsOptions.map((permision, key) => (
        <Radio
          {...permision}
          id={`${key}-direction`}
          key={permision.value}
          name={name}
          disabled={disabled}
        />
      ))}
      <div className={styles.validationErrorWrapper}>
        <ValidationError name={name} />
      </div>
    </div>
  );
};

AssignmentDirectionSelect.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default AssignmentDirectionSelect;
