import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentFriendlyName,
  getIsAgent,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getSidebarCollapsed } from 'gcs-common/slices/uiState/uiStateSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { ICON } from 'gcs-common/constants/IconConstants';
import { getFavicon } from 'gcs-common/slices/theme/themeSelectors';
import styles from './styles.module.scss';
import CurrentUserImage from '../CurrentUserImage/CurrentUserImage';
import IconComponent from '../IconComponent/IconComponent';
import openHelp from '../../slices/settings/settingsThunks/openHelp';
import AnimatedSidebarButton from '../AnimatedSidebarButton/AnimatedSidebarButton';
import FeatureFlagProtectedRoute from '../ProtectedRoutes/FeatureFlagProtectedRoute';

const NavBar = () => {

  const isAgent = useSelector(getIsAgent);
  const friendlyName = useSelector(getCurrentFriendlyName);
  const sidebarCollapsed = useSelector(getSidebarCollapsed);
  const logo = useSelector(getFavicon);
  const isOnline = useSelector(getIsOnline);

  const dispatch = useDispatch();

  const onOpenHelp = useCallback(() => {
    dispatch(openHelp());
  }, [dispatch]);

  const body = useMemo(() => {
    if (sidebarCollapsed) {
      return (
        <CurrentUserImage isAgent={isAgent} />
      );
    }
    return (
      <>
        {isAgent && <img src={logo} className={styles.agentLogoIcon} alt="Firmenlogo" />}
        <CurrentUserImage isAgent={isAgent} />
        <div className={isAgent ? styles.userDetails : styles.userCraftsmanDetails}>
          <div data-testid="myusername">{friendlyName}</div>
        </div>
        {isAgent && (
          <div className={styles.wrapper}>
            <FeatureFlagProtectedRoute disabledFlag={FEATURE_FLAGS.SUPPORT_PAGE_DISABLED}>
              <div>
                <AnimatedSidebarButton
                  className={styles.settingsButton}
                  onClick={onOpenHelp}
                  disabled={!isOnline}
                  tooltip={isOnline ? 'Hilfe' : 'Bitte online gehen um diese Funktion zu nutzen'}
                >
                  <IconComponent Icon={ICON.HELP} alt="feedback" />
                </AnimatedSidebarButton>
              </div>
            </FeatureFlagProtectedRoute>
          </div>
        )}
      </>
    );
  }, [sidebarCollapsed, isAgent, logo, friendlyName, onOpenHelp, isOnline]);

  return (
    <div className={isAgent ? styles.userDetailsContainer : styles.craftsmanDetailsContainer}>
      {body}
    </div>
  );
};

NavBar.propTypes = {};

export default NavBar;
