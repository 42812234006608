import ErpCustomerDropDown from '../../../../ErpCustomerDropDown/ErpCustomerDropDown';
import styles from './styles.module.scss';
// eslint-disable-next-line import/no-cycle
import { FiltersValues } from '../../TicketDashboard';

interface ErpCustomerFilterProps {
  setErpCustomerFilter:(filter: Partial<FiltersValues>) => void
}

const ErpCustomerFilter = (props: ErpCustomerFilterProps) => {
  const { setErpCustomerFilter } = props;
  const onSearchErpCustomer = (erpCustomerIds: string | string [] | null) => {
    if (Array.isArray(erpCustomerIds)) setErpCustomerFilter({ erpCustomerIds });
  };

  return (
    <div className={styles.erpSelectContainer}>
      <ErpCustomerDropDown onChange={onSearchErpCustomer} isClearable isMulti />
    </div>
  );
};


export default ErpCustomerFilter;
