import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChannelOldestLoadedMessageIndex, getMessageIdsForChannel } from '../messages/messagesSelectors';
import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import includesSubstring from '../../helper/includesSubstring';
import { getMessage } from '../messages/messageSelector';
import { getMessageSearchIsLoading } from './messageSearchSelectors';
import { getChannelHasMoreMessages } from '../channel/channelSelectors';
import { getIsChatConnected } from '../chatConnection/chatConnectionSelectors';
import fetchMessages from '../messages/messagesThunks/fetchMessages';

export const searchChannelMessages = createAsyncThunk(
  'messageSearch/searchChannelMessages',
  async ({ channelId, searchString }, { dispatch, getState }) => {
    const oldestMessageIndex = getChannelOldestLoadedMessageIndex(channelId)(getState());
    const channelHasMoreMessages = getChannelHasMoreMessages(channelId)(getState());
    const isChatConnected = getIsChatConnected(getState());

    if (channelHasMoreMessages && isChatConnected) {
      await dispatch(fetchMessages({
        channelId,
        pageSize: 98, // TODO: SMOOT-3273
        fromMessageIndex: oldestMessageIndex,
        singlePage: true,
      }));
    }

    const messageIds = getMessageIdsForChannel(channelId)(getState());
    const resultMessageIds = messageIds.filter(messageId => {
      const message = getMessage(messageId)(getState());
      const { messageType, deleted } = message;
      if (deleted || messageType === MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION) {
        return false;
      }
      if (
        messageType === MESSAGE_TYPE.MEDIA
        || messageType === MESSAGE_TYPE.MEDIA_FROM_MAIL
      ) {
        const {
          text,
          filename: attributesFilename,
        } = message;
        return (text && includesSubstring(text, searchString))
          || (attributesFilename && includesSubstring(attributesFilename, searchString));
      }
      const { text } = message;
      return text && includesSubstring(text, searchString);

    });
    return { resultMessageIds };
  },
  {
    condition: (_, { getState }) => {
      return !getMessageSearchIsLoading(getState());
    },
  },
);
