import { createSelector } from 'reselect';
import { getSelectedHouseId as currentUserSelectedHouseId } from '../../houses/housesSelectors';
import {
  toPaginationPaginationResult,
} from '../../../helper/reduxPaginationHelper';
import ASYNC_STATE from '../../../constants/AsyncState';
import { toIsLoading } from '../../../helper/reduxLoadingStatusHelper';

export const getAllHousesPlain = (state) => state.houses.housesPlain;

export const getAllHousesPlainList = (state) => Object.values(state.houses.housesPlain);

export const getHouseTable = (state) => Object.values(state.houses.housesTable);

const getAllHousesDetails = (state) => state.houses.housesDetails;

const getHousesState = state => (
  state.houses.fetchHouses || {}
);

export const getHousesLoading = createSelector(
  [getHousesState],
  toIsLoading,
);

export const getHousesPaginationResult = createSelector(
  [getHousesState],
  toPaginationPaginationResult,
);

export const getHouseLoading = (state) => state.houses.fetchHouse.status === ASYNC_STATE.LOADING;
export const getHouseLoadingError = (state) => (
  state.houses.fetchHouse.status === ASYNC_STATE.FAILED
);

export const getGloballySelectedHouseId = (state) => state.houses.globallySelectedHouseId;

export const getGloballySelectedHouseDetails = createSelector(
  [getGloballySelectedHouseId, getAllHousesDetails],
  (globallySelectedHouseId, allHouses) => allHouses[globallySelectedHouseId],
);


export const getSelectedHouseId = (state) => state.houses.selectedHouseId;

export const getSelectedHouseDetails = createSelector(
  [getSelectedHouseId, getAllHousesDetails],
  (houseId, allHouses) => allHouses[houseId],
);

export const getSelectedHouseCustomMessages = createSelector(
  [getSelectedHouseId, getAllHousesDetails],
  (houseId, allHouses) => allHouses[houseId] && allHouses[houseId].customMessages,
);

export const getCustomMessageTypes = (state) => state.houses.customMessageTypes;

export const getExampleCustomMessage = (state) => state.houses.exampleCustomMessage;

export const getBrands = (state) => state.houses.brands;


export const getActiveHouseId = createSelector(
  [getGloballySelectedHouseId, currentUserSelectedHouseId],
  (globallySelectedHouseId, houseId) => {
    if (globallySelectedHouseId) {
      return globallySelectedHouseId;
    }
    return houseId;
  },
);

export const getActiveHouse = createSelector(
  [getActiveHouseId, getAllHousesPlain, getAllHousesDetails],
  (activeHouseId, housesPlain, housesDetails) => {
    // only returns name and id from either housesPlain or houseDetails
    return housesPlain[activeHouseId] || housesDetails[activeHouseId];
  },
);

export const getSelectedHouseLdaNumbers = createSelector(
  getActiveHouseId,
  getAllHousesDetails,
  (houseId, houses) => houses[houseId]?.ldaNumbers || [],
);

export const getSelectedHouseLoading = (state) => (
  state.houses.fetchSelectedHouse.status === ASYNC_STATE.LOADING);

export const getIsFetchSelectedHouseSucceeded = (state) => (
  state.houses.fetchSelectedHouse.status === ASYNC_STATE.SUCCEEDED);

export const getIsValidatingHouseCustomMessage = (
  state,
) => state.houses.validateCustomMessage.status === ASYNC_STATE.LOADING;

export const getValidateHouseCustomMessageErrorMessage = (
  state,
) => state.houses.validateCustomMessage.errorMessage;

export const getCreateHouseErrorMessage = (
  state,
) => state.houses.create.errorMessage;
