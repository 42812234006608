import { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAgent, getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getMediaFullscreenUrl, getUploadFileError, getVideoFullscreenId } from 'gcs-common/slices/uiState/uiStateSelectors';
import { setUploadFileError } from 'gcs-common/slices/uiState/uiStateSlice';
import { getInitialChannelsLoading } from 'gcs-common/slices/channels/channelsSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import styles from './styles.module.scss';
import IdleTimeManager from '../IdleTimeManager/IdleTimeManager';
import LeftBar from '../LeftBar/LeftBar';
import RightBar from '../RightBar/RightBar';
import MediaFullscreen from '../MediaFullscreen/MediaFullscreen';
import ErrorPopUp from '../ErrorPopUp/ErrorPopUp';
import VideoFullscreen from '../VideoFullscreen/VideoFullScreen';
import OpMaintenanceBanner from '../OpMaintenanceBanner/OpMaintenanceBanner';

/*
 This is the main workspace for agents/ craftsmen with channel list, main chat window and tool bar
 */

const ChatWorkspace = () => {
  const mediaFullscreenUrl = useSelector(getMediaFullscreenUrl);
  const videoFullscreenId = useSelector(getVideoFullscreenId);
  const isAgent = useSelector(getIsAgent);
  const uploadFileError = useSelector(getUploadFileError);
  const dispatch = useDispatch();
  const initialChannelsLoadIng = useSelector(getInitialChannelsLoading);
  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));

  const onCloseErrorPopop = useCallback(() => {
    dispatch(setUploadFileError({ uploadFileError: '' }));
  }, [dispatch]);

  if (initialChannelsLoadIng) {
    return (
      <LoadingIndicatorFullScreen loadingText="Lädt Channel..." />
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.agentRoot}>
        <ErrorPopUp title="daten-fehler" errorMessage={uploadFileError} onClose={onCloseErrorPopop} />
        {isAgent && <IdleTimeManager />}
        {isOpMaintenanceEnabled && <OpMaintenanceBanner />}
        <LeftBar />
        {/* TODO WP2 move this stuff in one component */}
        <div className={styles.mainChatArea}>
          <div className={styles.chatCol}>
            <Outlet />
          </div>
          {/*  /!* TODO WP3: move this stuff in one component *!/ */}
          <div className={styles.rightBarCol}>
            <RightBar />
          </div>
        </div>
      </div>
      {mediaFullscreenUrl
      && <MediaFullscreen mediaFullscreenUrl={mediaFullscreenUrl} />}
      {videoFullscreenId
      && <VideoFullscreen videoId={videoFullscreenId} />}
    </DndProvider>
  );
};

export default ChatWorkspace;
