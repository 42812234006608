import { useDispatch, useSelector } from 'react-redux';
import { businessUnitSelected } from 'gcs-common/slices/administration/businessUnits/businessUnitsSlice';
import {
  getBusinessUnitsLoading,
  getBusinessUnitsPaginationResult,
} from 'gcs-common/slices/administration/businessUnits/businessUnitsSelectors';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import BusinessUnitTable from '../BusinessUnitTable/BusinessUnitTable';

const BusinessUnitOverview = () => {

  const businessUnitsLoading = useSelector(getBusinessUnitsLoading);
  const businessUnitsPagination = useSelector(getBusinessUnitsPaginationResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBusinessUnitSelected = (businessUnit) => {
    const { id } = businessUnit;
    dispatch(businessUnitSelected({ businessUnitId: id }));
    navigate(`/administration/business_units/${id}`);
  };

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.inputHeaderText}>
          BusinessUnits
          {' '}
          (
          {businessUnitsPagination && businessUnitsPagination.total}
          )
        </div>
        {businessUnitsLoading && <LoadingIndicator padding="0" />}
      </div>
      <BusinessUnitTable
        onBusinessUnitSelected={onBusinessUnitSelected}
        businessUnitsLoading={businessUnitsLoading}
      />
    </>
  );
};

export default BusinessUnitOverview;
