import {
  useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import { MESSAGE_TYPE } from 'gcs-common/constants/MessageTypes';
import {
  getMessageAuthorName,
  getMessageBody, getMessageDateCreated, getMessageIndex,
  getMessageIsFromMe,
  getMessageType,
} from 'gcs-common/slices/messages/messageSelector';
import { getMessageSearchInput } from 'gcs-common/slices/messageSearch/messageSearchSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import scrollToMessageId from 'gcs-common/slices/chat/chatThunks/scrollToMessageId';
import MediaMessage from '../MediaMessage/MediaMessage';
import styles from './styles.module.scss';

export function SearchResultMessage({ messageId }) {

  const fromMe = useSelector(getMessageIsFromMe(messageId));
  const messageType = useSelector(getMessageType(messageId));
  const body = useSelector(getMessageBody(messageId));
  const searchInput = useSelector(getMessageSearchInput);
  const dateCreated = useSelector(getMessageDateCreated(messageId));
  const selectedChannelId = useSelector(getSelectedChannelId);
  const authorName = useSelector(getMessageAuthorName(messageId, selectedChannelId));
  const messageIndex = useSelector(getMessageIndex(messageId));
  const dispatch = useDispatch();

  const humanDateCreated = useMemo(() => {
    return dateCreated && dateCreated.format('LLL');
  }, [dateCreated]);

  const onScrollToMessage = useCallback(() => {
    dispatch(scrollToMessageId({ channelId: selectedChannelId, messageId, messageIndex }));
  }, [dispatch, messageIndex, messageId, selectedChannelId]);

  let content = null;
  if (
    messageType === MESSAGE_TYPE.MEDIA
    || messageType === MESSAGE_TYPE.MEDIA_FROM_MAIL
  ) {

    content = (
      <MediaMessage
        maxHeight={120}
        messageId={messageId}
        CustomTextComponent={({ text }) => {
          return (
            <Highlighter
              textToHighlight={text}
              searchWords={[searchInput]}
              autoEscape
              highlightClassName={styles.searchResultHighlight}
            />
          );
        }}
      />
    );
  } else {
    content = (
      <Highlighter
        textToHighlight={body}
        searchWords={[searchInput]}
        autoEscape
        highlightClassName={styles.searchResultHighlight}
      />
    );
  }

  return (
    <div
      className={`${styles.searchResultMessageWrapper} ${fromMe ? styles.me : ''}`}
    >
      <button
        type="button"
        className={styles.searchResultMessageContent}
        onClick={onScrollToMessage}
      >
        <div className={styles.userName}>{authorName}</div>
        <div className={styles.content}>{content}</div>
        <div className={styles.messageDate}>{humanDateCreated}</div>
      </button>
    </div>
  );
}

SearchResultMessage.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default SearchResultMessage;
