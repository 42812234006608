import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getIsGroupChat,
  getNewChannelGroupSectionTitle,
  getNewChannelOptionIdsForSection,
} from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';

import NewChannelItem from '../NewChannelItem/NewChannelItem';
import styles from './styles.module.scss';

const NewChannelGroup = ({ sectionName }) => {
  const isGroupChat = useSelector(getIsGroupChat);
  const channelGroupName = useSelector(getNewChannelGroupSectionTitle(sectionName));
  const newChannelOptionIds = useSelector(getNewChannelOptionIdsForSection(sectionName));
  const isAgent = useSelector(getIsAgent);

  if (newChannelOptionIds.length === 0) {
    return null;
  }

  return (
    <div className={styles.channelGroup}>
      <div className={styles.channelGroupHeader}>
        <span>{channelGroupName}</span>
      </div>
      <div className={`${styles.channelsWrapper} ${isAgent ? styles.agentStyle : ''}`.trim()}>
        {newChannelOptionIds.map((newChannelOptionId) => (
          <NewChannelItem
            newChannelOptionId={newChannelOptionId}
            key={newChannelOptionId}
            multiSelect={isGroupChat}
          />
        ))}
      </div>
    </div>
  );
};

NewChannelGroup.propTypes = {
  sectionName: PropTypes.string.isRequired,
};

export default NewChannelGroup;
