import { captureMessage } from '@sentry/react';
import { debugLogger } from '../helper/debugLogger';
import { addFavoriteOffline } from '../slices/favorites/favoritesActions/addFavoriteOffline';
import { removeFavoriteOffline } from '../slices/favorites/favoritesActions/removeFavoriteOffline';
import { sendMessagesOffline } from '../slices/messageInput/messageInputActions/sendMessagesOffline';
import { deleteMessageOffline } from '../slices/messageInput/messageInputActions/deleteMessageOffline';
import sendMessageEffect from './offlineEffects/sendMessageEffect';
import addFavoriteEffect from './offlineEffects/addFavoriteEffect';
import removeFavoriteEffect from './offlineEffects/removeFavoriteEffect';
import deleteMessageEffect from './offlineEffects/deleteMessageEffect';

/**
 * Handles the 'effect' of an redux-offline action
 */
const offlineEffect = ({
  gccApiClient,
  chatClient,
  blobStorageClient,
  videoClient,
  getStore,
}) => async (effect, action) => {

  debugLogger('Running effect for action', action);

  switch (action.type) {
    case addFavoriteOffline.type: {
      return addFavoriteEffect(effect, action, { gccApiClient });
    }
    case removeFavoriteOffline.type: {
      return removeFavoriteEffect(effect, action, { gccApiClient });
    }
    case sendMessagesOffline.type: {
      const dispatch = getStore()?.dispatch;
      if (!dispatch) {
        captureMessage('Fatal! Dispatch is not available', { level: 'error' });
      }
      return sendMessageEffect(effect, action, {
        gccApiClient,
        chatClient,
        blobStorageClient,
        videoClient,
        dispatch: getStore().dispatch,
      });
    }
    case deleteMessageOffline.type: {
      return deleteMessageEffect(effect, action, { gccApiClient });
    }
    default: {
      return new Error(`Could not find effect matching actionType='${action.type}'`);
    }
  }
};

export default offlineEffect;
