/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  gccApiClientInit: false,
};

const gccApiConnectionSlice = createSlice({
  name: 'gccApiConnection',
  initialState,
  reducers: {
    initGccApiSuccess: (state) => {
      state.gccApiClientInit = true;
    },
  },
});

export const {
  initGccApiSuccess,
} = gccApiConnectionSlice.actions;

export default gccApiConnectionSlice.reducer;
