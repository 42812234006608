import { createSelector } from '@reduxjs/toolkit';
import {
  currentPaginationEntities,
  toPaginationPaginationResult,
} from '../../../helper/reduxPaginationHelper';
import ASYNC_STATE from '../../../constants/AsyncState';
import { toIsLoading } from '../../../helper/reduxLoadingStatusHelper';

export const getAllTeams = (state) => state.teams.teams;
export const getSelectedTeamId = (state) => state.teams.selectedTeamId;

export const getSelectedTeam = createSelector(
  [getSelectedTeamId, getAllTeams],
  (teamId, teams) => teams[teamId],
);

export const getSelectedTeamLoading = (state) => (
  state.teams.fetchSelected.status === ASYNC_STATE.LOADING
);
export const getIsFetchSelectedTeamSucceeded = (state) => (
  state.teams.fetchSelected.status === ASYNC_STATE.SUCCEEDED);

export const getDeleteTeam = (state) => state.teams.delete.status === ASYNC_STATE.LOADING;
export const getCreateTeam = (state) => state.teams.create.status === ASYNC_STATE.LOADING;

export const getRemovingUserFromSelectedTeamLoading = (state) => (
  state.teams.removeUserFromSelected.status === ASYNC_STATE.LOADING
);
export const getAddingUserToSelectedTeamLoading = (state) => (
  state.teams.addUserToSelected.status === ASYNC_STATE.LOADING
);

const getTeamsState = state => (
  state.teams.fetchTeams || {}
);

export const getTeams = createSelector(
  [getTeamsState, getAllTeams],
  currentPaginationEntities,
);

export const getTeamsPaginationResult = createSelector(
  [getTeamsState],
  toPaginationPaginationResult,
);

export const getTeamsLoading = createSelector(
  [getTeamsState],
  toIsLoading,
);

export const getCreateTeamErrorMessage = (
  state,
) => state.teams.create.errorMessage;

export const getCreateTeamDefaultErrorMessage = (
  state,
) => state.teams.create.defaultErrorMessage;

export const getUpdateTeamErrorMessage = (
  state,
) => state.teams.updateSelected.errorMessage;

export const getUpdateTeamDefaultErrorMessage = (
  state,
) => state.teams.updateSelected.defaultErrorMessage;

export const getDeleteTeamDefaultErrorMessage = (
  state,
) => state.teams.delete.defaultErrorMessage;

export const getRemovingUserFromSelectedTeamDefaultErrorMessage = (
  state,
) => state.teams.removeUserFromSelected.defaultErrorMessage;

export const getAddUserToSelectedTeamDefaultErrorMessage = (
  state,
) => state.teams.addUserToSelected.defaultErrorMessage;

export const getAddUserToSelectedTeamErrorMessage = (
  state,
) => state.teams.addUserToSelected.errorMessage;
