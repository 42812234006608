import { createSelector } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../../constants/AsyncState';


export const getAllAdministrationChannels = (state) => state.administrationChannels.channels;
export const getSelectedAdministrationChannelId = (
  state,
) => state.administrationChannels.selectedChannelId;

export const getSelectedAdministrationChannel = createSelector(
  [getSelectedAdministrationChannelId, getAllAdministrationChannels],
  (channelId, channels) => channels[channelId],
);

export const getSelectedAdministrationChannelLoading = (state) => (
  state.administrationChannels.fetchSelected.status === ASYNC_STATE.LOADING
);

export const getIsRemovingUserFromGroupChannelLoading = (state) => (
  state.administrationChannels.removeUserFromGroupChannel.status === ASYNC_STATE.LOADING
);

export const getIsUpdateGroupChannelNameLoading = (state) => (
  state.administrationChannels.updateSelected.status === ASYNC_STATE.LOADING
);

export const getUpdateGroupChannelNameErrorMessage = (
  state,
) => state.administrationChannels.updateSelected.errorMessage;

export const getUpdateGroupChannelNameDefaultErrorMessage = (
  state,
) => state.administrationChannels.updateSelected.defaultErrorMessage;

export const getRemovingUserFromSelectedGroupChannelDefaultErrorMessage = (
  state,
) => state.administrationChannels.removeUserFromGroupChannel.defaultErrorMessage;

export const getIsDeletingGroupChannelLoading = (state) => (
  state.administrationChannels.deleteGroupChannel.status === ASYNC_STATE.LOADING
);

export const getDeleteGroupChannelDefaultErrorMessage = (
  state,
) => state.administrationChannels.deleteGroupChannel.defaultErrorMessage;

export const getIsDeletingDirectChannelLoading = (state) => (
  state.administrationChannels.deleteDirectChannel.status === ASYNC_STATE.LOADING
);

export const getDeleteAgentDirectChannelDefaultErrorMessage = (
  state,
) => state.administrationChannels.deleteDirectChannel.defaultErrorMessage;

export const getAddUserToGroupChannelDefaultErrorMessage = (
  state,
) => state.administrationChannels.addUserToGroupChannel.defaultErrorMessage;
