import { useEffect } from 'react';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { getIsGroupChat } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import {
  resetSelectedNewChannelOptions, resetNewChannelOptions,
} from 'gcs-common/slices/newChannelOptions/newChannelOptionsSlice';
import {
  getCreateChannelError,
  getCreateChannelIsSuccess, getCreateSalesSpaceError,
  getCreateSalesSpaceIsSuccess,
} from 'gcs-common/slices/channels/channelsSelectors';
import { setLeftBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { LEFT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import {
  resetCreateChannelAsyncStatus,
  resetCreateSalesSpaceAsyncStatus,
} from 'gcs-common/slices/channels/channelsSlice';
import { getSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelTypeFilter';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import NewChannelListAgent from '../NewChannelListAgent/NewChannelListAgent';
import GroupedNewChannelList from '../NewChannelListAgent/GroupedNewChannelList/GroupedNewChannelList';

const NewChannelList = () => {

  const dispatch = useDispatch();
  const isGroupChat = useSelector(getIsGroupChat);
  const isAgent = useSelector(getIsAgent);
  const createChannelIsSuccess = useSelector(getCreateChannelIsSuccess);
  const createSalesSpaceIsSuccess = useSelector(getCreateSalesSpaceIsSuccess);
  const createChannelError = useSelector(getCreateChannelError);
  const createSalesSpaceError = useSelector(getCreateSalesSpaceError);
  const selectedChannelType = useSelector(getSelectedChannelTypeFilter);
  const searchInput = useSelector(getSearchInput);

  useEffect(() => {
    if (!isGroupChat) {
      dispatch(resetSelectedNewChannelOptions());
    }
  }, [dispatch, isGroupChat]);

  useEffect(() => {
    dispatch(resetNewChannelOptions());
  }, [dispatch, selectedChannelType, searchInput]);

  useEffect(() => {
    if (createChannelIsSuccess || createSalesSpaceIsSuccess) {
      dispatch(resetSelectedNewChannelOptions());
      dispatch(setLeftBarTab({ leftBarTab: LEFT_BAR_TABS.SIDEBAR }));

      if (createChannelIsSuccess) dispatch(resetCreateChannelAsyncStatus());
      if (createSalesSpaceIsSuccess) dispatch(resetCreateSalesSpaceAsyncStatus());
    }

    if (createChannelError || createSalesSpaceError) {
      if (createChannelError) {
        dispatch(resetCreateChannelAsyncStatus());
        dispatch(resetSelectedNewChannelOptions());
      }
      if (createSalesSpaceIsSuccess || createSalesSpaceError) {
        dispatch(resetCreateSalesSpaceAsyncStatus());
        dispatch(resetSelectedNewChannelOptions());
      }
    }
  }, [
    createChannelIsSuccess,
    createSalesSpaceIsSuccess,
    createChannelError,
    createSalesSpaceError,
    dispatch,
  ]);

  return isAgent ? (
    <NewChannelListAgent />
  ) : (
    <GroupedNewChannelList />
  );
};

export default NewChannelList;
