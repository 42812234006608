import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchBusinessUnits = createAsyncThunk(
  'businessUnits/fetchBusinessUnits',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
  } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getBusinessUnits(
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination };
  },
);

export const fetchBusinessUnitDetails = createAsyncThunk(
  'businessUnits/fetchBusinessUnitDetails',
  async ({ businessUnitId } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getBusinessUnitDetails(businessUnitId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { businessUnit: response };
  },
);

export const createBusinessUnit = createAsyncThunk(
  'businessUnits/createBusinessUnit',
  async ({ businessUnit } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.createBusinessUnit(businessUnit);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // TODO: Reload?
    return { businessUnit: response };
  },
);

export const updateBusinessUnit = createAsyncThunk(
  'businessUnits/updateBusinessUnit',
  async ({ businessUnitId, values }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateBusinessUnit(businessUnitId, values);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // TODO: Reload?
    return { businessUnit: response };
  },
);

export const deleteBusinessUnit = createAsyncThunk(
  'businessUnits/deleteBusinessUnit',
  async ({ businessUnitId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteBusinessUnit(businessUnitId);
    if (response.error_code && response.error_code !== 200) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // TODO: Reload?
    return response;
  },
);
