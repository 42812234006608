import { captureException } from '@sentry/react';
import { ERROR_DIALOG } from 'gcs-common/constants/errorDialogConstants';
import { openErrorDialog } from '../slices/errorDialogSlice/errorDialogSlice';
import { getErrorMsg } from '../helper/errorMessages';

const openErrorDialogMiddleware = ({ dispatch }) => next => action => {
  next(action);
  try {
    if (action.error
        && action.payload?.errorDialog
        && action.payload?.errorDialog !== ERROR_DIALOG.NONE) {
      const { payload } = action;
      if (!payload) {
        return;
      }
      const {
        errorCode,
        description: errorMessageFromApiResonse,
        errorDialog,
      } = payload;
      const errorMessageFromCode = getErrorMsg(errorCode);
      const defaultErrorMessage = getErrorMsg('chat.default');
      dispatch(openErrorDialog({
        dialogErrorMsg: errorMessageFromCode || errorMessageFromApiResonse || defaultErrorMessage,
        dialogErrorCode: errorCode,
        errorDialog,
      }));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('An error occurred while showing error message', e);
    captureException('An error occurred while showing error message');
  }
};

export default openErrorDialogMiddleware;
