import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

class ChannelListDateTag extends Component {
  render() {
    const { date } = this.props;
    const humanReadableDate = date.format('dddd, l');
    return (
      <div className={styles.channelListDateTag}>{humanReadableDate}</div>
    );
  }
}

ChannelListDateTag.propTypes = {
  date: PropTypes.shape({
    format: PropTypes.func.isRequired,
  }).isRequired,
};

export default ChannelListDateTag;
