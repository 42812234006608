import moment from 'moment';
import USER_TYPE from './userTypeHelper';


const AGENT_STATUS_TEXT = {
  ONLINE: 'Aktiv',
  IDLE: 'In Kürze verfügbar',
  OFFLINE: 'Inaktiv',
  UNKNOWN: 'Status unbekannt',
};

const ONLINE_STATUS = {
  ONLINE: 'ONLINE',
  IDLE: 'IDLE',
  OFFLINE: 'OFFLINE',
  UNKNOWN: 'UNKNOWN',
};

const ONLINE_STATUS_PRIORITY = {
  [ONLINE_STATUS.ONLINE]: 1,
  [ONLINE_STATUS.IDLE]: 2,
  [ONLINE_STATUS.OFFLINE]: 3,
  [ONLINE_STATUS.UNKNOWN]: 4,
  undefined: 5,
};

const buildAgentStatus = (type) => {
  return {
    type,
    text: AGENT_STATUS_TEXT[type],
    updatedAt: moment().format(),
  };
};

const calculateUserStatus = (isConnected, user, ignoreConnectionStatus = false) => {
  // ignore connection status for checking if a sales space is free to avoid channel list flickering
  if ((!isConnected && !ignoreConnectionStatus) || !user || !user.userType) {
    return { type: ONLINE_STATUS.UNKNOWN };
  }
  const { status, online } = user;
  switch (user.userType) {
    case USER_TYPE.CRAFTSMAN:
      return { type: online ? ONLINE_STATUS.ONLINE : ONLINE_STATUS.OFFLINE };
    case USER_TYPE.AGENT:
      if (status) return status;
      return { type: ONLINE_STATUS.UNKNOWN };
    default:
      return { type: ONLINE_STATUS.UNKNOWN };
  }
};

export {
  ONLINE_STATUS,
  AGENT_STATUS_TEXT,
  ONLINE_STATUS_PRIORITY,
  calculateUserStatus,
  buildAgentStatus,
};
