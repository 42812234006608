import { useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { StylesConfig } from 'react-select';
import fetchCurrentUserErpCustomers from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUserErpCustomers';
import { getCurrentUserErpCustomersLoading, getCurrentUserErpCustomers } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { useDebouncedCallback } from 'use-debounce';

interface OptionType {
  value: string;
  label: string;
}

interface Customer {
  friendlyName: string,
  id: string,
  customerNumber: number
} // TODO

interface ErpCustomerDropDownProps {
  onChange: (newValue: string | string[] | null) => void;
  isClearable?: boolean;
  isMulti?: boolean;
}

const ErpCustomerDropDown = (props: ErpCustomerDropDownProps) => {
  const { onChange, isClearable = false, isMulti = false } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const customers: Customer[] = useSelector(getCurrentUserErpCustomers);
  const customersLoading = useSelector(getCurrentUserErpCustomersLoading);
  const dispatch = useDispatch();
  const handleChange = (selectedOption: OptionType | OptionType[] | null) => {
    let values;
    if (Array.isArray(selectedOption)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      values = selectedOption.map(x => x.value);
    } else {
      values = selectedOption?.value || null;
    }

    onChange(values);
  };

  const loadErpCustomers = useCallback((search:string | null = null) => {
    // @ts-expect-error redux
    dispatch(fetchCurrentUserErpCustomers({
      search,

      /** We only need basic information, not the entire DTO. */
      plainSelect: true,

      /**
       * Fetching ERP customers can take over 4 seconds for some of our heavy users.
       * When multiple requests are fired in a short timespan, some might return out of order,
       * overriding up2date data with stale data from a previous search or filter.
       * => Make sure that we only keep latest data by forcing a processing sequence,
       *    ignoring requests that arrive out of order.
       */
      processingSequence: Date.now(),
    }));
  }, [dispatch]);

  const [loadErpCustomersDebounced] = useDebouncedCallback(loadErpCustomers, 500);

  useEffect(() => {
    loadErpCustomers();
  }, [loadErpCustomers]);

  const customersOptions: OptionType[] = useMemo(() => customers.map(el => ({
    label: `${el.friendlyName} (${el.customerNumber})`,
    value: el.id,
  })), [customers]);

  const customStyles: StylesConfig<OptionType> = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
    }),
  };

  return (
    <Select<OptionType>
      name="erpCustomerId"
      options={customersOptions}
      isLoading={customersLoading}
      isClearable={isClearable}
      onChange={(selectedOption) => handleChange(selectedOption || null)}
      styles={customStyles}
      placeholder="Kunden suchen"
      // @ts-expect-error idk ¯\_(ツ)_/¯ Error: Type 'boolean' is not assignable to type 'false'
      isMulti={isMulti}
      onInputChange={loadErpCustomersDebounced}
    />
  );
};

export default ErpCustomerDropDown;
