/* eslint-disable react/no-array-index-key */

import PropTypes from 'prop-types';
import avatarPlaceHolder from 'gcs-common/img/user.svg';
import styles from './styles.module.scss';

function MultiProfileImage({ images, count = 4 }) {
  return (
    <div className={styles.multiProfileWrapper}>
      {images
        .splice(0, count)
        .map((img, index) => (
          <img
            className={styles.profileImage}
            key={index}
            alt={`profile-${index}`}
            src={img || avatarPlaceHolder}
          />
        ))}
      {images.length > count
      && (
      <div
        className={styles.profileImageMore}
        key="more-images"
      >
        {`+${images.length - count}`}
      </div>
      )
      }
    </div>
  );
}

MultiProfileImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  count: PropTypes.func,
};

export default MultiProfileImage;
