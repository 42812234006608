import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCommunicationStructure,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSelectors';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import Icon from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import PopupMenu from '../../../../PopupMenu/PopupMenu';
import Button from '../../../../Button/Button';
import { showSuccessNotification } from '../../../../../slices/notifications/ShowNotificationThunk';
import PopUpModal from '../../../../PopUpModal/PopUpModal';
import CommunicationStructureDelete from '../../CommunicationStructureDelete/CommunicationStructureDelete';

const CommunicationStructureSettings = ({
  communicationStructureId,
  onExpandButtonClick,
  rowType,
}) => {

  const communicationStructure = useSelector(getCommunicationStructure(communicationStructureId));
  const { houseId } = communicationStructure;

  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const [deleteStructure, setDeleteStructure] = useState(null);
  const dispatch = useDispatch();

  const onOutsideClick = useCallback(() => {
    setShowMenuPopup(false);
  }, []);

  const structureIsDeletedSuccessfully = useCallback(() => {
    setDeleteStructure(null);
    dispatch(showSuccessNotification('Erfolgreich entfernt'));
  }, [dispatch]);
  const showArrowIcon = () => {
    if (rowType === 'open') {
      return (
        <Icon
          Icon={ICON.ARROW}
          transform={ICON_TRANSFORM.ROTATE_270}
          alt="up-arrow"
        />
      );
    }
    return (
      <Icon
        Icon={ICON.ARROW}
        transform={ICON_TRANSFORM.ROTATE_90}
        alt="down-arrow"
      />
    );
  };

  return (
    <div className={styles.expandDivRow}>
      <button type="button" onClick={onExpandButtonClick} className={styles.expandButtonWrapper}>
        {showArrowIcon()}
      </button>
      <div>
        <PopupMenu
          isVisible={showMenuPopup}
          onHide={onOutsideClick}
          menuItemsClass={styles.menuLeft}
        >
          <Button type="button" onClick={() => setDeleteStructure(true)}>
            Löschen
          </Button>
        </PopupMenu>

        {deleteStructure && (
          <PopUpModal
            title="kommunikationsstruktur löschen"
            onRequestClose={() => setDeleteStructure(null)}
            isOpen={!!deleteStructure}
          >
            <CommunicationStructureDelete
              communicationStructureId={communicationStructureId}
              houseId={houseId}
              onCancel={() => setDeleteStructure(null)}
              onSuccess={structureIsDeletedSuccessfully}
            />
          </PopUpModal>
        )}
      </div>
    </div>
  );
};

CommunicationStructureSettings.propTypes = {
  communicationStructureId: PropTypes.string.isRequired,
  onExpandButtonClick: PropTypes.func.isRequired,
  rowType: PropTypes.string.isRequired,
};

export default CommunicationStructureSettings;
