import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMessageImageUrl,
  getMessageMediaRelativePath,
} from '../messageSelector';
import preloadImage from '../../../helper/preloadImage';
import { parseQs } from '../../../helper/urlHelper';
import { calculateMessageMediaURL } from '../../../helper/mediaHelper';

const fetchAndPreloadImageUrl = createAsyncThunk(
  'messages/fetchAndPreloadImageUrl',
  async ({ messageId }, { getState }) => {
    const mediaRelativePath = getMessageMediaRelativePath(messageId)(getState());
    const url = calculateMessageMediaURL(mediaRelativePath);
    await preloadImage(url);
    return { url };
  },
  {
    condition: ({ messageId }, { getState }) => {
      // Cache: Only reload the file if it is expired or not present
      const mediaUrl = getMessageImageUrl(messageId)(getState());
      if (mediaUrl) {
        const { search } = new URL(mediaUrl);
        const mediaUrlQueryParams = parseQs(search);
        const { Expires } = mediaUrlQueryParams;
        // Note: If this is a thumbnailUrl, it has no Expires param
        if (!Expires) {
          return false;
        }
        return new Date().getTime() / 1000 > Expires;
      }
      return true;
    },
  },
);

export default fetchAndPreloadImageUrl;
