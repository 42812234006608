import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommunicationAssignment,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsSelectors';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import Icon from 'gcs-common/components/Icon/Icon';
import styles from './styles.module.scss';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import ErpCustomerAssignmentsDelete from '../ErpCustomerAssignmentsDelete/ErpCustomerAssignmentsDelete';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import PopupMenu from '../../../PopupMenu/PopupMenu';
import Button from '../../../Button/Button';

const
  CommunicationAssignmentSettings = ({
    communicationAssigmentId,
    onExpandButtonClick,
    rowType,
  }) => {

    const [showMenuPopup, setShowMenuPopup] = useState(false);
    const [deleteAssignment, setDeleteAssignment] = useState(false);
    const assignment = useSelector(getCommunicationAssignment(communicationAssigmentId));
    const dispatch = useDispatch();

    const onOutsideClick = useCallback(() => {
      setShowMenuPopup(false);
    }, []);

    const assignmentIsDeletedSuccessfully = useCallback(() => {
      setDeleteAssignment(false);
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    }, [dispatch]);

    const showArrowIcon = () => {
      if (rowType === 'open') {
        return (
          <Icon
            Icon={ICON.ARROW}
            transform={ICON_TRANSFORM.ROTATE_270}
            alt="up-arrow"
          />
        );
      }
      return (
        <Icon
          Icon={ICON.ARROW}
          transform={ICON_TRANSFORM.ROTATE_90}
          alt="down-arrow"
        />
      );
    };
    return (
      <div className={styles.expandDivRow}>
        <button type="button" onClick={onExpandButtonClick} className={styles.expandButtonWrapper}>
          {showArrowIcon()}
        </button>

        <div>
          <PopupMenu
            isVisible={showMenuPopup}
            onHide={onOutsideClick}
            menuItemsClass={styles.menuLeft}
          >
            <Button type="button" onClick={() => setDeleteAssignment(true)}>
              Löschen
            </Button>
          </PopupMenu>

          {deleteAssignment && (
          <PopUpModal
            title="Kommunikationszuweisung löschen"
            onRequestClose={() => setDeleteAssignment(false)}
            isOpen={deleteAssignment}
          >
            <ErpCustomerAssignmentsDelete
              erpCustomerId={assignment.connectionId}
              communicationAssignmentId={communicationAssigmentId}
              onCancel={() => setDeleteAssignment(false)}
              onSuccess={assignmentIsDeletedSuccessfully}
            />
          </PopUpModal>
          )}
        </div>
      </div>
    );
  };

CommunicationAssignmentSettings.propTypes = {
  communicationAssigmentId: PropTypes.string.isRequired,
  onExpandButtonClick: PropTypes.func.isRequired,
  rowType: PropTypes.string.isRequired,
};

export default CommunicationAssignmentSettings;
