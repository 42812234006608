import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import styles from './styles.module.scss';
import editIcon from '../../img/edit.svg';

const InlineInput = ({
  className = '',
  textClassName,
  disabled = false,
  name = '',
  label = '',
  value = '',
  onChange,
}) => {
  const [_value, _setValue] = useState(value);
  const [isEditing, setEditing] = useState(false);

  const [onInputChangedDebounced, cancel] = useDebouncedCallback(onChange, 1000);

  const onUpdate = useCallback((val) => {
    onInputChangedDebounced(name, val);
    _setValue(val);
  }, [name, onInputChangedDebounced]);

  const onBlur = useCallback(() => {
    setEditing(false);
    cancel();
    if (value !== _value) {
      onInputChangedDebounced(name, _value);
    }
  }, [_value, cancel, name, onInputChangedDebounced, value]);

  useEffect(() => {
    _setValue(value);
  }, [value]);

  return (
    <div className={`${styles.inlineInputWrapper} ${className}`}>
      <div className={styles.inlineInputHeader}>{label}</div>
      <div className={styles.inlineInputTextWrapper}>
        <input
          className={textClassName || styles.inlineInputText}
          placeholder="-"
          type="text"
          disabled={disabled}
          value={_value || ''}
          onChange={($event) => onUpdate($event.target.value)}
          onFocus={() => setEditing(true)}
          onBlur={onBlur}
        />
        <img className={!isEditing && styles.iconHidden} src={editIcon} alt="Edit" />
      </div>
    </div>
  );
};

InlineInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InlineInput;
