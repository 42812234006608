/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TasksState {
  selectedTaskId: string | undefined
  hiddenTaskReminders: string[]
}

const initialState: TasksState = {
  selectedTaskId: undefined,
  hiddenTaskReminders: [],
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    taskReminderHidden: (state, action: PayloadAction<string>) => {
      const taskId = action.payload;
      state.hiddenTaskReminders.push(taskId);
    },
    taskIdSelected: (state, action) => {
      const { taskId } = action.payload;
      state.selectedTaskId = taskId;
    },
  },
});

export const {
  taskReminderHidden,
  taskIdSelected,
} = tasksSlice.actions;

export default tasksSlice.reducer;
