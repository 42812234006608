import { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAgentToCraftsmanDirectChannel,
  createCraftsmanToCraftsmanDirectChannel,
  fetchErpCustomerUsers,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import {
  getErpCustomerUsers,
  getErpCustomerUsersLoading,
  getCreateErpCustomerDirectChannelErrorMessage,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import {
  resetCreateErpCustomerDirectChannelErrorMessage,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import USER_TYPE from 'gcs-common/helper/userTypeHelper';
import { resetCreateAgentDirectChannelErrorMessage } from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import FormikUserSelect from '../../shared/FormikUserSelect/FormikUserSelect';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import Tabs from '../../../Tabs/Tabs';

const DirectChannelCreateValidationSchema = Yup.object().shape({
  userId1: Yup.string().nullable().required('Erforderlich'),
});

const initialData = {
  agentId: undefined,
  userId1: undefined,
  userId2: undefined,
};
const CHANNEL_TYPE_SELECTION_TABS = {
  AGENTS: 'agents',
  CRAFTSMAN: 'craftsman',
};

// TODO rework this component since it creates both internal craftsman channels and direct chats
const ErpCustomerDirectChannelCreate = ({ erpCustomerId, onCancel, onSuccess }) => {

  const erpCustomerUsers = useSelector(getErpCustomerUsers);
  const erpCustomerUsersLoading = useSelector(getErpCustomerUsersLoading);
  const dispatch = useDispatch();
  const errorMessage = useSelector(getCreateErpCustomerDirectChannelErrorMessage);
  const [currentTab, setCurrentTab] = useState(CHANNEL_TYPE_SELECTION_TABS.AGENTS);

  const onTabChanged = useCallback((tab) => {
    if (errorMessage) {
      dispatch(resetCreateAgentDirectChannelErrorMessage());
    }
    setCurrentTab(tab);
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetCreateErpCustomerDirectChannelErrorMessage()),
      ));
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateErpCustomerDirectChannelErrorMessage());
  }, [dispatch]);

  const onChannelMembersChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetCreateErpCustomerDirectChannelErrorMessage());
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    dispatch(fetchErpCustomerUsers({ erpCustomerId }));
  }, [erpCustomerId, dispatch]);

  const submit = useCallback(async (values, { setSubmitting }) => {
    let result;
    if (currentTab === CHANNEL_TYPE_SELECTION_TABS.AGENTS) {
      const { userId1, agentId } = values;
      result = await dispatch(createAgentToCraftsmanDirectChannel({
        userId1, agentId, erpCustomerId,
      }));
    } else {
      const { userId2, userId1 } = values;
      result = await dispatch(
        createCraftsmanToCraftsmanDirectChannel({
          userId1, userId2, erpCustomerId,
        }),
      );
    }
    setSubmitting(false);
    if (!result.error) {
      onSuccess(true);
    }
  }, [erpCustomerId, currentTab, dispatch, onSuccess]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={DirectChannelCreateValidationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting, values }) => (
        <Form className={styles.column}>
          <FormikUserSelect
            options={erpCustomerUsers}
            isLoading={erpCustomerUsersLoading}
            selectKey="DirectChannelCreateCraftsman1Selection"
            userType={USER_TYPE.CRAFTSMAN}
            label="Nutzer*"
            name="userId1"
            placeholder="Bitte wählen"
            key="craftsmen"
            labelKey="friendlyName"
            valueKey="id"
            onChange={onChannelMembersChanged}
          />
          <Tabs
            onActiveTabChanged={onTabChanged}
            tabs={{
              agents: {
                name: 'Mitarbeiter',
                component: (
                  <div className={styles.row}>
                    <FormikUserSelect
                      selectKey="DirectChannelCreateAgentSelection"
                      userType={USER_TYPE.AGENT}
                      label="Mitarbeiter*"
                      name="agentId"
                      labelKey="friendlyName"
                      valueKey="id"
                      onChange={onChannelMembersChanged}
                    />
                  </div>

                ),
              },
              craftsmen: {
                name: 'Handwerker',
                component: (
                  <div className={styles.row}>
                    <FormikUserSelect
                      options={erpCustomerUsers}
                      selectKey="DirectChannelCreateCraftsman2Selection"
                      userType={USER_TYPE.CRAFTSMAN}
                      key="craftsmen"
                      label="Nutzer 2*"
                      localFilter={[values.userId1]}
                      name="userId2"
                      labelKey="friendlyName"
                      valueKey="id"
                      onChange={onChannelMembersChanged}
                    />
                  </div>

                ),
              },
            }}
          />

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

          <div className={styles.buttonRow}>
            <Button
              className={styles.modalButtonSecondary}
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Abbrechen
            </Button>
            <Button
              className={styles.modalButton}
              type="submit"
              disabled={isSubmitting}
            >
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
ErpCustomerDirectChannelCreate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  erpCustomerId: PropTypes.string.isRequired,
};

export default ErpCustomerDirectChannelCreate;
