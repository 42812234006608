import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { getIsAgent } from '../currentUser/currentUserSelectors';
import {
  NEW_CHANNEL_SECTIONS,
} from '../../constants/newChannelSections';
import PLATFORM from '../../constants/platformTypes';
import { getPlatform } from '../init/initSelectors';
import { CHANNEL_TYPE, CHANNEL_FILTER_TYPES } from '../../helper/channelTypeHelper';
import { getSelectedChannelTypeFilter } from '../channelList/channelListSelectors/getSelectedChannelTypeFilter';
import ASYNC_STATE from '../../constants/AsyncState';
import { filterByChannelTypeInSection } from './newChannelOptionsFilter';

export const getIsGroupChat = state => state.newChannelOptions.groupChat;
export const getNewChannelOptionsPagination = state => state.newChannelOptions.pagination;
const getDisplayNewChannelOptionsIds = state => state.newChannelOptions.displayNewChannelOptionsIds;
const getAllChannelOptions = state => state.newChannelOptions.allChannelOptions;

export const isOptionHidden = (newChannelOptionId) => createSelector(
  getAllChannelOptions,
  (newChannelOptions) => newChannelOptions[newChannelOptionId].archived,
);

export const getNewChannelOption = (newChannelOptionId) => createSelector(
  getAllChannelOptions,
  (newChannelOptions) => newChannelOptions?.[newChannelOptionId] || null,
);

export const getNewChannelOptionsLoading = (state) => (
  state.newChannelOptions.fetchNewChannelOptions.status === ASYNC_STATE.LOADING
);

export const getNewChannelOptionsFailed = (state) => (
  state.newChannelOptions.fetchNewChannelOptions.status === ASYNC_STATE.FAILED
);

// eslint-disable-next-line max-len
export const getSelectedNewChannelOptionIds = state => state.newChannelOptions.selectedNewChannelOptionIds;

export const getNewChannelOptionTypesToFetch = createSelector(
  createStructuredSelector({
    channelTypeFilter: getSelectedChannelTypeFilter,
    isGroupChat: getIsGroupChat,
    isAgent: getIsAgent,
    platform: getPlatform,
  }),
  ({
    channelTypeFilter,
    isGroupChat,
    isAgent,
    platform,
  }) => {
    const isMobile = (platform === PLATFORM.MOBILE);

    if (isGroupChat) {
      return [
        CHANNEL_TYPE.INTERNAL_AGENT,
        CHANNEL_TYPE.INTERNAL_CRAFTSMAN,
        CHANNEL_TYPE.DIRECT_CHAT,
      ];
    }

    if (isAgent && !isMobile && !isGroupChat) {
      return (channelTypeFilter === CHANNEL_FILTER_TYPES.TEAM_CHATS)
        ? [CHANNEL_TYPE.SALES_SPACE]
        : [CHANNEL_TYPE.INTERNAL_AGENT, CHANNEL_TYPE.INTERNAL_CRAFTSMAN, CHANNEL_TYPE.DIRECT_CHAT];
    }

    return [
      CHANNEL_TYPE.INTERNAL_AGENT,
      CHANNEL_TYPE.INTERNAL_CRAFTSMAN,
      CHANNEL_TYPE.DIRECT_CHAT,
      CHANNEL_TYPE.SALES_SPACE,
    ];
  },
);

export const getIsNewChannelSelected = (newChannelOptionId) => createSelector(
  getSelectedNewChannelOptionIds,
  (selectedNewChannelsIds) => !!selectedNewChannelsIds.find(id => id === newChannelOptionId),
);

export const getNewChannelGroupSectionTitle = (section) => createSelector(
  getIsAgent,
  (isAgent) => {
    switch (section) {
      case NEW_CHANNEL_SECTIONS.SALES_SPACE:
        return 'Teamchats';
      case NEW_CHANNEL_SECTIONS.HOUSE:
        return isAgent ? 'Chats mit Kunden' : 'Chats mit Haus';
      case NEW_CHANNEL_SECTIONS.DIRECT:
        return 'Direkt';
      case NEW_CHANNEL_SECTIONS.INTERNAL:
        return 'Chats mit Kollegen';
      case NEW_CHANNEL_SECTIONS.SELECTED_MEMBERS:
        return 'Ausgewählte Teilnehmer';
      default:
        return '';
    }
  },
);

export const getUserIdsFromSelectedNewChannelOptions = createSelector(
  getAllChannelOptions,
  getSelectedNewChannelOptionIds,
  (newChannelOptions, selectedNewChannelOptionIds) => (
    selectedNewChannelOptionIds.map((newChannelOptionId) => (
      newChannelOptions[newChannelOptionId]?.userId
    ))
  ),
);

// Channel sections are into dividing stuff which is shown into buckets.
// Channel Filters are for determining whats shown at all
export const getNewChannelOptionIdsForSection = (section) => createSelector(
  getAllChannelOptions,
  getDisplayNewChannelOptionsIds,
  getSelectedNewChannelOptionIds,
  (newChannelOptions, newChannelOptionIds, selectedNewChannelOptionIds) => {
    switch (section) {
      case NEW_CHANNEL_SECTIONS.SELECTED_MEMBERS:
        return selectedNewChannelOptionIds;
      default:
        return newChannelOptionIds
          .filter(filterByChannelTypeInSection({ section, newChannelOptions }));
    }
  },
);

export const getNonSelectedNewChannelOptionIds = createSelector(
  getDisplayNewChannelOptionsIds,
  getSelectedNewChannelOptionIds,
  (newChannelOptionIds, selectedNewChannelOptionIds) => {

    return newChannelOptionIds.filter((newChannelOptionId) => (
      !selectedNewChannelOptionIds.includes(newChannelOptionId)
    ));
  },
);
