import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChannelId,
} from 'gcs-common/slices/channels/channelsSelectors';
import {
  getLoadPreviousMessagesIsLoading,
  getLoadPreviousMessagesError,
} from 'gcs-common/slices/messages/messagesSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getSelectedMessageIds,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { channelInputFileStaged } from 'gcs-common/slices/messageInput/messageInputSlice';
import {
  getSearchScrollMessageLoading,
  getSearchScrollMessageError,
} from 'gcs-common/slices/chat/chatSelectors';
import { setUploadFileError } from 'gcs-common/slices/uiState/uiStateSlice';
import resetChatErrors from 'gcs-common/slices/chat/chatThunks/resetChatErrors';
import { getCurrentUserIsMemberOfChannel } from 'gcs-common/slices/salesSpaceChannels/salesSpaceChannelsSelectors';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import IconComponent from 'gcs-common/components/Icon/Icon';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import MessageInputArea from '../MessageInputArea/MessageInputArea';
import DNDFileUploadWrapper from '../DNDFileUploadWrapper/DNDFileUploadWrapper';
import AgentAvailabilityBanner from '../AgentAvailabilityBanner/AgentAvailabilityBanner';
import styles from './styles.module.scss';

import { ForwardMessageBottom } from '../ForwardMessageBottom/ForwardMessageBottom';
import VirtualMessageScroller from '../VirtualMessageScroller/VirtualMessageScroller';

const Chat = () => {
  const selectedChannelId = useSelector(getSelectedChannelId);
  const isAgent = useSelector(getIsAgent);
  const searchScrollMessage = useSelector(getSearchScrollMessageLoading);
  const searchScrollMessageError = useSelector(getSearchScrollMessageError);
  const prevMessageLoadingError = useSelector(getLoadPreviousMessagesError);
  const loadingPreviousMessages = useSelector(getLoadPreviousMessagesIsLoading);
  const shouldShowInput = useSelector(getCurrentUserIsMemberOfChannel(selectedChannelId));
  const selectedMessages = useSelector(getSelectedMessageIds(selectedChannelId));
  const selectingForwardMessages = !!selectedMessages.length;
  const dispatch = useDispatch();

  useEffect(() => {
    // reset errors when switching channel
    dispatch(resetChatErrors());
  }, [dispatch, selectedChannelId]);

  const onFilesDropped = useCallback((files) => {
    files.forEach(file => dispatch(channelInputFileStaged({
      channelId: selectedChannelId,
      file,
    })));
  }, [dispatch, selectedChannelId]);

  const onFileDropError = useCallback((_, errorMessage) => {
    dispatch(setUploadFileError({ uploadFileError: errorMessage }));
  }, [dispatch]);

  if (!selectedChannelId) {
    return (
      <div className={styles.chat}>
        <div className={styles.chatArea}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.chat} data-testid="chat">
      <DNDFileUploadWrapper
        onChange={onFilesDropped}
        onError={onFileDropError}
      >
        {!isAgent && <AgentAvailabilityBanner />}
        <div
          className={styles.chatArea}
        >
          {searchScrollMessage && (
            <div className={styles.infoMsg} key={2}>
              <Spinner size={ICON_SIZE.XX_SMALL} />
              &nbsp;Suche in früheren Nachrichten...
            </div>
          )}
          {searchScrollMessageError && (
            <div className={styles.errorMsg} key={1}>
              <IconComponent Icon={ICON.CLOSE} size={ICON_SIZE.X_SMALL} color={ICON_COLOR.WHITE} />
              &nbsp;
              {searchScrollMessageError}
            </div>
          )}

          {prevMessageLoadingError && (
            <div className={styles.errorMsg} key={1}>
              <IconComponent Icon={ICON.CLOSE} size={ICON_SIZE.X_SMALL} color={ICON_COLOR.WHITE} />
              &nbsp;Laden von früheren Nachrichten fehlgeschlagen
            </div>
          )}
          {loadingPreviousMessages && (
            <div className={styles.infoMsg} key={0}>
              <Spinner size={ICON_SIZE.XX_SMALL} />
              &nbsp;Lade frühere Nachrichten...
            </div>
          )}
          <VirtualMessageScroller key={selectedChannelId} />
        </div>
        <div className={styles.line} />
        {shouldShowInput && !selectingForwardMessages && (
          <div className={styles.inputArea}>
            <MessageInputArea />
          </div>
        )}
        {selectingForwardMessages
          && (
            <ForwardMessageBottom />
          )
        }
      </DNDFileUploadWrapper>

    </div>
  );
};

export default Chat;
