import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeatureFlags } from 'gcs-common/slices/administration/featureFlags/featureFlagsThunks';
import { getAllFeatureFlags } from 'gcs-common/slices/administration/featureFlags/featureFlagsSelectors';
import { fetchTenantSelectOptions } from 'gcs-common/slices/administration/tenants/tenantsThunks';
import Table from '../../Table/Table';
import styles from './styles.module.scss';
import FeatureFlagTenantsCell from './FeatureFlagTenantsCell/FeatureFlagTenantsCell';
import FeatureFlagHousesCell from './FeatureFlagHousesCell/FeatureFlagHousesCell';
import FeatureFlagUsersCell from './FeatureFlagUsersCell/FeatureFlagUsersCell';

const ffColumns = [
  {
    Header: 'Feature Flag',
    accessor: 'key',
  },
  {
    Header: 'Tenants',
    Cell: FeatureFlagTenantsCell,
    accessor: 'tenants',
  },
  {
    Header: 'Häuser',
    Cell: FeatureFlagHousesCell,
    accessor: 'houses',
  },
  {
    Header: 'Nutzer',
    Cell: FeatureFlagUsersCell,
    accessor: 'users',
  },
];

const FeatureFlagsOverview = () => {
  const dispatch = useDispatch();
  const featureFlags = useSelector(getAllFeatureFlags);

  useEffect(() => {
    dispatch(fetchFeatureFlags());
    dispatch(fetchTenantSelectOptions());
  }, [dispatch]);

  return (
    <>
      <div className={styles.inputHeaderText}>
        Feature Flags
        {' '}
        (
        {featureFlags.length}
        )
      </div>
      <Table
        data={featureFlags}
        columns={ffColumns}
      />
    </>
  );
};

export default FeatureFlagsOverview;
