import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../client';
import { Parameters, RequestBody } from '../../../../types/typeHelper';

export enum ConnectionMembersQueryKeys {
  GET_CONNECTION_MEMBERS = 'GET_CONNECTION_MEMBERS',
}

export function useGetConnectionMembers(query: Parameters<'/chat/connection-members', 'get'>['query']) {
  const client = useApiClient();
  return useQuery({
    queryKey: [
      ConnectionMembersQueryKeys.GET_CONNECTION_MEMBERS,
      query,
    ],
    enabled: !!(query.userId && query.houseId),
    queryFn: async () => {
      const { data } = await client.GET('/chat/connection-members', {
        params: {
          query,
        },
      });
      return data;
    },
  });
}


export function useUpdateConnectionMemberMutation() {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation({
    mutationFn: async ({ params, body }: {
      params: Parameters<'/chat/connection-members/{connection_member_id}', 'put'>,
      body: RequestBody<'/chat/connection-members/{connection_member_id}', 'put'>,
    }) => {
      return client.PUT('/chat/connection-members/{connection_member_id}', {
        params, body,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey:
            [ConnectionMembersQueryKeys.GET_CONNECTION_MEMBERS] });
    },
  });
}
