import PropTypes from 'prop-types';
import avatarPlaceHolder from 'gcs-common/img/user.svg';
import quickOrderIconSvg from 'gcs-common/icons/quick-ordering.svg';

const IMAGE_TYPE = {
  PROFILE: 'profil',
  ARTICLE: 'article',
};

const Image = ({ type = 'default', src, alt = '', className = null }) => {
  const handleFailImage = (e) => {
    switch (type) {
      case IMAGE_TYPE.PROFILE:
        e.target.src = avatarPlaceHolder;
        break;
      case IMAGE_TYPE.ARTICLE:
        e.target.src = quickOrderIconSvg;
        break;
      default:
        break;
    }

  };
  return (
    <img
      src={src || avatarPlaceHolder}
      alt={alt}
      className={className}
      onError={handleFailImage}
    />
  );
};

Image.propTypes = {
  type: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export { IMAGE_TYPE };
export default Image;
