
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import styles from './styles.module.scss';

const ValidationError = ({
  name = '',
}) => {
  return (
    <ErrorMessage
      name={name}
      render={(msg) => <div className={styles.validationErrorMessage}>{msg}</div>}
    />
  );
};

ValidationError.propTypes = {
  name: PropTypes.string,
};

export default ValidationError;
