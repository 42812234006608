import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCommunicationAssignment, getCommunicationAssignmentMainAgent, getAssignmentMembers,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsSelectors';
import {
  CommunicationAssignmentType,
  CommunicationAssignmentDirection,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructuresConstants';
import RowMemberList from 'gcs-common/components/RowMemberList/RowMemberList';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import Icon from 'gcs-common/components/Icon/Icon';
import InfoTooltip from 'gcs-common/components/InfoTooltip/InfoTooltip';
import styles from './styles.module.scss';
import CommunicationAssignmentSettings from '../CommunicationAssignmentSettings/CommunicationAssignmentSettings';

const ErpCustomerAssignmentRowCollapsed = ({
  communicationAssignmentId,
  setOpenRowId,
}) => {

  const assignment = useSelector(getCommunicationAssignment(communicationAssignmentId));
  // eslint-disable-next-line max-len
  const isTeamRow = assignment.type === CommunicationAssignmentType.SALES_SPACE;
  // eslint-disable-next-line max-len
  const assignmentMainAgent = useSelector(getCommunicationAssignmentMainAgent(
    communicationAssignmentId,
  ));
  const assignmentMembers = useSelector(getAssignmentMembers(
    communicationAssignmentId,
  ));

  const directionIcon = () => {
    if (assignment.direction === CommunicationAssignmentDirection.BIDIRECTIONAL) {
      return <Icon Icon={ICON.CYCLE_ARROWS} color={ICON_COLOR.BLACK} size={ICON_SIZE.X_SMALL} />;
    }
    return <Icon Icon={ICON.ARROW_LONG} color={ICON_COLOR.BLACK} size={ICON_SIZE.X_SMALL} />;
  };

  const onExpandButtonClick = useCallback(() => {
    setOpenRowId(communicationAssignmentId);
  }, [setOpenRowId, communicationAssignmentId]);

  const isDefaultAssignment = assignment.isGeneratedFromStructure;
  // eslint-disable-next-line max-len
  let leftBorderClassName = isDefaultAssignment ? styles.defaultAssignment : styles.customAssignment;
  // eslint-disable-next-line max-len
  let tooltipText = isDefaultAssignment ? 'Generierte Kommunikationszuweisung' : 'Kommunikationszuweisung';

  if (assignment.isEdited && isDefaultAssignment) {
    leftBorderClassName = styles.defaultAssignmentEdited;
    tooltipText = `${tooltipText} (Bearbeitet)`;
  }

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div className={styles.rowContainer} onClick={onExpandButtonClick}>
      <div className={leftBorderClassName}>
        <InfoTooltip text={tooltipText} showIcon={false} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.direction}>
          {directionIcon()}
        </div>
        {isTeamRow && (
          <div className={styles.nameContainer}>
            <div className={styles.name}>
              {assignment.channelName}
            </div>
          </div>
        )}

        <RowMemberList
          isTeamRow={isTeamRow}
          assignmentMainAgent={assignmentMainAgent}
          assignmentMembers={assignmentMembers}
        />

      </div>
      <CommunicationAssignmentSettings
        communicationAssigmentId={assignment.id}
        onExpandButtonClick={onExpandButtonClick}
        rowType="collapsed"
      />
    </div>
  );
};

ErpCustomerAssignmentRowCollapsed.propTypes = {
  communicationAssignmentId: PropTypes.string.isRequired,
  setOpenRowId: PropTypes.func.isRequired,
};

export default ErpCustomerAssignmentRowCollapsed;
