import { useEffect, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { ICON, ICON_COLOR, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { fileListKey } from 'gcs-common/helper/mediaHelper';
import documentImage from 'gcs-common/img/document.svg';
import IconComponent from '../../../IconComponent/IconComponent';
import styles from './styles.module.scss';
import useTicketAttachmentFileDrop from '../../../../customHooks/useTicketAttachmentFileDrop';
import TicketErrorModal from '../../TicketAttachmentSizeErrorModal/TicketAttachmentSizeErrorModal';
import LocalFilePreview from '../../../LocalFilePreview/LocalFilePreview';
// eslint-disable-next-line import/no-cycle
import { TicketCommentFormValues } from '../TicketCommentForm/TicketCommentForm';

const TicketInputComment = () => {
  const { values } = useFormikContext<TicketCommentFormValues>();
  const commentRef = useRef<HTMLInputElement>(null);
  const [showAttachmentErrorModal, setShowAttachmentErrorModal] = useState(false);

  const onFileSizeError = () => setShowAttachmentErrorModal(true);

  const {
    getRootProps, getInputProps, isDragActive, open, removeFile,
  } = useTicketAttachmentFileDrop({
    fieldName: 'files',
    onFileSizeError,
    /**
     * We want to use the comment text input as drop target,
     * but clicking it should not open the file picker.
     * File picker should only be opened by clicking our custom + button.
     */
    noClick: true,
  });

  useEffect(() => {
    if (values.files.length > 0) {
      // focus comment text field when a file is attached,
      // so it is obvious for the user that they have to enter a text as well
      commentRef.current?.focus();
    }
  }, [commentRef, values.files.length]);

  return (
    <div className={styles.comment} {...getRootProps()}>
      <div className={styles.commentTextBox}>
        <input {...getInputProps()} />
        <button onClick={open} type="button" className={styles.attachmentButton}>
          <IconComponent Icon={ICON.PLUS} color={ICON_COLOR.DARK_GREY} />
        </button>
        <Field name="comment" type="text" className={styles.commentInputText} innerRef={commentRef} />
      </div>
      <div
        className={styles.previewContainer}
        style={{ display: isDragActive || values.files.length ? 'flex' : 'none' }}
      >
        {values.files.length > 0 && (
          <div className={styles.filesWrapper}>
            {values.files.map((file, fileIndex) => (
              // @ts-expect-error TODO add id to File
              <div key={fileListKey(file)} className={styles.previewWrapper}>
                <LocalFilePreview
                  file={file}
                  filePlaceHolder={documentImage}
                  className={styles.commentImages}
                />

                <div className={styles.buttonActions}>
                  <button onClick={() => removeFile(fileIndex)} type="button">
                    <IconComponent
                      Icon={ICON.CLOSE}
                      size={ICON_SIZE.SMALL}
                      color={ICON_COLOR.ERROR}
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        <div {...getRootProps()}>
          {isDragActive && (
          <div className={styles.previewPlaceholder}>
            Hochladen
          </div>
          )}
        </div>
        {showAttachmentErrorModal && (
          <TicketErrorModal
            onCancel={() => setShowAttachmentErrorModal(false)}
          />
        )
        }
      </div>
    </div>
  );
};

export default TicketInputComment;
