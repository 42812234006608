import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';

import { getChannelInputShowSuggestions } from 'gcs-common/slices/messageInput/messageInputSelector';
import { changeInputText } from 'gcs-common/slices/messageInput/messageInputThunks/changeInputText';
import styles from './styles.module.scss';

const suggestions = [{
  text: 'Ich bin dran. Ich geb dir Bescheid wenn ich mehr weiß.',
  message: 'Ich bin dran. Ich geb dir Bescheid wenn ich mehr weiß.',
}];

const MessageSuggestions = () => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const showSuggestions = useSelector(getChannelInputShowSuggestions(selectedChannelId));
  const dispatch = useDispatch();

  const onMessageChanged = useCallback((text) => () => {
    dispatch(changeInputText({ channelId: selectedChannelId, inputText: text }));
  }, [dispatch, selectedChannelId]);

  return (
    <AnimateHeight
      className={styles.suggestions}
      height={showSuggestions ? 'auto' : 0}
      duration={300}
    >
      {suggestions.map((suggestion => (
        <button
          key={suggestion}
          type="button"
          className={styles.suggestion}
          onClick={onMessageChanged(suggestion.message)}
        >
          {suggestion.text}
        </button>
      )
      ))}
    </AnimateHeight>
  );
};

export default MessageSuggestions;
