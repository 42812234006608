import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ChannelTypeInfo } from 'gcs-common/helper/channelTypeHelper';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getErpCustomerDirectChannels,
  getErpCustomerDirectChannelsErrorMessage,
  getErpCustomerDirectChannelsLoading,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import { fetchErpCustomerDirectChannels } from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import {
  deleteDirectChannel,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';
import {
  filterByErpCustomerIdSelected,
  resetErpCustomerDirectChannelsErrorMessage,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { showFailureNotification, showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import Table from '../../../Table/Table';
import addIcon from '../../../../img/plus_white.svg';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import ErpCustomerDirectChannelCreate from '../DirectChannelCreate/ErpCustomerDirectChannelCreate';
import DirectChannelDelete from '../../Channels/DirectChannelDelete/DirectChannelDelete';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import detailsIcon from '../../../../img/arrow.svg';
import deletedIcon from '../../../../img/delete.svg';
import LozengeCell from '../../../Table/LozengeCell/LozengeCell';
import { stringSortingFunction, channelTypeSortFunction } from '../../../../helper/sortHelper';

const erpCustomerColumns = [
  {
    Header: 'Typ',
    accessor: 'channelType',
    filter: 'fuzzyText',
    Cell: (cell) => {
      if (!cell.value) return null;
      return cell.value && LozengeCell({

        value: ChannelTypeInfo[cell.value] ? ChannelTypeInfo[cell.value].name : cell.value,
        color: ChannelTypeInfo[cell.value] ? ChannelTypeInfo[cell.value].color : '#827f7f',
      });
    },
    sortType: channelTypeSortFunction,
  },
  {
    Header: 'Teilnehmer',
    accessor: 'membersCount',
  },
  {
    Header: 'Nachrichten',
    accessor: 'messagesCount',
  },
  {
    Header: 'Letzte Nachricht gesendet',
    accessor: 'lastMessageDate',
    filter: 'fuzzyText',
    Cell: (cell) => {
      return cell.value ? moment(cell.value).format('ddd, D MMM YYYY HH:mm') : '';
    },
  },
  {
    Header: 'Erstellt von',
    accessor: 'createdBy',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Erstellt am',
    accessor: 'dateCreated',
    filter: 'fuzzyText',
    Cell: (cell) => {
      return cell.value ? moment(cell.value).format('ddd, D MMM YYYY HH:mm') : '';
    },
  },
];

// TODO Rename component
// it's now used to display both direct chats as well as internal craftsman
const ErpCustomerDirectChannelTable = ({ erpCustomerId,
  searchInputValue = '',
  channelTypes }) => {

  const erpCustomerDirectChannels = useSelector(getErpCustomerDirectChannels);
  const erpCustomerDirectChannelsLoading = useSelector(getErpCustomerDirectChannelsLoading);
  const erpCustomerDirectChannelsErrorMessage = useSelector(
    getErpCustomerDirectChannelsErrorMessage,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showChannelCreate, setShowChannelCreate] = useState(false);
  const [showChannelDelete, setShowChannelDelete] = useState(false);
  const hasGlobalAdminPermission = useSelector(getHasPermission('global_admin'));

  useEffect(() => {
    if (erpCustomerId) {
      dispatch(fetchErpCustomerDirectChannels({ erpCustomerId, channelTypes }));
    }
  }, [erpCustomerId, dispatch, channelTypes]);

  useEffect(() => {
    if (erpCustomerDirectChannelsErrorMessage) {
      dispatch(showFailureNotification(
        erpCustomerDirectChannelsErrorMessage,
        dispatch(resetErpCustomerDirectChannelsErrorMessage()),
      ));
    }
  }, [erpCustomerDirectChannelsErrorMessage, dispatch]);

  const deleteSelected = useCallback(
    (event, rowData) => {
      event.stopPropagation();
      const { original } = rowData;
      setShowChannelDelete(original);
    },
    [],
  );

  const directChannelCreated = useCallback(
    () => {
      setShowChannelCreate(false);
      dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
    },
    [dispatch],
  );

  const channelDeleted = useCallback(
    () => {
      setShowChannelDelete(null);
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
    },
    [dispatch],
  );

  const onChannelSelected = (channel) => {
    const { id } = channel;
    dispatch(filterByErpCustomerIdSelected({ erpCustomerId }));
    navigate(`/administration/erpCustomers/channel/${id}`);
  };

  const handleDelete = useCallback(
    async () => {
      const result = await dispatch(deleteDirectChannel({ channelId: showChannelDelete.id }));
      if (!result.error) {
        channelDeleted();
        // Note: Timeout to be sure that the changes are reflected in twilio mirror
        setTimeout(() => {
          dispatch(fetchErpCustomerDirectChannels({ erpCustomerId }));
        }, 1000);
      }
    },
    [erpCustomerId, showChannelDelete, dispatch, channelDeleted],
  );

  const message = 'Möchten Sie den Channel wirklich löschen?';

  return (
    <>
      <Table
        isLoading={erpCustomerDirectChannelsLoading}
        data={erpCustomerDirectChannels}
        columns={erpCustomerColumns}
        filterString={searchInputValue}
        onRowSelected={onChannelSelected}
        renderActions={({ row }) => (
          <>
            <button type="button">
              {/* eslint-disable-next-line max-len */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img
                alt="deleted-icon"
                src={deletedIcon}
                onClick={(event) => deleteSelected(event, row)}
              />
            </button>
            <img
              alt="details-icon"
              src={detailsIcon}
            />
          </>
        )}
      />
      {hasGlobalAdminPermission && (
        <AddFabButton
          title="Channel"
          icon={addIcon}
          onClick={() => setShowChannelCreate(true)}
        />
      )}
      {showChannelCreate && (
        <PopUpModal
          title="Direkten Channel erstellen"
          onRequestClose={() => setShowChannelCreate(false)}
          isOpen
        >
          <ErpCustomerDirectChannelCreate
            erpCustomerId={erpCustomerId}
            onCancel={() => setShowChannelCreate(false)}
            onSuccess={directChannelCreated}
          />
        </PopUpModal>
      )}
      {showChannelDelete && (
        <PopUpModal
          title="Channel löschen"
          onRequestClose={() => setShowChannelDelete(null)}
          isOpen
        >
          <DirectChannelDelete
            message={message}
            onCancel={() => setShowChannelDelete(null)}
            handleDelete={handleDelete}
          />
        </PopUpModal>
      )
      }
    </>
  );
};

ErpCustomerDirectChannelTable.propTypes = {
  searchInputValue: PropTypes.string,
  erpCustomerId: PropTypes.string.isRequired,
  channelTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ErpCustomerDirectChannelTable;
