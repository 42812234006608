import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFavorite } from '../favoritesSelectors';
import { removeFavoriteOffline } from '../favoritesActions/removeFavoriteOffline';

export const removeFavorite = createAsyncThunk(
  'favorites/remove',
  async ({ channelId, favoriteId }, { dispatch, getState }) => {

    const favoriteToBeDeleted = getFavorite(favoriteId)(getState());
    dispatch(removeFavoriteOffline({ favoriteToBeDeleted, favoriteId, channelId }));
  },
);
