import { createAsyncThunk } from '@reduxjs/toolkit';
import { TenantsViews } from '../../../constants/views';

export const fetchTenants = createAsyncThunk(
  'tenants/fetchTenants',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
  } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getTenants(
      pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId,
    );
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination };
  },
);

export const fetchTenantDetails = createAsyncThunk(
  'tenants/fetchTenantDetails',
  async ({ tenantId } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getTenantDetails(tenantId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { tenant: response };
  },
);


export const createTenant = createAsyncThunk(
  'tenants/createTenant',
  async ({ tenant } = {}, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.createTenant(tenant);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // TODO: Reload?
    return { tenant: response };
  },
);

export const updateTenant = createAsyncThunk(
  'tenants/updateTenant',
  async ({ tenantId, values }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateTenant(tenantId, values);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // TODO: Reload?
    return { tenant: response };
  },
);

export const deleteTenant = createAsyncThunk(
  'tenants/deleteTenant',
  async ({ tenantId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteTenant(tenantId);
    if (response.error_code && response.error_code !== 200) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    // TODO: Reload?
    return response;
  },
);

export const fetchTenantSelectOptions = createAsyncThunk(
  'tenants/fetchTenantSelectOptions',
  async (args, { extra: { gccApiClient } }) => {
    const response = await gccApiClient.getTenants(0, 1000, '', null, null, TenantsViews.SELECT_OPTIONS);
    return response?.data;
  },
);
