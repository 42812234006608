import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class ProcessMessageModel extends AbstractMessageModel {

  constructor({ process, ...args } = {}) {
    super({
      messageType: MESSAGE_TYPE.PROCESS,
      ...args,
    });

    this.process = process;
  }
}
