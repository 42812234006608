import { useDispatch, useSelector } from 'react-redux';
import { tenantSelected } from 'gcs-common/slices/administration/tenants/tenantsSlice';
import {
  getTenantsLoading,
  getTenantsPaginationResult,
} from 'gcs-common/slices/administration/tenants/tenantsSelectors';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import TenantTable from '../TenantTable/TenantTable';

const TenantOverview = () => {

  const tenantsLoading = useSelector(getTenantsLoading);
  const tenantsPagination = useSelector(getTenantsPaginationResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onTenantSelected = (tenant) => {
    const { id } = tenant;
    dispatch(tenantSelected({ tenantId: id }));
    navigate(`/administration/tenants/${id}`);
  };

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.inputHeaderText}>
          Tenants
          {' '}
          (
          {tenantsPagination && tenantsPagination.total}
          )
        </div>
        {tenantsLoading && <LoadingIndicator padding="0" />}
      </div>
      <TenantTable
        onTenantSelected={onTenantSelected}
        tenantsLoading={tenantsLoading}
      />
    </>
  );
};

export default TenantOverview;
