import { useState } from 'react';
import { ICON, ICON_SIZE } from 'gcs-common/constants/IconConstants';
import { useSelector } from 'react-redux';
import { getDasboardTicketsStatusCounts } from 'gcs-common/slices/tickets/ticketsSelectors';
import { TICKET_STATI } from 'gcs-common/slices/tickets/ticketsConstants';
import styles from './styles.module.scss';
import IconComponent from '../../../../IconComponent/IconComponent';
// eslint-disable-next-line import/no-cycle
import { FiltersValues } from '../../TicketDashboard';

interface StatusFilterProps {
  setStatusFilter: (values: Partial<FiltersValues>)=> void
}

const StatusFilter = (props:StatusFilterProps) => {
  const { setStatusFilter } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ticketCounts: { [key:string]: number } = useSelector(getDasboardTicketsStatusCounts());
  const [activeStatus, setActiveStatus] = useState('');
  const total: number = ticketCounts
    ? Object.values(ticketCounts).reduce((a: number, c: number) => a + c, 0)
    : 0;
  const setStatus = (status: string) => {
    setActiveStatus(status);
    setStatusFilter({ status });
  };
  return (
    <>
      {ticketCounts ? (
        <div className={styles.statusFilterContainer}>
          <button
            className={`${styles.statusTab} ${activeStatus === '' ? styles.activeTab : ''}`}
            onClick={() => setStatus('')}
            type="button"
          >
            Alle
            <span className={styles.ticketCount}>
              (
              {total}
              )
            </span>
          </button>
          {
          Object.entries(TICKET_STATI).map(([key, { title }]) => (
            <button
              key={key}
              className={`${styles.statusTab} ${activeStatus === key ? styles.activeTab : ''}`}
              onClick={() => setStatus(key)}
              type="button"
            >
              {title}
              <span className={styles.ticketCount}>
                (
                {ticketCounts[key]}
                )
              </span>
            </button>
          ))
        }
        </div>
      )
        : (
          <IconComponent
            Icon={ICON.LOADING_DOTS}
            size={ICON_SIZE.X_LARGE}
          />
        ) }

    </>
  );
};

export default StatusFilter;
