/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../../helper/errorMessages';
import {
  addUserToSelectedTeam, createTeam, deleteTeam,
  fetchTeams,
  fetchSelectedTeam,
  removeUserFromSelectedTeam,
  updateSelectedTeam,
} from './teamsThunks';
import ASYNC_STATE from '../../../constants/AsyncState';
import paginateReducer from '../../../helper/reduxPaginationHelper';
import { byId } from '../../../helper/byKey';

const asyncState = {
  fetchSelected: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  fetchTeams: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  addUserToSelected: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
    errorMessage: null,
  },
  removeUserFromSelected: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  delete: {
    status: ASYNC_STATE.IDLE,
    defaultErrorMessage: null,
  },
  create: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
  updateSelected: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
    defaultErrorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  teams: {},
  selectedTeamId: undefined,
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    teamSelected: (state, action) => {
      const { teamId } = action.payload;
      state.selectedTeamId = teamId;
    },
    teamSelectionReset: (state) => {
      state.selectedTeamId = undefined;
    },
    resetCreateTeamErrorMessage: (state) => {
      state.create.errorMessage = null;
    },
    resetCreateTeamDefaultErrorMessage: (state) => {
      state.create.defaultErrorMessage = null;
    },
    resetUpdatedTeamErrorMessage: (state) => {
      state.updateSelected.errorMessage = null;
    },
    resetUpdatedTeamDefaultErrorMessage: (state) => {
      state.updateSelected.defaultErrorMessage = null;
    },
    resetDeleteTeamDefaultErrorMessage: (state) => {
      state.delete.defaultErrorMessage = null;
    },
    resetRemovingUserFromSelectedTeamDefaultErrorMessage: (state) => {
      state.removeUserFromSelected.defaultErrorMessage = null;
    },
    resetAddUserToSelectedTeamDefaultErrorMessage: (state) => {
      state.addUserToSelected.defaultErrorMessage = null;
    },
    resetAddUserToSelectedTeamErrorMessage: (state) => {
      state.addUserToSelected.errorMessage = null;
    },
  },
  extraReducers: {
    [fetchTeams.pending]: (state) => {
      state.fetchTeams.status = ASYNC_STATE.LOADING;
    },
    [fetchTeams.fulfilled]: paginateReducer(
      (state) => state.fetchTeams,
      (state, action) => {
        const { data } = action.payload;
        state.fetchTeams.status = ASYNC_STATE.SUCCEEDED;
        state.teams = { ...byId(data) };
      },
    ),
    [fetchTeams.rejected]: (state, action) => {
      const { error } = action.error;
      state.fetchTeams.status = ASYNC_STATE.FAILED;
      state.fetchTeams.error = error;
    },
    [deleteTeam.pending]: (state) => {
      state.delete.status = ASYNC_STATE.LOADING;
    },
    [deleteTeam.fulfilled]: (state) => {
      state.delete.status = ASYNC_STATE.SUCCEEDED;
    },
    [deleteTeam.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.delete.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'delete_team.communication_assignments_exist') {
        state.delete.defaultErrorMessage = getErrorMsg('delete_team.communication_assignments_exist');
      } else if (errorCode && errorCode === 'delete_team.customer_responsibility_members_exist') {
        state.delete.defaultErrorMessage = getErrorMsg('delete_team.customer_responsibilities_exist');
      } else {
        state.delete.defaultErrorMessage = getErrorMsg('delete_team.default');
      }
    },
    [createTeam.pending]: (state) => {
      state.create.status = ASYNC_STATE.LOADING;
    },
    [createTeam.fulfilled]: (state) => {
      state.create.status = ASYNC_STATE.SUCCEEDED;
    },
    [createTeam.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.create.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'create_team.name_exists') {
        state.create.errorMessage = getErrorMsg('create_team.name_exists');
      } else if (errorCode && errorCode === 'create_team.role_exists_in_other_team_set_error_creation') {
        state.create.errorMessage = getErrorMsg('create_team.agent_belong_to_another_team_with_selected_role');
      } else {
        state.create.defaultErrorMessage = getErrorMsg('default');
      }
    },
    [updateSelectedTeam.pending]: (state) => {
      state.updateSelected.status = ASYNC_STATE.LOADING;
    },
    [updateSelectedTeam.fulfilled]: (state) => {
      state.updateSelected.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateSelectedTeam.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.updateSelected.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'update_team.name_exists') {
        state.updateSelected.errorMessage = getErrorMsg('update_team.name_exists');
      } else if (errorCode && errorCode === 'update_team.empty_attribute') {
        state.updateSelected.errorMessage = getErrorMsg('update_team.empty_attribute');
      } else if (errorCode && errorCode === 'update_team.role_exists_in_other_team_set_error') {
        state.updateSelected.errorMessage = getErrorMsg('update_team.agent_belong_to_another_team_with_selected_role');
      } else {
        state.updateSelected.defaultErrorMessage = getErrorMsg('update_team.default');
      }
    },
    [fetchSelectedTeam.pending]: (state) => {
      state.fetchSelected.status = ASYNC_STATE.LOADING;
    },
    [fetchSelectedTeam.fulfilled]: (state, action) => {
      const { team } = action.payload;
      const { id } = team;
      state.fetchSelected.status = ASYNC_STATE.SUCCEEDED;
      state.selectedTeamId = id;
      state.teams[id] = team;
    },
    [fetchSelectedTeam.rejected]: (state, action) => {
      state.fetchSelected.status = ASYNC_STATE.FAILED;
      state.fetchSelected.error = action.error;
    },
    [addUserToSelectedTeam.pending]: (state) => {
      state.addUserToSelected.status = ASYNC_STATE.LOADING;
    },
    [addUserToSelectedTeam.fulfilled]: (state, action) => {
      const { team } = action.payload;
      const { id } = team;
      state.addUserToSelected.status = ASYNC_STATE.SUCCEEDED;
      state.teams[id] = team;
    },
    [addUserToSelectedTeam.rejected]: (state, action) => {
      const {
        payload: { errorCode } = {},
      } = action;
      state.addUserToSelected.status = ASYNC_STATE.FAILED;
      if (errorCode && errorCode === 'update_team.agent_belong_to_team_with_same_role_error') {
        state.addUserToSelected.errorMessage = getErrorMsg('add_agent_to_team.agent_belong_to_another_team_with_same_role');
      } else {
        state.addUserToSelected.defaultErrorMessage = getErrorMsg('add_agent_to_team.default');
      }
    },
    [removeUserFromSelectedTeam.pending]: (state) => {
      state.removeUserFromSelected.status = ASYNC_STATE.LOADING;
    },
    [removeUserFromSelectedTeam.fulfilled]: (state, action) => {
      const { team } = action.payload;
      const { id } = team;
      state.removeUserFromSelected.status = ASYNC_STATE.SUCCEEDED;
      state.teams[id] = team;
    },
    [removeUserFromSelectedTeam.rejected]: (state) => {
      state.removeUserFromSelected.status = ASYNC_STATE.FAILED;
      state.removeUserFromSelected.defaultErrorMessage = getErrorMsg('delete_user_from_team.default');
    },
  },
});

export const {
  teamSelected,
  teamSelectionReset,
  resetCreateTeamErrorMessage,
  resetCreateTeamDefaultErrorMessage,
  resetUpdatedTeamErrorMessage,
  resetUpdatedTeamDefaultErrorMessage,
  resetDeleteTeamDefaultErrorMessage,
  resetRemovingUserFromSelectedTeamDefaultErrorMessage,
  resetAddUserToSelectedTeamDefaultErrorMessage,
  resetAddUserToSelectedTeamErrorMessage,
} = teamsSlice.actions;

export default teamsSlice.reducer;
