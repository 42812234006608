import { useGetCurrentUserIsLoggedIn } from 'gcs-common/clients/api/entities/current-user/current-user-api';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet } from 'react-router';
import { useHandleUniversalLoginWebRedirect } from 'gcs-common/hooks/auth/useAuth0WebBrowserLoginFlow';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';

/**
 * only purpose of this component is to wait for
 * the request that checks if the user is logged in to finish
 * this component is not concerned with the result of the request
 */
const AuthProtectedRoute = () => {
  useHandleUniversalLoginWebRedirect();

  const { isPending } = useGetCurrentUserIsLoggedIn();

  if (isPending) {
    return (
      <LoadingIndicatorFullScreen loadingText="Lädt..." />
    );
  }

  return <Outlet />;
};

export default AuthProtectedRoute;
