/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { searchChannelMessages } from './messageSearchThunks';
import ASYNC_STATE from '../../constants/AsyncState';

const asyncState = {
  searchChannelMessages: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  searchInput: '',
  resultMessageIds: [],
};

const messageSearchSlice = createSlice({
  name: 'messageSearch',
  initialState,
  reducers: {
    setMessageSearchInput: (state, action) => {
      const { searchInput } = action.payload;
      state.searchInput = searchInput;
    },
  },
  extraReducers: {
    // FETCH
    [searchChannelMessages.pending]: (state) => {
      state.searchChannelMessages.status = ASYNC_STATE.LOADING;
      state.searchChannelMessages.errorMessage = null;
    },
    [searchChannelMessages.fulfilled]: (state, action) => {
      const { resultMessageIds } = action.payload;
      state.searchChannelMessages.status = ASYNC_STATE.SUCCEEDED;
      state.resultMessageIds = resultMessageIds;
    },
    [searchChannelMessages.rejected]: (state) => {
      state.searchChannelMessages.status = ASYNC_STATE.FAILED;
      state.searchChannelMessages.errorMessage = 'Fehler beim Suchen';

    },
  },
});

export const {
  setMessageSearchInput,
} = messageSearchSlice.actions;

export default messageSearchSlice.reducer;
