import { useSelector } from 'react-redux';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getJoinedHousesIds, getSelectedHouseId } from 'gcs-common/slices/houses/housesSelectors';
import { getSimpleLogo } from 'gcs-common/slices/theme/themeSelectors';
import { useNavigate } from 'react-router';
import { CHAT_ROUTES } from 'gcs-common/constants/routes';
import styles from './styles.module.scss';
import administrationIconSelected from '../../img/company_white.svg';
import administrationIcon from '../../img/company_grey.svg';
import starIcon from '../../img/star_grey.svg';
import responsibilityIcon from '../../img/responsibility_grey.svg';
import responsibilityIconSelected from '../../img/responsibility_selected.svg';
import userIcon from '../../img/user_grey.svg';
import userIconSelected from '../../img/user_white.svg';
import houseIcon from '../../img/info_grey.svg';
import houseIconSelected from '../../img/info_white.svg';
import teamIcon from '../../img/teams_grey.svg';
import teamIconSelected from '../../img/teams_white.svg';
import Button from '../Button/Button';
import arrowBackIcon from '../../img/arrow_back.svg';
import AnimatedSidebarListItem from '../AnimatedSidebarListItem/AnimatedSidebarListItem';
import PermissionProtectedRoute from '../ProtectedRoutes/PermissionProtectedRoute';

const AdministrationSidebar = () => {

  const houseId = useSelector(getSelectedHouseId);
  const simpleLogo = useSelector(getSimpleLogo);
  const navigate = useNavigate();

  const isGlobalAdmin = useSelector(getHasPermission('global_admin'));
  const userHouses = useSelector(getJoinedHousesIds);
  const userHousesCount = userHouses?.length;

  const adminForMultipleHouses = isGlobalAdmin || userHousesCount > 1;

  const houseSideBarName = adminForMultipleHouses ? 'Häuser' : 'Hausinformationen';
  const housesLink = adminForMultipleHouses ? '/administration/houses' : '/administration/houses/'.concat(houseId);
  const goToChat = () => navigate(CHAT_ROUTES.CHAT);

  return (
    <div
      className={styles.administrationSidebarWrapper}
    >
      <div className={styles.settingsHeader}>
        <Button type="button" onClick={goToChat} className={styles.settingsBack}>
          <img src={arrowBackIcon} alt="Zurück" />
          <div>Administration</div>
        </Button>
        <img className={styles.gcLogoIcon} src={simpleLogo} alt="Logo" />
      </div>
      <div className={styles.rootSeparator} />
      <div className={styles.administrationSidebarItems}>
        {/* TODO: NEW */}
        <PermissionProtectedRoute permission={['global_admin']}>
          <AnimatedSidebarListItem
            to="/administration/tenants"
            name="Tenants"
            icon={administrationIcon}
            iconSelected={administrationIconSelected}
          />
        </PermissionProtectedRoute>
        <PermissionProtectedRoute permission={['global_admin']}>
          <AnimatedSidebarListItem
            to="/administration/business_units"
            name="Business Units"
            icon={administrationIcon}
            iconSelected={administrationIconSelected}
          />
        </PermissionProtectedRoute>
        {/* end new */}
        <AnimatedSidebarListItem
          to={housesLink}
          name={houseSideBarName}
          icon={houseIcon}
          iconSelected={houseIconSelected}
        />
        <AnimatedSidebarListItem
          to="/administration/erpCustomers"
          name="Kunden"
          icon={administrationIcon}
          iconSelected={administrationIconSelected}
        />
        <AnimatedSidebarListItem
          to="/administration/teams"
          name="Teams"
          icon={teamIcon}
          iconSelected={teamIconSelected}
        />
        <AnimatedSidebarListItem
          to="/administration/users"
          name="Mitarbeiter"
          icon={userIcon}
          iconSelected={userIconSelected}
        />
        <AnimatedSidebarListItem
          to="/administration/responsibilities"
          name="Verantwortlichkeiten"
          icon={responsibilityIcon}
          iconSelected={responsibilityIconSelected}
        />
        <PermissionProtectedRoute permission={['global_admin']}>
          <AnimatedSidebarListItem
            to="/administration/feature-flags"
            name="Feature Flags"
            icon={starIcon}
            iconSelected={starIcon}
          />
        </PermissionProtectedRoute>

      </div>
    </div>
  );

};

export default AdministrationSidebar;
