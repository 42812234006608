const administrationErrorMessages = {
  create_erp_customer: {
    existing: 'Eine Firma mit dieser Kundennummer und LDA existiert bereits.',
    default: 'Änderungen wurden nicht gespeichert. Bitte versuchen Sie es erneut.',
  },
  create_customer_responsibility_member: {
    default: 'Änderungen wurden nicht gespeichert. Bitte versuchen Sie es erneut.',
    role_for_customer_exists: 'Für diesen Kunden existiert bereits ein Mitarbeiter/Team mit der ausgewählten Rolle.'
      + ' Bitte löschen Sie erst die bestehende Verantwortlichkeit oder wählen Sie eine andere Rolle',
  },
  create_agent: {
    email_exists: 'In diesem Haus existiert bereits ein Mitarbeiter mit dieser E-Mail.',
  },
  update_house: {
    name_exists: 'Ein Haus mit diesem Namen existiert bereits.',
    craftnote_api_key_invalid: 'Der Craftnote API Key ist ungültig.',
    craftnote_organization_id_exists: 'Diese Craftnote Organization Id existiert bereits.',
    house_number_exists: 'Ein Haus mit einer der angegebenen Hausnummern existiert bereits',
    default: 'Änderungen wurden nicht gespeichert. Bitte versuchen Sie es erneut.',
  },
  create_house: {
    house_number_exists: 'Ein Haus mit einer der angegebenen Hausnummern existiert bereits',
    default: 'Änderungen wurden nicht gespeichert. Bitte versuchen Sie es erneut.',
  },
  create_team: {
    name_exists: 'Der Name existiert bereits.',
    agent_belong_to_another_team_with_selected_role: 'Einer der gewählten Mitarbeiter gehört bereits zu einem anderen Team mit der gleichen Beteiligtenrolle',
  },
  delete_team: {
    default: 'Das Team wurde nicht gelöscht. Bitte versuchen Sie es erneut.',
    communication_assignments_exist: 'Ein Team mit bestehenden Kommunikationszuweisungen kann nicht gelöscht werden',
    customer_responsibilities_exist: 'Ein Team mit bestehenden Verantwortlichkeiten kann nicht gelöscht werden',
  },
  add_agent_to_team: {
    default: 'Der Nutzer wurde nicht hinzugefügt. Bitte versuchen Sie es erneut.',
    agent_belong_to_another_team_with_same_role: 'Der Mitarbeiter gehört zu einem anderen Team mit der gleichen Beteiligtenrolle',
  },
  delete_user_from_team: {
    default: 'Der Nutzer wurde nicht entfernt. Bitte versuchen Sie es erneut.',
  },
  update_team: {
    name_exists: 'Der Name existiert bereits.',
    empty_attribute: 'Erforderlich',
    agent_belong_to_another_team_with_selected_role: 'Einer der Mitarbeiter gehört zu einem anderen Team mit der gleichen Beteiligtenrolle',
    default: 'Änderungen wurden nicht gespeichert. Bitte versuchen Sie es erneut.',
  },
  create_channel_for_company: {
    existing: 'Der Verkaufsmitarbeiter Channel existiert bereits.',
    default: 'Der Channel wurde nicht hinzugefügt. Bitte versuchen Sie es erneut.',
  },
  create_craftsman_craftsman_channel: {
    existing: 'Der Channel existiert bereits.',
    default: 'Der Channel wurde nicht hinzugefügt. Bitte versuchen Sie es erneut.',
  },
  create_channel_for_agent: {
    existing: 'Der Channel existiert bereits.',
    default: 'Der Channel wurde nicht hinzugefügt. Bitte versuchen Sie es erneut.',
  },
  delete_channel: {
    default: 'Der Channel wurde nicht gelöscht. Bitte versuchen Sie es erneut.',
  },
  update_group_channel_name: {
    channel_exists: 'Der Channel existiert bereits.',
    default: 'Der Nutzer wurde nicht entfernt. Bitte versuchen Sie es erneut.',
  },
  update_team_channel_name: {
    channel_exists: 'Der Channel existiert bereits.',
    default: 'Der Nutzer wurde nicht entfernt. Bitte versuchen Sie es erneut.',
  },
  add_user_to_team_channel: {
    default: 'Der Nutzer wurde nicht hinzugefügt. Bitte versuchen Sie es erneut.',
  },
  delete_communication_assignment: {
    default_assignment_delete: 'Automatisch generierte Kommunikationszuweisung kann nicht gelöscht werden',
    default: 'Die Kommunikationszuweisung wurde nicht entfernt. Bitte versuche es erneut.',
  },
  create_user_for_company: {
    phone_number_exists: 'Ein Nutzer mit dieser Mobilnummer existiert bereits.',
    email_exists: 'Ein Nutzer mit dieser E-Mail-Adresse existiert bereits.',
    default: 'Der Nutzer wurde nicht entfernt. Bitte versuchen Sie es erneut.',
  },
  data_base_integrity_violation: {
    assignment_role_team_unique: 'Das Team existiert bereits in der Kommunikationszuweisung.',
    assignment_role_agent_unique: 'Dem Mitarbeiter ist bereits eine Kommunikationsaufgabe zugewiesen.',
    assignment_role_resp_team_unique: 'Ein Team mit der Beteiligtenrolle existiert bereits in der Kommunikationszuweisung.',
    assignment_role_resp_agent_unique: 'Ein Mitarbeiter mit der Beteiligtenrolle existiert bereits in der Kommunikationszuweisung.',
  },
  default: 'Es ist ein allgemeiner Fehler aufgetreten. Falls das Problem weiterhin besteht, wenden Sie sich bitte an support@getbeem.de.',
};

export {
  // eslint-disable-next-line import/prefer-default-export
  administrationErrorMessages,
};
