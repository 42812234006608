/* eslint-disable css-modules/no-unused-class */
import styles from './styles.module.scss';

// Note: usually we dont use styled components, this is an exception
// as otherwise we would some hacky workarounds to style the react-select
// Lib: https://github.com/JedWatson/react-select

const selectCustomStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided, state) => {
    let backgroundColor = 'white';
    if (state.isFocused) {
      backgroundColor = styles.focusColor;
    }
    if (state.isSelected) {
      backgroundColor = styles.selectedColor;
    }
    let allStyle = {};
    if (state.data.id === 'ALL') {
      allStyle = {
        borderBottom: `2px solid ${styles.primaryColor}`,
      };
    }
    return {
      ...provided,
      backgroundColor,
      width: state.selectProps.optionsWidth,
      ...allStyle,
    };
  },
  control: (provided) => ({
    ...provided,
    minHeight: 'unset',
    border: 'unset',
    boxShadow: 'unset',
    backgroundColor: 'transparent',
    borderRadius: '0',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: styles.selectedColor,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    // TODO why
    backgroundColor: state.selectProps.optionBackgroundColor || styles.multivalueBackgroundColor,
    borderRadius: state.selectProps.optionBackgroundColor && '20px',
    height: state.selectProps.optionBackgroundColor && '30px',
    alignItems: state.selectProps.optionBackgroundColor && 'center',
    paddingLeft: state.selectProps.optionBackgroundColor && '0px',
  }),
  multiValueRemove: (provided, state) => (
    state.isDisabled ? {
      ...provided,
      display: 'none',
    } : {
      ...provided,
      color: state.selectProps.optionBackgroundColor || 'white',
      backgroundColor: state.selectProps.optionBackgroundColor || styles.multivalueBackgroundColor,
      borderRadius: state.selectProps.optionBackgroundColor && '50%',
      paddingLeft: state.selectProps.optionBackgroundColor && '0',
      paddingRight: state.selectProps.optionBackgroundColor && '0',
      marginRight: state.selectProps.optionBackgroundColor && '10px',
    }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    backgroundColor: state.data.color,
    color: 'white',
    minWidth: state.selectProps.optionBackgroundColor && '90%',
  }),
  dropdownIndicator: (provided, state) => (
    state.isDisabled ? {
      ...provided,
      display: 'none',
    } : provided
  ),
};

export default selectCustomStyles;
