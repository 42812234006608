import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';

const ExtraMembersChip = React.forwardRef(({
  extraMembersCount,
}, ref) => {
  return (
    <div className={styles.plusMark} ref={ref}>
      +
      {' '}
      {extraMembersCount}
    </div>
  );
});

ExtraMembersChip.propTypes = {
  extraMembersCount: PropTypes.number.isRequired,
};

export default ExtraMembersChip;
