import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChannelOldestLoadedMessageIndex, getMessageIdsForChannel } from '../../messages/messagesSelectors';
import fetchMessages from '../../messages/messagesThunks/fetchMessages';

const scrollToMessageId = createAsyncThunk(
  'chat/scrollToMessage',
  async ({ channelId, messageId, messageIndex }, { dispatch, getState }) => {
    const channelMessages = getMessageIdsForChannel(channelId)(getState());
    const oldestMessageIndex = getChannelOldestLoadedMessageIndex(channelId)(getState());

    if (channelMessages && channelMessages.includes(messageId)) {
      return { channelId, messageId };
    }
    await dispatch(fetchMessages({
      channelId,
      pageSize: 98, // TODO: SMOOT-3273
      fromMessageIndex: oldestMessageIndex,
      toMessageIndex: messageIndex,
    }));

    return { channelId, messageId };
  },
);

export default scrollToMessageId;
