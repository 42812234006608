/* eslint-disable camelcase */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ICON,
  ICON_TRANSFORM,
  ICON_COLOR,
} from 'gcs-common/constants/IconConstants';
import { setRightBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import {
  getChannelMemberCount,
  getIsSalesSpaceChannelType,
} from 'gcs-common/slices/channel/channelSelectors';
import { getRightBarTab } from 'gcs-common/slices/uiState/uiStateSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import IconComponent from '../IconComponent/IconComponent';
import styles from './styles.module.scss';

const ProfileHeader = ({
  title = '', channelId = '',
}) => {
  const dispatch = useDispatch();
  const isAgent = useSelector(getIsAgent);
  const rightBarTab = useSelector(getRightBarTab);
  const channelMemberCount = useSelector(getChannelMemberCount(channelId));
  const isSalesSpaceChannel = useSelector(getIsSalesSpaceChannelType(channelId));

  const onClose = useCallback(() => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.TOOLS }));
  }, [dispatch]);

  const onGoBack = () => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.CHANNEL_DETAILS }));
  };
  const showBackButton = rightBarTab === RIGHT_BAR_TABS.MEMBER_DETAILS
    && (channelMemberCount > 2 || isSalesSpaceChannel) && isAgent;

  return (
    <>
      <div className={styles.headerMemberDetailsWrapper}>
        <div className={styles.headerMemberButtons}>
          {showBackButton && (
            <button
              className={styles.backButton}
              onClick={onGoBack}
              type="button"
            >
              <IconComponent
                Icon={ICON.ARROW}
                transform={ICON_TRANSFORM.ROTATE_180}
                color={ICON_COLOR.DARKEST_GREY}
              />
            </button>
          )}
        </div>

        <div className={styles.headerMemberDetails}>{title}</div>
        <div className={styles.headerMemberButtons}>
          <button className={styles.closeButton} onClick={onClose} type="button">
            <IconComponent Icon={ICON.CLOSE} />
          </button>
        </div>
      </div>
    </>
  );
};

ProfileHeader.propTypes = {
  title: PropTypes.string,
  channelId: PropTypes.string,
};


export default ProfileHeader;
