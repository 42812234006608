import styles from './styles.module.scss';
import Icon from '../Icon/Icon';
import { ICON, ICON_SIZE } from '../../constants/IconConstants';

const InfoBox = ({ text }: { text: string }) => {
  return (
    <div className={styles.infoBoxContainer}>
      <div className={styles.iconContainer}>
        <Icon Icon={ICON.INFORMATION} size={ICON_SIZE.MEDIUM} color="black" alt="info" />

      </div>
      <div className={styles.textContainer}>
        {text}
      </div>
    </div>
  );
};

export default InfoBox;
