import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getErpCustomersLoading,
  getErpCustomersPaginationResult,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import { erpCustomerSelected } from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import { fetchSelectedHouse } from 'gcs-common/slices/administration/houses/housesThunks';
import { getActiveHouseId } from 'gcs-common/slices/administration/houses/housesSelectors';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import ErpCustomerTable from '../ErpCustomerTable/ErpCustomerTable';


const ErpCustomerOverview = () => {

  const activeHouseId = useSelector(getActiveHouseId);

  const erpCustomersLoading = useSelector(getErpCustomersLoading);
  const erpCustomersPagination = useSelector(getErpCustomersPaginationResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onErpCustomerSelected = (erpCustomer) => {
    const { id } = erpCustomer;
    dispatch(erpCustomerSelected({ erpCustomerId: id }));
    navigate(`/administration/erpCustomers/${id}`);
  };

  useEffect(() => {
    if (activeHouseId) {
      dispatch(fetchSelectedHouse({ houseId: activeHouseId }));
    }
  }, [dispatch, activeHouseId]);

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.inputHeaderText}>
          Kunden
          {' '}
          (
          {erpCustomersPagination && erpCustomersPagination.total}
          )
        </div>
        {erpCustomersLoading && <LoadingIndicator padding="0" />}
      </div>
      <ErpCustomerTable
        onErpCustomerSelected={onErpCustomerSelected}
        erpCustomersLoading={erpCustomersLoading}
      />
    </>
  );
};

export default ErpCustomerOverview;
