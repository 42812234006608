import { createAction } from '@reduxjs/toolkit';

export const sendMessagesRollback = createAction('sendMessagesRollback', (payload) => ({ meta: payload }));

export const sendMessagesCommit = createAction('sendMessagesCommit', (payload) => ({ meta: payload }));

export const sendMessagesOffline = createAction('sendMessagesOffline', (payload) => {

  const meta = {
    ...payload,
  };

  return {
    meta: {
      ...meta,
      offline: {
        commit: sendMessagesCommit(meta),
        rollback: sendMessagesRollback(meta),
      },
    },
  };
});
