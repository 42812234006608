import { SalesSpaceChannelGrouping, SalesSpaceChannelOrder, SalesSpaceChannelsInGroupingSort } from '../../../helper/channelOrderingHelper';
import {
  setSelectedSalesSpaceChannelOrder,
  setSelectedSalesSpaceChannelsGrouping,
  setSelectedSalesSpaceChannelsInGroupSort,
} from '../salesSpaceChannelsSlice';


const updateSelectedSalesSpaceChannelOrder = ({ newChannelOrder }) => (dispatch) => {
  // eslint-disable-next-line default-case
  switch (newChannelOrder) {
    case (SalesSpaceChannelOrder.ALPHABETICAL): {
      dispatch(setSelectedSalesSpaceChannelOrder(
        { selectedSalesSpaceChannelOrder: SalesSpaceChannelOrder.ALPHABETICAL },
      ));
      dispatch(setSelectedSalesSpaceChannelsGrouping({
        selectedSalesSpaceChannelsGrouping: SalesSpaceChannelGrouping.NONE,
      }));
      dispatch(setSelectedSalesSpaceChannelsInGroupSort({
        selectedSalesSpaceChannelsInGroupSort: SalesSpaceChannelsInGroupingSort.ALPHABETICAL,
      }));
      break;
    }
    case (SalesSpaceChannelOrder.NEW_MESSAGE): {
      dispatch(setSelectedSalesSpaceChannelOrder(
        { selectedSalesSpaceChannelOrder: SalesSpaceChannelOrder.NEW_MESSAGE },
      ));
      dispatch(setSelectedSalesSpaceChannelsInGroupSort(
        { selectedSalesSpaceChannelsInGroupSort: SalesSpaceChannelsInGroupingSort.DATE },
      ));
      dispatch(setSelectedSalesSpaceChannelsGrouping({
        selectedSalesSpaceChannelsGrouping: SalesSpaceChannelGrouping.DATE,
      }));
      break;
    }
  }
};

export default updateSelectedSalesSpaceChannelOrder;
