import { createSelector } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../constants/AsyncState';

const getChatSubstate = state => state.chat;
export const getScrollDownLock = createSelector(
  getChatSubstate,
  (chatSubstate) => chatSubstate.scrollDownLock,
);

export const getShouldScrollDownChat = createSelector(
  getChatSubstate,
  (chatSubstate) => chatSubstate.shouldScrollDownChat,
);

export const getShouldScrollToMessageId = createSelector(
  getChatSubstate,
  (chatSubstate) => chatSubstate.shouldScrollToMessageId,
);

export const getSearchScrollMessageLoading = createSelector(
  getChatSubstate,
  (chatSubstate) => chatSubstate.scrollToMessageId.status === ASYNC_STATE.LOADING,
);

export const getSearchScrollMessageError = createSelector(
  getChatSubstate,
  (chatSubstate) => chatSubstate.scrollToMessageId.errorMessage,
);
