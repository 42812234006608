import * as SentryReact from '@sentry/react';
import md5 from 'crypto-js/md5';
import { setGcopPassword } from '../slices/currentCraftsmanUser/currentCraftsmanUserSlice';
import { hashSensitiveData } from '../helper/hashSensitiveDataHelper';
import { getIsAgent } from '../slices/currentUser/currentUserSelectors';
import { channelInputTextChanged } from '../slices/messageInput/messageInputSlice';
import { sendMessagesOffline } from '../slices/messageInput/messageInputActions/sendMessagesOffline';

// Configuration of
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
export const sentryReduxEnhancer = SentryReact.createReduxEnhancer({
  actionTransformer: action => {
    switch (action.type) {
      case setGcopPassword.type:
      case channelInputTextChanged.type:
      case sendMessagesOffline.type:
        return {
          ...action,
          payload: null,
        };
      default:
        return {
          ...action,
          payload: hashSensitiveData(action.payload),
        };
    }
  },
  stateTransformer: state => {
    // Note: Whitelisted state to send on error (max: 100kb ?)
    return {
      theme: state.theme,
    };
  },
  configureScopeWithState: (scope, state) => {
    if (state.currentUser?.currentUser && state.currentUser.currentUser.id) {
      const user = {
        id: state.currentUser.currentUser.id,
        twilioId: md5(state.currentUser.currentUser.externalIdentity).toString(),
      };
      if (state.currentUser.currentUser.email) {
        user.email = md5(state.currentUser.currentUser.email).toString();
      }
      scope.setUser(user);
      scope.setTag('isAgent', !!getIsAgent(state));
    }
    if (state.channels?.selectedChannelId) {
      scope.setTag('selectedChannelId', state.channels.selectedChannelId);
    }
  },
});
