/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
import { createSelector } from '@reduxjs/toolkit';
import {
  getChannelNewestMessageIsFromMe,
} from '../messages/messagesSelectors';
import {
  getChannelCorrespondent,
  getIsGroupChannel,
} from '../channel/channelSelectors';
import { getIsAgent } from '../currentUser/currentUserSelectors';
import FORWARD_TARGET from '../../constants/ForwardTarget';

const getChannelInputs = state => state.messageInput.channelInputs;

export const getIsSendingMessages = state => state.messageInput.sendCurrentMessageState.loading !== 'idle';

export const getChannelInput = channelId => createSelector(
  [getChannelInputs],
  (channelInputs) => {
    return channelInputs[channelId] || {};
  },
);

export const getSelectedMessageIds = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.selectedMessageIds || [];
  },
);

export const getSelectedMessageIdsForwardTarget = (channelSid) => createSelector(
  [getChannelInput(channelSid)],
  (channelState) => {
    return channelState.selectedMessageIdsForwardingTarget || FORWARD_TARGET.CHAT;
  },
);

export const getChannelInputText = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.text || '';
  },
);

export const getChannelInputPlaceholderText = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.textPlaceholder || '';
  },
);

export const getChannelInputIsSendingFiles = (channelId) => createSelector(
  [getChannelInputStagedFiles(channelId), getIsSendingMessages],
  (files, isSendingMessages) => {
    return !!(isSendingMessages && files && files.length > 0);
  },
);

export const getChannelInputIsSendingForwardMessages = (channelId) => createSelector(
  [getChannelInputStagedForwardMessageIds(channelId), getIsSendingMessages],
  (stagedForwardMessages, isSendingMessages) => {
    return !!(isSendingMessages && stagedForwardMessages.length > 0);
  },
);

export const getChannelInputStagedMessageSubType = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.stagedMessageSubType;
  },
);

export const getChannelInputForwardRecipientChannelId = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.stagedForwardRecipientsChannelIds || [];
  },
);

export const getChannelInputStagedProcess = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.stagedProcess;
  },
);

export const getChannelInputStagedProduct = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.stagedProduct;
  },
);

export const getChannelInputStagedOrder = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.stagedOrder;
  },
);

export const getChannelInputQuotedMessage = (channelId) => createSelector(
  [
    getChannelInputStagedReplyMessageId(channelId),
    getChannelInputStagedForwardMessageId(channelId)],
  (stagedReplyMessageId, stagedForwardMessageId) => {
    return stagedReplyMessageId || stagedForwardMessageId;
  },
);

export const getChannelInputStagedReplyMessageId = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.stagedReplyMessageId;
  },
);

export const getChannelInputStagedForwardMessageId = (channelId) => createSelector(
  [getChannelInputStagedForwardMessageIds(channelId)],
  (stagedForwardMessageIds) => {
    if (stagedForwardMessageIds.length === 1) {
      return stagedForwardMessageIds[0];
    }
    return null;
  },
);

export const getChannelInputStagedForwardMessageIds = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => {
    return channelState.stagedForwardMessageIds || [];
  },
);

export const getChannelInputStagedFiles = (channelId) => createSelector(
  [getChannelInput(channelId)],
  (channelState) => channelState.stagedFiles || [],
);

export const getChannelInputIsSendingDisabled = (channelId) => createSelector(
  [
    getChannelInputIsSendingFiles(channelId),
    getChannelInputIsSendingForwardMessages(channelId),
    getChannelInputStagedForwardMessageIds(channelId),
    getChannelInputForwardRecipientChannelId(channelId),
  ],
  (
    isSendingFiles,
    isSendingForwardMessage,
    stagedForwardMessageIds,
    otherRecipientIds,
  ) => {
    if (isSendingForwardMessage) {
      return true;
    }

    if (stagedForwardMessageIds.length) {
      return otherRecipientIds && otherRecipientIds.length < 1;
    }
    return isSendingFiles;
  },
);

export const getChannelInputShowSuggestions = (channelId) => createSelector(
  [
    getIsAgent,
    getChannelInputStagedProcess(channelId),
    getChannelInputStagedProduct(channelId),
    getChannelInputStagedFiles(channelId),
    getChannelInputStagedForwardMessageIds(channelId),
    getChannelInputStagedReplyMessageId(channelId),
    getChannelInputText(channelId),
    getChannelNewestMessageIsFromMe(channelId),
  ],
  (
    isAgent,
    process,
    product,
    files,
    stagedForwardMessageIds,
    stagedReplyMessageId,
    inputText,
    lastMessageFromMe,
  ) => {
    if (!isAgent) {
      return false;
    }
    return !(
      process
      || product
      || files.length > 0
      || stagedForwardMessageIds.length > 0
      || stagedReplyMessageId
      || inputText.length > 0
      || lastMessageFromMe
    );
  },
);

export const getChannelInputDisabled = (channelId) => createSelector(
  [
    getChannelInputIsSendingFiles(channelId),
    getChannelInputIsSendingForwardMessages(channelId),
  ],
  (
    isSendingFiles,
    isSendingForwardMessage,
  ) => {
    if (isSendingForwardMessage) {
      return true;
    }
    return isSendingFiles;
  },
);

export const getChannelInputPlaceholder = (channelId) => createSelector(
  [
    getChannelCorrespondent(channelId),
    getIsGroupChannel(channelId),
    getChannelInputStagedForwardMessageIds(channelId),
    getChannelInputPlaceholderText(channelId),
  ],
  (
    channelCorrespondent,
    isGroupChannel,
    forwardChannelId,
    placeholderText,
  ) => {
    if (placeholderText) {
      return placeholderText;
    }

    const correspondentName = channelCorrespondent && channelCorrespondent.friendlyName;

    if (forwardChannelId || isGroupChannel) {
      return 'Schreibe etwas ...';
    }

    return `Schreibe etwas ${correspondentName ? `@${correspondentName}` : '...'}`;
  },
);
