/* eslint-disable no-param-reassign, import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthReduxState = {
  initialLogin: boolean;
};

const initialState : AuthReduxState = {
  initialLogin: false,
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsInitialLogin: (state, action : PayloadAction<boolean>) => {
      state.initialLogin = action.payload;
    },
  },
});


export const {
  setIsInitialLogin,
} = authSlice.actions;

export default authSlice.reducer;
