import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsDeletingGroupChannelLoading,
  getDeleteGroupChannelDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSelectors';

import {
  resetDeleteGroupChannelDefaultErrorMessage,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSlice';
import styles from './styles.module.scss';
import Button from '../../../Button/Button';
import { showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';

const GroupChannelDelete = ({ onCancel, handleDelete, message }) => {

  const isDeleting = useSelector(getIsDeletingGroupChannelLoading);
  const dispatch = useDispatch();
  const defaultErrorMessage = useSelector(getDeleteGroupChannelDefaultErrorMessage);

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetDeleteGroupChannelDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetDeleteGroupChannelDefaultErrorMessage());
  }, [dispatch]);

  return (
    <div className={styles.column}>
      {message}
      <div className={styles.buttonRow}>
        <Button
          className={styles.modalButtonSecondary}
          type="button"
          disabled={isDeleting}
          onClick={onCancel}
        >
          Abbrechen
        </Button>
        <Button
          className={styles.modalButton}
          type="button"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          Löschen
        </Button>
      </div>
    </div>
  );
};

GroupChannelDelete.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default GroupChannelDelete;
