import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadTwilioCurrentUser } from '../../chatConnection/chatConnectionThunks/twilioLoadingThunk';

const fetchCurrentUser = createAsyncThunk(
  'currentUser/fetch',
  async (_, { dispatch, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getCurrentUser();
    const currentUserData = { currentUser: response };
    // Resubscribe to twilio entities and load data to state
    dispatch(loadTwilioCurrentUser(currentUserData));
    return currentUserData;
  },
);

export default fetchCurrentUser;
