const USER_TYPE = {
  AGENT: 'AGENT',
  CRAFTSMAN: 'CRAFTSMAN',
};

const UserTypeInfo = {
  [USER_TYPE.AGENT]: {
    name: 'Mitarbeiter',
    color: '#42921f',
  },
  [USER_TYPE.CRAFTSMAN]: {
    name: 'Kunde',
    color: '#971c58',
  },
};

const USER_PERMISSIONS = {
  HOUSE_ADMIN: {
    friendlyName: 'Haus Admin',
    value: 'administration',
  },
  GLOBAL_ADMIN: {
    friendlyName: 'Globaler Admin',
    value: 'global_admin',
  },
};

export {
  UserTypeInfo,
  USER_PERMISSIONS,
};

export default USER_TYPE;
