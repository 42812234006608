import PropTypes from 'prop-types';
import ModalWithCloseButton from '../../PopUpModal/ModalWithCloseButton/ModalWithCloseButton';
import styles from './styles.module.scss';

const CommuniactionStructureModal = ({
  title, children, isOpen, onRequestClose, zIndex = 99999,
}) => {
  return (
    <ModalWithCloseButton
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      className={styles.communicationModal}
      zIndex={zIndex}
    >
      {children}
    </ModalWithCloseButton>
  );

};

CommuniactionStructureModal.propTypes = {
  zIndex: PropTypes.number,
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default CommuniactionStructureModal;
