import { ReactNode, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentUser,
} from 'gcs-common/slices/currentUser/currentUserSelectors';
import LoadingIndicatorFullScreen from 'gcs-common/components/LoadingIndicatorFullScreen/LoadingIndicatorFullScreen';
import { initAppFetchData } from '../../slices/initialization/initializationThunks';
import {
  getInitAppFetchDataFailed,
  getInitAppFetchDataLoading,
} from '../../slices/initialization/initializationSelectors';

interface UserProtectedRouteProps {
  children: ReactNode;
}

/**
 * Fetch the user from the API.
 * Only render children components when the user is loaded.
 */
const UserProtectedRoute = (props: UserProtectedRouteProps) => {
  const { children } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentUser = useSelector(getCurrentUser);
  const initAppFetchDataLoading = useSelector(getInitAppFetchDataLoading);
  const initAppFetchDataFailed = useSelector(getInitAppFetchDataFailed);

  useEffect(() => {
    dispatch(initAppFetchData());
  }, [dispatch]);

  if (initAppFetchDataLoading) {
    return (
      <LoadingIndicatorFullScreen loadingText="Lädt User..." />
    );
  }

  if (initAppFetchDataFailed || !currentUser) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default UserProtectedRoute;
