import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getOtherMemberTyping } from 'gcs-common/slices/channel/channelSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getLatestMessageIdForSelectedChannel, getMessagesForChannel } from 'gcs-common/slices/messages/messagesSelectors';
import TypingIndicator from '../TypingIndicator/TypingIndicator';

const TypingIndicatorWrapper = ({ messageId }) => {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const otherUserTyping = useSelector(getOtherMemberTyping(selectedChannelId));
  const latestMessageId = useSelector(getLatestMessageIdForSelectedChannel);
  const isLatestMessage = messageId === latestMessageId;
  const messagesOnChat = useSelector(getMessagesForChannel(selectedChannelId));

  if (otherUserTyping && (isLatestMessage || !messagesOnChat)) {
    return <TypingIndicator />;
  }
  return null;
};

TypingIndicatorWrapper.propTypes = {
  messageId: PropTypes.string,
};

export default TypingIndicatorWrapper;
