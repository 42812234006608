/* eslint-disable no-console */
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import useUsercentrics from 'gcs-common/hooks/useUsercentrics';
import useTheme from 'gcs-common/hooks/useTheme';
import { getUsercentricsId } from 'gcs-common/slices/theme/themeSelectors';
import { Outlet } from 'react-router-dom';
import useTrackLocationChange from 'gcs-common/hooks/useTrackLocationChange';
import useShowErrorDialogFromSearchParams from 'gcs-common/hooks/useShowErrorDialogFromSearchParams';
import DebugUserSwitcher from 'gcs-common/components/DebugUserSwitcher/DebugUserSwitcher';
import styles from './styles.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { globalInit } from '../../slices/initSlice/initThunks';
import ChangeTheme from '../ChangeTheme/ChangeTheme';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import EditImageModal from '../EditImageModal/EditImageModal';
import WebcamModal from '../WebcamModal/WebcamModal';
import MetaTags from '../MetaTags/MetaTags';

const App = () => {
  const usercentricsId = useSelector(getUsercentricsId);

  useUsercentrics(usercentricsId);
  useTrackLocationChange();
  useTheme();
  useShowErrorDialogFromSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalInit());
  }, [dispatch]);

  return (
    <div className={styles.root}>
      <ChangeTheme />
      <DebugUserSwitcher />
      <MetaTags />
      <ToastContainer icon={false} theme="colored" autoClose={false} className={styles.toastContainer} />
      <Outlet />
      <ErrorDialog />
      <EditImageModal />
      <WebcamModal />
    </div>
  );

};

export default App;
