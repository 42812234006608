import { useEffect } from 'react';

/**
 * Custom hook that triggers a callback function when the ESC key is pressed.
 */
function useEscKey(onEscKeyPress) {
  useEffect(() => {
    const handleEscKeyPress = (event) => {
      if (event.key === 'Escape') {
        onEscKeyPress();
      }
    };

    window.addEventListener('keydown', handleEscKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [onEscKeyPress]);
}

export default useEscKey;
