import { useContext } from 'react';
import {
  MutationCache, onlineManager, QueryCache, QueryClient,
} from '@tanstack/react-query';
// eslint-disable-next-line import/no-cycle
import { ApiContext } from '../../provider/ApiProvider';

export const useApiClient = () => {
  const client = useContext(ApiContext);
  if (client != null) {
    return client;
  }
  throw Error('ApiClient not available.');
};

export const cacheTime = 1000 * 60 * 60 * 24 * 21;
const MUTATIONS_GLOBAL_SCOPE = 'MUTATIONS_GLOBAL_SCOPE';

// The buster is used to identify the current version of the current persisted store.
// When the buster changes react query will discard the old store and create a new one.
// Will be used when introducing breaking code changes that are incompatible with old data that
// is currently stored so that it does not use the old data.
export const BEEM_PERSISTED_STORE_BUSTER = 'beem-persisted-store-v1';

export const reactQueryClient = new QueryClient({
  queryCache: new QueryCache({}),
  mutationCache: new MutationCache({}),
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30,
      gcTime: cacheTime,
    },
    mutations: {
      // Scope is added to enforce that mutations are fired sequentially AND only fired
      // after the previous ones are done to avoid a race condition
      // Crucial for optimistic updates and offline persistence
      scope: {
        id: MUTATIONS_GLOBAL_SCOPE,
      },
      retry: () => !onlineManager.isOnline(),
    },
  },
});
