import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentUserId } from 'gcs-common/slices/currentUser/currentUserSelectors';
import React, { Suspense } from 'react';

const MuxPlayer = React.lazy(() => import('@mux/mux-player-react'));

const VideoStreamPlayer = ({ videoId }) => {
  const currentUserId = useSelector(getCurrentUserId);

  return (
    <Suspense fallback="...">
      <MuxPlayer
        streamType="on-demand"
        playbackId={videoId}
        metadata={{
          viewer_user_id: { currentUserId },
        }}
        style={{ width: '100%', height: '100%' }}
        autoPlay
      />
    </Suspense>
  );
};

export default VideoStreamPlayer;
VideoStreamPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
};
