import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  getCommunicationAssignment,
  getIsCommunicationAssignmentGeneratedFromStructure,
  getCreateCommunicationAssignmentDefaultError,
  getUpdateCommunicationAssignmentDefaultError,
  getCommunicationAssignmentMembersGeneratedFromStructure,
  getCommunicationAssignmentMembersGeneratedByUser,
  getAssignmentMembersOptions,
  getMainContactPersonOptions,
  getCommunicationAssignmentMainAgent,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsSelectors';
import {
  getCommunicationAssignmentMemberRole,
  getCommunicationAssignmentMainRole,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureSelectors';
import {
  resetCreateCustomerAssignmentDefaultErrorMessage,
  resetUpdateCustomerAssignmentDefaultErrorMessage,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsSlices';
import { getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import { getSelectedHouseDetails } from 'gcs-common/slices/administration/houses/housesSelectors';
import {
  createErpCustomerAssignment,
  fetchErpCustomerAssignments,
  updateErpCustomerAssignment,
  deleteErpCustomerAssignment,
} from 'gcs-common/slices/administration/erpCustomerAssignments/erpCustomerAssignmentsThunks';
import {
  CommunicationAssignmentType,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructuresConstants';
import { USER_PERMISSIONS } from 'gcs-common/helper/userTypeHelper';
import { VALUE_KEY } from 'gcs-common/constants/dropdownSelectConstants';
import RowOpen from '../../shared/RowOpen/RowOpen';
import {
  showFailureNotification, showSuccessNotification,
} from '../../../../slices/notifications/ShowNotificationThunk';

const diff = (a, b) => a.filter(x => !b.includes(x));

const ErpCustomerAssignmentRowOpen = ({
  communicationAssignmentId = '',
  erpCustomerId,
  setOpenRowId,
  isEditForm = false,
  isTeamRow = false,
  setNewCommunicationStructureAssignmentType,
  isModalOpen = false,
  onDeletion,
}) => {

  const dispatch = useDispatch();

  const hasHouseAdminPermission = useSelector(getHasPermission(USER_PERMISSIONS.HOUSE_ADMIN.value));
  // eslint-disable-next-line max-len
  const hasGlobalAdminPermission = useSelector(getHasPermission(USER_PERMISSIONS.GLOBAL_ADMIN.value));

  const validationSchema = useMemo(() => Yup.object().shape({
    channelName: isTeamRow ? Yup.string().required('Erforderlich') : undefined,
    assignmentDirection: Yup.string().nullable().required('Erforderlich'),
    mainContact: isTeamRow && Yup.string().nullable().when('channelImage', {
      is: (role) => !role,
      then: schema => schema.required(
        'Wenn es keinen Hauptkontakt gibt, müssen Sie ein Titelbild auswählen. Das Titelbild wird für alle Teamchats angezeigt.',
      ),
      otherwise: schema => schema,
    }),
    defaultAssignmentMembers: Yup.array().of(Yup.string()),
    customAssignmentMembers: Yup.array().of(Yup.string())
      .when(['mainContact', 'defaultAssignmentMembers'], {
        is: (mainContact, defaultAssignmentMembers) => {
          return (isTeamRow && (!mainContact?.length)
                 && !defaultAssignmentMembers?.length);
        },
        then: (schema) => {
          return schema.min(1, 'Entweder Hauptkontakt oder Benutzerdefinierte Kontakte sind erforderlich');
        },
        otherwise: (schema) => schema,
      })
      .when('defaultAssignmentMembers', {
        is: (defaultAssignmentMembers) => !isTeamRow && !defaultAssignmentMembers?.length,
        then: (schema) => schema.min(1, 'Mindestens ein Mitarbeiter oder Team ist erforderlich'),
        otherwise: (schema) => schema,
      }),
    channelImage: isTeamRow ? Yup.string().nullable() : undefined,
    isCraftnoteAutogenerate: Yup.bool().nullable(),
  }, ['mainContact', 'customAssignmentMembers', 'channelImage', 'defaultAssignmentMembers']), [isTeamRow]);

  const assignmentMainAgent = useSelector(getCommunicationAssignmentMainAgent(
    communicationAssignmentId,
  ));

  const mainContactPerson = useMemo(() => {
    return assignmentMainAgent || null;
  }, [assignmentMainAgent]);

  const communicationAssignmentMemberRole = useSelector(getCommunicationAssignmentMemberRole);
  const communicationAssignmentMainRole = useSelector(getCommunicationAssignmentMainRole);

  const assignment = useSelector(getCommunicationAssignment(communicationAssignmentId));
  const isAssignmentGeneratedFromStructure = useSelector(
    getIsCommunicationAssignmentGeneratedFromStructure(communicationAssignmentId),
  );

  const defaultAssignmentMembers = useSelector(
    getCommunicationAssignmentMembersGeneratedFromStructure(communicationAssignmentId),
  );

  const customAssignmentMembers = useSelector(getCommunicationAssignmentMembersGeneratedByUser(
    communicationAssignmentId,
  ));

  const defaultAssignmentMemberIds = defaultAssignmentMembers.map(member => member[VALUE_KEY]);
  const customAssignmentMemberIds = customAssignmentMembers.map(member => member[VALUE_KEY]);
  const mainContactPersonId = mainContactPerson ? mainContactPerson[VALUE_KEY] : null;

  const createDefaultErrorMessage = useSelector(getCreateCommunicationAssignmentDefaultError);
  const updateDefaultErrorMessage = useSelector(getUpdateCommunicationAssignmentDefaultError);

  const {
    channelName,
    channelImage,
    direction,
    isCraftnoteAutogenerate,
  } = assignment || {};

  const selectedHouse = useSelector(getSelectedHouseDetails);
  const craftnoteId = selectedHouse?.craftnoteOrganizationId;

  const assignmentMemberOptions = useSelector(
    getAssignmentMembersOptions({ erpCustomerId }),
  );

  const mainContactPersonOptions = useSelector(
    getMainContactPersonOptions({ erpCustomerId }),
  );

  const formikInitialValues = useMemo(() => {
    return ({
      isEditForm,
      channelName: channelName || '',
      channelImage,
      assignmentDirection: direction,
      isCraftnoteAutogenerate: (
        isCraftnoteAutogenerate !== undefined ? isCraftnoteAutogenerate : !!craftnoteId
      ),
      defaultAssignmentMembers: defaultAssignmentMemberIds,
      customAssignmentMembers: customAssignmentMemberIds,
      mainContact: mainContactPersonId,
    });
  }, [
    isEditForm,
    channelName,
    channelImage,
    direction,
    isCraftnoteAutogenerate,
    craftnoteId,
    defaultAssignmentMemberIds,
    customAssignmentMemberIds,
    mainContactPersonId,
  ]);

  useEffect(() => {
    if (createDefaultErrorMessage || updateDefaultErrorMessage) {
      dispatch(showFailureNotification(
        createDefaultErrorMessage || updateDefaultErrorMessage,
        () => {
          dispatch(resetCreateCustomerAssignmentDefaultErrorMessage());
          dispatch(resetUpdateCustomerAssignmentDefaultErrorMessage());
        },
      ));
    }
  }, [createDefaultErrorMessage, updateDefaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetCreateCustomerAssignmentDefaultErrorMessage());
    dispatch(resetUpdateCustomerAssignmentDefaultErrorMessage());
  }, [dispatch]);

  const onExpandButtonClick = useCallback(() => {
    setOpenRowId(null);
    setNewCommunicationStructureAssignmentType(null);
  }, [setNewCommunicationStructureAssignmentType, setOpenRowId]);

  const memberOptionsHelper = (memberIds, options, roleId) => {
    return memberIds
      .filter(id => id !== null)
      .map(id => {
        const memberOption = options.find(option => option[VALUE_KEY] === id);
        return {
          ...memberOption,
          communicationAssignmentRoleId: roleId,
        };
      });
  };

  const deleteAssignment = useCallback(async (assignmentId) => {
    const result = await dispatch(deleteErpCustomerAssignment({ assignmentId }));
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich entfernt'));
      onDeletion();
    } else {
      dispatch(showFailureNotification('Fehler beim Löschen'));
    }
  }, [dispatch, onDeletion]);

  const submit = useCallback(
    async (values, { setSubmitting }) => {

      if (isEditForm) {
        const communicationAssignmentAttributes = [
          'channelName',
          'channelImage',
          'assignmentDirection',
          'isCraftnoteAutogenerate',
        ];
        const updatedAssignmentValues = communicationAssignmentAttributes.reduce((red, attr) => {
          if (values[attr] !== assignment[attr]) {
          // eslint-disable-next-line no-param-reassign
            red[attr] = values[attr];
          }
          return red;
        }, {});

        const newMemberIds = diff(values.customAssignmentMembers, customAssignmentMemberIds);
        const newMemberOptions = memberOptionsHelper(
          newMemberIds,
          assignmentMemberOptions,
          communicationAssignmentMemberRole.id,
        );

        const newMainContactPersonId = diff([values.mainContact], [mainContactPersonId]);

        const newMainContactPersonOptions = memberOptionsHelper(
          newMainContactPersonId,
          mainContactPersonOptions,
          communicationAssignmentMainRole.id,
        );

        const toBeAddedMemberOptions = [...newMemberOptions, ...newMainContactPersonOptions];

        const toBeDeletedMemberIds = diff(
          customAssignmentMemberIds,
          values.customAssignmentMembers,
        );
        const toBeDeletedMainContactPersonId = (
          diff([mainContactPersonId], [values.mainContact])
        );

        const toBeDeletedMemberOptions = memberOptionsHelper(
          toBeDeletedMemberIds,
          customAssignmentMembers,
          communicationAssignmentMemberRole.id,
        );

        const toBeDeletedMainContactPersonOptions = memberOptionsHelper(
          toBeDeletedMainContactPersonId,
          [mainContactPerson],
          communicationAssignmentMainRole.id,
        );

        const allMembersToBeDeleted = [
          ...toBeDeletedMemberOptions,
          ...toBeDeletedMainContactPersonOptions,
        ];

        const result = await dispatch(updateErpCustomerAssignment({
          assignmentId: communicationAssignmentId,
          assignment: {
            ...updatedAssignmentValues,
            addedCommunicationAssignmentMembers: toBeAddedMemberOptions.map(option => ({
              agentId: option.agentId,
              teamId: option.teamId,
              customerResponsibilityRoleId: option.customerResponsibilityRoleId,
              communicationAssignmentRoleId: option.communicationAssignmentRoleId,
            })),
            removedCommunicationAssignmentMembers: allMembersToBeDeleted.map(option => ({
              id: option.id,
              agentId: option.agentId,
              teamId: option.teamId,
              customerResponsibilityRoleId: option.customerResponsibilityRoleId,
              communicationAssignmentRoleId: option.communicationAssignmentRoleId,
            })),
          },
        }));
        if (!result.error) {
          dispatch(showSuccessNotification('Erfolgreich geändert'));
        }
      } else {
        const newMemberIds = values.customAssignmentMembers;

        const newMemberOptions = memberOptionsHelper(
          newMemberIds,
          assignmentMemberOptions,
          communicationAssignmentMemberRole.id,
        );

        const newMainContactPersonId = values.mainContact;
        const newMainContactPersonOption = memberOptionsHelper(
          [newMainContactPersonId],
          mainContactPersonOptions,
          communicationAssignmentMainRole.id,
        );

        const allNewMembers = [...newMemberOptions, ...newMainContactPersonOption];

        const result = await dispatch(createErpCustomerAssignment({
          assignment: {
            channelName: values.channelName,
            channelImage: values.channelImage,
            assignmentDirection: values.assignmentDirection,
            assignmentType: isTeamRow
              ? CommunicationAssignmentType.SALES_SPACE
              : CommunicationAssignmentType.DIRECT,
            isCraftnoteAutogenerate: values.isCraftnoteAutogenerate || false,
            members: allNewMembers.map(option => ({
              agentId: option.agentId,
              teamId: option.teamId,
              customerResponsibilityRoleId: option.customerResponsibilityRoleId,
              communicationAssignmentRoleId: option.communicationAssignmentRoleId,
            })),
          },
          erpCustomerId,
        }));

        if (!result.error) {
          dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
        }
      }

      setSubmitting(false);
      setNewCommunicationStructureAssignmentType(null);
      setOpenRowId(null);

      dispatch(fetchErpCustomerAssignments({ erpCustomerId }));
    },
    [
      assignment,
      isEditForm,
      isTeamRow,
      erpCustomerId,
      customAssignmentMembers,
      communicationAssignmentMemberRole.id,
      communicationAssignmentMainRole.id,
      customAssignmentMemberIds,
      assignmentMemberOptions,
      communicationAssignmentId,
      setNewCommunicationStructureAssignmentType,
      setOpenRowId,
      dispatch,
      mainContactPersonId,
      mainContactPersonOptions,
      mainContactPerson,
    ],
  );

  return (
    <RowOpen
      // Formik
      formikInitialValues={formikInitialValues}
      validationSchema={validationSchema}
      // Booleans
      isEditForm={isEditForm}
      isModalOpen={isModalOpen}
      isTeamRow={isTeamRow}
      isAssignmentGeneratedFromStructure={isAssignmentGeneratedFromStructure}
      isDeleteEnabled={!isModalOpen && !isAssignmentGeneratedFromStructure}
      hasAdminPermission={hasHouseAdminPermission || hasGlobalAdminPermission}
      // Ids
      communicationAssignmentId={communicationAssignmentId}
      craftnoteId={craftnoteId}
      // Members
      mainContactPerson={mainContactPerson}
      communicationStructureMembers={defaultAssignmentMembers}
      customAssignmentMembers={customAssignmentMembers}
      mainContactPersonOptions={mainContactPersonOptions}
      memberOptions={assignmentMemberOptions}
      // Functions
      onSubmit={submit}
      onExpandButtonClick={onExpandButtonClick}
      onCancel={() => {
        setNewCommunicationStructureAssignmentType(null);
        setOpenRowId(null);
      }}
      onDelete={() => {
        setNewCommunicationStructureAssignmentType(null);
        setOpenRowId(null);
        deleteAssignment(communicationAssignmentId);
      }}
    />
  );
};

ErpCustomerAssignmentRowOpen.propTypes = {
  communicationAssignmentId: PropTypes.string,
  setOpenRowId: PropTypes.func.isRequired,
  erpCustomerId: PropTypes.string.isRequired,
  isEditForm: PropTypes.bool,
  isTeamRow: PropTypes.bool,
  setNewCommunicationStructureAssignmentType: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  onDeletion: PropTypes.func.isRequired,
};

export default ErpCustomerAssignmentRowOpen;
