import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetUpdateTenantErrorMessage,
  tenantSelectionReset,
} from 'gcs-common/slices/administration/tenants/tenantsSlice';
import { fetchTenantDetails, updateTenant } from 'gcs-common/slices/administration/tenants/tenantsThunks';
import {
  getSelectedTenant,
  getSelectedTenantLoading,
  getUpdateTenantErrorMessage,
} from 'gcs-common/slices/administration/tenants/tenantsSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { showFailureNotification, showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import styles from './styles.module.scss';
import InlineInput from '../../../InlineInput/InlineInput';
import backIcon from '../../../../img/back.svg';

import { TenantCreateValidationSchema } from '../TenantCreate/TenantCreate';

const TenantDetail = () => {
  const { id: tenantId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorMessage = useSelector(getUpdateTenantErrorMessage);

  useEffect(() => {
    if (errorMessage) {
      dispatch(showFailureNotification(
        errorMessage,
        dispatch(resetUpdateTenantErrorMessage()),
      ));
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetUpdateTenantErrorMessage());
  }, [dispatch]);

  const selectedTenant = useSelector(getSelectedTenant);
  const selectedTenantLoading = useSelector(getSelectedTenantLoading);

  const [tenantName, setTenantName] = useState(null);

  useEffect(() => {
    (async () => {
      await dispatch(fetchTenantDetails({ tenantId }));
    }
    )();
    return () => dispatch(tenantSelectionReset());
  }, [dispatch, tenantId]);

  const onTenantChanged = useCallback(
    async (key, value) => {
      if (tenantName !== value) {
        setTenantName(value);
        const result = await dispatch(updateTenant({ tenantId, values: { [key]: value } }));
        if (!result.error) {
          dispatch(showSuccessNotification('Erfolgreich geändert'));
        }
      }
    },
    [dispatch, tenantId, tenantName],
  );

  const submit = useCallback(async (values, { setSubmitting }) => {
    dispatch(updateTenant({ tenantId, values }));
    setSubmitting(false);
  }, [dispatch, tenantId]);

  const initialValues = useMemo(() => {
    if (selectedTenant) {
      return {
        friendlyName: selectedTenant.friendlyName || '',
      };
    }
    return {};
  }, [selectedTenant]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={TenantCreateValidationSchema}
        onSubmit={submit}
      >
        {({ isSubmitting }) => (
          <Form>

            <div className={styles.headerRow}>
              <div className={styles.row}>
                <button
                  className={styles.backButton}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  <img src={backIcon} alt="back-icon" />
                </button>
                <InlineInput
                  name="friendlyName"
                  textClassName={styles.inputHeaderText}
                  onChange={onTenantChanged}
                  value={initialValues.friendlyName}
                />
              </div>
              {(selectedTenantLoading || isSubmitting) && <LoadingIndicator padding="0px" />}
            </div>

            {
              (errorMessage)
              && <div className={styles.errorMessage}>{errorMessage}</div>
            }

          </Form>
        )}
      </Formik>
    </div>
  );
};
export default TenantDetail;
