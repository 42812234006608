import { useSelector } from 'react-redux';
import { getIsOnline } from 'gcs-common/slices/offline/offlineSelectors';
import { ICON_SIZE, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import Spinner from 'gcs-common/components/Spinner/Spinner';
import styles from './styles.module.scss';

const InternetConnectionBanner = () => {
  const isOnline = useSelector(getIsOnline);
  if (!isOnline) {
    return (
      <div className={styles.offlineWrapper}>
        <Spinner size={ICON_SIZE.XX_SMALL} color={ICON_COLOR.WHITE} />
        &nbsp;Sie sind offline, wir versuchen Sie wieder zu verbinden.
      </div>
    );
  }
  return null;
};

export default InternetConnectionBanner;
