/* eslint-disable no-use-before-define */
import { createSelector } from '@reduxjs/toolkit';
import {
  getCustomerResponsibilityRolesForAgent,
  getCustomerResponsibilityRolesForTeam,
} from '../customerResponsibilityMembers/customerResponsibilityMembersSelector';
import {
  CommunicationAssignmentType, CommunicationAssignmentRoleType,
} from './houseCommunicationStructuresConstants';
import { ROLE_STATUS } from '../../../constants/ChipConstants';
import ASYNC_STATE from '../../../constants/AsyncState';
import { VALUE_KEY } from '../../../constants/dropdownSelectConstants';

export const getHouseCommunicationStructures = (
  state,
) => state.houseCommunicationStructures.communicationStructures;

export const getDirectCommunicationStructureIds = (
  state,
) => state.houseCommunicationStructures.communicationStructures
  .filter(structure => structure.assignmentType === CommunicationAssignmentType.DIRECT)
  .map(structure => structure.id);

export const getTeamCommunicationStructureIds = (
  state,
) => state.houseCommunicationStructures.communicationStructures
  .filter(structure => structure.assignmentType === CommunicationAssignmentType.SALES_SPACE)
  .map(structure => structure.id);

export const getCommunicationStructure = (id) => createSelector(
  getHouseCommunicationStructures,
  (communicationStructures) => communicationStructures.find(structure => structure.id === id),
);

const getAssignmentRoles = (
  state,
) => state.houseCommunicationStructures.assignmentRoles;

const getAgentOptions = (
  state,
) => state.houseCommunicationStructures.agentOptions;

const getTeamOptions = (
  state,
) => state.houseCommunicationStructures.teamOptions;

export const getCommunicationAssignmentMainRole = createSelector(
  getAssignmentRoles,
  (assignmentRoles) => (
    assignmentRoles.find(role => role.type === CommunicationAssignmentRoleType.MAIN_CONTACT)
  ),
);

export const getHouseAgentsForCommunicationStructureOptions = createSelector(
  getAgentOptions, // all agents of the house
  agents => agents.map(agent => (
    {
      ...agent,
      agentId: agent.id,
      type: 'INDIVIDUAL',
      roleStatus: ROLE_STATUS.MEMBER_NO_ROLE,
      [VALUE_KEY]: agent.id,
    })),
);

export const getCustomerResponsibilityRolesForAgentOptions = createSelector(
  getCustomerResponsibilityRolesForAgent,
  roles => roles.map(
    role => ({
      ...role,
      customerResponsibilityRoleId: role.id,
      roleStatus: ROLE_STATUS.ROLE_NO_MEMBER,
      [VALUE_KEY]: role.id,
    }),
  ),
);

export const getCommunicationStructureOptionsForMainRole = createSelector(
  getHouseAgentsForCommunicationStructureOptions,
  getCustomerResponsibilityRolesForAgentOptions,
  (agents, roles) => [...agents, ...roles],
);

export const getHouseTeamsForCommunicationStructureOptions = createSelector(
  getTeamOptions,
  teams => teams.map(team => (
    {
      ...team,
      teamId: team.id,
      friendlyName: team.name,
      type: 'TEAM',
      roleStatus: ROLE_STATUS.MEMBER_NO_ROLE,
      [VALUE_KEY]: team.id,
    })),
);

export const getCustomerResponsibilityRolesForTeamOptions = createSelector(
  getCustomerResponsibilityRolesForTeam,
  roles => roles.map(
    role => ({
      ...role,
      customerResponsibilityRoleId: role.id,
      roleStatus: ROLE_STATUS.ROLE_NO_MEMBER,
      [VALUE_KEY]: role.id,
    }),
  ),
);

export const getCommunicationStructureMemberOptions = createSelector(
  getCommunicationStructureOptionsForMainRole,
  getHouseTeamsForCommunicationStructureOptions,
  getCustomerResponsibilityRolesForTeamOptions,
  (individualOptions, teams, roles) => [...individualOptions, ...teams, ...roles],
);

export const getCommunicationAssignmentMemberRole = createSelector(
  getAssignmentRoles,
  (assignmentRoles) => (
    assignmentRoles.find(role => role.type === CommunicationAssignmentRoleType.MEMBER)
  ),
);

// Selects all members in a communication structure
export const getCommunicationStructureMembers = (id) => createSelector(
  getCommunicationStructure(id),
  (communicationStructure) => {
    return communicationStructure?.members
      ?.map(member => {
        if (member.customerResponsibilityRoleId) {
          return {
            ...member,
            roleStatus: ROLE_STATUS.ROLE_NO_MEMBER,
            [VALUE_KEY]: member.customerResponsibilityRoleId,
          };
        } if (member.agentId) {
          return {
            ...member,
            roleStatus: ROLE_STATUS.MEMBER_NO_ROLE,
            [VALUE_KEY]: member.agentId,
          };
        } if (member.teamId) {
          return {
            ...member,
            roleStatus: ROLE_STATUS.MEMBER_NO_ROLE,
            [VALUE_KEY]: member.teamId,
          };
        }
        return null;
      });
  },
);

// Selects the MCP
export const getCommunicationStructureMainMember = (id) => createSelector(
  getCommunicationAssignmentMainRole,
  getCommunicationStructureMembers(id),
  (assignmentMainRole, communicationStructureMembers) => (
    communicationStructureMembers
      ?.find(member => member.assignmentRoleId === assignmentMainRole?.id)
      || undefined
  ),
);

// Selects all members in a communication structure, except the MCP
export const getCommunicationStructureInitialMembers = (id) => createSelector(
  getCommunicationStructureMembers(id),
  getCommunicationAssignmentMemberRole,
  (communicationStructureMembers, assignmentMemberRole) => (
    communicationStructureMembers
      ?.filter(member => member.assignmentRoleId === assignmentMemberRole.id)
      || []
  ),
);

export const getDeleteCommunicationStructureDefaultErrorMessage = (
  state,
) => state.houseCommunicationStructures.delete.defaultErrorMessage;

export const getCreateCommunicationStructureDefaultErrorMessage = (
  state,
) => state.houseCommunicationStructures.create.defaultErrorMessage;

export const getUpdateCommunicationStructureDefaultErrorMessage = (
  state,
) => state.houseCommunicationStructures.update.defaultErrorMessage;

export const getIsDeleting = (
  state,
) => state.houseCommunicationStructures.delete.status === ASYNC_STATE.LOADING;
