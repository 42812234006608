import ASYNC_STATE from '../../constants/AsyncState';

export const getMessageSearchInput = (state) => state.messageSearch.searchInput;
export const getMessageSearchResults = (state) => state.messageSearch.resultMessageIds;
export const getMessageSearchIsLoading = (state) => {
  return state.messageSearch.searchChannelMessages.status === ASYNC_STATE.LOADING;
};
export const getMessageSearchErrorMessage = (state) => {
  return state.messageSearch.searchChannelMessages.errorMessage;
};
