import { addFavoriteOffline } from '../../slices/favorites/favoritesActions/addFavoriteOffline';
import { removeFavoriteOffline } from '../../slices/favorites/favoritesActions/removeFavoriteOffline';


const isAddFavoriteActionForSameMessage = (action, channelId, messageId) => {
  if (action.type !== addFavoriteOffline.type) {
    return false;
  }
  const { channelId: actionChannelId, messageId: actionMessageId } = action.meta;
  return channelId === actionChannelId && messageId === actionMessageId;
};

const isRemoveFavoriteActionForSameMessage = (action, channelId, messageId) => {
  if (action.type !== removeFavoriteOffline.type) {
    return false;
  }
  const {
    channelId: actionChannelId, messageId: actionMessageId,
  } = action.meta.favoriteToBeDeleted;
  return channelId === actionChannelId && messageId === actionMessageId;
};

/**
1. Avoid queuing up duplicate remove favorite actions
2. If there is a add action for the same favorite, remove the add action
 and dont add the remove action to the queue
 */
export const removeFavoriteOfflineHandleEnqueue = (queue, action) => {

  const {
    channelId, messageId,
  } = action.meta.favoriteToBeDeleted;

  const isAddInQueue = queue.some(iAction => {
    return isAddFavoriteActionForSameMessage(iAction, channelId, messageId);
  });

  const filteredQueue = queue.filter(iAction => {
    return !isRemoveFavoriteActionForSameMessage(iAction, channelId, messageId)
      && !isAddFavoriteActionForSameMessage(iAction, channelId, messageId);
  });

  if (isAddInQueue) {
    // don't add the remove action to the queue
    return filteredQueue;
  }
  return [...filteredQueue, action];

};
/**
 1.Avoid queuing up duplicate add favorite actions
 2. If there is a remove action for the same favorite,
 remove it and dont add the add action to the queue
 */
export const addFavoriteOfflineHandleEnqueue = (queue, action) => {

  const { channelId, messageId } = action.meta;

  const isRemoveInQueue = queue.some(iAction => {
    return isRemoveFavoriteActionForSameMessage(iAction, channelId, messageId);
  });

  const filteredQueue = queue.filter(iAction => {
    return !isRemoveFavoriteActionForSameMessage(iAction, channelId, messageId)
      && !isAddFavoriteActionForSameMessage(iAction, channelId, messageId);
  });

  if (isRemoveInQueue) {
    // don't add the add action to the queue
    return filteredQueue;
  }
  return [...filteredQueue, action];
};
