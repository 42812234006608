import { memo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import NewMessageIndicator from '../NewMessageIndicator/NewMessageIndicator';
import ReadNotification from '../ReadNotification/ReadNotification';
import MessageDateTag from '../MessageDateTag/MessageDateTag';
import MessageSelectorContainer from '../MessageSelectorContainer/MessageSelectorContainer';
import Message from '../Message/Message';
import TypingIndicatorWrapper from '../TypingIndicatorWrapper/TypingIndicatorWrapper';

const MessageWrapper = ({ messageId }) => {
  return (
    <>
      <NewMessageIndicator messageId={messageId} />
      <MessageDateTag messageId={messageId} />
      <MessageSelectorContainer messageId={messageId}>
        <Message messageId={messageId} />
      </MessageSelectorContainer>
      <ReadNotification messageId={messageId} />
      <TypingIndicatorWrapper messageId={messageId} />
    </>
  );
};

MessageWrapper.propTypes = {
  messageId: PropTypes.string.isRequired,
};
export default memo(MessageWrapper);
