const quantityUnitToString = {
  ST: 'Stück',
  M: 'm',
  SA: 'Satz',
  RO: 'Rolle',
  SET: 'Set',
  PA: 'Paar',
  DOS: 'Dose',
  QM: 'm²',
  BTL: 'Beutel',
  TUB: 'Tube',
  PAK: 'Packung',
  KTU: 'Kartusche',
  SCK: 'Sack',
  KG: 'kg',
  FL: 'Flasche',
  EIM: 'Eimer',
  PK: 'Paket',
  KT: 'Karton',
  KAN: 'Kanister',
  L: 'l',
};

export const convertQuantityToString = (quantityUnit) => {
  const quantityEnums = Object.keys(quantityUnitToString);
  if (quantityEnums.includes(quantityUnit)) {
    return quantityUnitToString[quantityUnit];
  }
  return quantityUnit;
};

export const convertPriceToString = (price, { Currency: currency, QuantityUnit: quantityUnit }) => {
  if (!price || Number(price) === 0) {
    return undefined;
  }
  if (price.includes(currency)) {
    return price;
  }
  const priceFormatted = new Intl.NumberFormat('de-de', {
    style: 'currency',
    currency,
  });

  return `${priceFormatted.format(price)} Per 1 ${convertQuantityToString(quantityUnit)}`;
};

const processTypeToString = {
  KL: 'Rückgabe',
  LS: 'Liefer-Auftrag Strecke',
  LI: 'Liefer-Auftrag',
  LA: 'Abhol-Auftrag',
  KS: 'Kundenrücknahme Strecke',
  AN: 'Angebot',
  BS: 'Sonderbestellung Strecke',
  KB: 'Rückgabe',
  LK: 'Reparatur',
  LD: 'Kundendienst Anforderung',
  AB: 'Abrufauftrag',
  RU: 'Rückstand',
};

export const convertProcessTypeToString = (processType, processTypeDescription) => {
  const processTypeEnums = Object.keys(processTypeToString);
  if (processTypeEnums.includes(processType)) {
    return processTypeToString[processType];
  }
  return processTypeDescription;
};

const orderStatusToString = {
  7: 'Ware ist zugestellt.',
  4: 'Ware ist in Kommissionierung.',
  3: 'Ware ist in Kommissionierung.',
  F: 'Ware ist fakturiert.',
  9: 'Freigabe zur Fakturierung.',
  5: 'Ware liegt zur Abholung bereit',
};

export const convertOrderStatusToString = (orderStatus, orderStatusText, processType) => {

  if (['BB', 'BS'].includes(processType) && orderStatus === '5') {
    // SMOOT-4324 special case to not show the order status
    return '';
  }

  const statusTextEnums = Object.keys(orderStatusToString);
  if (statusTextEnums.includes(orderStatus)) {
    return orderStatusToString[orderStatus];
  }
  return orderStatusText;
};
