import {
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSelectedAdministrationChannel, getSelectedAdministrationChannelLoading,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsSelectors';
import {
  fetchSelectedAdministrationChannel,
} from 'gcs-common/slices/administration/administrationChannels/administrationChannelsThunks';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import GroupChannelDetail from '../GroupChannelDetail/GroupChannelDetail';


const ChannelDetail = () => {

  const { id: channelId } = useParams();
  const dispatch = useDispatch();

  const selectedChannel = useSelector(getSelectedAdministrationChannel);
  const selectedChannelLoading = useSelector(getSelectedAdministrationChannelLoading);

  useEffect(() => {
    dispatch(fetchSelectedAdministrationChannel({ channelId }));
  }, [dispatch, channelId]);

  if (selectedChannel === undefined || selectedChannelLoading === undefined) {
    return (
      <LoadingIndicator padding="0px" />
    );
  }
  return (
    <GroupChannelDetail
      channelId={channelId}
      selectedChannel={selectedChannel}
      selectedChannelLoading={selectedChannelLoading}
    />
  );
};

export default ChannelDetail;
