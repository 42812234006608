export const CustomerResponsibilityMemberTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
};

export const CustomerResponsibilityMemberTypesOptions = [
  {
    label: 'Einzel-Verantwortlichkeit',
    value: CustomerResponsibilityMemberTypes.INDIVIDUAL,
  },
  {
    label: 'Team-Verantwortlichkeit',
    value: CustomerResponsibilityMemberTypes.TEAM,
  },
];
