import { createSelector } from 'reselect';
import { toPaginationEntities, toPaginationPaginationResult } from '../../../helper/reduxPaginationHelper';
import { toIsLoading } from '../../../helper/reduxLoadingStatusHelper';
import ASYNC_STATE from '../../../constants/AsyncState';
import { CustomerResponsibilityMemberTypes } from './customerResponsibilityMembersConstants';

// eslint-disable-next-line max-len
export const getAllCustomerResponsibilitiesMembers = (state) => state.customerResponsibilityMembers.customerResponsibilityMembers;


const getAllCustomerResponsibilitiesMembersState = state => (
  state.customerResponsibilityMembers.fetchCustomerResponsibilityMembers || {}
);

// eslint-disable-next-line max-len
export const getCustomerResponsibilityMembersForErpCustomer = ({ erpCustomerId }) => createSelector(
  getAllCustomerResponsibilitiesMembers,
  allCustomerResponsibilityMembers => {
    return Object.values(allCustomerResponsibilityMembers).filter(
      member => member.customerId === erpCustomerId,
    );
  },
);

// eslint-disable-next-line max-len
export const getCustomerResponsibilityRoles = state => state.customerResponsibilityMembers.customerResponsibilityRoles;

export const getCustomerResponsibilityRolesForTeam = createSelector(
  getCustomerResponsibilityRoles,
  roles => roles.filter(
    el => el.type === CustomerResponsibilityMemberTypes.TEAM,
  ),
);

export const getCustomerResponsibilityRolesForAgent = createSelector(
  getCustomerResponsibilityRoles,
  roles => roles.filter(
    el => el.type === CustomerResponsibilityMemberTypes.INDIVIDUAL,
  ),
);

export const getCustomerResponsibilityRole = (id) => createSelector(
  getCustomerResponsibilityRoles,
  (roles) => roles.find(role => role.id === id),
);


export const getCustomerResponsibilitiesMembers = createSelector(
  [getAllCustomerResponsibilitiesMembersState, getAllCustomerResponsibilitiesMembers],
  toPaginationEntities,
);

export const getCustomerResponsibilitiesMembersStateLoading = createSelector(
  [getAllCustomerResponsibilitiesMembersState],
  toIsLoading,
);


export const getCustomerResponsibilitiesMembersPaginationResult = createSelector(
  [getAllCustomerResponsibilitiesMembersState],
  toPaginationPaginationResult,
);

export const getCreateCustomerResponsibilityMemberErrorMessage = (
  state,
) => state.customerResponsibilityMembers.createCustomerResponsibilityMember.errorMessage;


export const getCustomerResponsibilityRolesLoading = createSelector(
  [getCustomerResponsibilityRoles],
  toIsLoading,
);

export const getDeleteCustomerResponsibilityMemberErrorMessage = (
  state,
) => state.customerResponsibilityMembers.deleteCustomerResponsibilityMember.errorMessage;

export const getDeleteCustomerResponsibilityMemberLoading = (
  state,
  // eslint-disable-next-line max-len
) => state.customerResponsibilityMembers.deleteCustomerResponsibilityMember.status === ASYNC_STATE.LOADING;
