import { CHANNEL_TYPE } from '../../helper/channelTypeHelper';
import { NEW_CHANNEL_SECTIONS } from '../../constants/newChannelSections';


export const filterByChannelTypeInSection = ({
  section,
  newChannelOptions,
}) => (newChannelOptionId) => {

  const channelType = newChannelOptions[newChannelOptionId]?.type;

  switch (section) {
    case NEW_CHANNEL_SECTIONS.SALES_SPACE:
      return [CHANNEL_TYPE.SALES_SPACE].includes(channelType);
    case NEW_CHANNEL_SECTIONS.HOUSE:
      return [CHANNEL_TYPE.SALES_SPACE, CHANNEL_TYPE.DIRECT_CHAT].includes(channelType);
    case NEW_CHANNEL_SECTIONS.DIRECT:
      return [
        CHANNEL_TYPE.DIRECT_CHAT,
        CHANNEL_TYPE.INTERNAL_AGENT,
        CHANNEL_TYPE.INTERNAL_CRAFTSMAN,
      ].includes(channelType);
    case NEW_CHANNEL_SECTIONS.INTERNAL:
      return [CHANNEL_TYPE.INTERNAL_AGENT, CHANNEL_TYPE.INTERNAL_CRAFTSMAN].includes(channelType);
    default:
      return false;
  }
};
