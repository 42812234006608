import defaultConfig from '@redux-offline/redux-offline/lib/defaults';
import gccApiClient from 'gcs-common/clients/gccApiClient/gccApiClient';
import chatClient from 'gcs-common/clients/chatClient/chatClient';
import videoClient from 'gcs-common/clients/videoClient/videoClient';
import offlineEffect from 'gcs-common/offline/offlineEffect';
import offlineQueue from 'gcs-common/offline/offlineQueue';
import offlineDiscard from 'gcs-common/offline/offlineDiscard';
import offlineRetry from 'gcs-common/offline/offlineRetry';

const offlineConfig = ({ getStore }) => ({
  ...defaultConfig,
  effect: offlineEffect({
    gccApiClient,
    chatClient,
    videoClient,
    getStore,
  }),
  discard: offlineDiscard({
    getStore,
  }),
  persist: false,
  queue: offlineQueue,
  retry: offlineRetry,
});

export default offlineConfig;
