import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoritesForSelectedChannelAsIds } from 'gcs-common/slices/favorites/favoritesSelectors';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getCurrentUser } from 'gcs-common/slices/currentUser/currentUserSelectors';
import fetchFavorites from 'gcs-common/slices/favorites/favoritesThunks/fetchFavorites';
import Favorite from './Favorite/Favorite';
import styles from './styles.module.scss';

const FavoriteList = () => {
  const favoriteIds = useSelector(getFavoritesForSelectedChannelAsIds);
  const selectedChannelId = useSelector(getSelectedChannelId);
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchFavorites());
    }
  }, [currentUser, dispatch, selectedChannelId]);

  return (
    <div className={styles.favoriteList}>
      <div className={styles.favoriteTitle}>
        Favoriten
      </div>
      <div className={styles.favoritesContent}>
        {favoriteIds.map(
          (favoriteId) => (
            <Favorite
              key={favoriteId}
              favoriteId={favoriteId}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default FavoriteList;
