import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line import/no-cycle
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId } from '../../../../slices/currentUser/currentUserSelectors';
import { LEGACY_EVENTS } from '../../../../config/googleAnalyticsLegacyConfig';
import { useApiClient } from '../../client';
import { Parameters } from '../../../../../types/typeHelper';
import { trackGAEvent, trackGALegacyEvent } from '../../../../slices/googleAnalytics/googleAnalyticsThunks';
// eslint-disable-next-line import/no-cycle
import { transformDoneTasks } from './tasks-api-selectors';
import {
  FetchTasksResponseData,
  getGetTasksQueryKey,
  TasksClientQueryKeys,
  useReduxStateUpdateHelper,
  useTasksApiHelper,
} from './tasks-api-helpers';

export function useGetTasks<T=FetchTasksResponseData>(query: Parameters<'/chat/tasks', 'get'>['query'], select?: (data: FetchTasksResponseData | undefined) => T) {
  const client = useApiClient();
  return useQuery({
    queryKey: getGetTasksQueryKey(query.channel_id),
    enabled: !!query.channel_id,
    queryFn: async () => {
      const { data } = await client.GET('/chat/tasks', {
        params: {
          query,
        },
      });
      return data;
    },
    select,
  });
}

export function useUpdateTaskMutation() {
  const queryClient = useQueryClient();
  const { updateTask } = useTasksApiHelper();
  return useMutation({
    mutationKey: [TasksClientQueryKeys.UPDATE_TASK],
    mutationFn: updateTask,
    onMutate: async ({ body }) => {
      const updatedTask = body;
      const { channelId } = updatedTask;
      await queryClient.cancelQueries({ queryKey: getGetTasksQueryKey(channelId) });
      const tasksCached = queryClient
        .getQueryData<FetchTasksResponseData>(getGetTasksQueryKey(channelId));
      const newTasks = tasksCached
        ? tasksCached.tasks
          .map((task) => (task.id === updatedTask.id ? { ...task, ...updatedTask } : task))
        : [];
      queryClient.setQueryData(getGetTasksQueryKey(channelId),
        { tasks: newTasks });
    },
    onSettled: (_, __, variables) => {
      const { channelId } = variables.body;
      queryClient.invalidateQueries({ queryKey: getGetTasksQueryKey(channelId) });
    },
  });
}

interface UseDeleteTaskMutationProps {
  channelId: string
}

export function useDeleteTaskMutation({ channelId }: UseDeleteTaskMutationProps) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { updateNumOpenTasksForChannel } = useReduxStateUpdateHelper();
  const { deleteTask } = useTasksApiHelper();
  return useMutation({
    mutationKey: [TasksClientQueryKeys.DELETE_TASK],
    mutationFn: deleteTask,
    onMutate: async (data) => {
      const taskId = data.params.path.task_id;
      await queryClient.cancelQueries({ queryKey: getGetTasksQueryKey(channelId) });
      const tasksCached = queryClient
        .getQueryData<FetchTasksResponseData>(getGetTasksQueryKey(channelId));
      const newTasks = tasksCached ? tasksCached.tasks.filter((task) => task.id !== taskId) : [];
      queryClient.setQueryData(getGetTasksQueryKey(channelId),
        { tasks: newTasks });
    },
    onSuccess: () => {
      dispatch(trackGAEvent('TODO', 'TODO_DELETE'));
    },
    onSettled: () => {
      updateNumOpenTasksForChannel(channelId);
      queryClient.invalidateQueries({ queryKey: getGetTasksQueryKey(channelId) });
    },
  });
}

export function useCreateTaskMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { updateNumOpenTasksForChannel } = useReduxStateUpdateHelper();
  const { addTask } = useTasksApiHelper();
  const currentUserId: string = useSelector(getCurrentUserId);
  return useMutation({
    mutationKey: [TasksClientQueryKeys.CREATE_TASK],
    mutationFn: addTask,
    onMutate: async ({ body }) => {
      const queryKey = getGetTasksQueryKey(body.channelId);
      await queryClient.cancelQueries({ queryKey });
      const tasksCached = queryClient.getQueryData<FetchTasksResponseData>(queryKey);
      const newTask: FetchTasksResponseData['tasks'][number] = {
        ...body,
        userId: currentUserId,
        order: tasksCached ? tasksCached.tasks.length : 0,
        done: false,
      };
      const newTasks = tasksCached
        ? [...tasksCached.tasks, newTask]
        : [newTask];
      queryClient.setQueryData(queryKey,
        { tasks: newTasks });
    },
    onSuccess: (_data, variables) => {
      if (variables.body.messageId) {
        dispatch(trackGALegacyEvent(LEGACY_EVENTS.ADD_FROM_MESSAGE()));
      } else {
        dispatch(trackGAEvent('TODO', 'TODO_ADD'));
      }
    },
    onSettled: (_data, _error, variables) => {
      const { channelId } = variables.body;
      updateNumOpenTasksForChannel(channelId);
      queryClient.invalidateQueries({ queryKey: getGetTasksQueryKey(channelId) });
    },
  });
}

export function useSetTaskDoneMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { updateNumOpenTasksForChannel } = useReduxStateUpdateHelper();
  const { setTaskDone } = useTasksApiHelper();
  return useMutation({
    mutationKey: [TasksClientQueryKeys.SET_TASK_DONE],
    mutationFn: setTaskDone,
    onMutate: async (data) => {
      const taskId = data.params.path.task_id;
      const { channelId, done } = data.body;
      await queryClient.cancelQueries({ queryKey: getGetTasksQueryKey(channelId) });
      const tasksCached = queryClient
        .getQueryData<FetchTasksResponseData>(getGetTasksQueryKey(channelId));
      const numNotDoneTasks = tasksCached ? tasksCached.tasks.filter((t) => !t.done).length : 0;
      const newTasks = tasksCached ? tasksCached.tasks.map((task) => (task.id === taskId
        ? ({ ...task, done, order: numNotDoneTasks }) : task)) : [];
      queryClient.setQueryData(getGetTasksQueryKey(channelId),
        { tasks: newTasks });
    },
    onSuccess: (data) => {
      const done = data.data?.task.done;
      dispatch(trackGAEvent('TODO', `MARKED_AS_${done ? 'DONE' : 'NO_LONGER_DONE'}`));
    },
    onSettled: (_data, _error, variables) => {
      const { channelId } = variables.body;
      updateNumOpenTasksForChannel(channelId);
      queryClient.invalidateQueries({ queryKey: getGetTasksQueryKey(channelId) });
    },
  });
}

// export function useGetTaskMediaURLs(path: Parameters<'/chat/tasks/{task_id}/media',
// 'get'>['path']) {
//   const client = useApiClient();
//   return useQuery({
//     queryKey: [
//       TasksClientQueryKeys.GET_TASK_MEDIA_URLS,
//       path,
//     ],
//     enabled: !!path.task_id,
//     queryFn: async () => {
//       const { data } = await client.GET('/chat/tasks/{task_id}/media', {
//         params: {
//           path,
//         },
//       });
//       return data;
//     },
//   });
// }

export function useUpdateTasksOrderMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { updateTasksOrder } = useTasksApiHelper();
  return useMutation({
    mutationKey: [TasksClientQueryKeys.UPDATE_TASKS_ORDER],
    mutationFn: updateTasksOrder,
    onMutate: async ({ body }) => {
      const reorderedTasks = body.tasks;
      const channelId = reorderedTasks[0]?.channelId;
      await queryClient.cancelQueries({ queryKey: getGetTasksQueryKey(channelId!) });
      const tasksCached = queryClient
        .getQueryData<FetchTasksResponseData>(getGetTasksQueryKey(channelId!));
      const doneTasksTransformed = transformDoneTasks(tasksCached);
      queryClient.setQueryData(getGetTasksQueryKey(channelId!),
        { tasks: [...reorderedTasks, ...doneTasksTransformed] });
    },
    onSuccess: () => {
      dispatch(trackGALegacyEvent(LEGACY_EVENTS.REARRANGE()));
    },
    onSettled: (_data, _error, variables) => {
      const { tasks } = variables.body;
      const channelId = tasks[0]?.channelId;
      queryClient.invalidateQueries({ queryKey: getGetTasksQueryKey(channelId!) });
    },
  });
}
