import { useEffect, useState } from 'react';
import { DEBUG_USER_ID } from 'gcs-common/clients/gccApiClient/gccApiClient';
import { useDispatch, useSelector } from 'react-redux';
import { getAppThemes } from 'gcs-common/slices/theme/themeSelectors';
import { setDebugAppTheme } from 'gcs-common/slices/theme/themeSlice';
import { SELECTED_USER, USERS } from 'gcs-common/constants/debugUserSwitcherConstants';
import styles from './styles.module.scss';
import deletedIcon from '../../icons/delete.svg';

const DebugUserSwitcher = () => {
  const [expand, setExpand] = useState(false);
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({ label: '', theme: '', userId: '' });
  const [selectedEntry, setSelectedEntry] = useState(null);
  const appThemes = useSelector(getAppThemes);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedEntries = JSON.parse(localStorage.getItem(USERS)) || [];
    const storedSelectedEntry = JSON.parse(localStorage.getItem(SELECTED_USER)) || null;
    setEntries(storedEntries);
    setSelectedEntry(storedSelectedEntry);
  }, []);

  const handleAddEntry = () => {
    const updatedEntries = [...entries, newEntry];
    setEntries(updatedEntries);
    setNewEntry({ label: '', theme: 'gc', userId: '' });
    localStorage.setItem(USERS, JSON.stringify(updatedEntries));
  };

  const handleRemoveEntry = (index) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
    localStorage.setItem(USERS, JSON.stringify(updatedEntries));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry({ ...newEntry, [name]: value });
  };

  const handleSelectEntry = (index) => {
    const selected = entries[index];
    setSelectedEntry(selected);
    localStorage.setItem(SELECTED_USER, JSON.stringify(selected));
    localStorage.setItem(DEBUG_USER_ID, selected.userId);
    dispatch(setDebugAppTheme({ debugAppTheme: selected.theme }));
    localStorage.setItem('debugAppTheme', selected.theme);
    window.location.reload();
  };

  const handleDeselectEntry = () => {
    setSelectedEntry(null);
    localStorage.removeItem(SELECTED_USER);
    localStorage.removeItem(DEBUG_USER_ID);
    window.location.reload();
  };

  if (import.meta.env.PROD) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className={styles.switcherButton}
        onClick={() => setExpand(prevState => !prevState)}
      >
        {'<<'}
      </button>
      {expand && (

      <div className={styles.userSwitcherContainer}>
        <h1 className={styles.header}>User Switcher</h1>
        {selectedEntry && (
        <div className={styles.selectedEntry}>
          <h3>Selected User</h3>
          <p>
            Label:
            {' '}
            {selectedEntry.label}
            <br />
            Theme:
            {' '}
            {selectedEntry.theme}
            <br />
            UserId:
            {' '}
            {selectedEntry.userId}
          </p>
          <button type="button" onClick={handleDeselectEntry}>Deselect</button>
        </div>
        )}
        <ul className={styles.entryList}>
          {entries.map((entry, index) => (
            <button
              style={{ width: '100%' }}
              type="button"
              className={styles.entryItem}
              onClick={() => handleSelectEntry(index)}
            >

              {entry.label}
              {' - '}
              {entry.theme}
              {' - '}
              {entry.userId}

              <div style={{ width: '15px' }}>
                <button
                  type="button"
                  disabled={selectedEntry?.label === entry.label}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveEntry(index);
                  }}
                  title={
                  selectedEntry?.label === entry.label
                    ? 'Cannot remove because selected'
                    : 'Click to remove'
                  }
                >
                  <img
                    alt="deleted-icon"
                    src={deletedIcon}
                  />
                </button>
              </div>
            </button>
          ))}
        </ul>
        <div className={styles.inputContainer}>
          <input
            type="text"
            name="label"
            placeholder="Label"
            value={newEntry.label}
            onChange={handleInputChange}
          />
          <select name="theme" value={newEntry.theme} onChange={handleInputChange}>
            {appThemes && Object.keys(appThemes).map(theme => {
              return (
                <option value={theme}>{theme}</option>
              );
            })}
          </select>
          <input
            type="text"
            name="userId"
            placeholder="User ID"
            value={newEntry.userId}
            onChange={handleInputChange}
          />
          <button
            type="button"
            onClick={handleAddEntry}
          >
            Add Entry
          </button>
        </div>


      </div>

      )}
    </>
  );
};

export default DebugUserSwitcher;
