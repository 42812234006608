/* eslint-disable import/no-cycle */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSelectedHouseId } from '../../houses/housesSelectors';
import { openErrorDialog } from '../../errorDialogSlice/errorDialogSlice';
import changeSelectedChannelId from './changeSelectedChannelId';
import { getErrorMsg } from '../../../helper/errorMessages';
import fetchCurrentUserChannels from './fetchCurrentUserChannels';
import updateCurrentUserArchivedChannels from '../../currentUser/currentUserThunks/updateCurrentUserArchivedChannels';

const createChannel = createAsyncThunk(
  'channels/createChannel',
  async (
    { userIds },
    { dispatch, rejectWithValue, getState, extra: { gccApiClient } },
  ) => {
    const selectedHouseId = getSelectedHouseId(getState());

    try {
      const response = await gccApiClient.createDirectChannel(
        userIds, selectedHouseId,
      );

      // Handles response errors
      if (response.error_code) {
        dispatch(openErrorDialog({
          dialogErrorButtonText: 'Zurück',
          dialogErrorMsg: getErrorMsg(response.error_code) || getErrorMsg('create_channel.default'),
          dialogErrorTitle: 'Fehler',
        }));
        return rejectWithValue({ error: response.error_code });
      }

      dispatch(changeSelectedChannelId({ selectedChannelId: response?.id }));
      await dispatch(fetchCurrentUserChannels({ specificChannelId: response?.id }));
      dispatch(updateCurrentUserArchivedChannels({ channelId: response.id }));

      return { success: response };
    } catch (error) {
      // Handles offline errors
      return dispatch(openErrorDialog({
        dialogErrorButtonText: 'Zurück',
        dialogErrorMsg: 'Sie haben keine Verbindung zum Internet. Bitte versuchen Sie es erneut.',
        dialogErrorTitle: 'Fehler',
      }));
    }
  },
);

export default createChannel;
