import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import avatarPlaceHolder from 'gcs-common/img/user.svg';
import {
  deleteAgent,
  fetchSelectedAgent, updateSelectedAgent,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import fetchCurrentUser from 'gcs-common/slices/currentUser/currentUserThunks/fetchCurrentUser';
import { agentSelectionReset } from 'gcs-common/slices/administration/administrationUsers/administrationUsersSlice';
import {
  getSelectedAgent,
  getSelectedAgentLoading,
  getIsFetchSelectedAgentSucceeded,
} from 'gcs-common/slices/administration/administrationUsers/administrationUsersSelectors';
import { CHANNEL_TYPE } from 'gcs-common/helper/channelTypeHelper';
import { USER_PERMISSIONS } from 'gcs-common/helper/userTypeHelper';
import { getCurrentUserId, getHasPermission } from 'gcs-common/slices/currentUser/currentUserSelectors';
import * as Yup from 'yup';
import { getErrorMsg } from 'gcs-common/helper/errorMessages';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { showFailureNotification, showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import styles from './styles.module.scss';
import backIcon from '../../../../img/back.svg';
import Tabs from '../../../Tabs/Tabs';
import FormikInput from '../../../FormikInput/FormikInput';

import AgentGroupChannelTable from '../AgentGroupChannelTable/AgentGroupChannelTable';
import AgentDirectChannelTable from '../AgentDirectChannelTable/AgentDirectChannelTable';
import PermissionProtectedRoute from '../../../ProtectedRoutes/PermissionProtectedRoute';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import SearchBar from '../../../SearchBar/SearchBar';
import Alert from '../../../Alert/Alert';
import EditIcon from '../../../../img/edit.svg?react';
import DatabaseDeleteIcon from '../../../../img/database_delete.svg?react';
import CheckIcon from '../../../../img/check_green.svg?react';
import CancelIcon from '../../../../img/close_red.svg?react';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import AgentDeletionModal from './AgentDeletionModal/AgentDeletionModal';
import FormikCheckBox from '../../../FormikCheckBox/FormikCheckBox';
import CustomerResponsibilityMembersTable from '../../CustomerResponsibilityMembers/CustomerResponsibilityMembersTable/CustomerResponsibilityMembersTable';

const AgentUpdateValidationSchema = Yup.object().shape({
  // eslint-disable-next-line react/forbid-prop-types
  permissions: Yup.array(),
  email: Yup.string().email('Ungültige E-Mail Adresse').required('Erforderlich'),
  disabled: Yup.boolean(),
});

const localeDateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

const AgentDetail = () => {
  const { id: agentId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const currentUserId = useSelector(getCurrentUserId);
  const selectedAgent = useSelector(getSelectedAgent);
  const selectedAgentLoading = useSelector(getSelectedAgentLoading);
  const isAgentLoaded = useSelector(getIsFetchSelectedAgentSucceeded);
  const isGlobalAdmin = useSelector(getHasPermission(USER_PERMISSIONS.GLOBAL_ADMIN.value));
  const isHouseAdmin = useSelector(getHasPermission(USER_PERMISSIONS.HOUSE_ADMIN.value));
  const isAddedFromAd = selectedAgent?.isAddedFromAd;
  const existsInExternalSource = selectedAgent?.existsInExternalSource;
  const createdBy = selectedAgent?.createdBy;
  const createdAt = selectedAgent?.createdAt;
  const updatedAt = selectedAgent?.updatedAt;
  const createdAtLabel = useMemo(() => new Date(createdAt)
    .toLocaleDateString([], localeDateOptions)
    .replaceAll('.', ''), [createdAt]);
  const updatedAtLabel = useMemo(() => new Date(updatedAt)
    .toLocaleDateString([], localeDateOptions)
    .replaceAll('.', ''), [updatedAt]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await dispatch(fetchSelectedAgent({ agentId }));
    })();
    return () => dispatch(agentSelectionReset());
  }, [agentId, dispatch]);

  const cancelEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(false);
  }, []);

  const startEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const startDeletion = useCallback((event) => {
    event.preventDefault();
    setIsDeleting(true);
  }, []);

  const handleAgentDeletion = async (event) => {
    event.preventDefault();
    await dispatch(deleteAgent({ agentId }));
    setIsDeleting(false);
    navigate(-1);
  };

  // TODO: SMOOT-2845 Fix
  const initialValues = useMemo(() => {
    // Note: Formik/React creates a warning if a value is "null"
    // so we set all fields to at least ""
    // https://formik.org/docs/api/formik#initialvalues-values
    if (selectedAgent) {
      return {
        externalIdentity: selectedAgent.externalIdentity || '',
        phoneNumber: selectedAgent.phoneNumber || '',
        email: selectedAgent.email || '',
        friendlyName: selectedAgent.friendlyName || '',
        name: selectedAgent.name || '',
        firstName: selectedAgent.firstName || '',
        lastName: selectedAgent.lastName || '',
        salutation: selectedAgent.salutation || '',
        customerCompany: selectedAgent.customerCompany || '',
        imageUrl: selectedAgent.imageUrl,
        id: selectedAgent.id || '',
        permissions: selectedAgent.permissions || [],
        disabled: selectedAgent.disabled || false,
      };
    }
    return {
      externalIdentity: '',
      phoneNumber: '',
      email: '',
      friendlyName: '',
      name: '',
      firstName: '',
      lastName: '',
      salutation: '',
      customerCompany: '',
      id: '',
      permissions: [],
      disabled: false,
    };
  }, [selectedAgent]);

  const [searchInputValue, setSearchInputValue] = useState('');

  const onSearch = useCallback((event) => {
    const input = event.target.value;
    setSearchInputValue(input);
  }, []);

  const onCancelSearch = useCallback(() => {
    setSearchInputValue('');
  }, []);

  const submit = useCallback(async (values, { setSubmitting }) => {
    const changedValues = Object.keys(values).reduce((red, key) => {
      if (values[key] === initialValues[key]) return red;
      return {
        ...red,
        [key]: values[key],
      };
    }, {});

    const result = await dispatch(updateSelectedAgent({
      agentId: selectedAgent.id,
      updateFields: changedValues,
    }));
    const { errorCode } = result.payload;
    if (!result.error) {
      dispatch(showSuccessNotification('Erfolgreich geändert'));
      setIsEditing(false);
    } else {
      const errorMessage = getErrorMsg(errorCode) || getErrorMsg('agent_user_service.default');
      dispatch(showFailureNotification(errorMessage));
    }
    await dispatch(fetchSelectedAgent({ agentId }));
    if (currentUserId === agentId) await dispatch(fetchCurrentUser());
    setSubmitting(false);
  }, [agentId, currentUserId, dispatch, initialValues, selectedAgent]);

  const getProvisioningIndicationLabel = (isProvisionedByCRM, createdByUser) => {
    if (isProvisionedByCRM) {
      return 'CRM';
    }
    if (createdByUser) {
      return createdByUser;
    }
    return 'user management';
  };

  const isAutomaticallyProvisionedIndicationVisible = (isProvisionedByAd) => {
    return !!isProvisionedByAd;
  };

  const isCreatedByIndicationVisible = (isProvisionedByAd) => {
    return !isAutomaticallyProvisionedIndicationVisible(isProvisionedByAd);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={AgentUpdateValidationSchema}
        onSubmit={submit}
      >
        {({ isSubmitting, resetForm, values }) => {
          return (
            <Form>
              <div className={styles.headerRow}>
                <div className={styles.row}>
                  <button
                    className={styles.backButton}
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    <img src={backIcon} alt="back-icon" />
                  </button>
                  <div className={styles.inputHeaderText}>{initialValues.friendlyName}</div>
                  { isAutomaticallyProvisionedIndicationVisible(isAddedFromAd)
                      && (
                        <div className={styles.entityTypeWrapper}>
                          <span className={styles.entityTypeTag}>Automatisch bereitgestellt</span>
                        </div>
                      )
                  }
                </div>
                {selectedAgentLoading && <LoadingIndicator padding="0px" />}

                {/* for now only allow editing for global admins */}
                {(isGlobalAdmin || isHouseAdmin)
                  && (
                    <div className={styles.buttonsWrapper}>
                      {isEditing ? (
                        <>
                          <button
                            className={styles.saveButton}
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <CheckIcon />
                          </button>
                          <button
                            className={styles.saveButton}
                            type="button"
                            onClick={(event) => {
                              resetForm();
                              cancelEditing(event);
                            }}
                            disabled={isSubmitting}
                          >
                            <CancelIcon />
                          </button>
                        </>
                      ) : (
                        <>
                          <PermissionProtectedRoute permission={['global_admin']}>
                            {!isAddedFromAd && (
                              <button
                                className={styles.saveButton}
                                type="button"
                                onClick={startDeletion}
                                disabled={!isAgentLoaded}
                              >
                                <DatabaseDeleteIcon />
                              </button>
                            )}
                          </PermissionProtectedRoute>
                          <button
                            className={styles.saveButton}
                            type="button"
                            onClick={startEditing}
                            disabled={!isAgentLoaded}
                          >
                            <EditIcon />
                          </button>
                        </>
                      )}
                      {isSubmitting && <LoadingIndicator padding="0px" />}
                    </div>
                  )}

              </div>

              <div className={styles.row}>
                <div className={styles.imageColumn}>
                  <img
                    className={styles.profilePic}
                    src={initialValues?.imageUrl || avatarPlaceHolder}
                    alt="profilbild"
                  />
                  <FormikCheckBox
                    textClassName={values.disabled ? styles.disabledText : undefined}
                    disabled={!isEditing || !isGlobalAdmin}
                    text="Gesperrt"
                    name="disabled"
                  />
                </div>
                <div className={styles.column}>
                  {(isEditing && isAddedFromAd)
                  && (
                  <div className={styles.alertRow}>
                    <Alert type="info">Dies ist ein automatisch bereitgestellter Mitarbeiter: Manche Attribute können nicht direkt in BEEM aktualisiert werden.</Alert>
                  </div>
                  )
                }
                  <div className={styles.row}>
                    <FormikInput
                      disabled
                      label="Name"
                      name="friendlyName"
                    />
                    <FormikInput disabled={!(isEditing && (isGlobalAdmin || isHouseAdmin) && !isAddedFromAd)} label="Email" name="email" />
                    <FormikSelect
                      disabled={!isEditing || !isGlobalAdmin}
                      label="Berechtigungen"
                      name="permissions"
                      isMulti
                      options={[
                        USER_PERMISSIONS.GLOBAL_ADMIN,
                        USER_PERMISSIONS.HOUSE_ADMIN,
                      ]}
                      labelKey="friendlyName"
                      valueKey="value"
                    />
                    <div className={styles.agentContainer}>
                      <h2 className={styles.agentLabel}>Erstellt am</h2>
                      <p>
                        { createdAtLabel }
                      </p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.formikInputWrapper}>
                      <FormikInput
                        disabled
                        label="Firma"
                        name="customerCompany"
                      />
                    </div>
                    <div className={styles.formikInputWrapper}>
                      <FormikInput disabled label="Telefonnummer" name="phoneNumber" />
                    </div>
                    { isCreatedByIndicationVisible(isAddedFromAd)
                        && (
                          <div className={`${styles.agentContainer} ${styles.formikInputWrapper}`}>
                            <h2 className={styles.agentLabel}>Erstellt von</h2>
                            <strong>
                              { getProvisioningIndicationLabel(existsInExternalSource, createdBy)
                              }
                            </strong>
                          </div>
                        )
                    }
                    <div className={`${styles.agentContainer} ${styles.formikInputWrapper}`}>
                      {updatedAt !== createdAt && (
                        <div>
                          <h2 className={styles.agentLabel}>Verändert am</h2>
                          <p>
                            { updatedAtLabel }
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <PermissionProtectedRoute permission={['global_admin']}>
                <div className={styles.column}>
                  <div className={styles.globalAdminHeader}>Globaler Admin</div>
                  <div className={styles.row}>
                    <FormikInput
                      disabled
                      label="Twilio Id"
                      name="externalIdentity"
                    />
                    <FormikInput disabled label="DB Id" name="id" />
                    <FormikInput disabled label="Anrede" name="salutation" />
                    <FormikInput disabled label="Vorname" name="firstName" />
                    <FormikInput disabled label="Nachname" name="lastName" />
                  </div>
                </div>
              </PermissionProtectedRoute>
            </Form>
          );
        }}
      </Formik>

      {isDeleting && (
        <PopUpModal
          title="Mitarbeiter Löschen"
          onRequestClose={() => setIsDeleting(false)}
          isOpen
        >
          <AgentDeletionModal
            onCancel={() => setIsDeleting(false)}
            agent={selectedAgent}
            handleDelete={handleAgentDeletion}
          />
        </PopUpModal>
      )
      }

      <div className={styles.searchWrapper}>
        <SearchBar
          onSearchInputChange={onSearch}
          resetFilter={onCancelSearch}
          searchInput={searchInputValue}
          placeholder="Suche..."
        />
      </div>
      <div className={styles.tabWrapper}>
        <Tabs
          onActiveTabChanged={onCancelSearch}
          disabled={!isAgentLoaded}
          tabs={{
            directChannels: {
              name: 'Direkte Chats',
              component: (
                <div className={styles.row}>
                  <AgentDirectChannelTable
                    agentId={agentId}
                    searchInputValue={searchInputValue}
                    channelTypes={[CHANNEL_TYPE.DIRECT_CHAT]}
                  />
                </div>
              ),
            },
            responsibilities: {
              name: 'Verantwortlichkeiten',
              component: (
                <CustomerResponsibilityMembersTable filter={['teamOrAgent']} />
              ),
            },
            internalAgentChannel: {
              name: 'Hausinterne Kommunikation',
              component: (
                <div className={styles.row}>
                  <AgentDirectChannelTable
                    agentId={agentId}
                    searchInputValue={searchInputValue}
                    channelTypes={[CHANNEL_TYPE.INTERNAL_AGENT]}
                  />
                </div>
              ),
            },
            teamChats: {
              name: 'Teamchats',
              component: (
                <div className={styles.row}>
                  <AgentGroupChannelTable
                    agentId={agentId}
                    searchInputValue={searchInputValue}
                    channelTypes={[CHANNEL_TYPE.SALES_SPACE]}
                  />
                </div>
              ),
            },
          }}
        />
      </div>
    </div>
  );
};
export default AgentDetail;
