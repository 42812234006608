import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getSidebarCollapsed } from 'gcs-common/slices/uiState/uiStateSelectors';
import CRAFTSMAN_CHANNEL_SECTIONS from 'gcs-common/constants/CraftsmanChannelSections';
import styles from './styles.module.scss';
import ChannelListItem from '../ChannelListItem/ChannelListItem';

const CraftsmanChannelSection = ({ sectionName, channelIds, className }) => {
  const sidebarCollapsed = useSelector(getSidebarCollapsed);

  if (!channelIds || channelIds.length === 0) {
    return <div />;
  }

  return (
    <>
      {sectionName === CRAFTSMAN_CHANNEL_SECTIONS.INTERNAL_CHATS && (
      <div className={`${styles.channelSectionName} ${sidebarCollapsed ? styles.sidebarCollapsed : ''}`.trim()}>
        {sidebarCollapsed ? 'EIGENE' : 'EIGENE CHATS'}
        <span className={styles.channelSectionCount}>
          (
          { channelIds.length }
          )
        </span>
      </div>
      )}
      <div className={`${styles.channelList} ${className}`}>
        {channelIds.map(channelId => (
          <ChannelListItem
            key={channelId}
            channelId={channelId}
          />
        ))}
      </div>
    </>
  );
};

CraftsmanChannelSection.propTypes = {
  sectionName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  channelIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CraftsmanChannelSection;
