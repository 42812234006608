import { getPreSelectedChannelId } from '../channelsSelectors';
import changeSelectedChannelId from './changeSelectedChannelId';
import { resetPreSelectedChannelId } from '../channelsSlice';

const changeSelectedChannelToPreselectedChannel = ({ channelId }) => async (dispatch, getState) => {
  const preSelectedChannelId = getPreSelectedChannelId(getState());

  if (preSelectedChannelId === channelId) {
    dispatch(changeSelectedChannelId({ selectedChannelId: preSelectedChannelId }));
    dispatch(resetPreSelectedChannelId);
  }
};

export default changeSelectedChannelToPreselectedChannel;
