import BaseModel from '../BaseModel';

export default class AbstractMessageModel extends BaseModel {

  constructor(
    {
      authorId,
      autogenerated,
      channelId,
      craftnoteSender,
      craftnoteId,
      craftnoteChannel,
      craftsmanMessage,
      deleted,
      externalId,
      index,
      messageSubType,
      messageType,
      clientMessageId,
      previousAuthorId,
      sentFrom,
      text,
      dateUpdated,
      dateCreated,
      isForwarded,
      forwardedMessageId,
      forwardedMessageCreatedAt,
      forwardedMessageAuthorName,
      forwardedMessageText,

      id,
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
    },
  ) {
    super(id, createdAt, updatedAt, createdBy, updatedBy);

    this.authorId = authorId;
    this.autogenerated = autogenerated;
    this.channelId = channelId;
    this.craftnoteSender = craftnoteSender;
    this.craftnoteId = craftnoteId;
    this.craftnoteChannel = craftnoteChannel;
    this.craftsmanMessage = craftsmanMessage;
    this.deleted = deleted;
    this.externalId = externalId;
    this.index = index;
    this.messageSubType = messageSubType;
    this.messageType = messageType;
    this.clientMessageId = clientMessageId;
    this.previousAuthorId = previousAuthorId;
    this.sentFrom = sentFrom;
    this.text = text;
    this.dateUpdated = dateUpdated;
    this.dateCreated = dateCreated;

    // Forwarded message
    this.isForwarded = isForwarded;
    this.forwardedMessageId = forwardedMessageId;
    this.forwardedMessageCreatedAt = forwardedMessageCreatedAt;
    this.forwardedMessageAuthorName = forwardedMessageAuthorName;
    this.forwardedMessageText = forwardedMessageText;
  }
}
