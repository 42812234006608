/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  platform: null,
  isNative: null,
  isIos: null,
};
const initializationSlice = createSlice({
  name: 'initialization',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default initializationSlice.reducer;
