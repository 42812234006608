import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCraftsmanConnection, getCraftsmanConnections,
  getIsManualCraftsmanConnection,
  getUpdateCraftsmanConnectionToCustomerError,
  getUpdateCraftsmanConnectionToCustomerFailed,
  getUpdateCraftsmanConnectionToCustomerLoading,
} from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSelectors';
import { updateCraftsmanConnectionToCustomer } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsThunks';
import { resetEditError } from 'gcs-common/slices/craftsmanConnections/craftsmanConnectionsSlice';
import {
  getDeleteErpCustomerUserLoading,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import { opMaintenanceMessage } from 'gcs-common/helper/opMaintenanceHelper';
import { getHasFeatureFlag } from 'gcs-common/slices/currentUser/currentUserSelectors';
import FEATURE_FLAGS from 'gcs-common/helper/featureFlagsHelper';
import { getAppName, getDefaultBrand } from 'gcs-common/slices/theme/themeSelectors';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import Button from '../../../Button/Button';

import styles from './styles.module.scss';
import SettingsInput from '../../SettingsInput/SettingsInput';
import OpConnectionStatus from '../../../OpConnectionStatus/OpConnectionStatus';
import CraftsmanConnectionPermissions from '../CraftsmanConnectionPermissions/CraftsmanConnectionPermissions';

const CraftsmanConnectionModalEdit = ({ goToRemove, connectionId }) => {
  const appName = useSelector(getAppName);
  const connectionDetail = useSelector(getCraftsmanConnection(connectionId));
  const craftsmanConnections = useSelector(getCraftsmanConnections);
  const editConnectionIsLoading = useSelector(getUpdateCraftsmanConnectionToCustomerLoading);
  const editConnectionFailed = useSelector(getUpdateCraftsmanConnectionToCustomerFailed);
  const editCraftsmanConnectionError = useSelector(getUpdateCraftsmanConnectionToCustomerError);
  const defaultBrand = useSelector(getDefaultBrand);
  const isDeleting = useSelector(getDeleteErpCustomerUserLoading);
  const isManual = useSelector(getIsManualCraftsmanConnection(connectionId));
  const opAccountDetails = connectionDetail && connectionDetail?.opAccount
    ? connectionDetail?.opAccount
    : { username: null, password: null };
  const [username, setUsername] = useState(opAccountDetails.username);
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const isOpMaintenanceEnabled = useSelector(getHasFeatureFlag(FEATURE_FLAGS.OP_MAINTENANCE));

  const onResetError = useCallback(() => {
    dispatch(resetEditError());
  }, [dispatch]);

  const updateConnectionToCompany = useCallback(() => {
    if (connectionId) {
      dispatch(updateCraftsmanConnectionToCustomer({
        connectionId, username, password, brand: defaultBrand,
      }));
    }
  }, [dispatch, connectionId, username, password, defaultBrand]);

  const onChangeUsername = useCallback((value) => {
    setUsername(value);
  }, []);

  const onChangePassword = useCallback((value) => {
    setPassword(value);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetEditError());
    };
  }, [dispatch]);

  if (editConnectionIsLoading) {
    return (
      <div>
        <LoadingIndicator padding="0" />
      </div>
    );
  }

  return (
    <>
      <div className={styles.headline}>{`${appName} ONLINE PLUS Verknüpfung`}</div>
      <div className={styles.body}>
        {!isManual && (
        <div className={styles.status}>
          <OpConnectionStatus connectionId={connectionId} />
          <div>{username || 'Keine ONLINE PLUS Verknüpfung'}</div>
        </div>
        )}
        <div className={`${styles.accountRowWrapper}`}>
          <SettingsInput
            name="Benutzer"
            placeholder="Benutzer"
            value={username}
            onChange={onChangeUsername}
          />
          <SettingsInput
            name="Passwort"
            placeholder="Passwort"
            type="password"
            value={password}
            onChange={onChangePassword}
          />

          {editConnectionFailed ? (
            <div className={styles.errorMsg}>
              { isOpMaintenanceEnabled ? opMaintenanceMessage : editCraftsmanConnectionError}
            </div>
          ) : <CraftsmanConnectionPermissions connectionId={connectionId} />
          }
          {isDeleting && <LoadingIndicator />}
          <div className={styles.buttonContainer}>
            {(editConnectionFailed && !isManual) ? (
              <Button className={styles.btn} onClick={onResetError}>
                Nochmal versuchen
              </Button>
            ) : (
              <>
                {craftsmanConnections && Object.keys(craftsmanConnections).length !== 1
                && (
                <Button
                  disabled={isDeleting}
                  className={styles.btn}
                  onClick={() => goToRemove(connectionId)}
                >
                  Verknüpfung entfernen
                </Button>
                ) }

                <Button
                  className={styles.primaryBtn}
                  disabled={!(username && password && !editConnectionIsLoading)}
                  onClick={updateConnectionToCompany}
                  data-testid="update-button"
                >
                  Aktualisieren
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

CraftsmanConnectionModalEdit.propTypes = {
  goToRemove: PropTypes.func.isRequired,
  connectionId: PropTypes.string.isRequired,
};

export default CraftsmanConnectionModalEdit;
