import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalSearchInput } from 'gcs-common/slices/administration/globalSearch/globalSearchSelectors';
import { showSuccessNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import Table from '../../../Table/Table';
import ProfileImageCell from '../../../Table/ProfileImageCell/ProfileImageCell';
import AddFabButton from '../../../AddFabButton/AddFabButton';
import addIcon from '../../../../img/plus_white.svg';
import AgentPropType from '../../../../models/AgentPropType';
import TeamPropType from '../../../../models/TeamPropType';
import TeamAddUser from '../TeamAddUser/TeamAddUser';
import deletedIcon from '../../../../img/delete.svg';
import TeamRemoveUser from '../TeamRemoveUser/TeamRemoveUser';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import { stringSortingFunction } from '../../../../helper/sortHelper';

const userColumns = [
  {
    Header: 'Profilbild',
    accessor: 'imageUrl',
    Cell: ProfileImageCell,
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'friendlyName',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Angegebener Firmenname',
    accessor: 'userCompany',
    filter: 'fuzzyText',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Email',
    accessor: 'email',
    sortType: stringSortingFunction,
  },
  {
    Header: 'Datenschutz akzeptiert',
    accessor: 'privacyAccepted',
  },
];

const TeamUserTable = ({ team = {}, members = [] }) => {

  const globalSearchInput = useSelector(getGlobalSearchInput);

  const [showAddUserToTeam, setShowAddUserToTeam] = useState(false);
  const [removeUser, setRemoveUser] = useState(null);

  const dispatch = useDispatch();

  const deleteSelected = useCallback(
    (event, { original }) => {
      event.stopPropagation();
      setRemoveUser(original);
    },
    [],
  );

  const teamUserDeletedSuccessfully = useCallback(() => {
    setRemoveUser(null);
    dispatch(showSuccessNotification('Erfolgreich entfernt'));
  }, [dispatch]);

  const teamUserCreatedSuccessfully = useCallback(() => {
    setShowAddUserToTeam(false);
    dispatch(showSuccessNotification('Erfolgreich hinzugefügt'));
  }, [dispatch]);

  return (
    <>
      <Table
        data={members}
        columns={userColumns}
        filterString={globalSearchInput}
        renderActions={({ row }) => (
          <>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              alt="deleted-icon"
              src={deletedIcon}
              onClick={(event) => deleteSelected(event, row)}
            />
          </>
        )}
      />
      <AddFabButton title="Mitarbeiter" icon={addIcon} onClick={() => setShowAddUserToTeam(true)} />
      {showAddUserToTeam && (
        <PopUpModal
          title="Mitarbeiter hinzufügen"
          onRequestClose={() => setShowAddUserToTeam(false)}
          isOpen
        >
          <TeamAddUser
            team={team}
            onCancel={() => setShowAddUserToTeam(false)}
            onSuccess={teamUserCreatedSuccessfully}
          />
        </PopUpModal>
      )}
      {removeUser && (
        <PopUpModal
          title="Mitarbeiter entfernen"
          onRequestClose={() => setRemoveUser(null)}
          isOpen
        >
          <TeamRemoveUser
            team={team}
            user={removeUser}
            onCancel={() => setRemoveUser(null)}
            onSuccess={teamUserDeletedSuccessfully}
          />
        </PopUpModal>
      )
      }
    </>
  );
};

TeamUserTable.propTypes = {
  team: TeamPropType.isRequired,
  members: PropTypes.arrayOf(AgentPropType).isRequired,
};

export default TeamUserTable;
