import { MESSAGE_TYPE } from '../constants/MessageTypes';
import CraftsmanNotificationMessageModel from '../models/message/CraftsmanNotificationMessageModel';
import MediaFromMailMessageModel from '../models/message/MediaFromMailMessageModel';
import MediaMessageModel from '../models/message/MediaMessageModel';
import ProcessMessageModel from '../models/message/ProcessMessageModel';
import ProductMessageModel from '../models/message/ProductMessageModel';
import ReplyMessageModel from '../models/message/ReplyMessageModel';
import TextMessageModel from '../models/message/TextMessageModel';
import VideoMessageModel from '../models/message/VideoMessageModel';
import OrderMessageModel from '../models/message/OrderMessageModel';


const messageFactory = (messageData) => {
  const { messageType } = messageData;

  switch (messageType) {
    case MESSAGE_TYPE.CRAFTSMAN_NOTIFICATION:
      return new CraftsmanNotificationMessageModel(messageData);
    case MESSAGE_TYPE.MEDIA:
      return new MediaMessageModel(messageData);
    case MESSAGE_TYPE.MEDIA_FROM_MAIL:
      return new MediaFromMailMessageModel(messageData);
    case MESSAGE_TYPE.PROCESS:
      return new ProcessMessageModel(messageData);
    case MESSAGE_TYPE.PRODUCT:
      return new ProductMessageModel(messageData);
    case MESSAGE_TYPE.REPLY:
      return new ReplyMessageModel(messageData);
    case MESSAGE_TYPE.TEXT:
      return new TextMessageModel(messageData);
    case MESSAGE_TYPE.VIDEO:
      return new VideoMessageModel(messageData);
    case MESSAGE_TYPE.ORDER:
      return new OrderMessageModel(messageData);
    default:
      throw Error(`messageFactory: Unknown message type! ${messageData}`);
  }
};

export default messageFactory;
