/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  sharedFiles: [],
  selectedChannelForSharing: '',
  isFilesShareWasClosed: false,
  fileShareLoading: false,
};

const fileToAppSharingSlice = createSlice({
  name: 'fileToAppSharing',
  initialState,
  reducers: {
    sharedFilesAdded: (state, action) => {
      const { sharedFiles } = action.payload;
      state.sharedFiles = sharedFiles;
    },
    sharedFilesCleared: (state) => {
      state.sharedFiles = [];
    },
    filesShareClosed: (state) => {
      state.isFilesShareWasClosed = true;
    },
    filesShareReopened: (state) => {
      state.isFilesShareWasClosed = false;
    },
    channelForFileSharingSelected: (state, action) => {
      const { channelId } = action.payload;
      state.selectedChannelForSharing = channelId;
    },
    fileShareLoadSucceeded: (state) => {
      state.fileShareLoading = false;
    },
    fileShareLoadingStarted: (state) => {
      state.fileShareLoading = true;
    },
  },
});


export const {
  sharedFilesAdded,
  sharedFilesCleared,
  filesShareClosed,
  filesShareReopened,
  channelForFileSharingSelected,
  fileShareLoadSucceeded,
  fileShareLoadingStarted,
} = fileToAppSharingSlice.actions;

export default fileToAppSharingSlice.reducer;
