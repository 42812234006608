

const privacyText = [
  <h2>Datenschutzerklärung BEEM</h2>,
  <b>§ 1 Information über die Erhebung personenbezogener Daten</b>,
  `
  
  (1) Wir stellen Ihnen neben im Rahmen unseres Online-Angebots und auch als mobile App eine Kundeninteraktionsplattform unter der Bezeichnung „BEEM“ zur Verfügung, die Sie auf Ihr mobiles Endgerät herunterladen können. Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung von BEEM. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
  
  (2) Verantwortlicher für die Datenverarbeitung ist:
  
  GC Großhandels Contor GmbH
  Altenwall 6, 28195 Bremen
  E-Mail: info@gc-gruppe.de

  Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
  
  Die Kontaktdaten unseres betrieblichen Datenschutzbeauftragten lauten:
  
  Der Datenschutzbeauftragte
  An der Riede 1
  28816 Stuhr
  E-Mail: datenschutz@gc-gruppe.de

  (3) Bei der Nutzung der App oder der Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular wird Ihre E-Mail-Adresse und, falls Sie von Ihnen angegeben werden, Ihr Name und Ihre Telefonnummer von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder nach Ablauf von gesetzlichen Aufbewahrungspflichten.
  
  (4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
  
  `,
  <b>§ 2 SSL- bzw. TLS-Verschlüsselung</b>,
  `
  
  Wir nutzen aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Koordinaten oder Anfragen, die Sie an uns senden, eine SSL-bzw. TLS-Verschlüsselung. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
  
  `,
  <b>§ 3 Ihre Rechte</b>,
  `
  
  (1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
  
  ● Recht auf Auskunft,
  ● Recht auf Berichtigung oder Löschung,
  ● Recht auf Einschränkung der Verarbeitung,
  ● Recht auf Widerspruch gegen die Verarbeitung,
  ● Recht auf Datenübertragbarkeit.

  (2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten in unserem Unternehmen zu beschweren.
  
  `,
  <b>§ 4 Erhebung personenbezogener Daten bei Nutzung von BEEM</b>,
  `
  
  (1) Bei Herunterladen der mobilen App werden die erforderlichen Informationen an den App Store übertragen, also insbesondere Nutzername, Handynummer, E-Mail-Adresse und Kundennummer Ihres Accounts, Zeitpunkt des Downloads, Zahlungsinformationen (soweit erforderlich) und die individuelle Gerätekennziffer. Auf diese Datenerhebung haben wir keinen Einfluss und sind nicht dafür verantwortlich. Wir verarbeiten die Daten nur, soweit es für das Herunterladen der mobilen App auf Ihr mobiles Endgerät notwendig ist. 

  BEEM kann auch über unsere Website in dem vom Kunden genutzten Browser verwendet werden. 

  (2) Bei der Registrierung und Nutzung von BEEM erheben wir die nachfolgend beschriebenen personenbezogenen Daten. Wenn Sie unsere mobile App nutzen möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Funktionen unserer mobilen App anzubieten und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
  
  ● Name
  ● Handynummer
  ● E-Mail-Adresse
  ● IP-Adresse
  ● Profilbild (freiwillig)
  ● Datum und Uhrzeit der Anfrage
  ● Zeitzonendifferenz zur Greenwich Mean Time (GMT)
  ● Inhalt der Anforderung (konkrete Seite)
  ● Zugriffsstatus/HTTP-Statuscode
  ● jeweils übertragene Datenmenge
  ● Website, von der die Anforderung kommt
  ● Browser
  ● Betriebssystem und dessen Oberfläche
  ● Sprache und Version der Browsersoftware.
  
  (3) Weiterhin benötigen wir Ihre Gerätekennzeichnung, eindeutige Nummer des Endgerätes (IMEI = International Mobile Equipment Identity), eindeutige Nummer des Netzteilnehmers (IMSI = International Mobile Subscriber Identity), Mobilfunknummer (MSISDN), MAC-Adresse für WLAN-Nutzung, Name Ihres mobilen Endgerätes, E-Mail-Adresse.

  (4) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung von BEEM  Cookies auf Ihrem Endgerät gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die im Gerätespeicher Ihres mobilen Endgerätes abgelegt und der von Ihnen verwendeten mobilen App zugeordnet gespeichert werden. Durch Cookies können der Stelle, die das Cookie setzt (hier: uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihr mobiles Endgerät übertragen. Sie dienen dazu, mobile Apps insgesamt nutzerfreundlicher und effektiver zu machen.
  
  a) Diese mobile App nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:
    ● Transiente Cookies (dazu b)
    ● Persistente Cookies (dazu c).
  
  b) Transiente Cookies werden automatisiert gelöscht, wenn Sie die mobile App schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihrer mobilen App zuordnen lassen. Dadurch kann Ihr mobiles Endgerät wiedererkannt werden, wenn Sie unsere mobile App erneut nutzen. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder die App schließen.
  
  c) Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Einstellungen Ihres mobilen Betriebssystems und der App nach Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle Funktionen unserer mobilen App nutzen können.

  `,
  <b>§ 5 Userlane</b>,
  `
  
  Userlane
  Unsere Webseite nutzt Userlane, um Ihnen Anleitungen und Hilfestellungen für unsere Plattform bereitzustellen. Userlane ermöglicht es uns, Ihnen Features und Bedienung während der Benutzung virtuell zu erläutern. Anbieter ist die Userlane GmbH, Georgenstr. 39, 80779 München.

  Durch die Anmeldung als Kunde werden Cookies gespeichert, die zur Analyse der Benutzung von Userlane dienen. Diese Informationen werden in unserem Auftrag auf einen Server von Userlane übermittelt und dort gespeichert. Es findet dabei keine Zusammenführung der vom Browser übermittelten IP-Adresse mit weiteren Daten von Userlane statt. Eine Übersicht zu den Cookies finden Sie auf:
  `,
  <a href="https://docs.userlane.com/docs/userlanejs-cookies" rel="noreferrer" target="_blank">https://docs.userlane.com/docs/userlanejs-cookies.</a>,
  `
  
  Rechtsgrundlage
  Die Nutzung von Userlane im Rahmen unserer Web-Applikation erfolgt auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie die entsprechende Einstellung in den Cookie-Einstellungen dieser Webseite vornehmen. Außerdem können Sie die Speicherung von Cookies durch eine entsprechende Einstellung Ihrer Browsersoftware (z.B. „Do-Not-Track“-Einstellung des Browsers oder Browser Add-On) verhindern. Weiterführende Informationen zum Datenschutz bei Userlane finden Sie auf: https://www.userlane.com/privacy.

  Speicherdauer
  Die Daten, welche im Zusammenhang mit der Nutzung von Userlane verarbeitet werden, werden so lange gespeichert, wie dies für den genannten Zweck erforderlich ist oder bis Sie Ihre Einwilligung uns gegenüber widerrufen.

  `,
  <b>§ 6 Technische Umsetzung</b>,
  `
  
  BEEM wurde von unserem Dienstleister, der lary tech GmbH, Türkenstr. 38, 80799 München umgesetzt und wird auch von lary tech gehostet. Wir haben mit lary tech zur Gewährleistung des Datenschutzes und der Sicherheit ihrer Daten eine Vereinbarung über Auftragsverarbeitung i.S.d. Art. 28 Abs.3 DSGVO geschlossen. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von lary tech.

  `,
  <b>§ 7 Übermittlung in Drittstaaten</b>,
  `
  
  Mit Ausnahme der Verarbeitungen, bei denen wir über die Möglichkeit der Übermittlung von Daten an Empfänger mit Sitz außerhalb der EU in diesen Hinweisen zum Datenschutz aufklären, geben wir Ihre Daten nicht an Empfänger mit Sitz außerhalb der Europäischen Union oder des Europäischen Wirtschaftsraumes weiter. Die Datenübermittlungen erfolgen in der Regel auf Grundlage sogenannter Standardvertragsklauseln der EU-Kommission oder eines gültigen Angemessenheitsbeschlusses.

  `,
  <b>§ 8 Supportanfragen</b>,
  `

  Zur Bearbeitung von Supportanfragen verwenden wir das CRM-System Zendesk. Anbieter ist die Zendesk, Inc, 1019 Market Street in San Francisco, CA 94103 USA. Wir nutzen Zendesk, um Anfragen der Nutzer schnell und effizient bearbeiten zu können. Die Verarbeitung der Daten erfolgt zur Vertragserfüllung auf Basis von Art. 6 Abs. 1 lit. b) DSGVO oder auf Basis unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f) DSGVO. Sollten Sie nicht mit der Bearbeitung einer Anfrage über Zendesk einverstanden sein, können Sie alternativ per E-Mail, Telefon oder Fax mit uns kommunizieren. Es besteht ein Vertrag über Auftragsverarbeitung (Data Processing Agreement, DPA). Dadurch ist sichergestellt, dass der Anbieter die Nutzer-Daten nur im Rahmen der EU-Datenschutznormen ausschließlich zur Verarbeitung der Anfragen nutzt und diese nicht an Dritte weitergibt. Zusätzlich besteht mit Zendesk ein Vertrag über Auftragsverarbeitung (Data Processing Agreement, DPA). Dadurch ist sichergestellt, dass Zendesk die Nutzer-Daten nur im Rahmen der EU-Datenschutznormen ausschließlich zur Verarbeitung der Anfragen nutzt und diese nicht an Dritte weitergibt. Weitere Informationen finden sich in der Datenschutzerklärung von Zendesk unter: https://www.zendesk.de/company/customers-partners/privacy-policy. 
  
  `,
  <b>§ 9 Verwendung von Adobe Analytics</b>,
  `

  Wir setzen in unserem Online-Angebot nach erteilter Einwilligung den Webanalysedienst Adobe Analytics ein. Dieser Dienst ist in der EU, im EWR und der Schweiz ein Angebot der Adobe Systems Software Ireland Limited, 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland, in den USA der Adobe Inc., Adobe 345 Park Avenue, San Jose, CA 95110-2704, USA ("Adobe"). Mit diesem Anbieter haben wir einen Vertrag zur Auftragsverarbeitung abgeschlossen. Adobe Analytics verwendet Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Nutzung unseres Online-Angebots ermöglichen. Wir verwenden Adobe Analytics in unserem Online-Angebot ausschließlich mit sog. datenschutzfreundlichen Voreinstellungen: Dabei handelt es sich um die Aktivierung der Einstellungen „Before Geo-Lookup: Replace visitor’s last IP octet with 0“ und „Obfuscate IP-Removed“. Durch die Einstellung „Before Geo-Lookup: Replace visitor’s last IP octet with 0“ wird die IP-Adresse vor der Geo-Lokalisierung anonymisiert, indem das letzte Oktett der IP-Adresse durch Nullen ersetzt wird. Die Einstellung „Obfuscate IP –Removed“ bewirkt, dass die echte IP-Adresse durch eine generische IP-Adresse ersetzt wird. Diese Anonymisierung Ihrer IP-Adresse schließt eine direkte Personenbeziehbarkeit aus.

  Zudem übermitteln wir an Adobe lediglich Ihre pseudonymisierte Kundennummer. Adobe kann nicht nachvollziehen, um welche natürliche Person es geht. Die Kundennummer nutzen wir ausschließlich intern dazu, um Ihre Bestellung abzuwickeln (Art. 6 Abs. 1 Buchstabe b DSGVO) und Prozessoptimierungen vorzunehmen (Art. 6 Abs. 1 Buchstabe f DSGVO). Die durch das Cookie erzeugten Informationen über Ihre Nutzung unseres Online-Angebots (einschließlich Ihrer gekürzten/anonymisierten IP-Adresse) werden an einen Server von Adobe in Irland übertragen und dort gespeichert. Eine Übertragung in die USA ist nicht auszuschließen (siehe hierzu auch den Abschnitt „Setzen wir Cookies von Drittanbietern ein?“ in unserer Datenschutzerklärung). Adobe wertet auf diese Weise die Nutzung unseres Online-Angebots aus, um für uns statistische Berichte über die Aktivitäten in unserem Online-Angebot zu erstellen und um weitere mit der Nutzung verbundene Dienstleistungen an uns zu erbringen.

  Wir setzen die Cookies von Adobe Analytics nur ein, wenn Sie dazu über unser Cookie-Consent-Banner Ihre Einwilligung erteilt haben (Art. 6 Abs. 1 Buchstabe a DSGVO). Weitere Rechtsgrundlage für die Verarbeitung der anonymisierten IP-Adresse ist unser berechtigtes Interesse an der Verbesserung der Nutzbarkeit unseres Online-Angebots, soweit für uns kein entgegenstehendes Interesse erkennbar ist und auch kein Widerspruch vorliegt (Art. 6 Abs. 1 Buchstabe f DSGVO). Sie können Ihre einmal über unser Cookie-Consent-Banner erteilte Einwilligung in die Verarbeitung Ihrer Daten mit Adobe Analytics jederzeit widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Weitere Informationen zum Widerruf der Einwilligung finden in dieser Datenschutzerklärung im Abschnitt “Ihre Rechte”. Haben Sie die Deaktivierung der Adobe-Cookies vorgenommen, stehen Ihnen möglicherweise nicht alle Funktionen unserer Website in vollem Umfang für eine Nutzung zur Verfügung.

  Mehr Informationen zum Umgang mit Nutzerdaten bei Adobe Analytics finden Sie in der Datenschutzerklärung von Adobe:
  `,
  <a href="https://marketing.adobe.com/resources/help/en_US/whitepapers/cookies/cookies_privacy.html" rel="noreferrer" target="_blank">https://marketing.adobe.com/resources/help/en_US/whitepapers/cookies/cookies_privacy.html.</a>,
  <p>
    bzw. unter:
    <a href="https://www.adobe.com/de/privacy.html" rel="noreferrer" target="_blank"> https://www.adobe.com/de/privacy.html</a>
    .
  </p>,
];

export default privacyText;
