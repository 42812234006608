import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class ProductMessageModel extends AbstractMessageModel {

  constructor({ product, ...args } = {}) {
    super({
      messageType: MESSAGE_TYPE.PRODUCT,
      ...args,
    });

    this.product = product;
  }
}
