import PropTypes from 'prop-types';
import { ICON } from 'gcs-common/constants/IconConstants';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';

const ChannelSearchBar = ({ onChange, placeholder = '', value = '', style = {} }) => {

  return (
    <div className={styles.searchContainer} style={style}>
      <IconComponent Icon={ICON.SEARCH} style={{ marginRight: '2px' }} />
      <input
        className={styles.searchInput}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type="search"
      />
    </div>
  );
};

export default ChannelSearchBar;

ChannelSearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};
