import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIsHidden } from '../../channel/channelSelectors';
import { getCurrentUser } from '../../currentUser/currentUserSelectors';

export const toggleChannelVisibility = createAsyncThunk(
  'currentUser/toggleChannelVisibility',
  async ({ channelId }, { getState, extra: { gccApiClient } }) => {
    const currentUser = getCurrentUser(getState());
    const isHidden = getIsHidden(channelId)(getState());

    const hiddenChannelIds = isHidden
      ? currentUser.hiddenChannels.filter(hiddenChannelId => hiddenChannelId !== channelId)
      : [...currentUser.hiddenChannels, channelId];
    return gccApiClient.updateUserHiddenChannels(hiddenChannelIds);
  },
);
