import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIsOnline } from '../offline/offlineSelectors';
import { openErrorDialog } from '../errorDialogSlice/errorDialogSlice';
import { getIsAgent } from '../currentUser/currentUserSelectors';
import { getSelectedHouseId } from '../houses/housesSelectors';

const fetchNewChannelOptions = createAsyncThunk(
  'newChannelOptions/fetchNewChannelOptions',
  async (
    { newChannelOptionTypesToFetch, searchTerm, nextSlice, limit },
    { rejectWithValue, dispatch, getState, extra: { gccApiClient } },
  ) => {
    const online = getIsOnline(getState());
    const isAgent = getIsAgent(getState());
    const selectedHouseId = getSelectedHouseId(getState());

    const houseId = !isAgent && selectedHouseId;

    try {
      if (!online) {
        dispatch(openErrorDialog({ dialogErrorMsg: 'Sie sind offline. Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.' }));
        return {};
      }

      const response = await gccApiClient.geNewChannelOptions(
        newChannelOptionTypesToFetch, searchTerm, nextSlice, limit, houseId,
      );

      // Handles response errors
      if (response.error_code) {
        return rejectWithValue({ error: response.error_code });
      }

      return response;
    } catch (error) {
      return rejectWithValue({ error });
    }
  },
);

export default fetchNewChannelOptions;
