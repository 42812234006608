import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedErpCustomer,
  getUpdateErpCustomerErrorMessage,
  getUpdateErpCustomerDefaultErrorMessage,
  getIsFetchSelectedErpCustomerSucceeded,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSelectors';
import { getSelectedHouseLdaNumbers } from 'gcs-common/slices/administration/houses/housesSelectors';
import {
  updateErpCustomer,
  fetchSelectedErpCustomer,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersThunks';
import {
  resetUpdateSelectedErpCustomerDefaultErrorMessage,
  resetUpdateSelectedErpCustomerErrorMessage,
  erpCustomerSelectionReset,
} from 'gcs-common/slices/administration/erpCustomers/erpCustomersSlice';
import {
  fetchAllAssignmentRoles,
} from 'gcs-common/slices/administration/houseCommunicationStructures/houseCommunicationStructureThunks';
import {
  fetchAllResponsibilityRoles,
} from 'gcs-common/slices/administration/customerResponsibilityMembers/customerResponsibilityMembersThunk';
import { fetchUserDetails } from 'gcs-common/slices/administration/administrationUsers/administrationUsersThunks';
import { CHANNEL_TYPE } from 'gcs-common/helper/channelTypeHelper';
import { fetchSelectedHouse } from 'gcs-common/slices/administration/houses/housesThunks';
import { globalHouseIdSelected } from 'gcs-common/slices/administration/houses/housesSlice';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import { showSuccessNotification, showFailureNotification } from '../../../../slices/notifications/ShowNotificationThunk';
import styles from './styles.module.scss';
import backIcon from '../../../../img/back.svg';
import usePrevious from '../../../../customHooks/usePrevious';
import FormikInput from '../../../FormikInput/FormikInput';
import FormikSelect from '../../../FormikSelect/FormikSelect';
import EditIcon from '../../../../img/edit.svg?react';
import CheckIcon from '../../../../img/check_green.svg?react';
import CancelIcon from '../../../../img/close_red.svg?react';

import SearchBar from '../../../SearchBar/SearchBar';
import Tabs from '../../../Tabs/Tabs';
import ErpCustomerUserTable from '../ErpCustomerUserTable/ErpCustomerUserTable';
import ErpCustomerMigratePopup from '../ErpCustomerMigratePopup/ErpCustomerMigratePopup';
import PopUpModal from '../../../PopUpModal/PopUpModal';
import { ErpCustomerValidationSchema } from '../ErpCustomerCreate/ErpCustomerCreate';
import ErpCustomerDirectChannelTable from '../ErpCustomerDirectChannelTable/ErpCustomerDirectChannelTable';
import ErpCustomerGroupChannelTable from '../ErpCustomerGroupChannelTable/ErpCustomerGroupChannelTable';
import ErpCustomerAssignments from '../../ErpCustomerAssignments/ErpCustomerAssignments';
import CustomerResponsibilityMembersTable from '../../CustomerResponsibilityMembers/CustomerResponsibilityMembersTable/CustomerResponsibilityMembersTable';

const ChangedAgentModal = ({ monitorField }) => {

  const [showAgentChangedWarning, setShowAgentChangedWarning] = useState(false);

  const { values, initialValues, setFieldValue, setFieldTouched } = useFormikContext();
  const prevValues = usePrevious(values) || initialValues;
  const [resetToValues, setResetToValues] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {

    let changedAgentId = null;
    if (
      values[monitorField] !== prevValues[monitorField]
      && prevValues[monitorField] !== ''
      && values[monitorField] !== initialValues[monitorField]
      // don't show the popup if the change was by our wish
      && values[monitorField] !== resetToValues
    ) {
      const previousAgentId = prevValues[monitorField]?.id;
      const newAgentId = values[monitorField]?.id;
      changedAgentId = (newAgentId !== previousAgentId) ? newAgentId : null;
    }

    if (changedAgentId) {
      (async () => {
        const {
          payload: { user: agent },
        } = await dispatch(fetchUserDetails({ userId: changedAgentId }));
        setShowAgentChangedWarning(agent.friendlyName);
        setResetToValues(prevValues[monitorField]);
      })();
    }
  }, [dispatch, values, prevValues, initialValues, monitorField, resetToValues]);

  const onCancel = useCallback(() => {
    setFieldValue(monitorField, resetToValues, false);
    setFieldTouched(monitorField, false);
    setShowAgentChangedWarning(null);

  }, [monitorField, resetToValues, setFieldValue, setFieldTouched, setShowAgentChangedWarning]);

  const onSuccess = useCallback(() => {
    setShowAgentChangedWarning(null);
  }, [setShowAgentChangedWarning]);

  return (
    <div>
      {showAgentChangedWarning && (
        <PopUpModal
          title="KUNDENCHATS ÜBERTRAGEN"
          isOpen
          onRequestClose={() => { }}
        >
          <ErpCustomerMigratePopup
            agentName={showAgentChangedWarning}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </PopUpModal>
      )
      }
    </div>
  );
};

ChangedAgentModal.propTypes = {
  monitorField: PropTypes.string.isRequired,
};

const ErpCustomerDetail = () => {
  const { id: erpCustomerId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const selectedErpCustomer = useSelector(getSelectedErpCustomer(erpCustomerId));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await dispatch(fetchSelectedErpCustomer({ erpCustomerId }));
    })();
    return () => dispatch(erpCustomerSelectionReset());
  }, [erpCustomerId, dispatch]);
  const errorMessage = useSelector(getUpdateErpCustomerErrorMessage);
  const defaultErrorMessage = useSelector(getUpdateErpCustomerDefaultErrorMessage);
  const houseLDAs = useSelector(getSelectedHouseLdaNumbers);
  const isSelectedErpCustomerLoaded = useSelector(getIsFetchSelectedErpCustomerSucceeded);

  const {
    customerNumber: customerId,
    ldaNumber,
    description,
    lsp,
    friendlyName,
    shortName,
    houseId,
    existsInExternalSource,
    createdBy,
  } = selectedErpCustomer || {};

  useEffect(() => {
    if (defaultErrorMessage) {
      dispatch(showFailureNotification(
        defaultErrorMessage,
        dispatch(resetUpdateSelectedErpCustomerDefaultErrorMessage()),
      ));
    }
  }, [defaultErrorMessage, dispatch]);

  useEffect(() => {
    dispatch(resetUpdateSelectedErpCustomerErrorMessage());
    dispatch(resetUpdateSelectedErpCustomerDefaultErrorMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedErpCustomer({ erpCustomerId }));
    dispatch(fetchAllResponsibilityRoles({ view: 'communication_structure', houseId }));
    dispatch(fetchAllAssignmentRoles({}));
    return () => dispatch(erpCustomerSelectionReset());
  }, [dispatch, erpCustomerId, houseId]);

  useEffect(() => {
    if (houseId) {
      dispatch(globalHouseIdSelected({ houseId }));
      dispatch(fetchSelectedHouse({ houseId }));
    }
  }, [dispatch, houseId]);

  const onCustomerIdChanged = useCallback(() => {
    if (errorMessage) {
      dispatch(resetUpdateSelectedErpCustomerErrorMessage());
    }
  }, [dispatch, errorMessage]);

  const cancelEditing = useCallback((event) => {
    event.preventDefault();
    dispatch(resetUpdateSelectedErpCustomerErrorMessage());
    setIsEditing(false);
  }, [dispatch]);

  const startEditing = useCallback((event) => {
    event.preventDefault();
    setIsEditing(true);
  }, []);

  const {
    city,
    street,
    zip,
  } = selectedErpCustomer?.address || {};

  const initialValues = useMemo(() => {
    // Note: Formik/React creates a warning if a value is "null"
    // so we set all fields to at least ""
    // https://formik.org/docs/api/formik#initialvalues-values
    return ({
      city: city || '',
      customerNumber: customerId || '',
      description: description || '',
      lsp: lsp || '',
      friendlyName: friendlyName || '',
      shortName: shortName || '',
      street: street || '',
      zip: zip || '',
      ldaNumber: ldaNumber || [],
    });
  }, [city, customerId, description, lsp, friendlyName, shortName, street, zip, ldaNumber]);

  const submit = useCallback(async (values, { setSubmitting }) => {

    const result = await dispatch(updateErpCustomer({ erpCustomerId, values }));
    if (!result.error) {
      setIsEditing(false);
      dispatch(showSuccessNotification('Erfolgreich geändert'));
    }
    setSubmitting(false);
  }, [erpCustomerId, dispatch]);

  const [searchInputValue, setSearchInputValue] = useState('');

  const onSearch = useCallback((event) => {
    const input = event.target.value;
    setSearchInputValue(input);
  }, []);

  const onCancelSearch = useCallback(() => {
    setSearchInputValue('');
  }, []);

  const isAutomaticallyProvisionedIndicationVisible = (isProvisionedAutomatically) => {
    return !!isProvisionedAutomatically;
  };

  const isCreatedByIndicationVisible = (isProvisionedAutomatically) => {
    return !isAutomaticallyProvisionedIndicationVisible(isProvisionedAutomatically);
  };

  const getCreatedByLabelValue = (isProvisionedAutomatically, createdByUser) => {
    if (isCreatedByIndicationVisible(isProvisionedAutomatically)) {
      return createdByUser || 'user management';
    }
    return null;
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={ErpCustomerValidationSchema}
        onSubmit={submit}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>

            <div className={styles.headerRow}>
              <div className={styles.headerRowItem}>
                <button
                  className={styles.backButton}
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  <img src={backIcon} alt="back-icon" />
                </button>
                <div className={styles.inputHeaderText}>{friendlyName}</div>
                {isAutomaticallyProvisionedIndicationVisible(existsInExternalSource)
                  && (
                    <div className={styles.entityTypeWrapper}>
                      <span className={styles.entityTypeTag}>Automatisch bereitgestellt</span>
                    </div>
                  )
                }
              </div>
              <div className={styles.buttonsWrapper}>
                {isEditing ? (
                  <>
                    <button
                      className={styles.saveButton}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <CheckIcon />
                    </button>
                    <button
                      className={styles.saveButton}
                      type="button"
                      onClick={(event) => {
                        resetForm();
                        cancelEditing(event);
                      }}
                      disabled={isSubmitting}
                    >
                      <CancelIcon />
                    </button>
                  </>
                ) : (
                  <button
                    className={styles.saveButton}
                    type="button"
                    onClick={startEditing}
                    disabled={!isSelectedErpCustomerLoaded}
                  >
                    <EditIcon />
                  </button>
                )}
                {isSubmitting && <LoadingIndicator padding="0px" />}
              </div>
            </div>

            <div className={styles.rowWrapper}>
              <div className={styles.row}>
                <FormikInput disabled={!isEditing} label="Name*" name="friendlyName" />
                <FormikInput disabled={!isEditing} label="Kurzname" name="shortName" />
              </div>
              <div className={styles.row}>
                <FormikInput
                  disabled={!isEditing}
                  label="Kundennummer*"
                  type="text"
                  name="customerNumber"
                  onKeyUp={onCustomerIdChanged}
                />
                <FormikSelect
                  options={houseLDAs.map(lda => ({ key: lda, value: lda }))}
                  label="LDA*"
                  name="ldaNumber"
                  valueKey="key"
                  labelKey="value"
                  disabled={!isEditing}
                />
              </div>
              <div className={styles.row}>
                <FormikInput disabled={!isEditing} label="LSP" name="lsp" />
                {isCreatedByIndicationVisible(existsInExternalSource)
                  && <FormikInput disabled label="Erstellt von" name="creator" value={getCreatedByLabelValue(existsInExternalSource, createdBy)} />
                }
              </div>
            </div>

            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

            <FormikInput disabled={!isEditing} label="Beschreibung" name="description" />

            <div className={styles.rowWrapper}>
              <div className={styles.cell}>
                <FormikInput disabled={!isEditing} label="Straße und Hausnummer" name="street" />
              </div>

              <div className={styles.cell}>
                <FormikInput disabled={!isEditing} label="PLZ" name="zip" />
                <FormikInput disabled={!isEditing} label="Stadt" name="city" />
              </div>

            </div>
          </Form>
        )}
      </Formik>

      <div className={styles.searchWrapper}>
        <SearchBar
          onSearchInputChange={onSearch}
          resetFilter={onCancelSearch}
          searchInput={searchInputValue}
          placeholder="Suche..."
        />
      </div>
      <div className={styles.tabWrapper}>
        <Tabs
          onActiveTabChanged={onCancelSearch}
          disabled={!isSelectedErpCustomerLoaded}
          tabs={{
            assignments: {
              name: 'Kommunikationszuweisungen',
              component: (
                <div className={styles.rowWrapper}>
                  {isSelectedErpCustomerLoaded && (
                    <ErpCustomerAssignments
                      erpCustomerId={erpCustomerId}
                    />
                  )}
                </div>
              ),
            },
            responsibilities: {
              name: 'Verantwortlichkeiten',
              component: (
                <CustomerResponsibilityMembersTable filter={['customerNumber', 'customerName']} />
              ),
            },
            users: {
              name: 'Nutzer',
              component: (
                <div className={styles.rowWrapper}>
                  <ErpCustomerUserTable
                    erpCustomerId={erpCustomerId}
                    searchInputValue={searchInputValue}
                  />
                </div>
              ),
            },
            internalCraftsman: {
              name: 'Intern Handwerker',
              component: (
                <div className={styles.rowWrapper}>
                  <ErpCustomerDirectChannelTable
                    erpCustomerId={erpCustomerId}
                    searchInputValue={searchInputValue}
                    channelTypes={[CHANNEL_TYPE.INTERNAL_CRAFTSMAN]}
                  />
                </div>
              ),
            },
            directChat: {
              name: 'Direkte Chat',
              component: (
                <div className={styles.rowWrapper}>
                  <ErpCustomerDirectChannelTable
                    erpCustomerId={erpCustomerId}
                    searchInputValue={searchInputValue}
                    channelTypes={[CHANNEL_TYPE.DIRECT_CHAT]}
                  />
                </div>
              ),
            },
            groupChannels: {
              name: 'Teamchats',
              component: (
                <div className={styles.rowWrapper}>
                  <ErpCustomerGroupChannelTable
                    erpCustomerId={erpCustomerId}
                    searchInputValue={searchInputValue}
                  />
                </div>
              ),
            },
          }}
        />
      </div>
    </div>
  );
};
export default ErpCustomerDetail;
