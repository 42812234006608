
export const fetchArticleDetails = ({
  variant,
  supplier,
  runNumber,
  kbn,
  contextChannelId,
}) => async (
  dispatch, getState, { gccApiClient },
) => {
  const data = await gccApiClient.getArticleDetails({
    variant,
    supplier,
    runNumber,
    kbn,
    contextChannelId,
  });
  return data;
};
