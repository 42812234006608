import {
  useEffect, useState, useCallback, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getChannelInputStagedFiles, getChannelInputIsSendingFiles,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import {
  channelInputCleared, channelInputFileStaged,
  channelInputFileUnstaged,
} from 'gcs-common/slices/messageInput/messageInputSlice';
import { isImage } from 'gcs-common/helper/fileHelper';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';
import fileWhite from '../../img/file_white_full.svg';
import imageWhite from '../../img/image_white.svg';
import plusDark from '../../img/plus_dark.svg';
import closeWhite from '../../img/close_white.svg';

import FileInputWrapper from '../FileInputWrapper/FileInputWrapper';
import LocalFilePreview from '../LocalFilePreview/LocalFilePreview';
import FileThumbnail from './FileThumbnail/FileThumbnail';

function FilePreviewer(
  {
    hoverBoundaryClass,
    multiple = true,
  },
) {

  const selectedChannelId = useSelector(getSelectedChannelId);
  const loading = useSelector(getChannelInputIsSendingFiles(selectedChannelId));
  const files = useSelector(getChannelInputStagedFiles(selectedChannelId));
  const dispatch = useDispatch();

  const [imageIndex, setCurrentImageIndex] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const fileInputRef = useRef(null);
  const innerRef = useRef(null);

  useEffect(() => {
    if (multiple) {
      setCurrentImageIndex(files.length - 1);
      fileInputRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'start',
      });
    }
  }, [files, multiple]);

  const onRemoveImage = useCallback((file) => {
    dispatch(channelInputFileUnstaged({ channelId: selectedChannelId, file }));
  }, [dispatch, selectedChannelId]);

  const onClose = useCallback(() => {
    dispatch(channelInputCleared({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  const onClick = useCallback((index) => {
    setCurrentImageIndex(index);
  }, []);

  const onFileInputChanged = useCallback((inputFiles) => {
    inputFiles.forEach(file => {
      dispatch(channelInputFileStaged({ channelId: selectedChannelId, file }));
    });
  }, [dispatch, selectedChannelId]);

  const imagePreviews = useMemo(() => {
    return files && files.map((file, index) => (
      <FileThumbnail
        loading={loading}
        file={file}
        key={file.lastModified}
        alt="thumb-img-preview"
        selected={index === imageIndex}
        onClose={onRemoveImage}
        onClick={() => onClick(index)}
      />
    ));
  }, [files, imageIndex, loading, onClick, onRemoveImage]);

  // estimate proper size the layover
  const handleResize = useCallback(() => {

    const mainArea = document.getElementsByClassName(hoverBoundaryClass)[0];
    const otherHeight = [document.getElementsByClassName(styles.imagePickerBottom)[0],
      document.getElementsByClassName(styles.filePreviewHeader)[0]];
    // properly handle keyboard events
    if (mainArea && otherHeight[0] && otherHeight[1]) {
      const chatHeight = mainArea.offsetTop - otherHeight[0].offsetHeight
        - otherHeight[1].offsetHeight;
      setImageHeight(chatHeight);
    }
  }, [hoverBoundaryClass]);

  const handleKeyboard = useCallback((event) => {
    if (innerRef.current && event
      && (innerRef.current.contains(event.target) || event.target.contains(innerRef.current))) {
      if (event.code === 'ArrowRight') {
        setCurrentImageIndex(prevIndex => prevIndex + 1);
      } else if (event.code === 'ArrowLeft') {
        setCurrentImageIndex(prevIndex => prevIndex - 1);
      }
    }
    if (event.code !== 'Enter') {
      event.stopPropagation();
    }
  }, [innerRef]);

  // wrap around arrow movements (needs to be here and not in callback)
  useEffect(() => {
    if (imageIndex >= files.length) {
      setCurrentImageIndex(0);
    } else if (imageIndex < 0) {
      setCurrentImageIndex(files.length - 1);
    }
  }, [files, imageIndex]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('keyup', handleKeyboard);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keyup', handleKeyboard);
    };
  }, [handleKeyboard, handleResize]);

  // Makes sure the height responds to changes in the size of the input area
  useEffect(() => {
    const mainArea = document.getElementsByClassName(hoverBoundaryClass)[0];
    if (mainArea) {
      const resizeObserver = new ResizeObserver(() => {
        handleResize();
      });
      resizeObserver.observe(mainArea);

      return () => {
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, [hoverBoundaryClass, handleResize]);

  const currentImage = files[imageIndex];

  return (
    <div className={styles.filePreviewer} ref={innerRef}>
      <div>
        {loading
        && (
          <div className={styles.previewOverlay}>
            <LoadingIndicator />
          </div>
        )
        }
        <div
          className={styles.previewMain}
        >
          <div className={styles.filePreviewHeader}>
            <img
              src={isImage(currentImage) ? imageWhite : fileWhite}
              className={styles.fileTypeLogo}
              alt="main-preview-img"
            />
            {currentImage && (
              <div className={styles.headerText}>
                {currentImage.name}
              </div>
            )}
            <button
              disabled={loading}
              className={styles.closeButton}
              type="button"
              onClick={onClose}
            >
              <img
                src={closeWhite}
                alt="main-preview-close"
              />
            </button>
          </div>
          <SwipeableViews
            containerStyle={{ height: '100%' }}
            index={Math.max(0, imageIndex)}
            slideStyle={{ display: 'flex' }}
            onChangeIndex={onClick}
            enableMouseEvents
          >
            {files.map(file => (
              <div
                style={{ height: `${imageHeight.toString()}px`, width: '100%', display: 'flex', overflow: 'hidden' }}
                key={file.lastModified}
              >
                <LocalFilePreview
                  className={isImage(file)
                    ? styles.mainPreview : styles.mainPreviewFile}
                  alt="preview"
                  file={file}
                />
              </div>
            ))
            }
          </SwipeableViews>
        </div>
        <div className={styles.imagePickerBottom}>
          {imagePreviews}
          {multiple && (
            <div ref={fileInputRef}>
              <FileInputWrapper
                value={files}
                disabled={loading}
                className={styles.addImage}
                onChange={onFileInputChanged}
              >
                <img src={plusDark} alt="add-img" />
              </FileInputWrapper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FilePreviewer.propTypes = {
  hoverBoundaryClass: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

export default FilePreviewer;
