/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMsg } from '../../helper/errorMessages';
import ASYNC_STATE from '../../constants/AsyncState';
import { byId } from '../../helper/byKey';
import {
  addCraftsmanConnection, deleteCraftsmanConnection,
  fetchCraftsmanConnections,
  updateCraftsmanConnectionToCustomer,
} from './craftsmanConnectionsThunks';

const asyncState = {
  fetchCraftsmanConnections: {
    status: ASYNC_STATE.IDLE,
  },
  deleteCraftsmanConnection: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  addCraftsmanConnection: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
  updateCraftsmanConnectionToCustomer: {
    status: ASYNC_STATE.IDLE,
    errorMessage: null,
  },
};

const initialState = {
  ...asyncState,
  craftsmanConnections: {},
};

const craftsmanConnectionsSlice = createSlice({
  name: 'craftsmanConnections',
  initialState,
  reducers: {
    resetAddError: (state) => {
      state.addCraftsmanConnection.errorMessage = null;
      state.addCraftsmanConnection.status = ASYNC_STATE.IDLE;

    },
    resetEditError: (state) => {
      state.updateCraftsmanConnectionToCustomer.errorMessage = null;
      state.updateCraftsmanConnectionToCustomer.status = ASYNC_STATE.IDLE;
    },
    resetRemoveError: (state) => {
      state.deleteCraftsmanConnection.errorMessage = null;
      state.deleteCraftsmanConnection.status = ASYNC_STATE.IDLE;
    },
  },
  extraReducers: {
    [fetchCraftsmanConnections.pending]: (state) => {
      state.fetchCraftsmanConnections.status = ASYNC_STATE.LOADING;
    },
    [fetchCraftsmanConnections.fulfilled]: (state, action) => {
      const data = action.payload;
      state.fetchCraftsmanConnections.status = ASYNC_STATE.SUCCEEDED;

      state.craftsmanConnections = byId(data);
    },
    [fetchCraftsmanConnections.rejected]: (state) => {
      state.fetchCraftsmanConnections.status = ASYNC_STATE.FAILED;
    },
    [deleteCraftsmanConnection.pending]: (state) => {
      state.deleteCraftsmanConnection.status = ASYNC_STATE.LOADING;
    },
    [deleteCraftsmanConnection.fulfilled]: (state, action) => {
      const { connectionId } = action.meta.arg;
      state.deleteCraftsmanConnection.status = ASYNC_STATE.SUCCEEDED;
      delete state.craftsmanConnections[connectionId];
    },
    [deleteCraftsmanConnection.rejected]: (state, action) => {
      state.deleteCraftsmanConnection.status = ASYNC_STATE.FAILED;
      const {
        payload: { errorCode } = {},
      } = action;
      if (errorCode && errorCode === 'delete_connection_member.last_connection_member') {
        state.deleteCraftsmanConnection.errorMessage = getErrorMsg('delete_connection_member.last_connection_member');
      } else {
        state.deleteCraftsmanConnection.errorMessage = getErrorMsg('default');
      }
    },
    [updateCraftsmanConnectionToCustomer.pending]: (state) => {
      state.updateCraftsmanConnectionToCustomer.status = ASYNC_STATE.LOADING;
    },
    [updateCraftsmanConnectionToCustomer.fulfilled]: (state) => {
      state.updateCraftsmanConnectionToCustomer.status = ASYNC_STATE.SUCCEEDED;
    },
    [updateCraftsmanConnectionToCustomer.rejected]: (state, action) => {
      const {
        payload: { errorMessage } = {},
      } = action;
      state.updateCraftsmanConnectionToCustomer.status = ASYNC_STATE.FAILED;
      const errorMsg = errorMessage;
      state.updateCraftsmanConnectionToCustomer.errorMessage = errorMsg || getErrorMsg('update_connection_member.default');
    },
    [addCraftsmanConnection.pending]: (state) => {
      state.addCraftsmanConnection.errorMessage = null;
      state.addCraftsmanConnection.status = ASYNC_STATE.LOADING;
    },
    [addCraftsmanConnection.fulfilled]: (state) => {
      state.addCraftsmanConnection.status = ASYNC_STATE.SUCCEEDED;
    },
    [addCraftsmanConnection.rejected]: (state, action) => {
      const {
        payload: { errorMessage } = {},
      } = action;
      state.addCraftsmanConnection.status = ASYNC_STATE.FAILED;
      const errorMsg = errorMessage;
      state.addCraftsmanConnection.errorMessage = errorMsg || getErrorMsg('create_connection_member.default');
    },
  },
});

export const {
  resetAddError,
  resetRemoveError,
  resetEditError,
} = craftsmanConnectionsSlice.actions;

export default craftsmanConnectionsSlice.reducer;
