import { useEffect } from 'react';
import useRoundImageChecker from 'gcs-common/hooks/useRoundImageChecker';
import PropTypes from 'prop-types';
import { ONLINE_STATUS } from 'gcs-common/helper/userStatusHelper';
import UserStatusPropType from '../../models/UserStatusPropType';
import Image, { IMAGE_TYPE } from '../Image/Image';
import styles from './styles.module.scss';
import starIcon from '../../img/star_icon.svg';

const basicStatusStyle = {
  ONLINE: styles.aktivStatusIndicator,
  IDLE: styles.inkurzeVerfügbarStatusIndicator,
  OFFLINE: styles.inaktivStatusIndicator,
  UNKNOWN: styles.unknownStatusIndicator,
};

const dotStatusStyle = {
  ONLINE: styles.aktivStatusIndicatorDot,
  IDLE: styles.inkurzeVerfügbarStatusIndicatorDot,
  OFFLINE: styles.inaktivStatusIndicatorDot,
  UNKNOWN: styles.unknownStatusIndicatorDot,
};

const getStatusColor = ({
  type, isSalesSpace, isDot, isAgent, channelMemberCount,
}) => {
  if (isDot) return styles.dotStatusIndicator;
  if (!isAgent && isSalesSpace) return styles.noStatusIndicator;
  if (!isSalesSpace && channelMemberCount > 2) return styles.noStatusIndicator;
  return basicStatusStyle[type];
};

const getImageStyle = (isSalesSpace, isAgent) => {
  if (isSalesSpace && !isAgent) return styles.salesSpaceAgentWrapper;
  return styles.normalImageWrapper;
};

const ImageWithStatus = ({
  isSalesSpace = false,
  channelMemberCount = 0,
  imageUrl = null,
  userStatus = { type: ONLINE_STATUS.UNKNOWN },
  className = '',
  isSmall = false,
  isDot = false,
  isAgent = false,
  hasStar = false,
}) => {
  const { type } = userStatus;
  const imageStatusStyle = getImageStyle(isSalesSpace, isAgent);
  const imageStatusColor = getStatusColor({
    type, isSalesSpace, isDot, isAgent, channelMemberCount,
  });

  const [isRound, setImageUrl] = useRoundImageChecker(imageUrl);

  useEffect(() => {
    setImageUrl(imageUrl);
  }, [imageUrl, setImageUrl]);

  return (
    <div className={`${styles.wrapper} ${className} ${isSmall ? styles.smallWrapper : ''}`}>
      <div
        className={`${styles.statusImageWrapper} 
          ${imageStatusStyle} 
          ${imageStatusColor} 
          ${isSmall ? styles.small : ''} 
          ${isRound ? styles.isRound : ''}`}
        data-testid="image-with-status"
        data-testuserstatus={type}
      >
        <Image
          className={styles.image}
          src={imageUrl}
          type={IMAGE_TYPE.PROFILE}
        />

      </div>

      {
        isDot && <div className={`${styles.statusDot} ${dotStatusStyle[type]}`} />
      }

      { channelMemberCount > 2 && !isSalesSpace
      && (
        <div className={styles.channelMemberCountBadge}>
          {channelMemberCount}
        </div>
      )}

      {
        hasStar && (
          <img alt="Star Icon" className={`${styles.starIcon}`} src={starIcon} />
        )
      }

    </div>
  );
};

ImageWithStatus.propTypes = {
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  userStatus: UserStatusPropType,
  isSmall: PropTypes.bool,
  isDot: PropTypes.bool,
  isSalesSpace: PropTypes.bool,
  channelMemberCount: PropTypes.number,
  isAgent: PropTypes.bool,
  hasStar: PropTypes.bool,
};

export default ImageWithStatus;
