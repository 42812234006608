import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActiveHouseId } from '../houses/housesSelectors';

export const fetchTeams = createAsyncThunk(
  'teams/fetchTeams',
  async ({
    pageIndex, pageSize, searchQuery, sortOrder, sortedColumnId, houseId,
  } = {}, { getState, rejectWithValue, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());

    const response = await gccApiClient.getTeamsForHouse(
      pageIndex, pageSize, searchQuery, houseId || activeHouseId, sortOrder, sortedColumnId,
    );

    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    const { data, pagination } = response;
    return { data, pagination };
  },
);

export const fetchSelectedTeam = createAsyncThunk(
  'teams/fetchSelected',
  async ({ teamId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.getTeam(teamId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { team: response };
  },
);

export const updateSelectedTeam = createAsyncThunk(
  'teams/updateSelected',
  async ({ teamId, values }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.updateTeam(teamId, values);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { team: response };
  },
);

export const addUserToSelectedTeam = createAsyncThunk(
  'teams/addUserToSelected',
  async ({ teamId, userId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.addUserToTeam(teamId, userId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { team: response };
  },
);

export const removeUserFromSelectedTeam = createAsyncThunk(
  'teams/removeUserFromSelected',
  async ({ teamId, userId }, { rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.removeUserFromTeam(teamId, userId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    return { team: response };
  },
);

export const createTeam = createAsyncThunk(
  'teams/create',
  async ({ team }, { dispatch, getState, rejectWithValue, extra: { gccApiClient } }) => {
    const activeHouseId = getActiveHouseId(getState());
    const response = await gccApiClient.createTeam(team, activeHouseId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchTeams());
    return { team: response };
  },
);


export const deleteTeam = createAsyncThunk(
  'teams/delete',
  async ({ teamId }, { dispatch, rejectWithValue, extra: { gccApiClient } }) => {
    const response = await gccApiClient.deleteTeam(teamId);
    if (response.error_code) {
      return rejectWithValue({ errorCode: response.error_code });
    }
    await dispatch(fetchTeams());
    return response;
  },
);


export const getTeamForUsers = ({ userIds }) => async (
  dispatch, getState, { gccApiClient },
) => {
  const activeHouseId = getActiveHouseId(getState());
  return gccApiClient.getTeamForUsers(activeHouseId, userIds);
};
