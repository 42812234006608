import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Tabs({
  tabs,
  onActiveTabChanged = undefined,
  initialTab = '',
  disabled = false,
}) {
  const [activeTab, setActiveTab] = useState(initialTab || Object.keys(tabs)[0]);

  const handleTabSelected = useCallback(
    (tab) => {
      setActiveTab(tab);
      onActiveTabChanged?.(tab);
    },
    [onActiveTabChanged],
  );

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabHeaderWrapper}>
        {Object.entries(tabs).map(
          ([key, { name }]) => (
            <div
              type="button"
              role="button"
              key={key}
              onClick={() => handleTabSelected(key)}
              className={`${styles.tabHeader} ${key === activeTab && styles.active}`}
              disabled={disabled}
            >
              {name}
            </div>
          ),
        )}
      </div>
      <div>
        {tabs[activeTab].component}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.objectOf(
    PropTypes.shape({
      component: PropTypes.element,
      name: PropTypes.string,
    }),
  ).isRequired,
  onActiveTabChanged: PropTypes.func,
  initialTab: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Tabs;
