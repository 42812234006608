import { createSelector } from '@reduxjs/toolkit';
import ASYNC_STATE from '../../constants/AsyncState';

export const getCurrentCraftsmanUserState = state => state.currentCraftsmanUser;

export const getGcopLoading = createSelector(
  getCurrentCraftsmanUserState,
  (currentCraftsmanUserState) => currentCraftsmanUserState
    .updateGcopAccount.status === ASYNC_STATE.LOADING,
);
export const getGcopError = createSelector(
  getCurrentCraftsmanUserState,
  (currentCraftsmanUserState) => currentCraftsmanUserState.updateGcopAccount.errorMessage,
);
export const getGcopEditing = createSelector(
  getCurrentCraftsmanUserState,
  (currentCraftsmanUserState) => currentCraftsmanUserState.gcopEditing,
);
export const getGcopUsername = createSelector(
  getCurrentCraftsmanUserState,
  (currentCraftsmanUserState) => currentCraftsmanUserState.gcopUsername,
);
export const getGcopPassword = createSelector(
  getCurrentCraftsmanUserState,
  (currentCraftsmanUserState) => currentCraftsmanUserState.gcopPassword,
);
export const getAgentAvailabilityMessageForChannel = (channelId) => createSelector(
  getCurrentCraftsmanUserState,
  (currentCraftsmanUserState) => (
    currentCraftsmanUserState.agentAvailabilityMessage[channelId]
  ),
);
