import { MESSAGE_TYPE } from '../../constants/MessageTypes';
import AbstractMessageModel from './AbstractMessageModel';

export default class MediaFromMailMessageModel extends AbstractMessageModel {

  constructor(
    {
      contentType,
      filename,
      mediaSize,
      mediaRelativePath,
      ...args
    } = {},
  ) {
    super({
      messageType: MESSAGE_TYPE.MEDIA_FROM_MAIL,
      ...args,
    });

    this.contentType = contentType;
    this.filename = filename;
    this.mediaSize = mediaSize;
    this.mediaRelativePath = mediaRelativePath;
  }
}
