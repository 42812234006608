import PropTypes from 'prop-types';
import { CHANNEL_FILTER_TYPES } from 'gcs-common/helper/channelTypeHelper';
import styles from './styles.module.scss';
import ChannelTypeSelectionTab from '../ChannelTypeSelectionTab/ChannelTypeSelectionTab';

export default function ChannelTypeSelector({ hideCount = false }) {

  return (
    <div className={styles.channelSort}>
      <ChannelTypeSelectionTab
        hideCount={hideCount}
        filterType={CHANNEL_FILTER_TYPES.TEAM_CHATS}
      />
      <ChannelTypeSelectionTab
        hideCount={hideCount}
        filterType={CHANNEL_FILTER_TYPES.DIRECT_CHATS}
      />
    </div>

  );
}

ChannelTypeSelector.propTypes = {
  hideCount: PropTypes.bool,
};
