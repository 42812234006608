import { useDispatch, useSelector } from 'react-redux';
import { teamSelected } from 'gcs-common/slices/administration/teams/teamsSlice';
import {
  getTeamsLoading,
  getTeamsPaginationResult,
} from 'gcs-common/slices/administration/teams/teamsSelectors';
import { useNavigate } from 'react-router';
import LoadingIndicator from 'gcs-common/components/LoadingIndicator/LoadingIndicator';
import styles from './styles.module.scss';

import TeamTable from '../TeamTable/TeamTable';

const TeamOverview = () => {

  const teamsLoading = useSelector(getTeamsLoading);
  const teamsPagination = useSelector(getTeamsPaginationResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onTeamSelected = (team) => {
    const { id } = team;
    dispatch(teamSelected({ teamId: id }));
    navigate(`/administration/teams/${id}`);
  };

  return (
    <>
      <div className={styles.headerRow}>
        <div className={styles.inputHeaderText}>
          Teams
          {' '}
          (
          {teamsPagination && teamsPagination.total}
          )
        </div>
        {teamsLoading && <LoadingIndicator padding="0" />}
      </div>
      <TeamTable
        onTeamSelected={onTeamSelected}
        teamsLoading={teamsLoading}
      />
    </>
  );
};

export default TeamOverview;
