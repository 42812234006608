import { useCallback, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import { getIsAgent } from 'gcs-common/slices/currentUser/currentUserSelectors';
import {
  getChannelImage,
  getChannelMemberCount,
  getChannelStatus,
  getChannelSubtitle,
  getChannelTitle,
  getIsHidden,
  getIsSalesSpaceChannelType,
  getDirectChannelUserId,
} from 'gcs-common/slices/channel/channelSelectors';
import { setRightBarTab } from 'gcs-common/slices/uiState/uiStateSlice';
import { RIGHT_BAR_TABS } from 'gcs-common/constants/uiFlags';
import { currentUserIdSelected } from 'gcs-common/slices/memberDetails/memberDetailsSlice';
import ChatConnectionBanner from '../ChatConnectionBanner/ChatConnectionBanner';
import styles from './styles.module.scss';
import ImageWithStatus from '../ImageWithStatus/ImageWithStatus';
import ChannelEmailPopup from './ChannelEmailPopup/ChannelEmailPopup';
import SalesSpaceChannelHeaderBar from '../SalesSpaceChannelHeaderBar/SalesSpaceChannelHeaderBar';
import ChannelArchiveButton from './ChannelArchiveButton/ChannelArchiveButton';

function ChatNavBar() {
  const dispatch = useDispatch();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const isAgent = useSelector(getIsAgent);
  const channelMemberCount = useSelector(
    getChannelMemberCount(selectedChannelId),
  );
  const channelTitle = useSelector(getChannelTitle(selectedChannelId));
  const channelSubtitle = useSelector(getChannelSubtitle(selectedChannelId));
  const channelStatus = useSelector(getChannelStatus(selectedChannelId));
  const channelImage = useSelector(getChannelImage(selectedChannelId));
  const isSalesSpaceChannel = useSelector(
    getIsSalesSpaceChannelType(selectedChannelId),
  );
  const isHidden = useSelector(getIsHidden(selectedChannelId));
  const otherUserId = useSelector(getDirectChannelUserId(selectedChannelId));

  const onButtonClick = useCallback(() => {
    if (!isAgent) return;

    if ((channelMemberCount > 2 || isSalesSpaceChannel)) {
      dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.CHANNEL_DETAILS }));
      dispatch(
        currentUserIdSelected({
          userId: otherUserId,
          channelId: selectedChannelId,
        }),
      );
    } else if (channelMemberCount === 2) {
      dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.MEMBER_DETAILS }));
      dispatch(
        currentUserIdSelected({
          userId: otherUserId,
          channelId: selectedChannelId,
        }),
      );
    }
  }, [
    dispatch,
    channelMemberCount,
    otherUserId,
    selectedChannelId,
    isSalesSpaceChannel,
    isAgent,
  ]);

  useEffect(() => {
    dispatch(setRightBarTab({ rightBarTab: RIGHT_BAR_TABS.TOOLS }));
  }, [selectedChannelId, dispatch]);

  return (
    <>
      <div className={styles.groupDetailsContainer} data-testid="chat-nav-bar">
        <div className={styles.userDetailsContainer}>
          <button
            className={styles.userDetailsWrapper}
            type="button"
            onClick={onButtonClick}
          >
            <ImageWithStatus
              imageUrl={channelImage}
              className={styles.headerImage}
              channelMemberCount={channelMemberCount}
              isSalesSpace={isSalesSpaceChannel}
              isAgent={isAgent}
              userStatus={channelStatus}
            />
            <div className={styles.userDetails}>
              <div className={styles.groupName}>{channelTitle}</div>
              <div className={styles.userCompany}>{channelSubtitle}</div>
              {isHidden && (
                <div className={styles.hiddenIndicator}>Archiviert</div>
              )}
            </div>
          </button>
          <div className={styles.buttonGroup}>
            <ChannelArchiveButton selectedChannelId={selectedChannelId} />
            {isAgent && <ChannelEmailPopup />}
          </div>
        </div>
        {isSalesSpaceChannel && isAgent && <SalesSpaceChannelHeaderBar />}
      </div>
      <ChatConnectionBanner />
    </>
  );
}

export default ChatNavBar;
