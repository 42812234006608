/* eslint-disable jsx-a11y/label-has-for */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Select from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import ValidationError from 'gcs-common/components/Formik/ValidationError/ValidationError';
import styles from './styles.module.scss';
import selectCustomStyles from './customStyles';
import errorIcon from '../../img/close_red.svg';

const FormikSelect = ({
  name = '',
  options: providedOptions,
  label = '',
  disabled = false,
  placeholder = 'Suchen...',
  labelKey,
  valueKey,
  isMulti = false,
  isLoading = false,
  onChange,
  onInputChange,
  initialValue,
  hasError = false,
  localFilter,
  isClearable = true,
  compareOptionsFunction,
  getOptionLabel,
  formatOptionLabel,
  optionBackgroundColor,
  optionsWidth,
  customFilter,
}) => {
  const [field, , helpers] = useField(name);
  const selectRef = useRef(null);
  // Note: We cache previously selected options, otherwise they will be removed from the select
  const currentlySelectedCache = useRef(
    // eslint-disable-next-line no-nested-ternary
    Array.isArray(initialValue) ? initialValue : initialValue ? [initialValue] : [],
  );

  // Note: To display the currently selected entity if its load async
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let options = [...providedOptions];
  if (currentlySelectedCache.current && currentlySelectedCache.current.length > 0 && isMulti) {
    options = [...currentlySelectedCache.current, ...options];
  }

  const handleChange = useCallback((option) => {
    let value;
    if (isMulti) {
      value = option && option.map(op => (valueKey ? op[valueKey] : op));
      currentlySelectedCache.current = option;
      if (!value) {
        value = [];
      }
    } else {
      value = option && (valueKey ? option[valueKey] : option);
      currentlySelectedCache.current = option ? [option] : [];
    }
    helpers.setValue(value);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onChange && onChange(value);
  }, [helpers, isMulti, onChange, valueKey]);

  const [handleInputChangedDebounced] = useDebouncedCallback(
    (inputValue) => {
      if (onInputChange && (isMulti || (!isMulti && inputValue !== ''))) {
        onInputChange(inputValue);
      }
    },
    300,
  );

  const areOptionsEqual = useCallback((value, option) => {
    if (compareOptionsFunction) {
      return compareOptionsFunction(value, option);
    }
    return value === option;
  }, [compareOptionsFunction]);

  const value = useMemo(() => {
    if (isMulti) {
      return field.value && field.value.map(val => (options ? options.find((option) => areOptionsEqual(option[valueKey], val)) : ''));
    }
    return options ? options.find((option) => areOptionsEqual(option && option[valueKey], field.value)) : '';
  }, [field.value, isMulti, options, valueKey, areOptionsEqual]);

  const filteredOptions = useMemo(() => {
    if (localFilter) {
      return options.filter(a => !localFilter.includes(a[valueKey]));
    }
    return options;
  }, [localFilter, options, valueKey]);

  useEffect(() => {
    if (!disabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onInputChange && onInputChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <div className={styles.formikSelectContainer}>
      <div className={`${styles.formikSelectWrapper} ${disabled ? styles.disabled : ''}`}>
        {label && (
          <label
            className={styles.formikSelectLabel}
            htmlFor={field.name}
          >
            {label}
          </label>
        )}
        {(hasError && disabled) ? (
          <div
            className={styles.formikSelectError}
          >
            <img src={errorIcon} alt="Fehler" />
            Fehler
          </div>
        ) : (
          <div ref={selectRef}>
            <Select
              onInputChange={handleInputChangedDebounced}
              styles={selectCustomStyles}
              isDisabled={disabled}
              placeholder={disabled ? '-' : placeholder}
              options={filteredOptions} // the options that can be selected
              name={field.name}
              getOptionValue={option => (valueKey ? option[valueKey] : option)}
              getOptionLabel={getOptionLabel || (option => (labelKey ? option[labelKey] : option))}
              formatOptionLabel={formatOptionLabel}
              value={value || null}
              filterOption={customFilter}
              onChange={handleChange}
              onBlur={field.onBlur}
              menuPlacement="auto"
              isMulti={isMulti}
              isLoading={isLoading}
              noOptionsMessage={() => 'Keine Optionen'}
              loadingMessage={() => 'Lädt...'}
              menuPortalTarget={document.body}
              isClearable={isClearable}
              optionBackgroundColor={optionBackgroundColor}
              optionsWidth={optionsWidth}
              closeMenuOnScroll={(e) => e.target.contains(selectRef.current)}
            />
          </div>
        )}
      </div>
      <ValidationError name={name} />
    </div>
  );
};

FormikSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  localFilter: PropTypes.arrayOf(PropTypes.shape),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  onInputChange: PropTypes.func,
  compareOptionsFunction: PropTypes.func,
  getOptionLabel: PropTypes.func,
  optionBackgroundColor: PropTypes.string,
  optionsWidth: PropTypes.string,
  customFilter: PropTypes.func,
  formatOptionLabel: PropTypes.func,
};

export default FormikSelect;
