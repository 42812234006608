/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import 'react-datepicker/dist/react-datepicker.css';
import { ICON, ICON_TRANSFORM } from 'gcs-common/constants/IconConstants';
import { FC } from 'react';
import IconComponent from '../../IconComponent/IconComponent';
import styles from './styles.module.scss';

interface TaskListSeparatorProps {
  action: VoidFunction,
  isCollapsed: boolean,
}

const TaskListSeparator:FC<TaskListSeparatorProps> = ({ action, isCollapsed }) => {

  return (
    <div className={styles.listSeparator}>
      <button type="button" className={styles.toggleButton} onClick={action}>
        {!isCollapsed
          ? <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.ROTATE_270} />
          : <IconComponent Icon={ICON.ARROW} transform={ICON_TRANSFORM.ROTATE_90} />}
      </button>
    </div>
  );
};

export default TaskListSeparator;
