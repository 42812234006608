import { useRef } from 'react';
import useGetFirstRowItemsLength from 'gcs-common/hooks/useGetFirstRowItemsLength';
import PropTypes from 'prop-types';
import Chip from 'gcs-common/components/Chip/Chip';
import ExtraMembersChip from 'gcs-common/components/Chip/ExtraMembersChip/ExtraMembersChip';
import styles from './styles.module.scss';

const RowMemberList = ({
  isTeamRow,
  assignmentMainAgent = null,
  assignmentMembers = [],
  communicationMainRole = null,
  communicationStructureMembers = [],
  calculateMemberKey = () => {},
}) => {

  const membersDivRef = useRef(null);
  const firstRowItemsLength = useGetFirstRowItemsLength(membersDivRef);
  const members = assignmentMembers.length > 0 ? assignmentMembers : communicationStructureMembers;
  const mainContactPersonCount = (assignmentMainAgent || communicationMainRole) ? 1 : 0;
  const extraMembersCount = mainContactPersonCount + members.length - firstRowItemsLength;

  const renderChip = (member, isCommunicationStructure) => {
    return (
      <Chip
        memberName={member.friendlyName}
        customerResponsibilityRoleName={
          isCommunicationStructure ? null : member.customerResponsibilityRoleName
        }
        memberType={member.type}
        roleStatus={member.roleStatus}
        isMainContactPerson={member === assignmentMainAgent || member === communicationMainRole}
        isCommunicationStructure={isCommunicationStructure}
      />
    );
  };

  return (
    <div className={`${styles.wrapperAllMembers} ${!isTeamRow && styles.wrapperDirectChats}`}>
      <div className={styles.members} ref={membersDivRef}>
        {/* House tab */}
        {isTeamRow && (
          communicationMainRole ? (
            <div className={styles.column}>
              {renderChip(communicationMainRole, true)}
            </div>
          ) : null
        )}

        {communicationStructureMembers.map((member) => (
          <div className={styles.column} key={calculateMemberKey(member)}>
            {renderChip(member, true)}
          </div>
        ))}

        {/* Customers tab */}
        {isTeamRow && (
          assignmentMainAgent ? (
            <div className={styles.column}>
              {renderChip(assignmentMainAgent, false)}
            </div>
          ) : null
        )}
        {assignmentMembers.map(member => {
          return (
            <div className={styles.column} key={member.id}>
              {renderChip(member, false)}
            </div>
          );
        })}
      </div>
      {extraMembersCount > 0 && (
        <ExtraMembersChip
          extraMembersCount={extraMembersCount}
        />
      )}
    </div>
  );
};

RowMemberList.propTypes = {
  isTeamRow: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignmentMainAgent: PropTypes.object,
  assignmentMembers: PropTypes.arrayOf(PropTypes.shape),
  // eslint-disable-next-line react/forbid-prop-types
  communicationMainRole: PropTypes.object,
  communicationStructureMembers: PropTypes.arrayOf(PropTypes.shape),
  calculateMemberKey: PropTypes.func,
};

export default RowMemberList;
