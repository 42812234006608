import { PropTypes } from 'prop-types';
import { PROCESSES_SUGGESTION_TYPE } from 'gcs-common/constants/processesSuggestionType';
import clsx from 'clsx';
import styles from './styles.module.scss';
import exitIcon from '../../img/close.svg';

const SearchBar = (
  {
    filter, onSearchInputChange, searchInput, resetFilter, placeholder = 'Suchen...', className,
  },
) => {
  return (
    <div className={clsx(styles.search, className)}>
      <input
        className={styles.searchInput}
        placeholder={placeholder}
        type="search"
        value={searchInput}
        onChange={onSearchInputChange}
      />
      {filter
      && (
        <button
          type="button"
          className={styles.filter}
        >
          <div>
            <div className={styles.suggestion}>
              {filter.name}
            </div>
            <div className={styles.suggestionType}>
              {PROCESSES_SUGGESTION_TYPE[filter.type]}
            </div>
          </div>
          <button
            type="button"
            onClick={resetFilter}
          >
            <img
              src={exitIcon}
              className={styles.filterClose}
              alt="exit-icon"
            />
          </button>
        </button>
      )
      }
    </div>
  );
};
SearchBar.propTypes = {
  filter: PropTypes.shape({
    type: PropTypes.string, name: PropTypes.string,
  }),
  onSearchInputChange: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default SearchBar;
