import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSidebarCollapsed } from 'gcs-common/slices/uiState/uiStateSelectors';
import ChannelListItemCollapsed from '../ChannelListItemCollapsed/ChannelListItemCollapsed';
import ChannelListItemNormalWithId from '../ChannelListItemNormalWithId/ChannelListItemNormalWithId';

const ChannelListItem = ({ channelId }) => {

  const sidebarCollapsed = useSelector(getSidebarCollapsed);
  if (sidebarCollapsed) {
    return (
      <ChannelListItemCollapsed channelId={channelId} />
    );
  }

  return (
    <ChannelListItemNormalWithId channelId={channelId} />
  );
};

ChannelListItem.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelListItem;
