import { createSelector } from 'reselect';
import moment from 'moment';
import ASYNC_STATE from '../../constants/AsyncState';

export const getProcesses = (state) => state.processes.processes;
export const getOpConnectionStates = (state) => state.processes.opConnectionStates;
export const getHasMore = (state) => state.processes.hasMore;
export const getProcessesFetchErrorMessage = (state) => state.processes.fetch.errorMessage;
export const getProcessSearchInput = (state) => state.processes.searchInput;
export const getFilter = (state) => state.processes.filter;
export const getSuggestions = (state) => state.processes.suggestions;
export const getSelectedProcess = (state) => state.processes.selectedProcess;
export const getSelectedProduct = (state) => state.processes.selectedProduct;
export const getProcessesLoading = (state) => state.processes.fetch.status === ASYNC_STATE.LOADING;
export const getSuggestionsLoading = (state) => (
  state.processes.search.status === ASYNC_STATE.LOADING
);


// PROCESS DETAILS
export const getProcessDetailsLoading = (state) => (
  state.processes.processDetailsFetch.status === ASYNC_STATE.LOADING
);
export const getProcessDetails = (state) => state.processes.processDetails;

export const getProcessDetailsForProcess = (processNumber, processSuffix) => createSelector(
  getProcessDetails,
  (processDetails) => processDetails[`${processNumber}-${processSuffix}`],
);

export const getProcessesForChannel = (channelId) => createSelector(
  getProcesses,
  (processes) => processes[channelId] || [],
);

export const getOpConnectionStateForChannel = (channelId) => createSelector(
  getOpConnectionStates,
  (opConnectionStates) => opConnectionStates[channelId],
);

export const hasStaleOpData = (channelId) => createSelector(
  getOpConnectionStateForChannel(channelId),
  (opConnectionState) => opConnectionState?.isStale,
);

export const getProcessDateTag = (channelId, processListIndex) => createSelector(
  getProcessesForChannel(channelId),
  (processes) => {
    const process = processes[processListIndex];
    // use SortDate by default, but fall back to CreationDate if SortDate not available
    const { CreationDate, SortDate } = process;
    const processDate = SortDate ? moment(SortDate) : moment(CreationDate);
    let tag = null;
    let lastTimeTag = null;
    if (processes[processListIndex - 1]) {
      const lastProcess = processes[processListIndex - 1];
      const { CreationDate: lastCreationDate, SortDate: lastSortDate } = lastProcess;
      lastTimeTag = lastSortDate ? moment(lastSortDate) : moment(lastCreationDate);
    }
    if (!lastTimeTag || !lastTimeTag.isSame(processDate, 'day')) {
      tag = moment(processDate).format('dddd, L');
    }
    return tag;
  },
);
