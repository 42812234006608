import ASYNC_STATE from '../../constants/AsyncState';
import calculateSize from '../../helper/calculateSize';

export const getUsers = state => state.users.users;

export const getIsUserLoading = state => (
  state.users.fetchUser.loading === ASYNC_STATE.LOADING
);

export const getStateSize = state => {
  const storeSize = calculateSize(state);
  return `${Math.round(storeSize)} kB`;
};
