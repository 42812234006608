/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import styles from './styles.module.scss';

interface TaskTodoProps {
  task: FetchTasksResponseData['tasks'][number],
  clickAction: VoidFunction,
}

// TODO: REF Merge all task states?
const TaskTodo:FC<TaskTodoProps> = ({ task, clickAction }) => {
  return (
    <div
      className={styles.taskContent}
      onClick={clickAction}
    >
      <div>
        {task.description || ' '}
      </div>
    </div>
  );
};

export default TaskTodo;
