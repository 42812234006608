/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import { FetchTasksResponseData } from 'gcs-common/clients/api/entities/tasks/tasks-api-helpers';
import styles from './styles.module.scss';
import IconComponent from '../../IconComponent/IconComponent';

interface TaskDueProps {
  dueTask: FetchTasksResponseData['tasks'][number],
  onClose: VoidFunction,
  onClick: VoidFunction,
  taskDueClass?: string,
}

const TaskDue: FC<TaskDueProps> = ({ dueTask, onClose, onClick, taskDueClass }) => {

  const formattedData = useMemo(() => {
    const date = new Date(dueTask.dueDate ?? '');
    const today = new Date();
    if (!(today.getFullYear() === date.getFullYear()
      && today.getDate() === date.getDate() && today.getMonth() === date.getMonth())) {
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'long', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit',
      };
      return date.toLocaleDateString('de-DE', options);
    }
    return date.toLocaleTimeString('de-DE', { timeStyle: 'short' });
  }, [dueTask.dueDate]);

  return (
    <div data-testid="taskDueWarning" className={`${styles.notificationHeader}${taskDueClass ? ` ${taskDueClass}` : ''}`}>
      <IconComponent Icon={ICON.ALARM} color={ICON_COLOR.WHITE} style={{ marginRight: '5px' }} alt="clock-icon" />
      <div onClick={onClick}>
        {formattedData.concat('  ', dueTask.description)}
      </div>
      <button type="button" onClick={onClose}>
        <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.WHITE} alt="close-icon" />
      </button>
    </div>
  );
};

export default TaskDue;
