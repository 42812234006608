/* eslint-disable react/jsx-indent */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelInputForwardMessagesStaged, resetSelectedMessageIds } from 'gcs-common/slices/messageInput/messageInputSlice';
import { getSelectedChannelId } from 'gcs-common/slices/channels/channelsSelectors';
import {
  getSelectedMessageIds,
  getSelectedMessageIdsForwardTarget,
} from 'gcs-common/slices/messageInput/messageInputSelector';
import { openAsEmlEmail } from 'gcs-common/slices/email/emailThunks';
import { newTicketFromMessages } from 'gcs-common/slices/tickets/ticketsThunks';
import FORWARD_TARGET from 'gcs-common/constants/ForwardTarget';
import { ICON, ICON_COLOR } from 'gcs-common/constants/IconConstants';
import styles from './styles.module.scss';
import SelectedMessageIndicator from '../SelectedMessageIndicator/SelectedMessageIndicator';
import IconComponent from '../IconComponent/IconComponent';

export const ForwardMessageBottom = () => {
  const dispatch = useDispatch();

  const selectedChannelId = useSelector(getSelectedChannelId);
  const selectedMessagesIds = useSelector(getSelectedMessageIds(selectedChannelId));
  const forwardTarget = useSelector(getSelectedMessageIdsForwardTarget(selectedChannelId));

  const cancelForwarding = useCallback(() => {
    dispatch(resetSelectedMessageIds({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId]);

  const onForward = useCallback(() => {
    dispatch(channelInputForwardMessagesStaged(
      { channelId: selectedChannelId, messageIds: selectedMessagesIds },
    ));
    dispatch(resetSelectedMessageIds({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId, selectedMessagesIds]);

  const onEmail = useCallback(() => {
    dispatch(openAsEmlEmail({ messageIds: selectedMessagesIds }));
    dispatch(resetSelectedMessageIds({ channelId: selectedChannelId }));
  }, [dispatch, selectedChannelId, selectedMessagesIds]);

  const onCreateTicket = useCallback(() => {
    dispatch(newTicketFromMessages({
      channelId: selectedChannelId,
      messageIds: selectedMessagesIds,
    }));
    dispatch(resetSelectedMessageIds({ channelId: selectedChannelId }));
  }, [dispatch, selectedMessagesIds, selectedChannelId]);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <button
          onClick={cancelForwarding}
          type="button"
          data-testid="cancel-forwarding-button"
        >
          <IconComponent Icon={ICON.CLOSE} color={ICON_COLOR.DARK_GREY} />
        </button>
        <SelectedMessageIndicator selectedMessages={selectedMessagesIds} />
      </div>
      {forwardTarget === FORWARD_TARGET.EMAIL && (
        <button
          type="button"
          onClick={onEmail}
          data-testid="forward-to-email-button"
        >
          <IconComponent Icon={ICON.EMAIL} color={ICON_COLOR.DARK_GREY} />
        </button>
      )}
      {forwardTarget === FORWARD_TARGET.CHAT && (
        <button
          type="button"
          onClick={onForward}
          data-testid="forward-to-chat-button"
        >
          <IconComponent Icon={ICON.FORWARD} color={ICON_COLOR.DARK_GREY} />
        </button>
      )}
      {forwardTarget === FORWARD_TARGET.TICKET && (
        <button
          type="button"
          className={styles.etsButton}
          onClick={onCreateTicket}
          data-testid="forward-to-ticket-button"
        >
          Erstellen
        </button>
      )}
    </div>
  );
};
