import { useSelector } from 'react-redux';
import { NEW_CHANNEL_SECTIONS } from 'gcs-common/constants/newChannelSections';
import { getSearchInput } from 'gcs-common/slices/channelList/channelListSelectors/getSearchInput';
import { getIsGroupChat } from 'gcs-common/slices/newChannelOptions/newChannelOptionsSelectors';
import { getSelectedChannelTypeFilter } from 'gcs-common/slices/channelList/channelListSelectors/getSelectedChannelTypeFilter';
import { CHANNEL_FILTER_TYPES } from 'gcs-common/helper/channelTypeHelper';
import NewChannelGroup from '../NewChannelGroup/NewChannelGroup';
import GroupedNewChannelList from './GroupedNewChannelList/GroupedNewChannelList';
import NonGroupedNewChannelList from './NonGroupedNewChannelList/NonGroupedNewChannelList';
import NewChatSearchMessage from '../NewChatSearchMessage/NewChatSearchMessage';

const NewChannelListAgent = () => {
  const isGroupChat = useSelector(getIsGroupChat);
  const searchInput = useSelector(getSearchInput);
  const channelTypeFilter = useSelector(getSelectedChannelTypeFilter);
  const isTeamChatsSelected = channelTypeFilter === CHANNEL_FILTER_TYPES.TEAM_CHATS;

  return (
    <>
      {/* Shows selected newChannelOptions in group */}
      {isGroupChat && (
        <NewChannelGroup sectionName={NEW_CHANNEL_SECTIONS.SELECTED_MEMBERS} />
      )}
      {isGroupChat && <NewChatSearchMessage searchTerm={searchInput} />}
      {(!searchInput || (isTeamChatsSelected && !isGroupChat)) ? (
      // Shows ungrouped list when not searching and for team chats
        <NonGroupedNewChannelList />
      ) : (
      // Shows grouped list when searching and not team chats
        <GroupedNewChannelList />
      )}
    </>
  );
};

export default NewChannelListAgent;
